import Branch from "@/entity/Branch";
import ResponseGroup from "@/entity/ResponseGroup";
import Survey from "@/entity/Survey";
import store from "@/store";
import ItemType from "@/entity/ItemType";
import ItemProperty from "@/entity/ItemProperty";
import Response from "@/entity/Response";
import Mapping from "@/entity/Mapping";
import {helper} from "@/services/Helper";
import Item from "@/entity/Item";
import Property from "@/entity/Property";
import PackGuarantee from "@/entity/PackGuarantee";
import ItemGuarantee from "@/entity/ItemGuarantee";
import Question from "../entity/Question";
import {WORKFLOWS} from "@/Utils/constant";

class Mapper {
    rg!: ResponseGroup;
    survey!: Survey;
    surveys: Survey[] = [];
    responseGroups: ResponseGroup[] = [];
    branch!: Branch;
    items: Item[] = [];
    fullItemList: Item[] = [];
    assetCount = 0
    attestations = {
        green: 0,
        blue: 0,
        yellow: 0
    }
    workflow: any = null

    map(rg: ResponseGroup) {

        this.attestations = {
            green: 0,
            blue: 0,
            yellow: 0
        }
        // this.rg = store.state.currentResponseGroup
        // this.survey = store.state.currentSurvey
        this.fullItemList = [];
        this.branch = store.state.currentBranch;
        this.responseGroups = store.state.rgs;
        this.surveys = store.state.surveys;

        // console.log('rg count ' + this.responseGroups.length)
        // this.responseGroups.forEach((rg: ResponseGroup) => {
        //     console.log(rg.uuid+' '+rg.surveySlug)
        // })
        // console.log(this.surveys)
        // this.surveys.forEach((s: Survey) => {
        //     console.log(s.label + ' ' + s.uuid)
        // })
        let items: Item[] = [];

        const currentSurvey = this.surveys.find((s: Survey) => {
            return s.uuid === rg.survey.uuid;
        }) as any;
        // console.log(currentSurvey)
        rg.survey = currentSurvey;
        let currentItem: any = null;
        // console.log('form ' + rg.survey.label + ' item map ' + currentSurvey.item)
        this.branch.items.forEach((i: ItemType) => {
            if (i.required || i.isRoot || i.uuid === currentSurvey.item) {
                if (i) {
                    const item: Item = this.createItemFromType(i);
                    item.responseGroup = rg;
                    items.push(item);
                    if (i.uuid === currentSurvey.item) {
                        currentItem = item;
                    }
                }
            }
        });
        this.mapRg(rg, items);
        this.handleWorkflows()
        return {items, fullItemList: this.fullItemList, item: currentItem};
    }

    assets: Item[] = []

    handleWorkflows() {
        this.assets.forEach((item: Item) => {
            if (item.model.asaciWorkflow && !helper.empty(item.model.asaciWorkflow)) {
                this.workflow = item.model.asaciWorkflow
                if (item.model.asaciWorkflow === WORKFLOWS.ASACI) {
                    this.asaciWorkflow(item)
                }
            }
        })
    }

    asaciWorkflow(item: Item) {
        if (item.model.asaciWorkflow && item.model.asaciWorkflow === WORKFLOWS.ASACI) {
            const type = item.getPropertyByCode('type')
            let motoCodes = ["MOTO", "CYCLOMOTEUR", "SCOOTER", "MOBYLETTE", "MOTOCYCLETTE", "TANDEM_A_MOTEUR", "TRIPORTEUR", "SIDE_CAR"];
            if (type && type.value && motoCodes.includes(type.value.toUpperCase())) {
                this.attestations.green++
            } else {
                this.attestations.yellow++
            }
        }
    }

    createItemFromType(itemType: ItemType) {
        const item = new Item();
        item.name = itemType.name;
        item.model = itemType;

        itemType.properties.forEach((p: ItemProperty) => {
            const occ = new Property();
            occ.model = p;
            occ.name = p.name;
            occ.value = p.value;
            item.properties.push(occ);
        });

        if (item.model.className === 'Asset') {
            this.assets.push(item)
        }
        this.fullItemList.push(item);
        return item;
    }

    mapRg(rg: ResponseGroup, items: Item[]) {
        const survey: Survey = new Survey(
            this.surveys.find((s: Survey) => {
                return s.uuid === rg.survey.uuid;
            })
        );

        // console.log('mapping ' + survey.label)
        // console.log(survey)
        items.forEach((i: Item) => {
            // if (survey.item === i.model.uuid) {
            //     i.survey = survey
            // }
            this.mapItem(i, rg, survey);
        });
        // console.log(items)
    }

    mapItem(item: Item, rg: ResponseGroup, survey: Survey) {
        // console.log('-----------------------------')
        // console.log('mapping item ' + item.name)
        item.responseGroup = rg
        item.properties.forEach((p: Property) => {
            // console.log('======' + p.name)
            const mappings = survey.mappings.filter((m: Mapping) => {
                return m.propertyUuid === p.model.uuid;
            });

            mappings.sort((a: Mapping, b: Mapping) => {
                if (a.ranking && b.ranking) {
                    return a.ranking - b.ranking;
                }
                return 1
            })
            // console.log(p.model.name)
            // console.log(p)
            // console.log('map count '+mappings.length)
            mappings.forEach((m: Mapping) => {
                const response = rg.responses.find((r: Response) => {
                    return r.questionUuid === m.questionUuid;
                });
                let question: any = null;
                this.surveys.forEach((s: Survey) => {
                    const occ = new Survey(s);
                    occ.getQuestions().forEach((q: Question) => {
                        if (q.uuid === m.questionUuid) {
                            question = q;
                        }
                    });
                });
                if (question) {
                    if (!question.isVisibleIn(survey, rg)) {
                        return;
                    }
                }

                if (p.model.type.code === "OBJECT") {
                    // console.log(response)
                }

                if (response) {
                    if (helper.empty(p.value) || !(p.ranking) || (p.ranking && m.ranking && p.ranking! < m.ranking)) {
                        // console.log('set ' + p.model.name + ' = ' + response.value)
                        p.value = response.value;
                    }
                }
            });

            if (helper.empty(p.value) && !helper.empty(p.model.value)) {
                // console.log('set default ' + p.model.name)
                p.value = p.model.value
            }
            if (p.model.type.code.match(/OBJECT|OBJECT_GROUP/)) {
                // console.log('mapping object group ' + p.model.name)
                const itemType: any = this.branch.items.find((i: ItemType) => {
                    return i.uuid === p.model.value;
                });
                // console.log('item uuid ' + itemType.uuid)
                // console.log('property value ' + p.value)
                const subSurvey: Survey = this.surveys.find((s: Survey) => {
                    // console.log('... '+s.item+' === '+itemType.uuid)
                    return s.item === itemType.uuid;
                }) as any;
                if (subSurvey) {
                    // console.log('survey ' + p.value + ' found')
                    const m: Mapping = mappings.find((m: Mapping) => {
                        return m.propertyUuid === p.model.uuid;
                    }) as any;
                    let response = null;
                    if (m) {
                        response = rg.responses.find((r: Response) => {
                            return r.questionUuid === m.questionUuid;
                        });
                    }
                    if (response) {
                        response.responseGroups.forEach((subRg: ResponseGroup) => {
                            // console.log('mapping sub item ' + subSurvey.label)
                            // console.log(subRg.uuid)
                            let subItem = this.createItemFromType(itemType);
                            subItem = this.mapItem(subItem, subRg, subSurvey);
                            p.children.push(subItem);
                        });
                    }
                } else {
                    // console.error('sous formulaire ' + p.value + ' introuvable')
                    let subItem = this.createItemFromType(itemType);
                    subItem = this.mapItem(subItem, rg, survey);
                    p.children.push(subItem);

                }
            }
            if (
                item.model.code.toLowerCase() === "avenant" && p.model.code === "source") {
                p.value = rg.source;
            }
        });
        // console.log('*****************************')
        return item;
    }

    setChildrenPack(item?: Item) {
        const self: Item = this as any;

        if (
            item &&
            item.pack &&
            (self.model.isPackApplier || self.model.className === "Asset")
        ) {
            self.pack = item.pack;
            self.guarantees = [];
            item.pack.packGuarantees.forEach((p: PackGuarantee) => {
                if (!p.isOptional || p.selected) {
                    const occ = new ItemGuarantee();
                    occ.label = p.guarantee!.label;
                    occ.ceiling = p.ceiling;
                    occ.deductible = p.deductible;
                    occ.description = p.guarantee!.description;
                    occ.guarantee = {...p.guarantee} as any;
                    occ.isOptional = p.isOptional;
                    self.guarantees.push(occ);
                }
            });
        }

        let pack = null;
        let source: any = null;

        if (item && item.pack) {
            pack = item.pack;
            source = item;
        }
        if (!pack && self.pack) {
            pack = self.pack;
            source = self;
        }
        if (pack) {
            self.properties.forEach((p: Property) => {
                if (p.model.type.code.match(/OBJECT/)) {
                    p.children.forEach((c: Item) => {
                        c.setChildrenPack(source);
                    });
                }
            });
        } else {
        }
    }

    mapProperty(property: ItemProperty) {
    }
}

export const mapper = new Mapper();
