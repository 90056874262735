<template>
  <Base>
    <template v-slot:content>
      <div class="d-flex w-100">
        <div class="mx-10" v-for="category in categories">
          <div :class="{ 'selected' : category.uuid === currentCategory.uuid }"
               :style="{'color': category.color}"
               @click="currentCategory = category"
               class="fs-2 fw-bolder cursor-pointer text-h p-2 rounded category d-flex align-items-center">
            {{ trans(category.label) }} <span
              class="badge badge-danger ms-3">{{ category.branches.length }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap" v-if="currentCategory">
        <div class="card m-5 flex-grow-1" v-for="branch in currentCategory.branches">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="fw-bolder">
                <i :class="branch.icon" class="me-3 fs-2" v-if="branch.icon"></i>
                {{ branch.label }}
              </div>
              <div>
                <div @click="createPackCategory(branch)" class="btn btn-primary btn-sm btn-icon"
                     v-b-modal="'editPackCategory'">
                  <i class="fad fa-plus"></i>
                </div>
              </div>
            </div>


            <div class="mt-5">
              <ul>
                <li v-for="packCategory in branch.packCategories">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      {{ packCategory.label }}
                    </div>
                    <div class="d-flex">
                      <!--                                            <div class="form-check form-switch form-check-custom form-check-solid">-->
                      <!--                                                <input class="form-check-input" v-model="packCategory.enabled" type="checkbox" value=""/>-->
                      <!--                                            </div>-->
                      <router-link
                          :to="{name : 'pack-category-form', params :{ packCategorySlug : packCategory.slug, branchSlug: branch.slug }}"
                          class="btn btn-sm btn-icon" @click="editPackCategory(packCategory)"
                          v-b-modal="'editPackCategory'">
                        <i class="fad fa-edit"></i>
                      </router-link>
                    </div>

                  </div>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <b-modal :title="trans('Créer une catégorie de produit')" centered id="editPackCategory" ref="editPackCategory">
        <form>
          <div class="row" v-if="packCategory">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(packCategory.label)"
                            v-model="packCategory.label"></b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Description') }}</label>
              <b-form-textarea :id="'code'" :state="!helper.empty(packCategory.description)"
                               v-model="packCategory.description"></b-form-textarea>
            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="savePackCategory" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import BranchCategory from "@/entity/BranchCategory";
import PackCategory from "@/entity/PackCategory";
import Branch from "@/entity/Branch";

@Component({
  components: {Base}
})
export default class PackCategoryList extends Vue {
  categories: BranchCategory[] = []
  currentCategory: any = null
  packCategory: PackCategory = new PackCategory()


  createPackCategory(branch: Branch) {
    this.packCategory = new PackCategory()
    this.packCategory.branch = branch
  }

  editPackCategory(cat: PackCategory) {
    this.packCategory = cat
  }

  mounted(): void {
    this.loadBranches()
  }

  async savePackCategory() {
    let pc = {...this.packCategory};
    pc.branch = {slug: (pc.branch as any).slug, uuid: (pc.branch as any).uuid} as any
    const res = await api.post(api.core, 'pack/category/edit', {pc})
    const refs = this.$refs as any;
    if (refs.editPackCategory) {
      refs.editPackCategory.hide()
    }
    this.loadBranches()

  }

  async loadBranches() {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'api/branch/list')

    if (res && res.data && res.data.categories) {
      this.categories = []
      res.data.categories.forEach((cat: BranchCategory) => {
        this.categories.push(new BranchCategory(cat))
      })
    }
    if (this.categories.length > 0 && this.currentCategory === null) {
      this.currentCategory = this.categories[0] as any
    } else if (this.currentCategory instanceof BranchCategory) {
      this.categories.forEach((c: any) => {
        if (c.uuid === this.currentCategory.uuid) {
          this.currentCategory = c
        }
      })
    }
    this.$store.commit('stopLoading')
  }
}
</script>
<style scoped>
.dropmenu a {
  padding: 2px 5px;
  white-space: nowrap;
}

.dropmenu a:hover {
  background: var(--bs-secondary);
}

.category {
  filter: grayscale(1);
  transition: all .5s ease;
}

.category:hover {
  filter: grayscale(.6);
}

.category.selected {
  filter: grayscale(0);
}
</style>
