<template>
    <div class="d-flex align-items-center mb-8">
                        <span :class="{
                                            'bg-success' : todo.status.match(/DONE/),
                                            'bg-secondary' : todo.status.match(/CANCELLED/),
                                            'bg-warning' : todo.status.match(/EXPIRED/),
                                            'bg-danger' : todo.status.match(/WAITING/)
                                             }"
                              class="bullet bullet-vertical h-40px "></span>
        <div class="flex-grow-1 mx-5">
            <div>
                <router-link :to="{ name : 'contract-show', params: { contractUuid : todo.data.contract.uuid } }"
                             class="text-gray-800 text-hover-primary fw-bolder fs-6"
                             target="_blank">
                    <template v-if="empty(todo.label)">
                        {{trans(todo.type)}}
                    </template>
                    <template v-else>
                        {{todo.label}}
                    </template>
                </router-link>
            </div>
            <div>
                <router-link :to="{ name:  'customer-show' }">
                    {{todo.data.customer.name}}
                </router-link>
            </div>
            <span :title="readableDate(todo.deadline)" class="text-muted fw-bold d-block" v-b-tooltip.hover>
                               {{trans('Expire')}} {{timelapse(todo.deadline)}}
                            </span>
        </div>
        <div>
            <div>
                <span class="badge badge-light-success fs-8 fw-bolder">{{todo.status}}</span>
            </div>

            <div class="d-flex justify-content-end align-items-center mt-1" v-if="todo.status.match(/WAITING|EXPIRED/)">
                <div @click="cancelTask(todo)" class="btn btn-danger btn-icon btn-xs">
                    <i class="fad fa-ban"></i>
                </div>
                <div @click="validateTask(todo)" class="btn btn-icon btn-success btn-xs ms-1">
                    <i class="fad fa-check"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Todo from "@/entity/Todo";

    @Component({})
    export default class ManageAvenant extends Vue {
        @Prop() todo!: Todo

        validateTask(todo: Todo) {
            this.$emit('validateTask', todo)
        }

        cancelTask(todo: Todo) {
            this.$emit('cancelTask', todo)
        }
    }
</script>
<style scoped>
</style>
