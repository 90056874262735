/* eslint-disabled */

export default class TranslatableExtend {


    constructor(object?: any) {
    }

    postConstruct() {
    }


}
