<template>
    <Base>
    <template v-slot:toolbar>
        <div class="toolbar" id="kt_toolbar">
            <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
                <div class="d-flex align-items-center me-3">
                    <slot name="leftToolbar">
                        <span class="h-20px border-gray-200 border-start mx-4"></span>
                        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                            <li class="breadcrumb-item text-muted">
                                <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                                    <i class="fad fa-home"></i>
                                    {{ trans('Survey_list') }}
                                </router-link>
                            </li>
                        </ul>
                    </slot>
                </div>


                <div class="d-flex align-items-center py-1">

                    <div class="">
                        <span :title="'Réinitialiser la recherche'" @click="resetSearch"
                            class="btn btn-icon btn-light-danger btn-xs me-3 justify-content-center" v-b-tooltip.hover>
                            <i class="fad fa-trash fw-bolder cursor-pointer"></i>
                        </span>
                        <!--        <span class="me-3" v-if="$store.state.filter.promotion && promo">-->
                        <!--            {{$store.state.filter.promotion.title}} ◾-->
                        <!--        </span>-->
                        <b-dropdown no-caret no-flip ref="filter" right size="sm" style="padding:0" tag="div"
                            toggle-class="topbar-item text-decoration-none" variant="link">
                            <template v-slot:button-content>
                                <div class="cursor-pointer btn btn-secondary btn-sm me-3">
                                    <!--                    <span class="badge badge-danger me-2" v-if="count > 0">{{count}}</span>-->
                                    <i class="fad fa-search me-2"></i>
                                    <!--                    <template v-if="dateInterval">-->
                                    <!--                        {{readableDate($store.state.filter.dateMin,false,true,false)}} - -->
                                    <!--                        {{readableDate($store.state.filter.dateMax,false,true,false)}}-->
                                    <!--                    </template>-->
                                    <template>Filtrer</template>
                                </div>
                            </template>
                            <b-dropdown-text class="min-w-500px" tag="div">
                                <div class="row">
                                    <div class="col-auto flex-grow-1">
                                        <label class="my-2" for="">{{ trans('Branche') }}</label>
                                        <b-form-select class="form-control  form-select" v-model="search.branch">
                                            <b-form-select-option :value="null">{{ trans('Choisir une branche') }}
                                            </b-form-select-option>
                                            <!--                                                <template v-for="category in categories">-->
                                            <!--                                                    <b-form-select-option :key="branch.uuid" :value="branch.slug"-->
                                            <!--                                                                          v-for="branch,key in category.branches">-->
                                            <!--                                                        {{category.label}} - {{trans(branch.label)}}-->
                                            <!--                                                    </b-form-select-option>-->
                                            <!--                                                </template>-->
                                        </b-form-select>
                                    </div>
                                    <div class="col-auto flex-grow-1">
                                        <label class="my-2" for="">{{ trans('Contenant') }}</label>
                                        <input class="form-control" type="text" v-model="search.contains">
                                    </div>
                                    <div class="col-auto flex-grow-1">
                                        <label class="my-2" for="">{{ trans('Dernière modification après') }}</label>
                                        <input class="form-control" type="date" v-model="search.after">
                                    </div>
                                    <div class="col-auto flex-grow-1">
                                        <label class="my-2" for="">{{ trans('Dernière modification avant') }}</label>
                                        <input class="form-control" type="date" v-model="search.before">
                                    </div>
                                    <div class="col-auto flex-grow-1">
                                        <label class="my-2" for="">{{ trans('Niveau de complétion') }} (%)</label>
                                        <div class="d-flex">

                                            <input-number :max="100" :min="0" :value="0" v-model="search.minimalState">
                                            </input-number>
                                        </div>
                                    </div>
                                    <div class="col-auto flex-grow-1 d-flex flex-column">
                                        <label class="my-2" for="">{{ trans('Contient les infos client') }}</label>
                                        <div class="d-flex align-items-center flex-grow-1">
                                            <div class="form-check form-switch form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="search.hasClientInfos" value="" />
                                                <!--                <label class="form-check-label" for="flexSwitchDefault">-->
                                                <!--                </label>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end mt-5">
                                    <button @click="resetSearch"
                                        class="btn btn-sm btn-white btn-active-light-primary me-2" type="reset">
                                        Réinitialiser
                                    </button>
                                    <button @click="searchRgs" class="btn btn-sm btn-primary" type="submit">
                                        Rechercher
                                    </button>
                                </div>
                            </b-dropdown-text>
                        </b-dropdown>
                    </div>

                </div>

            </div>
        </div>
    </template>
    <template v-slot:content>

        <div class="row g-5 g-xl-8">
            <div class="col-xl-3">
                <!--begin::Statistics Widget 5-->
                <a class="card bg-danger hoverable card-xl-stretch mb-xl-8" href="#">
                    <!--begin::Body-->
                    <div class="card-body">
                        <!--begin::Svg Icon | path: icons/stockholm/Shopping/Cart3.svg-->
                        <div class="d-flex align-items-center">
                            <span class="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <i class="fad fa-balance-scale-left fs-3x text-white"></i>
                            </span>
                            <!--end::Svg Icon-->
                            <div class="text-inverse-danger fw-bolder fs-2 mb-2 mt-5 ms-5">{{ trans('Cotation') }}</div>
                        </div>
                        <div class="fw-bold text-inverse-danger fs-2 fw-bolder">
                            <div :data-kt-indicator="gettingCount ? 'on' : 'off'"
                                class="fw-bolder text-inverse-primary fs-5">
                                {{ helper.numberFormat(cotationCount) }}
                                <span>
                                    Nouvelles cotations
                                </span>
                                <span class="indicator-progress">
                                    <span class="spinner-border spinner-border-sm align-middle ms-2">
                                    </span>
                                </span>
                            </div>

                            <div :data-kt-indicator="gettingCount ? 'on' : 'off'"
                                class="fw-bolder text-inverse-primary fs-5">
                                {{ helper.numberFormat(cotationCount) }}
                                <span>
                                    Cotations terminées
                                </span>
                                <span class="indicator-progress">
                                    <span class="spinner-border spinner-border-sm align-middle ms-2">
                                    </span>
                                </span>
                            </div>

                            <div :data-kt-indicator="gettingCount ? 'on' : 'off'"
                                class="fw-bolder text-inverse-primary fs-5">
                                {{ helper.numberFormat(cotationCount) }}
                                <span>
                                    Prospects identifiés
                                </span>
                                <span class="indicator-progress">
                                    <span class="spinner-border spinner-border-sm align-middle ms-2">
                                    </span>
                                </span>
                            </div>

                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">
                <!--begin::Statistics Widget 5-->
                <a class="card bg-primary hoverable card-xl-stretch mb-xl-8" href="#">
                    <!--begin::Body-->
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <!--begin::Svg Icon | path: icons/stockholm/Home/Building.svg-->
                            <span class="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <i class="fad fa-file-signature fs-3x text-white"></i>
                            </span>
                            <!--end::Svg Icon-->
                            <div class="text-inverse-primary fw-bolder fs-2 mb-2 mt-5 ms-5">
                                Souscriptions
                            </div>
                        </div>
                        <div :data-kt-indicator="gettingCount ? 'on' : 'off'"
                            class="fw-bolder text-inverse-primary fs-5">
                            {{ helper.numberFormat(cotationCompleted) }}
                            <span>
                                Comparateur
                            </span>
                            <span class="indicator-progress">
                                <span class="spinner-border spinner-border-sm align-middle ms-2">
                                </span>
                            </span>
                        </div>

                        <div :data-kt-indicator="gettingCount ? 'on' : 'off'"
                            class="fw-bolder text-inverse-primary fs-5">
                            {{ helper.numberFormat(cotationCompleted) }}
                            <span>
                                Apporteur
                            </span>
                            <span class="indicator-progress">
                                <span class="spinner-border spinner-border-sm align-middle ms-2">
                                </span>
                            </span>
                        </div>

                        <div :data-kt-indicator="gettingCount ? 'on' : 'off'"
                            class="fw-bolder text-inverse-primary fs-5">
                            {{ helper.numberFormat(cotationCompleted) }}
                            <span>
                                Interne
                            </span>
                            <span class="indicator-progress">
                                <span class="spinner-border spinner-border-sm align-middle ms-2">
                                </span>
                            </span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
            <div class="col-xl-3">
                <!--begin::Statistics Widget 5-->
                <a class="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8" href="#">
                    <!--begin::Body-->
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <!--begin::Svg Icon | path: icons/stockholm/Home/Building.svg-->
                            <span class="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <i class="fad fa-arrow-alt-up fs-3x text-white"></i>
                            </span>
                            <!--end::Svg Icon-->
                            <div class="text-inverse-primary fw-bolder fs-2 mb-2 mt-5 ms-5">
                                Production
                            </div>
                        </div>
                        <div :data-kt-indicator="gettingCount ? 'on' : 'off'"
                            class="fw-bolder text-inverse-primary fs-5">
                            <div>
                                Emission : {{ helper.currencyFormat(1000000) }}
                            </div>
                            <div>
                                Encaissement : {{ helper.currencyFormat(1000000) }}
                            </div>
                            <div>
                                Reversé : {{ helper.currencyFormat(1000000) }}
                            </div>

                            <span class="indicator-progress">
                                <span class="spinner-border spinner-border-sm align-middle ms-2">
                                </span>
                            </span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>

            <div class="col-xl-3">
                <!--begin::Statistics Widget 5-->
                <a class="card bg-dark hoverable card-xl-stretch mb-5 mb-xl-8" href="#">
                    <!--begin::Body-->
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <!--begin::Svg Icon | path: icons/stockholm/Home/Building.svg-->
                            <span class="svg-icon svg-icon-white svg-icon-3x ms-n1">
                            
                                <i class="fad fa-arrow-alt-down fs-3x text-white"></i>
                            </span>
                            <!--end::Svg Icon-->
                            <div class="text-inverse-primary fw-bolder fs-2 mb-2 mt-5 ms-5">
                                Commission
                            </div>
                        </div>
                        <div :data-kt-indicator="gettingCount ? 'on' : 'off'"
                            class="fw-bolder text-inverse-primary fs-5">
                            <div>
                                Dûe : {{ helper.currencyFormat(1000000) }}
                            </div>
                            <div>
                                Encaissé : {{ helper.currencyFormat(1000000) }}
                            </div>
                            

                            <span class="indicator-progress">
                                <span class="spinner-border spinner-border-sm align-middle ms-2">
                                </span>
                            </span>
                        </div>
                    </div>
                    <!--end::Body-->
                </a>
                <!--end::Statistics Widget 5-->
            </div>
        </div>


    </template>
    </Base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import InputNumber from "@/components/InputNumber.vue";
import Popup from "@/entity/Popup";

@Component({
    components: { InputNumber, Base }
})
export default class Statistic extends Vue {
    cotationCount = 0;
    cotationCompleted = 0;
    cotationProcessed = 0;
    contactCount = 0;
    perPage = 5000;
    maxPage = 1;
    search = {
        branch: null,
        after: null,
        before: null,
        contains: null,
        minimalState: 0,
        hasClientInfos: false,
        page: 1,
    }
    page = 1;
    loading = false
    gettingCount = false
    gettingCustomer = false;
    gettingComplete = false;

    resetSearch() {
        this.search = {
            branch: null,
            after: null,
            before: null,
            contains: null,
            minimalState: 0,
            hasClientInfos: false,
            page: 1,
        }
    }

    async searchRgs() {
        const res = await api.post(api.form, 'response/group/search', { search: this.search })
        console.log(res)
    }

    mounted(): void {
        this.loadData()
    }


    async getCount() {
        this.gettingCount = true
        const res = await api.get(api.legacy, 'share/get/count')
        if (res && res.data && res.data.count) {
            this.cotationCount = res.data.count;
            this.maxPage = Math.ceil(this.cotationCount / this.perPage);
        }
        this.gettingCount = false
    }

    async loadData() {
        this.loading = true
        // this.$store.commit('loading')
        this.page = 1;
        this.cotationCompleted = 0;
        this.cotationProcessed = 0;
        this.cotationCount = 0;
        this.getCustomerCount()
        await this.getCount()
        await this.getComplete()
        // this.$store.commit('stopLoading')
        this.loading = false
    }

    async getComplete() {
        this.gettingComplete = true
        const popup = new Popup('Loading', 'LOADING PAGE ' + this.page + ' OF ' + this.maxPage, 'danger', 'fad fa-sync', true)
        // console.log('--------------------LOADING PAGE 1 OF ' + this.maxPage)
        const res = await api.get(api.legacy, 'share/get/complete/' + this.page + '/' + this.perPage);
        popup.hide()
        this.gettingComplete = false
        if (res && res.data && res.data.status === 'success') {
            this.cotationProcessed += res.data.processed
            this.cotationCompleted += res.data.complete
            this.page++;
            if (this.page < this.maxPage) {
                this.getComplete()
            }
        }
    }

    async getCustomerCount() {
        this.gettingCustomer = true
        const popup = new Popup('Loading', 'LOADING CUSTOMER COUNT', 'primary', 'fad fa-sync', true)
        const res = await api.get(api.legacy, 'share/get/identified/customer/count');
        if (res && res.data && res.data.count) {
            this.contactCount = res.data.count
        }
        popup.hide()
        this.gettingCustomer = false
    }
}
</script>
<style scoped>

</style>
