/* eslint-disabled */

import Popup from "@/entity/Popup";
import Account from "@/entity/Account"
import {helper} from "@/services/Helper";

export default class CustomerExtend {
    public name? = '';
    public prename? = '';
    public email = '';
    public civility? = '';
    public fonction? = '';
    public mobile = ''
    public fixe = ''
    public contractValue = 0
    public code = 0
    public phone = '';

    public account!: Account

    constructor(object?: any) {
        if (object) {
            this.account = new Account(object.account)
            this.contractValue = object.contractValue
            this.code = object.code
            this.phone = object.phone
        }
    }

    isIdentified() {
        return !this.empty(this.civility) && !this.empty(this.name) && !this.empty(this.prename) && !this.empty(this.fonction) && !this.empty('mobile')
    }

    validate() {
        if (this.empty(this.civility)) {
            new Popup('Information manquante', 'Veuillez renseigner la civilité')
            return false;
        }
        if (this.empty(this.name)) {
            new Popup('Information manquante', 'Veuillez renseigner le nom')
            return false;
        }
        if (this.empty(this.prename)) {
            new Popup('Information manquante', 'Veuillez renseigner le prénom')
            return false;
        }
        if (this.empty(this.fonction)) {
            new Popup('Information manquante', 'Veuillez renseigner la fonction')
            return false;
        }

        if (this.empty(this.mobile)) {
            new Popup('Information manquante', 'Veuillez renseigner le numéro mobile')
            return false;
        }

        return true
    }

    postConstruct() {
    }

    get identifier() {
        let identifier = '';
        if (this.prename !== 'null' && !helper.empty(this.prename)) {
            identifier += this.prename
        }
        if (this.name !== 'null' && !helper.empty(this.name)) {
            if (!helper.empty(identifier)) {
                identifier += ' '
            }
            identifier += this.name;
        }
        // identifier = this.prename + ' ' + this.name;
        if (identifier.trim() === '') {
            identifier = this.email
        }
        if (identifier === 'null null') {
            identifier = ''
        }
        return identifier
    }

    empty(data: string | undefined): boolean {
        return data === null || data === undefined || data.length === 0
    }

    hasContact() {
        return !this.empty(this.mobile)
    }

    hasEmail() {
        return !this.empty(this.email)
    }
}
