<template>
    <Base>
    <template #content>

        <div class="Extractor flex-grow-1 ">
            <div class="flex-2 d-flex flex-grow-1 justify-content-between">
                <div>
                    <div class="d-flex">
                        <div class="btn btn-primary btn-sm m-2" @click="loadJumiaDeals">
                            <i class="fad fa-cogs"></i>
                            Ratissez les liens Jumia Deals
                        </div>
                        <div class="btn btn-warning btn-sm m-2" @click="loadCoinAfrique">
                            <i class="fad fa-cogs"></i>
                            Ratissez les liens Coin Afrique
                        </div>
                    </div>
                    <div class="d-flex flex-column m-2">
                        <div>
                            Ratissez les pages
                        </div>
                        <div class="d-flex align-items-center">
                            <input type="number" class="form-control" v-model="page">
                            <div class="mx-2">
                                à
                            </div>
                            <input type="number" class="form-control" v-model="maxPage">
                        </div>
                    </div>
                </div>
                <div>
                    <div class="btn btn-primary btn-sm m-2" @click="loadCars">
                        Charger les informations des <template v-if="maxCar > 0">{{ maxCar }}</template> véhicules
                    </div>
                    <div>
                        <download-excel :data="cars" :fields="fields" class="btn btn-primary btn-sm"
                            style="margin:20px auto">
                            Télécharger excel
                        </download-excel>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <table class="table table-striped">
                        <thead>
                            <tr class="fw-bolder">
                                <th>#</th>
                                <th>Marque</th>
                                <th>Modele</th>
                                <th>Année</th>
                                <th>Carburant</th>
                                <th>Transmission</th>
                                <th class="text-end">Kilométrage</th>
                                <th>Publié le</th>
                                <th>Prix</th>
                                <th class="text-end">Contact</th>
                                <th class="text-end">Lien</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="car, index in cars">
                                <td>{{ index + 1 }}</td>
                                <td>{{ car.marque }}</td>
                                <td>{{ car.modele }}</td>
                                <td>{{ car.annee }}</td>
                                <td>{{ car.carburant }}</td>
                                <td>{{ car.transmission }}</td>
                                <td class="text-end">{{ car.kilometrage }}</td>
                                <td>{{ car.date }}</td>
                                <td>{{ car.prix }}</td>
                                <td class="text-end">{{ car.phone }}</td>
                                <td class="text-end">
                                    <a class="btn btn-xs btn-icon btn-primary ms-5" :href="car.link">
                                        <i class="fad fa-external-link"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </template>

    </Base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import cars from "../assets/cars.json";
import { api } from "@/services/Api";
import Popup from '@/entity/Popup';
import Base from "@/layouts/Base.vue";
import { helper } from '@/services/Helper';

@Component({
    components: { Base }
})
export default class Extractor extends Vue {
    fields = {
        'Marque': 'marque',
        'Modèle': 'modele',
        'Année': 'annee',
        'Carburant': 'carburant',
        'Transmission': 'transmission',
        'Kilométrage': 'kilometrage',
        'Prix': 'prix',
        'Publié le': 'date',
        'phone': 'phone',
        'Lien': 'link'
    }
    page = 1
    maxPage = 1396
    root = 'https://deals.jumia.ci/';
    currentIndex = 0
    maxCar = 0

    cars: any[] = []

    pageInterval = 5
    mounted(): void {
        this.loadExist()
    }

    loadJumiaDeals() {
        this.loadPage('jumiaDeals')
    }

    async loadExist() {
        const res = await api.get(api.form, 'crawler/car/list')
        res.data.cars.forEach((c: any) => {
            c.date = helper.readable(c.date)
            c.kilometrage = helper.numberFormat(c.kilometrage.replace('km', ''))
            c.phone = helper.phoneFormat(c.phone)
            c.prix = c.prix.replace('CFA', '')
            this.cars.push(c)
        })
    }
    async loadCars() {
        await this.getCarCount()
        this.cars = []
        await this.loadExist()
        this.loadData()
    }

    async getCarCount() {
        const res = await api.get(api.form, 'crawler/car/count')
        this.maxCar = res.data.count
        // console.log(res)

    }

    loadCoinAfrique() {
        this.loadPage('coinAfrique')
    }

    async loadPage(platform: string) {
        const res = new Popup('Chargement des liens ', ' page ' + this.page + ' à ' + (this.page + this.pageInterval), 'primary', 'fad fa-cogs', true);
        // console.log('loading announce links in page ' + this.page)
        await api.get(api.form, 'crawler/extractor/' + platform + '/' + this.page + '/' + this.pageInterval)
        res.hide()
        // this.page += 5
        this.page += this.pageInterval
        if (this.page < this.maxPage) {
            this.loadPage(platform)
        }
    }

    async loadData() {
        // console.log('loading data ' + this.currentIndex + ' à ' + (this.currentIndex + 5))
        const popup = new Popup('Chargement .. ', ' information vehicule page ' + this.currentIndex + ' à ' + (this.currentIndex + this.pageInterval), 'primary', 'fad fa-cogs', true);
        const res = await api.get(api.form, 'crawler/extractor/data/page/' + this.currentIndex + '/' + this.pageInterval)
        this.currentIndex += this.pageInterval;
        // res.data.cars.forEach((c: any) => {
        //     const match = this.cars.find((car: any) => {
        //         return car.link === c.link
        //     })
        //     if (!match) {
        //         c.date = helper.readable(c.date)
        //         c.kilometrage = helper.numberFormat(c.kilometrage.replace('km', ''))
        //         c.phone = helper.phoneFormat(c.phone)
        //         c.prix = c.prix.replace('CFA', '')
        //         this.cars.push(c)
        //     }
        // })
        popup.hide()
        if (this.currentIndex < this.maxCar) {
            this.loadData()
        }
    }
}
</script>
<style scoped>

</style>
