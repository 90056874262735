/* eslint-disabled */
import Action from "./Action";
import Subscription from "./Subscription";
import CampaignExtend from "@/entity/extends/CampaignExtend";

export default class Campaign extends CampaignExtend {

public id = 0;
public name = '';
public description? = '';
public code = '';
public actions: Array<Action> = [];
public subscription: Array<Subscription> = [];
public mapping? = [];

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.name= object.name;
       this.description= object.description;
       this.code= object.code;
       if(object.actions){
           object.actions.forEach((occ: any)=>{
               const action= occ instanceof Action? occ :  new Action(occ);
               this.actions.push(action);
           });
       }
       if(object.subscription){
           object.subscription.forEach((occ: any)=>{
               const subscription= occ instanceof Subscription? occ :  new Subscription(occ);
               this.subscription.push(subscription);
           });
       }
       this.mapping= object.mapping;
      }
      this.postConstruct()
  }

}
