import {RouteConfig} from "vue-router";
import PartnerHome from "@/views/Partner/PartnerHome.vue";
import PartnerAgenciesList from "@/views/Partner/PartnerAgenciesList.vue";
import PartnerUserList from "@/views/Partner/PartnerUserList.vue";
import PartnerHierarchy from "@/views/Partner/PartnerHierarchy.vue";
import PartnerAgency from "@/views/Partner/PartnerAgency.vue";

const prefix = '/partner'
const partnerRoutes: Array<RouteConfig> = [
    {
        path: '/production',
        name: 'partner-index',
        component: PartnerHome,
        meta: {requiredRoles: ['ROLE_PARTNER']},
    },
    {
        path: '/hierarchy',
        name: 'partner-admin-index',
        component: PartnerHierarchy,
        meta: { requiredRoles: ['ROLE_PARTNER'] },
    },
    {
        path: '/hierarchy',
        name: 'partner-hierarchy',
        component: PartnerHierarchy,
        meta: { requiredRoles: ['ROLE_PARTNER'] },
    },
    {
        path: '/agences',
        name: 'partner-agencies',
        component: PartnerAgenciesList,
        meta: { requiredRoles: ['ROLE_PARTNER'] },
    },
    {
        path: '/agence/:code',
        name: 'partner-agency',
        component: PartnerAgency,
        meta: { requiredRoles: ['ROLE_PARTNER'] },
    },
    {
        path: '/users',
        name: 'partner-admin-users',
        component: PartnerUserList,
        meta: { requiredRoles: ['ROLE_PARTNER'] },
    },

]
partnerRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default partnerRoutes;
