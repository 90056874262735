<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    Tableau de bord
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <date-range-picker ref="picker" :opens="'left'" :locale-data="localData" :singleDatePicker="false"
                               :autoApply="false" :ranges="ranges" @update="load" :timePicker="false"
                               :showWeekNumbers="true"
                               :showDropdowns="true" v-model="range">
              <template v-slot:input="picker" style="min-width: 350px;">
                <template>
                  {{ readableDate(picker.startDate, 'dmY') }} ❖ {{ readableDate(picker.endDate, 'dmY') }}
                </template>
              </template>
            </date-range-picker>
            <div class="d-flex align-items-center">
              <div>
                <button class="btn btn-icon btn-sm btn-info ms-1" @click="printReceipts"
                        v-if="search.start && search.end">
                  <i class="fad fa-print"></i>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>
    <template #content>

      <!-- <div class="d-flex justify-content-end">
        <div class="btn btn-primary my-5" v-if="selectedUuids.length > 0" @click="printReceipts">
          <i class="fad fa-print"></i>
          Imprimer les reçus ({{ selectedUuids.length }})
        </div>
      </div> -->
      <div class="card">
        <div class="card-body ">
          <table class="table table-striped gy-3 gs-2 ">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <!-- <th>
                <div class="form-check form-check-custom  form-check-sm" @change="toggleAll">
                  <input class="form-check-input" type="checkbox">
                </div>
              </th> -->
              <th>{{ trans('Date') }}</th>
              <th>{{ trans('Type') }}</th>
              <th>{{ trans('Label') }}</th>
              <th>{{ trans('Client') }}</th>
              <th>{{ trans('Initiateur') }}</th>
              <th>{{ trans('Source') }}</th>
              <th>{{ trans('Montant') }}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td colspan="9">
                <Loading/>
              </td>
            </tr>
            <tr v-for="payment in payments">
              <!-- <td>
                <RowSelector v-model="selectedUuids" :data="payment.uuid" />
              </td> -->
              <td>
                <template v-if="payment.payedAt">
                  {{ readable(payment.payedAt, 'dMY Hi') }}
                </template>
                <template v-else-if="payment.createdAt">
                  {{ readable(payment.createdAt, 'dMY Hi') }}
                </template>
              </td>
              <td>
                <template v-if="payment.method">
                  {{ payment.method.label }}
                </template>
              </td>
              <td>
                <!--                {{payment.label}}-->
                Paiement de l'avenant N°{{ payment.avenantNumber }} du contrat
                <router-link :to="{ name: 'contract-show', params: { contractUuid: payment.contractUuid } }"
                             class="fw-bolder">
                  {{ payment.contractNumber }}
                </router-link>
              </td>
              <td>
                <router-link :to="{ name: 'customer-show', params: { uuid: payment.customerUuid } }" class="fw-bolder">
                  {{ payment.customerFullname }}
                </router-link>
              </td>
              <td>
                <user-view v-model="payment.initiator"/>
              </td>
              <td>
                <partner-view v-model="payment.partner" v-if="payment.partner"></partner-view>
              </td>
              <td class="fw-bolder fs-2 text-end">
                {{ currency(payment.amount) }}
              </td>
              <td>
                <div class="d-flex">
                  <div class="btn btn-sm btn-icon btn-danger m-1" @click="process(payment)"
                       v-if="hasRole('ROLE_MANAGER')">
                    <i class="fad fa-check-double"></i>
                  </div>
                  <router-link target="_blank" :to="{ name: 'receipt-show', params: { uuid: payment.receipt.uuid } }"
                               class="btn btn-sm btn-icon m-1 btn-primary" v-if="payment.receipt">
                    <i class="fad fa-download"></i>
                  </router-link>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div>

          </div>
        </div>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import User from "@/entity/User";
import FileUpload from "@/components/FileUpload.vue";
import RowSelector from "@/components/RowSelector.vue";
import {api} from "@/services/Api";
import PaymentTransaction from "@/entity/PaymentTransaction";
import CONSTANTS from "@/Utils/constant";
import {helper} from "@/services/Helper";
import UserView from "@/components/UserView.vue";
import Loading from "@/components/Loading.vue";
import PartnerView from "@/components/PartnerView.vue";
import DateRangePicker from 'vue2-daterange-picker';

@Component({
  components: {PartnerView, UserView, FileUpload, Base, Loading, RowSelector, DateRangePicker}
})
export default class PaymentList extends Vue {

  search = {
    start: CONSTANTS.dateConstants.first_day_current_month(),
    end: CONSTANTS.dateConstants.last_day_current_month()
  }
  range = {
    startDate: CONSTANTS.dateConstants.first_day_current_month(),
    endDate: CONSTANTS.dateConstants.last_day_current_month()
  };

  localData = {
    direction: 'ltr',
    format: 'mm/dd/yyyy',
    separator: ' - ',
    applyLabel: 'Valider',
    cancelLabel: 'Annuler',
    weekLabel: ' ',
    customRangeLabel: 'Custom Range',
    daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    firstDay: 0
  }
  ranges: any = false;
  selectedUuids: string[] = []
  payments: PaymentTransaction[] = []
  loading = false

  loadRanges() {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0);


    this.ranges = {
      "Aujourdhui": [today, today],
      'Hier': [yesterday, yesterday],
      'Ce mois': [CONSTANTS.dateConstants.first_day_current_month(), CONSTANTS.dateConstants.last_day_current_month()],
      'Le mois dernier': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      "Cette année": [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
      "L'année passée": [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)],
    }
    this.$forceUpdate()
  }

  async printReceipts() {
    this.$store.commit('loading')
    const range = {start: this.range.startDate, end: this.range.endDate}
    // console.log(range)
    // return
    const res = await api.post(api.core, 'payment/receipts', {
      range: {
        start: this.range.startDate,
        end: this.range.endDate
      }
    }, {
      responseType: "blob",
    })

    // console.log(res);
    // return;
    // console.log(Object.keys(res.headers))
    // return
    const blob = new Blob([res.data], {type: res.headers['content-type'] as any});
    let url = window.URL || window.webkitURL;
    let link = url.createObjectURL(blob);
    let a = document.createElement("a");
    var title = helper.readable(this.range.startDate) + '_au_' + helper.readable(this.range.endDate)
    a.setAttribute("download", 'Recu_du_' + helper.slugify(title));
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.$store.commit('stopLoading')

  }

  toggleAll() {
    if (this.selectedUuids.length == 0) {
      this.selectedUuids = []
      this.payments.forEach((p) => {
        this.selectedUuids.push(p.uuid!)
      })
    } else {
      this.selectedUuids = []
    }
    this.$forceUpdate()
  }


  async print() {

  }

  mounted() {
    this.load()
    this.loadRanges()
  }

  async process(payment: PaymentTransaction) {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'payment/process/' + payment.uuid)
    this.$store.commit('stopLoading')
  }

  async load() {
    this.payments = []
    this.loading = true
    const start = helper.dateToHtml(this.range.startDate)
    const end = helper.dateToHtml(this.range.endDate)
    const res = await api.get(api.core, 'payment/list/' + start + '/' + end)
    if (res && res.data) {

      res.data.payments.forEach((p: any) => {
        this.payments.push(new PaymentTransaction(p))
      })
    }
    this.loading = false
  }

  create() {

  }

  edit() {

  }

  save() {

  }
}
</script>
<style scoped></style>
