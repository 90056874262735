<template>
    <div class="flex-grow-1 m-2" :key="'question_'+question.uuid" v-if="question.isAuthorized()">
        <label for="" class="fw-bolder my-2 d-flex justify-content-between">
            <span v-if="question.visibility !== 'PUBLIC'" class="cursor-pointer" v-b-tooltip.hover :title="question.visibility">
                <i class="fad fa-lock text-danger"></i>
            </span>
            {{question.label}}
                        <span @click="$store.commit('resetResponse',question)" class="btn btn-xs btn-icon" v-if="hasRole('ROLE_MANAGER')"><i class="fad fa-trash text-danger"></i></span>
        </label>
        <b-input-group>
            <b-input-group-prepend v-if="question.prefix">
                <div class="input-group-text flex-grow-1 h-100">
                    <template v-if=" isIcon(question.prefix)">
                        <i :class="question.prefix"></i>
                    </template>
                    <template v-else>
                        {{ question.prefix }}
                    </template>
                </div>
            </b-input-group-prepend>
            <div class="input-container flex-grow-1 rounded  d-flex">
                <input :regex="question.regex" type="tel" @input="throttle()"
                       @blur="question.updateAnswer()" @key.enter="question.updateAnswer()" class="form-control"
                       :class="{ 'rounded-left-0' : question.prefix, 'rounded-right-0' : question.suffix }"
                       v-model.trim="question.response.value">
                <span :title="trans('Mise à jour en cours')" class="input-status" v-b-tooltip.hover  v-if="question.isSending">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
                <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="!question.isValid">
                    <i class="fad fa-exclamation-circle text-danger"></i>
                </span>
                <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="question.isValid">
                    <i class="fad fa-check-circle text-success"></i>
                </span>
            </div>
            <b-input-group-append v-if="question.suffix">
                {{question.suffix}}
            </b-input-group-append>
        </b-input-group>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Question from "@/entity/Question";

    @Component({})
    export default class Contact extends Vue {
        @Prop() question!: Question

        isIcon(text: string) {
            return text.match(/fa |fab |fad |fal |fas /) !== null
        }

        to: any = null
    throttle() {
        clearTimeout(this.to);
        this.to = setTimeout(() => {
            clearTimeout(this.to)
            this.question.updateAnswer()
        }, 5000)
    }

    }
</script>
<style scoped>
    .input-container {
        position: relative;
    }

    .input-status {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
    }

</style>
