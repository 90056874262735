<template>
    <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
            <div class="d-flex align-items-center me-3">
                <slot name="leftToolbar">
                    <span class="h-20px border-gray-200 border-start mx-4"></span>
                    <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                        <li class="breadcrumb-item text-muted">
                            <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                                <i class="fad fa-home"></i>
                                Tableau de bord
                            </router-link>
                        </li>
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-200 w-5px h-2px"></span>
                        </li>
                        <!--                    <li class="breadcrumb-item text-muted">-->
                        <!--                        <a class="text-muted text-hover-primary">-->
                        <!--                            <i class="fad fa-list m-1"></i>-->
                        <!--                            {{ Ktrans('Pack_list') }}-->
                        <!--                        </a>-->
                        <!--                    </li>-->
                    </ul>
                </slot>
            </div>


            <div class="d-flex align-items-center py-1">
                <slot name="rightToolbar">
                </slot>
            </div>

        </div>
    </div>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';

    @Component({})
    export default class Toolbar extends Vue {
    }
</script>
<style scoped>
</style>
