import { render, staticRenderFns } from "./LoadingLogo.vue?vue&type=template&id=cebad64a&scoped=true"
import script from "./LoadingLogo.vue?vue&type=script&lang=ts"
export * from "./LoadingLogo.vue?vue&type=script&lang=ts"
import style0 from "./LoadingLogo.vue?vue&type=style&index=0&id=cebad64a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cebad64a",
  null
  
)

export default component.exports