<template>
  <div :key="uKey" class="item-manager my-5">
    <!--        ITEM {{item.name}}<template v-if="parent">>> Parent {{parent.name}}</template><template v-else> NO PARENT</template>-->
    <div class="d-flex justify-content-between bg-hover-light p-3">
      <div v-if="(item.model.className === 'Asset' || item.model.isPackApplier) && !item.model.method">
        <b-dropdown variant="link" no-caret no-flip right>
          <template v-slot:button-content>
            <div class="btn btn-light-danger ms-2 btn-sm btn-icon">
              <i class="fad fa-exclamation-triangle fs-2"></i>
            </div>
          </template>
          <b-dropdown-text class="min-w-md-300px p-5" tag="div">
            <p class="fs-6 text-muted fw-bolder">
              Cet objet
              <template v-if="item.model.className === 'Asset'">
                est un actif
              </template>
              <template v-else>
                défini un pack
              </template>
              mais ne possède aucune methode afin d'en calculer la prime.
              <br>
              Afin d'afficher le bouton Comparer définissez une méthode pour l'objet {{ item.model.name }}
              dans le gestionnaire d'objet
              <br>
              <br>
              <router-link :to="{ name: 'item-list', params: { slug: $route.params.branchSlug } }"
                           class=" align-items-center py-1" target="_blank">
                <button class="btn btn-primary btn-sm">
                  <i class="fad fa-external-link"></i>
                  Objets
                </button>
              </router-link>
            </p>
          </b-dropdown-text>
        </b-dropdown>
      </div>
      <label :class="{ 'text-primary': !item.expand }"
             class="d-flex flex-grow-1 align-items-center text-hover-primary cursor-pointer fs-3 fw-bolder"
             v-if="!item.model.isRoot">
        <input class="hidden" type="checkbox" v-model="item.expand">
        <div class="w-30px d-flex justify-content-center">
          <i class="fad fa-angle-down fs-3 cursor-pointer  text-hover-primary" v-if="item.expand"></i>
          <i class="fad fa-angle-right fs-3 cursor-pointer text-primary" v-else></i>
        </div>
        <div class="d-flex align-items-center">
                    <span class="me-3" style="white-space: nowrap">
                        <!--                        <template v-if="fromProp && fromProp.model.type.code === 'OBJECT_GROUP'">-->
                      <!--                            {{item.model.name}}-->
                      <!--                        </template>-->
                      <!--                        <template v-else>-->
                        {{ alias ? alias : item.model.name }}
                      <!--                        </template>-->

                    </span>
          <div class="whitespace-no-wrap me-5" v-if="item.generatedAlias !== item.defaultAlias">
            {{ item.generatedAlias }}
          </div>
          <template v-if="!item.model.required && !item.model.isRoot">
            <input class="form-control form-control-solid" style="border:1px dashed gainsboro" type="text"
                   v-model="item.defaultAlias">
          </template>
        </div>
      </label>

      <div v-if="item.model.asaciWorkflow === 'attestation_digitale_automobile'">
        <label
            class="form-check form-switch form-check-custom form-check-solid d-flex justify-content-end align-items-center">
          <span class=" form-label m-0 me-2">{{ trans('Demander une attestation digitale ASACI') }}</span>
          <input class="form-check-input me-2" v-model="sendToAsaci" @change="toggleAsaciRequest($event)"
                 type="checkbox" value=""/>
        </label>
      </div>

      <div>
        <div class="" v-if="item.model.code === 'souscripteur'">
          <b-input-group class="rounded align-items-center">
            <b-input-group-prepend>
              Rechercher un client
            </b-input-group-prepend>
            <div class="input-container flex-grow-1 rounded mx-2">
              <input class="form-control" v-model="client">
            </div>
            <b-input-group-append>
              <b-dropdown class="p-0 z-index-2" dropdown no-caret no-flip ref="filter" right size="sm" tag="div"
                          toggle-class="topbar-item text-decoration-none" variant="link">
                <template v-slot:button-content>
                  <div class="btn btn-primary btn-icon btn-sm" @click="searchClient">
                    <i class="fad fa-search"></i>
                  </div>
                </template>
                <div class="mh-350px overflow-auto">
                  <template v-for="customer in customers">
                    <b-dd-text>
                      <div
                          class="py-2 px-3 d-flex justify-content-between align-items-center bg-hover-light-primary whitespace-no-wrap">
                        <div>
                          <div>
                            {{ customer.identifier }}
                          </div>
                          <div>
                            {{ customer.mobile }} - {{ customer.fixe }}
                          </div>
                          <div class="fw-bolder">
                            {{ customer.email }}
                          </div>
                        </div>
                        <div class="btn btn-primary btn-icon btn-xs ms-5" @click="selectClient(customer)">
                          <i class="fad fa-check"></i>
                        </div>
                        <router-link :to="{name : 'customer-show', params : { uuid: customer.uuid}}" target="_blank"
                                     class="btn btn-secondary btn-icon btn-xs ms-2">
                          <i :title="'Visualiser'" class="fad fa-eye"></i>
                        </router-link>
                      </div>
                    </b-dd-text>
                  </template>
                </div>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>
        </div>


        <div @click="removeItem" class="btn btn-icon btn-light-danger btn-xs"
             v-if="!item.model.required && !item.model.isRoot && allowRemove">
          <i class="fad fa-trash"></i>
        </div>
      </div>
    </div>


    <div :class="{ 'card card-stretch mt-5': !isContainer }" class="shadow-none" v-if="item.expand">
      <div :class="{ 'p-0': item.model.isRoot }" class="card-body">

        <div v-if="knownCustomer && customer" class="d-flex justify-content-between align-items-center">
          <div>
            <div class="text-gray-400">
              {{ customer.uuid }}
            </div>
            <div class="fs-2 fw-bolder">
              {{ customer.identifier }}
            </div>
            <div>
              {{ customer.mobile }} - {{ customer.fixe }}
            </div>
            <div class="fs-2 fw-bolder">
              {{ customer.email }}
            </div>
          </div>
          <div>
            <div class="btn btn-danger btn-icon" @click="removeCustomer">
              <i class="fad fa-times"></i>
            </div>
          </div>
        </div>

        <div class="overflow-auto d-flex flex-wrap align-items-center" v-else>
          <template v-for="prop, index in item.properties">
            <template v-if="prop.isVisibleIn(items)">
              <!-- <template -->
              <!-- v-if="prop.model.type.code !== 'PACK' && !(item.model.code === 'avenant' && prop.model.code === 'prime_ttc')"> -->
              <PropertyManager :branch="branch" :insurers="insurers" :items="items"
                               :show-children-pack-selector="showChildrenPackSelector"
                               :insurer-restriction="insurerRestriction" :remove-unallowed-pack="true"
                               :key="item.uuid + '_' + prop.model.uuid" :optionalSelected="optionalSelected"
                               :packCategories="packCategories" :packFromParent="setChildPack" :packs="packs"
                               :parent="item" :parentPack="item.pack" :premiumDetailModels="premiumDetailModels"
                               :prop="prop" v-on:appendItem="appendItem" v-on:assetAdded="assetAdded"
                               v-on:update="handleUpdate"></PropertyManager>
            </template>
          </template>
        </div>
        <template
            v-if="(item.model.className === 'Asset' || item.model.isPackApplier) && item.model.method && showPackSelector">
          <div v-if="unallowedPack" class="bg-light-danger border-danger p-5 my-5 fw-bolder fs-2 text-danger">
            <i class="fad fa-exclamation-triangle fs-2 text-danger"></i>
            Selectionnez une autre offre
          </div>
          <div class="w-100 text-center" v-if="!comparePack"
               :class="{ 'card': isContainer || item.model.isRoot }">
            <div class="d-flex pb-10" v-if="item.pack"
                 :class="{ 'card-body': isContainer || item.model.isRoot }">
              <div class="bg-light-primary border-dashed w-100 p-5 text-primary fw-bolder rounded">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-start">
                                        <span>
                                            {{ item.model.name }} {{ item.generatedAlias }}
                                        </span>
                    <br>
                    <span class="fs-2">
                                            {{ item.pack.insurer.label }} ▪ {{ item.pack.label }}
                                        </span>
                  </div>
                  <div>
                                        <span>
                                            Prime Nette
                                        </span>
                    <br>
                    <span class="fs-2">
                                            {{ helper.currencyFormat(item.premium) }}
                                        </span>

                  </div>
                  <div>
                    <label @click.stop="compare" class="btn btn-danger">
                      <input class="hidden" type="checkbox" v-model="comparePack">
                      <i class="fad fa-balance-scale-left"></i>
                      Modifier
                    </label>
                  </div>
                </div>
                <div>
                  <div class="d-flex flex-wrap pt-5">
                    <div :class="{ 'badge-primary': !guarantee.isOptional, 'badge-success': guarantee.isOptional }"
                         class="badge m-1" v-for="guarantee in item.guarantees">
                      {{ guarantee.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card">
              <div class="card-body">
                <label @click.stop="compare" class="btn btn-danger">
                  <input class="hidden" type="checkbox" v-model="comparePack">
                  <i class="fad fa-balance-scale-left"></i>
                  Comparer les offres pour {{ item.model.name }} {{ item.generatedAlias }}
                </label>
              </div>
            </div>
          </div>
          <div class="w-100 offers" v-else>
            <div class="card">
              <div class="card-body d-flex justify-content-between align-items-center">
                <h2 class="mb-0">Selectionnez une offre pour {{ item.model.name }} <span
                    class="text-primary">{{ item.generatedAlias }}</span></h2>

                <div>
                  <div class="btn btn-icon btn-primary btn-xs mx-2" @click="resetOptional(item)">
                    <i class="fad fa-sync"></i>
                  </div>
                  <label class="btn btn-icon btn-danger btn-xs">
                    <input class="hidden" type="checkbox" v-model="comparePack">
                    <i class="fad fa-times"></i>
                  </label>

                </div>
              </div>
            </div>
            <div :key="'calcul_' + calculUuid" class="overflow-auto">
              <b-tabs active-nav-item-class="bg-primary text-white" active-tab-class="p-5"
                      nav-wrapper-class="bg-white rounded">
                <b-tab :title="'Garanties'" :title-link-class="''">
                  <div class="d-flex w-100 flex-grow-0">
                    <div style="position: sticky;left:0;top:0;background: white;z-index: 2">
                      <table class="table-striped gy-2 gs-3 guarantee">
                        <thead>
                        <tr>
                          <th>
                            Garanties
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template
                            v-for="guarantee, index in $store.state.engineBranchGuarantees">
                          <template v-if="onePackHasGuarantee(guarantee)">
                            <tr>
                              <td class="p-2" style="max-width:200px">
                                <div>
                                  {{ guarantee.label }}
                                </div>
                              </td>
                            </tr>
                          </template>
                        </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="results-container overflow-auto" style="flex:2;">
                      <div class="w-100 overflow-auto position-absolute" style="top:0;left:0">
                        <table class="table-striped gy-2 gs-3 ms-5 results">
                          <thead>
                          <tr>
                            <template v-for="result in results">
                              <th class="text-center">
                                <div>
                                  <div class=" px-5 text-center">
                                    <div class="fw-bolder fs-2">
                                      <div class="">
                                        {{ result.pack.insurer.label }}
                                      </div>
                                      <div class="pb-1 fw-bolder" style="white-space: nowrap">
                                        <img :src="uploadRoot + result.pack.insurer.photo"
                                             alt="" class="mh-50px"
                                             v-if="result.pack.insurer.photo">
                                        <template v-else>
                                          {{ result.pack.insurer.label }}
                                        </template>
                                      </div>
                                      <div>
                                        {{ result.pack.label }}
                                      </div>
                                    </div>
                                    <div>
                                      <div class=" text-primary fw-bolder">
                                                                                    <span class="fs-2x">
                                                                                        {{
                                                                                        helper.currencyFormat(getPackPremium(result))
                                                                                      }}
                                                                                    </span>
                                        <br>
                                        <span>
                                                                                        {{
                                            getDuree() ? ' / ' +
                                                getDuree()
                                                : ''
                                          }}
                                                                                    </span>
                                      </div>
                                      <div>
                                        <div @click="clickOffer(result)"
                                             class="btn bt-sm btn-primary mb-5">
                                          Souscrire
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </th>
                            </template>
                          </tr>
                          </thead>
                          <tbody>
                          <template
                              v-for="guarantee, index in $store.state.engineBranchGuarantees">
                            <tr v-if="onePackHasGuarantee(guarantee)">
                              <template v-for="result, index in results">
                                <td :key="result.pack.uuid + '-' + guarantee.id"
                                    class="p-2 text-center" valign="middle">
                                  <template
                                      v-if="result.pack.hasGuarantee(guarantee)">
                                    <template
                                        v-for="pg in result.pack.packGuarantees">
                                      <div
                                          v-if="pg.guarantee.id === guarantee.id">
                                        <template v-if="pg">
                                          <template v-if="!pg.isOptional">
                                            <i
                                                class="fad fa-shield-check text-success fs-2"></i>
                                          </template>
                                          <template
                                              v-else-if="guarantee.method">
                                            <template
                                                v-if="getOptionalPremium(result, guarantee)">
                                              <template
                                                  v-for="optional in result.optionals">
                                                <div class="d-flex justify-content-center align-items-center w-100"
                                                     v-if="optional.code === guarantee.method.code">
                                                  <!--                                                                                    <label for="">-->
                                                  <!--                                                                                        {{optional.append}}-->
                                                  <label
                                                      class="fw-bolder cursor-pointer text-primary text-decoration-underline"
                                                      v-if="!pg.selected">
                                                    <input
                                                        :key="helper.generateId()"
                                                        @change="toggleOptional(result, pg)"
                                                        class="hidden"
                                                        type="checkbox"
                                                        v-model="pg.selected">
                                                    <i
                                                        class="fad fa-plus-circle text-primary"></i>
                                                    {{
                                                      helper.currencyFormat(optional.premium)
                                                    }}
                                                  </label>
                                                  <div class="d-flex w-100 justify-content-center position-relative"
                                                       v-else>
                                                    <i
                                                        class="fad fa-shield-check text-success fs-2"></i>
                                                    <label
                                                        class="fw-bolder cursor-pointer text-primary text-decoration-underline position-absolute
                                                                                        d-flex align-items-center"
                                                        style="right:0;height:100%;">
                                                      <input
                                                          :key="pg.guarantee.id + '-' + result.pack.uuid"
                                                          @change="toggleOptional(result, pg)"
                                                          class="hidden"
                                                          type="checkbox"
                                                          v-model="pg.selected">
                                                      <i
                                                          class="fad fa-times-circle cursor-pointer text-danger float-end"></i>
                                                    </label>
                                                  </div>
                                                  <!--                                                                                    </label>-->
                                                </div>
                                              </template>
                                            </template>
                                            <template v-else>
                                              <div
                                                  class="d-flex w-100 justify-content-center position-relative">
                                                <i class="fad cursor-pointer fa-exclamation-circle text-warning fs-2"
                                                   title="Les conditions requises ne sont pas remplie"></i>

                                                <label class="fw-bolder cursor-pointer text-primary text-decoration-underline position-absolute
                                                                                        d-flex align-items-center"
                                                       style="right:0;height:100%;"
                                                       v-if="pg.selected">
                                                  <input
                                                      :key="helper.generateId()"
                                                      @change="toggleOptional(result, pg)"
                                                      class="hidden"
                                                      type="checkbox"
                                                      v-model="pg.selected">
                                                  <i
                                                      class="fad fa-times-circle cursor-pointer text-danger float-end"></i>
                                                </label>
                                              </div>
                                            </template>
                                          </template>
                                          <template v-else>
                                            <i class="fad cursor-pointer fa-exclamation-circle text-warning fs-2"
                                               title="Methode de calcul manquante"></i>
                                          </template>
                                        </template>
                                        <template v-else>
                                          <i
                                              class="fad fa-times-circle fs-2 text-gray-400"></i>
                                        </template>
                                      </div>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <i class="fad fa-times-circle fs-2 text-gray-400"
                                       title="Garantie non couverte"></i>
                                  </template>
                                  <!--                                                                <div :set="pg = result.pack.hasGuarantee(guarantee)" class="text-center d-flex justify-content-center">-->
                                  <!--                                                                </div>-->
                                </td>
                              </template>
                            </tr>
                          </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab :title="'Details'" :title-link-class="''">
                  <table>
                    <thead>
                    <tr>
                      <template v-for="result in results">
                        <th class="text-center">
                          <div>
                            <div
                                class="d-flex flex-column justify-content-center px-5 text-center">
                              <div class="fw-bolder fs-2">
                                <div class="pb-1 fw-bolder"
                                     style="white-space: nowrap">
                                  <img :src="uploadRoot + result.pack.insurer.photo"
                                       alt="" class="mh-50px"
                                       v-if="result.pack.insurer.photo">
                                  <template v-else>
                                    {{ result.pack.insurer.label }}
                                  </template>
                                </div>
                                <div>
                                  {{ result.pack.label }}
                                </div>
                              </div>
                              <div class=" text-primary fw-bolder">
                                                                    <span class="fs-2x">
                                                                        {{ helper.currencyFormat(result.premium) }}
                                                                    </span>
                                <br>
                                <span>
                                                                        {{ getDuree() ? ' / ' + getDuree() : '' }}
                                                                    </span>
                              </div>
                            </div>
                          </div>
                        </th>
                      </template>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="background:transparent">
                      <template v-for="result, rindex in results">
                        <td :key="uKey + '_' + result.pack.uuid + '_' + rindex"
                            style="border-right: 20px white solid;background: transparent"
                            valign="top">
                          <div></div>
                          <div class="mh-500px overflow-auto">
                            <table class="table  gs-5 gy-2">
                              <thead></thead>
                              <tbody>
                              <template v-for="detail, index in result.details">
                                <tr
                                    :key="uKey + '_' + result.pack.uuid + '_' + index">
                                  <td>
                                    {{ detail.name }}
                                  </td>
                                  <td>
                                    =
                                  </td>
                                  <td class="text-end">
                                    <transition mode="out-in"
                                                name="slide-left">
                                      <div
                                          :key="'detail_' + detail.value ? helper.slugify(detail.value) : helper.generateId()"
                                          class="fw-bolder cotation-detail">
                                        {{
                                          helper.currencyFormat(detail.value,
                                              false, false)
                                        }}
                                      </div>
                                    </transition>
                                  </td>
                                </tr>
                              </template>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </template>
                    </tr>
                    </tbody>
                  </table>
                </b-tab>
                <b-tab :title="'Debugger'" :title-link-class="''">
                  <table>
                    <thead>
                    <tr>
                      <template v-for="result in debugResults">
                        <th class="text-center">
                          <div>
                            <div
                                class="d-flex flex-column justify-content-center px-5 text-center">
                              <div class="fw-bolder fs-2">
                                <div class="pb-1 fw-bolder"
                                     style="white-space: nowrap">
                                  {{ result.pack.insurer.label }}
                                </div>
                                <div>
                                  {{ result.pack.label }}
                                </div>
                              </div>
                              <div class=" text-primary fw-bolder">
                                                                    <span class="fs-2x">
                                                                        {{ helper.currencyFormat(result.premium) }}
                                                                    </span>
                                <br>
                                <span>
                                                                        {{ getDuree() ? ' / ' + getDuree() : '' }}
                                                                    </span>
                              </div>
                            </div>
                          </div>
                        </th>
                      </template>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="background:transparent">
                      <template v-for="result, rindex in debugResults">
                        <td :key="uKey + '_debug_' + result.pack.uuid + '_' + rindex"
                            style="border-right: 20px white solid;background: transparent"
                            valign="top">
                          <div></div>
                          <div class="mh-500px overflow-auto">
                            <table class="table  gs-5 gy-2">
                              <thead></thead>
                              <tbody>
                              <template v-for="detail, index in result.details">
                                <tr
                                    :key="uKey + '_debug_' + result.pack.uuid + '_' + index">
                                  <td>
                                    {{ detail.name }}
                                  </td>
                                  <td>
                                    =
                                  </td>
                                  <td class="text-end">
                                    <transition mode="out-in"
                                                name="slide-left">
                                      <div
                                          :key="'detail_' + detail.value ? helper.slugify(detail.value) : helper.generateId()"
                                          class="fw-bolder cotation-detail">
                                        {{
                                          helper.currencyFormat(detail.value,
                                              false, false)
                                        }}
                                      </div>
                                    </transition>
                                  </td>
                                </tr>
                              </template>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </template>
                    </tr>
                    </tbody>
                  </table>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Item from "@/entity/Item";
import PropertyManager from "@/views/Income/Avenant/PropertyManager.vue";
import Property from "@/entity/Property";
import Branch from "@/entity/Branch";
import {helper} from "@/services/Helper";
import PackCategory from "@/entity/PackCategory";
import {api} from "@/services/Api";
import Insurer from '@/entity/Insurer';
import Pack from "@/entity/Pack";
import PackGuarantee from "@/entity/PackGuarantee";
import ItemGuarantee from "@/entity/ItemGuarantee";
import Popup from "@/entity/Popup";
import PremiumDetailModel from "@/entity/PremiumDetailModel";
import EngineMethod from "@/entity/EngineMethod";
import PropertyChoice from "@/entity/PropertyChoice";
import Guarantee from "@/entity/Guarantee";
import ItemProperty from "@/entity/ItemProperty";
import Customer from "@/entity/Customer";
import DataType from '@/entity/DataType';
import {auth} from "@/services/Auth";

@Component({
  name: 'ItemManager',
  components: {PropertyManager}
})
export default class ItemManager extends Vue {
  [x: string]: any;

  @Prop() item!: Item
  @Prop() items!: Item[]
  @Prop() branch!: Branch
  @Prop({default: null}) parent!: Item | null
  @Prop({default: ''}) pKey!: string
  @Prop({default: null}) fromProp!: ItemProperty | null
  @Prop() alias?: string
  @Prop({default: null}) insurerRestriction: Insurer | undefined
  expandGuarantee = true
  @Prop({default: false}) allowRemove!: boolean
  @Prop({default: false}) removeUnallowedPack!: boolean
  uKey = helper.generateId()
  @Prop() packCategories!: PackCategory[]
  @Prop() insurers!: Insurer[]
  @Prop() packs!: Pack[]
  uploadRoot = api.uploadRoot
  selectedPack: any = null
  showChildrenPackSelector = true
  @Prop() parentPack?: Pack
  currentPack: any = null
  insurer: any = null
  @Prop({default: false}) packFromParent!: boolean
  setChildPack = false
  packCategory: any = null
  filterByCategory: any = null
  comparePack = false
  @Prop({default: () => []}) optionalReceived!: PackGuarantee[]
  optionalSelected: PackGuarantee[] = []
  @Prop() premiumDetailModels!: PremiumDetailModel[]
  selectionUuid: any = null
  isMounted = false
  sendToAsaci = false
  unallowedPack = false
  @Prop({default: true}) showPackSelector!: boolean
  client = ''

  customers: Customer[] = []
  knownCustomer = false
  customer: Customer | null = null

  async searchClient() {
    if (!helper.empty(this.client)) {
      const res = await api.get(api.core, 'customer/search/' + this.client)
      if (res && res.data) {
        this.customers = []
        res.data.customers.forEach((c: any) => {
          const occ = new Customer(c)
          this.customers.push(occ)
        })
      }
    }
  }

  selectClient(customer: Customer) {
    this.customer = customer
    this.knownCustomer = true

    const c = JSON.parse(JSON.stringify(customer))
    this.item.properties.forEach((p: Property) => {
      // console.log(p.name + ' => ' + p.model.link)
      if (!helper.empty(p.model.link)) {
        const link: string = p.model.link!
        if (c[link]) {
          p.value = c[link]
        }
      }
    })
    const p = new Property();
    p.value = customer.uuid;
    p.name = 'uuid';
    const model = new ItemProperty();
    model.type = new DataType({id: 1, label: "Texte", code: "TEXT", class: "", format: "TEXT"});
    model.code = 'uuid';
    p.model = model
    this.item.properties.push(p)
  }

  removeCustomer() {
    this.customer = null
    this.knownCustomer = false
    this.item.properties = this.item.properties.filter((p: Property) => {
      return p.model.code !== 'uuid'
    })
    this.item.properties.forEach((p: Property) => {
      p.value = ''
    })
  }

  toggleAsaciRequest(event: any) {
    console.log(event.target.checked)
    const index = this.item.params.findIndex((param: any) => {
      return param.code === 'blockAsaciRequest'
    })
    if (index !== -1) {
      this.item.params.splice(index, 1)
    }
    if (!event.target.checked) {
      this.item.params.push({code: 'blockAsaciRequest', value: true})
    }
    console.log(this.item.params)
  }


  beforeMount(): void {
    if (this.item.pack) {
      if (this.item.pack.insurer!.uuid) {
        this.insurer = this.item.pack.insurer!.uuid
        this.$store.state.contractInsurer = this.item.pack.insurer
        this.$emit('setInsurer', this.insurer)
        this.selectionUuid = helper.generateId()
      }
      if (this.item.pack.category!.uuid) {
        this.packCategory = this.item.pack.category!.uuid
      }
    } else if (this.parentPack) {
      this.item.pack = this.parentPack
    }
    if (this.optionalReceived.length > 0 && this.optionalSelected) {
      this.optionalSelected = this.optionalReceived
    }

    if (!this.item.model.method) {
      const obj = this.items.find((item: Item) => {
        return item.model!.uuid === this.item.model.uuid
      });
      if (obj) {
        this.item.model.method = obj.model.method
      }
    }
    const index = this.item.params.findIndex((param: any) => {
      return param.code === 'blockAsaciRequest'
    })
    if (index === -1) {
      this.sendToAsaci = true
    }
  }

  getOptionalPremium(result: any, guarantee: Guarantee) {
    // console.log(guarantee.label)
    const match = result.optionals.find((o: any) => {
      if (guarantee.method) {
        return o.code === guarantee.method.code
      }
    })
    // console.log(match)
    return match
  }

  getPackPremium(result: any) {
    // console.log(result)
    let premium = result.premium
    // console.log('Prime de base ' + premium)
    // result.pack.packGuarantees.forEach((pg: PackGuarantee) => {
    //     const optional = this.getOptionalPremium(result, pg.guarantee)
    //     if (pg.selected && optional) {
    //         console.log('Ajouter prime optionnelle ' + optional.premium)
    //         premium += optional.premium
    //     }
    // })
    return premium
  }

  showPack(packId: number) {

    let show = true
    if (helper.empty(this.insurer) && helper.empty(this.packCategory)) {
      console.log('show all pack')
      show = true
    }
    const pack = this.packs.find((pc: Pack) => {
      return pc.id === packId
    })
    if (pack instanceof Pack) {
      if (!helper.empty(this.insurer) && pack.insurer) {
        if (this.insurer !== pack.insurer.uuid) {
          console.log('hide ' + packId + ' insurer dont match')
          show = false
        }
      }
      if (!helper.empty(this.packCategory) && pack.category) {
        if (this.packCategory !== pack.category.uuid) {
          console.log('hide ' + packId + ' category dont match')
          show = false
        }
      }
    } else {
      console.log('pack not found')
    }
    return show
  }

  initialPack: Pack | null = null

  async mounted() {
    this.setChildPack = this.packFromParent


    if (this.item.model!.isPackApplier) {
      this.setChildPack = true
      if (this.item.getAssetCount() === 1) {
        this.showChildrenPackSelector = false
      }
    }
    if (this.parent instanceof Item && this.parent.pack && !this.item.pack) {
      this.item.pack = this.parent.pack
    }
    if (this.item.pack) {
      this.initialPack = this.item.pack
    }
    window.addEventListener('resize', this.updateRowHeight)
    this.isMounted = true
    // console.log('mount')
    this.calculatePremium()
  }

  compare() {
    // console.log(this.item.guarantees)
    this.$store.state.recalculated = []
    // console.log('compare')
    this.calculatePremium(true, false)
    this.updateRowHeight()
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.updateRowHeight)
  }


  async handleUpdate(evt: any) {

    if (evt) {
      if (evt.type === 'packCategorySelected') {
        const occ = this.packCategories.find((pc: PackCategory) => {
          return pc.uuid === evt.payload
        })
        if (occ instanceof PackCategory) {
          this.packCategory = occ
        }
      }
      if (evt.type === 'packSelected') {
        if (this.item.model.isPackApplier) {
          const occ = this.packs.find((pc: Pack) => {
            return pc.id === evt.payload
          })
          if (occ instanceof Pack) {
            this.packCategory = occ.category
            this.item.pack = occ
            this.selectPack()
            this.setChildrenPack(this.item)
          }
        }
      }
      await this.$store.state.sleep(1000)
    }
    // console.log('emit item manager update')

    // console.log('handling update ' + this.item.name + ' ' + this.item.uuid)
    // console.log('item updated = ' + this.$store.state.contractUpdateComparison)
    if (this.isMounted) {
      // console.log('handleupdate ' + this.item.name)
      this.calculatePremium(true, false)
      console.log(evt)
      this.$emit('update', evt)
    }

    // this.$store.commit('updateContractItems', this.item.uuid)
  }


  @Watch('$store.state.contractUpdateComparison', {immediate: true, deep: true})
  recalculate() {

    // console.log('CATCH COMPARISON UPDATE IN ' + this.item.name)
    if (this.$store.state.contractUpdateComparison !== this.item.uuid && this.$store.state.contractUpdateComparison !== null
        && !this.$store.state.recalculated.includes(this.item.uuid)) {
      // console.log('recalculate ' + this.item.name)
      this.calculatePremium()
      this.$store.state.recalculated.push(this.item.uuid)
    } else {
      // console.log('AVOID recalculate ' + this.item.name + ' uuid => ' + this.item.uuid)
      // console.log(this.$store.state.contractUpdateComparison !== this.item.uuid)
      // console.log(this.$store.state.contractUpdateComparison !== null)
      // console.log(!this.$store.state.recalculated.includes(this.item.uuid))
      // console.log('item updated ' + this.$store.state.contractUpdateComparison)
      // if (this.$store.state.recalculated.includes(this.item.uuid)) {
      //     console.log('already recalculate')
      // }
    }
  }


  updateRowHeight() {
    const offers = document.querySelectorAll('.offers')
    offers.forEach((offer: any) => {
      const heads = offer.querySelectorAll('.offers th')

      const rows = offer.querySelectorAll('.offers .results tbody tr')
      const equiRows = offer.querySelectorAll('.offers .guarantee tbody tr')

      // const columns = offer.querySelectorAll('.offers td')
      let max = 0
      heads.forEach((g: HTMLElement) => {
        g.style.height = 'auto'
        if (g.getBoundingClientRect().height > max) {
          max = g.getBoundingClientRect().height
        }
      })
      heads.forEach((head: HTMLElement) => {
        head.style.height = max + 'px'
      })

      rows.forEach((row: HTMLElement, rowIndex: number) => {
        const equiRow = equiRows[rowIndex]
        const equiCol = equiRow.querySelector('td')

        const columns = row.querySelectorAll('td')
        let max = equiCol.getBoundingClientRect().height;
        columns.forEach((column: HTMLElement) => {
          column.style.height = 'auto'
          if (column.getBoundingClientRect().height > max) {
            max = column.getBoundingClientRect().height
          }
        })
        columns.forEach((column: HTMLElement) => {
          column.style.height = max + 'px'
        })

        // equiCol.forEach((ec: HTMLElement) => {
        equiCol.style.height = max + 'px'
        // })

      })

    })
  }

  results: any[] = []
  oldResults: any[] = []

  getDuree() {
    const avenant: Item = this.items.find((i: Item) => {
      return i.model.code === 'avenant'
    }) as any
    if (avenant instanceof Item) {
      const prop = avenant.getPropertyByCode('duree')
      const choice = prop.model.choices.find((c: PropertyChoice) => {
        return c.code === prop.value
      })
      return choice ? choice.label : ''
    }
  }

  onePackHasGuarantee(guarantee: Guarantee) {
    let match = false
    this.results.forEach((r: any) => {
      if (r.pack.hasGuarantee(guarantee)) {
        // console.log(r.pack.label + ' ' + r.pack.insurer.label + ' contain')
        match = true
      }
    })
    // console.log(guarantee.label + ' ==> ' + match)
    return match
  }

  debugResults: any[] = []
  calculUuid = null


  clickOffer(result: any) {
    // this.oldResults = []
    // console.log('EMPTY OLD RESULT')
    const uuids = this.item.getAssetUuids()
    this.$store.state.recalculated = this.$store.state.recalculated.filter((uuid: string) => {
      return !uuids.includes(uuid)
    })
    // console.log(uuids)
    this.setOffer(result);
    this.$emit('update', {type: 'offerSelected', payload: result})
  }


  @Watch('pKey', {immediate: true, deep: true})
  async calculatePremium(set = true, setChildrenPack = true) {
    // console.log(this.item.name)
    if (!this.isMounted) {
      // console.log('not mounted')
      return
    }
    if (this.$store.state.recalculated.includes(this.item.uuid)) {
      // console.log('already calculated')
      return
    }
    let method: EngineMethod = this.item.model.method as any
    if (!method) {
      const obj = this.items.find((item: Item) => {
        return item.model!.uuid === this.item.model.uuid
      });
      if (obj) {
        this.item.model.method = obj.model.method
        method = obj.model.method as any
      }
    }
    if (method) {
      // console.log('---------------')
      // console.log('Recalcul ' + this.item.name + ' ' + this.item.generatedAlias)

      // console.log(this.item.simplify())
      // this.$store.state.popups = []
      // console.log('recalculate premium ' + this.item.name)
      const itemCopy = new Item(this.item.simplify())
      // console.log(itemCopy)
      const items: Item[] = []
      this.items.forEach((i: Item) => {
        const occ = new Item(i.simplify())
        items.push(occ)
      })
      items.forEach((i: Item) => {
        this.replaceChild(i, itemCopy)
      })
      this.results = []
      this.debugResults = []
      let cheaper: any = null
      let classes: any[] = window['classes' as any] as any
      let selectedOffer: any = null
      // console.log(classes)
      if (classes) {
        // console.log(classes)
        this.insurers.forEach((i: Insurer) => {
          if (this.insurerRestriction) {
            if (i.slug !== this.insurerRestriction.slug) {
              // console.log('avoid insurer ' + i.label)
              return
            }
          }
          // console.log('=============================')
          // console.log(i.label)
          const engine: any = classes.find((c: any, index: any) => {
            return c.code === i.slug
          })
          if (!engine) {
            new Popup('Calcul Manquant', 'Avenant Affaire Nouvelle assureur ' + i.label)
            return
          }
          const insurerEngine = new engine.prototype()
          const keys = Object.keys(insurerEngine)
          items.forEach((i: Item) => {
            keys.forEach((k: string) => {
              if (i.model.code === k) {
                const data = classes.find((c: any) => {
                  return c.code === k
                })
                if (!data.prototype) {
                  console.error(i.model.code + ' manquant')
                }
                const prototype = new data.prototype(i)
                prototype.engine = insurerEngine
                insurerEngine[k] = prototype

              }
            })
          })
          if (typeof insurerEngine[method.code] === 'function') {
            // console.log(insurerEngine)
            i.packs.forEach((pack: Pack) => {
              const item = new Item(itemCopy.simplify())
              if (pack.branchSlug === this.branch.slug) {
                pack.insurer = {id: i.id, label: i.label, slug: i.slug, photo: i.photo} as any
                item.pack = pack
                insurerEngine.details = []
                try {
                  item.guarantees = []
                  const old = this.oldResults.find((o: any) => {
                    return o.pack.id === pack.id
                  })
                  let expand = false
                  if (this.item.pack && this.item.pack.id === item.pack.id) {
                    // console.log('current pack saved '+this.item.pack.label)
                    this.item.guarantees.forEach((ig: ItemGuarantee) => {
                      if (ig.isOptional) {
                        const pmatch = item.pack!.packGuarantees.find((ppg: PackGuarantee) => {
                          return ppg.guarantee.id === ig.guarantee!.id
                        })
                        if (pmatch) {
                          pmatch.selected = true
                          item.guarantees.push(ig)
                          // console.log('added guarantee ' + ig.guarantee.label)
                        }
                      } else {
                        item.guarantees.push(ig)
                        // console.log('added guarantee 2 ' + ig.guarantee.label)
                      }

                    })
                  }
                  if (old) {
                    item.guarantees = []
                    expand = old.expand
                    old.pack.packGuarantees.forEach((pg: PackGuarantee) => {
                      if (pg.isOptional) {
                        const match = item.pack!.packGuarantees.find((ppg: PackGuarantee) => {
                          return ppg.guarantee.id === pg.guarantee.id
                        })
                        if (match) {
                          match.selected = pg.selected
                        }
                      }
                      if (!pg.isOptional || pg.selected) {
                        const occ = new ItemGuarantee()
                        occ.label = pg.guarantee!.label
                        occ.ceiling = pg.ceiling
                        occ.deductible = pg.deductible
                        occ.description = pg.guarantee!.description
                        occ.guarantee = {...pg.guarantee} as any
                        occ.isOptional = pg.isOptional
                        item.guarantees.push(occ)
                        // console.log('append guarantee ' + pg.guarantee.label)
                      }
                    })
                  }
                  // console.log(item)
                  // if (setChildrenPack) {
                  item.setChildrenPack()
                  // console.log(item)
                  // }
                  let proto: any = classes.find((c: any) => {
                    return c.code === item.model.code
                  })
                  let itemConverted = new proto.prototype(item)
                  itemConverted.engine = insurerEngine
                  this.replaceEngineItemConverted(insurerEngine, itemConverted)

                  // console.log('converted')
                  // console.log(itemConverted)
                  const optionals: any[] = [];
                  item.pack.packGuarantees.forEach((pg: PackGuarantee) => {
                    if (pg.guarantee!.method && pg.isOptional) {
                      let methodName = pg.guarantee!.method.code
                      let code = item.model.code.charAt(0).toUpperCase() + item.model.code.slice(1)
                      let customMethodName = methodName + code

                      if (typeof insurerEngine[customMethodName] === 'function') {
                        methodName = customMethodName
                      }
                      if (typeof insurerEngine[methodName] === 'function') {
                        let optionalPremium = null
                        try {
                          // console.log('calculating optional ' + pg.guarantee.label + ' of ' + item.pack!.code + ' method is ' + methodName)
                          optionalPremium = insurerEngine[methodName](itemConverted)
                        } catch (e) {
                          // console.error('erreur lors du calcul de la garantie optionnelle ' + pg.guarantee.label + ' de ' + i.label)
                          console.log(e)
                        }
                        // console.log('Prime ' + pg.guarantee.label + ' ' + optionalPremium)
                        if (optionalPremium) {
                          const gocc = new ItemGuarantee()
                          gocc.isOptional = pg.isOptional
                          gocc.guarantee = pg.guarantee
                          gocc.deductible = pg.deductible
                          gocc.ceiling = pg.ceiling
                          gocc.label = pg.guarantee.label
                          gocc.description = pg.guarantee!.description
                          gocc.premium = optionalPremium

                          const op = {
                            code: pg.guarantee.method.code,
                            insurer: {id: i.id, label: i.label, slug: i.slug, uuid: i.uuid},
                            methodId: pg.guarantee.method.id,
                            name: pg.guarantee.method.label,
                            append: false,
                            showInDetails: true,
                            premium: optionalPremium,
                            itemGuarantee: gocc,
                            variable: "methode Vide",
                          }
                          optionals.push(op);
                        } else {
                          // console.error(i.label + ': Pack ' + item.pack!.label + ' la garantie optionnelle ' + pg.guarantee.label + ' est incalculable')
                          pg.selected = false
                          if (old) {
                            old.pack.packGuarantees.forEach((p: PackGuarantee) => {
                              if (p.guarantee.id === pg.guarantee.id) {
                                p.selected = false
                              }
                            })
                          }
                          const os = this.optionalSelected.findIndex((os: PackGuarantee) => {
                            return os.id === pg.id
                          })
                          if (os !== -1) {
                            this.optionalSelected.splice(os, 1)
                          }
                          let index = item.guarantees.findIndex((ig: ItemGuarantee) => {
                            return ig.guarantee.id === pg.guarantee.id
                          })
                          if (index) {
                            item.guarantees.splice(index, 1)
                            itemConverted.item = item
                          }
                        }
                      }
                    }
                  })

                  // console.log('=====>' + itemConverted.item.pack.code)
                  // console.log(JSON.stringify(itemConverted.item))
                  // itemConverted.item.guarantees.forEach((g: ItemGuarantee) => {
                  //     console.log("GARANTIE SELECTIONNEE : " + g.label)
                  // })
                  // console.log(itemConverted.item.guarantees.length + ' garanties')
                  // console.log('=====>' + itemConverted.item.pack.code)

                  // console.log('/////////////////////////////////////////////')
                  const premium = insurerEngine[method.code](itemConverted)
                  // console.log(premium)
                  // console.log(insurerEngine)
                  // console.log('/////////////////////////////////////////////')
                  const errors = JSON.parse(JSON.stringify(insurerEngine.errors))
                  errors.forEach((e: any) => {
                    let match = this.$store.state.popups.find((p: any) => {
                      return p.message === e.message
                    })
                    if (!match) {
                      new Popup(e.TitleMixin, e.message, 'warning', 'fad fa-exclamation')
                    }
                  })
                  insurerEngine.details.forEach((d: any) => {
                    d.show = true
                  })
                  // console.log(insurerEngine.details)
                  // console.log('prime ' + premium)
                  const copiedPack = new Pack(JSON.parse(JSON.stringify(item.pack)));
                  const result = {
                    pack: copiedPack,
                    premium: premium,
                    details: [...insurerEngine.details],
                    expand: expand,
                    optionals: optionals,
                  }
                  // console.log(i.label + ' ' + copiedPack.label + ' = ' + premium)
                  // insurerEngine.details.forEach((detail: any) => {
                  //     console.log(detail.name + ' = ' + detail.value)
                  // })
                  if (premium) {
                    if (cheaper === null || premium < cheaper) {
                      cheaper = result
                    }
                    insurerEngine.details = []


                    result.pack.packGuarantees.forEach((pg: PackGuarantee) => {
                      let selected = this.item.guarantees.find((itemGuarantee: ItemGuarantee) => {
                        return itemGuarantee.guarantee!.id === pg.guarantee.id && pg.isOptional
                      })
                      if (old) {
                        selected = old.pack.packGuarantees.find((p: PackGuarantee) => {
                          return p.isOptional && p.selected && p.guarantee.id === pg.guarantee.id
                        })
                      }
                      if (selected) {
                        // console.log('activate guarantee ' + pg.guarantee.label)
                        pg.selected = true
                      }
                    })
                    if (this.item.pack && this.item.pack.id === item.pack.id && !this.comparePack) {
                      if (set) {
                        selectedOffer = result
                      }
                    }
                    this.results.push(result)
                  } else {
                    // console.log(insurerEngine)
                    this.debugResults.push(result)
                  }
                  // insurerEngine.details.forEach((detail: any) => {
                  //     console.log(detail.name + ' = ' + detail.value)
                  // })
                } catch (e) {
                  // console.log('ImpossibLe de calculer le pack ' + i.label + ' ' + pack.label)
                  console.log(e)
                }
                // console.log(this.results)
                // insurerEngine.details.forEach((detail: any) => {
                //     console.log(detail.name + ' = ' + detail.value)
                // })
                // console.log(premium)
              }
            })
            this.unallowedPack = false
            if (this.item.pack && this.removeUnallowedPack) {
              const match = this.results.find((result: any) => {
                return result.pack.code === this.item.pack!.code
              })
              // console.log(match)
              if (!match && auth.hasRole('ROLE_MANAGER') && this.insurerRestriction) {
                this.unallowedPack = true
                new Popup('Pack non autorisé', 'Selectionnez une autre offre pour ' + this.item.alias, 'danger', 'fad fa-exclamation')
                this.item.pack = undefined
              }

            }
            // console.log(this.results)
          } else {
            console.log('Impossible de calculer la prime de ' + itemCopy.name + ' car la fonction "' + method.code + '" est introuvable')
          }
        })
        if (cheaper) {
          if (!this.filterByCategory) {
            this.filterByCategory = cheaper.pack.category.uuid
          }
        }
      } else {
        // console.log('no classes')

      }
      this.oldResults = [...this.results];
      // console.log('here')
      // console.error('on enregistre oldresult pour ' + this.item.name + ' ' + this.item.generatedAlias)
      // console.log(this.item.name + ' ' + this.item.generatedAlias + ' a ' + this.item.guarantees.length + ' garanties')
      // console.log(this.results)
      if (selectedOffer) {
        this.setOffer(selectedOffer, setChildrenPack)
      }
      this.$forceUpdate()
      // this.calculUuid = helper.generateId()
      // console.log(this.results)
    } else {
      if (this.item.model.isPackApplier || this.item.model.className === 'Asset') {
        // new Popup('Erreur', 'Aucun calcul configuré pour la prime nette "' + this.item.model.name + '"')
        // console.log('Aucun calcul configuré pour la prime nette "' + this.item.model.name + '"')
      }
    }
    // console.log('here')
    this.$store.state.recalculated.push(this.item.uuid)
    await this.$store.state.sleep(100)
    this.updateRowHeight()
    // console.log(this.item.model)
  }

  replaceChild(item: Item, child: Item) {
    // console.log('on fouille '+item.name)
    item.properties.forEach((p: Property) => {
      p.children.forEach((c: Item, index) => {
        if (c.uuid === child.uuid) {
          // console.log('on a trouve '+child.name)
          p.children[index] = child
        }
        this.replaceChild(c, child)
      })
    })
  }

  replaceEngineItemConverted(engine: any, itemConverted: any) {
    let item: Item = itemConverted.item
    // console.log('on veu remplacer ' + item.name)
    const keys = Object.keys(engine)
    for (const key of keys) {
      // console.log('on check la proprieté ' + key)
      const entity = engine[key]
      // console.log(entity)
      // console.log(item)
      // console.log(item.uuid + ' === ' + entity.uuid)
      // console.log(entity)
      if (entity) {
        if (entity.item && item.uuid === entity.item.uuid) {
          // console.log('match')
          engine[key] = itemConverted
          return
        }
        if (entity.item) {
          this.replaceChild(entity.item, item)
        }
      }
      // console.log(entity)
      // const itemProps = Object.keys(entity)
    }
  }

  setChildrenPack(item: Item) {
    if (item.pack) {
      item.properties.forEach((p: Property) => {
        if (p.model && p.model.type) {
          if (p.model.type.code === 'PACK') {
            p.value = item.pack!.id as any
          }
          if (item.pack && p.model.type.code === 'PACK_CATEGORY' && item.pack.category) {
            p.value = item.pack.category!.uuid as any
          }
          p.children.forEach((c: Item) => {
            c.guarantees = []
            item.guarantees.forEach((g: ItemGuarantee) => {
              const gocc = new ItemGuarantee()
              gocc.isOptional = g.isOptional
              gocc.guarantee = g.guarantee
              gocc.deductible = g.deductible
              gocc.ceiling = g.ceiling
              gocc.label = g.label
              gocc.description = g.guarantee!.description

              c.guarantees.push(gocc)
            })
            c.pack = new Pack({...item.pack})
            this.setChildrenPack(c)
          })
        }
      })
      this.uKey = helper.generateId()
    }
  }

  setChildOffer() {
    this.item.properties.forEach((p: Property) => {

    })
    this.uKey = helper.generateId()
  }


  isItemContainer() {
    let result = false
    this.item.properties.forEach((p: Property) => {
      if (p.model!.type.code.match(/OBJECT_GROUP/)) {
        result = true
      }
    })
    return result
  }


  async setOffer(result: any, setChildrenPack = true) {
    // this.oldResults = []
    // console.log('setting offer')
    // result.pack.packGuarantees.forEach((pg: PackGuarantee) => {
    //     if (pg.selected) {
    //         console.log('Optionnelle =>' + pg.guarantee.label)
    //     }
    // })
    // return
    // console.log(result)
    let change = false
    if (this.item.pack) {
      if (this.item.pack.id !== result.pack.id) {
        // console.log('not same pack')
        change = true
      }
      if (!change && this.item.premium !== this.getPackPremium(result)) {
        // console.log('not same premium')
        change = true
      }
      if (JSON.stringify(this.item.details) !== JSON.stringify(result.details)) {
        // console.log('not same details')
        // console.log(this.item.details)
        // console.log(result.details)
        change = true
      }
    } else {
      change = true
    }
    if (change) {
      // console.log('setting pack ' + result.pack.code + ' to ' + this.item.name + ' ' + this.item.generatedAlias)
      const selected = result.pack
      this.item.details = result.details
      this.$store.state.currentInsurer = result.pack.insurer
      // console.log('selecting pack | reset')
      if (this.item.model.isPackApplier || this.item.model.className === 'Asset') {
        const pack = this.packs.find((p: Pack) => {
          return p.id === selected.id
        })
        // console.log(pack)
        if (pack instanceof Pack) {
          this.item.pack = pack
          this.item.premium = this.getPackPremium(result)
          this.item.guarantees = []
          const value = {
            pack: {id: pack.id, code: pack.code},
            insurer: {id: pack.insurer!.id, slug: pack.insurer!.slug}, optionals: [] as number[]
          }
          this.$store.state.contractInsurer = pack.insurer
          this.$emit('setInsurer', pack.insurer)
          this.item.pack!.packGuarantees.forEach((p: PackGuarantee) => {
            let match = result.pack.packGuarantees.find((pg: PackGuarantee) => {
              return pg.id === p.id
            })
            if (!p.isOptional || match.selected) {
              // console.log('add guarantee '+p.guarantee!.label)
              const occ = new ItemGuarantee()
              occ.label = p.guarantee!.label
              occ.ceiling = p.ceiling
              occ.deductible = p.deductible
              occ.description = p.guarantee!.description
              occ.guarantee = {...p.guarantee} as any
              occ.isOptional = p.isOptional
              if (match.selected) {
                const optional = result.optionals.find((o: any) => {
                  return o.itemGuarantee.guarantee.id === match.guarantee.id
                })
                if (optional) {
                  value.optionals.push(p.guarantee.id)
                  occ.premium = optional.premium
                }
              }
              this.item.guarantees.push(occ)
            }
          })
          // for (var i = 0; i < this.item.properties.length; i++) {
          //     if (this.item.properties[i].model.code === 'pack') {
          //         this.item.properties[i].value = JSON.stringify(value)
          //         console.log(value)
          //     }
          // }
          // this.item.properties.forEach((p: Property) => {
          //     if (p.model.code === 'pack') {
          //         p.value = JSON.stringify(value)
          //         console.log(value)
          //     }
          // })
          // this.oldResults = [];
          // console.log('reset old results')
          if (this.item.model.isPackApplier && setChildrenPack) {
            // console.log('on set les pack des enfants de ' + this.item.name)
            // console.log(this.item)
            this.item.setChildrenPack()
            // this.$store.commit('updateContractItems', null)
            // await this.$store.state.sleep(100)
            // this.$store.commit('updateContractItems', this.item.uuid)
          }
          // console.log(this.item.name + ' ' + this.item.generatedAlias + ' a ' + this.item.guarantees.length + ' garanties')
          this.comparePack = false
          this.selectionUuid = helper.generateId()
          // console.log('set Offer emit update')
          // console.log(result)
          // this.$emit('update')
          this.$emit('update', {type: 'offerSelected', payload: result})


        }
      }
    } else {
      // console.log(result)
      // console.log('no change')
    }
    this.comparePack = false
    this.$store.commit('updateContractItems', helper.generateId())
    // console.log(this.item.premium)
    this.$forceUpdate()
  }

  selectPack() {
    // console.log('selecting pack | reset')
    if (this.item && this.selectedPack) {
      const pack = this.packs.find((p: Pack) => {
        return p.id === this.selectedPack
      })
      if (pack instanceof Pack) {
        this.item.pack = pack
        this.item.guarantees = []
        this.item.pack!.packGuarantees.forEach((p: PackGuarantee) => {
          if (!p.isOptional) {
            this.addPackGuarantee(p)
          }
        })
      }
    }
  }

  toggleOptionalGuarantee(result: any, guarantee: Guarantee) {
    // console.log(result)
    // console.log(pg)
    const optional = this.getOptionalPremium(result, guarantee)
    // console.log(optional)
    return
    const index = this.item.guarantees.findIndex((ig: ItemGuarantee) => {
      return ig.guarantee!.id === optional.itemGuarantee.guarantee!.id
    })
    if (index !== -1) {
      // this.item.guarantees.splice(index, 1)
    } else {
      // this.addPackGuarantee(optional.packGuarantee)
    }
    // this.setChildrenPack(this.item)
  }

  isGuaranteeSelected(pg: PackGuarantee) {
    // if (!pg.guarantee) {
    //     console.log('pg has no guarantee')
    //     console.log(pg)
    // }
    return this.item.guarantees.findIndex((ig: ItemGuarantee) => {
      // if (!ig.guarantee) {
      //     console.log('ig has no guarantee')
      // }
      return ig.guarantee!.id === pg.guarantee!.id
    }) !== -1
  }

  @Watch('optionalReceived', {immediate: true, deep: true})
  updateOptionals() {
    // console.log('optional have been updated from parent for ' + this.item.name + ' ' + this.item.generatedAlias)
    // this.optionalReceived.forEach((p: PackGuarantee) => {
    //     console.log('activate ' + p.guarantee.label)
    // })
    this.results.forEach((result: any) => {
      result.pack.packGuarantees.forEach((pg: PackGuarantee) => {
        if (pg.isOptional) {
          const match = this.optionalReceived.find((p: PackGuarantee) => {
            return p.id === pg.id
          })
          if (match) {
            pg.selected = true
            // console.log('activate ' + pg.guarantee.label)
          } else {
            pg.selected = false
            // console.log('deactivate ' + pg.guarantee.label)
          }
        }
      })
    })
    // console.log('updateOptionals')
    this.calculatePremium()
  }


  resetOptional(item: Item) {
    this.results.forEach((result: any) => {
      result.pack.packGuarantees.forEach((pg: PackGuarantee) => {
        if (pg.isOptional) {
          pg.selected = false
        }
      })
    })
    this.item.guarantees = this.item.guarantees.filter((g: ItemGuarantee) => {
      return g.isOptional = false
    })
    item.properties.forEach((p: Property) => {
      if (p.model && p.model.type) {
        p.children.forEach((c: Item) => {
          this.resetOptional(c)
        })
      }
    })
    // console.log(item)
    this.calculatePremium()
  }

  async toggleOptional(result: any, packGuarantee: PackGuarantee) {
    const optionalSelected: PackGuarantee[] = []
    this.oldResults.forEach((old) => {
      if (old.pack.id === result.pack.id) {
        old.pack.packGuarantees.forEach((p: PackGuarantee) => {
          if (p.guarantee.id === packGuarantee.guarantee.id) {
            p.selected = packGuarantee.selected
            if (p.selected) {
              // console.log('on active optionnelle ' + p.guarantee.label)
            } else {
              // console.log('on desactive optionnelle ' + p.guarantee.label)
            }
          }
          if (p.selected) {
            optionalSelected.push(p)
          }
        })

      }
    })
    this.optionalSelected = optionalSelected
    // })
    this.$store.state.recalculated = []
    // console.log('toggle option')
    this.calculatePremium(false)
    this.item.updateChildrenOptionals()
    this.$forceUpdate()
  }

  addPackGuarantee(p: PackGuarantee) {
    const occ = new ItemGuarantee()
    occ.label = p.guarantee!.label
    occ.ceiling = p.ceiling
    occ.deductible = p.deductible
    occ.description = p.guarantee!.description
    occ.guarantee = {...p.guarantee} as any
    occ.isOptional = p.isOptional
    this.item.guarantees.push(occ)

  }

  removePackGuarantee(pg: PackGuarantee) {
    const index = this.item.guarantees.findIndex((ig: ItemGuarantee) => {
      return ig.guarantee!.id === pg.guarantee!.id
    })
    if (index !== -1) {
      this.item.guarantees.splice(index, 1)
    }
  }

  async loadPackData() {
    await this.loadPacks()
    await this.loadInsurers()
    await this.loadPackCategories()
    // await this.loadGuarantees()

  }

  async loadPackCategories() {
    this.packCategories = await this.$store.getters.packCategories(this.branch.slug)
    if (this.packCategories.length > 0) {
      this.filterByCategory = this.packCategories[0].uuid
    }
  }

  async loadPacks() {
    const res = await api.get(api.core, 'pack/list')
    if (res && res.data && res.data.packs) {
      this.packs = []
      res.data.packs.forEach((i: Pack) => {
        const occ = new Pack(i)
        const match = this.packs.find((p: Pack) => {
          return p.id === occ.id
        })
        if (!match) {
          this.packs.push(occ)
        }
      })
    }
  }

  async loadInsurers() {
    const res = await api.get(api.core, 'insurer/list')
    if (res && res.data && res.data.insurers) {
      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        const occ = new Insurer(i)
        this.insurers.push(occ)
      })
    }
  }


  get isContainer() {
    let result = true
    this.item.properties.forEach((p: Property) => {
      // console.log('==>' + this.item.name)
      if (p.model) {
        // console.log(p.model.name)
        if (p.model.type.code !== 'OBJECT_GROUP') {
          result = false
        }
      } else {
        console.log('model not set for ' + p.name)
      }
    })
    return result
  }

  removeItem() {
    // console.log('removing' + this.item.name)
    if (this.parent) {
      // console.log(this.parent)
      this.parent.properties.forEach((p: Property) => {
        const index = p.children.findIndex((c: Item) => {
          // console.log(c.name)
          return c === this.item
        })
        // console.log(index)
        if (index !== -1) {
          p.children.splice(index, 1)
        }
      })
    }
  }

  assetAdded() {
    // console.log('Asset add catch in ' + this.item.name)
    if (this.item.model.isPackApplier && this.item.pack) {
      // console.log('applying pack ' + this.item.pack.label)
      this.setChildrenPack(this.item)
    } else {
      this.$emit('assetAdded')
    }

  }

  appendItem(occ: Item) {
    this.$emit('appendItem', occ)
  }
}
</script>
<style scoped>
.results {
  /*border-collapse: separate;*/
}

.offers tbody tr:nth-child(2n+1) {
  background: #a0d8ff26;
}

.results td,
.results th {
  min-width: 200px;
}

.results-container {
  position: relative;
  /*border: 1px red solid;*/
}

.results-container:before,
.results-container:after {
  content: '';
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  /*background: rgb(2,0,36);*/
  background: linear-gradient(-90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 75%);

}

.results-container:after {
  left: auto;
  right: 0;
  background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 75%);
}

.results td {
  border-right: 20px white solid;
  /*background: rgba(255, 255, 255, 0.21);*/
}
</style>
