<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Survey_list') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div class="btn btn-primary btn-sm">
              Créer un client
            </div>

            <div class="btn btn-primary btn-sm ms-5" @click="printCustomers" v-if="hasRole('ROLE_SUPER_ADMIN')">
              Imprimer
            </div>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Rechercher
              </label>
              <div>
                <input class="form-control" placeholder="Rechercher" type="text"
                       v-model="searched.value">
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-10">
            <div @click="resetSearch" class="btn btn-danger me-5 d-flex align-items-center">
              <i class="fad fa-trash"></i>
              Réinitialiser
            </div>
            <button :disabled="searching" @click="search" class="btn btn-primary d-flex align-items-center">
              <i class="fad fa-search"></i>
              Rechercher
            </button>
          </div>
        </div>
      </div>

      <div class="card mt-10">
        <div class="card-body">
          <table class="table table-striped gy-3 gs-3">
            <thead>
            <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
              <th>{{ trans('Client') }}</th>
              <th>
                {{ trans('Contact') }}
              </th>
              <th class="text-center">{{ trans('Gestionnaire') }}</th>
              <th>{{ trans('Apporteur') }}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="searching">
              <td colspan="5">
                <Loading></Loading>
              </td>
            </tr>
            <template v-else>
              <tr v-for="customer in customers">
                <td>
                  <div>
                    {{ customer.id }} ▪️ {{ customer.identifier }}
                  </div>
                  <div>
                    {{ customer.email }}
                  </div>
                </td>
                <td class="text-end">
                  {{ phoneFormat(customer.fixe) }}
                  <br>
                  {{ phoneFormat(customer.mobile) }}
                </td>
                <td>

                </td>
                <td>

                </td>
                <td>
                  <div class="d-flex">
                    <router-link :to="{ name : 'customer-show', params : {uuid: customer.uuid} }"
                                 class="btn btn-xs btn-primary btn-icon">
                      <i class="fad fa-eye"></i>
                    </router-link>
                  </div>

                </td>
              </tr>
            </template>

            </tbody>
          </table>
        </div>
      </div>

      <div class="my-20">
        <Pagination :count="total" :itemLength="customers.length" :matchCount="matchCount" :perPage="searched.perPage"
                    :showEmpty="true" @change="search" v-model="searched.page"></Pagination>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Pagination from "@/components/Pagination.vue";
import Customer from '@/entity/Customer';
import UserView from "@/components/UserView.vue";
import Loading from "@/components/Loading.vue";
import {AxiosHeaders} from "axios";

@Component({
  components: {Base, Pagination, UserView, Loading}
})
export default class CustomerList extends Vue {

  searching = false

  searched = {
    page: 1,
    value: '',
    perPage: 50
  }
  matchCount = 0;
  total = 0
  customers: Customer[] = []

  mounted() {
    this.search()
  }

  resetSearch() {
    this.searched = {
      page: 1,
      value: '',
      perPage: 50
    }
  }

  async printCustomers() {
    const res = await api.get(api.core, 'customer/print',{responseType: 'blob'})
    if (res && res.data) {
      const blob = new Blob([res.data], {type: res.headers['content-type']});
      let url = window.URL || window.webkitURL;
      let link = url.createObjectURL(blob);
      let a = document.createElement('a');
      a.setAttribute("download", res.headers['name']);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

    }
  }

  async search() {
    this.searching = true
    const res = await api.post(api.core, 'customer/list', {'search': this.searched})
    if (res && res.data && res.data.customers) {
      this.customers = []
      res.data.customers.forEach((c: any) => {
        const occ = new Customer(c)
        this.customers.push(occ)
      })
    }
    this.searching = false
  }
}
</script>
<style scoped>
</style>
