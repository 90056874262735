<template>
    <div class="bg-secondary d-flex justify-content-between rounded">
        <div class="p-2">
            {{choiceOff}}
        </div>
        <div class="p-2">
            {{choiceOn}}
        </div>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';

    @Component({})
    export default class CustomSwitch extends Vue {
        @Prop({ default: '' }) choiceOff!: string
        @Prop({ default: '' }) choiceOn!: string
    }
</script>
<style scoped>
</style>
