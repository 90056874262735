<template>
  <div :key="'question_' + question.uuid" class="flex-grow-1 m-2 question" v-if="question.isAuthorized()">
    <label class="fw-bolder my-2 d-flex justify-content-between" for="">
      <div>
            <span :title="question.visibility" class="cursor-pointer" v-b-tooltip.hover
                  v-if="question.visibility !== 'PUBLIC'">
                <i class="fad fa-lock text-danger"></i>
            </span>
        {{ question.label }}
      </div>
      <!--            <span @click="$store.commit('resetResponse',question)" class="btn btn-xs btn-icon" v-if="hasRole('ROLE_MANAGER')"><i class="fad fa-trash text-danger"></i></span>-->
    </label>

    <b-input-group
        :class="[question.isSending ? 'border-secondary' : question.isValid ? 'border-success' : 'border-danger']"
        class="rounded border-1 d-flex flex-grow-1">

      <b-input-group-prepend class="d-flex flex-column" v-if="question.prefix">
        <div class="input-group-text flex-grow-1">
          <template v-if="isIcon(question.prefix)">
            <i :class="question.prefix"></i>
          </template>
          <template v-else>
            {{ question.prefix }}
          </template>
        </div>
      </b-input-group-prepend>
      <div class="input-container flex-grow-1 rounded d-flex">

        <div :class="{ 'bg-green': question.response.value === 'false' }" @click="selectFalse"
             class="btn border-dashed border cursor-pointer choice bg-hover-light-green text-hover-dark">
          <i class="fad fa-thumbs-down"></i>
          {{ trans('Non') }}
        </div>
        <div :class="{ 'bg-green': question.response.value === 'true' }" @click="selectTrue"
             class="btn border-dashed border mx-3 cursor-pointer choice bg-hover-light-green text-hover-dark">
          <i class="fad fa-thumbs-up"></i>
          {{ trans('Oui') }}
        </div>
        <!--                <label class="form-check form-switch form-check-custom form-check-solid d-flex flex-grow-1">-->
        <!--                    <span class="btn btn-sm">{{trans('Non')}}</span>-->
        <!--                    <input class="form-check-input" type="checkbox" value=""/>-->
        <!--                    <span class="form-check-label">{{trans('Oui')}}</span>-->
        <!--                </label>-->
        <span :title="trans('Mise à jour en cours')" class="input-status d-flex align-items-center" v-b-tooltip.hover
              v-if="question.isSending">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
        <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover
              v-else-if="!question.isValid">
                    <i class="fad fa-exclamation-circle text-danger"></i>
                </span>
<!--        <span :title="trans(question.message)" class="input-status d-flex align-items-center" v-b-tooltip.hover-->
<!--              v-else-if="question.isValid">-->
<!--                    <i class="fad fa-check-circle text-success"></i>-->
<!--                </span>-->
      </div>
      <b-input-group-append v-if="question.suffix">
        {{ question.suffix }}
      </b-input-group-append>
    </b-input-group>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Question from "@/entity/Question";

@Component({})
export default class Checkbox extends Vue {
  @Prop() question!: Question

  mounted(): void {
  }

  selectTrue() {
    // console.log('true')
    if (this.question.response.value === 'true') {
      this.$store.commit('responseSaved')
    } else {
      this.question.response.value = "true"
      this.question.updateAnswer()
    }
  }

  selectFalse() {
    if (this.question.response.value === 'false') {
      this.$store.commit('responseSaved')
    } else {
      this.question.response.value = "false"
      this.question.updateAnswer()
    }
  }

  // type = 'text'
}
</script>
<style scoped>
.choice i {
  color: inherit !important;
}

.bg-green {
  background: #3DDAD7 !important;
}

.bg-light-green {
  background: rgba(61, 218, 215, 0.45);
}

.bg-hover-light-green:hover {
  background: rgba(61, 218, 215, 0.40);
}
</style>
