/* eslint-disabled */

export default class GuaranteeExtend {


    constructor(object?: any) {
    }

    postConstruct() {

    }
}
