<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <Base>
    <template v-slot:content>

      <div class="card mb-5 mb-xl-10">
        <div class="card-body pt-9 pb-0">
          <Loading v-if="isLoading"></Loading>
          <div class="d-flex flex-wrap flex-sm-nowrap mb-3" v-else-if="avenant">
            <div class="me-7 mb-4">
              <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <!--                <img :src="uploadRoot + contract.subscriber.photo" alt="image" class="object-cover"-->
                <!--                     v-if="contract.subscriber && contract.subscriber.photo">-->
                <!--                <img :src="uploadRoot + contract.insurer.photo" alt="image"-->
                <!--                     class="object-contain shadow-xs rounded"-->
                <!--                     v-else-if="contract.insurer && contract.insurer.photo">-->
                <div
                    class="w-100px h-100px h-lg-175px w-lg-160px d-flex align-items-center justify-content-center shadow-xs rounded">
                  <i class="fad fa-user fs-5x"></i>
                  <!--                                    <img  alt="image" class="object-cover" style="opacity: 0">-->
                </div>
                <div :class="{
                                'bg-success': avenant.status === CONSTANTS.AVENANT_STATUS.EN_COURS,
                                'bg-warning': avenant.status === CONSTANTS.AVENANT_STATUS.PROJET,
                                'bg-primary': avenant.status === CONSTANTS.AVENANT_STATUS.VALIDE
                            }"
                     class="position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-white h-20px w-20px">
                </div>
              </div>
            </div>
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-2">
                    <div class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                      <!--                      <i :class="contract.branch.icon + ' me-2 fs-2'"-->
                      <!--                         :style="{ 'color': contract.branch.color }"></i>-->
                      <!--                      <img :src="uploadRoot + contract.insurer.photo" :alt="contract.insurer.label"-->
                      <!--                           class="object-contain h-40px" v-if="contract.insurer && contract.insurer.photo">-->
                      <!--                      <template v-else>-->
                      <!--                        {{ avenant.insurer.label }}-->
                      <!--                      </template>-->
                      Avenant N°{{ avenant.numero }} ▪ {{ avenant.type }}
                    </div>
                    <router-link :to="{name: 'contract-show', params:{contractUuid: avenant.contractUuid}}"
                                 class="ms-5 btn btn-primary">
                      <i class="fad fs-2 fa-file-signature"></i> Contrat
                    </router-link>
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-2 pe-2">
                    <!--                    Police : N° {{ contract.police }}-->
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-2 pe-2" v-if="avenant.customer">
                    <router-link :to="{ name : 'customer-show', params: { uuid : avenant.customer.uuid } }" href="">
                      <i class="fad fa-user fs-2 me-5"></i>
                      {{ avenant.customer.identifier }}
                    </router-link>
                  </div>
                  <div class=" my-4">
                    <div class="fw-bolder d-flex align-items-center">
                      <div :title="readableDate(avenant.dateEffet, 'dmY')" v-b-tooltip.hover
                           v-if="avenant.dateEffet">
                        {{ readableDate(avenant.dateEffet, 'dmY') }}
                      </div>
                      <div class="mx-1 d-flex align-items-center">
                        ↔
                      </div>
                      <div :title="readableDate(avenant.dateEcheance, 'dmY')" v-b-tooltip.hover
                           v-if="avenant.dateEcheance">
                        {{ readableDate(avenant.dateEcheance, 'dmY') }}
                      </div>
                    </div>
                  </div>
                  <!--                                    <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">-->
                  <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 d-flex align-items-center" href="#">-->
                  <!--                                            <i class="fad fa-user me-2"></i>-->
                  <!--                                            Developer</a>-->
                  <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 d-flex align-items-center" href="#">-->
                  <!--                                           <i class="fad fa-map-marker me-2"></i>-->
                  <!--                                            SF, Bay Area-->
                  <!--                                        </a>-->
                  <!--                                        <a class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 d-flex align-items-center" href="#">-->
                  <!--                                            <i class="fad fa-at me-2"></i>-->
                  <!--                                            max@kt.com-->
                  <!--                                        </a>-->
                  <!--                                    </div>-->
                </div>
                <div class=" my-4 align-items-center">
                  <div :class="{
                                    'bg-success': avenant.status === CONSTANTS.AVENANT_STATUS.EN_COURS,
                                'bg-warning': avenant.status === CONSTANTS.AVENANT_STATUS.PROJET,
                                'bg-primary': avenant.status === CONSTANTS.AVENANT_STATUS.VALIDE
                                }" class="btn btn-sm btn-primary me-3">{{ avenant.status }}
                  </div>
                  <div class="mt-5">
                    <div class="btn btn-icon btn-warning">
                      <i class="fad fa-check-double"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap flex-stack">
                <div class="d-flex flex-column flex-grow-1 pe-8">
                  <div class="d-flex flex-wrap">
                    <div
                        class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                                            <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                                <i class="fad fa-coins"></i>
                                            </span>
                        <div class="fs-2 fw-bolder counted" data-kt-countup="true"
                             data-kt-countup-prefix="$" data-kt-countup-value="4500">
                          {{ currency(avenant.primeTTC) }}
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Prime TTC</div>
                    </div>


                    <div
                        class="border border-danger bg-light-danger border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                                            <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                                <i class="fad fa-coins text-danger"></i>
                                            </span>
                        <div class="fs-2 fw-bolder counted text-danger">
                          {{ currency(avenant.balance, true) }}
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-danger">Reste à Payer</div>
                    </div>

                    <div
                        class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                                            <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                                <i class="fad fa-coins"></i>
                                            </span>
                        <div class="fs-2 fw-bolder counted">
                          <!--                          {{ currency(contract.subscriber.contractValue) }}-->
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Valeur Client</div>
                    </div>
                  </div>
                  <!--end::Stats-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Info-->
          </div>

          <b-tabs active-nav-item-class="" active-tab-class="" nav-wrapper-class="nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap
                            flex-grow-1" v-model="tabIndex">
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-object-group fs-2 me-2"></i>
                  Actifs
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-file-contract fs-2 me-2"></i>
                  Documents
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadTransactions">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-coins fs-2 me-2"></i>
                  Paiements
                </div>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </div>


      <div class="card" key="avenants">
        <div class="card-body">
          <Loading v-if="isLoading"></Loading>


          <div v-if="tabIndex === 0">
            <table class="table table-striped gy-3 gs-3">
              <thead>
              <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                <th>Actif</th>
                <th>Assuré</th>
                <th>Pack</th>
                <th>Ajoute le</th>
                <th>Prime</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="item in avenantItems">
                <tr style="height: 1px"
                    v-if="item.model.className !== 'Customer' && !item.model.isRoot && (!item.parentItem || (item.parentItem && item.parentItem.expand))">
                  <td class="p-0">
                    <label :class="{ 'fw-bolder': itemHasChildren(item) }"
                           class="cursor-pointer w-100 h-100 d-flex align-items-center">
                      <input @change="toggleItemExpand(item, $event)" class="hidden"
                             type="checkbox" v-model="item.expand">
                      <template v-for="index in item.level">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </template>
                      <template v-if="itemHasChildren(item)">
                        <i class="fad fa-angle-right me-2" v-if="!item.expand"></i>
                        <i class="fad fa-angle-down me-2" v-else></i>
                      </template>
                      {{ item.model.name }}
                      <template v-if="!helper.empty(item.defaultAlias)">
                        {{ item.defaultAlias }}
                      </template>
                    </label>
                  </td>
                  <td>
                                            <span class="fw-bolder text-uppercase">
                                                <!--                                                <template v-if="item.beneficiary">-->
                                              <!--                                                    {{item.beneficiary.properties}}-->
                                              <!--                                                </template>-->
                                                {{ item.beneficiaryIdentifier }}
                                            </span>
                  </td>
                  <td>
                    <template v-if="item.pack">
                      {{ item.pack.insurer.label }} ▪ {{ item.pack.label }}
                    </template>
                  </td>
                  <td>
                    {{ readableDate(item.addedAt, 'dmY H:i') }}
                  </td>
                  <td class="text-end fw-bolder whitespace-no-wrap">
                    <template v-if="!helper.empty(item.premium)">
                      {{ currency(item.premium) }}
                    </template>
                    <span class="badge badge-secondary" v-else>{{
                        currency(item.calculatedPremium)
                      }}</span>
                  </td>
                  <td class="text-end">
                    <div class="d-flex justify-content-end">
                      <!--                                                <div class="btn btn-xs btn-icon btn-primary me-2">-->
                      <!--                                                    <i class="fad fa-edit"></i>-->
                      <!--                                                </div>-->

                      <b-dropdown class="" no-caret no-flip right tag="div"
                                  toggle-class="topbar-item text-decoration-none p-0 me-2" variant="link"
                                  v-if="item.model.printables.length > 0">
                        <template v-slot:button-content>
                          <div class="btn btn-xs btn-light-primary btn-icon rounded">
                            <i class="fad fa-print"></i>
                          </div>
                        </template>
                        <template #default="{ hide }">
                          <template v-for="printable, index in item.model.printables">
                            <!--                                                        <b-dropdown-divider v-if="index > 0"></b-dropdown-divider>-->
                            <!--                                                        <b-dd-text>-->
                            <div :class="{ 'border-top': index > 0 }"
                                 class="d-flex justify-content-between px-3 py-2 align-items-center">
                              <div class="whitespace-no-wrap flex-grow-1">
                                {{ printable.label }}
                              </div>
                              <router-link
                                  :to="{ name: 'printable-view', params: { itemUuid: item.uuid, printableUuid: printable.uuid } }"
                                  class="d-flex justify-content-end ms-5" target="_blank">
                                <div class="btn btn-xs btn-primary btn-icon mx-1">
                                  <i class="fad fa-eye"></i>
                                </div>
                              </router-link>
                              <!-- <a
                                  :href="coreEndpoint + 'item/printable/preview/' + item.uuid + '/' + printable.uuid + '?zone=' + $store.state.currentTenant.code"
                                  class="d-flex justify-content-end ms-5"
                                  target="_blank">
                                  <div class="btn btn-xs btn-primary btn-icon mx-1">
                                      <i class="fad fa-eye"></i>
                                      </div>
                                  <div class="btn btn-xs btn-success btn-icon mx-1">
                                      <i class="fad fa-download"></i>
                                      </div>
                                  </a> -->
                            </div>
                            <!--                                                        </b-dd-text>-->
                          </template>
                        </template>
                      </b-dropdown>
                      <router-link :to="{ name : 'item-viewer', params : {uuid : item.uuid, type: item.model.code} }"
                                   class="btn btn-xs btn-icon btn-light-dark">
                        <i class="fad fa-eye"></i>
                      </router-link>
                    </div>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>

          <div v-if="tabIndex === 1">
            <a target="_blank" :href="core + 'avenant/devis/' + $route.params.uuid +'?zone='+tenant.code"
               class="btn btn-primary" v-if="tenant">
              Devis
            </a>
          </div>
          <div v-if="tabIndex === 2">
            <div class="border border-dashed border-danger bg-light-danger p-5 m-5 rounded fs-2 text-danger"
                 v-if="avenant.balance < 0">
              <div class="fw-bold d-flex align-items-center">
                <i class="fad fa-money-bill fs-2x text-danger me-2"></i>
                Liens de paiements
              </div>
              <div>


                <div class="bg-light-primary my-3 p-2">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 d-flex align-items-center">
                      <i class="fad fa-ballot-check fs-2x"></i>
                      <span class="text-muted fs-5 ms-2">
                      Selection
                    </span>
                    </div>
                    <router-link target="_blank"
                                 :to="{name : 'avenant-payment',params:{uuid: $route.params.uuid, zone:tenant.code}}"
                                 class="fs-6 flex-grow-1 text-end fw-bolder text-primary">
                      {{ comparator + tenant.code + '/' + $route.params.uuid }}
                    </router-link>
                    <div class="ms-5">
                      <div class="btn btn-icon btn-sm btn-primary text-center">
                        <i class="fad fa-copy"></i>
                      </div>

                      <b-dropdown id="dropdown-1" variant="link"
                                  no-caret no-flip right>
                        <template v-slot:button-content>
                          <div class="btn btn-success ms-2 btn-sm btn-icon">
                            <i class="fad fa-paper-plane"></i>
                          </div>
                        </template>
                        <b-dropdown-text class="min-w-md-300px " tag="div">
                          <h4>Envoyer le raccourci au client</h4>
                        </b-dropdown-text>
                        <b-dropdown-text v-if="avenant.customer.mobile" class="">
                          <div class="d-flex align-items-center justify-content-between py-2 bg-hover-light-primary">
                            <div class="fw-bolder fs-2">
                              {{ phoneFormat(avenant.customer.mobile) }}
                            </div>
                            <div>
                              <div class="btn btn-success btn-icon ms-5 btn-sm">
                                <i class="fad fa-paper-plane"></i>
                              </div>
                            </div>
                          </div>
                        </b-dropdown-text>

                        <b-dropdown-text v-if="avenant.customer.fixe" class="">
                          <div class="d-flex align-items-center justify-content-between py-2 bg-hover-light-primary">
                            <div class="fw-bolder fs-2">
                              {{ phoneFormat(avenant.customer.fixe) }}
                            </div>
                            <div>
                              <div class="btn btn-success btn-icon ms-5 btn-sm">
                                <i class="fad fa-paper-plane"></i>
                              </div>
                            </div>
                          </div>
                        </b-dropdown-text>

                        <b-dropdown-text>
                          <div class="py-2 bg-hover-light-primary">
                            <b-input-group>
                              <b-form-input class="text-end" v-model="customContact" type="text"></b-form-input>
                              <b-input-group-append>
                                <div @click="sendPaymentLink(null,customContact)"
                                     class="btn btn-sm btn-icon border-radius-left-0 d-flex align-items-center btn-success h-100">
                                  <i class="fad fa-paper-plane"></i>
                                </div>
                              </b-input-group-append>
                            </b-input-group>
                          </div>
                        </b-dropdown-text>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
                <template v-for="pm in paymentMethods">
                  <div class="bg-light-primary my-3 p-2" :key="'pm-'+pm.uuid"
                       v-if="!pm.requireManagerValidation && pm.active">
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1  d-flex align-items-center">
                        <img :src="uploadRoot+pm.photo" class="w-30px h-30px rounded">
                        <span class="text-muted fs-5 ms-2">
                      {{ pm.label }}
                    </span>
                      </div>
                      <router-link target="_blank"
                                   :to="{name : 'avenant-payment',params:{uuid: $route.params.uuid, method:pm.slug, zone:tenant.code}}"
                                   class="fs-6 flex-grow-1 text-end fw-bolder text-primary">
                        {{ getLink(pm) }}
                      </router-link>
                      <div class="ms-5">
                        <div class="btn btn-icon btn-sm btn-primary text-center">
                          <i class="fad fa-copy"></i>
                        </div>

                        <b-dropdown id="dropdown-1" variant="link" :ref="'pm-button-'+pm.uuid"
                                    no-caret no-flip right>
                          <template v-slot:button-content>
                            <div class="btn btn-success ms-2 btn-sm btn-icon">
                              <i class="fad fa-paper-plane"></i>
                            </div>
                          </template>
                          <b-dropdown-text class="min-w-md-300px " tag="div">
                            <h4>Envoyer le raccourci au client</h4>
                          </b-dropdown-text>
                          <b-dropdown-text v-if="avenant.customer.mobile" class="">
                            <div class="d-flex align-items-center justify-content-between py-2 bg-hover-light-primary">
                              <div class="fw-bolder fs-2">
                                {{ phoneFormat(avenant.customer.mobile) }}
                              </div>
                              <div>
                                <div class="btn btn-success btn-icon ms-5 btn-sm"
                                     @click="sendPaymentLink(pm,avenant.customer.mobile)">
                                  <i class="fad fa-paper-plane"></i>
                                </div>
                              </div>
                            </div>
                          </b-dropdown-text>

                          <b-dropdown-text v-if="avenant.customer.fixe" class="">
                            <div class="d-flex align-items-center justify-content-between py-2 bg-hover-light-primary">
                              <div class="fw-bolder fs-2">
                                {{ phoneFormat(avenant.customer.fixe) }}
                              </div>
                              <div>
                                <div class="btn btn-success btn-icon ms-5 btn-sm"
                                     @click="sendPaymentLink(pm,avenant.customer.fixe)">
                                  <i class="fad fa-paper-plane"></i>
                                </div>
                              </div>
                            </div>
                          </b-dropdown-text>

                          <b-dropdown-text>
                            <div class="py-2 bg-hover-light-primary">
                              <b-input-group>
                                <b-form-input class="text-end" v-model="customContact" type="text"></b-form-input>
                                <b-input-group-append>
                                  <div @click="sendPaymentLink(pm,customContact)"
                                       class="btn btn-sm btn-icon border-radius-left-0 d-flex align-items-center btn-success h-100">
                                    <i class="fad fa-paper-plane"></i>
                                  </div>
                                </b-input-group-append>
                              </b-input-group>
                            </div>
                          </b-dropdown-text>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>
                </template>
              </div>

            </div>
            <table class="table table-striped gy-3 gs-3">
              <thead>
              <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                <th>Crée le</th>
                <th>Label</th>
                <th>Type</th>
                <th>Status</th>
                <th>Client</th>
                <th>Initiateur</th>
                <th>Payé le</th>
                <th>Source</th>
                <th>Montant</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="transaction in transactions">
                <td>
                  {{ readable(transaction.createdAt) }}
                </td>
                <td>
                  {{ transaction.label }}
                </td>
                <td>
                  {{ transaction.type }}
                </td>
                <td>
                  {{ transaction.status }}
                </td>
                <td>
                  <router-link :to="{ name: 'customer-show', params: { uuid: transaction.customerUuid } }"
                               class="fw-bolder">
                    {{ transaction.customerFullname }}
                  </router-link>
                </td>
                <td>
                  <user-view v-model="transaction.initiator"/>
                </td>
                <td>
                  <template v-if="transaction.payedAt">
                    {{ readable(transaction.payedAt) }}
                  </template>
                </td>
                <td>
                  <partner-view v-model="transaction.partner" v-if="transaction.partner"></partner-view>
                </td>
                <td>
                  {{ currency(transaction.amount) }}
                </td>
                <td>
                  <div class="btn btn-icon btn-primary btn-sm" @click="processPayment(transaction)" v-if="!transaction.payedAt">
                    <i class="fad fa-check-circle fs-2"></i>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from '@/services/Api';
import Loading from "@/components/Loading.vue";
import FileUpload from "@/components/FileUpload.vue";
import Avenant from "@/entity/Avenant";
import CONSTANTS from "@/Utils/constant";
import UserView from "@/components/UserView.vue";
import IncorporationDetail from "@/views/Contract/IncorporationDetail.vue";
import Swal from 'sweetalert2'
import Item from "@/entity/Item";
import Property from "@/entity/Property";
import PaymentMethod from "@/entity/PaymentMethod";
import store from "@/store";
import Popup from "@/entity/Popup";
import PaymentTransaction from "@/entity/PaymentTransaction";
import PartnerView from "@/components/PartnerView.vue";

@Component({
  components: {PartnerView, IncorporationDetail, FileUpload, Loading, Base, UserView}
})
export default class AvenantShow extends Vue {
  avenant = new Avenant()
  coreEndpoint = api.core
  uploadRoot = api.uploadRoot
  core = api.core
  comparator = api.comparator
  isLoading = false
  customContact = ''
  tabIndex = 0
  itemList: Item[] = []
  paymentMethods: PaymentMethod[] = []
  transactions: PaymentTransaction[] = []
  tenant: any = null

  mounted() {

    this.loadData()
    this.tenant = this.$store.getters.currentTenant()
  }

  async loadTransactions() {
    const res = await api.get(api.core, 'avenant/get/transactions/' + this.$route.params.uuid)
    if (res && res.data && res.data.transactions) {
      this.transactions = []
      res.data.transactions.forEach((t: any) => {
        const occ = new PaymentTransaction(t)
        this.transactions.push(occ)
      })
    }
  }

  // getLink(pm: PaymentMethod | null) {
  //
  //   // name : 'avenant-payment',params:{uuid: $route.params.uuid, method:pm.slug, zone:tenant.code}
  //   const host = window.location.host;
  //   if (pm) {
  //     const route: any = this.$router.resolve({
  //       name: 'avenant-payment',
  //       params: {uuid: this.$route.params.uuid, method: pm.slug, zone: this.tenant.code}
  //     });
  //
  //     return host + route.href
  //   } else {
  //     const route: any = this.$router.resolve({
  //       name: 'avenant-payment',
  //       params: {uuid: this.$route.params.uuid, zone: this.tenant.code}
  //     });
  //
  //     return host + route.href
  //   }
  // }

  async processPayment(payment: PaymentTransaction) {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'payment/process/' + payment.uuid)
    this.$store.commit('stopLoading')
    if (!res) {
      const response = await Swal.fire({
        title: 'Transaction Invalide',
        html: "Voulez vous forcer le paiement ? L'avenant lié sera emit et la transaction sera enregistrée comme payée",
        timer: 30000,
        icon: "error",
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: 'Forcer le paiement',
        didOpen: () => {
          // Swal.showLoading()
          // const b = Swal.getHtmlContainer().querySelector('b')
          // timerInterval = setInterval(() => {
          //     b.textContent = Swal.getTimerLeft()
          // }, 100)
        },
        willClose: () => {
          // clearInterval(timerInterval)
        }
      })
      if (response.isConfirmed) {
        this.forceProcessPayment(payment)
      }
    }

  }

  async forceProcessPayment(payment: PaymentTransaction) {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'payment/force/process/' + payment.uuid)
    this.$store.commit('stopLoading')
    if (res && res.data && res.data.contract) {
      this.$router.push({
        name: 'contract-show',
        params: {
          contractUuid: res.data.contract
        }
      })
    }
  }

  getLink(pm: PaymentMethod | null) {

    let route = api.comparator + 'avenant/payment/' + this.$route.params.uuid;
    if (pm) {
      route += '/' + pm.slug
    }
    return route
  }

  async loadData() {
    const res = await api.get(api.core, 'avenant/get/data/' + this.$route.params.uuid)
    if (res && res.data) {
      this.avenant = new Avenant(res.data.avenant)
      if (this.avenant.balance < 0) {
        this.tabIndex = 2
        this.loadTransactions()
      }
      this.paymentMethods = []
      res.data.paymentMethods.forEach((pm: any) => {
        const occ = new PaymentMethod(pm)
        this.paymentMethods.push(occ)
      })
    }
  }

  async sendPaymentLink(pm: PaymentMethod | null, contact: string) {
    this.$store.commit('loading')
    const link = this.getLink(pm);
    if (pm) {
      const ref = 'pm-button-' + pm.uuid;
      const refs = this.$refs as any
      if (refs.hasOwnProperty(ref)) {
        console.log('hide');
        refs[ref][0].hide()
      }
    }
    const res = await api.post(api.core, 'avenant/send/payment/short', {
      uuid: this.$route.params.uuid,
      link: link,
      contact: contact
    })
    this.$store.commit('stopLoading')
    if (res && res.data) {
      const pop = new Popup('SMS Envoyé', 'Un lien de paiement à été envoyé au ' + contact, 'primary', 'fad fa-sms')
    }
  }

  get avenantItems() {
    if (this.avenant && this.avenant.root) {
      // console.log(this.getItemByLines(this.contract.root))
      return this.getItemByLines(this.avenant.root)
    } else {
      return []
    }
  }


  getItemByLines(item: Item, result?: Item[]) {

    if (!result) {
      result = []
    }
    if (!result.includes(item)) {
      result.push(item)
    }
    item.properties.forEach((p: Property) => {
      p.children.forEach((c: Item) => {
        c.parentItem = item
        c.level = item.level + 1
        this.getItemByLines(c, result)
      })
    })
    return result
  }

  async generatePaymentLink() {
    const res = await api.get(api.core, 'avenant/generate/payment/link/' + this.$route.params.uuid)
  }

  itemHasChildren(item: Item) {
    // let result = false
    return item.properties.some((p: Property) => p.children.length > 0)
    // item.properties.forEach((p: Property) => {
    //     if (p.children.length > 0) {
    //         result = true
    //     }
    // })
    // return result
  }

  toggleItemExpand(item: Item, evt: Event) {
    // console.log(evt)
    // console.log(evt instanceof Event)
    let value: any = evt
    if (evt instanceof Event) {
      value = (evt.target as any).checked as any
    } else {
      item.expand = value
      // console.log('hide ' + item.name)
    }
    item.properties.forEach((p: Property) => {
      p.children.forEach((c: Item) => {
        const index = this.itemList.findIndex((i: Item) => {
          return i.uuid === item.uuid
        })
        if (index !== -1) {
          this.itemList[index].expand = item.expand
          this.toggleItemExpand(c, value)
        }
      })
    })
  }

}
</script>
<style scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all .2s;
}

.slide-left-leave-from {
  transition: all .2s;
}

.slide-left-enter {
  opacity: 0;
  transform: translateY(50%);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}

.modal-xl .modal-content {
  height: 100%;
}</style>
