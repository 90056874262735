<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class ComparisonOfferDetailLine extends Vue {

  @Prop() detail!: any
  expand = false

  mounted() {
  }

  addSub(sub: any) {
    if (!sub) {
      console.log('no sub')
      return
    }
    if (sub) {
      this.$emit('addSub', sub)
    }
  }
}
</script>

<template>
  <div class="flex-grow-1" :class="{ 'bg-light-primary' : expand }" style="border-bottom:1px whitesmoke solid">
    <label class="d-flex justify-content-between align-items-center flex-grow-1 cursor-pointer">
      <span class="p-2 d-flex align-items-center fw-bolder">
        {{ detail.name }}
      </span>
      <span class="text-end p-2 d-flex align-items-center ms-5">
        {{ helper.currencyFormat(detail.value) }}
        <i class="fad fa-caret-right ms-5 cursor-pointer fs-2" v-if="detail.details && detail.details.length > 0"></i>
        <i class="fad fa-caret-right ms-5 cursor-pointer fs-2 text-white" v-else></i>
      </span>
      <input type="checkbox" v-model="expand" class="hidden">
    </label>
    <div v-if="expand" class="" style="border-top:1px var(--bs-blue) solid;">
      <template v-for="sub in detail.details" >
        <div @click="addSub(sub)" style="border-bottom:1px var(--bs-blue) solid;border-right:1px var(--bs-blue) solid;border-left:15px var(--bs-blue) solid"
            class="ps-3 py-1 d-flex align-items-center justify-content-between cursor-pointer">
          <div class="d-flex align-items-center fw-bolder text-uppercase">
            <div>
              {{ sub.item }}
            </div>
            <div>
              &nbsp; - &nbsp;
            </div>
            <div class="fw-bolder">
              {{ sub.alias }}
            </div>
          </div>
          <div>
            <i class="fad fa-caret-right me-2 ms-5 cursor-pointer fs-2" v-if="sub.details && sub.details.length > 0"></i>
          </div>
        </div>
        <!--        <template v-for="subDetail in sub.details">-->
        <!--          <ComparisonOfferDetailLine :detail="subDetail" v-on:addSub="addSub"/>-->
        <!--        </template>-->
      </template>
    </div>
  </div>
</template>

<style scoped>

</style>
