// @ts-ignore
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};
// @ts-ignore
Number.prototype.addLeadZero = function (text, length) {
    text = '' + text;
    while (text.length < length) {
        text = '0' + text;
    }
    return text;
};
export const DATES = [
    {
        name: "Aujourd'hui",
        code_text: "today",
        code_int: 0,
    },
    {
        name: "Demain",
        code_text: "tomorrow",
        code_int: 1,
    },
    {
        name: "Debut du mois en cours",
        code_text: "first_day_current_month",
        code_int: 2,
    },
    {
        name: "Fin du mois en cours",
        code_text: "last_day_current_month",
        code_int: 3,
    },
    {
        name: "Début du mois prochain",
        code_text: "first_day_next_month",
        code_int: 4,
    },
    {
        name: "Fin du mois prochain",
        code_text: "last_day_next_month",
        code_int: 5,
    },
    {
        name: "Après Demain",
        code_text: "after_tomorrow",
        code_int: 1,
    },
];

const CONSTANTS: any = {
    dateConstants: {
        today: () => new Date(),
        tomorrow: () => {
            const now = new Date();
            // @ts-ignore
            return now.addDays(1);
        },
        after_tommorow: () => {
            const now = new Date();
            // @ts-ignore
            return now.addDays(2);
        },
        first_day_current_month: () => {
            var date = new Date(),
                y = date.getFullYear(),
                m = date.getMonth();
            return new Date(y, m, 1);
        },
        last_day_current_month: () => {
            var date = new Date(),
                y = date.getFullYear(),
                m = date.getMonth();
            return new Date(y, m + 1, 0);
        },
        first_day_next_month: () => {
        },
        last_day_next_month: () => {
        },
        last_day_current_year: () => {
        },
    },
    modelPropertyTypes: {
        text: "text",
        number: "number",
        boolean: "boolean",
        object: "object",
        date: "date",
    },
    responseGroupStatus: {
        INCOMPLETE: "Incomplet",
        REACHABLE: "Joignable",
        COMPARED: "Comparé",
        OFFER_SELECTED: "Offre Sélectionnée",
        PAYMENT_INITIALIZED: "Paiement Initialisé",
        VALIDATED: "Gagné",
        EXPIRED: "Expiré",
        ABORTED: "Perdu",
    },
    responseGroupStatusColor: {
        INCOMPLETE: '#FFFFFF',
        REACHABLE: "#ffd160",
        COMPARED: "#fd7e14",
        OFFER_SELECTED: "#6610f2",
        PAYMENT_INITIALIZED: "#0DD1E3FF",
        VALIDATED: "#198754",
        EXPIRED: "#ce1b3d",
        ABORTED: "#ce1b3d",
    },
    fileExtensions: [],
    todoTypes: [
        "CONTACT_CUSTOMER",
        "QUALIFY_CUSTOMER",
        "SEND_CUSTOMER_OFFER",
        "CLOSE_QUOTE",
        "CUSTOM",
    ],
    CONTRACT_STATUS: {
        QUOTATION: "DEVIS",
        ONGOING: "EN_COURS",
        EXPIRED: "EXPIRE",
        CANCELLED: "RESILIE",
        PAUSED: "SUSPENDU",
        WAITING: "EN_ATTENTE",
        RENEW: "RENUVELLE",
    },
    AVENANT_STATUS: {
        PROJET: "PROJET",
        VALIDE: "VALIDE",
        EN_COURS: "EN_COURS",
    },
    PAYMENT_STATUS: {
        WAITING: "En attente",
        FAILED: "Echouée",
        SUCCEED: "Réussie",
    },
    orderStatus: {
        CREATED: "Crée",
        WAITING: "En attente",
        FAILED: "Echoué",
        PAYED: "Payé",
        EXPIRED: "Expiré",
    },
    orderStatusCode: {
        CREATED: "CREATED",
        WAITING: "WAITING",
        FAILED: "FAILED",
        PAYED: "PAYED",
        EXPIRED: "EXPIRED",
    },
};
export const WORKFLOWS = {
    'ASACI': 'attestation_digitale_automobile'
}

export default CONSTANTS;
