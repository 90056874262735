<template>
  <Base>
    <template v-slot:content>

      <h1>Versions</h1>
      <div class="d-flex">

        <div v-for="engine in engines" class="d-flex flex-column">
          <router-link
              :to="{ name: 'engine-method-list', params: { branchSlug: engine.branchSlug, engineVersion: engine.version } }"
              class="card shadow-sm cursor-pointer min-w-175px text-gray-400 bg-hover-primary text-hover-white m-5"
          >
            <div class="card-body justify-content-center d-flex flex-column align-items-center">
              <div>
                <i class="fad fa-cogs fs-3x"></i>
              </div>
              <div class="mt-5 d-flex flex-column justify-content-center align-items-center fw-bolder fs-6">
                            <span v-if="!helper.empty(engine.name)">
                                {{ engine.name }}
                            </span>
                <span v-else>
                                -
                            </span>
                <span class="fs-8">
                                v.{{ engine.version }}
                            </span>
              </div>
            </div>
          </router-link>
          <div class="d-flex">
            <div v-if="branch && engine.version === branch.version"
                 class="rounded bg-secondary text-center cursor-pointer btn-sm mx-5 flex-grow-1" disabled>
              Par Default
            </div>
            <div v-else class="btn btn-primary btn-sm mx-5 flex-grow-1" @click="setActive(engine)">
              Par defaut
            </div>
            <div class="btn btn-danger btn-sm btn-icon" @click="deleteEngine(engine)" v-if="hasRole('SUPER_ADMIN')">
              <i class="fad fa-trash"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <div class="card shadow-sm cursor-pointer bg-primary text-white w-150px  m-5" v-b-modal="'engineModal'">
          <div class="card-body justify-content-center d-flex flex-column align-items-center">
            <i class="fad fa-plus text-white fs-4x"></i>
            <div class="mt-5">
              Créer
            </div>
          </div>
        </div>
      </div>


      <b-modal :title="trans('Moteur de calcul')" centered id="engineModal" ref="engineModal">
        <form>
          <div class="">
            <div class="col-auto m-2 flex-grow-1">
              <label class="form-label">{{ trans('Nom') }}</label>
              <div>
                <input class="form-control" type="text" v-model="engine.name">
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label class="form-label">{{ trans('Description') }}</label>
              <div>
                            <textarea class="form-control" type="text" v-model="engine.description">
                                    </textarea>
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label class="form-label">{{ trans('Dupliquer') }}</label>
              <div>
                <select class="form-control form-select" v-model="engine.model">
                  <option :value="null">Nouveau</option>
                  <option :value="occ.id" :key="occ.id" v-for="occ in engines">
                    {{ occ.name }} - v{{ occ.version }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editEngine()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Branch from "@/entity/Branch";
import {api} from "@/services/Api";
import Engine from "@/entity/Engine";
import Insurer from '@/entity/Insurer';

@Component({
  components: {Base}
})
export default class EngineList extends Vue {
  branch!: Branch
  engine = new Engine()
  engines: Engine[] = []
  uploadRoot = api.uploadRoot
  insurers: Insurer[] = []

  mounted(): void {
    console.log(this.$route.params)
    this.loadEngines()
  }

  async setActive(engine: Engine) {
    const res = await api.get(api.core, 'engine/set/active/' + engine.id)
    this.loadEngines()
  }

  async loadEngines() {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'engine/list/' + this.$route.params.branchSlug)
    if (res && res.data) {
      this.branch = new Branch(res.data.branch)
      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        const occ = new Insurer(i)
        this.insurers.push(occ)
      })
      this.engines = []
      res.data.engines.forEach((e: Engine) => {
        const occ = new Engine(e)
        this.engines.push(occ)
      })
    }
    this.$store.commit('stopLoading')
  }

  async deleteEngine(engine: Engine) {
    const res = await api.get(api.core, 'engine/delete/' + engine.id)
  }

  async editEngine() {

    // console.log(this.engine)
    // return
    this.$store.commit('loading')
    const res = await api.post(api.core, 'engine/edit/' + this.$route.params.branchSlug, {engine: this.engine})
    this.engine = new Engine()
    if (this.$refs.engineModal) {
      (this.$refs.engineModal as any).hide()
    }

    this.$store.commit('stopLoading')
    this.loadEngines()

  }


}
</script>
<style scoped>

</style>
