<template>
  <div class=" w-100">
    <div :style="{ 'background': line.ranking % 2 === 0 ? 'whitesmoke' : 'white' }"
         class=" border-bottom px-5 py-3 position-relative line d-flex align-items-center fw-bolder if-line"
         v-if="!$store.state.showCode">
      <div class="handle">
        <i class="fad fa-arrows me-5"></i>
      </div>

      <b-dropdown class="position-absolute add-line-before d-none" no-caret no-flip right
                  style="top:-10px;right:100px;" tag="div" toggle-class="topbar-item text-decoration-none p-0"
                  variant="link">
        <template v-slot:button-content>
          <div class="btn btn-xs btn-primary btn-icon rounded-circle">
            <i class="fad fa-plus"></i>
          </div>
        </template>
        <b-dd-item @click="addTextLineBefore">Ajouter un Texte</b-dd-item>
        <b-dd-item @click="addDefaultLineBefore">Ajouter un Calcul</b-dd-item>
        <b-dd-item @click="addConditionLineBefore">Ajouter une condition</b-dd-item>
        <b-dd-item @click="addCallableLineBefore">Ajouter une fonction</b-dd-item>
        <b-dd-item @click="addForeachLineBefore">Ajouter une iteration</b-dd-item>
      </b-dropdown>


      <!--            <div @click="addLineBefore()" class="btn-primary rounded-pill btn btn-xs btn-icon position-absolute add-line-before d-none"-->
      <!--                 style="top:-10px;right:75px;" title="Ajouter une ligne ici" v-b-tooltip.hover>-->
      <!--                <i class="fad fa-plus"></i>-->
      <!--            </div>-->
      <div v-for="index in level">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <label class="cursor-pointer flex-grow-1" style="line-height: 2em">
        <input class="hidden" type="checkbox" v-model="expand" :key="'line_check_' + helper.generateId()">
        <i class="fad fa-angle-right" v-if="!expand"></i>
        <i class="fad fa-angle-down" v-else></i>
        Si

        <span class="flex-wrap flex-grow-1 align-items-center">
                    <template v-for="group, gindex in line.conditionGroups">
                        <span class="fs-8 mx-1" v-if="gindex > 0">
                            OU
                        </span>
                        <span class="align-items-center flex-wrap bg-secondary p-1 rounded m-1">
                            <template v-for="condition, cindex in group.conditions">
                                <span class="fs-8 mx-1 b" v-if="cindex > 0">
                                    ET
                                </span>
                                <span :class="{ 'text-danger blink': condition.first && !condition.first.allowed }"
                                      :title="condition.first && !condition.first.allowed && condition.first.itemType ?
                                        'La propriété <b>' + condition.first.name + '</b> requiert un objet <b>' + condition.first.itemType.name + '</b>' : ''"
                                      class=" align-items-center" v-b-tooltip.html>
                                    <template v-if="condition.first">
                                        {{
                                        helper.empty(condition.first.alias) ? condition.first.name :
                                            condition.first.alias
                                      }}
                                    </template>
                                    <template v-if="condition.operator">
                                        {{ condition.operator.symbol }}
                                    </template>
                                    <template v-if="!helper.empty(condition.second)">
                                        <template v-if="isConditionNumeric(condition)">
                                            {{ helper.currencyFormat(condition.second, false, false) }}
                                        </template>
                                        <template v-if="isConditionChoices(condition)">
                                            {{ condition.second.label }}
                                        </template>
                                        <template v-if="isConditionBoolean(condition)">
                                            {{ condition.second ? 'Oui' : 'Non' }}
                                        </template>
                                    </template>
                                </span>
                            </template>
                        </span>
                    </template>
                </span>
      </label>
      <div class="d-flex align-items-center ">
        <div @click="loadStored" class="btn btn-xs btn-icon btn-primary ms-5" v-b-modal="'conditionModal_' + uKey">
          <i class="fad fa-edit"></i>
        </div>
        <div @click="removeLine" class="btn btn-xs btn-icon btn-danger ms-1">
          <i class="fad fa-trash"></i>
        </div>
        <div :title="'Ligne ' + line.ranking" class="text-dark badge badge-warning ms-1" v-b-tooltip.hover.left>
                    <span>
                        {{ line.ranking }}
                    </span>
        </div>
      </div>
    </div>
    <div class="d-flex" v-else>
      <div v-for="index in (level + 1)">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <span class="fw-bolder">
                If (
            </span>
      <span class="flex-wrap align-items-center">
                <template v-for="group, gindex in line.conditionGroups">
                    <span class="fs-8 mx-1" v-if="gindex > 0">
                        OU
                    </span>
                    <span class="fw-bolder" v-if="line.conditionGroups.length > 1">(</span>
                    <span class="align-items-center flex-wrap">
                        <template v-for="condition, cindex in group.conditions">
                            <span class="fs-8 mx-1 b" v-if="cindex > 0">
                                ET
                            </span>
                            <span class="align-items-center">
                                <span class="fw-bolder text-primary " v-if="condition.first">
                                    {{
                                    helper.empty(condition.first.alias) ? condition.first.name : condition.first.alias
                                  }}
                                </span>
                                <template v-if="condition.operator">
                                    {{ condition.operator.symbol }}
                                </template>
                                <template v-if="!helper.empty(condition.second)">
                                    <span class="fw-bolder" v-if="isConditionNumeric(condition)">
                                        {{ condition.second }}
                                    </span>
                                    <span class="fw-bolder" v-if="isConditionChoices(condition)">
                                        "{{ condition.second.label }}"
                                    </span>
                                    <span class="fw-bolder" v-if="isConditionBoolean(condition)">
                                        {{ condition.second ? 'true' : 'false' }}
                                    </span>
                                </template>
                            </span>
                        </template>
                    </span>
                    <span class="fw-bolder" v-if="line.conditionGroups.length > 1">)</span>
                </template>
            </span>
      <span class="fw-bolder">) { </span>
    </div>
    <div v-if="expand">
      <draggable :animation="200" :group="line.uuid" :list="line.subLines" @end="updateLineNumber" class=""
                 filter=".action-button" ghost-class="bg-light-primary" handle=".handle" tag="div">
        <EngineLineEditor :key="'sub_line_' + line.uuid" :level="level + 1" :line="line" v-for="line in line.subLines"
                          v-on:addLine="addLine" v-on:addLineBefore="addSubLineBefore" v-on:addSubLine="addSubLine"
                          v-on:removeLine="removeSubLine" v-on:updateLineNumber="updateLineNumber"></EngineLineEditor>
      </draggable>
    </div>

    <div v-if="$store.state.showCode">
            <span class="fw-bolder" v-for="index in (level + 1)">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
      <span class="fw-bolder">
                }
            </span>
    </div>

    <div class="position-relative line d-flex align-items-center fw-bolder end-if py-3 bg-light-danger" v-if="expand">
      <!--            <div @click="addSubLine" class="btn-primary rounded-pill btn btn-xs btn-icon position-absolute add-line-before d-none"-->
      <!--                 style="top:-10px;right:75px;" title="Ajouter une ligne ici" v-b-tooltip.hover>-->
      <!--                <i class="fad fa-plus"></i>-->
      <!--            </div>-->

      <b-dropdown class="position-absolute add-line-before d-none" no-caret no-flip right
                  style="top:-10px;right:75px;" tag="div" toggle-class="topbar-item text-decoration-none p-0"
                  v-if="!$store.state.showCode" variant="link">
        <template v-slot:button-content>
          <div class="btn btn-xs btn-primary btn-icon rounded-circle">
            <i class="fad fa-plus"></i>
          </div>
        </template>
        <b-dd-item @click="addTextSubLine">Ajouter un Texte</b-dd-item>
        <b-dd-item @click="addDefaultSubLine">Ajouter un Calcul</b-dd-item>
        <b-dd-item @click="addConditionSubLine">Ajouter une condition</b-dd-item>
        <b-dd-item @click="addCallableSubLine">Ajouter une fonction</b-dd-item>
        <b-dd-item @click="addForeachSubLine">Ajouter une iteration</b-dd-item>
      </b-dropdown>
      <!--            Fin de la condition-->
    </div>

    <div class="d-flex flex-wrap px-5 py-1 bg-light-danger align-items-center" v-if="errorMessages.length > 0">
      <div class="text-gray-400 fs-8  rounded px-2">
        Erreur(s) :
      </div>
      <div class="bg-danger m-1 fs-8 text-white rounded px-2" v-for="error in errorMessages">
        {{ error }}
      </div>
    </div>

    <b-modal :id="'conditionModal_' + uKey" :ref="'conditionModal_' + uKey" :title="trans('Condition')" centered
             class="modal-fullscreen" content-class="modal-fullscreen" scrollable size="xl">
      <form action="">
        <template v-for="group, gindex in line.conditionGroups">
          <div :key="'group_' + group.uuid" class="d-flex justify-content-center">
            <div class="badge badge-secondary" v-if="gindex > 0">OU</div>
          </div>
          <div class="border-dashed p-2 m-2 rounded bg-light-success position-relative">
            <div @click="removeGroup(group)"
                 class="position-absolute bg-danger px-3  cursor-pointer rounded-top"
                 style="bottom:100%;right:0">
              <i class="fad fa-times text-white"></i>
            </div>
            <div class="d-flex align-items-center flex-wrap">
              <template v-for="condition, index in group.conditions">
                <div :key="'condition_' + condition.uuid" class="badge badge-secondary" v-if="index > 0">ET
                </div>
                <div class="bg-secondary rounded px-2 m-2 d-flex align-items-center">
                  <!--                                    <b-form-select class="form-control form-select">-->
                  <!--                                        <b-form-select-option :value="null">{{trans('Select_a_type')}}-->
                  <!--                                        </b-form-select-option>-->
                  <!--                                    </b-form-select>-->


                  <b-dropdown no-caret no-flip right size="sm" tag="div"
                              toggle-class="topbar-item text-decoration-none" variant="link">
                    <template v-slot:button-content>
                      <div class="btn btn-sm btn-clean btn-dropdown btn-primary">
                        <template v-if="helper.empty(condition.first)">
                          {{ trans('Propriété') }}
                        </template>
                        <template v-else>
                          {{
                            helper.empty(condition.first.alias) ? condition.first.name :
                                condition.first.alias
                          }}
                        </template>
                      </div>
                    </template>
                    <template #default="{ hide }">
                      <b-dropdown-text class="p-0 min-w-300px" tag="div">
                        <b-tabs active-nav-item-class="bg-primary text-white" active-tab-class=""
                                fill nav-wrapper-class="">
                          <b-tab :title-link-class="''" title="Objets">
                            <div class="row g-0 overflow-auto mh-325px px-5">
                              <template v-for="type in $store.state.sources">
                                <div v-if="type.isRoot || type.required || type.code === 'partner'">
                                  <ItemTypeClickInspector :type="type"
                                                          @setProperty="importProperty(condition, $event); hide()">
                                  </ItemTypeClickInspector>
                                </div>
                              </template>
                              <template v-for="req in $store.state.engineMethod.requirements">
                                <div v-if="req.itemType">

                                  <ItemTypeClickInspector :type="req.itemType"
                                                          @setProperty="importProperty(condition, $event); hide()">
                                  </ItemTypeClickInspector>
                                  <!--                                  <label-->
                                  <!--                                      :class="{ 'text-primary': !req.itemType.expand }"-->
                                  <!--                                      class="fw-bolder cursor-pointer text-hover-primary w-100 py-2">-->
                                  <!--                                    <input class="hidden" type="checkbox"-->
                                  <!--                                           v-model="req.itemType.expand">-->
                                  <!--                                    <i class="fad fa-angle-right"-->
                                  <!--                                       v-if="!req.itemType.expand"></i>-->
                                  <!--                                    <i class="fad fa-angle-down" v-else></i>-->
                                  <!--                                    {{ req.itemType.name }}-->
                                  <!--                                  </label>-->
                                  <!--                                  <div class="d-flex flex-wrap">-->
                                  <!--                                    <template v-for="prop in req.itemType.properties"-->
                                  <!--                                              v-if="req.itemType.expand">-->
                                  <!--                                      <div @click="setProperty(condition, req.itemType, prop, 'input'); hide()"-->
                                  <!--                                           class="px-5 m-1 badge badge-primary cursor-pointer">-->
                                  <!--                                        {{ prop.name }}-->
                                  <!--                                      </div>-->
                                  <!--                                    </template>-->
                                  <!--                                  </div>-->

                                </div>
                              </template>
                            </div>
                          </b-tab>
                          <b-tab :title-link-class="''" title="Resultats">
                            <template v-for="result in $store.state.returnArguments">
                              <div @click="setPropertyFromResult(condition, result); hide()"
                                   class="py-2 px-2 cursor-pointer text-hover-primary fw-bolder"
                                   v-if="getLineByUuid(result.resultOfLine) !== null && getLineByUuid(result.resultOfLine).ranking < line.ranking">
                                <template v-if="helper.empty(result.alias)">
                                  Ligne {{ getLineByUuid(result.resultOfLine).ranking }} :
                                  {{ result.name }}
                                </template>
                                <template v-else>
                                  Ligne {{ getLineByUuid(result.resultOfLine).ranking }} :
                                  {{ result.alias }}
                                </template>

                              </div>
                            </template>
                          </b-tab>
                        </b-tabs>
                      </b-dropdown-text>
                    </template>
                  </b-dropdown>

                  <b-dropdown :disabled="helper.empty(condition.first)" no-caret no-flip right size="sm"
                              tag="div" toggle-class="topbar-item text-decoration-none" variant="link">
                    <template v-slot:button-content>
                      <div class="btn btn-sm btn-clean btn-dropdown btn-primary  mx-1">
                        <template v-if="!helper.empty(condition.operator)">
                          {{ condition.operator.name }}
                        </template>
                        <template v-else>
                          Opérateur
                        </template>
                      </div>
                    </template>
                    <b-dropdown-item @click="setOperator(condition, operator)" class="whitespace-no-wrap"
                                     tag="div" :key="'operator-' + helper.generateId()"
                                     v-for="operator in getAllowedOperators(condition)">
                      {{ operator.name }}
                    </b-dropdown-item>
                  </b-dropdown>


                  <b-dropdown no-caret no-flip right size="sm" tag="div"
                              toggle-class="topbar-item text-decoration-none" v-if="isConditionChoices(condition)"
                              variant="link">
                    <template v-slot:button-content>
                      <div class="btn btn-sm btn-clean btn-dropdown btn-primary  mx-1">
                        <template v-if="!helper.empty(condition.second)">
                          {{ condition.second.label }}
                        </template>
                        <template v-else>
                          Opérande
                        </template>
                      </div>
                    </template>
                    <b-dropdown-item @click="setSecond(condition, choice)" class="whitespace-no-wrap"
                                     :key="'choice-' + choice.id" tag="div" v-for="choice in condition.first.choices">
                      {{ choice.label }}
                    </b-dropdown-item>
                  </b-dropdown>

                  <b-dropdown no-caret no-flip right size="sm" tag="div"
                              toggle-class="topbar-item text-decoration-none"
                              v-else-if="isConditionBoolean(condition)" variant="link">
                    <template v-slot:button-content>
                      <div class="btn btn-sm btn-clean btn-dropdown btn-primary  mx-1">
                        <template v-if="!helper.empty(condition.second)">
                          {{ condition.second ? 'Oui' : 'Non' }}
                        </template>
                        <template v-else>
                          Opérande
                        </template>
                      </div>
                    </template>
                    <b-dropdown-item @click="setSecond(condition, true)" class="whitespace-no-wrap"
                                     tag="div">
                      Oui/Vrai
                    </b-dropdown-item>
                    <b-dropdown-item @click="setSecond(condition, false)" class="whitespace-no-wrap"
                                     tag="div">
                      Non/Faux
                    </b-dropdown-item>
                  </b-dropdown>

                  <b-form-input @change="check" class="w-100px" type="number"
                                v-else-if="isConditionNumeric(condition)" v-model="condition.second"></b-form-input>


                  <div class="d-flex ms-1">
                    <div @click="removeCondition(condition, group)"
                         class="btn btn-danger btn-icon btn-sm">
                      <i class="fad fa-trash"></i>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="d-flex justify-content-center mt-5">
              <div @click="addCondition(group)" class="badge badge-success cursor-pointer">
                <i class="fad fa-plus text-white"></i>
                ET
              </div>
            </div>
          </div>
        </template>
        <div class="d-flex justify-content-center mt-5">
          <div @click="addGroup" class="badge badge-success cursor-pointer">
            <i class="fad fa-plus text-white"></i>
            OU
          </div>
        </div>
      </form>

      <template #modal-footer="{ ok, cancel, hide }">
        <b-button @click="clearPaste" size="sm" v-if="$store.state.copiedConditionGroups" variant="danger">
          <i class="fad fa-paste"></i>
          {{ trans('Annuler') }}
        </b-button>
        <b-button @click="pasteGroups()" size="sm" v-if="$store.state.copiedConditionGroups" variant="warning">
          <i class="fad fa-paste"></i>
          {{ trans('Coller') }}
        </b-button>
        <b-button @click="copyGroups(); hide()" size="sm" v-else variant="warning">
          <i class="fad fa-copy"></i>
          {{ trans('Copier les conditions') }}
        </b-button>
        <b-button @click="cancel()" size="sm" variant="secondary">
          <i class="fad fa-times"></i>
          {{ trans('Cancel') }}
        </b-button>
        <b-button @click="hide()" size="sm" variant="primary">
          <i class="fad fa-save"></i>
          {{ trans('Enregistrer') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import EngineMethodLine from "@/entity/EngineMethodLine";
import EngineConditionGroup from "@/entity/EngineConditionGroup";
import EngineCondition from "@/entity/EngineCondition";
import {helper} from "@/services/Helper";
import ItemProperty from "@/entity/ItemProperty";
import ItemType from "@/entity/ItemType";
import EngineArgument from "@/entity/EngineArgument";
import Engine from "@/entity/Engine";
import {api} from "@/services/Api";
import Popup from "@/entity/Popup";
import Pack from "@/entity/Pack";
import PackCategory from "@/entity/PackCategory";

@Component({
  components: {}
})
export default class EngineConditionLineEditor extends Vue {
  @Prop() line!: EngineMethodLine
  @Prop() number!: number
  @Prop() level!: number
  expand = true
  numericOperators = [
    {'name': 'Egal', 'symbol': '=='},
    {'name': 'Inférieur', 'symbol': '<'},
    {'name': 'Inférieur ou égal', 'symbol': '<='},
    {'name': 'Supérieur', 'symbol': '>'},
    {'name': 'Supérieur ou égal', 'symbol': '>='},
    {'name': 'Différent', 'symbol': '!='},
  ]
  errorMessages: string[] = []
  defaultOperators = [
    {'name': 'Egal', 'symbol': '=='},
    {'name': 'Différent', 'symbol': '!='},
  ]
  uKey = helper.generateId()
  copied: any = null

  mounted(): void {
    this.loadStored()
    this.check()
  }

  loadStored() {
    const stored = localStorage.getItem('conditionGroups')
    if (stored) {
      this.$store.state.copiedConditionGroups = JSON.parse(stored)
    } else {
      this.$store.state.copiedConditionGroups = null
    }
  }

  copyGroups() {
    this.$store.state.copiedConditionGroups = JSON.parse(JSON.stringify(this.line.conditionGroups))
    localStorage.setItem('conditionGroups', JSON.stringify(this.$store.state.copiedConditionGroups))
  }

  updateCopy() {
    this.$store.state.copiedConditionGroups = JSON.parse(JSON.stringify(this.line.conditionGroups))
  }

  clearPaste() {
    this.$store.state.copiedConditionGroups = null
    localStorage.removeItem('conditionGroups')
  }

  updateLineNumber() {
    this.$emit('updateLineNumber')
  }

  pasteGroups() {

    this.$store.state.copiedConditionGroups.forEach((g: EngineConditionGroup) => {
      g.uuid = helper.generateId()
      g.conditions.forEach((c: EngineCondition) => {
        c.uuid = helper.generateId()
      })
      const group = new EngineConditionGroup(g)
      this.line.conditionGroups.push(group)
    })
    this.$store.state.copiedConditionGroups = null
    localStorage.removeItem('conditionGroups')
    this.check()
  }

  importProperty(condition: EngineCondition, evt: any) {
    this.setProperty(condition, evt.type, evt.prop, evt.source)
  }

  setPropertyFromResult(condition: EngineCondition, result: any) {
    condition.first = new EngineArgument(result)
    condition.operator = null
    condition.second = null
    this.check()
    this.$forceUpdate()
  }

  async setProperty(condition: EngineCondition, itemType: ItemType, prop: ItemProperty, source: string) {

    const arg = {
      id: prop.id,
      uuid: prop.uuid,
      itemType: {
        id: itemType.id,
        uuid: itemType.uuid,
        code: itemType.code,
        // entity: itemType.entity,
        className: itemType.className,
        name: itemType.name
      },
      link: prop.link,
      type: prop.type,
      choices: prop.choices,
      code: prop.code,
      name: prop.name,
      value: prop.value,
      alias: null,
      symbol: null as any
    }
    console.log(arg)
    condition.first = new EngineArgument(arg)
    condition.operator = null
    condition.second = null

    if (arg.type.code === 'PACK') {
      if (arg.link === 'category') {
        await this.loadPackCategories(condition)
      } else {
        await this.loadPacks(condition)
      }
    }
    if (arg.type.code === 'PACK_CATEGORY') {
      await this.loadPackCategories(condition)
    }

    if (this.$refs.dropdown) {
      (this.$refs.dropdown as any).hide(true)
    }
    this.check()
    this.$forceUpdate()
  }

  async loadPacks(condition: EngineCondition) {

    let res: any = null
    if (this.$store.state.engineBranch && this.$store.state.engineInsurer) {
      const pop = new Popup('', 'Chargement des packs ' + this.$store.state.engineInsurer.label + ' ' + this.$store.state.engineBranch.label + ' en cours');
      res = await api.get(api.core, 'pack/list/' + this.$store.state.engineBranch.slug + '/' + this.$store.state.engineInsurer.slug)
      pop.hide()
    } else if (this.$store.state.engineBranch) {
      const pop = new Popup('', 'Chargement des packs ' + this.$store.state.engineBranch.label + ' en cours');
      res = await api.get(api.core, 'pack/list/' + this.$store.state.engineBranch.slug)
      pop.hide()
    } else {
      const pop = new Popup('', 'Chargement des packs en cours');
      res = await api.get(api.core, 'pack/list')
      pop.hide()
    }
    if (res && res.data) {
      condition.first.choices = []
      res.data.packs.forEach((p: Pack) => {
        const occ = {label: p.label, code: p.code, id: p.id};
        condition.first.choices.push(occ);
      })
    }
  }

  async loadPackCategories(condition: EngineCondition) {
    let res: any = null
    if (this.$store.state.engineBranch) {
      const pop = new Popup('', 'Chargement des categories de packs ' + this.$store.state.engineBranch.label + ' en cours');
      res = await api.get(api.core, 'pack/category/list/' + this.$store.state.engineBranch.slug)
      pop.hide()
      if (res && res.data) {
        condition.first.choices = []
        console.log(res.data.packCategories)
        res.data.packCategories.forEach((p: PackCategory) => {
          const occ = {label: p.label, code: p.slug, id: p.id};
          condition.first.choices.push(occ);
        })
      }
    } else {
      const pop = new Popup('', 'Chargement des la branche en cours reessayer dans quelques instants');
    }

  }

  isConditionNumeric(condition: EngineCondition): boolean {
    if (condition.first) {
      if (condition.first.type && condition.first.type.format === 'NUMBER') {
        return true
      }
    }
    return false
  }

  isConditionChoices(condition: EngineCondition): boolean {
    const codes = ['RADIO', 'PACK', 'PACK_CATEGORY'];
    if (condition.first) {
      if (condition.first.type && codes.includes(condition.first.type.code)) {
        return true
      }
    }
    return false
  }

  isConditionBoolean(condition: EngineCondition): boolean {
    if (condition.first) {
      if (condition.first.type && condition.first.type.code === 'BOOLEAN') {
        return true
      }
    }
    return false
  }

  setOperator(condition: EngineCondition, operator: any) {
    condition.operator = operator
    this.check()
    this.$forceUpdate()
  }

  setSecond(condition: EngineCondition, operande: any) {
    // console.log('set second')
    condition.second = JSON.parse(JSON.stringify(operande));
    this.check()
    this.$forceUpdate()
  }

  createArgument(data: any, source: string) {
    const arg = {
      source: source,
      type: null as any,
      choices: [] as any,
      name: null as any,
      value: null as any,
      alias: null as any,
      symbol: null as any
    };
    if (data instanceof ItemProperty) {
      arg.type = data.type
      arg.name = data.name
      arg.value = data.value
      arg.choices = data.choices
    }

    // const arg = {
    //     id: id,
    //     uuid: id,
    //     itemType: null,
    //     type: type,
    //     choices: [],
    //     code: method.code,
    //     name: method.label,
    //     value: method.id,
    //     alias: method.label,
    //     symbol: null as any
    // }
    return arg
  }

  getLineByUuid(uuid: string) {
    const line: any = this.$store.state.engineMethod.getLineByGid(uuid)
    return line
  }


  getAllowedOperators(condition: EngineCondition) {
    return this.isConditionNumeric(condition) ? this.numericOperators : this.defaultOperators
  }

  addCondition(group: EngineConditionGroup) {
    const condition = new EngineCondition()
    group.conditions.push(condition)
  }

  addGroup() {
    const group = new EngineConditionGroup()
    this.line.conditionGroups.push(group)
  }

  removeGroup(group: EngineConditionGroup) {
    const index = this.line.conditionGroups.findIndex((c: EngineConditionGroup) => {
      return c === group
    })
    if (index !== -1) {
      this.line.conditionGroups.splice(index, 1)
    }
  }

  removeCondition(condition: EngineCondition, group: EngineConditionGroup) {
    const index = group.conditions.findIndex((c: EngineCondition) => {
      return c === condition
    })
    if (index !== -1) {
      group.conditions.splice(index, 1)
    }
  }

  addLine(payload: any) {
    // console.log('add line received in condition line')
    this.$emit('addSubLine', payload)
  }

  addDefaultSubLine() {
    this.$emit('addSubLine', {type: 'default', parent: this.line})
  }


  addTextSubLine() {
    this.$emit('addSubLine', {type: 'text', parent: this.line})
  }

  addConditionSubLine() {
    // console.log('add condition subline')
    this.$emit('addSubLine', {type: 'condition', parent: this.line})
  }

  addForeachSubLine() {
    this.$emit('addSubLine', {type: 'foreach', parent: this.line})
  }

  addCallableSubLine() {
    this.$emit('addSubLine', {type: 'callable', parent: this.line})
  }

  // addLineBefore() {
  //     this.$emit('addLineBefore', {type: 'default', before: this.line})
  // }

  addSubLine(payload: any) {
    if (!payload.parent) {
      payload.parent = this.line
    }
    this.$emit('addLine', payload)
  }

  addSubLineBefore(payload: any) {
    if (!payload.parent) {
      payload.parent = this.line
    }
    this.$emit('addLineBefore', payload)
  }

  addDefaultLineBefore() {
    this.$emit('addLineBefore', {type: 'default', before: this.line})
  }

  addTextLineBefore() {
    this.$emit('addLineBefore', {type: 'text', before: this.line})
  }

  addConditionLineBefore() {
    console.log('add condition subline')
    this.$emit('addLineBefore', {type: 'condition', before: this.line})
  }

  addForeachLineBefore() {
    this.$emit('addLineBefore', {type: 'foreach', before: this.line})
  }

  addCallableLineBefore() {
    this.$emit('addLineBefore', {type: 'callable', before: this.line})
  }

  removeLine() {
    // console.log('remove line')
    this.$emit('removeLine', {line: this.line})
  }

  removeSubLine(payload: any) {
    // console.log('removing sub line')
    if (!payload.parent) {
      payload.parent = this.line
    }
    this.$emit('removeLine', payload)
  }

  @Watch('$store.state.engineMethod.requirements', {immediate: true, deep: false})
  @Watch('line.sublines', {immediate: true, deep: false})
  check() {
    this.errorMessages = []
    this.line.isValid = true
    let remap = false
    if (this.line.conditionGroups.length === 0) {
      this.errorMessages.push('Aucun groupe de condition fourni')
      this.line.isValid = false
    }
    this.line.conditionGroups.forEach((c: EngineConditionGroup, index) => {
      if (c.conditions.length === 0) {
        this.errorMessages.push('Aucune condition fournie dans le groupe ' + (index + 1))
        this.line.isValid = false
      }
      c.conditions.forEach((c: EngineCondition, cindex) => {
        if (!c.first) {
          this.errorMessages.push('Première operande manquante condition "' + (cindex + 1) + '" groupe "' + (index + 1) + '"')
        }
        if (c.first) {
          if (!this.isArgumentAllowed(c.first)) {
            this.line.isValid = false
            this.$store.state.sources.forEach((i: ItemType) => {
              if (c.first.itemType && c.first.itemType.code === i.code) {
                c.first.itemType = i
                i.properties.forEach((p: ItemProperty) => {
                  if (p.code === c.first.code || p.uuid === c.first.uuid || p.name === c.first.name) {
                    c.first = new EngineArgument(p)
                    c.first.itemType = i
                    remap = true
                  }
                })
              } else {
                // console.log(c.first)
                // console.log(c.first.name +' not '+ i.code)
              }
            })
          }

          if (!c.operator) {
            this.line.isValid = false
            this.errorMessages.push('Opérateur manquant condition "' + c.first.name + '"  groupe "' + (index + 1) + '"')
          }
          if (helper.empty(c.second)) {
            this.line.isValid = false
            this.errorMessages.push('Seconde operande manquante condition "' + c.first.name + '"  groupe "' + (index + 1) + '"')
          }
        }
      })
    })
    if (remap) {
      console.log('remap')
      this.check()
    }


    // this.line.isValid = this.errorMessages.length === 0
    this.$forceUpdate()
  }

  isArgumentAllowed(arg: EngineArgument) {
    let match = false
    // console.clear()
    // console.log('////////////////')
    // console.log('is '+arg.name+' allowed')
    if (arg.itemType) {
      match = false
      for (const i of this.$store.state.sources) {
        let res = this.itemHasArgument(i, arg)
        if (res === true) {
          match = true
          break;
        }
      }
    }
    if (!match && arg.resultOfLine) {
      const line = this.getLineByUuid(arg.resultOfLine)
      if (line.ranking < this.line.ranking) {
        match = true
      }
    }
    // console.log(match)
    // if (!match) {
    //     console.log(arg)
    // }
    arg.allowed = match
    return match
  }

  findArgument(arg: EngineArgument) {

  }

  itemHasArgument(itemType: ItemType, arg: EngineArgument) {

    // console.log('search in '+itemType.name)
    let match = false
    for (const p of itemType.properties) {
      if (p.code === arg.code && arg.itemType!.uuid === itemType!.uuid) {
        match = true
        // console.log('found')
        break;
      } else {
        // console.log(arg)
        // console.log(p.code +'!=='+arg.code + ' || '+ arg.itemType!.uuid+' !== '+itemType.uuid)
      }
      if (p.type.code === 'OBJECT') {
        const item = this.$store.state.itemTypes.find((i: ItemType) => {
          return i.uuid === p.value
        })
        if (item) {
          let res: any = this.itemHasArgument(item, arg)
          if (res === true) {
            match = true
            break;
          }
        }
      }
    }
    // console.log(match)
    return match
  }
}
</script>
<style scoped>
.if-line:hover .add-line-before {
  display: block !important;
}

.end-if:hover .add-line-before {
  display: block !important;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}</style>
