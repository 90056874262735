<template>
  <Base>
    <template v-slot:content>
      <div class="d-flex justify-content-between mb-5" v-if="rg"></div>
      <div class="row">
        <div class="col-8   ">
          <div class="d-flex justify-content-between mb-5" v-if="rg">
            <h1
              class="fw-bolder text-uppercase d-flex align-items-center justify-content-between flex-grow-1"
            >
              <div class="d-flex align-items-center">
                <router-link :to="{ name: 'cotation-list' }">
                  <i class="fad fa-arrow-left fs-3 me-5 text-dark"></i>
                </router-link>
                <div>Cotation {{ rg.survey.branch }}</div>
              </div>
            </h1>

            <div class="d-flex align-items-center">
              <div v-if="hasRole('ROLE_MANAGER')">
                <b-dropdown
                  no-caret
                  no-flip
                  right
                  tag="div"
                  toggle-class="topbar-item text-decoration-none p-0"
                  variant="link"
                >
                  <template v-slot:button-content>
                    <div
                      class="btn btn-secondary d-flex align-items-center"
                      v-if="rg.qualified === null"
                    >
                      En Attente de Qualification
                      <i class="fad fa-caret-down ms-3"></i>
                    </div>
                    <div
                      class="btn btn-primary me-5 d-flex align-items-center"
                      v-else-if="rg.qualified"
                    >
                      <i class="fad fa-thumbs-up me-3 fs-3"></i>
                      Qualifiée
                      <i class="fad fa-caret-down ms-3"></i>
                    </div>
                    <div
                      class="btn btn-danger me-5 d-flex align-items-center"
                      v-else
                    >
                      <i class="fad fa-thumbs-down me-3 fs-3"></i>
                      Disqualifiée
                      <i class="fad fa-caret-down ms-3"></i>
                    </div>
                  </template>
                  <div class="min-w-250px bg-white">
                    <b-dd-item @click="qualify(true)" class="bg-light-success">
                      <i class="fad fa-thumbs-up me-3 fs-3"></i>
                      <span class="fw-bolder fs-4"> Qualifié </span>
                    </b-dd-item>
                    <b-dd-item @click="qualify(false)" class="bg-light-danger">
                      <i class="fad fa-thumbs-down fs-3 me-3"></i>
                      <span class="fw-bolder fs-3"> Disqualifié </span>
                    </b-dd-item>
                  </div>
                </b-dropdown>
              </div>
              <div class="d-flex align-items-center">
                <div
                  class="ms-2 bg-secondary rounded w-30px h-30px d-flex align-items-center justify-content-center"
                >
                  <i class="fad fa-user fs-2"></i>
                </div>
                <a class="fw-bolder ms-2 d-flex align-items-center" href="#">
                  <user-view v-model="rg.manager" />
                  <i class="fad fa-caret-down ms-2 text-primary"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="card mb-5 mb-xl-10">
            <div class="card-body">
              <Loading v-if="isLoading"></Loading>
              <div class="d-flex flex-wrap flex-sm-nowrap mb-3" v-if="rg">
                <div class="flex-grow-1">
                  <!--begin::Title-->
                  <div
                    class="d-flex justify-content-between align-items-start flex-wrap my-2"
                  >
                    <!--begin::User-->
                    <div>
                      <div
                        class="d-flex flex-grow-1 align-items-center"
                        v-if="rg.prospect"
                      >
                        <div
                          class="w-150px h-150px rounded me-5 shadow-sm d-flex justify-content-center align-items-center"
                        >
                          <img
                            :alt="rg.prospect.identifier"
                            :src="rg.prospect.photoLink"
                            v-if="rg.prospect.photo"
                            class="rounded w-100 h-100 object-cover"
                          />
                          <i class="fad fa-user fs-5x"></i>
                        </div>
                        <div class="flex-column d-flex">
                          <!--                                        <div class="fs-2 fw-bolder text-uppercase">Prospect</div>-->
                          <div class="d-flex align-items-center">
                            <a
                              class="text-gray-800 text-hover-primary fs-2 me-1"
                              href="#"
                            >
                              {{ rg.prospect.identifier }}
                            </a>
                          </div>
                          <div class="fw-bold fs-6 mb-4 pe-2">
                            <div class="d-flex align-items-center">
                              <i class="fad fa-phone me-3"></i>
                              <div
                                class="d-flex flex-wrap align-items-center me-3"
                                v-if="!helper.empty(rg.prospect.mobile)"
                              >
                                <div
                                  class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2"
                                >
                                  {{ helper.phoneFormat(rg.prospect.mobile) }}
                                  <div
                                    class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0"
                                  >
                                    <i class="fad fa-phone"></i>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="d-flex flex-wrap align-items-center me-3"
                                v-if="!helper.empty(rg.prospect.fixe)"
                              >
                                <div
                                  class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2"
                                >
                                  {{ helper.phoneFormat(rg.prospect.fixe) }}
                                  <div
                                    class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0"
                                  >
                                    <i class="fad fa-phone"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex align-items-center my-2">
                              <i class="fad fa-handshake me-3"></i>

                              <!--
                              <b-dropdown
                                class=""
                                style=""
                                tag="div"
                                @toggle="loadPartners"
                                toggle-class="topbar-item text-decoration-none p-0"
                                variant="link"
                              >
                                <template v-slot:button-content>
                                  <span>
                                    {{
                                      rg.partner
                                        ? rg.partner
                                        : "Aucun Partenaire"
                                    }}
                                  </span>
                                </template>
                                <b-dropdown-text v-if="loadingPartner">
                                  <Loading />
                                </b-dropdown-text>
                                <template v-else>
                                  <b-dd-item
                                    :key="'p_' + partner.uuid"
                                    v-for="partner in partners"
                                    @click="setPartner(partner)"
                                  >
                                    {{ partner.name }} - {{ partner.code }}
                                  </b-dd-item>
                                </template>
                              
                              </b-dropdown>

                            -->

                              <!--                                <b-dd-item @click="addDefaultLineBefore">Ajouter un Calcul</b-dd-item>-->
                                <!--                                <b-dd-item @click="addConditionLineBefore">Ajouter une condition</b-dd-item>-->
                                <!--                                <b-dd-item @click="addCallableLineBefore">Ajouter une fonction</b-dd-item>-->
                                <!--                                <b-dd-item @click="addForeachLineBefore">Ajouter une iteration</b-dd-item>-->



                              <div class="d-flex">
              <b-dropdown class="flex-grow-1 w-100" no-caret no-flip right tag="div"
                          toggle-class="w-100 p-0 flex-grow-1" variant="link"
                          >
                <template v-slot:button-content>
                  <div
                      class="form-control flex-grow-1 text-start justify-content-between d-flex align-items-center w-100">
                    <template v-if="empty(rg.partner)">
                      Selectionnez des agents
                    </template>
                    <template v-else>
                      <div class="w-100 overflow-auto d-flex">
                        <div 
                             class="">
                          {{ getPartnerUserNameByCode(rg.partner) }}
                        
                        </div>
                      </div>
                    </template>
                    <i class="fad fa-angle-down ms-5 fs-2"></i>
                  </div>
                </template>
                <b-dropdown-text class="min-w-md-350px p-0" tag="div">
                  <template>
                    <div class="overflow-auto border-rounded" style="max-height:250px">
                      <div class="p-1">
                        <input type="text" placeholder="Rechercher" v-model="partnerSearched" class="form-control">
                      </div>
                     
                      <template v-for="partner of partnerUsers">
                        <template
                            v-if="empty(partnerSearched) || (!empty(partnerSearched) && (!empty(partner.name) && partner.name.trim().toLowerCase().includes(partnerSearched.toLowerCase())) || (partner.code.trim().toLowerCase().includes(partnerSearched.toLowerCase())))">
                          <div class="bg-hover-light-primary px-3 py-2 border-top"
                               @click="setPartner(partner)"
                               :class="{ 'bg-light-primary' : rg.partner == partner.code }">
                            <div class="fw-bolder">
                              {{ partner.name }}
                            </div>
                            <div class="fw-bolder text-muted fs-8">
                              {{ partner.code }}
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </b-dropdown-text>
              </b-dropdown>
            </div>      



                           
                            </div>

                            <div class="d-flex align-items-center">
                              <i class="fad fa-at me-3"></i>
                              <a
                                class="d-flex align-items-center text-primary my-2"
                                href="#"
                                v-if="!helper.empty(rg.prospect.email)"
                              >
                                {{ rg.prospect.email }}
                              </a>
                              <span
                                class="d-flex align-items-center text-primary"
                                v-else
                              >
                                -
                              </span>
                            </div>
                            <div class="fw-bolder fs-7">
                              <span
                                :title="readableDate(rg.createdAt)"
                                class="d-flex align-items-center cursor-pointer"
                                v-b-tooltip.hover
                              >
                                Créé {{ timelapse(rg.createdAt) }}
                              </span>
                              <span
                                :title="readableDate(rg.lastVisit)"
                                class="d-flex align-items-center cursor-pointer"
                                v-b-tooltip.hover
                              >
                                Consulté {{ timelapse(rg.lastVisit) }}
                              </span>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center flex-column mt-5">
                        <div
                          class="d-flex justify-content-between w-100 mt-auto mb-2"
                        >
                          <span
                            :class="{
                              'badge-secondary': rg.status === 'INCOMPLETE',
                              'badge-warning': rg.status.match(/REACHABLE/),
                              'badge-danger': rg.status.match(
                                /ABORTED|EXPIRED|IDENTIFIED|DISQUALIFIED/
                              ),
                              'badge-primary':
                                rg.status.match(/QUALIFIED|COMPARED/),
                              'badge-success': rg.status === 'VALIDATED',
                              'badge-info': rg.status === 'OFFER_SELECTED',
                            }"
                            class="badge fw-bolder fs-6"
                          >
                            {{ rg.status }}
                          </span>
                          <span class="fw-bolder fs-6 ms-20">
                            {{ rg.state }}
                            %
                            <i
                              @click="updateState"
                              class="fad fa-sync cursor-pointer"
                            ></i>
                          </span>
                        </div>
                        <div class="h-5px mx-3 w-100 bg-light mb-3">
                          <div
                            :style="{ width: rg.state + '%' }"
                            aria-valuemax="100"
                            aria-valuemin="0"
                            aria-valuenow="50"
                            class="bg-success rounded h-5px"
                            role="progressbar"
                          ></div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div>
                          <i class="fad fa-map-marker-alt"></i>
                          <a href="#" v-if="rg.location">
                            {{ rg.location.city }}, {{ rg.location.country }}
                          </a>
                        </div>
                        <div class="ms-3">
                          <i class="fad fa-mobile-alt"></i>
                          <a
                            :title="rg.device.os + ' ' + rg.device.browser"
                            href="#"
                            v-if="rg.device"
                          >
                            <template
                              v-if="
                                !empty(rg.device.brand) ||
                                !empty(rg.device.model)
                              "
                            >
                              {{ rg.device.brand }}, {{ rg.device.model }}
                            </template>
                            <template v-else>
                              {{ rg.device.os }}, {{ rg.device.browser }}
                            </template>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex flex-column flex-grow-1 ms-10">
                      <!--                                            <div class="fs-2 fw-bolder mb-2">{{trans('Tâches')}}</div>-->
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-1 rounded overflow-hidden" v-if="rg">
                <div
                  class="status-arrow"
                  :class="{ 'bg-primary': getStatusPound(rg.status) >= 0 }"
                >
                  Nouvelle Cotation
                </div>
                <div
                  class="status-arrow"
                  :class="{ 'bg-primary': getStatusPound(rg.status) > 0 }"
                >
                  Ouverture de lead
                </div>
                <!--                <div class="status-arrow" v-b-modal="'qualification'" v-if="getStatusPound(rg.status) < 2">-->
                <!--                  Qualification-->
                <!--                </div>-->
                <!--                <template v-else>-->
                <!--                  <div class="status-arrow bg-danger" v-if="rg.status == 'DISQUALIFIED'" v-b-modal="'qualification'">-->
                <!--                    Disqualifiée-->
                <!--                  </div>-->
                <!--                  <div class="status-arrow bg-success" v-else="getStatusPound(rg.status) > 2">-->
                <!--                    Qualifiée-->
                <!--                  </div>-->
                <!--                </template>-->
                <!--                <template v-if="getStatusPound(rg.status) > 2">-->
                <div
                  class="status-arrow"
                  :class="{ 'bg-primary': getStatusPound(rg.status) >= 2 }"
                >
                  Comparer
                </div>
                <div
                  class="status-arrow"
                  :class="{ 'bg-primary': getStatusPound(rg.status) >= 3 }"
                >
                  Offre Selectionnée
                </div>
                <!--                  <template v-if="getStatusPound(rg.status)>=4">-->
                <div
                  class="status-arrow"
                  :class="{ 'bg-success': rg.status === 'VALIDATED' }"
                  v-if="rg.status === 'VALIDATED'"
                >
                  Gagnée
                </div>
                <div
                  class="status-arrow"
                  v-if="rg.status === 'ABORTED'"
                  :class="{
                    'bg-danger':
                      rg.status === CONSTANTS.responseGroupStatus.ABORTED,
                  }"
                >
                  Perdue
                </div>
                <div
                  class="status-arrow"
                  v-if="rg.status === 'EXPIRED'"
                  :class="{
                    'bg-danger':
                      rg.status === CONSTANTS.responseGroupStatus.EXPIRED,
                  }"
                >
                  Expirée
                </div>
                <!--                  </template>-->
                <!--                </template>-->
              </div>
            </div>
          </div>

          <b-modal
            :title="trans('Qualification')"
            centered
            id="qualification"
            ref="qualification"
          >
            <template #default>
              <div class="text-center">
                <div>
                  <i class="fad fa-question-circle fs-5x"></i>
                </div>
                <div class="mt-5 fw-bolder fs-2">
                  Voulez vous qualifier cette cotation ?
                </div>
                <div class="mt-5">
                  Assurez-vous d'avoir vérifié les informations nécessaires à
                  une cotation
                </div>
              </div>
            </template>

            <template #modal-footer="{ ok, cancel, hide }">
              <b-button @click="cancel()" size="sm" variant="secondary">
                <i class="fad fa-times"></i>
                {{ trans("Annuler") }}
              </b-button>
              <b-button @click="qualify(false)" size="sm" variant="danger">
                <i class="fad fa-thumbs-down"></i>
                {{ trans("Disqualifier") }}
              </b-button>
              <b-button @click="qualify(true)" size="sm" variant="primary">
                <i class="fad fa-thumbs-up"></i>
                {{ trans("Qualifier") }}
              </b-button>
            </template>
          </b-modal>

          <template v-if="rg">
            <div class="card">
              <div class="card-body">
                <div
                  class="d-flex align-items-center justify-content-between mb-5"
                >
                  <h2 class="">Doublons</h2>
                </div>
                <div>
                  <div class="fs-2 fw-bolder my-5">
                    {{ duplicatedAssets.length }} cotation(s) existante(s)
                    contenant un des actifs
                  </div>
                  <table class="table table-striped g-3">
                    <tbody>
                      <tr v-for="asset of duplicatedAssets" class="">
                        <td>
                          <div>
                            {{ readable(asset.createdAt) }}
                          </div>
                        </td>
                        <td>
                          <div class="mx-5">
                            <div class="fw-bolder">
                              <template v-if="asset.rgAlias">
                                {{ asset.rgAlias }}
                              </template>
                              <template v-else>
                                {{ asset.rgUuid }}
                              </template>
                            </div>
                            <div>
                              {{ asset.surveyLabel }}
                            </div>
                          </div>
                          <!--                        {{ asset.branch }} - {{ asset.alias }} => {{ asset.label }} = {{ asset.value }}-->
                        </td>
                        <td>
                          <div>
                            <div>
                              {{ asset.questionLabel }}
                            </div>
                            <div class="text-uppercase fw-bolder">
                              {{ asset.value }}
                            </div>
                          </div>
                        </td>
                        <td>
                          {{ asset.partner }}
                        </td>
                        <td>
                          <div
                            class="d-flex align-items-center flex-column mt-5"
                          >
                            <div
                              class="d-flex justify-content-between w-100 mt-auto mb-2"
                            >
                              <span
                                :class="{
                                  'badge-secondary':
                                    asset.status === 'INCOMPLETE',
                                  'badge-warning': asset.status.match(
                                    /IDENTIFIED|DISQUALIFIED/
                                  ),
                                  'badge-danger':
                                    asset.status.match(/ABORTED|EXPIRED/),
                                  'badge-primary':
                                    asset.status.match(/QUALIFIED|COMPARED/),
                                  'badge-success': asset.status === 'VALIDATED',
                                  'badge-info':
                                    asset.status === 'OFFER_SELECTED',
                                }"
                                class="badge fw-bolder fs-6"
                              >
                                {{
                                  CONSTANTS.responseGroupStatus[asset.status]
                                }}
                              </span>
                              <span class="fw-bolder fs-6 ms-20">
                                {{ asset.state }}
                                %
                                <!--                          <i @click="updateState" class="fad fa-sync cursor-pointer"></i>-->
                              </span>
                            </div>
                            <div class="h-5px mx-3 w-100 bg-light mb-3">
                              <div
                                :style="{ width: asset.state + '%' }"
                                aria-valuemax="100"
                                aria-valuemin="0"
                                aria-valuenow="50"
                                class="bg-success rounded h-5px"
                                role="progressbar"
                              ></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <router-link
                            target="_blank"
                            :to="{
                              name: 'show-cotation',
                              params: { uuid: asset.rgUuid },
                            }"
                            class="btn btn-icon btn-primary btn-sm"
                          >
                            <i class="fad fa-external-link"></i>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="btn btn-primary mt-5" @click="searchDuplicate">
                    Rechercher
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-if="rg">
            <div class="d-flex align-items-center justify-content-between mb-5">
              <h2 class="">Détails</h2>
              <router-link
                :to="{
                  name: 'debug-cotation',
                  params: { uuid: $route.params.uuid },
                }"
                class="text-danger text-decoration-underline"
              >
                Debugger
              </router-link>
            </div>
            <div class="card mb-5 mb-xl-10">
              <div class="card-body">
                <div v-if="rg">
                  <table class="table table-striped w-100 g-2">
                    <tbody>
                      <tr
                        class="bg-hover-light-primary"
                        v-for="response in rg.responses"
                        :key="response.uuid"
                      >
                        <td>
                          {{ response.questionLabel }}
                        </td>
                        <td>
                          {{ response.value }}
                        </td>
                        <td>
                          <div v-if="hasRole('ROLE_ADMIN')"
                            class="btn btn-icon btn-danger btn-xs"
                            @click="deleteResponse(response.uuid)"
                          >
                            <i class="fad fa-trash-alt"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="d-flex justify-content-center align-items-center p-10 flex-column"
                >
                  <i class="fad fa-empty-set fs-3x"></i>
                  <p class="mt-5 fs-2">
                    <!-- Impossible de fournir une proposition en raison des réponses manquantes -->
                  </p>
                </div>
              </div>
            </div>
          </template>

          <template v-if="rg">
            <h2 class="mb-5">Documents</h2>
            <div class="card mb-5 mb-xl-10">
              <div class="card-body">
                <div
                  class="d-flex justify-content-center align-items-center p-10 flex-column"
                >
                  <i class="fad fa-empty-set fs-3x"></i>
                  <p class="mt-5 fs-2">
                    <!-- Impossible de fournir une proposition en raison des réponses manquantes -->
                  </p>
                </div>
              </div>
            </div>
          </template>

          <template
            v-if="rg && rg.status !== 'VALIDATED' && rg.status !== 'ABORTED'"
          >
            <h2 class="mb-5">Produits compatible</h2>
            <div class="card mb-5 mb-xl-10">
              <div class="card-body">
                <div
                  class="d-flex justify-content-center align-items-center p-10 flex-column"
                >
                  <i class="fad fa-empty-set fs-3x"></i>
                  <p class="mt-5 fs-2">
                    Impossible de fournir une proposition en raison des réponses
                    manquantes
                  </p>
                </div>
              </div>
            </div>
          </template>
          <h2 class="mb-5">Activité</h2>

          <div class="card mb-5 mb-xl-10">
            <div class="card-body">
              <div class="table-responsive">
                <!--begin::Table-->
                <table
                  class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                >
                  <!--begin::Table head-->
                  <thead>
                    <tr class="fw-bolder text-muted">
                      <!--                                <th class="w-25px">-->
                      <!--                                    <div class="form-check form-check-sm form-check-custom form-check-solid">-->
                      <!--                                        <input class="form-check-input" data-kt-check="true" data-kt-check-target=".widget-9-check"-->
                      <!--                                               type="checkbox"-->
                      <!--                                               value="1">-->
                      <!--                                    </div>-->
                      <!--                                </th>-->
                      <th>{{ trans("Action") }}</th>
                      <th>{{ trans("Acteur") }}</th>
                      <!--                                <th>{{trans('Status')}}</th>-->
                      <!--                                <th class="min-w-100px text-end">Actions</th>-->
                    </tr>
                  </thead>
                  <tbody v-if="rg">
                    <tr v-if="rg.logs.length === 0">
                      <td colspan="3">
                        <div
                          class="d-flex justify-content-center align-items-center p-10"
                        >
                          <div @click="loadLogs" class="btn btn-primary">
                            <i class="fad fa-cogs"></i>
                            Charger les logs
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-else v-for="log in rg.logs">
                      <td>
                        <b>{{ log.label }}</b>
                        <br />
                        {{ log.date }}
                      </td>
                      <td>
                        <b>{{ log.prospect }}</b>
                        <br />
                        {{ log.device }}
                        <template v-if="log.os">
                          {{ log.os }}
                        </template>
                        <!--                                            <br>-->
                        <!--                                            {{log.os}}-->
                      </td>
                    </tr>

                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4  ">
          <div class="d-flex justify-content-center mb-5">
            <router-link
              v-if="rg"
              :to="{
                name: 'response-group-manager',
                params: { surveySlug: rg.survey.branch, rgUuid: rg.uuid },
              }"
              class="btn btn-primary"
              target="_blank "
            >
              <i class="fad fa-ballot-check fs-2"></i>
              Formulaire
            </router-link>
          </div>
          <div class="card flex-1">
            <div class="card-body">
              <div>
                <div class="fs-2 fw-bolder my-2">{{ trans("Tâches") }}</div>
                <template v-if="rg">
                  <div
                    class="d-flex align-items-center mb-8"
                    v-for="todo in todos"
                  >
                    <span
                      :class="{
                        'bg-success': todo.status.match(/DONE/),
                        'bg-secondary': todo.status.match(/CANCELLED/),
                        'bg-warning': todo.status.match(/EXPIRED/),
                        'bg-danger': todo.status.match(/WAITING/),
                      }"
                      class="bullet bullet-vertical h-40px"
                    ></span>
                    <!--                        <div class="form-check form-check-custom form-check-solid mx-5">-->
                    <!--                            <input class="form-check-input" type="checkbox" value="">-->
                    <!--                        </div>-->
                    <div class="flex-grow-1 mx-5">
                      <a
                        class="text-gray-800 text-hover-primary fw-bolder fs-6"
                        href="#"
                      >
                        <template v-if="empty(todo.label)">
                          {{ trans(todo.type) }}
                        </template>
                        <template v-else>
                          {{ todo.label }}
                        </template>
                      </a>

                      <div>
                        <span class="fs-7x fw-bolder">
                          {{ trans(todo.type) }}
                        </span>
                        <span
                          :title="readableDate(todo.date)"
                          class="text-muted fw-bold d-block"
                          v-b-tooltip.hover
                        >
                          {{ trans("Expire") }} {{ timelapse(todo.date) }}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span
                          :class="{
                            'badge-light-success': todo.status.match(/DONE/),
                            'badge-secondary': todo.status.match(/CANCELLED/),
                            'badge-light-warning': todo.status.match(/EXPIRED/),
                            'badge-light-danger': todo.status.match(/WAITING/),
                          }"
                          class="badge fs-8 fw-bolder"
                          >{{ todo.status }}</span
                        >
                      </div>
                      <div
                        class="d-flex justify-content-end align-items-center mt-1"
                        v-if="
                          todo.status.match(/WAITING|EXPIRED/) && !todo.updating
                        "
                      >
                        <div
                          @click="cancelTask(todo)"
                          class="btn btn-danger btn-icon btn-xs"
                        >
                          <i class="fad fa-ban"></i>
                        </div>
                        <div
                          @click="validateTask(todo)"
                          class="btn btn-icon btn-success btn-xs ms-1"
                        >
                          <i class="fad fa-check"></i>
                        </div>
                      </div>
                      <!--                                            <template v-if="todo.status.match(/DONE/)">-->
                      <!--                                                <i class="fad fa-check-double text-success"></i>-->
                      <!--                                            </template>-->
                      <!--                                            <template v-if="todo.status.match(/CANCELLED/)">-->
                      <!--                                                <i class="fad fa-ban text-gray-400"></i>-->
                      <!--                                            </template>-->
                    </div>
                  </div>
                </template>
                <!--                                <div class="table-responsive">-->
                <!--                                    &lt;!&ndash;begin::Table&ndash;&gt;-->
                <!--                                    <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 ">-->
                <!--                                        <tbody v-if="rg">-->
                <!--                                        <template v-for="todo in rg.todos">-->
                <!--                                            <tr :class="{-->
                <!--                                            'bg-light-success text-success' : todo.status.match(/DONE/),-->
                <!--                                            'bg-secondary text-gray-400' : todo.status.match(/CANCELLED/),-->
                <!--                                            'bg-light-warning text-warning' : todo.status.match(/EXPIRED/),-->
                <!--                                            'bg-light-danger text-danger' : todo.status.match(/WAITING/)-->
                <!--                                             }"-->
                <!--                                                class="p-2"-->
                <!--                                                v-if="todo.status.match(/WAITING|EXPIRED/) || showAllTodos">-->
                <!--                                                <td class="p-2">-->
                <!--                                                    &lt;!&ndash;                                            <span class="badge badge-danger">&ndash;&gt;-->
                <!--                                                    <template v-if="empty(todo.label)">-->
                <!--                                                        {{trans(todo.type)}}-->
                <!--                                                    </template>-->
                <!--                                                    <template v-else>-->
                <!--                                                        {{todo.label}}-->
                <!--                                                    </template>-->
                <!--                                                    &lt;!&ndash;                                            </span>&ndash;&gt;-->
                <!--                                                    <br>-->
                <!--                                                    <span class="fw-bolder fs-7 mt-2" v-if="todo.status.match(/WAITING|EXPIRED/)">-->

                <!--                                            {{trans('Avant')}} ◾ {{readableDate(todo.date)}}-->
                <!--                                                </span>-->
                <!--                                                    <span class="fw-bolder fs-7 mt-2" v-if="todo.status.match(/DONE/)">-->
                <!--                                                        {{trans('Effectué par')}} {{todo.updateBy}}-->
                <!--                                                    </span>-->
                <!--                                                    <span class="fw-bolder fs-7 mt-2" v-if="todo.status.match(/CANCELLED/)">-->
                <!--                                                        {{trans('Annulé par')}} {{todo.updateBy}}-->
                <!--                                                    </span>-->
                <!--                                                </td>-->
                <!--                                                <td class="p-2" style="text-align: right">-->
                <!--                                                    <template v-if="todo.status.match(/WAITING|EXPIRED/) && !todo.updating">-->
                <!--                                                        <div @click="cancelTask(todo)" class="btn btn-danger btn-icon btn-xs me-1">-->
                <!--                                                            <i class="fad fa-ban"></i>-->
                <!--                                                        </div>-->
                <!--                                                        <div @click="validateTask(todo)" class="btn btn-icon btn-success btn-xs">-->
                <!--                                                            <i class="fad fa-check"></i>-->
                <!--                                                        </div>-->
                <!--                                                    </template>-->
                <!--                                                    <template v-if="todo.status.match(/DONE/)">-->
                <!--                                                        <i class="fad fa-check-double text-success"></i>-->
                <!--                                                    </template>-->
                <!--                                                    <template v-if="todo.status.match(/CANCELLED/)">-->
                <!--                                                        <i class="fad fa-ban text-gray-400"></i>-->
                <!--                                                    </template>-->
                <!--                                                </td>-->
                <!--                                            </tr>-->
                <!--                                        </template>-->
                <!--                                        </tbody>-->
                <!--                                    </table>-->
                <!--                                </div>-->
                <div class="d-flex justify-content-end">
                  <div class="d-flex align-items-center me-3">
                    <label
                      :class="[showAllTodos ? 'btn-primary' : 'btn-secondary']"
                      class="btn btn-icon btn-sm"
                      for="showAllTodos"
                    >
                      <i class="fad fa-eye"></i>
                      <input
                        class="hidden"
                        id="showAllTodos"
                        type="checkbox"
                        v-model="showAllTodos"
                        value=""
                      />
                    </label>
                  </div>
                  <div class="btn btn-primary btn-sm" v-b-modal="'createTask'">
                    <i class="fad fa-plus"></i>
                    {{ trans("Créer une tâche") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-modal
            :title="trans('Créer une tâche')"
            centered
            id="createTask"
            ref="createTask"
          >
            <form>
              <div class="row" v-if="todo">
                <div class="col-auto m-2 flex-grow-1">
                  <label :for="'label'" class="required form-label">{{
                    trans("Label")
                  }}</label>
                  <b-form-input
                    :id="'label'"
                    :state="!helper.empty(todo.label)"
                    v-model="todo.label"
                  >
                  </b-form-input>
                </div>

                <div class="col-auto m-2 flex-grow-1">
                  <label :for="'code'" class="required form-label">{{
                    trans("Type")
                  }}</label>
                  <b-form-select
                    :state="!helper.empty(todo.type)"
                    class="form-control form-select"
                    v-model="todo.type"
                  >
                    <b-form-select-option :value="null"
                      >{{ trans("Selectionnez un type") }}
                    </b-form-select-option>
                    <b-form-select-option
                      :key="type"
                      :value="type"
                      v-for="(type, key) in CONSTANTS.todoTypes"
                    >
                      {{ trans(type) }}
                    </b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <div class="row">
                <div class="col-auto m-2 flex-grow-1">
                  <label :for="'code'" class="required form-label">{{
                    trans("Attribué à")
                  }}</label>
                  <VueMultiselect
                    :options="managers"
                    :show-labels="false"
                    label="title"
                    placeholder="Gestionnaire"
                    track-by="email"
                    v-model="todo.user"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <div class="d-flex align-items-center">
                        <div
                          class="w-40px h-40px me-5"
                          v-if="props.option.photo"
                        >
                          <img
                            :src="props.option.photoLink"
                            class="option__image w-100 h-100 object-cover"
                          />
                        </div>
                        <div class="option__desc">
                          <span class="option__title">
                            {{ props.option.email }}
                          </span>
                        </div>
                      </div>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="d-flex align-items-center">
                        <div
                          class="w-40px h-40px me-5"
                          v-if="props.option.photo"
                        >
                          <img
                            :src="props.option.photoLink"
                            class="option__image w-100 h-100 object-cover"
                          />
                        </div>
                        <div class="option__desc d-flex flex-column">
                          <span class="option__title">
                            {{ props.option.name }} {{ props.option.prename }}
                          </span>
                          <span class="option__small fs-7">
                            {{ props.option.email }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </VueMultiselect>
                </div>
              </div>

              <div class="row">
                <div class="col-auto m-2 flex-grow-1">
                  <label :for="'class'" class="required form-label">{{
                    trans("Date butoire")
                  }}</label>
                  <b-form-input
                    :id="'class'"
                    :state="!helper.empty(todo.date)"
                    type="datetime-local"
                    v-model="todo.date"
                  ></b-form-input>
                </div>
                <div class="col-auto m-2 flex-grow-1">
                  <label :for="'code'" class="form-label">{{
                    trans("Note")
                  }}</label>
                  <b-form-textarea
                    :id="'code'"
                    :state="!helper.empty(todo.comment)"
                    v-model="todo.comment"
                  >
                  </b-form-textarea>
                </div>
              </div>
            </form>
            <template #modal-footer="{ ok, cancel, hide }">
              <b-button @click="cancel()" size="sm" variant="secondary">
                <i class="fad fa-times"></i>
                {{ trans("Cancel") }}
              </b-button>
              <b-button @click="createTodo" size="sm" variant="primary">
                <i class="fad fa-save"></i>
                {{ trans("Save") }}
              </b-button>
            </template>
          </b-modal>
          <div class="card flex-1 my-5">
            <div class="card-body">
              <div class="fs-2 fw-bolder mb-2">{{ trans("Notes") }}</div>

              <div
                v-if="rg?.notes.length == 0"
                class="d-flex justify-content-center align-items-center my-10 flex-column"
              >
                <i class="fad fa-empty-set fs-3x"></i>
                <div class="fw-bolder text-gray-400 mt-5">
                  Aucune note pour l'instant
                </div>
              </div>

              <div v-for="(item, index) in rg?.notes" :key="index" class="border-bottom border-2 pt-3 d-flex justify-content-between">
                <div>
                  <p class="fs-8" style="font-size: 1rem; text-align: right;">{{ item.createBy }} le {{ readableDate(item.createdAt , 'dmY H:i ') }}</p>
             
                  <div class="pb-3">
                    <p class="fs-6">
                    {{ item.comment }} 
                  </p>
                  </div>
                 

                   </div>

                   <div v-if="hasRole('ROLE_ADMIN')"
                      class="btn btn-icon btn-danger btn-xs"
                      @click="deleteNote(item.id)"
                    >
                      <i class="fad fa-trash"></i>
                    </div>
              </div>

              <div class="mt-3">
                <textarea
                  v-model="note.comment"
                  class="form-control"
                ></textarea>
              </div>
              <div class="text-center">
                <button @click="addNotes"  :disabled="note.comment==''" class="btn btn-primary btn-sm mt-5">
                  {{ trans("Ajouter une note") }}
                </button>
              </div>
            </div>
          </div>

          <div class="card flex-1 my-5">
            <div class="card-body">
              <div class="fs-2 fw-bolder mb-2">{{ trans("Pertinence") }}</div>
              <div class="d-flex justify-content-center p-5">
                <div
                  class="d-flex justify-content-center align-items-center fs-2 fw-bolder"
                  style="
                    border: 10px gainsboro solid;
                    width: 100px;
                    height: 100px;
                    border-radius: 100%;
                  "
                >
                  0%
                </div>
              </div>
              <h5 class="text-center">
                {{ trans("Ajouter une appréciation") }}
              </h5>
              <div class="d-flex">
                <div
                  class="flex-grow-1 d-flex justify-content-center cursor-pointer bg-hover-light-primary"
                >
                  <img alt="" class="w-50px" src="/images/is.gif" />
                </div>
                <div
                  class="flex-grow-1 d-flex justify-content-center cursor-pointer bg-hover-light-primary"
                >
                  <img alt="" class="w-50px" src="/images/ps.gif" />
                </div>
                <div
                  class="flex-grow-1 d-flex justify-content-center cursor-pointer bg-hover-light-primary"
                >
                  <img alt="" class="w-50px" src="/images/s.gif" />
                </div>
                <div
                  class="flex-grow-1 d-flex justify-content-center cursor-pointer bg-hover-light-primary"
                >
                  <img alt="" class="w-50px" src="/images/ts.gif" />
                </div>
              </div>
            </div>
          </div>

          <div class="card flex-1 my-5">
            <div class="card-body">
              <div class="fs-2 fw-bolder mb-2">{{ trans("Devis") }}</div>
              <template v-if="rg">
                <div
                  class="d-flex my-2 align-items-center whitespace-no-wrap justify-content-between"
                  v-for="order in rg.orders"
                >
                  <router-link
                    :to="{ name: 'show-order', params: { uuid: order.uuid } }"
                    class="btn btn-icon btn-primary me-3"
                  >
                    <i class="fad fa-external-link"></i>
                  </router-link>
                  <div>
                    <img
                      :src="uploadRoot + getInsurerPhotoByCode(order.insurer)"
                      :alt="getInsurerLabelByCode(order.insurer)"
                      class="mw-70px mh-70px me-2 object-contain"
                    />
                    <router-link
                      target="_blank"
                      :to="{ name: 'order-view', params: { uuid: order.uuid } }"
                    >
                      N° {{ order.number }}
                    </router-link>
                    <div class="text-gray-400 fs-7 fw-bolder">
                      {{ readableDate(order.createdAt, "dmY His") }}
                    </div>
                  </div>
                  <div class="ms-5 fw-bolder">
                    <router-link
                      target="_blank"
                      :to="{ name: 'order-view', params: { uuid: order.uuid } }"
                      class="fw-bolder"
                    >
                      {{ currency(order.premium) }}
                    </router-link>
                    <div v-if="order">
                      <span
                        class="badge"
                        :class="{
                          'badge-secondary':
                            getOrderStatus(order).label ===
                            CONSTANTS.orderStatus.CREATED,
                          'badge-warning':
                            getOrderStatus(order).label ===
                            CONSTANTS.orderStatus.WAITING,
                          'badge-danger':
                            getOrderStatus(order).label ===
                            CONSTANTS.orderStatus.EXPIRED,
                          'badge-success':
                            getOrderStatus(order).label ===
                            CONSTANTS.orderStatus.PAYED,
                        }"
                        >{{ getOrderStatus(order).label }}</span
                      >
                    </div>
                  </div>
                  <div class="d-flex justify-content-around ms-5">
                    <div
                      class="btn btn-icon btn-danger btn-xs"
                      @click="deleteOrder(order)"
                    >
                      <i class="fad fa-trash"></i>
                    </div>
                  </div>
                </div>
                <div v-if="rg.orders.length === 0" class="text-center fs-2">
                  <div>
                    <i class="fad fa-empty-set fs-2"></i>
                  </div>
                  <div>Aucun Devis</div>
                </div>
              </template>
            </div>
          </div>

          <div class="card flex-1 my-5">
            <div class="card-body">
              <div class="fs-2 fw-bolder mb-2">
                {{ trans("Prospects potentiels") }}
              </div>
              <template v-if="rg">
                <div class="d-flex" v-for="owner in rg.potentialOwners">
                  {{ owner.email }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import ResponseGroup from "@/entity/ResponseGroup";
import Loading from "@/components/Loading.vue";
import UserView from "@/components/UserView.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import User from "@/entity/User";
import Popup from "@/entity/Popup";
import Todo from "@/entity/Todo";
import CONSTANTS from "@/Utils/constant";
import Order from "@/entity/Order";
import Insurer from "@/entity/Insurer";
import { auth } from "@/services/Auth";
import Partner from "@/entity/Partner";
import PaymentMethod from "@/entity/PaymentMethod";
import Note from "@/entity/Note";
import { helper } from "@/services/Helper";

@Component({
  components: { VueMultiselect, Loading, Base, UserView },
})
export default class ShowCotation extends Vue {
  rg: ResponseGroup | null = null;
  isLoading = false;
  managers: User[] = [];
  manager: any = null;
  showAllTodos = true;
  todo: Todo = new Todo();
  todos: Todo[] = [];
  insurers: Insurer[] = [];
  uploadRoot = api.uploadRoot;
  duplicatedAssets: any[] = [];
  partners: Partner[] = [];
  products = {};
  note: Note = new Note();
  loadingPartner = false;

  partnerSearched = ''


  getStatusPound(status: string) {
    const list = CONSTANTS.responseGroupStatus;
    let i = 0;
    for (let state in list) {
      if (state === status) {
        return i;
      }
      i++;
    }
  }



  partnerUsers: any[] = []
  
  togglePartnerUsers(partner: Partner) {

    if (this.rg) {
      // this.rg.partner = code

    this.setPartner( partner)

    }
   


this.$forceUpdate()
}


getPartnerUserNameByCode(code: string) {
  
    const partner = this.partnerUsers.find((partner: Partner) => {
      return partner.code === code
    })
    if (partner) {  
      if (!helper.empty(partner.name)) {
        return partner.name
      }
      return partner.code
    }
    return code;
  }





  async deleteNote(id:number){
    {
    const pop = new Popup(
      "",
      "Suppression d'une note",
      "danger",
      "fad fa-trash",
      true
    );
    const res = await api.delete(api.form, "note/delete/" + id);
    if (res && res.data && res.data.status === "success") {
      
      if (this.rg) {
        this.rg.notes = this.rg?.notes.filter(item => item.id !== id)
      }
      

      

      console.log(this.rg?.notes);
     
    }
    pop.hide();
  }
  }
  async qualify(state: boolean) {
    this.$store.commit("loading");
    const res = await api.get(
      api.form,
      "response/group/qualify/" + this.rg!.uuid + "/" + state
    );
    this.$store.commit("stopLoading");
    const refs = this.$refs as any;
    if (refs.hasOwnProperty("qualification")) {
      refs.qualification.hide();
    }
    if (res && res.data && res.data.responseGroup) {
      this.rg = new ResponseGroup(res.data.responseGroup);
    }
  }

  async deleteResponse(uuid: string) {
    const res = await api.get(api.form, "response/reset/" + uuid);
    if (res && res.data) {
      const index = this.rg!.responses.findIndex((res: any) => {
        return res.uuid === uuid;
      });
      if (index !== -1) {
        this.rg!.responses.splice(index, 1);
      }
    }
  }

  async loadPartners() {
    if (this.partners.length === 0) {
      this.loadingPartner = true;
      const res = await api.get(api.core, "partner/list");
      if (res && res.data) {
        this.partners = [];
        res.data.partners.forEach((p: Partner) => {
          this.partners.push(new Partner(p));
        });
      }
      this.loadingPartner = false;
    }
  }

  async searchDuplicate() {
    this.$store.commit("loading");
    const res = await api.get(
      api.form,
      "response/group/search/duplicated/identifier/" + this.rg!.uuid
    );
    this.$store.commit("stopLoading");
    if (res && res.data && res.data.assets) {
      this.duplicatedAssets = res.data.assets;
    }
  }

  async beforeMount() {
    this.insurers = await this.$store.getters.insurers();
  }


  async addNotes(){

    const pop = new Popup('Chargement', 'Ajout d\'une note', 'primary', 'fad fa-cogs', true)
    const res = await api.post(
      api.form,
      "note/edit/" + this.rg?.uuid,
      this.note
    );
if (res) {
  this.rg?.notes.push(res.data.note)
}
    pop.hide()


  }

 async mounted() {
    this.load();

    if (auth.hasRole("ROLE_MANAGER")) {
      this.loadTodos();


      const partners = await this.$store.getters.partners()
      if (partners) {
        this.partners = []
        partners.forEach((p: Partner) => {
          if (!p.code.includes('-')) {
            this.partners.push(p)
          }
        })
      }


      this.partnerUsers = await this.$store.getters.partnerUsers()
    }




    // this.resetSearch()
   

  }

  log(e: any) {
    console.log(e);
  }

  async setPartner(partner: Partner) {

    this.$store.commit("loading");
  
    const res = await api.get(
      api.form,
      "response/group/set/partner/" + this.rg!.uuid + "/" + partner.code
    );
    if (res && res.data && res.data.status === "success") {
      this.rg!.partner = partner.code as any;
    }
    this.$store.commit("stopLoading");
  }

  getOrderStatus(order: Order) {
    if (CONSTANTS.orderStatus.hasOwnProperty(order.status)) {
      const val = {
        code: order.status,
        label: CONSTANTS.orderStatus[order.status],
      };
      return val;
    } else {
      return { code: order.status, label: order.status };
    }
  }

  async deleteOrder(order: Order) {
    const pop = new Popup(
      "",
      "Suppression en cours",
      "danger",
      "fad fa-trash",
      true
    );
    const res = await api.delete(api.form, "order/delete/" + order.uuid);
    if (res && res.data && res.data.status === "success") {
      const index = this.rg!.orders.findIndex((o: Order) => {
        return o.uuid === order.uuid;
      });
      if (index !== -1) {
        this.rg?.orders.splice(index, 1);
      }
    }
    pop.hide();
  }

  getInsurerByCode(code: string): Insurer {
    const insurer = this.insurers.find((i: Insurer) => {
      return i.slug === code;
    });
    return insurer!;
  }

  getInsurerPhotoByCode(code: string) {
    const insurer = this.getInsurerByCode(code);
    if (insurer) {
      return insurer!.photo;
    }
    return "null";
  }

  getInsurerLabelByCode(code: string) {
    const insurer = this.insurers.find((i: Insurer) => {
      return i.slug === code;
    });
    return insurer?.label;
  }

  async updateState() {
    const res = await api.get(
      api.form,
      "api/update/response/group/state/" + this.$route.params.uuid
    );
    this.rg = new ResponseGroup(res.data.responseGroup);
    // console.log(res)
  }

  async load() {
    this.isLoading = true;
    const res = await api.get(
      api.form,
      "response/group/show/" + this.$route.params.uuid
    );
    this.rg = new ResponseGroup(res.data.responseGroup);
    this.isLoading = false;
  }

  async loadTodos() {
    const res = await api.get(
      api.auth,
      "todo/list/subject/" + this.$route.params.uuid
    );
    if (res && res.data && res.data.todos) {
      this.todos = [];
      res.data.todos.forEach((d: Todo) => {
        this.todos.push(new Todo(d));
      });
    }
  }

  async createTodo() {
    if (this.rg) {
      const p = new Popup(
        "En cours",
        "Validation de la tâche",
        "success",
        "fad fa-cogs",
        true
      );
      this.todo.updating = true;
      const res = await api.post(api.form, "todo/create/" + this.rg.uuid, {
        todo: this.todo,
      });
      if (res && res.data && res.data.todo) {
        this.rg.todos.push(new Todo(res.data.todo));
      }
      p.hide();
      this.todo.updating = false;
    }
    const ref = this.$refs as any;
    if (ref.createTask) {
      ref.createTask.hide();
    }
  }

  async validateTask(todo: Todo) {
    const p = new Popup(
      "En cours",
      "Validation de la tâche",
      "success",
      "fad fa-cogs",
      true
    );
    todo.updating = true;
    const res = await api.get(api.form, "todo/validate/" + todo.uuid);
    if (res && res.data && res.data.todo) {
      todo.status = res.data.todo.status;
    }
    p.hide();
    todo.updating = false;
    // console.log(res)
  }

  async cancelTask(todo: Todo) {
    const p = new Popup(
      "En cours",
      "Annulation de la tâche",
      "danger",
      "fad fa-cogs",
      true
    );
    todo.updating = true;
    const res = await api.get(api.form, "todo/cancel/" + todo.uuid);
    if (res && res.data && res.data.todo) {
      todo.status = res.data.todo.status;
    }
    todo.updating = false;
    // console.log(res)
    p.hide();
  }

  async loadLogs() {
    const p = new Popup(
      "En cours",
      "Chargement des logs",
      "success",
      "fad fa-cogs",
      true
    );
    const res = await api.get(
      api.form,
      "response/group/get/logs/" + this.$route.params.uuid
    );
    p.hide();
    if (res && res.data && res.data.responseGroup && this.rg) {
      this.rg.logs = res.data.responseGroup.logs;
    }
  }
}
</script>
<style scoped>
.status-arrow {
  flex: 1;
  height: 25px;
  background-color: gainsboro;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 0.8em;
}

.status-arrow:hover {
  background-color: mediumturquoise;
}

.status-arrow:not(:last-child):after {
  width: 19px;
  height: 19px;
  position: absolute;
  right: -10px;
  top: 3px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  transform: scaleX(0.8) rotate(45deg);
  content: " ";
  cursor: pointer;
  z-index: 2;
  background-color: inherit;
  /* background: gray; */
}
</style>
