<template>
  <Base>
    <template #toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            Afficher tout
            <div class="form-check form-switch form-check-custom form-check-solid ms-5">
              <input type="checkbox" value="" class="form-check-input h-20px w-30px" v-model="showAll" @change="load">
            </div>
          </div>

        </div>
      </div>
    </template>
    <template #content>

      <div class="card">
        <div class="card-body p-0">
          <table class="table table-striped gy-7 gs-7">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>Partner</th>
              <th>{{ trans('Devis') }}</th>
              <th>{{ trans('Période') }}</th>
              <th>{{ trans('Branche') }}</th>
              <th>{{ trans('Prospect') }}</th>
              <th>{{ trans('Addresse') }}</th>
              <th>{{ trans('Montant') }}</th>
              <th>{{ trans('Agent') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="order in deliveries" :key="'order_'+order.uuid">
              <td v-if="hasRole('ROLE_MANAGER')">
                <PartnerView v-model="order.partner" v-if="order.partner"/>
              </td>
              <td>
                <div class="d-flex">
                  <!--                  <div>-->
                  <!--                    <i class="fad fa-truck fs-3x me-4"></i>-->
                  <!--                  </div>-->
                  <div>
                    <router-link
                        :to="{ name: 'show-cotation', params: { uuid: order.responseGroup.uuid } }"
                        class="fw-bolder">
                      {{ order.number }}
                    </router-link>
                    <div>
                      {{ datify(order.createdAt) }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  {{ datify(order.startAt, 'dMy') }}
                </div>
                <div>
                  {{ datify(order.endAt, 'dMy') }}
                </div>
              </td>
              <td>
                <div class="fw-bolder">
                  {{ order.branch }}
                </div>
                <div>
                  {{ order.duration.label }}
                </div>
              </td>

              <td>
                <div v-if="order.prospect">
                  <div class="fw-bolder">
                    {{ order.prospect.email }}
                  </div>
                  <div>
                    {{ phoneFormat(order.prospect.mobile) }}
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <div class="fw-bolder">
                    {{ order.town }},{{ order.address }}
                  </div>
                  <div>
                    {{ phoneFormat(order.contact) }}
                  </div>
                </div>
              </td>
              <td>
                <div class="fw-bolder fs-2">
                  {{ currency(order.premium) }}
                </div>
                <!--                <div class="d-flex">-->
                <!--                  <div class="text-primary text-decoration-underline mx-1">Détails</div>-->
                <!--                  <div class="text-primary text-decoration-underline mx-1">Devis</div>-->
                <!--                </div>-->
              </td>
              <td>
                <UserView :key="'manager_'+order.uuid" v-model="order.responseGroup.manager"></UserView>
                <!-- <div class="btn btn-xs btn-icon btn-warning" @click="setduration(duration)">
                    <i class="fad fa-edit"></i>
                </div> -->
              </td>
              <td>
                <div>
                  <div class="btn btn-icon btn-sm btn-success m-1" @click="emit(order)">
                    <i class="fad fa-thumbs-up"></i>
                  </div>
                  <div class="btn btn-icon btn-sm btn-danger m-1" @click="revoke(order)">
                    <i class="fad fa-thumbs-down"></i>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div>

          </div>
        </div>
      </div>

    </template>
  </Base>
</template>
<script lang="ts">
import UserView from '@/components/UserView.vue';
import Order from '@/entity/Order';
import Base from '@/layouts/Base.vue';
import {api} from '@/services/Api';
import store from '@/store';
import Swal from 'sweetalert2';
import {Component, Vue} from 'vue-property-decorator';
import PartnerView from "@/components/PartnerView.vue";

@Component({
  components: {PartnerView, Base, UserView}
})
export default class Delivery extends Vue {

  deliveries: Order[] = []
  order: Order | null = null
  showAll = true

  async mounted() {
    this.load()
  }

  async emit(order: Order) {
    const response = await Swal.fire({
      title: 'Création de contrat',
      html: 'Un contrat sera crée avec les informations de cette cotation. Êtes-vous sûr de vouloir créer ce contrat ?',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Créer le contrat',
      didOpen: () => {
        // Swal.showLoading()
        // const b = Swal.getHtmlContainer().querySelector('b')
        // timerInterval = setInterval(() => {
        //     b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        // clearInterval(timerInterval)
      }
    })
    if (response.isConfirmed) {
      this.$store.commit('loading')
      const res = await api.get(api.core, 'delivery/emit/' + order!.uuid)
      if (res && res.data && res.data.uuid) {
        this.$router.push({name: 'contract-show', params: {contractUuid: res.data.uuid}})
      }
      this.$store.commit('stopLoading')
      // this.loadContract()
    }
  }

  async revoke(order: Order) {
    const response = await Swal.fire({
      title: 'Annuler',
      html: 'Êtes-vous sûr de vouloir annuler cette demande ?',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Oui, Annuler cette demande',
      cancelButtonText: 'Non, Garder cette demande',
      didOpen: () => {
        // Swal.showLoading()
        // const b = Swal.getHtmlContainer().querySelector('b')
        // timerInterval = setInterval(() => {
        //     b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        // clearInterval(timerInterval)
      }
    })
    if (response.isConfirmed) {
      this.$store.commit('loading')
      const res = await api.get(api.form, 'order/delivery/revoke/' + order!.uuid)
      if (res && res.data && res.data.status === 'success') {
        this.load()
      }
      this.$store.commit('stopLoading')
      // this.loadContract()
    }
  }

  load() {
    if (this.showAll) {
      this.loadAll()
    } else {
      this.loadMine()
    }
  }

  async loadAll() {
    const res = await api.get(api.form, 'order/delivery/list')
    if (res && res.data && res.data.orders) {
      this.deliveries = []
      res.data.orders.forEach((o: any) => {
        const occ = new Order(o)
        this.deliveries.push(occ)
      })
    }
    // this.deliveries = deliveries

  }

  async loadMine() {
    const res = await api.get(api.form, 'order/my/delivery/list')
    if (res && res.data && res.data.orders) {
      this.deliveries = []
      res.data.orders.forEach((o: any) => {
        const occ = new Order(o)
        this.deliveries.push(occ)
      })
    }
    // this.deliveries = deliveries

  }
}
</script>
<style scoped></style>
