<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'communication-index'}" class="text-muted text-hover-primary">
                    <i class="fad fa-comments"></i>
                    {{ trans('Communication') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <input class="form-control" placeholder="Rechercher" type="text" v-model="searched"
                   v-on:keyup.enter="search">
            <div class="btn btn-icon btn-primary btn-sm ms-2" @click="search"><i class="fad fa-search"></i></div>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="card">
        <div class="card-body">
          <div class="d-flex">
            <input class="form-control" placeholder="Entrez l'adresse url à raccourcir" type="text"
                   v-model="url">
            <div @click="short()" class="btn btn-primary btn-sm ms-5 d-flex align-items-center">
              <i class="fad fa-search me-2"></i>
              {{ trans('Raccourcir') }}
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-10">
        <div class="card-body">
          <div>
            Résultat
          </div>
          <div class="d-flex flex-wrap">
            <div v-for="url in urls" :key="url.id" class="p-5 m-5 border-dashed mw-150px cursor-pointer">
              <div class="d-flex justify-content-between mb-3">
                <div class="fw-bolder text-uppercase fs-2" @click="copyShortLink(url.id)">
                  {{ shortLink(url.id) }}
                </div>
                <div class="badge badge-danger d-flex align-items-center justify-content-center">
                  {{ url.visitCount }}
                </div>
              </div>
              <div class="fs-6">
                {{ url.target }}
              </div>
            </div>
          </div>
          <div v-if="!empty(result)">
            <div
                class="m-5 bg-light-primary fs-3x border-dashed border-primary rounded p-5 text-center fw-bolder text-primary">
              {{ result }}

              <i class="fad fa-copy fs-3x ms-5 cursor-pointer text-primary text-hover-dark" @click="copy"></i>
            </div>
            <div class="d-flex justify-content-center my-5">
              <vue-qr colorDark="black" colorLight="white" :autoColor="true" class=" rounded position-relative"
                      :text="result" :whiteMargin="true" :qid="result" :size="500"></vue-qr>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Base>

</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Link from "@/entity/Link";
import {helper} from "@/services/Helper";
import Popup from "@/entity/Popup";
import VueQr from 'vue-qr'
import {shortener} from "@/Utils/Shortener";

@Component({
  components: {VueQr, Base}
})
export default class UrlShortener extends Vue {
  [x: string]: any;

  websiteUrl = process.env.VUE_APP_WEBSITE_URL
  result = ''
  links: Link[] = []
  url = ''

  shortLink(id: number) {
    return shortener.converIdToCHar(id)
  }

  copyShortLink(id: number) {
    const link = api.shortener + shortener.converIdToCHar(id)
    navigator.clipboard.writeText(link);
    new Popup('Lien Copié',link,'secondary','fad fa-copy')
  }

  urls: any[] = []
  searched = ''

  async copy() {
    if (this.result) {
      navigator.clipboard.writeText(this.result).then(() => {
        new Popup('Copie Effectuée', 'Lien Copier')
      })
    }
  }

  async search() {
    const res = await api.post(api.shortener, 'url/search', {searched: this.searched})
    if (res && res.data && res.data.urls) {
      this.urls = res.data.urls
    }
  }

  async short() {
    if (!helper.empty(this.url)) {
      const res = await api.post(api.shortener, 'url/short', {'link': this.url})
      if (res && res.data && res.data.link) {
        this.result = res.data.link
      }
    } else {
      new Popup('Url Vide', 'Veuillez entrez un lien à raccourcir')
    }
  }

  async getLinks() {
    const res = await api.get(api.core, 'admin/get/shorten/links')
    if (res && res.data && res.data.links) {
      this.links = []
      res.data.links.forEach((occ: Link) => {
        this.links.push(new Link(occ))
      })
    }
  }

  async mounted() {
  }
}
</script>
<style scoped>
</style>
