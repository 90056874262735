<template>
    <div>
    </div>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';

    @Component({})
    export default class ComparatorMenu extends Vue {
    }
</script>
<style scoped>
</style>
