<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    Tableau de bord
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <!--            <button class="btn btn-primary btn-sm ms-3" @click="createAgency" v-b-modal="'editAgency'">-->
            <!--              <i class="fad fa-plus"></i>-->
            <!--              {{ trans('Créer une agence') }}-->
            <!--            </button>-->
          </div>

        </div>
      </div>
    </template>
    <template #content>
      <div class="card">
        <div class="card-body">
          <Loading v-if="loading"></Loading>
          <PartnerTree v-else-if="root" :partner="root" v-on:createSub="createSub($event)"
                       v-on:edit="editAgency($event)" :isRoot="true" :paymentMethods="paymentMethods"></PartnerTree>
        </div>
      </div>

      <b-modal :title="trans('Créer une agence')" centered id="editAgency" ref="editAgency">
        <form>
          <div class="row" v-if="agency">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Nom') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(agency.name)" v-model="agency.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1" v-if="agency.parent">
              <label :for="'label'" class="required form-label">{{ trans('Agence mère') }}</label>
              <select :id="'label'" :state="!helper.empty(agency.parent)" v-model="agency.parent"
                      class="form-control form-select">
                <option :value="parent.id" v-for="parent of agencies" :key="'a_'+parent.id">
                  {{ parent.name }}
                </option>
              </select>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
              <div class="fs-8 text-danger mb-2">
                Ce code ne doit contenir aucun espace ou caractère spécial, il ne sert pas à être explicite
                mais juste à identifier une agence, il doit contenir entre 2 et 8 caractères
              </div>
              <b-input-group>
                <template #prepend v-if="agency.parent">
                  <b-input-group-text class="bg-light-primary">
                    <strong class="text-primary fw-bolder">
                      {{ getAgencyById(agency.parent) ? getAgencyById(agency.parent).code : '' }}-
                    </strong>
                  </b-input-group-text>
                </template>
                <b-form-input :id="'code'" :state="!helper.empty(agency.code)" v-model="agency.code" v-uppercase
                              maxlength="4">
                </b-form-input>
              </b-input-group>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'contact'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'contact'" :state="!helper.empty(agency.contact)" v-model="agency.contact">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{
                  trans('Methode de paiement autorisée')
                }}</label>
              <div class="d-flex flex-wrap  justify-content-start" v-if="agency.parent">
                <div v-for="method of getAgencyById(agency.parent).paymentMethods"
                     class="m-2 fw-bolder d-flex align-items-center justify-content-start">
                  <label class="form-check form-switch form-check-custom form-check-solid m-2">
                    <input :checked="isMethodActiveForPartner(method, agency)"
                           @change="toggleMethodForPartner(method, agency)" class="form-check-input"
                           type="checkbox"/>
                  </label>
                  {{ method.label }}
                </div>
              </div>
              <div class="d-flex flex-wrap  justify-content-start" v-else>
                <div v-for="method of $store.state.partner.paymentMethods"
                     class="m-2 fw-bolder d-flex align-items-center justify-content-start">
                  <label class="form-check form-switch form-check-custom form-check-solid m-2">
                    <input :checked="isMethodActiveForPartner(method, agency)"
                           @change="toggleMethodForPartner(method, agency)" class="form-check-input"
                           type="checkbox"/>
                  </label>
                  {{ method.label }}
                </div>
              </div>

            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveAgency" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Partner from "@/entity/Partner";
import PartnerTree from "@/views/Partner/PartnerTree.vue";
import Loading from "@/components/Loading.vue";
import {helper} from "@/services/Helper";
import PaymentMethod from "@/entity/PaymentMethod";
import Popup from "@/entity/Popup";

@Component({
  components: {Loading, PartnerTree, Base}
})
export default class PartnerHierarchy extends Vue {

  root: any = null
  partner: Partner | any = null
  agency: Partner | null = null
  paymentMethods: PaymentMethod[] = []

  agencies: any[] = []

  mounted() {

    this.loadData()
  }

  resetAgency() {
    const agency = new Partner()
    agency.parent = this.$store.state.partner
    this.agency = agency
  }


  isMethodActiveForPartner(method: PaymentMethod, partner: Partner) {
    return partner.paymentMethods.find((p: PaymentMethod) => p.id === method.id) ? true : false
  }

  toggleMethodForPartner(method: PaymentMethod, partner: Partner) {
    const index = partner.paymentMethods.findIndex((p: PaymentMethod) => p.id === method.id)
    console.log(index)
    if (index >= 0) {
      partner.paymentMethods.splice(index, 1)
    } else {
      partner.paymentMethods.push(method)
    }
  }

  loading = false

  async loadData() {
    this.loading = true
    const res = await api.get(api.core, 'api/partner/get/hierarchy')
    this.loading = false
    if (res && res.data) {
      this.root = new Partner(res.data.partner)
      this.agencies = []
      this.paymentMethods = []
      res.data.paymentMethods.forEach((p: any) => {
        const occ = new PaymentMethod(p)
        this.paymentMethods.push(occ)
      })
      this.getList(this.root)
    }
  }

  getList(partner: Partner) {
    this.agencies.push(partner)
    partner.agencies.forEach((agency) => {
      this.getList(agency)
    })
  }

  createSub(partner: Partner) {
    // console.log(code)
    this.createAgency(partner)
    const refs = this.$refs as any;
    if (refs.editAgency) {
      refs.editAgency.show()
    }
  }

  createAgency(partner: Partner) {
    this.agency = new Partner()
    this.agency.parent = partner.id as any

  }

  getAgencyById(id: any) {
    return this.agencies.find((agency: Partner) => {
      return agency.id === id
    })
  }

  editAgency(agency: Partner) {

    // console.log(agency)
    this.agency = new Partner({...agency})
    if (this.agency.parent && this.agency.code.includes(this.agency.parent.code)) {
      this.agency.code = this.agency.code.replace(this.agency.parent.code, '')
    }
    if (this.agency.parent) {
      this.agency.parent = this.agency.parent.id as any
    }
    this.agency.code = helper.slugify(this.agency.code, '')
    // console.log(this.agency)
    const refs = this.$refs as any;
    if (refs.editAgency) {
      refs.editAgency.show()
    }
  }

  async saveAgency() {
    this.$store.commit('loading')
    let agency = {...this.agency} as any;
    if (!agency.parent) {
      new Popup('Erreur', 'Selectionnez une agence mère', 'warning')
      return
    }
    // pc.branch = {slug: (pc.branch as any).slug, uuid: (pc.branch as any).uuid} as any
    const res = await api.post(api.core, 'api/agency/edit', {partner: agency})

    // return
    this.$store.commit('stopLoading')
    if (res && res.data) {
      const refs = this.$refs as any;
      if (refs.editAgency) {
        refs.editAgency.hide()
      }
      this.resetAgency()
      this.loadData()
      // this.loadPartner()
    }
  }


}
</script>


<style scoped>

</style>
