
<template>
<div id="filter">
  <div id="container">
    <div id="close" @click="close">
      <i class="fad fa-times fs-2x text-dark cursor-pointer"></i>
    </div>
    <img :src="$store.state.imageZoom" alt="" class="w-100 h-100 object-fit-contain">
  </div>
</div>
</template>
<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';

@Component({})
export default class ImageZoomer extends Vue {

  close(){
    this.$store.commit('unzoomImage')
  }
}
</script>


<style scoped>
#filter {
  position: absolute;
  /*top: 0;*/
  /*left: 0;*/
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .75);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

#container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #17e1e6;
  width: 90%;
  height: 90%;
  //margin: 20px;
  overflow: hidden;
  border-radius:20px;
}
#close{
  position: absolute;
  top:20px;
  right:20px;

}

</style>
