<template>
    <div id="page">
        <div class="d-flex flex-column flex-root">
            <div class="page d-flex flex-row flex-column-fluid">
                <slot name="aside">
                    <LeftAside></LeftAside>
                </slot>
                <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <slot name="header">
                        <HeaderTop v-if="isConnected"></HeaderTop>
                    </slot>
                    <slot name="toolbar">
                    </slot>
                    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div class="post d-flex flex-column-fluid" id="kt_post">
                            <div class="container" id="kt_content_container">
                                <slot name="content">

                                    
                                </slot>
                            </div>
                        </div>
                        <div class="pt-20 text-gray-400 text-center">
                            &copy; Copyright WiASSUR 2021
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slot name="rightModal">

        </slot>
    </div>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';


    import HeaderTop from "@/layouts/HeaderTop.vue";
    import {auth} from "@/services/Auth";
    import Toolbar from "@/layouts/Toolbar.vue";
    import LeftAside from "@/layouts/LeftAside.vue";


    @Component({
        components: {LeftAside, Toolbar, HeaderTop}
    })
    export default class Base extends Vue {
        public fixedToolbar = true;
        public toolbarEnabled = true;

        get isConnected(): boolean {
            return auth.isConnected();
        }


        mounted(): void {

            //     console.log(this.$route);
        }
    }
</script>
<style scoped>
    #page{
        max-width: 100%;
    }
</style>
