<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div @click="resetDataType" class="btn-sm btn btn-primary ms-5"
                 v-b-modal.dataTypeModal>
              <i class="fad fa-plus"></i>
              {{ trans('Creér un type de donnée') }}
            </div>

            <div @click="generate" class="btn-sm btn btn-danger ms-5">
              <i class="fad fa-cogs"></i>
              {{ trans('Générer') }}
            </div>

          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>

      <div class="card">
        <div class="card-body p-0">
          <table class="table table-striped gy-7 gs-7">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>{{ trans('Label') }}</th>
              <th>{{ trans('Code') }}</th>
              <th>{{ trans('Format') }}</th>
              <th>{{ trans('Classe') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="type in types">
              <td>{{ type.label }}</td>
              <td>{{ type.code }}</td>
              <td>{{ type.format }}</td>
              <td>
                <template v-if="type.class">
                  {{ type.class }}
                </template>
                <template v-else>
                  <i class="fad fa-empty-set"></i>
                </template>
              </td>
            </tr>
            </tbody>
          </table>
          <div>

          </div>
        </div>
      </div>

      <b-modal :title="trans('Editer un type de donnée')" centered id="dataTypeModal" ref="dataTypeModal">
        <form>
          <div class="row" v-if="type">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(type.label)"
                            v-model="type.label"></b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(type.code)"
                            v-model="type.code"></b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Type de donnée') }}</label>
              <b-input-group>
                <b-form-select :state="!helper.empty(type.format)"
                               class="form-control form-select"
                               v-model="type.format">
                  <b-form-select-option :value="null">{{ trans('Selectionnez un format') }}
                  </b-form-select-option>
                  <b-form-select-option :key="'format_'+key" :value="format.code" v-for="format,key in formats">
                    {{ trans(format.name) }}
                  </b-form-select-option>
                </b-form-select>
                <b-input-group-append>
                  <div class="btn btn-sm btn-icon btn-danger h-100"
                       style="border-top-left-radius: 0;border-bottom-left-radius: 0">
                    <i class="fad fa-plus"></i>
                  </div>
                </b-input-group-append>
              </b-input-group>
            </div>

            <div class="col-auto m-2 flex-grow-1" v-if="type.format === 'CLASS'">
              <label :for="'class'" class=" form-label">{{ trans('Classe') }}</label>

              <b-form-input :id="'class'" v-model="type.class"></b-form-input>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editDataType()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import Base from "@/layouts/Base.vue";
import DataType from "@/entity/DataType";
import Popup from "@/entity/Popup";

@Component({
  components: {Base}
})
export default class DataTypeManager extends Vue {


  formats = [
    {name: 'Texte', code: 'TEXT'},
    {name: 'Numérique', code: 'NUMBER'},
    {name: 'Date', code: 'DATE'},
    {name: 'Oui/Non', code: 'BOOLEAN'},
    {name: 'Liste', code: 'ARRAY'},
    {name: 'Classe', code: 'CLASS'},
    {name: 'Objet', code: 'OBJECT'},
    {name: 'Groupe d\'objets', code: 'OBJECT_GROUP'},

  ]
  type = new DataType()

  types: DataType[] = []

  resetDataType() {
    this.type = new DataType()
  }

  mounted(): void {
    this.loadTypes()
  }

  async loadTypes() {
    const pop = new Popup('Chargement des types', '', 'primary', 'fad fa-sync', true)
    const res = await api.get(api.master, 'api/get/data/types')
    if (res && res.data && res.data.types) {
      (this.$refs.dataTypeModal as any).hide()
      this.types = []
      res.data.types.forEach((type: DataType) => {
        this.types.push(new DataType(type))
      })
    }
    pop.hide()
  }

  async editDataType() {
    const res = await api.post(api.master, 'data/type/edit', {type: this.type})
    if (res && res.data && res.data.type) {
      (this.$refs as any).dataTypeModal.hide()
      this.loadTypes()
    }
  }

  async generate() {
    const res = await api.get(api.master, 'data/type/generate')
  }
}
</script>
<style scoped>
</style>
