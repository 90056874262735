<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    Tableau de bord
                  </router-link>

                </li>
                <li class="breadcrumb-item">
                  <span class="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <li class="breadcrumb-item text-muted">
                  <!--                                    <router-link :to="{name: 'form-list', params : { slug : survey.branch }}" v-if="survey">-->
                  <!--                                        Formulaires-->
                  <!--                                    </router-link>-->
                </li>
                <li class="breadcrumb-item">
                  <span class="bullet bg-gray-200 w-5px h-2px"></span>
                </li>
                <li class="breadcrumb-item text-muted">
                  {{ trans('Edit_survey') }}
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <button class="btn btn-primary btn-sm ms-3" @click="print">
              <i class="fad fa-print"></i>
              {{ trans('Imprimer') }}
            </button>
          </div>

        </div>
      </div>
    </template>
    <template #content>
      <div class="w-100">
        <iframe id="iframe" class="flex-1 w-100" style="min-height:2000px;min-width:900px">
        </iframe>
      </div>


    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import User from "@/entity/User";
import FileUpload from "@/components/FileUpload.vue";
import {api} from "@/services/Api";
import {auth} from "@/services/Auth";
import Swal from 'sweetalert2';
import JsonViewer from 'vue-json-viewer'

@Component({
  components: {FileUpload, Base, JsonViewer}
})
export default class Receipt extends Vue {

  coreEndpoint = api.core

  beforeUnmount() {
    // window.removeEventListener('DOMContentLoaded', this.resize)
    // window.removeEventListener("message", this.xhrListener, false);
  }

  mounted() {
    this.getData()
    // window.addEventListener("message", this.xhrListener, false);
    // window.addEventListener('DOMContentLoaded', this.resize);
  }

  async getData() {
    this.$store.commit('loading')
    const url = this.coreEndpoint + 'payment/receipt/' + this.$route.params.uuid + '?zone=' + this.$store.state.currentTenant.code


    // const config = {responseType: 'blob'};
    // const res = await api.get(this.coreEndpoint, 'payment/receipt/' + this.$route.params.uuid + '?zone=' + this.$store.state.currentTenant.code, config)
    // this.$store.commit('stopLoading')
    // api.downloadBlob(res)
    // return
    //
    var xhr = new XMLHttpRequest();
    const res = await api.refreshToken()
    xhr.open('GET', url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE) {
        console.log(xhr.status)
        if (xhr.status === 200) {
          var data_url = URL.createObjectURL(xhr.response);
          const iframe = document.querySelector('iframe')
          iframe!.src = data_url;
          iframe!.onload = () => {
            this.$store.commit('stopLoading')
          }
        } else {
          console.error('no pdf :(');
        }
      }
    };
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.$store.state.userToken);
    xhr.send();


  }

  create() {

  }

  edit() {

  }

  save() {

  }

  print() {
    (document as any).getElementById("iframe")!.contentWindow.print();

  }

  json: any = null

  async xhrListener(event: any) {
    if (event.data.method === 'GET') {
      console.log(event)
      this.$store.commit('loading')
      if (event.data.action === 'download') {
        const res = await api.get('', event.data.url, {responseType: 'blob'})
        const blob = new Blob([res.data], {type: res.headers['content-type']});
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement('a');
        a.setAttribute("download", res.headers['name']);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else if (event.data.action === 'show-json') {
        // console.log('SHOW JSON')
        // console.log(event.data.data)
        this.json = JSON.parse(event.data.data);
        (this.$refs as any).jsonViewer.show()
        this.$store.commit('stopLoading')
      } else if (event.data.action === 'new-demande-attestation') {
        this.$store.commit('loading')
        const res = await api.get(api.core, 'demande/attestation/new/' + this.$route.params.itemUuid)
        if (res && res.data && res.data.demande) {
          window.location.reload()
        }
        this.$store.commit('stopLoading')
      } else if (event.data.url) {
        const res = await api.get('', event.data.url)
      }
      // console.log(res)
      this.$store.commit('stopLoading')
    }
  }


}
</script>
<style scoped></style>
