<template>
    <tbody>
    <tr>
        <td>
        <label :class="{ 'fw-bolder cursor-pointer text-hover-primary' : item.children && item.children.length > 0, 'text-primary' :
        item.children && item.children.length > 0 && !expand }"
               class="AvenantDetail p-2 d-flex align-items-center justify-content-between bg-hover-light-primary border-bottom">
            <input class="hidden" type="checkbox" v-model="expand">
            <div class="d-flex align-items-center flex-">
                <span v-for="index in level">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <template v-if="item.children && item.children.length > 0">
                    <i class="fad fa-angle-right fs-2 me-2" v-if="!expand"></i>
                    <i class="fad fa-angle-down fs-2 me-2" v-else></i>
                </template>
                {{item.name}}
            </div>
            <div>
                <template v-if="item.beneficiary">
                    <!--                    {{item.beneficiary.name}}-->
                    {{item.beneficiary.getPropByCode('name')}} {{item.beneficiary.getPropByCode('prename')}}
                </template>
            </div>
            <div class="fw-bolder ms-5" v-if="!helper.empty(item.premium)">
                <span class="badge ms-5 badge-danger">{{currency(item.premium)}}</span>
            </div>
        </label>
        </td>

    </tr>
    <template v-if="expand">
        <template v-for="child in item.children">
            <AvenantDetail :item="child" :level="level+1"></AvenantDetail>
        </template>
    </template>
    </tbody>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Item from "@/entity/Item";

    @Component({})
    export default class AvenantDetail extends Vue {
        @Prop() item!: Item
        @Prop({default: 0}) level!: number
        expand = true

        mounted(): void {
            console.log(this.item)
        }

    }
</script>
<style scoped>
</style>
