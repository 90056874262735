<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Survey_list') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1" v-b-modal="'insurerForm'">
            <button class="btn btn-primary btn-sm">
              <i class="fad fa-plus"></i>
              {{ trans('Créer un assureur') }}
            </button>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex flex-wrap">
        <div class="card w-250px m-5 shadow-sm" v-for="insurer in insurers">
          <div class="card-body p-0 d-flex flex-column">
            <div class="d-flex justify-content-center position-relative">
              <div class="position-absolute" style="top:10px;left:10px">
                <div class="form-check form-switch form-check-custom form-check-solid ">
                  <input @change="toggleActivation(insurer)" class="form-check-input h-20px w-30px"
                         type="checkbox" v-model="insurer.active" value=""/>
                </div>
              </div>
              <img :src="uploadRoot + insurer.photo" alt="" class="w-50px h-50px m-5 object-contain rounded">
              <div class="position-absolute" style="top:10px;right:10px">
                <!--                <div @click="editInsurer(insurer)" class="btn btn-xs btn-icon btn-light-primary me-2"-->
                <!--                     v-b-modal="'insurerForm'">-->
                <!--                  <i class="fad fa-edit"></i>-->
                <!--                </div>-->
                <router-link :to="{name: 'insurer-form', params : {slug : insurer.slug}}"
                             class="btn btn-xs btn-icon btn-light-primary me-2">
                  <i class="fad fa-edit"></i>
                </router-link>
                <div @click="deleteInsurer(insurer)" class="btn btn-xs btn-icon  btn-light-danger"
                     v-if="hasRole('ROLE_ADMIN')">
                  <i class="fad fa-trash"></i>
                </div>
              </div>
            </div>
            <div class="text-center fw-bolder pb-5">
              {{ insurer.label }}
            </div>
            <div class="text-center fw-bolder pb-5">
              {{ insurer.slug }}
            </div>

            <div class="d-flex justify-content-between align-items-center px-4 pb-4 ">
              <div class="fw-bolder text-primary">
                <i class="fad fa-file fs-2"></i>
                <span class="text-dark">
                {{ insurer.eAttestationCountBlue }}
                  </span>
              </div>
              <div class="fw-bolder text-warning">
                <i class="fad fa-file fs-2"></i>
                <span class="text-dark">
                {{ insurer.eAttestationCountYellow }}
                  </span>
              </div>
              <div class="fw-bolder text-success">
                <i class="fad fa-file "></i>
                <span class="text-dark">
                {{ insurer.eAttestationCountGreen }}
                </span>
              </div>
            </div>
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex border-bottom">
                <div
                    :class="[selectedCategory.uuid === cat.uuid ? 'bg-primary text-white' : 'text-gray-400 bg-hover-light-primary']"
                    @click="setSelectedCategory(cat)" class="flex-grow-1 text-center fw-bolder p-2"
                    v-for="cat in categories">
                  {{ cat.label }}
                </div>
              </div>
              <template v-for="cat in categories">
                <div class="d-flex border-bottom">
                  <div
                      :class="[selectedBranch.uuid === branch.uuid ? 'bg-primary text-white' : 'text-gray-400 bg-hover-light-primary']"
                      :title="branch.label" @click="setSelectedBranch(branch)"
                      class="d-flex justify-content-center p-3 flex-grow-1 cursor-pointer" v-b-tooltip.hover
                      v-for="branch, index in cat.branches"
                      v-if="branch.category.uuid === selectedCategory.uuid">
                                    <span class="svg-icon svg-icon-3x svg-icon-success">
                                        <i :class="branch.icon" v-if="branch.icon"></i>
                                        <i class="fad fa-question-circle" v-else></i>
                                    </span>
                  </div>
                </div>
              </template>
              <div class="flex-grow-1">
                <template v-for="pack, index in insurer.packs">
                  <div class="p-3 border-bottom d-flex"
                       v-if="selectedBranch && pack.branchSlug === selectedBranch.slug">
                    <div class="flex-grow-1">
                      {{ pack.label }}
                    </div>
                    <div>
                      <router-link :to="{
                                            name: 'pack-form', params: {
                                                insurerSlug: insurer.slug, branchSlug: pack.branchSlug,
                                                packId: pack.id
                                            }
                                        }" class="btn btn-xs btn-icon btn-primary me-2">
                        <i class="fad fa-edit"></i>
                      </router-link>
                      <div @click="deletePack(pack)" class="btn btn-xs btn-icon btn-danger">
                        <i class="fad fa-trash"></i>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="position-relative">
              <template v-for="cat in categories">
                <div class="d-flex justify-content-center p-3" v-for="branch, index in cat.branches"
                     v-if="selectedBranch.uuid === branch.uuid">
                  <router-link
                      :to="{ name: 'pack-form', params: { insurerSlug: insurer.slug, branchSlug: branch.slug } }"
                      class="btn btn-primary btn-sm">
                    <i class="fad fa-plus"></i>
                    Créer un pack
                  </router-link>
                </div>
              </template>
            </div>
          </div>
        </div>

      </div>


      <b-modal :title="trans('Editer un assureur')" centered id="insurerForm" ref="insurerForm">
        <form>
          <div class="row" v-if="insurer">
            <div class="d-flex justify-content-center">
              <FileUpload :allowMultiple="false" :allowRemove="false"
                          :allowedType="'image/jpeg,image/png,image/gif,image/jfif'" :fitMode="'contain'"
                          :icon="'fad fa-edit'"
                          :previewClass="'d-flex border-dashed w-150px object-contain cursor-pointer h-150px align-items-center justify-content-center m-auto'"
                          :text="'Selectionnez un logo'" :type="'full'" accept=".jpg,.jpeg,.png,.gif,.jfif"
                          v-on:filename="insurer.photo = $event"></FileUpload>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(insurer.label)"
                            v-model="insurer.label"></b-form-input>
            </div>


            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Code ASACI') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(insurer.codeCompagnie)"
                            v-model="insurer.codeCompagnie"></b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Code Autorisation ASACI') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(insurer.codeAutorisation)"
                            v-model="insurer.codeAutorisation"></b-form-input>
            </div>


            <!--            <div class="d-flex justify-content-between align-items-center mt-5">-->
            <!--              <div class="fw-bolder fs-2">-->
            <!--                Les propriétés-->
            <!--              </div>-->
            <!--              <div>-->
            <!--                <div class="btn btn-icon btn-xs btn-primary">-->
            <!--                  <i class="fad fa-plus"></i>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveInsurer" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal :title="trans('Editer un pack')" centered id="packForm" ref="packForm" scrollable size="xl">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Nom') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(pack.label)" v-model="pack.label"></b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Catégorie') }}</label>
              <!--                            <b-form-input :id="'label'" :state="!helper.empty(pack.)"-->
              <!--                                          v-model="insurer.label"></b-form-input>-->
            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveInsurer" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import Base from "@/layouts/Base.vue";
import Insurer from "@/entity/Insurer";
import FileUpload from "@/components/FileUpload.vue";
import BranchCategory from "@/entity/BranchCategory";
import Popup from "@/entity/Popup";
import Branch from "@/entity/Branch";
import Pack from "@/entity/Pack";

@Component({
  components: {FileUpload, Base}
})
export default class InsurerList extends Vue {
  insurers: Insurer[] = []
  insurer = new Insurer()
  pack = new Pack()
  uploadRoot = api.uploadRoot
  categories: BranchCategory[] = []
  selectedCategory: any = null
  selectedBranch: any = null

  async mounted() {
    await this.loadInsurers()
    await this.loadBranch()
  }

  branchSelected = null

  cancel() {

  }

  loadPack() {

  }

  async toggleActivation(insurer: Insurer) {
    let message = 'Activation';
    if (insurer.active) {
      message = 'Désactivation';
    }
    const p = new Popup(insurer.label, message, 'primary', 'fad fa-sync', true);
    const res = await api.get(api.core, 'insurer/toggle/activation/' + insurer.uuid);
    p.hide()
    if (res && res.data) {
      insurer.active = res.data.state
    }
  }

  async saveInsurer() {
    const popup = new Popup('Enregistrement en cours ...', '', 'warning', 'fad fa-cogs', true)
    const insurer = {...this.insurer}
    const res = await api.post(api.core, 'insurer/edit', {insurer});
    (this.$refs.insurerForm as any).hide()
    popup.hide()
    this.loadInsurers()
  }

  async loadInsurers() {
    const popup = new Popup('Chargement des assureurs', '', 'warning', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'insurer/list')
    if (res && res.data && res.data.insurers) {
      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        this.insurers.push(new Insurer(i))
      })
    }
    popup.hide()
  }

  async loadBranch() {
    const popup = new Popup('Chargement des branches', '', 'warning', 'fad fa-cogs', true)
    this.categories = await this.$store.getters.branchCategories()
    if (this.categories.length > 0) {
      const c = this.categories[0]
      this.setSelectedCategory(c)
      if (c.branches.length > 0) {
        this.setSelectedBranch(c.branches[0])
      }
    }
    popup.hide()
  }

  editInsurer(insurer: Insurer) {
    this.insurer = new Insurer({...insurer})
  }

  async deleteInsurer(insurer: Insurer) {
    const res = await api.get(api.core, 'insurer/delete/' + insurer.uuid)
    this.loadInsurers()
  }

  async deletePack(pack: Pack) {
    const res = await api.get(api.core, 'pack/delete/' + pack.id)
    this.loadInsurers()
  }

  setSelectedCategory(cat: BranchCategory) {
    this.selectedCategory = cat
    if (cat.branches.length > 0) {
      this.setSelectedBranch(cat.branches[0])
    }
  }

  setSelectedBranch(branch: Branch) {
    this.selectedBranch = branch

  }
}
</script>
<style scoped>
i.fad {
  color: inherit !important;
}
</style>
