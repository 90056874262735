<template>
    <Base>
        <template v-slot:toolbar>
            <div class="toolbar" id="kt_toolbar">
                <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
                    <div class="d-flex align-items-center me-3">
                        <slot name="leftToolbar">
                            <span class="h-20px border-gray-200 border-start mx-4"></span>
                            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                <li class="breadcrumb-item text-muted">
                                    <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                                        <i class="fad fa-home"></i>
                                        {{trans('Survey_list')}}
                                    </router-link>
                                </li>
                            </ul>
                        </slot>
                    </div>


                    <div class="d-flex align-items-center py-1">
                        <router-link :to="{ name : 'comparator-form-processor-full',
                                                 params: {surveySlug: $store.state.form.slug, uuid: $store.state.rg.uuid}

                                                 }"
                                     class="btn btn-sm me-3 btn-danger"
                                     target="_blank" v-if="$store.state.form && $store.state.rg">
                            <i class="fad fa-desktop-alt"></i>
                            {{trans('Plein ecran')}}
                        </router-link>

                        <router-link
                                :to="{ name: 'survey-form',params: { surveySlug : $store.state.form.slug,branchSlug : $store.state.form.branch } }"
                                class="btn btn-sm btn-primary"
                                target="_blank"
                                v-if="$store.state.form">
                            <i class="fad fa-ballot-check"></i>
                            {{trans('Modifier les questions')}}
                        </router-link>
                    </div>

                </div>
            </div>
        </template>
        <template v-slot:content>
            <FormProcessor :key="'rgKey_master_'+rgUuid" :name="'master'" :rgUuid="rgUuid" v-if="rgUuid" v-on:reset="reset"></FormProcessor>
        </template>
    </Base>
</template>
<script lang="ts">
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import Base from "@/layouts/Base.vue";
    import FormProcessor from "@/views/Income/Comparator/FormProcessor.vue";
    import {Route} from "vue-router";

    @Component({
        components: {FormProcessor, Base}
    })
    export default class FormProcessorAdminUi extends Vue {
        rgUuid: any = null

        mounted() {
            this.reset()
        }
        @Watch('$route', { immediate: true, deep: true })
        onUrlChange(newVal: Route) {
            this.reset()
        }
        reset(){
            // console.log('RESET')
            // console.log(this.$route.params.uuid)
            this.rgUuid = this.$route.params.uuid
        }
    }
</script>
<style scoped>
</style>
