<template>
    <div class="ProspectIdentifier">
        <div class="position-fixed bg-white p-5 rounded shadow-lg d-inline-flex " style="right: 40px;bottom:40px;z-index: 2"
             v-if="!rg.isSecure && !helper.empty(potentialOwners) && !hide">
            <div>
                <h4 class="d-flex align-items-center justify-content-between">
                    <span class="me-20">
                    <i class="fad fa-user me-2 text-dark fs-2"></i>
                    {{trans('Continuer en tant que :')}}
                    </span>
                    <label class="cursor-pointer">
                        <i class="fad fa-times"></i>
                        <input type="checkbox" v-model="hide" class="hidden">
                    </label>
                </h4>

                <div class="mh-100px mt-3 overflow-auto" style="border-top:1px gainsboro solid;border-bottom:1px gainsboro solid">
                    <div>
                        <div class="py-2 bg-hover-light-primary" v-for="owner in potentialOwners">
                            <i :title="trans('Requiert un mot de passe')" class="fad fa-lock me-2 text-success" v-b-tooltip.hover
                               v-if="owner.auth"></i>
                            <i class="fad fa-lock-open me-2" v-else></i>
                            {{owner.email}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import ResponseGroup from "@/entity/ResponseGroup";
    import {api} from "@/services/Api";

    @Component({})
    export default class ProspectIdentifier extends Vue {
        [x: string]: any;

        @Prop() potentialOwners!: []
        @Prop() rg!: ResponseGroup
        email = ''
        contact = ''
        hide = false

        mounted() {
            // console.log(this.potentialOwners)
        }

        async secure() {
            if (!this.empty(this.email)) {
                const res = await api.get(api.form, 'secure/response/group/' + this.rg.uuid + '/' + this.email + '/' + this.contact)
                console.log(res)
            }

        }
    }
</script>
<style scoped>

</style>
