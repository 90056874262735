<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <router-link :to="{name:'email-template-form'}" class="btn-sm btn btn-primary ms-5">
              <i class="fad fa-plus"></i>
              {{ trans('Créer') }}
            </router-link>

          </div>

        </div>
      </div>
    </template>

    <template #content>
      <div class="card">
        <div class="card-body">

          <table class="table table-striped gx-5">
            <thead>
            <tr>
              <th>
                {{ trans('Modèle') }}
              </th>
              <th>
                {{ trans('Destinataire par defaut') }}
              </th>
              <th>
                {{ trans('Actions') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="template in templates">
              <td>
                <div class="fw-bolder">
                  {{ template.name }}
                </div>
                <div>
                  {{ template.description }}
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <div v-for="(recipient,index) in template.defaultRecipients" class="m-1 badge-light-primary badge">
                    {{ recipient }}
                  </div>
                </div>
              </td>
              <td>
                <router-link :to="{name:'email-template-form',params:{uuid:template.uuid}}"
                             class="btn btn-warning btn-icon btn-sm">
                  <i class="fad fa-edit"></i>
                </router-link>
                <div class="btn btn-sm btn-icon btn-danger ms-2" @click="remove(template)">
                  <i class="fad fa-trash-alt"></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </Base>
</template>

<script lang="ts">
import Base from "@/layouts/Base.vue";
import {Vue, Component} from 'vue-property-decorator';
import {api} from "@/services/Api";
import EmailTemplate from "@/entity/EmailTemplate";

@Component({
  components: {Base}
})
export default class EmailTemplateList extends Vue {


  templates: EmailTemplate[] = []

  mounted() {
    this.load()
  }

  async remove(template: EmailTemplate) {
    const res = await api.get(api.com, 'email/template/delete/' + template.uuid)
    if (res) {
      this.load()
    }
  }

  async load() {
    this.$store.commit('loading')
    const res = await api.get(api.com, 'email/template/list')
    if (res && res.data) {
      this.templates = []
      res.data.templates.forEach((t: any) => {
        const occ = new EmailTemplate(t)
        this.templates.push(occ)
      })
    }
    this.$store.commit('stopLoading')
  }

}
</script>


<style scoped>

</style>