/* eslint-disabled */
import Partner from "./Partner";
import PaymentTransaction from "./PaymentTransaction";
import PaymentMethodExtend from "@/entity/extends/PaymentMethodExtend";

export default class PaymentMethod extends PaymentMethodExtend {

    public id = '';
    public label = '';
    public slug = '';
    public active = '';
    public partners: Array<Partner> = [];
    public transactions: Array<PaymentTransaction> = [];
    public requireManagerValidation? = false;
    public requirePhoneNumber? = false;
    public uuid? = '';
    public createdAt?: Date;
    public updatedAt?: Date;
    public createBy? = '';
    public updateBy? = '';
    public removeBy? = '';
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public photo? = '';
    public alt? = '';
    public ranking?: number;

    constructor(object?: any) {
        super(object)
        if (object) {
            this.id = object.id;
            this.label = object.label;
            this.slug = object.slug;
            this.active = object.active;
            this.requirePhoneNumber = object.requirePhoneNumber
            if (object.partners) {
                object.partners.forEach((occ: any) => {
                    const partner = occ instanceof Partner ? occ : new Partner(occ);
                    this.partners.push(partner);
                });
            }
            if (object.transactions) {
                object.transactions.forEach((occ: any) => {
                    const paymenttransaction = occ instanceof PaymentTransaction ? occ : new PaymentTransaction(occ);
                    this.transactions.push(paymenttransaction);
                });
            }
            this.requireManagerValidation = object.requireManagerValidation;
            this.uuid = object.uuid;
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            this.createBy = object.createBy;
            this.updateBy = object.updateBy;
            this.removeBy = object.removeBy;
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            this.photo = object.photo;
            this.alt = object.alt;
            this.ranking = object.ranking;
        }
        this.postConstruct()
    }

}
