<template>
  <div @dragover="allowDrop()" @drop="drop()" class="uploader" :key="helper.generateId()">

    <div class="text-danger" v-if="error && showErrors">
      {{ message }}
    </div>
    <div class="d-flex w-100 align-items-center" v-if="(file || currentFileName) && !previewStyle && !previewClass">
      <i class="fad fa-file me-2 fs-3x"></i>
      <div class="flex-grow-1">
        <div class="text-start">
          {{ excerpt }}
        </div>
        <div class="progress mw-200px" v-if="isUploading">
          <div :aria-valuenow="percent" :style="{ 'width' : percent+'%' }"
               aria-valuemax="100" aria-valuemin="0"
               class="progress-bar progress-bar-striped progress-bar-animated "
               role="progressbar"></div>
        </div>
      </div>

      <button @click.prevent="downloadFile" class="btn btn-primary btn-icon btn-sm ms-3" v-if="allowDownload">
        <i class="fad fa-file-download text-inverse-primary"></i>
      </button>
      <button @click.prevent="cancelUpload" class="btn btn-danger btn-icon btn-sm ms-3" v-if="allowRemove">
        <i class="fad fa-times text-inverse-danger"></i>
      </button>
    </div>
    <div class="d-flex align-items-center" v-else-if="!previewStyle && !previewClass">
      <label :style="buttonStyle" class="btn btn-primary flex-grow-1"
             :class="[{'btn-icon' : !text || text.length === 0, 'btn-sm' : buttonClass && !buttonClass.includes('btn-lg') && !buttonClass.includes('btn-xs') },buttonClass]">
        <input :accept="accept" :multiple="allowMultiple" @change="uploadFile"
               type="file">
        <i :class="icon" v-if="icon"></i>
        <i class="fad fa-upload" v-else></i>
        <span>
                    {{ text }}
                </span>
      </label>
    </div>
    <label :class="previewClass" :for="inputId" :style="previewStyle" v-if="previewStyle || previewClass">
      <input :accept="accept" :id="inputId" :multiple="allowMultiple" @change="uploadFile" type="file">
      <template v-if="background">
        <img :src="background" :style="{ 'object-fit': fitMode ? fitMode : 'cover' }" :alt="value" class="w-100 h-100">
        <div
            class="position-absolute  w-50px h-50px d-flex justify-content-center  align-items-center fw-bolder fs-7x text-primary"
            v-if="isUploading">
          {{ percent }}%
          <i class="fad fa-spinner-third spin text-danger fs-4x position-absolute"></i>
        </div>
      </template>
      <template v-else>
        <span>{{ text }}</span>
      </template>
    </label>
  </div>
</template>
<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {uploadService} from "@/services/UploadService";
import File from "@/entity/File";
import {api} from "@/services/Api";
import {helper} from "@/services/Helper";

@Component({})
export default class FileUpload extends Vue {
  @Prop() allowMultiple?: boolean;
  @Prop() allowDownload?: boolean;
  @Prop() allowRemove?: boolean;
  @Prop() showFullName?: boolean;
  @Prop() currentFile ?: File;
  @Prop() buttonClass?: any;
  // publicUrl = process.env.VUE_APP_BACKEND_URL;
  @Prop() fitMode ?: string;
  @Prop() accept ?: string;
  @Prop() data ?: any;
  @Prop() allowedType ?: any;
  @Prop() text ?: string;
  @Prop() type ?: string;
  @Prop() icon ?: string;
  @Prop() subFolder ?: string;
  @Prop() previewStyle?: string;
  @Prop() previewClass?: string;
  @Prop() buttonStyle?: string;
  @Prop() currentFileName?: string
  @Prop({default: false}) showErrors?: boolean

  @Prop({default: null}) readonly value!: string
  @Prop() currentImageLink?: string;
  public types: string[] = [];
  message = 'Selectionnez un fichier';
  error = false;
  background: any = '';
  public file: any = null;
  folder = null;
  percent = 100;
  success = false;
  @Prop() uuid ?: string;
  isUploading = false;
  fileId = helper.generateId();
  inputId = '';
  uploadRoot = api.uploadRoot


  get excerpt(): string {
    if (this.file) {
      if (this.showFullName) {
        return this.file.name ?? 'Fichier uploadé'
      }
      if (this.file instanceof File) {
        return this.file.excerpt
      }
      let ln = '';
      if (this.file.name.length > 30) {
        ln = this.file.name.substring(0, 20);
        ln += '...' + this.file.name.substring((this.file.name.length - 5), this.file.name.length);
        return this.substract(this.file.name);
      }
      return this.file.name;
    }
    if (this.currentFileName) {
      return this.substract(this.currentFileName)
    }
    return 'Fichier Uploader';
  }

  @Watch('value')
  loadBackground() {
    if (this.value) {
      console.log(this.value)
      if (this.value.includes(this.uploadRoot)) {
        this.background = this.value
      } else {
        this.background = this.uploadRoot + this.value;
        this.$forceUpdate()
      }
    }
  }

  substract(text: string, count: number = 30) {
    if (text.length > 30) {
      let ln = text.substring(0, 20);
      ln += '...' + text.substring((text.length - 5), text.length);
      return ln
    }
    return text
  }

  mounted(): void {
    if (this.currentFile) {
      this.file = this.currentFile;
    }

    this.inputId = uploadService.generateId();

    if (this.allowedType) {
      if (typeof this.allowedType === 'string') {
        this.types = this.allowedType.split(',');
      } else if (Array.isArray(this.allowedType)) {
        this.types = this.allowedType;
      }
    }

    if (this.currentImageLink) {
      this.background = this.currentImageLink;
    }
    if (this.value) {
      if (this.value.includes(this.uploadRoot)) {
        this.background = this.value
      } else {
        this.background = this.uploadRoot + this.value;
      }
    }
    uploadService.on('uploadStart', (data: any) => {
      this.$emit('uploading')
      if (data.id === this.fileId) {
        this.percent = 0
        this.isUploading = true;
        this.percent = data.percent;
      }
    });
    uploadService.on('chunkSent', (data: any) => {
      if (data.id === this.fileId) {
        this.percent = data.percent;
      }
    });
    uploadService.on('uploadSuccess', (data: any) => {

      if (data.id === this.fileId) {
        this.isUploading = false;
        this.error = false;
        this.success = true;
        this.percent = 100;
        // this.setUuid(data.serverFileId);
        console.log(data)
        this.$emit('uuid', data.serverFileId);
        this.$emit('link', data.link);
        this.$emit('filename', data.filename);
        this.$emit('input', data.filename)

        this.uploadData = {
          id: data.serverFileId,
          link: data.link,
          filename: data.filename,
          type: data.type
        }
        this.$emit('fileUploaded', this.uploadData)

      }
    });
    uploadService.on('error', (data: any) => {

      if (data.id === this.fileId) {
        // const error = data.xhr.error;
        this.isUploading = false;
        this.error = true;
        this.message = 'Une erreur a été rencontré lors du téléversement, veuillez réessayer plus tard';
        this.$emit('error', this.message)
      }
    });
  }

  async downloadFile(): Promise<void> {
    if (this.file instanceof File) {
      await api.downloadFile(this.file);
    }

  }

  drop() {
    console.log('drop');
  }

  allowDrop() {
    console.log('allow drop');
  }


  setUuid(uuid: string) {
    this.uuid = uuid;
  }

  cancelUpload() {
    uploadService.cancel(this.fileId);
    if (this.file instanceof File) {
      this.$emit('remove', this.file.uuid);
    }
    this.file = null;
    this.fileId = '';
    this.isUploading = false;
    this.percent = 0;
    this.error = false;
    this.success = false;
    this.background = '';
  }

  key = helper.generateId()

  uploadData: any = null

  uploadFile(event: Event): void {

    if (this.uploadData) {
      this.$emit('sendFileMessage', this.uploadData)
      return
    }

    this.error = true;
    let files = (event.target as any).files;
    if (files.length <= 0) {
      this.message = 'Selectionnez un fichier';
      this.$emit('error', this.message)
      return;
    }
    const file = files[0] as any;

    if (this.types.length > 0) {
      if (this.types.indexOf(file.type) === -1) {
        this.error = true;
        this.message = 'Format "' + file.type + '" non autorisé';
        this.$emit('error', this.message)
        return;
      }
    }
    this.$emit('queue')
    this.file = file;
    if (this.previewStyle || this.previewClass) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.background = reader.result;
      });
      reader.readAsDataURL(file);
    }
    this.isUploading = true;
    this.error = false;
    this.success = false;


    // uploadService.on('uploadStart', (data: any) => {
    //   console.log('uploadstart')
    //   if (data.id === this.fileId) {
    //     this.$emit('uploading')
    //     this.isUploading = true;
    //     // this.percent = data.percent;
    //   }
    // });
    // uploadService.on('chunkSent', (data: any) => {
    //   if (data.id === this.fileId) {
    //     // console.log(data.percent)
    //   }
    // });
    // uploadService.on('uploadSuccess', (data: any) => {
    //   if (data.id === this.fileId) {
    //     this.isUploading = false;
    //     this.uploadData = {
    //       id: data.serverFileId,
    //       link: data.link,
    //       filename: data.filename,
    //       type: data.type
    //     }
    //     this.$emit('sendFileMessage', this.uploadData)
    //   }
    // });
    // uploadService.on('error', (data: any) => {
    //   if (data.id === this.fileId) {
    //     this.message = "Une erreur a été rencontré lors du tééversement du fichier."
    //     this.isUploading = false;
    //     this.error = true;
    //     this.$emit('error', this.message)
    //   }
    // });

    uploadService.upload(file, this.fileId, undefined, this.data, this.subFolder);
  }

}
</script>
<style scoped>
input[type='file'] {
  display: none;
}

.percent-container {
  position: absolute;
  bottom: 20px;
  width: 90%;
  height: 20px;
  z-index: 3;
  background: rgba(255, 255, 255, .4);
  box-shadow: 0 0 1px grey;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
}

.spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg)
  }
  100% {
    transform: rotateZ(360deg)
  }
}
</style>
