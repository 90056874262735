<template>
  <div class="d-flex align-items-center">
    <div>
      <div v-if="!helper.empty(user.identifier)">
        <div class="cursor-pointer fw-bolder ">
          {{ user.identifier }}
        </div>
        <div class="text-muted fw-bolder cursor-pointer" v-if="!oneLine">
          {{ user.email }}
        </div>
      </div>

      <span v-else class="fw-bolder min-w-100px">
            {{ value }}

        </span>
    </div>
    <div class="cursor-pointer ms-5" @click="load">
      <i class="fad fa-sync"></i>
    </div>
  </div>
</template>

<script lang="ts">
import User from '@/entity/User';
import {api} from '@/services/Api';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {}
})
export default class UserView extends Vue {

  @Prop({default: null}) readonly value!: string
  @Prop({default: false}) readonly oneLine!: boolean
  user: User = new User()


  async mounted() {
    const manager = await api.getManager(this.value)
    if (manager) {
      this.user = new User(manager)
    }
  }

  async load() {
    const type = localStorage.getItem('userType');
    let res = null;
    if (type === 'partner') {
      res = await api.get(api.auth, 'partner/api/get/user/' + this.value)
    } else {
      res = await api.get(api.auth, 'api/get/user/' + this.value)
    }
    if (res && res.data && res.data.user) {
      this.user = new User(res.data.user)
    }
  }
}
</script>
