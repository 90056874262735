<template>
  <div class="QuestionList d-flex flex-wrap w-100" :key="'question_'+question.uuid">
    <div class="flex-grow-1 m-5" v-for="question in questions">
            <span class="fw-bolder">
                <span v-for="link in question.ariane">
                    {{ link }}
                </span>
            </span>
      <br>

      <QuestionTypeSelector :creatingResponseGroup="creatingResponseGroup" :melted="true" :question="question"
                            v-on:deleteSubResponseGroup="deleteSubResponseGroup" v-on:reset="reset">
      </QuestionTypeSelector>

    </div>
  </div>
</template>
<script lang="ts">
import Question from '@/entity/Question';
import {Component, Prop, Vue} from 'vue-property-decorator';
import ResponseGroup from "@/entity/ResponseGroup";
import Survey from "@/entity/Survey";
import Response from "@/entity/Response";
import {helper} from "@/services/Helper";
import QuestionTypeSelector from "@/views/Income/Comparator/QuestionTypeSelector.vue";

@Component({
  components: {QuestionTypeSelector}
})
export default class QuestionList extends Vue {
  @Prop() question!: Question
  rg!: ResponseGroup
  survey: any
  questions: Question[] = []
  questionUuids: string[] = []
  allQuestions: Question[] = []
  ariane: any[] = []
  creatingResponseGroup = false

  beforeUpdate(): void {
    this.load()
    // console.log('updated')
  }

  // activated(): void {
  //     console.log('activated')
  // }

  mounted(): void {
    this.load()
    // console.log(this.ariane)
    // console.log(this.$store.state.rgs)

  }

  load() {
    if (this.question.defaultValue) {
      this.questionUuids = JSON.parse(this.question.defaultValue)
    }
    const index = this.$store.state.rgs.findIndex((saved: ResponseGroup) => {
      return saved.surveySlug === this.question.surveySlug
    })
    if (index !== -1) {
      this.rg = this.$store.state.rgs[index]

      const surveyIndex = this.$store.state.surveys.findIndex((saved: Survey) => {
        return saved.uuid === this.rg.survey.uuid
      })
      if (surveyIndex !== -1) {
        this.survey = new Survey(this.$store.state.surveys[surveyIndex])
      }
    }
    this.allQuestions = this.survey.getQuestions()
    this.extractFromRg(this.rg)
  }

  reset() {
  }

  deleteSubResponseGroup() {
  }

  extractFromRg(rg: ResponseGroup, ariane: any[] = []) {
    rg.responses.forEach((r: Response) => {
      const question = this.allQuestions.find((q: Question) => q.uuid === r.questionUuid)
      if (question) {
        if (question.type.code.match(/OBJECT/) && question.survey) {
          r.responseGroups.forEach((data: ResponseGroup) => {
            const srg = this.$store.state.rgs.find((saved: ResponseGroup) => {
              return saved.uuid === data.uuid
            })
            if (srg) {
              const ar = JSON.parse(JSON.stringify(ariane))
              this.extractFromRg(srg, ar)
            } else {
              console.log('NON TROUVE ' + data.uuid)
            }
          })

        }

        if (this.questionUuids.includes(r.questionUuid)) {

          const t = new Question(JSON.parse(JSON.stringify(question)))
          // console.log('create question ' + t.label)
          r.responseGroup = rg
          t.response = r
          const index = this.questions.findIndex((q: Question) => {
            return q.uuid === t.uuid && r.uuid === q.response.uuid && rg.uuid === r.responseGroupUuid
          })
          if (index === -1) {
            ariane.push(rg.alias)
            // console.log(r.questionLabel)
            t.ariane = ariane
            // console.log(ariane)
            // console.log(r)
            t.validate()
            this.questions.push(t)
          }
        }
      } else {
        console.log('question ' + r.questionLabel + ' not found')
      }
    })
  }
}
</script>
<style scoped>
</style>
