<template>
    <div class="QuestionTypeSelector">

        <NumberQuestion :question="question" v-if="question.type.code === 'NUMBER'"></NumberQuestion>
        <TextType :question="question" v-else-if="question.type.code === 'TEXT'"></TextType>
        <QuestionList :question="question" v-else-if="question.type.code === 'QUESTION_LIST'"></QuestionList>
        <Contact :question="question" v-else-if="question.type.code === 'CONTACT'"></Contact>
        <Radio :question="question" v-else-if="question.type.code === 'RADIO'"></Radio>
        <Mail :question="question" v-else-if="question.type.code === 'MAIL'"></Mail>
        <Date :question="question" v-else-if="question.type.code === 'DATE'"></Date>

        <FileQuestion :question="question"
                      v-else-if="question.type.code === 'FILE_UPLOAD'"></FileQuestion>
        <SurveyType :melted="true" :question="question"
                    v-else-if="question.type.code === 'SURVEY'"
                    v-on:reset="reset"></SurveyType>
        <MultipleSurvey :creatingResponseGroup="creatingResponseGroup"
                        :melted="true" :question="question"
                        v-else-if="question.type.code === 'MULTIPLE_SURVEY'"

                        v-on:deleteSubResponseGroup="deleteSubResponseGroup"
                        v-on:reset="reset"></MultipleSurvey>
        <PackCategory :question="question"
                      v-else-if="question.type.code === 'PACK_CATEGORY'"></PackCategory>


        <div class="d-flex align-items-center m-2" v-else>
            <span class="badge badge-danger">
                {{question.type.code}} NOT HANDLED
            </span>
        </div>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Radio from "@/views/Income/Comparator/FormComponents/Radio.vue";
    import MultipleSurvey from "@/views/Income/Comparator/FormComponents/MultipleSurvey.vue";
    import NumberQuestion from "@/views/Income/Comparator/FormComponents/NumberQuestion.vue";
    import SurveyType from "@/views/Income/Comparator/FormComponents/SurveyType.vue";
    import FileQuestion from "@/views/Income/Comparator/FormComponents/FileQuestion.vue";
    import Mail from "@/views/Income/Comparator/FormComponents/Mail.vue";
    import TextType from "@/views/Income/Comparator/FormComponents/TextType.vue";
    import QuestionList from "@/views/Income/Comparator/FormComponents/QuestionList.vue";
    import Contact from "@/views/Income/Comparator/FormComponents/Contact.vue";
    import Date from "@/views/Income/Comparator/FormComponents/Date.vue";
    import PackCategory from "@/views/Income/Comparator/FormComponents/PackCategory.vue";
    import Question from "@/entity/Question";

    @Component({
        components: {PackCategory, MultipleSurvey, SurveyType, FileQuestion, Date, Mail, Radio, Contact, QuestionList, TextType, NumberQuestion},
    })
    export default class QuestionTypeSelector extends Vue {
        @Prop() creatingResponseGroup!: boolean
        @Prop() question!: Question


        reset() {
            this.$emit('reset')
        }

        deleteSubResponseGroup(data: any) {
            this.$emit('deleteSubResponseGroup', data)
        }
    }
</script>
<style scoped>
</style>
