<template>
    <Base>


    <template v-slot:toolbar>
        <div class="toolbar" id="kt_toolbar">
            <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
                <div class="d-flex align-items-center me-3">
                    <slot name="leftToolbar">
                        <span class="h-20px border-gray-200 border-start mx-4"></span>
                        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                            <li class="breadcrumb-item text-muted">
                                <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                                    <i class="fad fa-home"></i>
                                    {{ trans('Cotations') }}
                                </router-link>
                            </li>
                        </ul>
                    </slot>
                </div>


                <div class="d-flex align-items-center py-1">
                    <router-link target="_blank" :to="{name : 'comparateur-index'}" class="btn btn-sm btn-clean btn-dropdown btn-primary">
                                <i class="fad fa-file-signature fs-2"></i>
                                {{ trans('Effectuer une cotation') }}
                            </router-link>
                </div>

            </div>
        </div>
    </template>


    <template v-slot:content>
        <div class=" m-5">
            <div class="btn-group my-5" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-sm btn-icon btn-secondary">
                    <i class="fad fa-abacus"></i>
                </button>
                <button type="button" class="btn btn-sm btn-icon btn-secondary">
                    <i class="fad fa-th-list"></i>
                </button>
                <button type="button" class="btn btn-sm btn-icon btn-secondary">
                    <i class="fad fa-coins"></i>
                </button>
            </div>
            <CotationSearch></CotationSearch>
        </div>
    </template>
    </Base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Survey from "@/entity/Survey";
import Branch from "@/entity/Branch";
import BranchCategory from "@/entity/BranchCategory";
import { api } from "@/services/Api";
import SavedResponseGroup from "@/views/Income/Comparator/SavedResponseGroup.vue";
import InputNumber from "@/components/InputNumber.vue";
import ResponseGroup from "@/entity/ResponseGroup";
import Popup from "@/entity/Popup";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import CONSTANTS from "@/Utils/constant";
import CotationSearch from "@/views/Income/CotationSearch.vue";
import Loading from "@/components/Loading.vue";

@Component({
    components: { Loading, CotationSearch, VueMultiselect, InputNumber, SavedResponseGroup, Base }
})
export default class CotationList extends Vue {
    [x: string]: any;

    categories: BranchCategory[] = [];
    currentCategory: any = null;

    isLoading = false

    mounted() {
        this.load()
    }

    async load() {
        this.isLoading = true
        this.$store.commit('loading');
        this.categories = await this.$store.getters.branchCategories()
        if (this.currentCategory === null && this.categories.length > 0) {
            this.currentCategory = this.categories[0].uuid
        }
        this.$store.commit('stopLoading')
        this.isLoading = false
    }
}
</script>
<style scoped>
.branch-button {
    position: relative;
}

.branch-button:hover:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    opacity: 0.1;
    background: var(--bg-hover);
}

.branch-button:hover * {
    color: var(--bg-hover) !important
}
</style>

<style>
.dropdown-menu {
    padding: 0 !important;
}
</style>
