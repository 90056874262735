/* eslint-disabled */

export default class TodoExtend {

    updating = false

    constructor(object?: any) {
    }

    postConstruct() {
    }
}
