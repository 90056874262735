<template>
  <div class="page comparator-home d-flex flex-column flex-grow-1">
    <template v-if="!melted">
      <ComparatorHeader/>
      <div class="w-100 bg-success d-flex justify-content-center fs-2x text-white py-10 fw-bolder"
           v-if="rg && rg.status === 'VALIDATED'">
        Cette cotation a déjà été payée
      </div>
      <div v-if="notFound" class="d-flex flex-column justify-content-center align-items-center">
        <video src="../../../assets/media/videos/phone.mp4" autoplay="autoplay" muted="muted" loop="loop" playsinline
               type="video/mp4" style="width:300px"></video>

        <h1 class="mt-10">
          Désolé cette cotation n'existe plus chez nous
        </h1>
        <p class="text-center fs-2">
          Elle a peut-être déjà été soldée ou annulée
          <br>
          Contactez notre service client pour plus d'informations
        </p>
        <div class="d-flex mt-5 align-items-center">
          <router-link :to="{ name: 'comparateur-index' }" class="btn btn-primary ms-5">
            <i class="fad fa-file-contract"></i>
            Souscrire à un nouveau contrat
          </router-link>
        </div>
      </div>
    </template>

    <template v-if="!notFound && rg && form">
      <div class="row px-2 py-5 mx-auto w-100" v-if="!melted">
        <transition appear name="slide-left">
          <h1 class="text-green fw-bolder" style="font-size: 2em" v-if="form">{{ trans('Ma cotation') }}
            {{ form.label }} </h1>
        </transition>
      </div>

      <!--        <input class="form-control" style="width: 300px" type="number" v-model="progress">-->
      <div class="row flex-column mx-auto flex-grow-1 w-100 d-flex align-items-start">
        <div class="d-flex justify-content-center position-relative mt-2" v-if="!melted">
          <span :key="'progress_' + progress" class="progress-percent me-2 fw-bolder fs-2">{{
              progress
            }}%</span>
          <transition appear name="max-width">
            <div class="progress position-relative w-100 rounded" ref="progress">
              <div :key="'progress_in_' + progress" :style="{ width: progress + '%' }"
                   class="rounded determinate"></div>
            </div>
          </transition>
        </div>

        <template v-if="isManager()">
          <template v-for="step, stepIndex in form.steps">
            <div :key="'section_' + section.uuid" class="my-3 px-0" v-for="section in step.sections">
              <template v-if="section.isVisible()">
                <h2 class="my-5" v-if="!helper.empty(section.label)">
                  {{ section.label }}
                </h2>

                <div :class="{ 'bg-transparent p-0 m-0': isSectionContainSurvey(section) }" class="">
                  <div :class="{ 'p-0': isSectionContainSurvey(section) }" class="p-0">

                    <div class="d-flex flex-wrap justify-content-start">
                      <template v-for="question, index in section.questions">

                        <template v-if="question.isVisible()">
                          <transition-group appear class="flex-grow-1 question mw-100" name="slide-up" tag="div"
                                            :style="{ display: !question.isAuthorized() || question.hidden ? 'none' : 'flex' }">
                            <question-manager :key="'question_' + question.uuid" :currentStep="step"
                                              :question="question"
                                              :style="{ '--i': index + 1 }"
                                              :creatingResponseGroup="creatingResponseGroup"
                                              v-on:deleteSubResponseGroup="deleteSubResponseGroup" :reset="reset"
                                              :branch="branch"
                                              :rg="rg" @nextStep="nextStep" :form="form"></question-manager>
                          </transition-group>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </div>

          </template>
        </template>
        <template v-else-if="form && currentStepIndex < form.steps.length">
          <template v-for="step, stepIndex in form.steps">
            <template v-if="(currentStep && step.uuid === currentStep.uuid)">
              <div>
                <transition :name="animation" appear mode="out-in">
                  <div :key="'step_title_' + step.uuid" class="fs-2 fw-bolder text-green pt-10 px-5"
                       v-if="!helper.empty(step.label)">
                    <!--                                {{step.id}}-->
                    {{ step.label }}
                  </div>
                </transition>
                <template v-if="!helper.empty(step.description)">
                  <transition :name="animation" appear mode="out-in">
                    <div :key="'step_description_' + step.uuid" class="text-gray-400 fs-4 mw-700px  px-5"
                         style="transition-delay: .2s;text-align: justify">
                      {{ step.description }}
                    </div>
                  </transition>
                </template>
              </div>
              <div :key="'section_' + section.uuid" class="w-100 section mt-5" v-for="section in currentStep.sections">
                <template v-if="section.isVisible()">
                  <h2 class="my-5" v-if="!helper.empty(section.label)">
                    {{ section.label }}
                  </h2>

                  <div :class="{ 'bg-transparent p-0 m-0': isSectionContainSurvey(section) }"
                       class="card question-container-card w-100">
                    <div :class="{ 'p-0': isSectionContainSurvey(section) }" class="card-body p-0 w-100">

                      <div class="d-flex flex-wrap justify-content-start w-100">
                        <template v-for="question, index in section.questions">
                          <template v-if="question.isVisible()">
                            <transition-group appear class="flex-grow-1 question mw-100" name="slide-up" tag="div"
                                              :style="{ display: !question.isAuthorized() || question.hidden ? 'none' : 'flex' }">
                              <question-manager :key="'question_' + question.uuid" :currentStep="currentStep"
                                                :question="question" :style="{ '--i': (index < 5) ? (index+1) : 5 }"
                                                :creatingResponseGroup="creatingResponseGroup" :reset="reset"
                                                :branch="branch" :rg="rg"
                                                @nextStep="nextStep" :form="form"></question-manager>
                            </transition-group>
                          </template>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </template>
      </div>


      <!-- <div class="h-100px"></div> -->
      <!-- <div class="row mx-auto w-100" style="position: fixed;bottom: 0px;left: 50%;background: white;transform: translateX(-50%);"> -->
      <div class="row mx-auto w-100">
        <div class="d-flex justify-content-between my-10">
          <div>
            <button @click="previousStep" class="btn btn-secondary" v-if="showPreviousStep">
              <i class="fad fa-arrow-left"></i>
              {{ trans('Etape précédente') }}
            </button>
          </div>

          <div>
            <button :disabled="!currentStep.isValid" @click="nextStep" class="btn btn-warning" v-if="showNextStep">
              {{ trans('Etape suivante') }}
              <i class="fad fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>

    </template>

  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ComparatorHeader from "@/views/Comparator/Components/ComparatorHeader.vue";
import ResponseGroup from "@/entity/ResponseGroup";
import Survey from "@/entity/Survey";
import {api} from "@/services/Api";
import {helper} from "@/services/Helper";
import Popup from "@/entity/Popup";
import Step from "@/entity/Step";
import Section from "@/entity/Section";
import Question from "@/entity/Question";
import Response from "@/entity/Response";
import NumberQuestion from "@/views/Income/Comparator/FormComponents/NumberQuestion.vue";
import Money from "@/views/Income/Comparator/FormComponents/Money.vue";
import CarBrand from "@/views/Income/Comparator/FormComponents/CarBrand.vue";
import CarModel from "@/views/Income/Comparator/FormComponents/CarModel.vue";
import TextType from "@/views/Income/Comparator/FormComponents/TextType.vue";
import Checkbox from "@/views/Income/Comparator/FormComponents/Checkbox.vue";
import QuestionList from "@/views/Income/Comparator/FormComponents/QuestionList.vue";
import Contact from "@/views/Income/Comparator/FormComponents/Contact.vue";
import Radio from "@/views/Income/Comparator/FormComponents/Radio.vue";
import Mail from "@/views/Income/Comparator/FormComponents/Mail.vue";
import Date from "@/views/Income/Comparator/FormComponents/Date.vue";
import FileQuestion from "@/views/Income/Comparator/FormComponents/FileQuestion.vue";
import SurveyType from "@/views/Income/Comparator/FormComponents/SurveyType.vue";
import MultipleSurvey from "@/views/Income/Comparator/FormComponents/MultipleSurvey.vue";
import PackCategory from "@/views/Income/Comparator/FormComponents/PackCategory.vue";
import {auth} from "@/services/Auth";
import Branch from "@/entity/Branch";
import Comparison from "@/views/Income/Comparator/FormComponents/Comparison.vue";
import Insurer from "@/entity/Insurer";
import QuestionManager from '@/views/Comparator/Components/QuestionManager.vue';

@Component({
  name: 'ResponseGroupManager',
  components: {
    QuestionManager,
    Comparison,
    PackCategory,
    MultipleSurvey,
    SurveyType,
    FileQuestion,
    Date,
    Mail,
    Radio,
    Contact,
    QuestionList,
    Checkbox,
    TextType,
    CarModel,
    CarBrand,
    Money,
    NumberQuestion,
    ComparatorHeader
  }
})
export default class ResponseGroupManager extends Vue {
  [x: string]: any;

  loading = false
  @Prop() uuid?: string
  @Prop({default: false}) melted!: boolean
  rgUuid: any = null
  rg: ResponseGroup | any = null
  branch: Branch | any = null
  progress = 0
  form: Survey | any = null
  requireAuth = false
  notFound = false
  potentialOwners: any[] = []
  ownerEmail = ''
  currentStep: Step | any = null
  currentStepIndex = 0
  isStepValid = false

  animation = 'slide-left'

  toggle(text: string) {
    console.log(this.question.response.value)
  }

  isManager() {
    return auth.hasRole('ROLE_MANAGER') || auth.hasRole('ROLE_PARTNER')
  }

  isPartner() {
    return auth.hasRole('ROLE_PARTNER')
  }


  @Watch('$store.state.responseDeletions', {immediate: true})
  removeResponse() {
    let hasDeleted = false
    const deletions = JSON.parse(JSON.stringify(this.$store.state.responseDeletions))
    if (this.rg) {
      let i = 0;
      for (const uuid of deletions) {
        this.rg.responses.forEach((r: Response, index: number) => {
          if (r.uuid === uuid) {
            console.log('remove response ' + r.value)
            this.rg.responses.splice(index, 1)
            deletions.splice(i, 1)
            hasDeleted = true;
          }
        })
        i++
      }
    }
    if (hasDeleted) {
      this.$store.state.responseDeletions = deletions
      this.setRgResponses()
    }
  }

  mounted(): void {
    // console.log('------------------ mount rgm  | ' + this.uuid + ' | ' + this.$route.params.rgUuid)
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = (e: any) => {
      // console.log(e)
      // console.log('popstate')
      window.history.pushState(null, "", window.location.href);
      this.previousStep()
    };
    // document.addEventListener("backbutton", (e) => {
    //     console.log('go back')
    // });

    if (!helper.empty(this.uuid)) {
      this.rgUuid = this.uuid
    } else if (!helper.empty(this.$route.params.rgUuid)) {
      this.rgUuid = this.$route.params.rgUuid
    }
    if (helper.empty(this.rgUuid)) {
      const pop = new Popup('Erreur', 'Code cotation introuvable');
      this.$router.push({name: 'comparateur-index'})
      return
    }
    if (this.rgUuid === 'new') {
      this.createResponseGroup(this.$route.params.surveySlug)
      return
    }
    this.loadData()
  }

  async createResponseGroup(surveySlug: string) {
    this.$store.commit('loading')
    // const partner = sessionStorage.getItem('partner')
    // let res = null
    // // console.log(partner)
    // if (partner) {
    //   res = await api.get(api.form, 'api/response/group/new/' + surveySlug + '/null/' + partner)
    // } else {
    //   res = await api.get(api.form, 'api/response/group/new/' + surveySlug)
    // }
    const source = this.$store.state.source
    const affiliation = this.$store.state.affiliation
    const parent = 'null'
    const res = await api.get(api.form, 'api/response/group/new/' + surveySlug + '/' + parent + '/' + source + '/' + affiliation)
    if (res && res.data && res.data.responseGroup) {
      const rg = new ResponseGroup(res.data.responseGroup)
      const cotation = {
        uuid: rg.uuid,
        state: 0,
        createdAt: rg.createdAt,
        lastUpdate: rg.updatedAt,
        branch: rg.branchSlug,
        survey: rg.surveySlug
      }
      const cotations = localStorage.getItem('cotations') ? JSON.parse(localStorage.getItem('cotations')!) : []
      cotations.push(cotation)
      localStorage.setItem('cotations', JSON.stringify(cotations))
      // console.log(cotations)
      // return
      this.$router.push({
        name: 'response-group-manager',
        params: {
          surveySlug: surveySlug,
          rgUuid: res.data.responseGroup.uuid
        }
      })
      window.location.reload()
    }
    this.$store.commit('stopLoading')
  }

  reset() {

  }

  getStepIndex(step: Step) {
    return this.form.steps.findIndex((s: Step) => s.uuid === step.uuid)
  }

  goToStep(step: Step) {
    if (auth.hasRole('ROLE_MANAGER')) {
      this.currentStepIndex = this.form.steps.findIndex((s: Step) => s.uuid === step.uuid)
    }
  }

  get title() {
    // console.log(this.form)
    return 'Comparateur'
  }


  get showPreviousStep() {
    if (this.form.steps.length > 0 && this.currentStepIndex > 0) {
      return true
    }
    return false
  }

  get showNextStep() {
    if (!this.currentStep) {
      // console.log('no current step')
      return false
    }
    if (!this.form) {
      // console.log('no form')
      return false
    }
    if (this.form.steps.length === 1 && this.melted) {
      // console.log('melted and no steps')
      return false
    }
    if (this.currentStepIndex === (this.form.steps.length - 1)) {
      // console.log('is last step')
      return false
    }
    // if(this.form.steps.slice(-1).pop().uuid === this.currentStep.uuid){
    //     return false
    // }
    // if (this.form && this.form.steps.length > 0 && this.form.steps[this.form.steps.length - 2].uuid !== this.currentStep.uuid) {
    //     return false
    // }
    return true
  }

  async previousStep() {
    if (this.form.steps.length > 0) {
      this.animation = 'slide-right'
      if (this.currentStepIndex > 0) {
        this.currentStepIndex--
        this.currentStep = this.form.steps[this.currentStepIndex]
        if (!this.currentStep!.isVisible()) {
          this.previousStep()
        }
      }
    }
    this.updateStepRank(this.currentStep.uuid)
    await this.updateState()
  }

  async nextStep() {
    const cs: Step = this.currentStep as any
    cs.validate()
    let nextIndex = this.currentStepIndex + 1
    if (cs.isValid) {
      this.animation = 'slide-left'
      if (this.form.steps.length > nextIndex) {
        this.currentStepIndex = nextIndex
        this.currentStep = this.form.steps[nextIndex]
        if (!this.currentStep.isVisible()) {
          this.nextStep()
        } else {
          this.currentStep.validate()
        }
      }
    }
    this.updateStepRank(this.currentStep.uuid)
    await this.updateState()
  }

  async updateStepRank(uuid: string) {
    // if (!api.sendingResponses) {
    const res = await api.get(api.form, 'api/update/current/rank/' + this.rg.uuid + '/' + uuid)
    // }
  }

  async lastStep() {
    let found = false

    for (let [index, step] of this.form.steps.entries()) {
      step.validate()
      // console.log('check '+step.ranking)
      if (!step.isValid || step.ranking === this.rg.currentStepRank) {
        // console.log('match '+step.ranking)
        found = true
        this.currentStepIndex = index
        this.currentStep = this.form.steps[this.currentStepIndex]
        return
      }
    }
    this.currentStepIndex = 0
    this.currentStep = this.form.steps[0]
  }

  isSectionContainSurvey(section: Section) {
    let found = false;
    section.questions.forEach((q: Question) => {
      if (q.type.code.match(/OBJECT|OBJECT_GROUP/)) {
        found = true;
        return found;
      }
    })
    return found;
  }

  async loadData() {
    // console.log('loading data for rg ' + this.rgUuid)
    this.$store.commit('loading')
    const index = this.$store.state.rgs.findIndex((saved: ResponseGroup) => {
      return saved.uuid === this.rgUuid
    })
    if (index !== -1) {
      this.rg = this.$store.state.rgs[index]
    }
    if (this.rg) {
      const surveyIndex = this.$store.state.surveys.findIndex((saved: Survey) => {
        return saved.uuid === this.rg.survey.uuid
      })
      if (surveyIndex !== -1) {
        this.form = new Survey(this.$store.state.surveys[surveyIndex])
      }
    }
    if (this.$store.state.currentBranch) {
      this.branch = this.$store.state.currentBranch
    }
    // if (this.rg && this.form) {
    //   console.log('=> loading  rg for survey ' + this.form.label)
    //   console.log(this.rg)
    // }

    if (!this.rg || !this.form) {
      this.requireAuth = false
      this.notFound = false
      const res = await api.get(api.form, 'api/get/data/' + this.rgUuid)
      if (res && res.data && res.data.responseGroup) {
        this.branch = new Branch(res.data.data.branch)
        await this.loadEngine()
        this.rg = new ResponseGroup(res.data.responseGroup)
        const formModel = res.data.data.survey
        this.$store.commit('appendSurvey', formModel)
        const insurers: any = []
        // console.log(res.data.data.insurers)
        res.data.data.insurers.forEach((i: Insurer) => {
          // console.log(i)
          const occ = new Insurer(i)
          insurers.push(occ)
        })
        this.$store.state.insurers = insurers
        this.form = new Survey(formModel)
        this.extractSurveys(this.form)
        if (res.data.potentialOwners) {
          this.potentialOwners = res.data.potentialOwners
        }
      }
      if (res && res.data && res.data.status === 'auth_required') {
        this.requireAuth = true
        this.ownerEmail = res.data.email
      }
      if (res && res.data && res.data.status === 'not_found') {
        this.notFound = true
        this.$store.commit('stopLoading')
        return
      }
    } else {
      await this.loadEngine()
    }


    if (!this.melted) {
      this.$store.state.currentResponseGroup = this.rg
      this.$store.state.currentSurvey = this.form
      this.$store.state.currentBranch = this.branch
    }
    // return
    this.setRgResponses()
    this.extractRgs(this.rg)
    this.$store.commit('appendRg', this.rg)
    this.$store.commit('stopLoading')


    if (this.form.steps.length > 0) {
      this.lastStep()
    }
    // this.currentStep.validate()

    await this.updateState()
    // this.lastStep()
  }

  updated(): void {
    // console.log(this.step)
    // console.log(this.currentStep)

  }

  async loadEngine() {
    await api.loadEngine(this.branch.slug)
    // this.$store.commit('responseSaved')
    // this.compare()
    // this.$store.state.engineClasses = window['classes' as any]
  }


  beforeUnmount() {
    window['classes' as any] = null as any
    this.$store.state.engineClasses = []
  }

  executeIfFileExist(src: any, callback: any, reject: any = null) {
    var xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function () {
      const isReady = this.readyState === this.DONE
      console.log('is Ready ' + isReady)
      if (this.status === 0 && reject) {
        reject()
      }
      if (this.readyState === this.DONE) {
        callback()
      }
    }
    xhr.open('GET', src)
  }

  creatingResponseGroup = false

  async deleteSubResponseGroup(payload: any) {
    if (payload.rg) {
      this.creatingResponseGroup = true
      const pop = new Popup('Chargement', 'Suppression en cours', 'warning', 'fad fa-cogs', true)
      const res = await api.get(api.form, 'api/delete/sub/response/group/' + payload.rg.uuid + '/' + payload.question.response.uuid)
      this.creatingResponseGroup = false
      if (res && res.data && res.data.status === 'success') {
        const index = payload.question.response.responseGroups.findIndex((rg: ResponseGroup) => {
          return rg.uuid === payload.rg.uuid
        })
        if (index !== -1) {
          payload.question.response.responseGroups.splice(index, 1)
        }
        this.$store.commit('deleteSubResponseGroup', payload.rg.uuid)
        payload.question.updateAnswer();
        this.$store.commit('responseSaved')
      }
      pop.hide()
    } else {
      this.deleteSubResponseGroups(payload)
    }
  }

  async deleteSubResponseGroups(payload: any) {

    const data = {
      questionUuid: payload.question.uuid,
      rgUuids: [] as string[]
    }
    payload.rgs.forEach((rg: ResponseGroup) => {
      data.rgUuids.push(rg.uuid)
    })
    this.$store.commit('loading')
    const pop = new Popup('Chargement', 'Suppression en cours', 'warning', 'fad fa-cogs', true)
    const res = await api.post(api.form, 'api/delete/sub/response/groups', data)
    this.$store.commit('stopLoading')
    if (res && res.data && res.data.status === 'success') {
      res.data.deleted.forEach((d: any) => {
        const index = payload.question.response.responseGroups.findIndex((rg: ResponseGroup) => {
          return rg.uuid === d
        })
        if (index !== -1) {
          payload.question.response.responseGroups.splice(index, 1)
        }
        this.$store.commit('deleteSubResponseGroup', d)
        payload.question.updateAnswer();
      })
      this.$store.commit('responseSaved')
    }
    pop.hide()
  }

  setRgResponses() {
    if (this.form && this.rg) {
      if (this.form.steps.length > 0) {
        this.form.steps.forEach((s: Step) => {
          s.sections.forEach((sec: Section) => {
            sec.questions.forEach((q: Question) => {
              // console.log('set response for question ' + q.label)
              let res: Response | null = this.rg.getResponseForQuestion(q)
              // console.log(res)
              let exist = true
              if (!res) {
                // console.log('create new res')
                exist = false
                res = new Response()
                res.question = q
                q.response = res
                this.rg.responses.push(res)
                // res.responseGroup = this.rg
              }
              this.$store.commit('appendResponse', res);
              res.question = q
              res.questionUuid = q.uuid!
              res.responseGroup = this.rg
              res.responseGroupUuid = this.rg.uuid
              if ((q.uuid === res.questionUuid || (res.question && q.uuid === res.question!.uuid))
                  && res.responseGroupUuid === this.rg.uuid) {
                // console.log('update response')
                q.response = res
                if (q.type.code.includes('OBJECT')) {
                  this.$store.commit('appendSurvey', q.survey);
                }
                q.validate()
              }
              q.response.responseGroupUuid = this.rg.uuid
              // console.log(q.label)
              // console.log('===> ' + q.response.value)
              // console.log(q.response.responseGroups)
              // let match = false
              // for (let response of this.rg) {
              //   if (response.question && response.questionUuid === q.uuid) {
              //     response = q.response
              //     match = true;
              //
              //   }
              // }
              // console.log(q)
              // this.rg.responses.push(q.response)

              // let match = false
              // this.rg.responses.forEach((r: Response, index: string) => {
              //   if (r.question!.uuid === q.uuid) {
              //     match = true
              //     console.log('resposne already exist')
              //     this.rg.responses[index] = q.response
              //   }
              // })
              // if(!match){
              // this.rg.responses.push(q.response)
              // }

              // q.setDefaultValue()
            })
          })
        })
      }
    }

    // this.$forceUpdate()
  }


  @Watch('$store.state.rgStatus')
  async handleResponse() {
    this.updateState()
    this.isCurrentStepFinished()
  }

  @Watch('$store.state.stepStatus')
  async checkStepStatus() {
    this.currentStep.validate()
    // console.log('step validation '+this.currentStep.isValid)
  }

  async updateState() {
    if (this.rg && this.form) {
      const details = this.rg.getStateDetails(null, this.currentStepIndex)
      const index = this.form.slug + '_' + this.rg.uuid;
      let state = details[index]['percent']
      let step = 1
      if (this.progress > state) {
        step = -1
      }
      let max = 0
      let min = 0
      let count = 0
      this.progress = state
      // while (this.progress !== state && max < 100) {
      //     this.progress += step
      //     max++
      //     await this.$store.state.sleep(30)
      // }
      // console.log(this.progress)
      // console.log(max)

    }
  }

  isCurrentStepFinished() {
    const currentStep: Step = this.currentStep
    if (!currentStep) {
      return
    }
    currentStep.validate()
    if (currentStep.isValid) {
      // this.nextStep()
    }
    // this.$forceUpdate()

  }

  extractRgs(rg: ResponseGroup) {
    rg.responses.forEach((res: Response) => {
      res.responseGroups.forEach((r: ResponseGroup) => {
        const rgOcc = new ResponseGroup(r)
        this.$store.commit('appendRg', rgOcc)
        this.extractRgs(rgOcc)
      })
    })
  }

  extractSurveys(survey: Survey) {
    this.$store.commit('appendSurvey', survey)
    survey.steps.forEach((step: Step) => {
      step.sections.forEach((section: Section) => {
        section.questions.forEach((question: Question) => {
          if (question.survey) {
            this.extractSurveys(question.survey)
          }
        })
      })
    })
    // rg.responses.forEach((res: Response) => {
    //   res.responseGroups.forEach((r: ResponseGroup) => {
    //     const rgOcc = new ResponseGroup(r)
    //     this.$store.commit('appendRg', rgOcc)
    //     this.extractRgs(rgOcc)
    //   })
    // })
  }
}
</script>

<style scoped>
.page {
  max-width: 100%;
}

.row {
  max-width: 1200px;
}

/*@media only screen and (max-width: 992px) {*/
/*    .wizard {*/
/*        display: none;*/
/*    }*/

/*    .question-container-card {*/
/*        width: 100%*/
/*    }*/

/*}*/
.text-green {
  color: #3ddad7;
}

.progress {
  overflow: visible;

}

.progress-percent {
  bottom: 5px;
  position: absolute;
  right: 0;
}

/deep/ input:not[type="checkbox"] {
  padding: .8rem 2rem;
}

/deep/ select {
  padding: .8rem 1rem;
}

.progress {
  height: 4px;
  /*margin-bottom: 2rem;*/
  background-color: #DCE7E8;
}

.progress .determinate {
  background-color: #3DDAD7;
  transition: all .5s ease;
}

.wizard-step.active,
.wizard-step.active i,
.wizard-step.active .wizard-title {
  color: #3DDAD7 !important;
}


/*///////////////////////////////////////////////*/
.max-width {
  transform-origin: left center;
}

.max-width-enter-active,
.max-width-leave-active {
  transition: all 1s;
  transform: scaleX(1);
}

.max-width-leave-from {
  transition: all 1s;
}

.max-width-enter {
  opacity: 0;
  transform: scaleX(0);
}

.max-width-leave-to {
  opacity: 0;
  transform: scaleX(1);
}

/*/////////////////////////////////////////////////////*/
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all calc(var(--i) * .2s);
}

.slide-up-leave-from,
.slide-up-leave-from {
  transition: all 0s;
}

.slide-up-enter {
  opacity: 0;
  transform: translateY(50%);
}

/*/////////////////////////////////////////////////////*/
.slide-up2-enter-active,
.slide-up2-leave-active {
  transition: all calc(var(--i) * .2s);
}

.slide-up2-leave-from,
.slide-up2-leave-from {
  transition: all calc(var(--i) * .2s);
}

.slide-up2-enter {
  opacity: 0;
  transform: translateY(100%);
}

@media only screen and (max-width: 992px) {
  .wizard {
    display: none;
  }

  .question-container-card {
    width: 100%
  }

}
</style>
