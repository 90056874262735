/* eslint-disabled */
import ResponseGroup from "./ResponseGroup";
import Contact from "./Contact";
import Device from "./Device";
import Note from "./Note";
import ProspectExtend from "@/entity/extends/ProspectExtend";

export default class Prospect extends ProspectExtend {

public id!: number;
public email? = '';
public code = '';
public responseGroups: Array<ResponseGroup> = [];
public salt = '';
public devices: Array<Device> = [];
public name? = '';
public prename? = '';
public manager? = '';
public mobile = '';
public fixe = '';
public notes: Array<Note> = [];
public uuid = '';
public createdAt?: Date;
public updatedAt?: Date;
public createBy? = '';
public updateBy? = '';
public removeBy? = '';
public createdFromIp? = '';
public updatedFromIp? = '';
public photo? = '';
public alt? = '';

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.email= object.email;
       this.code= object.code;
       if(object.responseGroups){
           object.responseGroups.forEach((occ: any)=>{
               const responsegroup= new ResponseGroup(occ);
               this.responseGroups.push(responsegroup);
           });
       }
       this.mobile = object.mobile
       this.fixe = object.fixe
       this.salt= object.salt;
       if(object.devices){
           object.devices.forEach((occ: any)=>{
               const device= new Device(occ);
               this.devices.push(device);
           });
       }
       this.name= object.name;
       this.prename= object.prename;
       this.manager= object.manager;
       if(object.notes){
           object.notes.forEach((occ: any)=>{
               const note= new Note(occ);
               this.notes.push(note);
           });
       }
       this.uuid= object.uuid;
       if(object.createdAt){
           this.createdAt= new Date(object.createdAt);
       }
       if(object.updatedAt){
           this.updatedAt= new Date(object.updatedAt);
       }
       this.createBy= object.createBy;
       this.updateBy= object.updateBy;
       this.removeBy= object.removeBy;
       this.createdFromIp= object.createdFromIp;
       this.updatedFromIp= object.updatedFromIp;
       this.photo= object.photo;
       this.alt= object.alt;
      }
      this.postConstruct()
  }

}
