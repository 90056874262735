<template>
    <div class="ItemTypeInspector">
        <label :class="{ 'text-primary' : !expand }"
               class="fw-bolder cursor-pointer text-hover-primary w-100 py-2" v-if="!parent">
            <input class="hidden" type="checkbox" v-model="expand">
            <i class="fad fa-angle-right" v-if="!expand"></i>
            <i class="fad fa-angle-down" v-else></i>
            {{type.name}}
        </label>
        <div class="d-flex flex-wrap ps-2">
            <template v-for="prop in type.properties" v-if="expand ||show">
                <div v-if="prop.type.code !== 'OBJECT'">
                    <div @dragstart="dragArgument(type,prop,$event)" class="m-1 cursor-pointer badge badge-primary " draggable="true">
                        {{prop.name}}
                    </div>
                </div>
                <template v-else>

                    <label @dragstart="dragArgument(type,prop,$event)"
                           class="fw-bolder cursor-pointer text-hover-primary w-100 py-2"
                           draggable="true">
                        <input class="hidden" type="checkbox" v-model="showSub">
                        <i class="fad fa-angle-right" v-if="!showSub"></i>
                        <i class="fad fa-angle-down" v-else></i>
                        {{prop.name}}
                    </label>
                    <div :set="subType = getItemType(prop.value)">
                        <ItemTypeInspector :parent="link" :prop="prop" :show="showSub" :type="subType"
                                           @dragArgument="emitArgument"></ItemTypeInspector>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import ItemType from "@/entity/ItemType";
    import ItemProperty from "@/entity/ItemProperty";

    @Component({})
    export default class ItemTypeInspector extends Vue {
        @Prop() type!: ItemType
        @Prop() prop?: ItemProperty
        expand = false
        @Prop() parent: any
        showSub = false
        @Prop() show?: boolean
        link: any[] = []

        mounted(): void {
            // console.log( this.type.name)
            // this.type.properties.forEach((p:ItemProperty)=>{
            //     console.log('==='+p.name)
            // })
            this.link = this.parent ? [...this.parent] : []
            let prop = {}
            if (this.prop) {
                prop = {code: this.prop.code, name: this.prop.name}
            }
            this.link.push({name: this.type.name, uuid: this.type.uuid, code: this.type.code, prop: prop})
            // console.log(this.type)
            this.type.properties.forEach((p: ItemProperty) => {
                if (p.type.code === 'OBJECT') {
                    // console.log(p)
                    this.getItemType(p.value)
                }
            })
        }

        emitArgument(evt: any) {
            this.$emit('dragArgument', evt)
        }

        dragArgument(type: ItemType, prop: ItemProperty, evt: any) {
            // console.log('dragging ' + this.type.name)
            // console.log(type)
            this.$emit('dragArgument', {type, prop, evt, source: [...this.link]})
        }

        getItemType(uuid: any) {
            const item = this.$store.state.itemTypes.find((i: ItemType) => {
                return i.uuid === uuid;
            })
            return item
        }
    }
</script>
<style scoped>
</style>
