<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Importation') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">

          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-content-end mb-10">
        <label class="btn btn-sm btn-primary align-items-center">
          <i class="fad fa-file-import"></i>
          <input type="file" @change="importFile" class="hidden"
                 accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
          <template v-if="file">
            {{ file.name }}
          </template>
          <template v-else>
            Importer
          </template>
        </label>
      </div>
      <!--        <textarea class="form-control" @paste="loadData"></textarea>-->
      <div class="fs-2 fw-bolder" v-if="file">
        Selectionnez une feuille
      </div>
      <div class="d-flex justify-content-start align-items-center">
        <div v-for="name, index of sheetNames" @click="selectSheet(index)"
             class="px-10 py-5 cursor-pointer rounded m-2 whitespace-no-wrap"
             :class="{ 'bg-white': sheetIndex !== index, 'bg-primary text-white fw-bolder': sheetIndex === index }">
          {{ name }}
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-10">
        <div class="d-flex align-items-center ">
          <div>
            Importer
          </div>
          <div class="mx-2">
            <input type="number" class="form-control w-80px text-end" v-model="count"
                   @change="selectSheet(sheetIndex)"/>
          </div>
          <div class="mx-2">
            lignes à partir de la ligne
          </div>
          <div class=" mx-2">
            <input type="number" min="0" class="form-control w-80px" v-model="firstLine"
                   @change="selectSheet(sheetIndex)"/>
          </div>
        </div>
        <div>
          <div class="btn btn-primary" v-if="rowsDemo.length > 0" @click="step= 'mapping'">
            Convertir en cotation
          </div>
        </div>
      </div>
      <div class="row mt-10" v-if="step === 'import'">
        <div class="card overflow-auto w-100">
          <div class="card-body">
            <table>
              <tr v-for="row, index in rowsDemo" class="bg-hover-light-dark">
                <td>
                  {{ parseInt(firstLine) + index + 1 }}
                </td>
                <td v-for="column in row" nowrap class="cursor-pointer bg-hover-light-primary py-2 px-5">
                  {{ column }}
                </td>^
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-10" v-if="step==='mapping'"></div>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import readXlsxFile, {readSheetNames} from 'read-excel-file'
import ItemType from '@/entity/ItemType';

@Component({
  components: {Base}
})
export default class IncomeImport extends Vue {
  file: any = null
  sheetNames = []
  sheetIndex: any = null
  firstLine = 0
  step = 'import'
  count = 20

  add(row: any) {

  }

  remove(row: any) {

  }

  loadData(event: any) {
    let data = event.clipboardData.getData('Text')
    data = data.split('	')
    console.log(data)
  }

  importFile(event: any) {
    this.file = event.target.files[0]
    // console.log(event.target.files[0])
    this.sheetNames = []
    readSheetNames(this.file).then((names: any) => {
      this.sheetNames = names
      if (names.length === 1) {
        this.selectSheet(0)
      }
    })
  }

  rowsDemo: any[] = []

  selectSheet(index: number) {
    // console.log(index)
    this.sheetIndex = index
    this.rowsDemo = []
    this.firstLine = parseInt(this.firstLine + '')
    this.count = parseInt(this.count + '')
    readXlsxFile(this.file, {sheet: (index + 1), dateFormat: 'mm/dd/yyyy'}).then((rows) => {
      // console.log(this.firstLine)
      // console.log(this.count)
      this.rowsDemo = rows.slice(this.firstLine, (this.firstLine + this.count))
      // console.log(this.rowsDemo)
      // `rows` is an array of rows
      // each row being an array of cells.
    })
  }

  itemTypes: ItemType[] = []

  async loadItems() {
    // const res = await api.get(api.core, 'item/type/list/' + this.branch.slug)
    // if (res && res.data && res.data.itemTypes) {
    //     this.itemTypes = []
    //     res.data.itemTypes.forEach((itemType: any) => {
    //         const item = new ItemType(itemType)
    //         this.itemTypes.push(item)
    //         if (item.isRoot) {
    //             this.root = item
    //         }
    //     })
    //     if (this.root) {
    //         this.tree = this.getItemTree(this.root)
    //         // console.log(this.tree)
    //     }
    // }
  }
}
</script>
<style scoped></style>
