import { render, staticRenderFns } from "./IncorporationDetail.vue?vue&type=template&id=251e1efb&scoped=true"
import script from "./IncorporationDetail.vue?vue&type=script&lang=ts"
export * from "./IncorporationDetail.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "251e1efb",
  null
  
)

export default component.exports