/* eslint-disabled */
import ResponseGroup from "./ResponseGroup";
import OrderExtend from "@/entity/extends/OrderExtend";

export default class Order extends OrderExtend {

    public id!: number;
    public responseGroup!: ResponseGroup;
    public entities = [];
    public premium!: number;
    public number = '';
    public details = [];
    public insurer = '';
    public startAt!: Date;
    public duration = [];
    public premiumDetails = [];
    public files = [];
    public town? = '';
    public address? = '';
    public contact? = '';
    public status = '';
    public uuid = '';
    public createdAt?: Date;
    public updatedAt?: Date;
    public createBy? = '';
    public updateBy? = '';
    public removeBy? = '';
    public createdFromIp? = '';
    public updatedFromIp? = '';
    public contractUuid? = '';
    public paymentMethod? = '';
    public reductions: any[] = []

    constructor(object?: any) {
        super(object)
        if (object) {
            this.id = object.id;
            this.responseGroup = (object.responseGroup instanceof ResponseGroup) ? object.responseGroup : new ResponseGroup(object.responseGroup);
            this.entities = object.entities;
            this.premium = object.premium;
            this.number = object.number;
            this.details = object.details;
            this.insurer = object.insurer;
            if (object.startAt) {
                this.startAt = new Date(object.startAt);
            }
            this.reductions = object.reductions
            this.contractUuid = object.contractUuid;
            this.duration = object.duration;
            this.premiumDetails = object.premiumDetails;
            this.files = object.files;
            this.town = object.town;
            this.address = object.address;
            this.contact = object.contact;
            this.status = object.status;
            this.uuid = object.uuid;
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            this.createBy = object.createBy;
            this.updateBy = object.updateBy;
            this.removeBy = object.removeBy;
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
            this.paymentMethod = object.paymentMethod;
        }
        this.postConstruct()
    }

}
