<template>
  <div class="d-flex position-relative align-items-center">
    <EngineArithmetiqueLineEditor :key="line.uuid" :level="level" :line="line" v-on:updateLineNumber="updateLineNumber"
                                  v-if="line.type === 'default'" v-on:addLineBefore="addLineBefore"
                                  v-on:removeLine="removeLine">
    </EngineArithmetiqueLineEditor>
    <EngineTextLineEditor :key="line.uuid" :level="level" :line="line" v-on:updateLineNumber="updateLineNumber"
                          v-if="line.type === 'text'" v-on:addLineBefore="addLineBefore" v-on:removeLine="removeLine">
    </EngineTextLineEditor>
    <EngineConditionLineEditor :key="line.uuid" :level="level" :line="line" v-else-if="line.type === 'condition'"
                               v-on:updateLineNumber="updateLineNumber" v-on:addLineBefore="addLineBefore"
                               v-on:addSubLine="addSubLine"
                               v-on:removeLine="removeLine"></EngineConditionLineEditor>
    <EngineForEachLineEditor :key="line.uuid" :level="level" :line="line" v-else-if="line.type === 'foreach'"
                             v-on:updateLineNumber="updateLineNumber" v-on:addLineBefore="addLineBefore"
                             v-on:removeLine="removeLine">
    </EngineForEachLineEditor>
    <EngineCallableLineEditor :key="line.uuid" :level="level" :line="line" v-else-if="line.type === 'callable'"
                              v-on:updateLineNumber="updateLineNumber" v-on:addLineBefore="addLineBefore"
                              v-on:removeLine="removeLine">
    </EngineCallableLineEditor>
  </div>
</template>
<script lang="ts">
import EngineMethodLine from '@/entity/EngineMethodLine';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {helper} from "@/services/Helper";
import EngineArithmetiqueLineEditor from "@/views/Engine/EngineArithmetiqueLineEditor.vue";
import EngineTextLineEditor from "@/views/Engine/EngineTextLineEditor.vue";
import EngineConditionLineEditor from "@/views/Engine/EngineConditionLineEditor.vue";
import EngineCallableLineEditor from "@/views/Engine/EngineCallableLineEditor.vue";
import EngineForEachLineEditor from "@/views/Engine/EngineForEachLineEditor.vue";
import EngineArgument from "@/entity/EngineArgument";

@Component({
  components: {
    EngineForEachLineEditor,
    EngineCallableLineEditor,
    EngineConditionLineEditor,
    EngineArithmetiqueLineEditor,
    EngineTextLineEditor
  }
})
export default class EngineLineEditor extends Vue {
  @Prop() line!: EngineMethodLine
  @Prop() level!: number

  mounted(): void {
    if (this.line.returnArgument) {
      // console.log(this.$store.state.returnArguments)
      if (this.line.returnArgument.resultOfLine) {
        const match = this.$store.state.returnArguments.find((ra: EngineArgument) => {
          return ra.resultOfLine === this.line.returnArgument!.resultOfLine
        })
        if (!match) {
          // console.log('register result of line ' + this.line.ranking + ' => ' + this.line.returnArgument.name)
          this.$store.state.returnArguments.push(this.line.returnArgument)
        } else {
          // console.log('update return argument line ' + this.line.ranking + ' to ' + match.name)
          this.line.returnArgument = match
        }
      }
    }

    // this.number = this.$store.state.lineCounter.valueOf()
    // if (this.line.returnArgument) {
    //     if (helper.empty(this.line.returnArgument.alias)) {
    //         this.line.returnArgument.alias = 'Resultat ' + this.number;
    //     }
    // }
    // this.$store.state.lineCounter++
  }

  updateLineNumber() {
    this.$emit('updateLineNumber')
  }

  addLineBefore(payload: any) {
    this.$emit('addLineBefore', payload)
  }

  addSubLine(payload: any) {
    console.log('add subline from line editor')
    this.$emit('addLine', payload)
  }

  removeLine(evt: any) {
    this.$emit('removeLine', evt)
  }


}
</script>
<style scoped>
.line:hover .add-line-before {
  display: block !important;
}
</style>
