<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                  <i class="fad fa-chart-pie"></i>
                  {{ trans("Tableau de bord") }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>

        <div class="d-flex align-items-center py-1">
          <date-range-picker :opens="'left'" ref="picker" class="mx-4" @update="updateValues" :locale-data="localData"
            :ranges="ranges" :singleDatePicker="false" :autoApply="false" v-model="dateRange">
            <template v-slot:input="picker" style="min-width: 350px">
              {{ readableDate(picker.startDate, "dmY") }} -
              {{ readableDate(picker.endDate, "dmY") }}
            </template>
          </date-range-picker>
          <div class="">
            <!--        <span class="me-3" v-if="$store.state.filter.promotion && promo">-->
            <!--            {{$store.state.filter.promotion.title}} ◾-->
            <!--        </span>-->
          </div>
        </div>
      </div>
    </div>
  </template>
  <template #content>
    <div class="g-5 row">
      <h1>Statistiques Globales</h1>
      <div class="row my-5 g-5">
        <div class="col-6 col-xl-12 d-flex">
          <div class="card card-custom bg-radial-gradient-danger gutter-b card-stretch flex-grow-1">
            <div class="card-header border-0 py-5">
              <h3 class="card-title fw-bolder fs-1 text-white">
                Cotations ({{ numberFormat(totalQuotes) }})
              </h3>
              <div class="card-toolbar"></div>
            </div>

            <div class="card-body d-flex flex-column p-0" style="position: relative">
              <div class="card-spacer bg-white flex-grow-1">
                <div class="row m-0">
                  <div v-for="(item, index) in rgByStatus" :key="index" class=" col px-8 py-6">
                    <span :class="redesignTitle(item.color)" class="font-size-sm fw-bolder" style="text-wrap: nowrap">{{
                  item.status }}</span>
                    <div class="fs-sm-2 fw-bolder">{{ item.count }}</div>
                    <div class="bg-secondary rounded h-5px w-100 position-relative">
                      <div :class="redesign(item.color)" :style="stylePercent(item.percent)"
                        class=" position-absolute h-5px" style="top: 0" />
                      <div class="position-absolute fw-bolder text-muted fs-8" style="top: 100%; right: 0">
                        {{ item.percent }} %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="col-6 col-xl-4 d-flex">
          <div class="card card-custom bg-radial-gradient-primary gutter-b card-stretch flex-grow-1">
            <div class="card-header border-0 py-5">
              <h3 class="card-title fw-bolder fs-2 text-white d-flex justify-content-between flex-grow-1">
                <div>Sources</div>
                <div></div>
              </h3>
              <div class="card-toolbar"></div>
            </div>
            <div class="card-body d-flex flex-column p-0" style="position: relative">
              <div
                class="card-spacer bg-white card-rounded flex-grow-1 d-flex justify-content-center align-items-center">
                <div v-if="chartCotationsSource">
                  <apexchart type="treemap" width="400" height="280" :options="chartCotationsSource.chartOptions"
                    :series="chartCotationsSource.series"></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-xl-4 d-flex">
          <div class="card card-custom bg-radial-gradient-danger gutter-b card-stretch flex-grow-1">
            <div class="card-header border-0 py-5">
              <h3 class="card-title fw-bolder fs-2 text-white d-flex justify-content-between flex-grow-1">
                <div>Paiements</div>
                <div>{{ sumCount(ammountByPMethod) }} FCFA</div>
              </h3>
              <div class="card-toolbar"></div>
            </div>
            <div class="card-body d-flex flex-column p-0" style="position: relative">
              <div
                class="card-spacer bg-white card-rounded flex-grow-1 d-flex justify-content-center align-items-center">
                <div v-if="ammountByPMethod.length>0">
                  <apexchart type="radialBar" width="400" :options="chartByPaymentMethod.chartOptions"
                    :series="chartByPaymentMethod.series"></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-xl-4 d-flex">
          <div class="card card-custom bg-radial-gradient-primary gutter-b card-stretch flex-grow-1">
            <div class="card-header border-0 py-5">
              <h3 class="card-title fw-bolder fs-2 text-white d-flex justify-content-between flex-grow-1">
                <div>Avenants</div>
                <div></div>
              </h3>
              <div class="card-toolbar"></div>
            </div>
            <div class="card-body d-flex flex-column p-0" style="position: relative">
              <div
                class="card-spacer bg-white card-rounded flex-grow-1 d-flex justify-content-center align-items-center">
                <div v-if="chartAvenants">
                  <apexchart type="treemap" width="400" height="280" :options="chartAvenants.chartOptions"
                    :series="chartAvenants.series"></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="hasRole('ROLE_MANAGER')">

      <div class="d-flex justify-content-between">
        <h1>Statistiques Utilisateurs Manager</h1>
        <div>
          <div class="btn btn-primary">
            <i class="fad fa-file-export"></i>
            Exporter
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="card">
          <div class="card-body">
            <table class="table table-striped g-5">
              <thead>
                <tr class="fw-bolder">
                  <th>Utilisateurs</th>
                  <th>Cotations</th>
                  <th>Incomplète</th>
                  <th>Joignable</th>
                  <th>Disqualifiée</th>
                  <th>Qualifiée</th>
                  <th>Comparée</th>
                  <th>Offre Selectionnée</th>
                  <th>Validée</th>
                  <th>Annulée</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in rgByUser" v-if="item.user != null" :key="index">
                  <td>
                    {{ item.user }}
                  </td>

                  <td>{{ sumCount(item.rg) }}</td>

                  <td>
                    {{ getCount("INCOMPLETE", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("REACHABLE", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("DISQUALIFIED", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("QUALIFIED", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("COMPARED", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("OFFER_SELECTED", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("VALIDATED", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("EXPIRED", item.rg) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <h1>Statistiques Partenaires</h1>
      <div class="row my-5 g-5">
        <div class="col-6 col-xl-12 d-flex">
          <div class="card card-custom bg-radial-gradient-danger gutter-b card-stretch flex-grow-1">
            <div class="card-header border-0 py-5">
              <h3 class="card-title fw-bolder fs-2 text-white">
                Cotations ({{ totalQuotesByPartner }})
              </h3>
              <div class="card-toolbar"></div>
            </div>

            <div class="card-body d-flex flex-column p-0" style="position: relative">
              <div class="card-spacer bg-white flex-grow-1">
                <div class="row m-0">
                  <div v-for="(item, index) in rgPartnerByStatus" :key="index" class="col px-8 py-6">
                    <span :class="redesignTitle(item.color)" class="font-size-sm fw-bolder" style="text-wrap: nowrap">{{
                  item.status }}</span>
                    <div class="fs-sm-2 fw-bolder">{{ item.count }}</div>
                    <div class="bg-secondary rounded h-5px w-100 position-relative">
                      <div :class="redesign(item.color)" :style="stylePercent(item.percent)"
                        class=" position-absolute h-5px" style="top: 0" />
                      <div class="position-absolute fw-bolder text-muted fs-8" style="top: 100%; right: 0">
                        {{ item.percent }} %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="col-6 col-xl-4 d-flex">
          <div class="card card-custom bg-radial-gradient-primary gutter-b card-stretch flex-grow-1">
            <div class="card-header border-0 py-5">
              <h3 class="card-title fw-bolder fs-2 text-white d-flex justify-content-between flex-grow-1">
                <div>Sources</div>
                <div></div>
              </h3>
              <div class="card-toolbar"></div>
            </div>
            <div class="card-body d-flex flex-column p-0" style="position: relative">
              <div
                class="card-spacer bg-white card-rounded flex-grow-1 d-flex justify-content-center align-items-center">
                <div v-if="chartSourceQuotationByPartner">
                  <apexchart type="treemap" width="400" height="280"
                    :options="chartSourceQuotationByPartner.chartOptions"
                    :series="chartSourceQuotationByPartner.series"></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-xl-4 d-flex">
          <div class="card card-custom bg-radial-gradient-danger gutter-b card-stretch flex-grow-1">
            <div class="card-header border-0 py-5">
              <h3 class="card-title fw-bolder fs-2 text-white d-flex justify-content-between flex-grow-1">
                <div>Paiements</div>
<!--                <div>{{ sumCount(ammountByPMethodByPartner) }} FCFA</div>-->
              </h3>
              <div class="card-toolbar"></div>
            </div>
            <div class="card-body d-flex flex-column p-0" style="position: relative">
              <div
                class="card-spacer bg-white card-rounded flex-grow-1 d-flex justify-content-center align-items-center">
                <div v-if="chartByPaymentMethodByPartner">
                  <apexchart type="radialBar" width="400" :options="chartByPaymentMethodByPartner.chartOptions"
                    :series="chartByPaymentMethodByPartner.series"></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-6 col-xl-4 d-flex">
          <div class="card card-custom bg-radial-gradient-primary gutter-b card-stretch flex-grow-1">
            <div class="card-header border-0 py-5">
              <h3 class="card-title fw-bolder fs-2 text-white d-flex justify-content-between flex-grow-1">
                <div>Avenants</div>
                <div></div>
              </h3>
              <div class="card-toolbar"></div>
            </div>
            <div class="card-body d-flex flex-column p-0" style="position: relative">
              <div
                class="card-spacer bg-white card-rounded flex-grow-1 d-flex justify-content-center align-items-center">
                <div v-if="chartAvenantsByPartner">
                  <apexchart type="treemap" width="400" :options="chartAvenantsByPartner.chartOptions"
                    :series="chartAvenantsByPartner.series"></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <h1>Statistiques Utilisateurs Partenaires</h1>
        <div>
          <div class="btn btn-primary">
            <i class="fad fa-file-export"></i>
            Exporter
          </div>
        </div>
      </div>

    </template>

      <div class="row my-5">
        <div class="card">
          <div class="card-body">
            <table class="table table-striped g-5">
              <thead>
                <tr class="fw-bolder">
                  <th>Utilisateurs</th>
                  <th>Cotations</th>
                  <th>Incomplète</th>
                  <th>Joignable</th>
                  <th>Disqualifiée</th>
                  <th>Qualifiée</th>
                  <th>Comparée</th>
                  <th>Offre Selectionnée</th>
                  <th>Validée</th>
                  <th>Annulée</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in rgByUserPartner" v-if="item.partner != null" :key="index">
                  <td>
                    <div v-if="!empty(item.partner)">
                      <partner-view v-model="item.partner"></partner-view>
                    </div>
                  </td>

                  <td>{{ sumCount(item.rg) }}</td>

                  <td>
                    {{ getCount("INCOMPLETE", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("REACHABLE", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("DISQUALIFIED", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("QUALIFIED", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("COMPARED", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("OFFER_SELECTED", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("VALIDATED", item.rg) }}
                  </td>

                  <td>
                    {{ getCount("EXPIRED", item.rg) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


    </div>
  </template>
  </Base>
</template>

<script lang="ts">

import PartnerView from "@/components/PartnerView.vue";
import { Vue, Component } from "vue-property-decorator";
import DateRangePicker from "vue2-daterange-picker";
import { api } from "@/services/Api";
import Base from "@/layouts/Base.vue";
import { helper } from "@/services/Helper";
import Survey from "@/entity/Survey";
import Branch from "@/entity/Branch";
import BranchCategory from "@/entity/BranchCategory";
import CONSTANTS from "@/Utils/constant";

import { auth } from "@/services/Auth";
import Partner from "@/entity/Partner";
@Component({
  components: { Base, DateRangePicker, PartnerView },
})
export default class CotationDashboard extends Vue {

  // [x: string]: any;
  //quote
  totalQuotes = 0;
  rgBySource = [];
  rgByStatus: any = [];
  rgByUser: any = [];

  // quote by partner
  totalQuotesByPartner = 0;
  TotalRgByPartner = [];
  rgPartnerByStatus: any = [];
  // rgByPartner = [];
  rgPartnerBySource: any = [];
  rgByUserPartner:any =[]
  //contract
  ammountByPMethod = [];
  avenantbyType = [];
  avenantByTypeByPartner= [];
  ammountByPMethodByPartner=[];

  surveys: Survey[] = [];
  formSearched = "";
  branchs: Branch[] | any = [];
  branchSearched = "";
  dateRange = {
    startDate: CONSTANTS.dateConstants.first_day_current_month(),
    endDate: CONSTANTS.dateConstants.last_day_current_month(),
  };

  // chartCotationsPartner: any = null;
  chartCotationsSource: any = null;
  chartTypeCotations: any = null;



  // Partner
  chartCotationsByPartner: any = null;
  chartTotalQuotationByPartner: any = null;
  chartSourceQuotationByPartner: any = null;
  chartByPaymentMethod:any = null;

  chartAvenants:any = null;

  chartByPaymentMethodByPartner: any = null;
  chartAvenantsByPartner: any = null;

  //Contract


  chartProspectPartner: any = null;
  chartMotif: any = null;
  searchTemplate = {
    branch: [],
    managers: [],
    forms: [],
    start: CONSTANTS.dateConstants.first_day_current_month(),
    end: CONSTANTS.dateConstants.last_day_current_month(),
  };
  partners: any[] = [];
  partnerUsers: any[] = [];

  rgNameByCode(code: string) {
    let match = this.partners.find((p: Partner) => {
      return p.code === code;
    });
    if (match instanceof Partner) {
      if (!helper.empty(match.name)) {
        return match.name;
      }
    }
    match = this.partnerUsers.find((p: Partner) => {
      return p.code === code;
    });
    if (match instanceof Partner) {
      if (!helper.empty(match.name)) {
        return match.name;
      }
    }
    return code;
  }

  redesign(value: string) {
    // console.log(`bg-${value}`);
    return `bg-${value}`;
  }

  redesignTitle(value: string) {
    return `text-${value} `;
  }
  stylePercent(percent: number) {
    return `width:${percent}%`;
  }

  sumCount(item: any) {
    let data = 0
    item.map((object: any) => {
      data = data + object.count
    })
    return data;
  }

  localData = {
    direction: "ltr",
    format: "mm/dd/yyyy",
    separator: " - ",
    applyLabel: "Valider",
    cancelLabel: "Annuler",
    weekLabel: " ",
    customRangeLabel: "Custom Range",
    daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    monthNames: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    firstDay: 0,
  };

  ranges: any = false;

  loadRanges() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    this.ranges = {
      Aujourdhui: [today, today],
      Hier: [yesterday, yesterday],
      "Ce mois": [
        CONSTANTS.dateConstants.first_day_current_month(),
        CONSTANTS.dateConstants.last_day_current_month(),
      ],
      "Le mois dernier": [
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth(), 0),
      ],
      "Cette annee": [
        new Date(today.getFullYear(), 0, 1),
        new Date(today.getFullYear(), 11, 31),
      ],
    };
    this.$forceUpdate();
  }
  search: any = {};
  getCount(itemString: string, data: any) {
    const foundItem = data.find((item: any) => item.status === itemString);

    return foundItem ? foundItem.count : 0;
  }
  beforeMount(): void {
    this.search = { ...this.searchTemplate };
    this.loadRanges();

    // console.log(this.search);
  }
  resetSearch() {
    this.search = { ...this.searchTemplate };
    this.loadData();
    this.$forceUpdate();
  }
  async loadData() {
    this.$store.commit("loading");

      const resCotations = await api.post(
        api.form,
        "data/dashboard/quotation",
        {
          search: this.search,
        }
      );
// if (auth.hasRole("ROLE_MANAGER")) {
  const resContract = await api.post(api.core, "data/dashboard/contract", {
        search: this.search,
      });


      // res resContract

      this.ammountByPMethod = resContract.data && resContract.data.ammountByPMethod;
      this.avenantbyType = resContract.data && resContract.data.avenantbyType;

      // res resContract partner


      this.ammountByPMethodByPartner = resContract.data && resContract.data.ammountByPMethodByPartner;
      this.avenantByTypeByPartner = resContract.data && resContract.data.avenantByTypeByPartner;


// }

      this.totalQuotes = resCotations.data && resCotations.data.responseGroup;
      // this.rgByPartner = resCotations.data && resCotations.data.responseGroupsPartner;
      this.rgBySource = resCotations.data && resCotations.data.rgBySource;
      this.rgByStatus = resCotations.data && resCotations.data.rgByStatus;
      this.rgByUser = resCotations.data && resCotations.data.rgByUser;



      //partner
      this.totalQuotesByPartner = resCotations.data && resCotations.data.totalQuotesByPartner
      this.rgPartnerByStatus = resCotations.data && resCotations.data.rgPartnerByStatus
      this.rgPartnerByStatus = resCotations.data && resCotations.data.rgPartnerByStatus
      this.rgPartnerBySource = resCotations.data && resCotations.data.rgPartnerBySource
      this.rgByUserPartner = resCotations.data && resCotations.data.rgByUserPartner;

      const countMap: any = {};
      this.rgByStatus.forEach((item: any) => {
        countMap[item.status] = item.count;
      });

      this.rgByStatus = Object.keys(CONSTANTS.responseGroupStatus).map(
        (key) => {
          return {
            status: CONSTANTS.responseGroupStatus[key],
            count: countMap[key] || 0,
            color:
              ["EXPIRED", "ABORTED", "DISQUALIFIED"].indexOf(key) != -1
                ? "danger"
                : ["COMPARED", "OFFER_SELECTED", "VALIDATED"].indexOf(key) != -1
                  ? "success"
                  : "black-50",

            percent: Math.round(
              ((countMap[key] || 0) * 100) / this.totalQuotes
            ),
          };
        }
      );
      this.rgPartnerByStatus.forEach((item: any) => {
        countMap[item.status] = item.count;
      });
      this.rgPartnerByStatus = Object.keys(CONSTANTS.responseGroupStatus).map(
        (key) => {
          return {
            status: CONSTANTS.responseGroupStatus[key],
            count: countMap[key] || 0,
            color:
              ["EXPIRED", "ABORTED", "DISQUALIFIED"].indexOf(key) != -1
                ? "danger"
                : ["COMPARED", "OFFER_SELECTED", "VALIDATED"].indexOf(key) != -1
                  ? "success"
                  : "black-50",

            percent: Math.round(
              ((countMap[key] || 0) * 100) / this.totalQuotesByPartner
            ),
          };
        }
      );

      // this.chartCotationsPartner = {
      //   series:
      //     this.rgByPartner && this.rgByPartner.map((item: any) => item.count),
      //   chartOptions: {
      //     chart: {
      //       width: 300,
      //       height: 300,
      //       type: "pie",
      //     },
      //     labels:
      //       this.rgByPartner &&
      //       this.rgByPartner.map((item: any) => item.partner),
      //     theme: {},
      //     dataLabels: {
      //       enabled: false,
      //     },
      //     // responsive: [
      //     //   {
      //     //     breakpoint: 480,
      //     //     options: {
      //     //       chart: {
      //     //         width: 200,
      //     //       },
      //     //       legend: {
      //     //         show: false,
      //     //       },
      //     //     },
      //     //   },
      //     // ],
      //     legend: {
      //       // show: false,
      //       position: "right",
      //       offsetY: 0,
      //       height: 230,
      //     },
      //   },
      // };

      this.chartCotationsSource = {
        series: [
          {
            data:
              this.rgBySource &&
              this.rgBySource.map((item: any) => ({
                x: item.source ,
                y: item.count,
              })),
          },
        ],
        chartOptions: {
          chart: {
            width: 380,
            type: "treemap",
          },
          xaxis: {
            categories:
              this.rgBySource &&
              this.rgBySource.map((item: any) => item.source),
          },

          theme: {
            monochrome: {
              enabled: true,
            },
          },
          dataLabels: {
          enabled: true,
          style: {
            fontSize: '15px',
          },
          formatter: function(text: any, op: { value: any; }) {
            return [text, op.value]
          },
          offsetY: -4
        },


          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: {
            position: "right",
            offsetY: 0,
            height: 230,
          },
        },
      };
      //partner

      this.chartSourceQuotationByPartner = {

        series: [
          {
            data:
              this.rgPartnerBySource &&
              this.rgPartnerBySource.map((item: any) => ({
                x: item.source,
                y: item.count,
              })),
          },
        ],
        chartOptions: {
          chart: {
            width: 380,
            type: "treemap",
          },
          xaxis: {
            categories:
              this.rgPartnerBySource &&
              this.rgPartnerBySource.map((item: any) => item.source),
          },

          theme: {
            monochrome: {
              enabled: true,
            },
          },

          dataLabels: {
          enabled: true,
          style: {
            fontSize: '15px',
          },
          formatter: function(text: any, op: { value: any; }) {
            return [text, op.value]
          },},

          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: {
            position: "right",
            offsetY: 0,
            height: 230,
          },
        },

      }




      //contract

      this.chartByPaymentMethod = {
        series: this.ammountByPMethod && this.ammountByPMethod.map((item: any) => this.chartCalcul(item)),

        chartOptions: {
          chart: {
            height: 390,
            type: 'radialBar',
          },


          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                // size: '30%',
                background: 'transparent',
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  show: false,
                }
              },
              barLabels: {
                enabled: true,
                useSeriesColors: true,
                margin: 8,
                fontSize: '16px',
                formatter: function (seriesName: string, opts: { w: { globals: { series: { [x: string]: string; }; }; }; seriesIndex: string | number; }) {
                  return seriesName + ' FCFA' + ' - '
                },
              },
            }
          },

          stroke: {
              lineCap: 'round'
            },
          colors: this.ammountByPMethod.map((item: any) => item.label == 'Orange Money' ? '#f77500' : item.label == 'wave' ? '#1ab2e5' : item.label == 'MoMo' ? '#ffcb03' : item.label == 'Flooz' ? '#ffcb03' : '#39539E') ?? ['#f77500', '#0084ff', '#39539E', '#0077B5'],
          labels: this.ammountByPMethod && this.ammountByPMethod.map((item: any) => (item.label + ': ' + item.count)),
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                show: false
              }
            }
          }]
        }
      }

      this.chartAvenants = {
        series: [
          {
            data:
              this.avenantbyType &&
              this.avenantbyType.map((item: any) => ({
                x: item.type ,
                y: item.count,
              })),
          },
        ],
        chartOptions: {
          chart: {
            width: 380,
            type: "treemap",
          },
          xaxis: {
            categories:
              this.avenantbyType &&
              this.avenantbyType.map((item: any) => item.type),
          },

          theme: {
            monochrome: {
              enabled: false,
            },
          },
          dataLabels: {
          enabled: true,
          style: {
            fontSize: '15px',
          },
          formatter: function(text: any, op: { value: any; }) {
            return [text, op.value]
          },
          offsetY: -4
        },


          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: {
            position: "right",
            offsetY: 0,
            height: 230,
          },
        },
      };


        // partner

        this.chartByPaymentMethodByPartner = {
        series: this.ammountByPMethodByPartner && this.ammountByPMethodByPartner.map((item: any) => this.chartCalcul(item)),

        chartOptions: {
          chart: {
            height: 390,
            type: 'radialBar',
          },


          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                // size: '30%',
                background: 'transparent',
                image: undefined,
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  show: false,
                }
              },
              barLabels: {
                enabled: true,
                useSeriesColors: true,
                margin: 8,
                fontSize: '16px',
                formatter: function (seriesName: string, opts: { w: { globals: { series: { [x: string]: string; }; }; }; seriesIndex: string | number; }) {
                  return seriesName + ' FCFA' + ' - '
                },
              },
            }
          },

          stroke: {
              lineCap: 'round'
            },
          colors: this.ammountByPMethodByPartner.map((item: any) => item.label == 'Orange Money' ? '#f77500' : item.label == 'wave' ? '#1ab2e5' : item.label == 'MoMo' ? '#ffcb03' : item.label == 'Flooz' ? '#ffcb03' : '#39539E') ?? ['#f77500', '#0084ff', '#39539E', '#0077B5'],
          labels: this.ammountByPMethodByPartner && this.ammountByPMethodByPartner.map((item: any) => (item.label + ': ' + item.count)),
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                show: false
              }
            }
          }]
        }
      }

      this.chartAvenantsByPartner = {
        series: [
          {
            data:
              this.avenantByTypeByPartner &&
              this.avenantByTypeByPartner.map((item: any) => ({
                x: item.type ,
                y: item.count,
              })),
          },
        ],
        chartOptions: {
          chart: {
            width: 380,
            type: "treemap",
          },
          xaxis: {
            categories:
              this.avenantByTypeByPartner &&
              this.avenantByTypeByPartner.map((item: any) => item.type),
          },

          theme: {
            monochrome: {
              enabled: false,
            },
          },
          dataLabels: {
          enabled: true,
          style: {
            fontSize: '15px',
          },
          formatter: function(text: any, op: { value: any; }) {
            return [text, op.value]
          },
          offsetY: -4
        },


          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: {
            position: "right",
            offsetY: 0,
            height: 230,
          },
        },
      };


    this.$store.commit("stopLoading");
  }


  chartCalcul(item: any, reverse?: boolean) {

    if (reverse) {
      return item.count
    }
    return (item.count * 100) / this.sumCount(this.ammountByPMethod)
  }
  updateValues() {
    const startDate: Date = new Date(this.dateRange.startDate);
    const endDate: Date = new Date(this.dateRange.endDate);
    this.search = {
      ...this.search,
      start: helper.reversedDateToHtml(startDate),
      end: helper.reversedDateToHtml(endDate),
    };

    this.loadData();
  }
  async loadSurveys() {
    if (helper.empty(this.surveys)) {
      const res = await api.get(api.form, "survey/min/list");
      if (res && res.data && res.data.surveys) {
        res.data.surveys.forEach((s: any) => {
          const occ = new Survey(s);
          this.surveys.push(occ);
        });
      }
    }
  }
  isFormVisible(survey: Survey) {
    if (helper.empty(this.formSearched)) {
      return true;
    }
    if (
      survey.slug.toLowerCase().includes(this.formSearched.toLowerCase()) ||
      survey.label.toLowerCase().includes(this.formSearched.toLowerCase())
    ) {
      return true;
    }
    // if (this.search.branch) {
    //   console.log(survey)
    //   console.log(this.search.branch)
    // }

    return false;
  }
  toggleSurvey(code: string) {
    const index = this.search.forms?.indexOf(code);
    if (index !== -1) {
      this.search.forms.splice(index, 1);
    } else {
      this.search.forms.push(code);
    }
    this.$forceUpdate();
  }
  getSurveyNameByCode(code: string) {
    const survey = this.surveys.find((survey: Survey) => {
      return survey.slug === code;
    });
    if (survey) {
      if (!helper.empty(survey.label)) {
        return survey.label;
      }
      return survey.slug;
    }
    return code;
  }
  async loadBranch() {
    const categories: BranchCategory[] =
      await this.$store.getters.branchCategories();

    categories.forEach((category: BranchCategory) => {
      category.branches.forEach((branche: Branch) => {
        this.branchs.push(branche);
      });
    });

    // console.log(this.branchs,'branchs');

    this.$store.commit("stopLoading");
  }
  isBranchVisible(branch: Branch) {
    if (helper.empty(this.branchSearched)) {
      return true;
    }
    if (branch.slug.toLowerCase().includes(this.branchSearched.toLowerCase())) {
      return true;
    }
    // if (this.search.branch) {
    //   console.log(survey)
    //   console.log(this.search.branch)
    // }

    return false;
  }
  toggleBranch(code: string) {
    const index = this.search.branch?.indexOf(code);
    console.log(index);
    if (index !== -1) {
      this.search.branch.splice(index, 1);
    } else {
      this.search.branch.push(code);
    }

    console.log(this.search);
    this.$forceUpdate();
  }
  getBranchNameByCode(code: string) {
    const branch = this.branchs.find((branch: Branch) => {
      return branch.slug === code;
    });
    if (branch) {
      if (!helper.empty(branch.label)) {
        return branch.label;
      }
      return branch.slug;
    }
    return code;
  }
  async mounted() {
    // if (auth.hasRole("ROLE_MANAGER")) {
      this.loadData();
      this.loadSurveys();
      this.loadBranch();

      // this.partners = await this.$store.getters.partners()
      // this.partnerUsers = await this.$store.getters.partnerUsers()
    // }
  }
}
</script>

<style>
.dateranger-dashboard .daterangepicker {
  left: 0 !important;
}
</style>
