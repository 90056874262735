<template>
    <div class="input-number flex-grow-1 d-inline-flex align-items-center rounded px-1" style="background: #F5F8FA">
        <div @click="decrease" class="btn btn-sm btn-icon btn-secondary">
            <i class="fad fa-minus"></i>
        </div>
        <span class="px-2 ms-5" v-if="prefix">
            <template v-if="isIcon(prefix)">
                <i :class="prefix"></i>
            </template>
            <template v-else>
                {{ prefix }}
            </template>
        </span>
        <input :max="max" :min="min" @change="handleInput" class="mx-2 flex-grow-1" type="number" v-model="content" @input="handleInput" />
        <span class="px-2" v-if="suffix">
            <template v-if="isIcon(suffix)">
                <i :class="suffix"></i>
            </template>
            <template v-else>
                {{ prefix }}
            </template>
        </span>
        <div @click="increase" class="btn btn-sm btn-icon btn-secondary">
            <i class="fad fa-plus"></i>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class InputNumber extends Vue {
    @Prop({ default: 0 }) readonly value!: number
    @Prop({ default: 1 }) readonly step!: number
    @Prop() min!: number
    @Prop() max!: number
    @Prop({ default: null }) prefix!: string
    @Prop({ default: null }) suffix!: string
    content = parseFloat(this.value + '')
    to: any = null

    isIcon(text: string) {
        return text.match(/fa |fab |fad |fal |fas /) !== null
    }

    mounted(): void {
        if (this.min > this.content) {
            this.content = this.min
        }
        if (this.max < this.content) {
            this.content = this.max
        }
        // console.log(this.value)
        // this.handleInput()
    }

    handleInput() {
        this.$emit('input', this.content)
    }

    increase() {
        if (this.max) {
            if (this.content + 1 < this.max) {
                this.content++
            }
        } else {
            this.content++
        }
        if (isNaN(this.content)) {
            this.content = 1
        }
        this.handleInput()
    }


    decrease() {
        if (this.min) {
            if (this.content - 1 > this.min) {
                this.content--
            }
        } else {
            this.content--
        }
        if (isNaN(this.content)) {
            this.content = -1
        }
        this.handleInput()
    }
}
</script>
<style scoped>
input {
    border-radius: 0;
    background: transparent;
    border: none;
    outline: none;
    text-align: right;
    min-width: 100px;
}
</style>
