<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <Base>
        <template v-slot:content>

          <div v-if="pack && pack.insurer" class="fs-2x fw-bolder mb-5">
          {{pack.insurer.label}}
          </div>
            <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="form-check form-switch form-check-custom form-check-solid fs-2 fw-bolder">
                        <input @change="toggleActivation()" class="form-check-input h-30px w-50px mx-5"
                               type="checkbox" v-model="pack.enable" value=""/>
                        <div v-if="pack.enable">
                          Ce pack est actuellement activé
                        </div>
                        <div v-else>
                          Ce pack est actuellement désactivé
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="row">
                        <div class="col-auto m-2 flex-grow-1">
                            <label :for="'name'" class="required form-label">{{trans('Nom')}}</label>
                            <b-form-input :id="'name'" :state="!helper.empty(pack.label)"
                                          v-model="pack.label"></b-form-input>
                        </div>
                        <div class="col-auto m-2 flex-grow-1">
                            <label :for="'name'" class="required form-label">{{trans('Catégorie')}}</label>
                            <b-form-select :state="!helper.empty(pack.category)" class="form-control  form-select" v-model="pack.category">
                                <b-form-select-option value="">{{trans('Selectionnez')}}</b-form-select-option>
                                <b-form-select-option :key="cat.id" :value="cat" v-for="cat,index in categories">
                                    {{trans(cat.label)}}
                                </b-form-select-option>
                            </b-form-select>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between">
                        <div class="flex-grow-1 me-5">
                            <h1 class="my-10">{{trans('Garanties')}}</h1>
                            <table class="table table-striped gy-3 gs-3">
                                <thead>
                                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                                    <th>{{trans('Garantie')}}</th>
                                    <th>{{trans('Optionel')}}</th>
                                    <th>{{trans('Franchise')}}</th>
                                    <th>{{trans('Plafond')}}</th>
                                </tr>
                                </thead>
                                <draggable :animation="200" :list="pack.packGuarantees" @end="updateRanking" ghost-class="btn-icon"
                                           handle=".handle"
                                           group="packGuarantee"
                                           tag="tbody">
                                    <tr class="" v-for="packGuarantee,index in pack.packGuarantees">
                                        <td>
                                            <i class="fad fa-arrows fs-2 mx-5 handle"></i>
                                            {{packGuarantee.guarantee.label}}
                                        </td>
                                        <td valign="middle">
                                            <div class="form-check form-switch form-check-custom form-check-solid me-10">
                                                <input class="form-check-input h-20px w-30px" type="checkbox"
                                                       v-model="packGuarantee.isOptional"/>
                                            </div>
                                        </td>
                                        <td>
                                          <textarea class="form-control" type="text" v-model="packGuarantee.deductible"></textarea>
                                        </td>
                                        <td>
                                          <textarea class="form-control" type="text" v-model="packGuarantee.ceiling"></textarea>
                                        </td>
                                        <td valign="middle">
                                            <i @click="removePackGuarantee(packGuarantee)"
                                               class="fad fa-times-circle text-danger cursor-pointer"></i>
                                        </td>
                                    </tr>
                                </draggable>
                            </table>
                        </div>
                        <div class="border-start ps-5">
                            <div style="position: sticky;top:70px">
                                <h1 class="my-10">{{trans('Liste')}}</h1>
                                <draggable :animation="200" :list="packGuarantees" class="d-flex flex-column mh-500px overflow-auto"
                                           filter=".action-button" ghost-class="bg-light-primary" group="packGuarantee" tag="div">
                                    <template v-for="packGuarantee in packGuarantees">
                                        <div class="fw-bolder badge m-1 badge-light-primary cursor-pointer"
                                             v-if="guaranteeNotAdded(packGuarantee)">
                                            {{packGuarantee.guarantee.label}}
                                        </div>
                                    </template>
                                </draggable>
                            </div>
                        </div>
                    </div>

                    <h1 class="my-10">{{trans('Fichiers à Télécharger')}}</h1>


                    <table class="table table-striped gy-3 gs-3">
                        <thead>
                        <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                            <th>{{trans('Nom')}}</th>
                            <th>{{trans('Demande de consentement')}}</th>
                            <th>{{trans('Fichier')}}</th>
                            <th>{{trans('Actions')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="" v-for="file,index in pack.files" valign="middle">
                            <td>
                                <input class="form-control" type="text" v-if="helper.empty(file.id)" v-model="file.name">
                                <template v-else>
                                    {{file.name}}
                                </template>
                            </td>
                            <td valign="middle">
                                <div class="form-check form-switch form-check-custom form-check-solid me-10">
                                    <input class="form-check-input h-20px w-30px" type="checkbox" v-model="file.consent" value=""/>
                                </div>
                            </td>
                            <td>
                                <FileUpload :allowMultiple="false" :allowRemove="false"
                                            :currentFileName="file.filename"
                                            :text="'Selectionnez un fichier'"
                                            v-model="file.filename"></FileUpload>
                            </td>
                            <td class="text-end" valign="middle">
                                <div @click="removeFile(index,file)" class="btn btn-xs btn-icon btn-danger">
                                    <i class="fad fa-trash"></i>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div @click="addFile" class="d-flex justify-content-center">
                        <div class="btn btn-primary btn-sm">
                            <i class="fad fa-plus"></i>
                            Ajouter
                        </div>
                    </div>

                    <div @click="save" class="d-flex justify-content-end">
                        <div class="btn btn-primary btn-sm">
                            <i class="fad fa-save"></i>
                            Enregistrer
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Base>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import Base from "@/layouts/Base.vue";
    import Pack from "@/entity/Pack";
    import {api} from "@/services/Api";
    import PackCategory from "@/entity/PackCategory";
    import Guarantee from "@/entity/Guarantee";
    import PackGuarantee from "@/entity/PackGuarantee";
    import PackFile from "@/entity/PackFile";
    import {helper} from "@/services/Helper";
    import FileUpload from "@/components/FileUpload.vue";
    import Insurer from "@/entity/Insurer";
    import Popup from "@/entity/Popup";

    @Component({
        components: {FileUpload, Base}
    })
    export default class PackForm extends Vue {
        pack = new Pack()
        categories: PackCategory[] = []
        packGuarantees: PackGuarantee[] = []

        async mounted() {

            if (this.$route.params.packId) {
                await this.loadPack()
            }
            await this.loadPackCategories()
            await this.loadGuarantees()

        }

      async toggleActivation() {
        let message = 'Activation';
        if (this.pack.enable) {
          message = 'Désactivation';
        }
        const p = new Popup(this.pack.label, message, 'primary', 'fad fa-sync', true);
        const res = await api.get(api.core, 'pack/toggle/activation/' + this.pack.uuid);
        p.hide()
        if (res && res.data) {
          this.pack.enable = res.data.state
        }
      }
        async updateRanking() {
            let i = 1
            this.pack.packGuarantees.forEach((p: PackGuarantee) => {
                p.ranking = i
                i++
            })

        }

        async loadPack() {
            const popup = new Popup('Chargement du pack', '', 'warning', 'fad fa-cogs', true)
            const res = await api.get(api.core, 'pack/get/data/' + this.$route.params.packId)
            if (res && res.data && res.data.pack) {
                this.pack = new Pack(res.data.pack)
            }
            popup.hide()
        }

        guaranteeNotAdded(pg: PackGuarantee) {
            return this.pack.packGuarantees.findIndex((p: PackGuarantee) => {
                return p.guarantee.id === pg.guarantee.id
            }) === -1
        }

        async save() {
            await this.updateRanking()
            // this.pack.packGuarantees.forEach((p: PackGuarantee) => {
            //     console.log(p.ranking + ' => ' + p.guarantee.label + ' => ' + p.isOptional)
            // })

            this.$store.commit('loading')
            const res = await api.post(api.core, 'pack/edit/' + this.$route.params.insurerSlug, {pack: this.pack})
            if (res) {
                this.loadPack()
            }
            this.$store.commit('stopLoading')
        }

        addFile() {
            const occ = new PackFile()
            occ.name = this.pack.files.length + 1 + ''
            this.pack.files.push(occ)
            this.pack.files.forEach((f: PackFile) => {
                // if(helper.empty(f.f))
            })
        }

        async removeFile(index: number, packFile: PackFile) {

            const popup = new Popup('Suppression du fichier', '', 'warning', 'fad fa-cogs', true)
            const res = await api.get(api.core, 'pack/file/delete/' + packFile.id)
            popup.hide()
            this.pack.files.splice(index, 1)
            this.loadPack()
        }

        async loadPackCategories() {
            const popup = new Popup('Chargement des catégories', '', 'warning', 'fad fa-cogs', true)
            const res = await api.get(api.core, 'pack/category/list/' + this.$route.params.branchSlug)
            if (res && res.data && res.data.packCategories) {
                this.categories = []
                res.data.packCategories.forEach((c: PackCategory) => {
                    const occ = new PackCategory(c)
                    this.categories.push(occ)
                })
            }
            popup.hide()
        }

        removePackGuarantee(pg: PackGuarantee) {
            const index = this.pack.packGuarantees.findIndex((ig: PackGuarantee) => {
                return ig.guarantee!.id === pg.guarantee!.id
            })
            if (index !== -1) {
                this.pack.packGuarantees.splice(index, 1)
            }
        }

        async loadGuarantees() {
            const popup = new Popup('Chargement des garanties', '', 'warning', 'fad fa-cogs', true)
            const res = await api.get(api.core, 'guarantee/list/' + this.$route.params.branchSlug)
            // console.log(res)
            if (res && res.data && res.data.guarantees) {
                this.packGuarantees = []
                res.data.guarantees.forEach((g: Guarantee) => {
                    const occ = new PackGuarantee()
                    occ.guarantee = new Guarantee(g)
                    occ.isOptional = false
                    this.packGuarantees.push(occ)
                })
            }
            popup.hide()
        }

    }
</script>
<style scoped>
</style>
