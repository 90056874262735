<template>
    <div id="wa-3d-logo">
    </div>
</template>
<script lang="ts">
    /* eslint-disable */
    import * as THREE from 'three';
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

    @Component({
        components: {}
    })
    export default class Logo extends Vue {
        public requestAnimationFrameId: any = null
        public camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.01, 20)
        public scene = new THREE.Scene()
        public renderer = new THREE.WebGLRenderer({antialias: true, alpha: true})
        public controls = new OrbitControls(this.camera, this.renderer.domElement)
        public clock = new THREE.Clock()
        public textureLoader = new THREE.TextureLoader();
        // public fontLoader = new THREE.FontLoader()
        public iteration = 0
        public objects: any[] = []
        public lastTick = 0
        public helpers: any[] = []
        public materials: any = {
            red: new THREE.MeshMatcapMaterial({
                matcap: this.textureLoader.load('/matcap/red3.png'), name: 'red',
                // side: THREE.DoubleSide
            }),
            white: new THREE.MeshMatcapMaterial({
                matcap: this.textureLoader.load('/matcap/white.png'), name: 'white',
                // side: THREE.DoubleSide
            }),
            silver: new THREE.MeshMatcapMaterial({
                matcap: this.textureLoader.load('/matcap/silver2.png'),
                name: 'silver'
            }),
            black: new THREE.MeshMatcapMaterial({matcap: this.textureLoader.load('/matcap/black.png'), name: 'black'}),
            green: new THREE.MeshMatcapMaterial({matcap: this.textureLoader.load('/matcap/green.png'), name: 'green'}),
            gold: new THREE.MeshMatcapMaterial({matcap: this.textureLoader.load('/matcap/gold.png'), name: 'gold'}),
            gold3: new THREE.MeshMatcapMaterial({matcap: this.textureLoader.load('/matcap/gold3.png'), name: 'gold3'}),
            gold2: new THREE.MeshMatcapMaterial({matcap: this.textureLoader.load('/matcap/gold2.png'), name: 'gold2'}),
            normal: new THREE.MeshMatcapMaterial({
                matcap: this.textureLoader.load('/matcap/normal.png'),
                name: 'normal'
            }),
        
            pink: new THREE.MeshMatcapMaterial({matcap: this.textureLoader.load('/matcap/pink.png'), name: 'pink'}),
            pink2: new THREE.MeshMatcapMaterial({matcap: this.textureLoader.load('/matcap/pink2.png'), name: 'pink2'}),
        
        };
        public mouse = {
            x: 0,
            y: 0
        }
        public trophy = new THREE.Group()
        public target: any = null
        public trophyContainer = new THREE.Group()
        public intersects: any[] = []
        public laurier = new THREE.Group()
        public buttons: THREE.Mesh[] = []
        public buttonsText: THREE.Mesh[] = []
        
        public raycaster = new THREE.Raycaster();
        public hexagonGeometry: any = null
        public hexagons: any[] = []
        public lines = new THREE.Group()
        public fit = 1.8
        @Prop() zoom!: number
        
        beforeDestroy(): void {
            this.release()
        }
        
        saveMesh(mesh: THREE.Mesh, name: string) {
            mesh.name = name;
            this.objects.push(mesh)
            mesh.geometry.name = name + 'Geo'
            this.objects.push(mesh.geometry)
            if (!this.objects.find((obj) => {
                return obj.name === (mesh.material as any).name
            })) {
                this.objects.push(mesh.material)
            }
        }
        
        getHexagonGeometry(): THREE.BufferGeometry {
            if (this.hexagonGeometry) {
                return this.hexagonGeometry;
            }
            const shape = new THREE.Shape();
            const x = 0, y = 0
            shape.moveTo(x - .02, y - .035);
            shape.lineTo(x - .04, y)
            shape.lineTo(x - .02, y + .035)
            shape.lineTo(x + .02, y + .035)
            shape.lineTo(x + .04, y)
            shape.lineTo(x + .02, y - 0.035)
        
            const extrudeSettings = {
                curveSegments: 50,
                steps: 4,
                depth: .005,
                bevelEnabled: true,
                bevelThickness: .005,
                bevelSize: .01,
                bevelOffset: 0,
                bevelSegments: 10
            };
            this.hexagonGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
            return this.hexagonGeometry
        }
        
        release() {
            cancelAnimationFrame(this.requestAnimationFrameId)
            this.objects.forEach((obj) => {
                if (typeof obj.dispose === 'function') {
                    obj.dispose()
                }
            })
            const keys = Object.keys(this.materials)
            for (let i = 0; i < keys.length; i++) {
                if (typeof (this.materials as any)[keys[i]].dispose === 'function') {
                    (this.materials as any)[keys[i]].dispose()
                }
            }
            const dg = document.querySelectorAll('.dg.main.a');
            if (dg.length > 1) {
                dg[0].parentNode!.removeChild(dg[0]);
            }
        }
        
        getTouches(evt: any) {
            return evt.touches ||             // browser API
                evt.originalEvent.touches; // jQuery
        }
        
        async beforeMount(): Promise<void> {
            // this.createStarGeo()
        
        }
        
        destroyed(): void {
            window.removeEventListener('resize', this.resize)
        }
        
        async mounted() {
            this.intersects = []
            this.fit += this.zoom
            this.load()
            // this.showHexagons()
        }
        
        
        load() {
        
            this.release()
            // const width: any = document.querySelector('#wa-3d-logo')!.getBoundingClientRect().width
            // const height: any = document.querySelector('#wa-3d-logo')!.getBoundingClientRect().height
            this.resize()
            // this.renderer.setSize(width, height)
            // this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
            this.renderer.outputEncoding = THREE.sRGBEncoding
            // this.renderer.toneMapping = THREE.ACESFilmicToneMapping
            this.renderer.toneMappingExposure = 1
        
            this.camera.position.x = 0
            this.camera.position.z = 5
            this.camera.position.y = 0
            this.camera.near = .1
            this.camera.far = 20
            this.scene.add(this.camera)
            // this.scene.add(new THREE.AxesHelper())
        
        
            this.controls.enableZoom = false
            this.controls.enablePan = false
            this.controls.enableRotate = false
        
        
            // this.controls.listenToKeyEvents(window as any);
            // this.controls.enableDamping = true;
            // this.controls.minZoom = 0;
            // this.controls.maxZoom = 2;
            // this.controls.maxDistance = 4;
            // this.controls.minDistance = 1;
            // this.controls.maxAzimuthAngle = 0.15 * Math.PI;
            // this.controls.minAzimuthAngle = -0.15 * Math.PI;
            // this.controls.maxPolarAngle = (.6 * Math.PI);
            // this.controls.minPolarAngle = this.controls.maxPolarAngle - (0.15 * Math.PI);
        
        
            let targetBox = new THREE.BoxGeometry(.4, .4, .2);
            // if (this.isMobile()) {
            //     targetBox.dispose()
            //     targetBox = new THREE.BoxBufferGeometry(1.3, 1.8, .2);
            // }
            targetBox.center()
            this.target = new THREE.Mesh(targetBox, this.materials.white)
            this.target.visible = false
            this.target.position.y = -.1
            if (this.isMobile()) {
                this.target.position.y = -.19
            }
        
            this.createHexagonBackground()
            this.createShield()
            this.createLogo()
            this.loadBranches()
            // this.camera.position.y = -.1
            this.scene.add(this.target)
            this.renderer.render(this.scene, this.camera);
            document.querySelector("#wa-3d-logo")!.appendChild(this.renderer.domElement)
            window.addEventListener('resize', this.resize);
            this.tick()
        }
        
        resize() {
            const width = document.querySelector('#wa-3d-logo')!.getBoundingClientRect().width
            const height = document.querySelector('#wa-3d-logo')!.getBoundingClientRect().height
        
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
            // this.rotate()
            this.renderer.setSize(width, height)
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
            this.fitCameraToObject(this.lines, this.fit)
        }
        
        public hexagonShown = false
        public started: any = null
        public finished: any = null
        public timeout: any = null
        
        
        animateHexagons() {
            const hexagons = this.hexagons
        
            const elapsed = this.clock.getElapsedTime()
            const delta = elapsed - this.lastTick
            const step = delta * 0.3
            hexagons.forEach((hex, index) => {
                const currentScale = hex.scale.x
                const minScale = hex.userData.minScale
                const maxScale = 1
                const direction = hex.userData.direction
        
                //Zoom
                if (direction === 1) {
                    // if(index === 0){
                    //     console.log('Zoom')
                    // }
                    let nextScale = maxScale
                    if (currentScale < maxScale && hex.userData.delay === 0) {
                        nextScale = (currentScale + step) + (hex.userData.acceleration * step)
                        // nextScale = currentScale + 0.001
                    }
                    if (nextScale > maxScale) {
                        nextScale = maxScale
                    }
                    // console.log("Zoom next scale "+nextScale)
                    if (hex.userData.delay > 0) {
                        hex.userData.delay -= 1
                    } else {
                        hex.scale.set(nextScale, nextScale, nextScale)
                    }
                    if (currentScale === maxScale) {
                        // console.log('finished')
                        hex.userData.delay = Math.ceil(Math.random() * this.maxInDelay)
                        hex.userData.direction = -1
                        hex.userData.minScale = (Math.random() * 0.8) + 0.0001
                    }
                }
                if (direction === -1) {
                    // if(index === 0){
                    //     console.log('Dezoom')
                    // }
                    let nextScale = minScale
                    if (currentScale > minScale && hex.userData.delay === 0) {
                        nextScale = (currentScale - step) - (hex.userData.acceleration * step)
                        // nextScale = currentScale - 0.001
                    }
                    if (nextScale < minScale) {
                        nextScale = minScale
                    }
                    // console.log("DeZoom next scale "+nextScale)
        
                    if (hex.userData.delay > 0) {
                        hex.userData.delay -= 1
                    } else {
                        hex.scale.set(nextScale, nextScale, nextScale)
                    }
                    if (currentScale === minScale) {
                        // console.log('finished')
                        hex.userData.delay = Math.ceil(Math.random() * this.maxInDelay)
                        hex.userData.direction = 1
                        // hex.userData.minScale = (Math.random() * 0.8) + 0.0005
                    }
                }
            })
        }
        
        public maxInDelay = 5
        
        
        createHexagonBackground() {
            const hexagonPerline = [2, 3, 4, 3, 4, 3, 4, 3, 2, 1]
        
            let linePosY = 0
            const ySpace = .046
            const xSpace = .16
            this.getHexagonGeometry()
            this.hexagonGeometry.computeBoundingBox()
            const hexagonWidth = this.getHexagonGeometry().boundingBox!.max.x - this.getHexagonGeometry().boundingBox!.min.x
            const hexagonHeight = this.getHexagonGeometry().boundingBox!.max.y - this.getHexagonGeometry().boundingBox!.min.y
        
            for (let j = 0; j < hexagonPerline.length; j++) {
                const count = hexagonPerline[j];
                const line = new THREE.Group()
                line.name = 'line-' + j
                const totalLineWidth = (count * hexagonWidth) + (xSpace - hexagonWidth) * (count - 1)
                let meshPosX = 0
                for (let i = 0; i < count; i++) {
                    const mesh = new THREE.Mesh(this.getHexagonGeometry(), this.materials.silver)
                    mesh.name = 'hexagon-line-' + j + '-x-' + i
                    mesh.userData.delay = Math.ceil(Math.random() * this.maxInDelay)
                    mesh.userData.acceleration = Math.random() + 1
                    mesh.userData.direction = ((i + j) % 2 === 0) ? 1 : -1
        
                    mesh.position.set(meshPosX, linePosY, 0)
                    line.add(mesh)
                    meshPosX += xSpace
                    mesh.scale.set(0, 0, 0)
                    this.hexagons.push(mesh)
                }
                line.position.x = (-totalLineWidth / 2) + (hexagonWidth / 2)
                line.position.y = (((hexagonHeight / 2) * hexagonPerline.length) / 2)
                linePosY -= ySpace
                this.lines.add(line);
            }
            this.lines.position.z = -.015
            this.scene.add(this.lines)
            this.fitCameraToObject(this.lines, this.fit)
        }
        
        createShield() {
            const shape = new THREE.Shape();
            const x = 0, y = 0
            shape.moveTo(x, y);
            shape.bezierCurveTo(-0.25, 0.17, -.5, .6, -.5, 1);
            shape.bezierCurveTo(-0.18, 1.05, -0.08, 1.06, 0, 1.2);
            shape.bezierCurveTo(0.08, 1.06, 0.18, 1.05, .5, 1);
            shape.bezierCurveTo(.5, .6, 0.25, 0.17, 0, 0);
        
        
            const extrudeSettings = {
                curveSegments: 50,
                steps: 20,
                depth: .15,
                bevelEnabled: true,
                bevelThickness: .009,
                bevelSize: .1,
                bevelOffset: 0,
                bevelSegments: 20
            };
            const lgeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
            const mesh = new THREE.Mesh(lgeometry, this.materials.silver);
            const scale = 0.16
            mesh.position.y = -0.095
            mesh.position.z = 0.005
            mesh.scale.set(scale, scale, scale)
        
            const extrudeSettings2 = {
                curveSegments: 50,
                steps: 20,
                depth: .05,
                bevelEnabled: true,
                bevelThickness: .009,
                bevelSize: .1,
                bevelOffset: 0,
                bevelSegments: 20
            };
            const lgeometry2 = new THREE.ExtrudeGeometry(shape, extrudeSettings2);
            const contour = new THREE.Mesh(lgeometry2, this.materials.gold3);
            // const rem = mesh.clone()
            // mesh.position.z = -0.02
            // mesh.position.y = -0.17
            // mesh.scale.set(0.32, 0.32, 0.32)
            const scale2 = 0.2
            contour.position.y = -0.12
            contour.position.z = 0.01
            contour.scale.set(scale2, scale2, scale2)
            this.shield.position.z = 0.13
            this.shield.add(contour, mesh)
            // sh
            // const contour = CSG.subtract(contour, mesh)
            // contour.scale.set(1.2, 1.2, 1.2)
            this.scene.add(this.shield)
        }
        
        public shield = new THREE.Group()
        
        createLogo() {
            const shape = new THREE.Shape();
            shape.moveTo(0, 0);
            shape.lineTo(-0.5, 1)
            shape.bezierCurveTo(
                -0.25, 1.05,
                -.2, .7,
                0, .37
            );
            shape.lineTo(0.25, 0.75)
            shape.lineTo(0.5, 0.37)
            shape.bezierCurveTo(
                .7, .7,
                0.75, 1.05,
                1, 1
            );
            shape.lineTo(.5, 0)
            shape.lineTo(.25, 0.35)
            // this.camera.position.z = 0.8
            const extrudeSettings = {
                curveSegments: 50,
                steps: 4,
                depth: .2,
                bevelEnabled: false,
                bevelThickness: .009,
                bevelSize: .05,
                bevelOffset: 0,
                bevelSegments: 5
            };
            const lgeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
            const W = new THREE.Mesh(lgeometry, this.materials.green);
            W.position.y = -0.19
            const scale = 0.33
            W.scale.set(scale, scale, scale)
            const shape2 = new THREE.Shape();
            shape2.moveTo(0, 0);
            shape2.bezierCurveTo(-0.2, -0.01, -0.3, 0.4, -0.7, 1);
            shape2.bezierCurveTo(-0.4, 1.1, -0.05, .2, 0, 0);
        
            const extrudeSettings2 = {
                curveSegments: 50,
                steps: 4,
                depth: .1,
                bevelEnabled: false,
                bevelThickness: .009,
                bevelSize: .05,
                bevelOffset: 0,
                bevelSegments: 5
            };
            const Ageometry = new THREE.ExtrudeGeometry(shape2, extrudeSettings2);
            const A = new THREE.Mesh(Ageometry, this.materials.pink2);
            A.position.y = -0.19
            A.scale.set(scale, scale, scale)
            W.position.x = -.2
            A.position.x = .24
            A.position.z = .01
            const cylinder = new THREE.CylinderGeometry(.03, .03, .03, 32);
            const point = new THREE.Mesh(cylinder, this.materials.pink);
            point.rotation.x = this.degrees_to_radians(90)
            point.position.set(.08, -0.1, 0)
            this.logo = new THREE.Group()
            this.logo.add(W, A, point)
            const s = 0.3
            this.logo.scale.set(s, s, s)
            this.logo.position.z = 0.027
            this.logo.position.y = 0.03
            this.logo.position.x = 0.01
        
            this.logo2 = this.logo.clone()
            this.logo2.position.z = 0.005
            this.logo2.position.x = -0.01
        
            this.logo2.rotation.y = this.degrees_to_radians(180)
        
        
            this.shield.add(this.logo, this.logo2)
            // this.scene.add(this.logo)
        }
        
        logo = new THREE.Group()
        logo2: any = null
        
        
        loadBranches() {
            // const loader = new SVGLoader();
        
            const branches = [
                {name: 'Auto', icon: 'icon'},
                {name: 'Santé', icon: 'icon'},
                {name: 'Habitation', icon: 'icon'},
                {name: 'Accident de la vie', icon: 'icon'},
                {name: 'Voyage', icon: 'icon'},
                {name: 'Scolaire', icon: 'icon'},
            ]
            const minStep = 0.6
            const step = (2 * Math.PI) / branches.length
            const radius = 0.28
            const currentStep = 0
            const torusGeometry = new THREE.TorusGeometry(radius, .001, 8, 64)
            const torus = new THREE.Mesh(torusGeometry, this.materials.gold3)
            torus.rotation.x = this.degrees_to_radians(90)
            // this.scene.add(torus)
            const cylinder = new THREE.CylinderGeometry(0.02, 0.02, .002, 32)
            this.ring.add(torus)
            branches.forEach((branch, index) => {
                const mesh = new THREE.Mesh(cylinder, this.materials.black)
                // const icon = new THREE.Group()
                // icon.add(mesh)
                mesh.position.x = Math.sin((step * index)) * radius
                mesh.position.z = Math.cos((step * index)) * radius
                mesh.rotation.x = this.degrees_to_radians(90)
                mesh.rotation.z = -step * index
                // icon.rotation.y = step * index
                this.ring.add(mesh)
            })
            this.ring.rotation.x = this.degrees_to_radians(10)
            this.scene.add(this.ring)
        }
        
        ring = new THREE.Group()
        
        tick() {
            const elapsed = this.clock.getElapsedTime()
            const delta = elapsed - this.lastTick
            this.camera.updateMatrix()
            this.camera.updateProjectionMatrix();
            this.camera.lookAt(this.lines.position)
            // this.controls.update()
        
            this.animateHexagons()
            this.camera.position.x = Math.cos(elapsed) * 0.1
            this.camera.position.y = Math.sin(elapsed) * 0.1
            this.shield.rotation.y += 0.03
            this.ring.rotation.y -= 0.02
            this.ring.rotation.x = -this.camera.position.y * 3.5
        
            var vector = new THREE.Vector3(this.mouse.x, this.mouse.y, 1);
            this.raycaster.setFromCamera(this.mouse, this.camera);
            this.intersects = this.raycaster.intersectObjects(this.scene.children, true);
            this.lastTick = elapsed
            this.renderer.render(this.scene, this.camera)
            this.requestAnimationFrameId = requestAnimationFrame(this.tick)
        }
        
        degrees_to_radians(degrees: number): number {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }
        
        isMobile() {
            let check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || (window as any).opera);
            return check;
        };
        
        fitCameraToObject(object?: any, offset = 3) {
        
            // if (!object) {
            //     return
            // }
            const camera = this.camera;
            const controls = this.controls
            object = object as any || this.laurier as any
        
            offset = offset || 2.5;
        
            if (!this.isMobile()) {
                // offset -= 1
            }
            // object.computeBoundingBox()
        
            // const boundingBox: any = object.boundingBox;
            const boundingBox = new THREE.Box3();
            boundingBox.setFromObject(object);
        
        
            let center = new THREE.Vector3(), size = new THREE.Vector3()
            // console.log(boundingBox.min.x + ' ' + boundingBox.max.x)
            // center.x = (boundingBox.max.x - boundingBox.min.x)/2 + boundingBox.min.x
            // center.y = (boundingBox.max.y - boundingBox.min.y)/2 + boundingBox.min.y
            // center.z = (boundingBox.max.z - boundingBox.min.z)/2 + boundingBox.min.z
            boundingBox.getCenter(center)
            boundingBox.getSize(size);
        
        
            // get the max side of the bounding box (fits to width OR height as needed )
            const maxDim = Math.max(size.x, size.y, size.z);
            const fov = camera.fov * (Math.PI / 180);
            let cameraZ = Math.abs(maxDim / 4 * Math.tan(fov * 2));
        
            offset += 1
            cameraZ *= offset; // zoom out a little so that objects don't fill the screen
        
            camera.position.z = cameraZ;
            // camera.position.y = object.position.y;
        
            const minZ = boundingBox.min.z;
            const cameraToFarEdge = (minZ < 0) ? -minZ + cameraZ : cameraZ - minZ;
        
            // camera.far = cameraToFarEdge * 3;
            camera.updateProjectionMatrix();
        
            if (controls) {
        
                // set camera to rotate around center of loaded object
                controls.target = center;
        
                // prevent camera from zooming out far enough to create far plane cutoff
                // controls.maxDistance = cameraToFarEdge * 2;
        
                controls.saveState();
        
            } else {
        
                camera.lookAt(center)
        
            }
        }


    }
</script>
<style>
    #wa-3d-logo {
       width: 100%;
       height: 100%;
    }

    canvas {
       width: 100% !important;
       height: 100% !important;
    }

    @media screen and (max-width: 650px) {
       #wa-3d-logo {
           width: 100%;
           height: 200px!important;
           min-height: 200px!important;
       }
    }
</style>
