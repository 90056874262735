import { render, staticRenderFns } from "./NumberQuestion.vue?vue&type=template&id=2813c016&scoped=true"
import script from "./NumberQuestion.vue?vue&type=script&lang=ts"
export * from "./NumberQuestion.vue?vue&type=script&lang=ts"
import style0 from "./NumberQuestion.vue?vue&type=style&index=0&id=2813c016&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2813c016",
  null
  
)

export default component.exports