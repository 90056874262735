<template>
  <div class="d-flex align-items-stretch flex-shrink-0">
    <div class="d-flex align-items-stretch flex-shrink-0">

      <b-dropdown no-caret no-flip right class="mx-2" size="sm" tag="div"
                  toggle-class="topbar-item text-decoration-none" variant="link" v-if="$store.state.tenants.length > 1">
        <template v-slot:button-content>
          <div class="btn btn-sm btn-light-primary btn-dropdown btn-lg mx-5 pulse pulse-primary">
                        <span v-if="$store.state.currentTenant">
                            {{ $store.state.currentTenant.label }}
                        </span>
          </div>
        </template>
        <b-dropdown-item class="p-2" tag="div" :key="tenant.uuid" v-for="tenant in $store.state.tenants"
                         @click="toggleTenant(tenant)">
          {{ tenant.label }}
        </b-dropdown-item>
      </b-dropdown>

      <!--            END SHOPPING CART-->

      <!--
          <div class="d-flex align-items-center ms-1 ms-lg-3">
              <div class="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
                   id="kt_drawer_chat_toggle">

                  <span class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
              </div>
          </div> -->


      <b-dropdown no-caret no-flip right size="sm" tag="div" toggle-class="topbar-item text-decoration-none"
                  variant="link">
        <template v-slot:button-content>
          <div class="btn btn-icon btn-clean btn-dropdown btn-lg me-5 pulse pulse-danger">
                        <span class="svg-icon svg-icon-1">
                          <i class="fad fa-bell"
                             :class="{'text-danger' : $store.state.notifications.length > 0    }"></i>
                        </span>
            <span class="pulse-ring" v-if="$store.state.notifications.length > 0"></span>
          </div>
        </template>
        <b-dropdown-text class="min-w-md-350px p-0" tag="div">
          <div :style="{ 'background-image': 'url(' + require('./../../assets/images/pattern-1.jpg') + ')' }"
               class="d-flex flex-column bgi-no-repeat rounded-top">
            <h3 class="text-white fw-bold px-9 mt-10 mb-6">Notifications
              <span class="fs-8 opacity-75 ps-3">{{ $store.state.notifications.length }}</span>
            </h3>
            <!--            <ul class="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">-->
            <!--              <li class="nav-item">-->
            <!--                <a class="nav-link text-white opacity-75 opacity-state-100 pb-4">Comparateur</a>-->
            <!--              </li>-->
            <!--              <li class="nav-item">-->
            <!--                <a class="nav-link text-white opacity-75 opacity-state-100 pb-4">Production</a>-->
            <!--              </li>-->
            <!--              &lt;!&ndash;                  <li class="nav-item">&ndash;&gt;-->
            <!--              &lt;!&ndash;                    <a :class="{ 'active': notificationTabIndex === 1 }"&ndash;&gt;-->
            <!--              &lt;!&ndash;                       @click.prevent="notificationTabIndex = 1"&ndash;&gt;-->
            <!--              &lt;!&ndash;                       class="nav-link text-white opacity-75 opacity-state-100 pb-4">Updates</a>&ndash;&gt;-->
            <!--              &lt;!&ndash;                  </li>&ndash;&gt;-->
            <!--              &lt;!&ndash;                  <li class="nav-item">&ndash;&gt;-->
            <!--              &lt;!&ndash;                    <a :class="{ 'active': notificationTabIndex === 2 }"&ndash;&gt;-->
            <!--              &lt;!&ndash;                       @click.prevent="notificationTabIndex = 2"&ndash;&gt;-->
            <!--              &lt;!&ndash;                       class="nav-link text-white opacity-75 opacity-state-100 pb-4">Logs</a>&ndash;&gt;-->
            <!--              &lt;!&ndash;                  </li>&ndash;&gt;-->
            <!--            </ul>-->
          </div>

          <div class="card">
            <div class="card-body p-0">
              <div class="scroll-y mh-325px my-5">
                <div class="d-flex flex-stack py-4 px-4 bg-hover-light-primary" v-for="notification in $store.state.notifications" @click="notificationClick(notification)">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-35px me-4">
                      <span class="symbol-label bg-light-primary">
                        <span class="svg-icon svg-icon-2 svg-icon-primary">
                          <img :src="notification.photo" alt="" v-if="isUrl(notification.photo)">
              <img :src="uploadRoot + notification.photo" alt="" v-else-if="isFile(notification.photo)" class="w-25px h-25px">
              <i v-else-if="isIcon(notification.photo)" :class="notification.photo"
                 class="icon fs-3x px-2 text-dark"></i>
              <i v-else class="fad fa-sync text-dark"></i>
                        </span>
                      </span>
                    </div>
                    <div class="mb-0 me-2">
                      <div class="fs-6 text-gray-800 text-hover-primary fw-bolder">{{ notification.title }}
                      </div>
                      <div class="text-gray-400 fw-bold fs-7">{{ notification.content }}</div>
                    </div>
                  </div>
                  <!--                  <span class="badge badge-light fs-8">1 hr</span>-->
                </div>
              </div>
              <div class="py-3 text-center border-top">
                <a class="btn btn-color-gray-600 btn-active-color-primary">View All
                  <span class="svg-icon svg-icon-5">
                                                  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                                                       xmlns="http://www.w3.org/2000/svg"
                                                       xmlns:xlink="http://www.w3.org/1999/xlink">
                                                      <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                                          <polygon points="0 0 24 0 24 24 0 24"/>
                                                          <rect fill="#000000" height="9" opacity="0.5" rx="1"
                                                                transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                                                width="2" x="7.5" y="7.5"/>
                                                          <path
                                                              d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                                              fill="#000000" fill-rule="nonzero"
                                                              transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"/>
                                                      </g>
                                                  </svg>
                                              </span>
                </a>
              </div>
            </div>
          </div>
          <!--              <b-tabs>-->
          <!--                <b-tab :active="notificationTabIndex === 0" :key="'alerts'">-->

          <!--                </b-tab>-->
          <!--                <b-tab :active="notificationTabIndex === 1" :key="'updates'" role="tabpanel">-->
          <!--                  <div class="d-flex flex-column px-9">-->
          <!--                    <div class="pt-10 pb-0">-->
          <!--                      <h3 class="text-dark text-center fw-bolder">Get Pro Access</h3>-->
          <!--                      <div class="text-center text-gray-600 fw-bold pt-1">Outlines keep you-->
          <!--                        honest. They stoping you from amazing poorly about drive-->
          <!--                      </div>-->
          <!--                      <div class="text-center mt-5 mb-9">-->
          <!--                        <a class="btn btn-sm btn-primary px-6" data-bs-target="#kt_modal_upgrade_plan"-->
          <!--                           data-bs-toggle="modal" href="#">Upgrade</a>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    &lt;!&ndash; <img alt="metronic" class="mh-200px"-->
          <!--                         src="./../../assets/media/svg/illustrations/work.svg"/> &ndash;&gt;-->
          <!--                  </div>-->
          <!--                </b-tab>-->
          <!--                <b-tab :active="notificationTabIndex === 2" :key="'logs'">-->
          <!--                  <div class="scroll-y mh-325px my-5 px-8">-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-success me-4">200 OK</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">New-->
          <!--                          order</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">Just now</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-danger me-4">500 ERR</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">New-->
          <!--                          customer</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">2 hrs</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-success me-4">200 OK</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">Payment-->
          <!--                          process</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">5 hrs</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-warning me-4">300 WRN</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">Search-->
          <!--                          query</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">2 days</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-success me-4">200 OK</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">API-->
          <!--                          connection</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">1 week</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-success me-4">200 OK</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">Database-->
          <!--                          restore</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">Mar 5</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-warning me-4">300 WRN</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">System-->
          <!--                          update</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">May 15</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-warning me-4">300 WRN</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">Server-->
          <!--                          OS-->
          <!--                          update</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">Apr 3</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-warning me-4">300 WRN</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">API-->
          <!--                          rollback</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">Jun 30</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-danger me-4">500 ERR</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">Refund-->
          <!--                          process</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">Jul 10</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-danger me-4">500 ERR</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">Withdrawal-->
          <!--                          process</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">Sep 10</span>-->
          <!--                    </div>-->
          <!--                    <div class="d-flex flex-stack py-4">-->
          <!--                      <div class="d-flex align-items-center me-2">-->
          <!--                        <span class="w-70px badge badge-light-danger me-4">500 ERR</span>-->
          <!--                        <a class="text-gray-800 text-hover-primary fw-bold" href="#">Mail-->
          <!--                          tasks</a>-->
          <!--                      </div>-->
          <!--                      <span class="badge badge-light fs-8">Dec 10</span>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <div class="py-3 text-center border-top">-->
          <!--                    <a class="btn btn-color-gray-600 btn-active-color-primary"-->
          <!--                       href="pages/profile/activity.html">View All-->
          <!--                      <span class="svg-icon svg-icon-5">-->
          <!--                                        <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"-->
          <!--                                             xmlns="http://www.w3.org/2000/svg"-->
          <!--                                             xmlns:xlink="http://www.w3.org/1999/xlink">-->
          <!--                                            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">-->
          <!--                                                <polygon points="0 0 24 0 24 24 0 24" />-->
          <!--                                                <rect fill="#000000" height="9" opacity="0.5" rx="1"-->
          <!--                                                      transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"-->
          <!--                                                      width="2" x="7.5" y="7.5" />-->
          <!--                                                <path-->
          <!--                                                    d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"-->
          <!--                                                    fill="#000000" fill-rule="nonzero"-->
          <!--                                                    transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />-->
          <!--                                            </g>-->
          <!--                                        </svg>-->
          <!--                                    </span>-->
          <!--                    </a>-->
          <!--                  </div>-->
          <!--                </b-tab>-->
          <!--              </b-tabs>-->
        </b-dropdown-text>
      </b-dropdown>

      <b-dropdown no-caret no-flip right size="sm" tag="div" toggle-class="topbar-item text-decoration-none"
                  variant="link">
        <template v-slot:button-content>
          <div class="cursor-pointer symbol symbol-30px symbol-md-40px">
            <img :alt="user.email" :src="uploadRoot + user.photo" v-if="user && user.photo"/>
            <i class="fad fa-user fs-3x" v-else></i>
          </div>
        </template>
        <b-dropdown-text class="p-0" tag="div">
          <div class="menu-gray-600 fw-bold py-4 fs-6 w-275px">
            <div class="menu-item px-3">
              <div class="menu-content d-flex align-items-center px-3">
                <div class="symbol symbol-50px me-5">
                  <img :alt="user.email" :src="uploadRoot + user.photo" v-if="user && user.photo"/>
                  <i class="fad fa-user fs-3x" v-else></i>
                </div>
                <div class="d-flex flex-column">
                  <div class="fw-bolder d-flex align-items-center fs-7">
                    <template v-if="user">
                      {{ user.name }} {{ user.prename }}
                    </template>
                    <!--                                        <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span>-->
                  </div>
                  <a class="fw-bold text-muted text-hover-primary fs-7" href="#" v-if="user">
                    {{ user.email }}
                  </a>
                </div>
              </div>
            </div>
            <div class="separator my-2"></div>
            <div class="menu-item px-5">
              <router-link :to="{ name: 'home' }" class="menu-link px-5">
                Mon compte
              </router-link>
            </div>
            <div class="menu-item px-5">
              <router-link :to="{ name: 'home' }" class="menu-link px-5  d-flex justify-content-between">
                <span class="menu-text">Mes Tâches</span>
                <span class="menu-badge">
                                    <span class="badge badge-light-danger badge-circle fw-bolder fs-7">
                                        0
                                    </span>
                                </span>
              </router-link>
            </div>
            <div class="separator my-2"></div>
            <div class="menu-item px-5">
              <router-link :to="{ name: 'home' }" class="menu-link px-5">
                Configuration
              </router-link>
            </div>
            <div class="menu-item px-5">
              <div class="menu-link px-5">
                                <span
                                    class="menu-title position-relative d-flex align-items-center justify-content-between">
                                    Langue
                                    <b-dropdown no-caret no-flip right size="sm" tag="div"
                                                toggle-class="topbar-item text-decoration-none" variant="link">
                                        <template v-slot:button-content>
                                            <span
                                                class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                                                Français
                                                <img class="w-15px h-15px rounded-1 ms-2"
                                                     src="./../../assets/media/flags/france.svg"/>
                                            </span>
                                        </template>
                                        <b-dropdown-text class="p-0" tag="div">
                                            asset
                                        </b-dropdown-text>
                                    </b-dropdown>

                                </span>
              </div>
              <!--                            <div class="menu-sub menu-sub-dropdown w-175px py-4">-->
              <!--                                <div class="menu-item px-3">-->
              <!--                                    <a class="menu-link d-flex px-5 active" href="account/settings.html">-->
              <!--                        															<span class="symbol symbol-20px me-4">-->
              <!--                        																<img alt="metronic"-->
              <!--                                                                                             class="rounded-1"-->
              <!--                                                                                             src="./../../assets/media/flags/united-states.svg"/>-->
              <!--                        															</span>English</a>-->
              <!--                                </div>-->
              <!--                                <div class="menu-item px-3">-->
              <!--                                    <a class="menu-link d-flex px-5" href="account/settings.html">-->
              <!--                        															<span class="symbol symbol-20px me-4">-->
              <!--                        																<img alt="metronic"-->
              <!--                                                                                             class="rounded-1"-->
              <!--                                                                                             src="./../../assets/media/flags/spain.svg"/>-->
              <!--                        															</span>Spanish</a>-->
              <!--                                </div>-->
              <!--                                <div class="menu-item px-3">-->
              <!--                                    <a class="menu-link d-flex px-5" href="account/settings.html">-->
              <!--                        															<span class="symbol symbol-20px me-4">-->
              <!--                        																<img alt="metronic"-->
              <!--                                                                                             class="rounded-1"-->
              <!--                                                                                             src="./../../assets/media/flags/germany.svg"/>-->
              <!--                        															</span>German</a>-->
              <!--                                </div>-->
              <!--                                <div class="menu-item px-3">-->
              <!--                                    <a class="menu-link d-flex px-5" href="account/settings.html">-->
              <!--                        															<span class="symbol symbol-20px me-4">-->
              <!--                        																<img alt="metronic"-->
              <!--                                                                                             class="rounded-1"-->
              <!--                                                                                             src="./../../assets/media/flags/japan.svg"/>-->
              <!--                        															</span>Japanese</a>-->
              <!--                                </div>-->
              <!--                                <div class="menu-item px-3">-->
              <!--                                    <a class="menu-link d-flex px-5" href="account/settings.html">-->
              <!--                        															<span class="symbol symbol-20px me-4">-->
              <!--                        																<img alt="metronic"-->
              <!--                                                                                             class="rounded-1"-->
              <!--                                                                                             src="./../../assets/media/flags/france.svg"/>-->
              <!--                        															</span>French</a>-->
              <!--                                </div>-->
              <!--                            </div>-->
            </div>
            <div class="menu-item px-5">
              <a @click.prevent="logout()" class="menu-link px-5">
                Déconnexion
              </a>
            </div>
          </div>
        </b-dropdown-text>
      </b-dropdown>

      <div class="d-flex align-items-center d-lg-none ms-2 me-n3" data-bs-toggle="tooltip" title="Show header menu">
        <div class="btn btn-icon btn-active-light-primary" id="kt_header_menu_mobile_toggle">
                    <span class="svg-icon svg-icon-1">
                        <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                             xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                <rect height="24" width="24" x="0" y="0"/>
                                <path clip-rule="evenodd"
                                      d="M22 11.5C22 12.3284 21.3284 13 20.5 13H3.5C2.6716 13 2 12.3284 2 11.5C2 10.6716 2.6716 10 3.5 10H20.5C21.3284 10 22 10.6716 22 11.5Z"
                                      fill="black" fill-rule="evenodd"/>
                                <path clip-rule="evenodd"
                                      d="M14.5 20C15.3284 20 16 19.3284 16 18.5C16 17.6716 15.3284 17 14.5 17H3.5C2.6716 17 2 17.6716 2 18.5C2 19.3284 2.6716 20 3.5 20H14.5ZM8.5 6C9.3284 6 10 5.32843 10 4.5C10 3.67157 9.3284 3 8.5 3H3.5C2.6716 3 2 3.67157 2 4.5C2 5.32843 2.6716 6 3.5 6H8.5Z"
                                      fill="black" fill-rule="evenodd" opacity="0.5"/>
                            </g>
                        </svg>
                    </span>
        </div>
      </div>
    </div>


  </div>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import {auth} from "@/services/Auth";
import User from "@/entity/User";
import Tenant from '@/entity/Tenant';
import Notification from '@/entity/Notification';
import store from '@/store';

@Component({})
export default class RightMenu extends Vue {
  public notificationTabIndex = 1;
  public person = false;
  uploadRoot = api.uploadRoot
  user: User | null = null

  logout(): void {
    auth.logout();
  }

  notificationClick(notification: Notification) {

    if (notification.route) {
      this.$router.push(notification.route)
    } else if (notification.url) {
      window.location.href = notification.url
    }
  }

  isFile(photo: string) {
    return photo && photo.includes('.') && photo.split('.').length === 2
  }

  isUrl(photo: string) {
    return photo && photo.toLowerCase().startsWith('http')
  }

  isIcon(photo: string) {
    return photo && photo.startsWith('fa')
  }

  loadTenants() {
    const tenants: any = localStorage.getItem('tenants') ? JSON.parse(localStorage.getItem('tenants')!) : []
    const current: any = localStorage.getItem('currentTenant') ? JSON.parse(localStorage.getItem('currentTenant')!) : null
    const formatted = []
    for (let tenant of tenants) {
      formatted.push(new Tenant(tenant))
    }
    store.state.tenants = formatted
    if (current) {
      store.state.currentTenant = new Tenant(current)
    } else {
      store.state.currentTenant = new Tenant({code: 'wiassur', label: 'WiASSUR'})
    }
  }


  toggleTenant(tenant: Tenant) {
    localStorage.setItem('currentTenant', JSON.stringify(tenant))
    store.state.currentTenant = tenant
    window.location.reload()
  }

  mounted(): void {
    const user = auth.getCurrentUser()
    if (user) {
      this.user = user
    }
    this.loadTenants()
    // this.user = this.$store.getters.getUser();
    // if (this.user && localStorage.person) {
    //     this.person = true;
    //     this.user = JSON.parse(localStorage.person);
    // }
    // this.load()

    // console.log(KTMenu);
    // KTMenu.init();
  }

  async load(): Promise<void> {
    // const res = await api.get('api/subscription/list');
    // res.subscriptions.forEach((item: Subscription) => {
    //     this.subscriptions.push(new Subscription(item));
    // })
  }
}
</script>
<style scoped></style>
