<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <Base>
    <template v-slot:content>

      <div class="card mb-5 mb-xl-10">
        <div class="card-body pt-9 pb-0">
          <Loading v-if="isLoading"></Loading>
          <div class="d-flex flex-wrap flex-sm-nowrap mb-3" v-else-if="partner">
            <div class="me-7 mb-4">
              <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img :src="uploadRoot + partner.photo" alt="image" class="object-cover"
                     v-if="partner && partner.photo">
                <div
                    class="w-100px h-100px h-lg-175px w-lg-160px d-flex align-items-center justify-content-center shadow-xs rounded"
                    v-else>
                  <i class="fad fa-user fs-5x"></i>
                  <!--                                    <img  alt="image" class="object-cover" style="opacity: 0">-->
                </div>
                <!--                <div :class="{-->
                <!--                                'bg-success': contract.status === CONSTANTS.CONTRACT_STATUS.ONGOING,-->
                <!--                                'bg-warning': contract.status === CONSTANTS.CONTRACT_STATUS.QUOTATION,-->
                <!--                                'bg-danger': contract.status === CONSTANTS.CONTRACT_STATUS.TERMINATED,-->
                <!--                                'bg-primary': contract.status === CONSTANTS.CONTRACT_STATUS.SUSPENDED-->
                <!--                            }"-->
                <!--                     class="position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-white h-20px w-20px">-->
                <!--                </div>-->
              </div>
            </div>
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-2">
                    <div class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                      {{ partner.name }}
                    </div>
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-2 pe-2">
                    {{ partner.code }}
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-2 pe-2">

                  </div>

                </div>
                <div>
                  <div class="btn btn-primary" v-b-modal="'editPartner'">
                    Modifier
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap flex-stack">
                <div class="d-flex flex-column flex-grow-1 pe-8">
                  <div class="d-flex flex-wrap">
                    <div
                        class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                                            <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                                <i class="fad fa-coins"></i>
                                            </span>
                        <div class="fs-2 fw-bolder counted" data-kt-countup="true"
                             data-kt-countup-prefix="$" data-kt-countup-value="4500">
                          ---
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Solde</div>
                    </div>


                    <div
                        class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                                            <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                                <i class="fad fa-coins"></i>
                                            </span>
                        <div class="fs-2 fw-bolder counted" data-kt-countup="true"
                             data-kt-countup-prefix="$" data-kt-countup-value="4500">
                          ---
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Commission</div>
                    </div>

                    <div
                        class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div class="d-flex align-items-center">
                                            <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                                <i class="fad fa-coins"></i>
                                            </span>
                        <div class="fs-2 fw-bolder counted">
                          ---
                        </div>
                      </div>
                      <div class="fw-bold fs-6 text-gray-400">Valeur Client</div>
                    </div>
                  </div>
                  <!--end::Stats-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Info-->
          </div>

          <b-tabs active-nav-item-class="" active-tab-class="" nav-wrapper-class="nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap
                            flex-grow-1" v-model="tabIndex">
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-layer-group fs-2 me-2"></i>
                  {{ trans('Contrats') }}
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-building fs-2 me-2"></i>
                  {{ trans('Agences') }}
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadUsers">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-users fs-2 me-2"></i>
                  {{ trans('Utilisateurs') }}
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="initCommission">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-file-contract fs-2 me-2"></i>
                  Commissions
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-coins fs-2 me-2"></i>
                  Paiements
                </div>
              </template>
            </b-tab>
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'" @click="loadSurveys">
              <template #title>
                <div class="d-flex align-items-center">
                  <i class="fad fa-building fs-2 me-2"></i>
                  {{ trans('Liens') }}
                </div>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </div>


      <div class="card" key="avenants">
        <div class="card-body">
          <!--          <Loading v-if="isLoading"></Loading>-->
          <template>
            <div v-if="tabIndex === 0">
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Type</th>
                  <th>Période</th>
                  <th>Montant/Reste</th>
                  <th>Apporteur</th>
                  <th>Agent</th>
                  <th>Statut</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loadingContracts">
                  <td colspan="7">
                    <Loading/>
                  </td>
                </tr>
                <tr v-for="contract in contracts" :key="'c_'+contract.uuid">
                  <td>
                    <div>

                      <div class="text-gray-400 fw-bolder d-flex align-items-center">
                        <i :class="'fad fs-2 ' + contract.branch.icon" class="me-2 text-gray-400"></i>
                        {{ contract.branch.label }} ▪ {{ contract.branch.category.label }}
                      </div>
                      <router-link :to="{ name : 'contract-show', params: { contractUuid : contract.uuid } } "
                                   class="d-flex align-items-center fw-bolder">
                        {{ contract.insurer.label }} ▪ {{ contract.number }}
                      </router-link>

                      <!--                      <div class="me-2">-->
                      <!--                        Créer le {{ readableDate(contract.createdAt, 'dMY H:i') }}-->
                      <!--                      </div>-->
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="fw-bolder align-items-center">
                      <div :title="readableDate(contract.startAt, 'dmY')" v-b-tooltip.hover
                           v-if="contract.startAt">
                        {{ readableDate(contract.startAt, 'dMY') }}
                      </div>
                      <div :title="readableDate(contract.expireAt, 'dmY')" v-b-tooltip.hover
                           v-if="contract.expireAt">
                        {{ readableDate(contract.expireAt, 'dMY') }}
                      </div>
                    </div>
                  </td>
                  <td class="text-end">
                    <span class="fw-bolder">
                      {{ currency(contract.primeTTC) }}
                    </span>
                    <br>
                    <span class="text-danger fw-bolder">
                      {{ currency(contract.balance, true) }}
                    </span>
                  </td>
                  <td>
                    <template v-if="contract.partner">
                      <div class="fw-bolder">
                        {{ contract.partner.name }}
                      </div>
                      <div class="text-gray-400 fw-bolder fs-8">
                        {{ contract.partner.code }}
                      </div>
                    </template>
                  </td>
                  <td>
                    <user-view v-model="contract.manager"></user-view>
                  </td>
                  <td>
                    <div>
                                            <span :class="{
                                                'badge-primary': contract.status === CONSTANTS.CONTRACT_STATUS.SUSPENDED,
                                                'badge-warning': contract.status === CONSTANTS.CONTRACT_STATUS.QUOTATION,
                                                'badge-success': contract.status === CONSTANTS.CONTRACT_STATUS.ONGOING,
                                                'badge-danger': contract.status === CONSTANTS.CONTRACT_STATUS.TERMINATED,
                                            }" class="badge">
                                                {{ contract.status }}
                                            </span>
                    </div>
                  </td>
                  <td>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="tabIndex === 1">

              <div class="d-flex justify-content-end">
                <div class="btn btn-primary" @click="createAgency" v-b-modal="'editAgency'">
                  <i class="fad fa-plus"></i>
                  Créer
                </div>
              </div>
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Nom</th>
                  <th>Code</th>
                  <th>Contact</th>
                  <th>Créer le</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="agency in partner.agencies">
                  <tr style="height: 1px" :key="'agency_'+agency.uuid">
                    <td>
                      {{ agency.name }}
                    </td>
                    <td>
                      {{ agency.code }}
                    </td>
                    <td>
                      {{ agency.contact }}
                    </td>
                    <td>
                      {{ readableDate(agency.createdAt, 'dmY H:i') }}
                    </td>

                    <td class="text-end">
                      <div class="d-flex justify-content-end">
                        <div class="btn btn-xs btn-icon btn-primary m-1" v-b-modal="'editAgency'"
                             @click="editAgency(agency)">
                          <i class="fad fa-edit"></i>
                        </div>
                        <div class="btn btn-xs btn-icon btn-danger m-1">
                          <i class="fad fa-ban"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <div v-if="tabIndex === 2">

              <div class="d-flex justify-content-end">
                <div class="btn btn-primary" @click="createUser">
                  <i class="fad fa-plus"></i>
                  Créer
                </div>
              </div>
              <Loading v-if="loadingUsers"/>
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Utilisateur</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Agence</th>
                  <th>Créer le</th>
                  <th>Derniere connexion</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="partner in users">
                  <tr style="height: 1px" :key="'p_'+partner.uuid">
                    <td>
                      {{ partner.name }}
                    </td>
                    <td>
                      {{ partner.email }}
                    </td>
                    <td>
                      {{ partner.contact }}
                    </td>
                    <td>
                      {{ getAgencyNameByCode(partner.code) }}
                    </td>
                    <td>
                      {{ readableDate(partner.createdAt, 'dmY H:i') }}
                    </td>

                    <td>
                      {{ readableDate(partner.lastToken, 'dmY H:i') }}
                    </td>

                    <td class="text-end">
                      <div class="d-flex justify-content-end">
                        <div class="btn btn-xs btn-icon btn-primary m-1" @click="editUser(partner)">
                          <i class="fad fa-edit"></i>
                        </div>
                        <div class="btn btn-xs btn-icon btn-danger m-1">
                          <i class="fad fa-ban"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <div v-if="tabIndex === 3">

            </div>
            <div v-if="tabIndex === 4">

              <table class="table table-stripped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Libellé</th>
                  <th>Initiateur</th>
                  <th>Montant</th>
                  <th>
                    Reçu
                  </th>
                </tr>
                </thead>
                <tbody>
                </tbody>
              </table>

            </div>
            <div v-if="tabIndex === 5">
              <div v-if="partner" class="bg-light-primary d-flex flex-wrap">

                <div v-for="survey in surveys" class="position-relative m-20 p-10 rounded" style="background: #e0338a"
                     :set="url = getUrl({ name: 'response-group-manager' , params:{surveySlug: survey.slug, rgUuid: 'new' }, query:{'source' : partner.code} })">
                  <!--                  <qr-code text="Hello World!" class="p-10 m-10 bg-white rounded position-relative"></qr-code>-->
                  <!--                  :logoSrc="getBranchLogoBySlug(survey.branch)" :logoMargin="10" :logoCornerRadius="0"-->
                  <a target="_blank" :href="url" class="text-center d-flex justify-content-center">
                    <img :src="getBranchLogoBySlug(survey.branch)" alt="" class=" w-80px"
                         style="filter:invert(100%)">
                  </a>
                  <div class=" fw-bolder text-inverse-primary text-uppercase my-5 w-100 text-center">
                    {{ getBranchLabelBySlug(survey.branch) }}
                  </div>
                  <a target="_blank" :href="url" class="text-center d-flex justify-content-center">
                    <vue-qr colorDark="black" colorLight="white" :autoColor="true" class=" rounded position-relative"
                            :text="url" :whiteMargin="true" :qid="survey.uuid" :size="300"></vue-qr>
                  </a>
                  <div>
                    <div class=" fw-bolder text-inverse-primary text-uppercase w-100 text-center mt-5">
                      ️ {{ survey.label }}
                    </div>
                    <!--                    <a target="_blank" :href="url" class="my-5">-->
                    <!--                      {{ url }}-->
                    <!--                    </a>-->
                  </div>
                </div>
              </div>

            </div>

          </template>
        </div>
      </div>

      <b-modal :title="trans('Créer un partenaire')" centered id="editPartner" ref="editPartner">
        <form>
          <div class="row" v-if="partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(partner.name)" v-model="partner.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(partner.code)" v-model="partner.code" v-uppercase>
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(partner.contact)" v-model="partner.contact">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{
                  trans('Methode de paiement autorisée')
                }}</label>
              <div class="d-flex flex-wrap  justify-content-between">
                <div v-for="method in methods" :key="'m_'+method.id" class="m-2 fw-bolder d-flex align-items-center">
                  <label class="form-check form-switch form-check-custom form-check-solid m-2">
                    <input :checked="isMethodActiveForPartner(method, partner)"
                           @change="toggleMethodForPartner(method, partner)" class="form-check-input"
                           type="checkbox"/>
                  </label>
                  {{ method.label }}
                </div>
              </div>

            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="savePartner" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal :title="trans('Créer une agence')" centered id="editAgency" ref="editAgency">
        <form>
          <div class="row" v-if="agency && partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Nom') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(agency.name)" v-model="agency.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="bg-light-primary"><strong class="text-primary fw-bolder">{{
                      partner.code
                    }}-</strong></b-input-group-text>
                </template>
                <b-form-input :id="'code'" :state="!helper.empty(agency.code)" v-model="agency.code" v-uppercase>
                </b-form-input>
              </b-input-group>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(agency.contact)" v-model="agency.contact">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{
                  trans('Methode de paiement autorisée')
                }}</label>
              <div class="d-flex flex-wrap  justify-content-between">
                <div v-for="method in methods" :key="'me_'+method.id" class="m-2 fw-bolder d-flex align-items-center">
                  <label class="form-check form-switch form-check-custom form-check-solid m-2">
                    <input :checked="isMethodActiveForPartner(method, agency)"
                           @change="toggleMethodForPartner(method, agency)" class="form-check-input"
                           type="checkbox"/>
                  </label>
                  {{ method.label }}
                </div>
              </div>

            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveAgency" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal :title="trans('Utilisateurs')" centered id="editUser" ref="editUser">
        <form>
          <div class="row" v-if="user && partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Noms') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(user.name)" v-model="user.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Email') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.email)" v-model="user.email">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Agence') }}</label>
              <select v-model="user.code" class="form-control form-select">
                <option :value="partner.code">{{ partner.name }}</option>
                <option :value="agency.code" v-for="agency in partner.agencies" :key="'ag_'+agency.uuid">{{
                    agency.name
                  }}
                </option>
              </select>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Mot de passe') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.password)" v-model="user.password">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.contact)" v-model="user.contact">
              </b-form-input>
            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="updatePartnerUser" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Contract from "@/entity/Contract";
import {api} from '@/services/Api';
import Loading from "@/components/Loading.vue";
import FileUpload from "@/components/FileUpload.vue";
import Avenant from "@/entity/Avenant";
import CONSTANTS from "@/Utils/constant";
import Item from "@/entity/Item";
import UserView from "@/components/UserView.vue";

import Property from "@/entity/Property";
import IncorporationDetail from "@/views/Contract/IncorporationDetail.vue";
import Printable from "@/entity/Printable";
import Swal from 'sweetalert2'
import Partner from "@/entity/Partner";
import PaymentMethod from "@/entity/PaymentMethod";
import BranchCategory from "@/entity/BranchCategory";
import Branch from "@/entity/Branch";
import Engine from "@/entity/Engine";
import Insurer from "@/entity/Insurer";
import EngineMethod from "@/entity/EngineMethod";
import {helper} from "@/services/Helper";
import Survey from '@/entity/Survey';
import Tenant from '@/entity/Tenant';

import VueQr from 'vue-qr'

@Component({
  components: {IncorporationDetail, FileUpload, Loading, Base, UserView,VueQr}
})
export default class PartnerAgency extends Vue {

  amount = 0
  coreEndpoint = api.core
  uploadRoot = api.uploadRoot
  tabIndex = 0
  isLoading = false
  avenant: any = null
  contracts: Contract[] = []
  methods: PaymentMethod[] = []
  partner: Partner | any = null
  user: Partner = new Partner()
  agency: Partner = new Partner()
  branchCategories: BranchCategory[] = []
  surveys: Survey[] = []
  tenants: Tenant[] = []

  getUrl(data: any) {
    const url = window.location.origin + this.$router.resolve(data).href
    return url
  }
  async updatePartnerUser() {
    // this.user.code = this.partner.code
    this.$store.commit('loading')
    const res = await api.post(api.auth, 'partner/api/edit', {partner: this.user});
    this.$store.commit('stopLoading');
    (this.$refs as any).editUser.hide()
    this.loadUsers()

  }

  getBranchLogoBySlug(slug: string) {
    for (let cat of this.branchCategories) {
      for (let branch of cat.branches) {
        if (branch.slug === slug) {
          return api.uploadRoot + branch.photo
        }
      }
    }
    return null
  }

  getBranchLabelBySlug(slug: string) {
    for (let cat of this.branchCategories) {
      for (let branch of cat.branches) {
        if (branch.slug === slug) {
          return cat.label + ' ▪️ ' + branch.label
          // return  branch.label
        }
      }
    }
    return slug;
  }


  async loadSurveys() {
    const res = await api.get(api.form, 'api/get/surveys/comparateur')
    if (res && res.data && res.data.surveys) {
      this.surveys = []
      res.data.surveys.forEach((s: Survey) => {
        const occ = new Survey(s)
        this.surveys.push(occ)
      })
      // this.categories = [];
      // res.data.categories.forEach((c: BranchCategory) => {
      //   this.categories.push(new BranchCategory(c))
      // })
      //
      // if (!this.current && this.categories.length > 0) {
      //   this.setCurrent(this.categories[0])
      // }
    }
  }

  async mounted() {
    this.loadPartner()
    this.loadContracts()
    this.loadPartnerMethods()
    this.branchCategories = await this.$store.getters.branchCategories()
    // await this.loadContract()
  }

  createAgency() {
    this.agency = new Partner()
    this.agency.parent = this.partner

  }

  editAgency(agency: Partner) {

    this.agency = new Partner(agency)
    this.agency.parent = this.partner
    if (this.agency.code.includes(this.partner.code)) {
      this.agency.code = this.agency.code.replace(this.partner.code, '')
    }
    this.agency.code = helper.slugify(this.agency.code, '')

  }

  initCommission() {
    if (this.branchCategories.length > 0) {
      if (this.branchCategories[0].branches.length > 0) {
        this.loadMethods(this.branchCategories[0].branches[0])
      }
    }
  }

  toggleMethodForPartner(method: PaymentMethod, partner: Partner) {
    const index = partner.paymentMethods.findIndex((p: PaymentMethod) => p.id === method.id)
    console.log(index)
    if (index >= 0) {
      partner.paymentMethods.splice(index, 1)
    } else {
      partner.paymentMethods.push(method)
    }
  }

  async savePartner() {
    this.$store.commit('loading')
    let partner = {...this.partner};
    if (partner.parent) {
      partner.parent = partner.parent.id
    }
    // pc.branch = {slug: (pc.branch as any).slug, uuid: (pc.branch as any).uuid} as any
    const res = await api.post(api.core, 'partner/edit', {partner})
    // return
    this.$store.commit('stopLoading')
    if (res && res.data) {
      const refs = this.$refs as any;
      if (refs.editPartner) {
        refs.editPartner.hide()
      }
      this.loadPartner()
    }
  }

  async saveAgency() {
    this.$store.commit('loading')
    let agency = {...this.agency};
    agency.parent = this.partner.id;
    // pc.branch = {slug: (pc.branch as any).slug, uuid: (pc.branch as any).uuid} as any
    const res = await api.post(api.core, 'partner/edit', {partner: agency})
    // return
    this.$store.commit('stopLoading')
    if (res && res.data) {
      const refs = this.$refs as any;
      if (refs.editAgency) {
        refs.editAgency.hide()
      }
      this.loadPartner()
    }
  }

  editUser(user: Partner) {
    this.user = new Partner(user);
    (this.$refs as any).editUser.show()
  }

  createUser() {
    this.user = new Partner();
    this.user.code = this.partner.code;
    (this.$refs as any).editUser.show()
  }

  isMethodActiveForPartner(method: PaymentMethod, partner: Partner) {
    return partner.paymentMethods.find((p: PaymentMethod) => p.id === method.id) ? true : false
  }

  async loadPartner() {
    this.isLoading = true
    const res = await api.get(api.core, 'partner/get/' + this.$route.params.code)
    this.isLoading = false
    if (res && res.data) {
      this.partner = new Partner(res.data.partner)

    }
  }

  async loadPartnerMethods() {
    // this.$store.commit('loading')
    const res = await api.get(api.core, 'partner/method/list/' + this.$route.params.code)
    if (res && res.data) {
      this.methods = []
      res.data.methods.forEach((p: PaymentMethod) => {
        this.methods.push(new PaymentMethod(p))
      })
    }
    // this.$store.commit('stopLoading')
  }

  loadingContracts = false
  contractPage = 1

  async loadContracts() {
    this.loadingContracts = true
    const res = await api.post(api.core, 'contract/search', {
      searched: {
        partner: this.$route.params.code,
        page: this.contractPage,
        perPage: 50,
        branch: null,
        customer: null,
        insurer: null,
        manager: null,
        police: null,
        effetMin: null,
        effetMax: null,
        expireMin: null,
        expireMax: null,
        createMin: null,
        createMax: null,
      }
    })
    if (res && res.data) {
      this.contracts = []
      res.data.contracts.forEach((c: any) => {
        const occ = new Contract(c)
        this.contracts.push(occ)
      })
    }
    this.loadingContracts = false
  }

  getAgencyNameByCode(code: string) {
    const agency = this.partner.agencies.find((agency: Partner) => {
      return agency.code === code
    })

    return agency ? agency.name : code
  }

  users: Partner[] = []
  loadingUsers = false

  async loadUsers() {
    this.loadingUsers = true
    const res = await api.get(api.auth, 'partner/api/agency/user/list/' + this.$route.params.code)
    this.loadingUsers = false

    if (res && res.data) {
      this.users = []
      res.data.users.forEach((p: any) => {
        const occ = new Partner(p)
        this.users.push(occ)
      })
    }
  }

  agencies: Partner[] = []

  async loadAgencies() {
    this.loadingUsers = true
    const res = await api.get(api.core, 'partner/agencies/list/' + this.$route.params.code)
    if (res && res.data) {
      this.agencies = []
      res.data.agencies.forEach((p: any) => {
        const occ = new Partner(p)
        this.agencies.push(occ)
      })
    }
    this.loadingUsers = false
  }

  engineMethods: EngineMethod[] = []
  loadingEngineMethod = false

  async loadMethods(branch: Branch) {
    this.loadingEngineMethod = true
    const res = await api.get(api.core, 'engine/method/list/global/' + branch.slug + '/version/' + branch.engineVersion)
    if (res && res.data) {
      this.engineMethods = []
      res.data.methods.forEach((m: EngineMethod) => {
        const occ = new EngineMethod(m)
        this.engineMethods.push(occ)
      })

      // this.engines = []
      // res.data.engines.forEach((e: Engine) => {
      //     const occ = new Engine(e)
      //     this.engines.push(occ)
      // })
      // console.log(this.branch)
    }
    this.loadingEngineMethod = false
  }
}
</script>
<style scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all .2s;
}

.slide-left-leave-from {
  transition: all .2s;
}

.slide-left-enter {
  opacity: 0;
  transform: translateY(50%);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}

.modal-xl .modal-content {
  height: 100%;
}</style>
