<template>
<!--  <transition-group appear class="flex-grow-1 question mw-100"  name="slide-up" tag="div">-->
  <div class="flex-grow-1 m-2 question" v-if="question.isAuthorized()">
    <label class="fw-bolder my-2 d-flex justify-content-between" for="">
      <div>
                            <span :title="question.visibility" class="cursor-pointer" v-b-tooltip.hover
                                  v-if="question.visibility !== 'PUBLIC'">
                                <i class="fad fa-lock text-danger"></i>
                            </span>
        {{ question.label }}
      </div>
      <span @click="$store.commit('resetResponse', question)" class="btn btn-xs btn-icon"
            v-if="hasRole('ROLE_MANAGER')">
                <i class="fad fa-trash text-danger"></i>
            </span>
    </label>


    <!--        <b-input-group class="input-group" role="group">-->
    <!--            <b-input-group-prepend class="input-group-prepend">-->
    <!--                <div class="input-group-text">$</div>-->
    <!--            </b-input-group-prepend>-->
    <!--            <input class="form-control"  type="text">-->
    <!--            <b-input-group-append class="input-group-append">-->
    <!--                <div class="input-group-text">.00</div>-->
    <!--            </b-input-group-append>-->
    <!--        </b-input-group>-->

    <b-input-group
        :class="[question.isSending ? 'border-secondary' : question.isValid ? 'border-success' : 'border-danger']"
        class="rounded border-1 d-flex flex-grow-1" style="border-style:solid">
      <b-input-group-prepend class="d-flex flex-column" v-if="question.prefix">
        <div class="input-group-text flex-grow-1">
          <template v-if="isIcon(question.prefix)">
            <i :class="question.prefix"></i>
          </template>
          <template v-else>
            {{ question.prefix }}
          </template>
        </div>
      </b-input-group-prepend>
      <div class="input-container flex-grow-1 rounded  d-flex">
        <input :class="{ 'rounded-left-0': question.prefix, 'rounded-right-0': question.suffix }"
               :regex="question.regex" :type="type" @blur="question.updateAnswer()"
               @keyup.enter="question.updateAnswer()" @input="throttle()" class="form-control border-none"
               v-model.trim="question.response.value">
        <span :title="trans('Mise à jour en cours')" class="input-status" v-b-tooltip.hover
              v-if="question.isSending">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
        <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover
              v-else-if="!question.isValid">
                    <i class="fad fa-exclamation-circle text-danger"></i>
                </span>
        <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover
              v-else-if="question.isValid">
                    <i class="fad fa-check-circle text-success"></i>
                </span>
      </div>
      <b-input-group-append v-if="question.suffix">
        {{ question.suffix }}
      </b-input-group-append>
    </b-input-group>
  </div>
<!--  </transition-group>-->
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Question from "@/entity/Question";

@Component({})
export default class TextType extends Vue {
  @Prop() question!: Question
  type = 'text'

  isIcon(text: string) {
    return text.match(/fa |fab |fad |fal |fas /) !== null
  }

  to: any = null

  throttle() {
    clearTimeout(this.to);
    this.to = setTimeout(() => {
      clearTimeout(this.to)
      this.question.updateAnswer()
    }, 5000)
  }

  beforeMount(): void {
    // console.log(this.question.genId+'. '+ this.question.label+' => for rg '+this.question.response.responseGroup.uuid)
    // console.log(this.question.response)
  }

  mounted(): void {
    this.setType()
    // console.log(this.question.uuid+'_'+this.question.response.responseGroup.uuid)
    // console.log(this.question.response)
  }

  setType() {
    if (this.question.hasTag('EMAIL')) {
      this.type = 'mail'
    }
    if (this.question.hasTag('CONTACT')) {
      this.type = 'tel'
    }
  }

  save() {
    console.log('save')
  }
}
</script>
<style scoped>
.input-container {
  position: relative;
}

.input-status {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
