<template>
  <Base>

    <template #content>
      <div class="fs-2x fw-bolder">
        {{ trans('Etat actuel du contrat') }}
      </div>

      <div class="p-10 border-dashed">
        <Loading v-if="loading"/>
        <ItemTree :item="root" v-if="root" :itemTypes="itemTypes"/>
<!--        <AddRemoveItemLine :item="root" v-if="root" :level="0" :itemTypes="itemTypes" :itemType="root.model"/>-->
      </div>
    </template>
  </Base>
</template>


<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Item from "@/entity/Item";
import Loading from "@/components/Loading.vue";
import AddRemoveItemLine from "@/views/Income/Avenant/IncorporationRetrait/AddRemoveItemLine.vue";
import ItemType from "@/entity/ItemType";
import ItemTree from "@/views/Configuration/Branch/Item/ItemTree.vue";

@Component({
  components: {AddRemoveItemLine, Loading, Base}
})
export default class IncorporationRetrait extends Vue {

  root: Item | null = null
  loading = false
  itemTypes: ItemType[] = []

  mounted() {
    this.loadRoot()
  }

  loadGroupObjectItems() {

  }

  async loadRoot() {
    this.loading = true
    const res = await api.get(api.core, 'contract/get/root/item/' + this.$route.params.uuid)
    this.loading = false

    if (res && res.data) {
      this.root = new Item(res.data.root)
      this.itemTypes = []
      res.data.itemTypes.forEach((i: any) => {
        const occ = new ItemType(i)
        if (!this.itemTypes.includes(occ)) {
          this.itemTypes.push(occ)
        }
      })
    }
  }

  async loadItemTypes() {
    // const res = await api.
  }
}
</script>

<style scoped>

</style>
