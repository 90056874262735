<template>
  <div class="d-flex align-items-stretch" id="kt_header_nav">
    <div class="header-menu align-items-stretch" data-kt-drawer="true"
         data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-direction="end"
         data-kt-drawer-name="header-menu" data-kt-drawer-overlay="true"
         data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
         data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-place="true" data-kt-place-mode="prepend"
         data-kt-place-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
      <div
          class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
          data-kt-menu="true" id="#kt_header_menu">

        <router-link :to="{ name: 'configuration-index' }" class="menu-item me-lg-1"
                     v-if="hasRole('ROLE_CONFIGURATION')">
          <div class="menu-link" :class="{ 'active': pathIncludes('/configuration') }">
                        <span class="menu-title">
                            <i class="fad fa-cogs fs-2 me-2"></i>
                            {{ trans('Configuration') }}
                        </span>
          </div>
        </router-link>


        <router-link :to="{ name: 'income-index' }" class="menu-item me-lg-1"
                     v-if="hasRole('ROLE_PRODUCTION') || hasRole('ROLE_PARTNER')">
          <div class="menu-link" :class="{ 'active': pathIncludes('/income') }">
                        <span class="menu-title">
                            <i class="fad fa-shopping-bag fs-2 me-2"></i>
                            {{ trans('Production') }}
                        </span>
          </div>
        </router-link>


        <!--                <router-link :to="{ name: 'partner-index' }" class="menu-item me-lg-1"-->
        <!--                    v-if="hasRole('ROLE_PARTNER')">-->
        <!--                    <div class="menu-link" :class="{ 'active': pathIncludes('/partner/production') }">-->
        <!--                        <span class="menu-title">-->
        <!--                            <i class="fad fa-shopping-bag fs-2 me-2"></i>-->
        <!--                            {{ trans('Production') }}-->
        <!--                        </span>-->
        <!--                    </div>-->
        <!--                </router-link>-->


      

        <router-link :to="{ name: 'crm-index' }" class="menu-item me-lg-1"
                     v-if="hasRole('ROLE_COMMUNICATION')">
          <div class="menu-link" :class="{ 'active': pathIncludes('/crm') }">
                        <span class="menu-title">
                            <i class="fad fa-comments-dollar fs-2 me-2"></i>
                            {{ trans('CRM') }}
                        </span>
          </div>
        </router-link>

   <!--  
    
    
    
    
      <router-link :to="{ name: 'communication-index' }" class="menu-item me-lg-1"
                     v-if="hasRole('ROLE_COMMUNICATION')">
          <div class="menu-link" :class="{ 'active': pathIncludes('/communication') }">
                        <span class="menu-title">
                            <i class="fad fa-comments fs-2 me-2"></i>
                            {{ trans('Communication') }}
                        </span>
          </div>
        </router-link>
    
    <router-link :to="{ name: 'prospection-index' }" class="menu-item me-lg-1"
                     v-if="hasRole('ROLE_MANAGER')">
          <div class="menu-link" :class="{ 'active': pathIncludes('/prospection') }">
                        <span class="menu-title">

                            <i class="fad fa-headset fs-2 me-2"></i>
                            {{ trans('Prospection') }}
                        </span>
          </div>
        </router-link>

        <router-link :to="{ name: 'chat-index' }" class="menu-item me-lg-1"
                     v-if="hasRole('ROLE_CHAT')">
          <div class="menu-link" :class="{ 'active': pathIncludes('/chat') }">
                        <span class="menu-title">
                            <i class="fab fa-whatsapp-square fs-2 me-2"></i>
                            {{ trans('Chat') }}
                        </span>
          </div>
        </router-link>
-->
        <router-link :to="{ name: 'admin-index' }" class="menu-item me-lg-1"
                     v-if="hasRole('ROLE_ADMIN')">
          <div class="menu-link" :class="{ 'active': pathIncludes('/admin') }"
               @click="setPlatform('admin')">
                        <span class="menu-title">
                            <i class="fad fa-users fs-2 me-2"></i>
                            {{ trans('Administration') }}
                        </span>
          </div>
        </router-link>

        <router-link :to="{ name: 'partner-admin-index' }" class="menu-item me-lg-1"
                     v-if="isTopPartner()">
          <div class="menu-link" :class="{ 'active': pathIncludes('/partner') }"
               @click="setPlatform('partner/admin')">
                        <span class="menu-title">
                            <i class="fad fa-users fs-2 me-2"></i>
                            {{ trans('Administration') }}
                        </span>
          </div>
        </router-link>

      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import {auth} from "@/services/Auth";

@Component({})
export default class LeftMenu extends Vue {
  public website = process.env.VUE_APP_BACKEND_URL;

  setPlatform(platform: string) {
    localStorage.setItem('platform', platform)
    this.$store.state.platform = platform
  }

  isTopPartner() {
    // if (auth.hasRole('ROLE_PARTNER')) {
    // console.log(this.$store.state.partner)
      if (this.$store.state.partner) {
        if (!this.$store.state.partner.parent) {
          return true
        }
      }
    // }
    return false
  }

  pathIncludes(name: string) {
    return this.$router.currentRoute.path.includes(name)
  }


  get isConnected(): boolean {
    return api.isConnected();
  }
}
</script>
<style scoped>

</style>
