<template>
  <PartnerLogin v-if="userType === 'partner'"></PartnerLogin>
  <ManagerLogin v-else-if="userType === 'manager'"></ManagerLogin>
</template>
<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import {Component, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import router from "@/router";
import {auth} from "@/services/Auth";
import Logo from "@/components/Logo.vue";
import User from '@/entity/User';
import Swal from 'sweetalert2';
import PartnerLogin from "@/views/Partner/PartnerLogin.vue";
import ManagerLogin from "@/views/ManagerLogin.vue";
import {helper} from "@/services/Helper";
// import {api} from "@/services/Api";

@Component({
  components: {ManagerLogin, PartnerLogin, Logo}
})
export default class Login extends Vue {
  userType: string = 'manager'

  mounted() {

    this.check()
  }

  check() {
    const ut: any = localStorage.getItem('userType')
    if (!helper.empty(ut)) {
      this.userType = ut
      return
    }
    const host = window.location.host;
    const parts = host.split('.');
    const domainLength = 3;
    if (parts[0] === "partner") {
      this.userType = 'partner'
    }
    localStorage.setItem('userType', this.userType)
  }

}
</script>
<style scoped></style>
