<template>
  <div class="row survey w-100 m-auto" :key="'question_'+question.uuid">
    <div class="my-5 d-flex align-items-center justify-content-between p-0">
      <!--            {{question.response.uuid}}-->
      <Loading v-if="creatingResponseGroup"></Loading>
      <!--            <h1>-->
      <!--                {{question.label}}-->
      <!--            </h1>-->

      <div>
      </div>
    </div>

    <!--        <div class="d-flex justify-content-center">-->

    <!--            <button :data-kt-indicator="spin" @mouseover="load" class="btn btn-secondary" type="submit"-->
    <!--                    v-if="helper.empty(branch) || helper.empty(slug) ">-->
    <!--                <span class="indicator-label">{{trans('Editer le formulaire')}}</span>-->
    <!--                <span class="indicator-progress">-->
    <!--                    {{trans('Editer le formulaire')}}-->
    <!--                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>-->
    <!--            </button>-->

    <!--            <router-link :to="{ name: 'survey-form',params: { surveySlug :slug,branchSlug : branch } }" class="btn btn-warning"-->
    <!--                         target="_blank" v-else>-->
    <!--                <i class="fad fa-edit"></i>-->
    <!--                {{trans('Editer le formulaire')}} {{slug}}-->
    <!--            </router-link>-->


    <!--        </div>-->
    <!--        <template v-if="question.response.responseGroups.length > 0">-->
    <!--            Question : {{question.uuid}}-->
    <!--            <br>-->
    <!--            Reponse : {{question.response.uuid}}-->
    <!--            <br>-->
    <!--            Given Rg uuid : {{question.response.responseGroups[0].uuid}}-->
    <!--        </template>-->
    <template v-for="rg,index in question.response.responseGroups">
      <div class=" d-flex align-items-center">
        <h2 class="m-0" v-if="question.max > 1">
          <template v-if="rg.alias">
            {{ rg.alias }}
          </template>
          <template v-else>
            {{ question.label }} N°{{ index + 1 }}
          </template>
        </h2>
        <!--          <button @click="deleteSubResponseGroup(rg)" class="btn btn-sm btn-danger ms-3 btn-icon">-->
        <!--            <i class="fad fa-trash"></i>-->
        <!--          </button>-->
      </div>

      <ResponseGroupManager :key="'rgKey_'+rg.uuid" :melted="melted" :name="'rg_'+rg.uuid" :uuid="rg.uuid"/>
    </template>
    <!--        <FormProcessor :key="question.response.responseGroups[0].uuid+'_'+question.uuid" :melted="melted"-->
    <!--                       :name="'rg_'+question.response.responseGroups[0].uuid+'_'+question.uuid"-->
    <!--                       :rgUuid="question.response.responseGroups[0].uuid"-->
    <!--                       v-if="question.response.responseGroups.length > 0"></FormProcessor>-->
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Question from "@/entity/Question";
import {api} from "@/services/Api";
import ResponseGroup from "@/entity/ResponseGroup";
import Response from "@/entity/Response";
import Loading from "@/components/Loading.vue";
import Popup from "@/entity/Popup";
// import ResponseGroupManager from "@/views/Comparator/Components/ResponseGroupManager.vue";

@Component({
  name: 'SurveyType',
  components: { Loading}
})
export default class SurveyType extends Vue {
  [x: string]: any;

  @Prop() question!: Question
  @Prop() responseGroup!: ResponseGroup
  creatingResponseGroup = false
  @Prop() melted?: boolean
  rg: any = null
  branch = ''
  slug = ''
  spin = 'off'

  beforeMount(): void {
    // console.log(this.question.label + ' => load RG ' + this.question.response.responseGroups[0].uuid)
  }

  async mounted() {
    console.log(this.question.label + ' => load RG ' + this.question.response.responseGroups[0].uuid)
    if (this.question.response.responseGroups.length === 0) {
      this.createSubResponseGroup()
    }
  }


  async createSubResponseGroup() {
    console.log('create sub')
    if (this.question.max) {
      if (this.question.response.responseGroups.length >= this.question.max) {
        new Popup('Erreur', 'Vous avez atteint le nombre maximum autorisé', 'danger')
        return;
      }
    }
    this.creatingResponseGroup = true
    const data = {rgUuid: this.responseGroup.uuid, questionUuid: this.question.uuid}
    api.createSubGroup(data, (res: any) => {
      this.creatingResponseGroup = false
      if (res && res.data && res.data.responses) {
        res.data.responses.forEach((d: any) => {
          const r = d.response;
          if (d.questionUuid === this.question.uuid && d.responseGroupUuid === this.responseGroup.uuid) {
            const index = this.question.response.responseGroups.findIndex((rg: ResponseGroup) => {
              return rg.uuid === r.uuid
            })
            if (index === -1) {
              const occ = new ResponseGroup(r);
              this.question.response.responseGroups.push(occ)
              this.$store.commit('appendRg', occ)
              this.question.updateAnswer();
              this.$store.commit('responseSaved')
            }
          }
        })
      }
    })
  }


  async load() {
    this.spin = 'on'
    const res = await api.get(api.form, 'question/get/survey/linked/' + this.question.uuid)
    if (res && res.data && res.data.survey) {
      this.branch = res.data.survey.branch
      this.slug = res.data.survey.slug
    }
    this.spin = 'off'
  }

}
</script>
<style scoped>
</style>
