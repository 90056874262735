<template>
  <div :id="'section-container_'+section.uuid" class="section-container mb-10 p-5">
    <div class="d-flex align-items-center px-5 py-2 mb-3" style="border-bottom:1px dashed black">
      <div class=" border-dashed d-flex justify-content-center align-items-center me-3 cursor-move">
        <i class="fad fa-arrows fs-3 "></i>
      </div>
      <div class="d-flex justify-content-between flex-grow-1">
        <div class="d-flex">
          <div class="form-check form-check-custom me-3 form-check-sm">
            <input :checked="selectAll" @change="toggleSection" class="form-check-input" type="checkbox" value=""/>
          </div>
          <h1 class="m-0 d-flex align-items-center">{{ section.ranking }} - {{ section.label }}</h1>
        </div>
        <div>
          <div :title="trans('Ajouter une question')" @click="createQuestion"
               class="btn btn-icon me-3 btn-primary btn-sm"
               v-b-modal="'questionForm-'+uKey"
               v-b-tooltip.hover>
            <i class="fad fa-plus"></i>
          </div>
          <label :disabled="isImporting" :title="trans('Importer des questions')"
                 class="btn btn-icon me-3 btn-dark btn-sm"
                 v-b-tooltip.hover>
            <input :disabled="isImporting" @change="importQuestions" accept=".waform" class="hidden" multiple
                   type="file">
            <i class="fad fa-download"></i>
          </label>
          <!--                    <div :title="trans('Exporter des questions')" class="btn btn-icon me-3 btn-success btn-sm"-->
          <!--                         v-b-tooltip.hover>-->
          <!--                        <i class="fad fa-upload"></i>-->
          <!--                    </div>-->

          <div :title="trans('Editer cette section')" @click="showSectionForm"
               class="btn btn-icon btn-warning btn-sm me-3"
               v-b-tooltip.hover>
            <i class="fad fa-edit"></i>
          </div>

          <div :title="trans('Supprimer cette section')" @click="deleteSection" class="btn btn-icon btn-danger btn-sm"
               v-b-tooltip.hover>
            <i class="fad fa-trash"></i>
          </div>

        </div>
      </div>
      <p></p>
    </div>
    <div class="section pointer-events-none">
      <div class="d-flex flex-column justify-content-center align-items-center">

        <draggable :animation="200" :id="'section_'+section.uuid"
                   :list="section.questions"
                   @end="updateQuestionPosition"
                   class="w-100 d-flex flex-wrap"
                   filter=".action-button"
                   ghost-class="bg-light-primary" group="questions" tag="div">
          <div :id="'question_'+question.uuid"
               :key="'question_'+question.uuid"
               class="flex-grow-1 w-50 px-5 d-flex align-items-center justify-content-between question-container "
               v-for="question in section.questions">
            <div class="d-flex flex-grow-1">
              <div class="form-check form-check-custom me-3 form-check-sm">
                <input :checked="selected.includes(question.uuid)" :id="'toggle_'+question.uuid"
                       @change="toggleQuestion(question.uuid)" class="form-check-input" type="checkbox" value=""/>
              </div>

              <div class="flex-grow-1 d-flex">


                <!---------------------------------------------------------------------------------------------------->
                <!--                                <MultipleSurvey :key="question.uuid" :question="question"-->
                <!--                                                v-if="question.type.code === 'OBJECT_GROUP'"></MultipleSurvey>-->
                <!--                                <SurveyType :key="question.uuid" :question="question" v-else-if="question.type.code === 'OBJECT'"></SurveyType>-->
                <!--                                <TextType :key="question.uuid" :question="question" v-else-if="question.type.code === 'TEXT'"></TextType>-->

                <!---------------------------------------------------------------------------------------------------->

                <div class="p-3 fw-bolder d-flex align-items-center">
                  <!--                        {{question.id}} {{question.uuid}}-->
                  <i class="fad fa-exclamation-circle text-danger me-3" v-if="question.required"></i>
                  <span>{{ question.ranking }} - {{ question.label }}</span>
                  <span class="ms-3 badge badge-dark" v-if="question.type">{{ question.type.label }}</span>
                  <!--                                    <span>NOT HANDLED YET</span>-->
                </div>
              </div>
            </div>
            <div class="d-flex">

              <button :data-kt-indicator="'off'" @click="loadSurvey(question)"
                      class="btn btn-sm me-3 btn-icon btn-primary"
                      type="submit"
                      v-if="question.type && question.type.code && question.type.code.match(/OBJECT/)">
                                <span class="indicator-label">
                                    <i class="fad fa-external-link"></i>
                                </span>
                <span class="indicator-progress">
                    <i class="fad fa-external-link"></i>
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
              </button>

              <div @click="editQuestion(question)"
                   class="btn btn-icon btn-sm btn-warning me-3"
                   v-b-modal="'questionForm-'+uKey">
                <i class="fad fa-edit"></i>
              </div>
              <div @click="deleteQuestion(question)"
                   class="btn btn-sm btn-icon btn-danger">
                <i class="fad fa-trash"></i>
              </div>
            </div>
          </div>
        </draggable>


        <!--                <div class="btn btn-primary btn-sm" v-b-modal="'questionForm-'+uKey">-->
        <!--                    <i class="fad fa-plus"></i>-->
        <!--                    {{trans('Ajouter une question')}}-->
        <!--                </div>-->
      </div>
    </div>
    <question-form :question="question" :questionTypes="questionTypes" :section="section" :survey="survey" :uKey="uKey"
                   v-on:loadTypes="loadTypes" :durations="durations" :packCategories="packCategories"
                   v-on:reloadSurvey="reloadSurvey"
                   v-on:save="editQuestion"></question-form>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Section from "@/entity/Section";
import Question from "@/entity/Question";
import Survey from "@/entity/Survey";
import Step from "@/entity/Step";
import QuestionForm from "@/views/Configuration/Branch/SurveyComponents/QuestionForm.vue";
import QuestionType from "@/entity/QuestionType";
import {api} from "@/services/Api";
import Popup from "@/entity/Popup";
import Duration from '@/entity/Duration';
import PackCategory from "@/entity/PackCategory";

@Component({
  components: {QuestionForm}
})
export default class SectionView extends Vue {
  [x: string]: any;

  @Prop() section!: Section
  step!: Step
  @Prop() survey!: Survey
  @Prop() questionTypes!: QuestionType[]
  @Prop() durations!: Duration[]
  @Prop() packCategories!: PackCategory[]
  uKey = this.genId()
  question: Question = new Question()
  @Prop() selected!: string[]


  // selectAll = false

  mounted() {
    this.step = this.section.step as Step
    this.question = new Question()
    this.question.visibility = 'PUBLIC'
  }

  async updateQuestionPosition(evt: any) {
    const question = evt.clone.id.replace('question_', '')
    const fromSection = evt.from.id.replace('section_', '')
    const oldIndex = evt.oldIndex
    const toSection = evt.to.id.replace('section_', '')
    const newIndex = evt.newIndex
    if (fromSection !== toSection || oldIndex !== newIndex) {
      const res = await api.post(api.form, 'question/update/position', {
        section: toSection,
        index: newIndex,
        question: question
      })
      this.reloadSurvey()
    }
  }

  isImporting = false

  importQuestions(event: any) {
    this.isImporting = true
    const files = event.target.files;

    Array.from(files).forEach(async (file: any, index: number, array) => {
      const text = await file.text()
      // console.log(text)
      const res = await api.post(api.form, 'question/import', {section: this.section.uuid, data: text})
      if (index === files.length - 1) {
        this.reloadSurvey()
        this.isImporting = false
      }
    })
  }

  async loadSurvey(question: Question) {
    // console.log(question)
    // return
    const res = await api.get(api.form, 'question/get/survey/linked/' + question.uuid)
    if (res && res.data && res.data.survey) {
      const routeData = this.$router.resolve({
        name: 'survey-form',
        params: {surveySlug: res.data.survey.slug, branchSlug: res.data.survey.branch}
      });
      window.open(routeData.href, '_blank');
      // this.$router.push({name: 'survey-form', params: {surveySlug: res.data.survey.slug, branchSlug: res.data.survey.branch}})
    }
  }

  loadTypes() {
    this.$emit('loadTypes')
  }

  createQuestion() {
    this.question = new Question()
    this.question.visibility = 'PUBLIC'
    this.question.section = this.section
    // this.section.questions.push(this.question)
    // console.log(this.survey.getPosition(this.question))
  }

  get selectAll(): boolean {
    let all = true;
    if (this.section.questions.length === 0) {
      return false
    }
    this.section.questions.forEach((q: Question) => {
      if (q.uuid && !this.selected.includes(q.uuid)) {
        all = false;
      }
    })
    return all
  }

  toggleSection() {
    // console.log(this.selectAll)
    if (this.selectAll) {
      this.section.questions.forEach((q: Question) => {
        if (q.uuid && this.selected.includes(q.uuid)) {
          this.$emit('toggleQuestion', q.uuid)
        }
      })
    } else {
      this.section.questions.forEach((q: Question) => {
        if (q.uuid && !this.selected.includes(q.uuid)) {
          this.$emit('toggleQuestion', q.uuid)
        }
      })
    }
    // console.log(this.selected)
  }

  toggleQuestion(uuid: string) {
    this.$emit('toggleQuestion', uuid)
    // return;
    // const index = this.selected.findIndex((id) => id === uuid);
    // if (index !== -1) {
    //     this.selected.splice(index, 1)
    // } else {
    //     this.selected.push(uuid)
    // }
    // let all = true;
    // this.section.questions.forEach((q: Question) => {
    //     if (!this.selected.includes(q.uuid)) {
    //         all = false;
    //     }
    // })
    // if (all) {
    //     this.selectAll = true;
    // } else {
    //     this.selectAll = false;
    // }

    // this.$emit('toggleQuestion', uuid)
    // console.log(all)
    // console.log(this.selected)
    // this.$emit('questionSelected', this.selected)
  }


  async deleteQuestion(question: Question) {
    const pop = new Popup('En cours', 'Suppression de la question "' + question.label + '"', 'danger', undefined, true)
    const res = await api.delete(api.form, 'question/delete/' + question.uuid)
    pop.hide()
    this.reloadSurvey()
  }

  reloadSurvey() {
    this.$emit('reloadSurvey')

    this.question = new Question()
  }

  showSectionForm() {
    this.$emit('showSectionForm', this.section)
  }

  editQuestion(question: Question) {
    // console.log(question)
    // return
    this.question = new Question(JSON.parse(JSON.stringify({...question})))

  }

  async deleteSection() {
    const res = await api.delete(api.form, 'section/delete/' + this.section.uuid)
    if (res) {
      const index = this.step.sections.findIndex((s: Section) => {
        return s.uuid === this.section.uuid
      })
      if (index !== -1) {
        this.step.sections.splice(index, 1)
      }
    }
    this.$emit('delete', this.section.uuid)
  }

}
</script>
<style scoped>

.section-container {
  /*border-bottom:1px dashed black;*/
  background: rgba(0, 0, 0, .05);
  /*padding:10px;*/
}

.section {
  /*border: 1Px dashed gray;*/
  /*padding:10px;*/
}
</style>
