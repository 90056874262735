<template>
  <div class="my-2" :style="{ 'margin-left': (depth * 20) + 'px' }">
    <div class="bg-hover-light-primary rounded " :class="[ show ? 'd-flex' : 'hidden']">
      <div class="d-flex align-items-center cursor-pointer flex-grow-1 p-3">
        <div v-if="partner.agencies && partner.agencies.length > 0" class="px-3" @click="expand = !expand">
          <i class="fad fa-caret-down fs-3 me-2" v-if="expand"></i>
          <i class="fad fa-caret-right text-primary fs-3 me-2" v-else></i>
        </div>
        <div v-else class="px-3 ms-5">
          <!--          <i class="fad fa-empty-set fs-3 me-2"></i>-->
        </div>
        <div>
          <router-link :to="{ name: 'partner-agency', params: { code: partner.code } }"
                       class="fw-bolder text-hover-primary text-dark">
            {{ partner.name }}
          </router-link>
          <div class="text-muted fw-bolder">
            {{ partner.code }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-grow-1 justify-content-end">
        <div v-for="(method,index) of paymentMethods" class="d-flex">
          <div class="py-1 px-5 justify-content-center align-items-center"
               :class="{'bg-white': index % 2 !==0,'bg-light-primary': index%2 === 0}">
            <div>
              <img :src="uploadRoot + method.photo" alt="" class="w-25px h-25px mb-2">
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <div class="form-check form-check-custom form-check-sm">
                <input class="form-check-input" type="checkbox" :checked="isMethodSelected(method)"
                       @change="toggleMethod(method)"
                       :disabled="!method.active"/>
              </div>
            </div>
          </div>
          <!--          <div class="form-check form-switch form-check-custom form-check-solid ">-->
          <!--            <input class="form-check-input h-20px w-30px" type="checkbox" :checked="isMethodSelected(method)"/>-->
          <!--          </div>-->
        </div>
        <!--        <div v-for="method of partner.paymentMethods" class="m-1">-->
        <!--        </div>-->
      </div>
      <div class="p-3 ms-10 justify-content-end d-flex">
        <div class="btn btn-icon btn-light-warning btn-xs" title="Modifier" @click="edit(partner)">
          <i class="fad fa-edit"></i>
        </div>

        <!--        <div class="btn btn-icon btn-light-primary btn-xs ms-1" title="Utilisateurs">-->
        <!--          <i class="fad fa-users"></i>-->
        <!--        </div>-->

        <div class="btn btn-icon btn-light-success btn-xs ms-1" title="Créer une succursale"
             @click="createSub(partner)">
          <i class="fad fa-plus"></i>
        </div>
        <router-link target="_blank" :to="{ name: 'partner-form', params: { code: partner.code } }"
                     class="btn btn-icon btn-xs btn-light-primary mx-1">
          <i class="fad fa-external-link"></i>
        </router-link>


        <div v-if="hasRole('ROLE_ADMIN')" class="btn btn-danger btn-icon btn-xs ms-1" @click="deletePartner(partner)">
          <i class="fad fa-trash"></i>
        </div>
      </div>
    </div>
    <div v-if="expand">
      <PartnerTree v-for="agency of partner.agencies" :key="agency.id" :partner="agency" :depth="depth + 1" :search="search"
                   v-on:createSub="createSub($event)" v-on:edit="edit($event)" v-on:delete="deletePartner"
                   :parent="partner" :paymentMethods="paymentMethods" :isRoot="false">
      </PartnerTree>
    </div>
  </div>
</template>


<script lang="ts">
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import Partner from "@/entity/Partner";
import {api} from "@/services/Api";
import Swal from "sweetalert2";
import PaymentMethod from "@/entity/PaymentMethod";
import {helper} from "@/services/Helper";

@Component({
  name: 'PartnerTree'
})
export default class PartnerTree extends Vue {
  @Prop() partner!: Partner
  @Prop() parent?: Partner
  @Prop({default: false}) isRoot?: boolean
  @Prop({default: ''}) search!: string
  @Prop({default: () => []}) paymentMethods!: PaymentMethod[]
  uploadRoot = api.uploadRoot
  @Prop({default: 0}) depth!: number
  expand = true

  show = true

  mounted() {

    if (this.parent) {
      this.partner.parent = this.parent
    }
  }

  @Watch('search')
  filter() {
    if (helper.empty(this.search)) {
      this.show = true;
      return
    }
    if (this.partner.name) {
      if (this.partner.name.toLowerCase().includes(this.search.toLowerCase())) {
        this.show = true
        return
      }
    }
    // if (this.partner.code.toLowerCase().includes(this.search.toLowerCase())) {
    //   this.show = true
    //   return
    // }
    this.show = false
  }

  async toggleMethod(method: PaymentMethod) {
    const res = await api.get(api.core, 'partner/toggle/method/' + this.partner.code + '/' + method.slug)
  }

  isMethodSelected(method: PaymentMethod) {
    const index = this.paymentMethods.findIndex((p: PaymentMethod) => {
      return method.id === method.id
    })
    return index !== -1
  }

  createSub(partner: Partner) {
    this.$emit('createSub', partner)
  }

  edit(partner: Partner) {
    this.$emit('edit', partner)
  }

  async deletePartner(partner: Partner) {
    console.log('h')
    console.log(partner)
    this.$emit('delete', partner)
  }
}
</script>


<style scoped></style>
