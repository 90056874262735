<template>
  <div class="d-flex my-1">
    <div class="d-flex border-dashed rounded" style="background: #F5F8FA">
      <div class="px-5 py-3 cursor-pointer border-right border-1 rounded-left"
           :class="{ 'bg-primary' : value === true }" @click="setValue(true)">
        Oui
      </div>
      <div class="px-5 py-3 cursor-pointer rounded-right"
           :class="{ 'bg-primary' : value === false }" @click="setValue(false)">
        Non
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Component, Prop, Vue} from "vue-property-decorator";


@Component({})
export default class BooleanInput extends Vue {

  @Prop({default: false}) value?: boolean

  setValue(val: boolean) {
    this.$emit('input', val)
  }
}
</script>

<style scoped>

</style>
