<template>
  <div>
    <div class="card">
      <div class="card-body offers">
        <div class="d-flex align-items-stretch justify-content-start w-100 flex-grow-0">
          <div>

            <table v-if="branch" class="table table-striped gx-5 guarantee">
              <thead>
              <tr>
                <th class="fw-bolder" style="vertical-align: middle">
                  <div class="fs-2 d-flex align-items-center justify-content-center">
                    {{ trans('Garanties') }}
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="guarantee in branch.guarantees" style="vertical-align: middle">
                <td class="fw-bolder">
                  {{ guarantee.label }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="results-container flex-grow-1 ms-5 d-flex align-item-start w-100 overflow-auto">
            <div v-if="selected"
                 class="p-10 d-flex justify-content-center align-items-center flex-grow-1 bg-light-primary">
              <div class="">
                <div class="mb-10 d-flex justify-content-center">
                  <img alt="Logo" src="./../../../../assets/logo.png" class="h-80px"/>
                </div>
                <div class="fs-2x fw-bolder text-center">
                  {{ selected.label }}
                </div>
                <div class="flex-grow-1">
                  <div v-for="pcg in selected.packCategoryGuarantees"
                       class="d-flex align-items-center fs-2 py-2 border-top border-white flex-grow-1 justify-content-start px-20">
                    <div>
                      <i class="fad fa-shield-check fs-2 text-success" v-if="!pcg.optional"></i>
                      <i class="fad fa-plus-circle fs-2 text-primary" v-else-if="pcg.selected"></i>
                      <i class="fad fa-times-circle fs-2 text-danger" v-else></i>
                    </div>
                    <div class="ms-5 fw-bolder">
                      {{ pcg.label }}
                    </div>
                  </div>

                  <div class="my-5 d-flex justify-content-center" @click="unselect()">
                    <div class="btn btn-primary">
                      <i class="fad fa-edit"></i>
                      Modifier
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table v-else-if="branch" class="table table-striped text-center gx-5 results">
              <thead>
              <tr>
                <th v-for="pc in branch.packCategories" :key="pc.uuid"
                    class="fw-bolder whitespace-no-wrap fs-2 min-w-200px">
                  <div>
                    <div>
                      {{ pc.label }}
                    </div>
                    <div v-if="allInsurerHasCategory(pc)">
                      <i class="fad fa-check-circle text-success fs-2"
                         title="Tous les assureurs proposent cette formule"></i>
                    </div>
                    <div v-else-if="someInsurerHasCategory(pc)">
                      <i class="fad fa-exclamation-circle text-warning fs-2"
                         title="Certains assureurs ne proposent pas cette formule"></i>
                    </div>
                    <div v-else>
                      <i class="fad fa-times-circle text-danger fs-2"
                         title="Aucun assureurs ne proposent cette formule"></i>
                    </div>
                    <div class=" btn btn-primary mb-5" @click="save(pc)">
                      Selectionner
                    </div>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="guarantee in branch.guarantees">
                <td v-for="pc in branch.packCategories" :key="pc.uuid" style="vertical-align: middle">
                  <!--                  <template v-if="pc.hasGuarantee(guarantee)">-->
                  <template v-if="allInsurerHasMandatory(pc,guarantee)">
                    <i class="fad fa-shield-check fs-2 text-success"
                       title="Tous les assureurs inclus la garantie"></i>
                  </template>
                  <template v-else-if="someInsurerHasMandatory(pc,guarantee)">
                    <i class="fad fa-shield-check fs-2 text-primary"
                       title="Certains assureurs inclus la garantie"></i>
                  </template>
                  <template v-else-if="someInsurerHasOptional(pc,guarantee)">

                    <template v-if="pc.isOptionalSelected(guarantee)">
                      <div class="d-flex justify-content-center align-items-center cursor-pointer position-relative">
                        <i class="fad fa-shield-check fs-2 text-success"></i>
                        <div class="position-absolute" style="right:5px">
                          <i class="fad fa-times-circle text-danger ms-5" @click="removeOptional(pc,guarantee)"></i>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="d-flex justify-content-center align-items-center text-primary cursor-pointer"
                           @click="addOptional(pc,guarantee)"
                           :class="{ 'text-success': allInsurerHasOptional(pc,guarantee) }">
                        <i class="fad fa-plus-circle text-inherit fs-2" v-if="allInsurerHasOptional(pc,guarantee)"></i>
                        <i class="fad fa-exclamation-circle text-inherit fs-2" v-else></i>
                        <div class="ms-2 text-inherit text-decoration-underline">
                          Ajouter
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <i class="fad fa-times-circle text-danger fs-2"></i>
                  </template>
                  <!--                                      <template v-else>-->
                  <!--                                        <template v-if="pc.isOptionalSelected(guarantee)">-->
                  <!--                                          <div class="d-flex justify-content-center align-items-center cursor-pointer position-relative">-->
                  <!--                                            <i class="fad fa-shield-check fs-2 text-success"></i>-->
                  <!--                                            <div class="position-absolute" style="right:5px">-->
                  <!--                                              <i class="fad fa-times-circle text-danger ms-5" @click="removeOptional(pc,guarantee)"></i>-->
                  <!--                                            </div>-->
                  <!--                                          </div>-->
                  <!--                                        </template>-->
                  <!--                                        <template v-else>-->
                  <!--                                          <div class="d-flex justify-content-center align-items-center  cursor-pointer"-->
                  <!--                                               @click="addOptional(pc,guarantee)">-->
                  <!--                                            <i class="fad fa-plus-circle text-primary fs-2"></i>-->
                  <!--                                            <div class="ms-2 text-primary text-decoration-underline">-->
                  <!--                                              Ajouter-->
                  <!--                                            </div>-->
                  <!--                                          </div>-->
                  <!--                                        </template>-->
                  <!--                                      </template>-->
                  <!--                                    </template>-->
                  <!--                                    <template v-else>-->
                  <!--                                      <i class="fad fa-times-circle text-danger fs-2"></i>-->
                  <!--                                    </template>-->

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import Question from "@/entity/Question";
import Branch from "@/entity/Branch";
import ResponseGroup from "@/entity/ResponseGroup";
import Survey from "@/entity/Survey";
import Step from "@/entity/Step";
import {api} from "@/services/Api";
import Guarantee from "@/entity/Guarantee";
import Pack from "@/entity/Pack";
import PackGuarantee from "@/entity/PackGuarantee";
import PackCategory from "@/entity/PackCategory";
import PackCategoryGuarantee from "@/entity/PackCategoryGuarantee";
import {helper} from "@/services/Helper";
import Insurer from "@/entity/Insurer";

@Component({})
export default class GuaranteeSelector extends Vue {
  @Prop() question!: Question
  @Prop() branch!: Branch
  @Prop() rg!: ResponseGroup
  @Prop() survey!: Survey
  insurers: Insurer[] = []
  @Prop() currentStep!: Step
  uploadRoot = api.uploadRoot
  selected: PackCategory | null = null
  timeout: any = null

  mounted() {
    // console.log(this.branch)
    // console.log(this.question.response.value)
    this.insurers = this.$store.state.insurers
    this.updateRowHeight()
    window.addEventListener('resize', this.updateRowHeight)
    this.timeout = setTimeout(this.updateRowHeight, 200)
    this.setCurrentResponse()
  }

  unselect() {
    this.selected = null
    this.timeout = setTimeout(this.updateRowHeight, 200)
  }

  allInsurerHasCategory(category: PackCategory) {
    for (let i of this.insurers) {
      let found = false
      for (let p of i.packs) {
        if (p.category?.id === category.id) {
          found = true;
        }
      }
      if (!found) {
        return false
      }
    }
    return true;
  }

  someInsurerHasCategory(category: PackCategory) {
    for (let i of this.insurers) {
      for (let p of i.packs) {
        if (p.category?.id === category.id) {
          return true;
        }
      }
    }
    return false;
  }

  allInsurerHasMandatory(category: PackCategory, guarantee: Guarantee) {
    for (let i of this.insurers) {
      for (let p of i.packs) {
        if (p.category?.id === category.id && !p.hasMandatoryGuarantee(guarantee)) {
          // console.log()
          return false
        }
      }
    }
    return true;
  }

  someInsurerHasMandatory(category: PackCategory, guarantee: Guarantee) {
    for (let i of this.insurers) {
      for (let p of i.packs) {
        if (p.category?.id === category.id && p.hasMandatoryGuarantee(guarantee)) {
          return true
        }
      }
    }
    return false;
  }

  allInsurerHasOptional(category: PackCategory, guarantee: Guarantee) {
    for (let i of this.insurers) {
      for (let p of i.packs) {
        if (p.category?.id === category.id && !p.hasOptionalGuarantee(guarantee)) {
          return false
        }
      }
    }
    return true;
  }

  someInsurerHasOptional(category: PackCategory, guarantee: Guarantee) {
    for (let i of this.insurers) {
      for (let p of i.packs) {
        if (p.category?.id === category.id && p.hasOptionalGuarantee(guarantee)) {
          return true
        }
      }
    }
    return false;
  }

  allIsOptional() {

  }


  setCurrentResponse() {
    if (!helper.empty(this.question.response.value)) {
      const data = JSON.parse(this.question.response.value as any)
      if (data) {
        if (data.category) {
          this.branch.packCategories.forEach((pc: PackCategory) => {
            if (pc.id === data.category.id) {
              pc.packCategoryGuarantees.forEach((pcg: PackCategoryGuarantee) => {
                if (data.optionals.includes(pcg.guarantee.id)) {
                  // console.log('optional selected ' + pcg.label)
                  pcg.selected = true
                }
              })
              this.selected = pc
            }
          })
        }
      }

    }
  }

  addOptional(packCategory: PackCategory, guarantee: Guarantee) {
    let index = packCategory.packCategoryGuarantees.findIndex((pc: PackCategoryGuarantee) => {
      return pc.guarantee.id === guarantee.id
    })
    if (index === -1) {
      const pcg = new PackCategoryGuarantee()
      pcg.guarantee = guarantee
      pcg.optional = true
      packCategory.packCategoryGuarantees.push(pcg)
      index = packCategory.packCategoryGuarantees.length - 1
    }
    if (index !== -1) {
      packCategory.packCategoryGuarantees[index].selected = true
    }
  }

  save(packCategory: PackCategory) {
    const data: any = {
      code: '',
      category: {id: packCategory.id, slug: packCategory.slug, label: packCategory.label},
      optionals: [] as number[]
    }
    packCategory.packCategoryGuarantees.forEach((pcg: PackCategoryGuarantee) => {
      if (pcg.optional && pcg.selected) {
        data.optionals.push(pcg.guarantee.id)
      }
    })
    this.selected = packCategory
    this.question.response.value = JSON.stringify(data)
    this.question.updateAnswer()
  }

  removeOptional(packCategory: PackCategory, guarantee: Guarantee) {
    const index = packCategory.packCategoryGuarantees.findIndex((pc: PackCategoryGuarantee) => {
      return pc.guarantee.id === guarantee.id
    })
    if (index !== -1) {
      packCategory.packCategoryGuarantees[index].selected = false
    }
  }

  updateRowHeight() {
    clearTimeout(this.timeout)
    this.timeout = null
    // console.log('update row')
    const offers = document.querySelectorAll('.offers')
    offers.forEach((offer: any) => {
      const heads = offer.querySelectorAll('.offers th')
      const rows = offer.querySelectorAll('.offers .results tbody tr')
      const equiRows = offer.querySelectorAll('.offers .guarantee tbody tr')
      // const columns = offer.querySelectorAll('.offers td')
      let max = 0
      heads.forEach((g: HTMLElement) => {
        g.style.height = 'auto'
        if (g.getBoundingClientRect().height > max) {
          max = g.getBoundingClientRect().height
        }
      })
      heads.forEach((head: HTMLElement) => {
        head.style.height = max + 'px'
      })

      rows.forEach((row: HTMLElement, rowIndex: number) => {
        max = 0
        const columns = row.querySelectorAll('td')
        columns.forEach((column: HTMLElement) => {
          column.style.height = 'auto'
          if (column.getBoundingClientRect().height > max) {
            max = column.getBoundingClientRect().height
          }
        })

        const equiRow = equiRows[rowIndex]
        let equiCol: any[] = []
        if (equiRow) {
          equiCol = equiRow.querySelectorAll('td')
          equiCol.forEach((ec: HTMLElement) => {
            if (ec.getBoundingClientRect().height > max) {
              max = ec.getBoundingClientRect().height
            }
          })
        }
        columns.forEach((column: HTMLElement) => {
          column.style.height = max + 'px'
        })


        equiCol.forEach((ec: HTMLElement) => {
          ec.style.height = max + 'px'
        })

      })

    })
  }
}
</script>

<style scoped>


.offers tbody tr:nth-child(2n+1) {
  background: rgba(61, 218, 215, 0.21);
}

.results td,
.results th {
  min-width: 200px;
}

.results-container {
  position: relative;
  /*border: 1px red solid;*/
}

.results-container:before,
.results-container:after {
  content: '';
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  /*background: rgb(2,0,36);*/
  background: linear-gradient(-90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 75%);

}

.results-container:after {
  left: auto;
  right: 0;
  background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 75%);
}

.results td {
  border-right: 10px white solid;
  /*background: rgba(255, 255, 255, 0.21);*/
}

</style>
