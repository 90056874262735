/* eslint-disabled */
import EmailTemplate from "./EmailTemplate";
import EmailJobExtend from "@/entity/extends/EmailJobExtend";

export default class EmailJob extends EmailJobExtend {

public id = '';
public recipients = '';
public template!: EmailTemplate;
public data? = '';
public status = '';
public uuid? = '';
public createdAt?: Date;
public updatedAt?: Date;

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.recipients= object.recipients;
this.template = (object.template instanceof EmailTemplate) ? object.template : object.template ? new EmailTemplate(object.template) : object.template;
       this.data= object.data;
       this.status= object.status;
       this.uuid= object.uuid;
       if(object.createdAt){
           this.createdAt= new Date(object.createdAt);
       }
       if(object.updatedAt){
           this.updatedAt= new Date(object.updatedAt);
       }
      }
      this.postConstruct()
  }

}
