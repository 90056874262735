<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>
        </div>
      </div>
    </template>
    <template #content>

      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-center">
            <div class="col col-auto">
              <label for="">Par page</label>
              <div>
                <select class="form-control form-select" name="" id="" v-model="searched.perPage">
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>
            <div class="col col-auto">
              <label for="">Langue</label>
              <div>
                <select class="form-control form-select" name="" id="">
                  <option value="">Selectionnez une langue</option>
                </select>
              </div>
            </div>
            <div class="col col-auto">
              <label for="">Contient</label>
              <div>
                <input type="text" class="form-control" v-model="searched.text">
              </div>
            </div>
            <div class="col col-auto d-flex flex-column">
              <label for="">Non Traduit</label>
              <div class="flex-grow-1 d-flex align-items-center">
                <div class="form-check form-switch form-check-custom form-check-solid ">
                  <input class="form-check-input h-20px w-30px" type="checkbox" v-model="searched.untranslated"
                         value=""/>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-end">
              <div class="btn btn-primary btn-icon" @click="search">
                <i class="fad fa-search"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <div class="btn btn-danger my-5" v-if="selectedUuids.length > 0" @click="deleteTranslatables">
          <i class="fad fa-trash-alt"></i>
          Supprimer ({{ selectedUuids.length }})
        </div>
      </div>

      <div class="card">
        <div class="card-body p-0">
          <table class="table table-striped g-5">
            <thead>
            <tr>
              <th>
                <div class="form-check form-check-custom  form-check-sm" @change="toggleAll">
                  <input class="form-check-input" type="checkbox">
                </div>

              </th>
              <th>
                Code
              </th>
              <th v-for="language of languages" :key="'header_'+language.id">
                {{ language.label }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="translatable of translatables" :key="translatable.id">
              <td>
                <div class="d-flex align-items-center">
                  <RowSelector v-model="selectedUuids" :data="translatable.id"/>
                  <div>
                    <i class="fad fa-trash-alt text-danger fs-2 ps-4 cursor-pointer"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="mw-500px">
                  <div class="fw-bolder">
                    <!--                    {{ translatable.code }}-->
                    {{ clean(translatable.value) }}
                  </div>
                  <div class="text-muted fs-8">
                    {{ translatable.description }}
                  </div>
                </div>
              </td>
              <td v-for="language of languages">
                <!--                <template #title>-->
                <!--                  {{ language.code }}-->
                <!--                  <i class="fad fa-exclamation-triangle text-danger"-->
                <!--                     v-if="!translatable.getTransition(language.code)"></i>-->
                <!--                </template>-->
                <!--                <template #default>-->
                <!--                      <template v-for="transition of translatable.translations">-->
                <div class="d-flex align-items-center">
                        <textarea
                            name="" :id="'textarea_'+translatable.id+'_'+language.id"
                            cols="5" rows="2" class="form-control"
                            @blur="saveTranslation(translatable.id,language.id)"
                        >{{ translatable.getTransition(language.code) ?
                          translatable.getTransition(language.code).content
                          : '' }}</textarea>
                  <div class="btn btn-primary btn-icon ms-3"
                       @click="saveTranslation(translatable.id,language.id)">
                    <i class="fad fa-save"></i>
                  </div>
                </div>
                <!--                      </template>-->
                <!--                </template>-->
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Pagination :count="total" :itemLength="translatables.length" :matchCount="matchCount" v-model="searched.page"
                  showEmpty="true" class="p-10"
                  :perPage="searched.perPage" :key="'pagination_'+searched.page" @input="search($event)"></Pagination>

    </template>
  </Base>
</template>


<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import FileUpload from "@/components/FileUpload.vue";
import {api} from "@/services/Api";
import Pagination from "@/components/Pagination.vue";
import Translatable from "@/entity/Translatable";
import Language from "@/entity/Language";
import ResponseGroup from "@/entity/ResponseGroup";
import {helper} from "@/services/Helper";
import RowSelector from "@/components/RowSelector.vue";

@Component({
  components: {RowSelector, Pagination, FileUpload, Base}
})
export default class UiPlatformList extends Vue {

  selectedUuids: any[] = []
  matchCount = 0
  total = 0
  translatables: Translatable[] = []
  searched = {
    text: '',
    lang: null,
    code: null,
    untranslated: true,
    page: 1,
    platform: null,
    perPage: 50,
  }
  languages: Language[] = []

  toggleAll() {

  }

  clean(text: string) {
    return text.replace(/_/g, ' ');
  }

  deleteTranslatable(id: any) {
    this.selectedUuids = [id]
    this.deleteTranslatables()
  }

  async deleteTranslatables() {
    const res = await api.post(api.translator, 'translatable/delete/ids', {ids: this.selectedUuids})
    if (res && res.data) {
      this.translatables = this.translatables.filter((t: Translatable) => {
        return !this.selectedUuids.includes(t.id)
      })
    }
    this.selectedUuids = []
  }

  async saveTranslation(tid: number, lid: number) {
    const input: any = document.querySelector('#textarea_' + tid + '_' + lid)
    if (input && !helper.empty(input.value)) {
      // console.log(input.value)
      const res = await api.post(api.translator, 'translation/edit', {
        tid: tid,
        lid: lid,
        value: input.value.trim()
      })
      if (res && res.data) {
        const t = new Translatable(res.data.translatable)
        const index = this.translatables.findIndex((t: Translatable) => {
          return t.id === res.data.translatable.id
        })
        if (index !== -1) {
          console.log('update index ' + index)
          // this.translatables[index] = t
          this.$set(this.translatables, index, t)
        }
      }
    } else {
      console.log(input)
    }
  }

  mounted() {
    this.searched.platform = this.$route.params.id as any
    this.search()
    this.loadLanguages()
  }

  async search(page = null) {
    if (page) {
      if (!isNaN(page!)) {
        this.searched.page = parseInt(page + '')
      }
    }
    const res = await api.post(api.translator, 'platform/get/translations', this.searched)
    if (res && res.data) {
      this.translatables = []
      // console.log(res.data)
      this.matchCount = res.data.match
      this.total = res.data.total
      res.data.translatables.forEach((r: Translatable) => {
        this.translatables.push(new Translatable(r))
      })
    }
  }

  async loadLanguages() {
    const res = await api.get(api.translator, 'language/list')
    if (res) {
      this.languages = []
      res.data.languages.forEach((language: any) => {
        this.languages.push(new Language(language))
      })
    }
  }
}
</script>


<style scoped>

</style>
