<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <draggable>
      <div v-if="item" class="d-flex position-relative item-tree-container px-5 mx-5">
        <div :id="'item_'+item.uuid" :data-parent="parent ? parent.uuid : null"
             class="draggable-item justify-content-center d-flex flex-column align-items-center border-dashed rounded
      bg-light-primary d-flex" style="width:110px;height:110px">
          <!--                    <i class="fad fa-building fs-2"></i>-->
          <div @click="toggleChildren" class="fw-bolder fs-8 px-2 text-uppercase text-center overflow-auto flex-grow-1
          d-flex align-items-center justify-content-center cursor-pointer" style="max-lines: 2;line-clamp: 2;">
            {{ property ? property.name : item.name }}

            <!--            <div class="text-muted fs-8">-->
            <!--            {{ item.name }}-->
            <!--              </div>-->
          </div>
          <div class="d-flex w-100 border-top border-1">
            <div title="Agences"
                 class="flex-grow-1 px-2 text-center cursor-pointer bg-hover-light rounded d-flex justify-content-center align-items-center">
              <div class="fw-bolder fs-5">
                0
              </div>
              <i class="fad fa-sitemap fs-9 text-dark ms-2"></i>
            </div>
            <div title="Utilisateurs"
                 class="flex-grow-1 text-center px-2 border-left border-1 rounded cursor-pointer bg-hover-light  d-flex justify-content-center align-items-center">
              <div class="fw-bolder fs-5">
                0
              </div>
              <i class="fad fa-users fs-9 text-dark ms-2"></i>
            </div>
          </div>
        </div>
        <Transition name="slide-fade">
          <div :style="{ '--i':  1 }"
               class="menu bg-white rounded shadow-xs px-1  position-absolute flex-grow-1 h-100 flex-column justify-content-start"
               style="left:100%;margin-left:-10px">
            <div class="btn btn-icon btn-xs my-1 btn-light-success btn-circle"
                 title="Modifier les informations de l'agence">
              <i class="fad fa-edit text-inverse-primary"></i>
            </div>
            <div class="btn btn-circle btn-icon btn-light-info btn-xs my-1" title="Ajouter une sous agence">
              <i class="fad fa-building-circle-check"></i>
            </div>
            <div class="btn btn-circle btn-icon btn-light-primary btn-xs my-1" title="Ajouter un utilisateur">
              <i class="fad fa-user-plus"></i>
            </div>
            <div class="btn btn-circle btn-icon btn-light-danger btn-xs my-1" title="Supprimer cette agence">
              <i class="fad fa-trash"></i>
            </div>
          </div>
        </Transition>
      </div>
      <div v-else-if="!parent"
           class="justify-content-center d-flex flex-column align-items-center border-dashed px-2 py-5 rounded bg-light-primary bg-hover-primary w-100px h-100px text-hover-white">
        <div>
          <i class="fad fa-plus fs-3x"></i>
        </div>
        <div class=" text-center">
          Créer une agence
        </div>
      </div>
    </draggable>
    <template v-if="item && showChildren">
      <div class="connector flex-grow-1 align-self-stretch d-flex justify-content-center position-relative">
        <svg :id="'connector_'+item.uuid" class="flex-grow-1 h-70px w-100" style="position: absolute;top:0;left:0">
          <template v-for="prop in item.properties">
            <template v-if="prop.model.type.code.includes('OBJECT')">
              <g v-for="(child, index) in prop.children">
                <path :id="'hidden_connect_'+ child.uuid" :ref="'link' + index" class="path link"/>
                <path :id="'connect_'+ child.uuid" :ref="'link' + index" class="path link"/>
              </g>
              <template v-if="prop.model.type.code.includes('OBJECT_GROUP') &&  prop.children.length < prop.model.max">
                <g>
                  <path :id="'hidden_connect_'+ prop.id" :ref="'link' + prop.id" class="path link"/>
                  <path :id="'connect_'+ prop.id" :ref="'link' + prop.id" class="path link"/>
                </g>
              </template>
            </template>
          </template>
        </svg>
        <div class="h-70px"></div>
      </div>
      <div class="d-flex justify-content-evenly align-items-start">
        <template v-for="prop in item.properties">
          <template v-if="prop.model.type.code.includes('OBJECT')">
            <template v-for="child in prop.children">
              <ItemTree :item="child" :key="child.uuid" :parent="item" :property="prop" @link="linkChildren"></ItemTree>
            </template>
            <template v-if="prop.model.type.code.includes('OBJECT_GROUP') &&  prop.children.length < prop.model.max">
              <div
                  class="draggable-item  mx-2 d-flex flex-column cursor-pointer bg-hover-light-primary align-items-center justify-content-center border-dashed rounded"
                  :id="'item_'+prop.id" :data-parent="item.uuid" style="width:110px;height:110px">
                <i class="fad fa-plus mb-3"></i>
                {{ prop.name }}
              </div>
            </template>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>


<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import Item from "@/entity/Item";
import Property from "@/entity/Property";

@Component({})
export default class ItemTree extends Vue {
  @Prop() item!: Item
  @Prop() parent?: Item
  @Prop() property?: Property
  showChildren = true
  children: Item[] = []

  editItem() {

  }

  mounted() {
    this.$emit('link')
  }

  toggleChildren() {
    this.showChildren = !this.showChildren
  }

  linkChildren() {
    if (this.item) {
      const parent: any = document.querySelector("#item_" + this.item.uuid);
      const children: any = document.querySelectorAll(".draggable-item[data-parent='" + this.item.uuid + "']")

      // let minX: any = null
      // let maxX: any = null
      //
      // children.forEach((child: any) => {
      //   const childRect = child.getBoundingClientRect();
      //   if (minX === null || childRect.left < minX) {
      //     minX = childRect.left
      //   }
      //   if (maxX === null || childRect.left > minX) {
      //     maxX = childRect.left
      //   }
      // })
      // if (minX && maxX) {
      //   const middle = (maxX - minX) / 2
      //   console.log(this.agency.label + ' => ' + middle)
      //   parent.style.marginLeft = middle + 'px'
      // }

      // return
      const svg: any = document.querySelector('#connector_' + this.item.uuid)
      if (parent && svg) {
        const parentRect = parent!.getBoundingClientRect();
        const svgRect = svg.getBoundingClientRect();

        const startX = parentRect.left + parentRect.width / 2 - svgRect.left;
        const startY = parentRect.bottom - svgRect.top;

        children.forEach((child: any) => {
          const childRect = child.getBoundingClientRect();
          const endX = childRect.left + childRect.width / 2 - svgRect.left;
          const endY = childRect.top - svgRect.top;
          const uuid = child.id.replace('item_', '')
          let path = document.querySelector("#connector_" + this.item.uuid + " #connect_" + uuid)!
          let hiddenPath = document.querySelector("#connector_" + this.item.uuid + " #hidden_connect_" + uuid)!
          if (path) {
            const delta1 = 0;
            const delta2 = 30;
            const delta3 = 0;
            const delta4 = 30;
            const controlX1 = startX + delta1;
            const controlY1 = startY + delta2;
            const controlX2 = endX - delta3;
            const controlY2 = endY - delta4;

            const d = `M ${startX} ${startY} C ${controlX1} ${controlY1}, ${controlX2} ${controlY2}, ${endX} ${endY}`;
            path.setAttribute('d', d);
            path.setAttribute('stroke', 'gainsboro');
            path.setAttribute('stroke-width', '2');
            path.setAttribute('fill', 'none');
            path.setAttribute('stroke-dasharray', '5, 5');

            hiddenPath.setAttribute('d', d);
            hiddenPath.setAttribute('stroke', 'transparent');
            hiddenPath.setAttribute('stroke-width', '20');
            hiddenPath.setAttribute('fill', 'none');

          } else {
            console.log('path ' + "#connector_" + this.item.uuid + " #connect_" + uuid + ' not found')
          }

        })
      }
    }
    this.$emit('link')
  }
}
</script>


<style scoped>
path:hover + path {
  stroke: red;
}

.item-tree-container > .menu {
  opacity: 0;
  display: flex;
  transition: all .5s ease;
  //transform: translateX(-10px);
}

.item-tree-container:hover > .menu {
  opacity: 1;
  transform: translateX(0);
}

</style>
