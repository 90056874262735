<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                  <i class="fad fa-home"></i>
                  {{ trans('Dashboard') }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>


        <div class="d-flex align-items-center py-1">
          <button v-b-modal.modal-create @click="campaign = item" class="btn-sm btn btn-primary ms-5">
            <i class="fad fa-plus"></i>
            {{ trans('Créer') }}
          </button>

        </div>


        <b-modal id="modal-create" @ok="edit" centered title="Créer une campagne">

          <div class="mb-3">
            <label for="name" class="form-label">Nom</label>
            <input type="text" name="name" class="form-control" v-model="campaign.name" id="name" />
          </div>


          <div class="mb-3">
            <label for="code" class="form-label">CODE</label>
            <input type="text" name="code" class="form-control" v-model="campaign.code" id="code" />
          </div>

          <div class="mb-3">
            <label for="description" class="form-label">Description</label>
            <textarea class="form-control" id="description" v-model="campaign.description" rows="3"></textarea>
          </div>



        </b-modal>

      </div>
    </div>
  </template>

  <template #content>
    <div class="card">
      <div class="card-body">

        <table class="table table-striped gx-5">
          <thead>
            <tr>
              <th>
                {{ trans('Campagne') }}
              </th>
             
              <th>
                {{ trans('Actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in campaigns">
              <td>
                <div class="fw-bolder">
                  {{ item.name }}
                </div>
                <div>
                  {{ item.description }}
                </div>
              </td>

              <td>
                <router-link :to="{ name: 'campaign-create', params: { id: item.id } }"
                  class="btn btn-primary btn-icon btn-sm">
                  <i class="fad fa-eye"></i>

                
                </router-link>
                
                <div v-b-modal.modal-create @click="campaign = item"  class="btn btn-sm btn-icon btn-warning ms-2">
                  <i class="fad fa-edit"></i>
              
              </div>

                <div class="btn btn-sm btn-icon btn-danger ms-2" @click="remove(campaign)">
                  <i class="fad fa-trash-alt"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  </Base>
</template>


<script lang="ts">
import Base from "@/layouts/Base.vue";
import { Vue, Component } from 'vue-property-decorator';
import { api } from "@/services/Api";
import Campaign from "@/entity/Campaign";


@Component({
  components: { Base }
})
export default class CampaignList extends Vue {

  campaigns: Campaign[] = []
  campaign: Campaign = new Campaign()
item = new Campaign()


  mounted() {
    this.load()
  }


  


  async remove(campaign: Campaign) {
    const res = await api.get(api.com, 'campaign/delete/' + campaign.id)
    if (res) {
      this.load()
    }
  }

  async edit() {
    this.$store.commit('loading')
    const res = await api.post(api.com, 'campaign/edit', {campaign:this.campaign})

    if (res) {
      this.$router.push({name:'campaign-create' ,  params: { id: res.data.campaign.id }})
    }

    this.$store.commit('stopLoading')
  }
  async load() {
    this.$store.commit('loading')
    const res = await api.get(api.com, 'campaign/list')
    if (res && res.data) {
      this.campaigns = []
      res.data.campaigns.forEach((t: any) => {
        const occ = new Campaign(t)
        this.campaigns.push(occ)
      })
    }
    this.$store.commit('stopLoading')

  }

}
</script>


<style scoped></style>