/* eslint-disabled */
import SmsTemplate from "./SmsTemplate";
import SmsJobExtend from "@/entity/extends/SmsJobExtend";

export default class SmsJob extends SmsJobExtend {

public id = '';
public recipients = '';
public template!: SmsTemplate;

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.recipients= object.recipients;
this.template = (object.template instanceof SmsTemplate) ? object.template : object.template ? new SmsTemplate(object.template) : object.template;
      }
      this.postConstruct()
  }

}
