<template>
    <div class="flex-grow-1 m-2 " :key="'question_'+question.uuid">
        <label class="fw-bolder my-2 d-flex justify-content-between" for="">
            {{question.label}}

            <!--            <span @click="$store.commit('resetResponse',question)" class="btn btn-xs btn-icon" v-if="hasRole('ROLE_MANAGER')"><i class="fad fa-trash text-danger"></i></span>-->
        </label>

        <b-input-group
                :class="[ question.isSending ? 'border-secondary' : question.isValid ? 'border-success' : 'border-danger' ]"
                class="rounded border-1 d-flex flex-grow-1" style="border-style:solid">
            <b-input-group-prepend class="d-flex flex-column" v-if="question.prefix">
                <div class="input-group-text flex-grow-1">
                    <template v-if=" isIcon(question.prefix)">
                        <i :class="question.prefix"></i>
                    </template>
                    <template v-else>
                        {{ question.prefix }}
                    </template>
                </div>
            </b-input-group-prepend>
            <div class="input-container flex-grow-1 rounded  d-flex">
                <select :state="!helper.empty(question.defaultValue)"
                        @change="question.updateAnswer()"
                               class="form-control form-select"
                        v-model.trim="question.response.value">
                    <b-form-select-option :value="null">{{trans('Selectionnez une catégorie')}}
                    </b-form-select-option>
                    <b-form-select-option :key="category.uuid" :value="category.slug" v-for="category,key in categories">
                        {{trans(category.label)}}
                    </b-form-select-option>
                </select>
                <span :title="trans('Mise à jour en cours')" class="input-status" v-b-tooltip.hover v-if="question.isSending">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
                <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="!question.isValid">
                    <i class="fad fa-exclamation-circle text-danger"></i>
                </span>
                <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="question.isValid">
                    <i class="fad fa-check-circle text-success"></i>
                </span>
            </div>
            <b-input-group-append v-if="question.suffix">
                {{question.suffix}}
            </b-input-group-append>
        </b-input-group>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Question from "@/entity/Question";
    import {api} from "@/services/Api";

    @Component({})
    export default class PackCategory extends Vue {
        @Prop() question!: Question
        type = 'text'
        categories: PackCategory[] = []

        mounted() {
            // console.log(this.question)
            this.load()
        }

        async load() {
            // console.log(this.question)
            this.categories = await this.$store.getters.packCategories(this.question.branchSlug)
        }
    }
</script>
<style scoped>
    .input-container {
        position: relative;
    }

    .input-status {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        cursor: pointer;
    }

</style>
