<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Survey_list') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div class="btn btn-primary btn-sm" v-b-modal="'editPartner'">
              <i class="fad fa-plus"></i>
              Créer
            </div>
            <div @click="generate" class="btn btn-success ms-2 btn-sm">
              <i class="fad fa-cogs"></i>
              Générer 10 nouveaux codes
            </div>
            <router-link :to="{name : 'partner-parameters'}" class="btn btn-warning ms-2 btn-sm">
              <i class="fad fa-edit"></i>
              Gérer les paramètres
            </router-link>
            <router-link :to="{name : 'partner-pack-restriction'}" class="btn btn-danger ms-2 btn-sm">
              <i class="fad fa-ban"></i>
              Restreindre les offres
            </router-link>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-auto flex-grow-1">
              <table class="table table-striped gx-5">
                <thead>
                <tr>
                  <th></th>
                  <th>Apporteur</th>
                  <th>Code</th>
                  <th>Methodes</th>
                  <th>Cotation</th>
                  <th>Contrat</th>
                  <th>Moyenne d'avancement</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="partner in partners">
                  <tr :key="partner.uuid" v-if="showDeleted || helper.empty(partner.deletedAt)">
                    <td>
                      <div class="form-check form-switch form-check-custom form-check-solid ">
                        <input @change="toggleActivation(partner)" class="form-check-input h-20px w-30px"
                               type="checkbox" v-model="partner.active" value=""/>
                      </div>
                    </td>
                    <td>
                      {{ partner.name }}
                    </td>
                    <td>
                      {{ partner.code }}
                    </td>
                    <td>
                      <div class="d-flex flex-wrap">
                        <template v-for="method in methods">

                          <div v-if="isMethodActiveForPartner(method, partner)" class="m-1">
                            <!-- {{ method.label }} -->
                            <img :src="uploadRoot + method.photo" alt="" class="w-30px h-30px rounded"
                                 :title="method.label"/>
                          </div>
                        </template>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <router-link :to="{name : 'partner-form', params: {code:partner.code}}"
                                   class="btn btn-xs btn-icon btn-primary" @click="editPartner(partner)">
                        <i class="fad fa-edit"></i>
                      </router-link>
                      <div class="btn btn-xs btn-icon btn-warning ms-2" @click="editPartnerIdentifier(partner)">
                        <i class="fad fa-shield"></i>
                      </div>

                      <div class="btn btn-xs btn-icon btn-danger ms-2" @click="removePartner(partner)">
                        <i class="fad fa-trash"></i>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <b-modal :title="trans('Créer un partenaire')" centered id="editPartner" ref="editPartner">
        <form>
          <div class="row" v-if="partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(partner.name)" v-model="partner.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(partner.code)" v-model="partner.code" v-uppercase>
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(partner.contact)" v-model="partner.contact">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{
                  trans('Methode de paiement autorisée')
                }}</label>
              <div class="d-flex flex-wrap  justify-content-between">
                <div v-for="method in methods" class="m-2 fw-bolder d-flex align-items-center">
                  <label class="form-check form-switch form-check-custom form-check-solid m-2">
                    <input :checked="isMethodActiveForPartner(method, partner)"
                           @change="toggleMethodForPartner(method, partner)" class="form-check-input"
                           type="checkbox"/>
                  </label>
                  {{ method.label }}
                </div>
              </div>

            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="savePartner" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>


      <b-modal :title="trans('Identifier')" centered id="editPartnerIdentifier" ref="editPartnerIdentifier">
        <form>
          <div class="row" v-if="partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Nom d\'utilisateur') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(partner.username)" v-model="partner.username">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Mot de passe') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(partner.password)" v-model="partner.password">
              </b-form-input>
            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="savePartnerIdentifier" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

      <!-- <div class="my-20">
          <Pagination :count="total" :itemLength="contracts.length" :matchCount="matchCount"
              :perPage="searched.perPage" :showEmpty="true" @change="search" v-model="searched.page"></Pagination>
      </div> -->
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from '@/services/Api';
import Partner from '@/entity/Partner';
import Loading from "@/components/Loading.vue";
import PaymentMethod from '@/entity/PaymentMethod';
import Insurer from "@/entity/Insurer";
import Popup from "@/entity/Popup";
import Swal from "sweetalert2";

@Component({
  components: {Base, Loading}
})
export default class PartnerList extends Vue {

  partner: Partner = new Partner()
  partners: Partner[] = []
  methods: PaymentMethod[] = []
  uploadRoot = api.uploadRoot
  showDeleted = false

  mounted() {
    this.loadPartners()
  }

  async toggleActivation(partner: Partner) {
    let message = 'Activation';
    if (partner.active) {
      message = 'Désactivation';
    }
    const p = new Popup(partner.name, message, 'primary', 'fad fa-sync', true);
    const res = await api.get(api.core, 'partner/toggle/activation/' + partner.id);
    p.hide()
    if (res && res.data) {
      partner.active = res.data.state
    }
  }

  isMethodActiveForPartner(method: PaymentMethod, partner: Partner) {
    return partner.paymentMethods.find((p: PaymentMethod) => p.id === method.id) ? true : false
  }

  async removePartner(partner: Partner) {
    const reset = await Swal.fire({
      title: 'Suppression',
      icon: 'question',
      html: 'Êtes-vous sûr de vouloir supprimer ce partenaire et tous ses utilisateurs ?',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
          'Supprimer',
      confirmButtonAriaLabel: 'Supprimer',
      cancelButtonText:
          'Annuler',
      cancelButtonAriaLabel: 'Annuler'
    })
    if (reset.isConfirmed) {
      this.$store.commit('loading')
      const res = await api.get(api.core, 'partner/remove/' + partner.id)
      this.$store.commit('stopLoading')
      this.loadPartners()
    }
  }

  async loadPartners() {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'partner/top/list')
    if (res && res.data) {
      this.partners = []
      this.methods = []
      res.data.partners.forEach((p: Partner) => {
        this.partners.push(new Partner(p))
      })
      res.data.methods.forEach((p: PaymentMethod) => {
        this.methods.push(new PaymentMethod(p))
      })
    }
    this.$store.commit('stopLoading')
  }

  toggleMethodForPartner(method: PaymentMethod, partner: Partner) {
    const index = partner.paymentMethods.findIndex((p: PaymentMethod) => p.id === method.id)
    console.log(index)
    if (index >= 0) {
      partner.paymentMethods.splice(index, 1)
    } else {
      partner.paymentMethods.push(method)
    }
  }

  editPartner(partner: Partner) {
    this.partner = new Partner({...partner})
    const refs = this.$refs as any;
    if (refs.editPartner) {
      refs.editPartner.show()
    }
  }

  editPartnerIdentifier(partner: Partner) {
    this.partner = new Partner({...partner})
    const refs = this.$refs as any;
    if (refs.editPartnerIdentifier) {
      refs.editPartnerIdentifier.show()
    }
  }

  async savePartner() {
    let partner = {...this.partner};
    // pc.branch = {slug: (pc.branch as any).slug, uuid: (pc.branch as any).uuid} as any
    const res = await api.post(api.core, 'partner/edit', {partner})
    // return
    if (res && res.data) {
      const refs = this.$refs as any;
      if (refs.editPartner) {
        refs.editPartner.hide()
      }
      this.loadPartners()
    }
  }

  async savePartnerIdentifier() {
    let partner = {...this.partner};
    // pc.branch = {slug: (pc.branch as any).slug, uuid: (pc.branch as any).uuid} as any
    const res = await api.post(api.auth, 'partner/edit', {partner})
    // return
    if (res && res.data) {
      const refs = this.$refs as any;
      if (refs.editPartnerIdentifier) {
        refs.editPartnerIdentifier.hide()
      }
      this.loadPartners()
    }
  }

  async generate() {
    const res = await api.get(api.core, 'partner/generate')
    this.loadPartners()
  }
}
</script>
<style scoped>

</style>
