<template>
  <Base>
    <template v-slot:content>
      <div class="card ">
        <div class="card-body ">
          <Loading v-if="isLoading"></Loading>
          <div v-else>
            <table class="table table-striped g-2 w-100">
              <thead>
              <tr>
                <td>
                  Parrain
                </td>
                <td>
                  Parrainé
                </td>
                <td>
                  Crée le
                </td>
                <td>
                  Date de validation
                </td>
                <td>
                  Parrain
                </td>
                <td>
                  Fileul
                </td>
                <td>
                  Statut
                </td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="parrainage of parrainages">
                <td>
                  <div>
                    <div>
                      {{ parrainage.godfather.name }} {{ parrainage.godfather.prename }}
                    </div>
                    <div class="badge badge-primary">
                      {{ parrainage.godfather.code }}
                    </div>
                    <div>
                      {{ parrainage.godfather.phone }}
                    </div>
                  </div>
                </td>
                <td>

                  <div>
                    <div>
                      {{ parrainage.godson.name }} {{ parrainage.godson.prename }}
                    </div>
                    <div class="badge badge-primary">
                      {{ parrainage.godson.code }}
                    </div>
                    <div>
                      {{ parrainage.godson.phone }}
                    </div>
                  </div>
                </td>
                <td>
                  <div v-if="!empty(parrainage.createdAt)" class="d-flex align-items-center">
                    <!--                    <i class="fad fa-thumbs-up fs-2 text-success me-2"></i>-->
                    <span>
                      {{ datify(parrainage.createdAt, 'dmY H:i') }}
                    </span>
                  </div>
                </td>
                <td>
                  <div v-if="!empty(parrainage.validatedAt)">
                    <div class="d-flex align-items-center text-success fw-bolder">
                      <!--                    <i class="fad fa-thumbs-up fs-2 text-success me-2"></i>-->
                      <span>
                      {{ datify(parrainage.validatedAt, 'dmY H:i') }}
                    </span>
                    </div>
                    <div class="badge badge-primary">
                      {{ currency(parrainage.contractPremium) }}
                    </div>
                  </div>
                </td>
                <td>
                  {{ currency(parrainage.godfatherPremium) }}
                </td>
                <td>
                  {{ currency(parrainage.godsonPremium) }}
                </td>
                <td>
                  <b-dropdown class="add-line-before" no-caret no-flip right tag="div"
                              toggle-class="topbar-item text-decoration-none p-0" variant="link">
                    <template v-slot:button-content>
                      <div class="btn  btn-primary">
                        Action
                      </div>
                    </template>
                    <b-dd-item v-if="parrainage.responseGroup" target="_blank"
                               :to="{name: 'show-cotation', params:{ uuid: parrainage.responseGroup }}">
                      Cotation
                    </b-dd-item>
                    <b-dd-item v-if="parrainage.orderUuid" target="_blank"
                               :to="{name: 'show-order', params:{ uuid: parrainage.orderUuid }}">
                      Devis
                    </b-dd-item>
                    <b-dd-item v-if="parrainage.contract" target="_blank"
                               :to="{name: 'contract-show', params:{ contractUuid: parrainage.contract }}">
                      Contrat
                    </b-dd-item>
                  </b-dropdown>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Pagination :count="total" :itemLength="parrainages.length" :matchCount="matchCount" v-model="searched.page"
                  showEmpty="true" class="p-10"
                  :perPage="searched.max" :key="'pagination_'+searched.page"
                  @input="search($event)"></Pagination>
    </template>
  </Base>
</template>


<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import FileUpload from "@/components/FileUpload.vue";
import Loading from "@/components/Loading.vue";
import UserView from "@/components/UserView.vue";
import {api} from "@/services/Api";
import Parrainage from "@/entity/Parrainage";
import Pagination from "@/components/Pagination.vue";

@Component({
  components: {Pagination, UserView, Loading, FileUpload, Base}
})
export default class ParrainageList extends Vue {
  isLoading = false
  parrainages: any[] = []
  total = 0
  matchCount = 0
  searchTemplate = {
    branch: null,
    after: null,
    before: null,
    contains: null,
    minimalState: 0,
    hasClientInfos: false,
    manager: null,
    page: 1,
    max: 100,
    statut: ['VALIDEE', 'EN ATTENTE'] as any
  }
  searched: any = {}

  mounted() {
    this.searched = {...this.searchTemplate}
    this.search()
  }

  async search(page: number | null = null) {
    if (page) {
      if (!isNaN(page!)) {
        // console.log('setting page ', page)
        this.searched.page = parseInt(page + '')
      }
    }
    const res = await api.post(api.auth, 'parrainage/list', this.searched)
    if (res && res.data && res.data.parrainages) {
      console.log(res.data)
      this.parrainages = []
      this.matchCount = res.data.count
      this.total = res.data.total
      res.data.parrainages.forEach((p: any) => {
        const occ = new Parrainage(p);
        this.parrainages.push(occ)
      })

    }
  }
}


</script>


<style scoped>

</style>
