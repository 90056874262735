<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Survey_list') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div class="d-flex align-items-center">
              <div class="mx-2">
                du
              </div>
              <div class="mx-2">
                <b-form-datepicker size="sm" type="date" class="form-control" v-model="interval.start">
                </b-form-datepicker>
              </div>
              <div class="mx-2">
                au
              </div>
              <div class="mx-2">
                <b-form-datepicker size="sm" type="date" class="form-control" v-model="interval.end">
                </b-form-datepicker>
              </div>
              <div>
                <button class="btn btn-icon btn-sm btn-primary" @click="load">
                  <i class="fad fa-search"></i>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex fs-2 justify-content-between align-items-center mb-5">
        <div class="d-flex align-items-center">
          <select name="" class="form-control form-select mx-1">
            <option :value="null">
              Tous les assureurs
            </option>
            <option v-for="insurer in insurers">
              {{ insurer.label }}
            </option>
          </select>

          <select name="" class="form-control form-select mx-1">
            <option :value="null">
              Toutes les branches
            </option>
            <template v-for="cat in branchCategories">
              <option v-for="branch in cat.branches">
                {{ branch.label }}
              </option>
            </template>

          </select>

        </div>
        <div>
          <div class="btn btn-primary btn-sm mx-1">
            Prime Nette : {{ currency(netSum) }}
          </div>
          <div class="btn btn-primary btn-sm mx-1">
            Prime TTC : {{ currency(ttcSum) }}
          </div>

          <div class="btn btn-danger btn-sm mx-1">
            Encaissement : {{ currency(paySum) }}
          </div>
          <div class="btn btn-danger btn-sm mx-1">
            Restant : {{ currency(ttcSum - paySum) }}
          </div>
        </div>
      </div>
      <b-tabs active-nav-item-class="" active-tab-class="bg-white p-5"
              nav-wrapper-class="mt-10  border-0 nav-line-tabs nav-line-tabs-2x fs-5 fw-bolder flex-nowrap flex-grow-1">
        <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
          <template #title>
            <div class="d-flex align-items-center ">
              <i class="fad fa-arrow-alt-up fs-2 me-2"></i>
              Emissions
            </div>
          </template>
          <template #default>
            <div style="width:100%;overflow:auto">
              <div class="d-flex justify-content-end  mb-5">
                <button :disabled="loadingEmission" class="btn btn-primary d-flex align-items-center">
                  <b-spinner label="Spinning" class="me-2" v-if="loadingEmission"></b-spinner>
                  <download-excel :data="emissions" :fields="emissionData"
                                  :worksheet="'Emissions_'+readable(interval.start,'dMy')+'_au_'+readable(interval.end,'dMy')"
                                  :name="'Emissions_'+readable(interval.start,'dMy')+'_au_'+readable(interval.end,'dMy')+'.xls'">
                    Télécharger
                  </download-excel>
                </button>
              </div>
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                      <input type="checkbox" class="form-check-input">
                    </div>
                  </th>
                  <th>Date</th>
                  <th>Actif</th>
                  <th>Risque</th>
                  <th>Assureur</th>
                  <th>Assuré</th>
                  <th>N0 de Telephone</th>
                  <th>Opération</th>
                  <th>Police</th>
                
                  <th>Provenance</th>
                  <th>Emission</th>
                  <th>Effet</th>
                  <th>Echéance</th>
                  <th>Accessoire Cie</th>
                  <th>Accessoire WA</th>
                  <th>Taxe</th>
                  <th>FGA</th>
                  <th>Prime Nette</th>
                  <th>Prime TTC</th>
                  <th>Encaissé</th>
                  <th>Solde</th>
                  <th v-if="hasRole('ROLE_MANAGER')">Commission</th>
                  <th>
                    <template v-if="hasRole('ROLE_MANAGER')">
                      Commission Apporteur
                    </template>
                    <template v-else-if="hasRole('ROLE_PARTNER')">
                      Commission Apporteur
                    </template>
                  </th>
                  <th v-if="hasRole('ROLE_PARTNER')">BNC</th>
                  <th v-if="hasRole('ROLE_PARTNER')">Commission Nette</th>
                  <th v-if="hasRole('ROLE_MANAGER')">Déclaré le</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="avenant,index in emissions">
                    <td>
                      <div class="text-center fw-bolde text-gray-400">
                        {{ index + 1 }}
                      </div>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input type="checkbox" class="form-check-input">
                      </div>
                    </td>
                    <td>
                      <!-- {{ helper.dateToHtml(avenant.dateEmission) }} -->
                      {{ readableDate(avenant.dateEmission, 'dMy') }}
                    </td>
                    <td>

                    </td>
                    <td>
                      {{ branchLabelByUuid(avenant.branch) }}
                    </td>
                    <td>
                      {{ insurerLabelByUuid(avenant.insurer) }}
                    </td>
                    <td>
                      {{ avenant.customer.identifier }}
                    </td>
                    <td>
                      {{ avenant.customer.mobile }}
                    </td>
                    <td>
                      {{ avenant.type }}
                    </td>


                    <td>
                      {{ avenant.police }}
                    </td>

                   

                    <td>
                      {{avenant.createBy}}
                    </td>

                    <td>
                      <span class="fw-bolder">
                      <user-view v-model="avenant.emitBy"></user-view>
                    </span>
                    </td>

                    <td style="text-align:right">
                      {{ readableDate(avenant.dateEffet, 'dMy') }}
                    </td>
                    <td style="text-align:right">
                      <template v-if="avenant.dateEcheance">
                        {{ readableDate(avenant.dateEcheance, 'dMy') }}
                      </template>
                    </td>
                    <td class="text-end">
                      {{ currency(getDetail(avenant, 'accessoire')) }}
                    </td>
                    <td>

                    </td>
                    <td class="text-end">
                      {{ currency(getDetail(avenant, 'taxe')) }}
                    </td>
                    <td class="text-end">
                      {{ currency(getDetail(avenant, 'fga')) }}
                    </td>
                    <td class="text-end">
                      {{ currency(primeNette(avenant)) }}
                    </td>
                    <td class="text-end">
                      {{ currency(avenant.primeTTC) }}
                    </td>
                    <td class="text-end">
                      {{ currency(avenant.primeTTC + avenant.balance) }}
                    </td>
                    <td class="text-end fw-bolder text-danger">
                      {{ currency(avenant.balance, true) }}
                    </td>
                    <td v-if="hasRole('ROLE_MANAGER')" class="text-end">
                      {{ currency(avenant.commission) }}
                    </td>
                    <td class="text-end">
                      {{ currency(avenant.commissionApporteur) }}
                    </td>
                    <td class="text-end">
                      {{ currency(avenant.commissionApporteur * 0.075) }}
                    </td>
                    <td class="text-end">
                      {{ currency(avenant.commissionApporteur - (avenant.commissionApporteur * 0.075)) }}
                    </td>
                    <td style="text-align:right" v-if="hasRole('ROLE_MANAGER')" class="text-end">
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                  </tr>
                </template>

                </tbody>
              </table>
              <div v-if="loadingEmission">
                <Loading></Loading>
              </div>
            </div>
          </template>
        </b-tab>
        <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
          <template #title>
            <div class="d-flex align-items-center ">
              <i class="fad fa-arrow-alt-down fs-2 me-2"></i>
              Encaissements
            </div>
          </template>
          <template #default>
            <table class="table table-striped gy-3 gs-3">
              <thead>
              <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                <th>Date</th>
                <th>Type</th>
                <th>Initiateur</th>
                <th>Contrat</th>
                <th>Client</th>
                <th>Montant</th>
                <!-- <th>Reste à payer</th> -->
                <th>Statut</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="loadingPayment">
                <td colspan="12">
                  <Loading></Loading>
                </td>

              </tr>
              <template v-else>
                <tr v-for="payment in payments">
                  <td>
                    {{ readableDate(payment.createdAt, 'dMy H:i') }}
                    <!-- {{ helper.dateToHtml(avenant.dateEmission) }} -->
                  </td>
                  <td>
                    {{ payment.type }}
                  </td>
                  <td>
                    <user-view v-model="payment.initiator"/>

                  </td>
                  <td>
                    <!-- {{payment.avenant.branch}} -->
                    {{ insurerLabelByUuid(payment.avenant.insurer) }}
                    ◆
                    {{ branchLabelByUuid(payment.avenant.branch) }}
                  </td>
                  <td>
                    <!-- {{}} -->
                  </td>
                  <td>
                    {{ currency(payment.amount) }}
                  </td>
                  <td>
                                        <span class="badge" :class="{
                                            'badge-success': payment.status === CONSTANTS.PAYMENT_STATUS.SUCCEED,
                                            'badge-danger': payment.status === CONSTANTS.PAYMENT_STATUS.FAILED,
                                            'badge-secondary': payment.status === CONSTANTS.PAYMENT_STATUS.WAITING,
                                        }">
                                            {{ payment.status }}
                                        </span>

                  </td>
                  <td>

                  </td>

                </tr>
              </template>
              </tbody>
            </table>
          </template>
        </b-tab>
        <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
          <template #title>
            <div class="d-flex align-items-center ">
              <i class="fad fa-coins fs-2 me-2"></i>
              Relicats
            </div>
          </template>
        </b-tab>
        <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
          <template #title>
            <div class="d-flex align-items-center ">
              <i class="fad fa-file-contract fs-2 me-2"></i>
              Reversements
            </div>
          </template>

          <template #default>
            <table class="table table-stripped gy-3 gs-3">
              <thead>
              <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                <th>Date</th>
                <th>Risque</th>
                <th>Assureur</th>
                <th>Police</th>
                <th>Attestation</th>
                <th>Carte Brune</th>
                <th>Assuré</th>
                <th>Effet</th>
                <th>Echéance</th>
                <th>Prime Nette</th>
                <th>Prime TTC</th>
                <th>Payé</th>
                <th>Commission</th>
                <th>Transmis</th>
              </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
          </template>
        </b-tab>
        <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
          <template #title>
            <div class="d-flex align-items-center ">
              <i class="fad fa-sort-alt fs-2 me-2"></i>
              Bilan
            </div>
          </template>

          <template #default>
            <div style="width:100%;overflow:auto">
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Date</th>
                  <th>Risque</th>
                  <th>Assureur</th>
                  <th>Police</th>
                  <th>Attestation</th>
                  <th>Carte Brune</th>
                  <th>Assuré</th>
                  <th>Actif</th>
                  <th>Opération</th>
                  <th>Provenance</th>
                  <th>Effet</th>
                  <th>Echéance</th>
                  <th>Prime Nette</th>
                  <th>Prime TTC</th>
                  <th>Payé</th>
                  <th>Reste à Payer</th>
                  <th>Mode de règlement (+ID)</th>
                  <th>Transmis le</th>
                  <th>Versé le</th>
                  <th>Commission</th>
                  <th>Reçu le</th>
                  <th>Mode de paiement</th>
                  <th>Fichiers physiques</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loadingEmission">
                  <td colspan="14">
                    <Loading></Loading>
                  </td>
                </tr>
                <template v-else>
                  <tr v-for="avenant in emissions">
                    <td>
                      <!-- {{ helper.dateToHtml(avenant.dateEmission) }} -->
                      {{ readableDate(avenant.dateEmission, 'dMy') }}
                    </td>

                    <td>
                      {{ branchLabelByUuid(avenant.branch) }}
                    </td>
                    <td>
                      {{ insurerLabelByUuid(avenant.insurer) }}
                    </td>
                    <td>
                      {{ avenant.police }}
                    </td>
                    <td>
                      <!-- attestation -->
                    </td>
                    <td>
                      <!-- carte brune -->
                    </td>
                    <td>
                      <!-- assuré -->
                      {{ avenant.customer.identifier }}
                    </td>
                    <td>
                      <!-- immatriculation -->
                      {{ avenant.assetLabels.join(',') }}
                    </td>
                    <td>
                      {{ avenant.type }}
                    </td>
                    <td>
                      <user-view v-model="avenant.source"/>
                    </td>
                    <td style="text-align:right">
                      {{ readableDate(avenant.dateEffet, 'dmY') }}
                    </td>
                    <td style="text-align:right">
                      <template v-if="avenant.dateEcheance">
                        {{ readableDate(avenant.dateEcheance, 'dmY') }}
                      </template>
                    </td>
                    <td>
                      {{ currency(primeNette(avenant)) }}
                    </td>
                    <td>
                      {{ currency(avenant.primeTTC) }}
                    </td>
                    <td>
                      {{ currency(avenant.amountPayed) }}
                    </td>
                    <td>
                      {{ currency(avenant.balance, true) }}
                    </td>

                    <td>
                      <!-- mode de reglement -->
                    </td>
                    <td style="text-align:right">
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                    <td>
                      <!-- versé le -->
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                    <td>
                      <!-- commission -->
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                    <td>
                      <!-- commission recu le -->
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                    <td>
                      <!-- Mode de paiement -->
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                    <td>
                      <!-- fichier physique -->
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>

                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </template>
        </b-tab>
      </b-tabs>

    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import {helper} from "@/services/Helper";
import InputNumber from "@/components/InputNumber.vue";
import Popup from "@/entity/Popup";
import UserView from "@/components/UserView.vue";

import BranchCategory from '@/entity/BranchCategory';
import CONSTANTS from '@/Utils/constant';
import Loading from "@/components/Loading.vue";
import Avenant from '@/entity/Avenant';
import Insurer from '@/entity/Insurer';
import PaymentTransaction from '@/entity/PaymentTransaction';
import AccountOperation from '@/entity/AccountOperation';

@Component({
  components: {InputNumber, Base, Loading, UserView}
})
export default class IncomeState extends Vue {
  cotationCount = 0;
  cotationCompleted = 0;
  cotationProcessed = 0;
  contactCount = 0;

  emissionData = {
    'Date': {
      field: 'dateEmission',
      callback: (value: any) => {
        return helper.readable(value, 'dmY');
      }
    },
    'Risque': {
      field: 'branch',
      callback: (value: any) => {
        return this.branchLabelByUuid(value)
      }
    },
    'Assureur': {
      field: 'insurer',
      callback: (value: any) => {
        return this.insurerLabelByUuid(value)
      }
    },
    'Assure': {
      field: 'customer.identifier',
    },
    'Operation': {
      field: 'operation'
    },
    'Police': {
      field: 'police',
      callback: (value: any) => {
        return ' ' + value
      }
    },
    'Effet':  {
      field: 'dateEffet',
      callback: (value: any) => {
        return helper.readable(value, 'dmY');
      }
    },
    'Echeance':  {
      field: 'dateEcheance',
      callback: (value: any) => {
        return helper.readable(value, 'dmY');
      }
    },
    'Accessoire Compagnie':  {

      callback: (value: any) => {
        return this.getDetail(value, 'accessoire');
      }
    },
    'Accessoire WiASSUR':  {
      // field: 'dateEmission',
      callback: (value: any) => {
        return null;
      }
    },
    'Taxe':  {
      callback: (value: any) => {
        return this.getDetail(value, 'taxe');
      }
    },
    'FGA':  {
      callback: (value: any) => {
        return this.getDetail(value, 'fga');
      }
    },
    'Prime Nette':  {
      callback: (value: any) => {
        return this.getDetail(value, 'prime-nette');
      }
    },
    'Prime TTC':  {
      field: 'primeTTC',
      callback: (value: any) => {
        return value;
      }
    },
    'Encaissement':  {
      // field: 'dateEmission',
      callback: (value: any) => {
        return value.primeTTC + value.balance;
      }
    },
    'Solde':  {
      field: 'balance',
      callback: (value: any) => {
        return Math.abs(value);
      }
    },
    'Commission':  {
      field: 'commission',
      callback: (value: any) => {
        return value;
      }
    },
    'Commission Apporteur':  {
      field: 'commissionApporteur',
      callback: (value: any) => {
        return value;
      }
    },
    'BNC':  {
      // field: 'dateEmission',
      callback: (value: any) => {
        return value.commissionApporteur * 0.075;
      }
    },
    'Commission Nette':  {
      // field: 'dateEmission',
      callback: (value: any) => {
        return value.commissionApporteur - (value.commissionApporteur * 0.075);
      }
    },
  } as any

  perPage = 5000;
  // fdcm = CONSTANTS.dateConstants.first_day_current_month()
  maxPage = 1;
  interval = {
    start: CONSTANTS.dateConstants.first_day_current_month(),
    end: CONSTANTS.dateConstants.last_day_current_month()
  }
  emissions: Avenant[] = []
  payments: PaymentTransaction[] = []
  branchCategories: BranchCategory[] = []
  insurers: Insurer[] = []
  searching = false
  searched: any = {
    branch: null,
    customer: null,
    police: null,
    emitMin: null,
    emitMax: null,
    effetMin: null,
    effetMax: null,
    expireMin: null,
    expireMax: null,
    createMin: null,
    createMax: null,
    page: 1,
    perPage: 50
  }
  page = 1;
  loading = false
  gettingCount = false
  gettingCustomer = false;
  gettingComplete = false;


  resetSearch() {
    this.searched = {
      branch: null,
      customer: null,
      police: null,
      emitMin: null,
      emitMax: null,
      effetMin: null,
      effetMax: null,
      expireMin: null,
      expireMax: null,
      createMin: null,
      createMax: null,
      page: 1,
      perPage: 50
    }
  }

  isLoading = false

  async search() {
    this.load()
  }

  async mounted() {
    let isi = localStorage.getItem('incomeStateInterval')
    if (helper.empty(isi)) {
      localStorage.setItem('incomeStateInterval', JSON.stringify(this.interval))
    } else {
      this.interval = JSON.parse(isi!)
    }


    await Promise.all([this.loadBranches(), this.loadInsurers()])
    this.load()
  }


  stopEmissionLoading = false

  async load() {
    this.interval.start = new Date(this.interval.start)
    this.interval.end = new Date(this.interval.end)
    this.emissionPage = 1
    localStorage.setItem('incomeStateInterval', JSON.stringify(this.interval))
    this.loadEmissions()
  }

  branchLabelByUuid(uuid: string) {
    for (let category of this.branchCategories) {
      for (let branch of category.branches) {
        if (branch.uuid === uuid) {
          return branch.label
        }
      }
    }
  }


  insurerLabelByUuid(uuid: string) {
    for (let insurer of this.insurers) {
      if (insurer.uuid === uuid) {
        return insurer.label
      }
    }
  }

  primeNette(avenant: Avenant) {
    for (let pd of avenant.premiumDetails) {
      // console.log(helper.slugify(pd.model.label.toLowerCase()) + ' ' + pd.premium)
      if (helper.slugify(pd.model.label.toLowerCase()) == 'prime-nette') {
        return pd.premium
      }
    }
    return 0
  }

  getDetail(avenant: Avenant, name: string) {
    for (let pd of avenant.premiumDetails) {
      if (helper.slugify(pd.model.label.toLowerCase()) == name.toLowerCase()) {
        return pd.premium
      }
    }
    return 0
  }

  get netSum() {
    let sum = 0
    this.emissions.forEach((a: Avenant) => {
      sum += this.primeNette(a)
    })
    return Math.round(sum)
  }

  get ttcSum() {
    let sum = 0
    this.emissions.forEach((a: Avenant) => {
      sum += a.primeTTC
    })
    return Math.round(sum)
  }

  get paySum() {
    let sum = 0
    this.payments.forEach((p: PaymentTransaction) => {
      if (p.status === CONSTANTS.PAYMENT_STATUS.SUCCEED) {
        sum += p.amount
      }
    })
    return Math.round(sum)
  }

  async loadBranches() {
    this.isLoading = true
    this.branchCategories = await this.$store.getters.branchCategories()
    this.isLoading = false
  }

  async loadInsurers() {
    this.insurers = await this.$store.getters.insurers()
  }

  loadingPayment = false

  async loadPayments() {
    this.loadingPayment = true
    const start = helper.dateToHtml(this.interval.start)
    const end = helper.dateToHtml(this.interval.end)
    const res = await api.get(api.core, 'payment/list/' + start + '/' + end)
    if (res && res.data) {
      this.payments = []
      res.data.avenants.forEach((a: Avenant) => {
        const avenant = new Avenant(a)
        avenant.account.operations.forEach((o: AccountOperation) => {
          const transaction = new PaymentTransaction(o.transaction)
          transaction.avenant = avenant
          this.payments.push(transaction)
        })
      })
      // console.log(this.payments)
      // res.data.payments.forEach((p: PaymentTransaction) => {
      //     this.payments.push(new PaymentTransaction(p))
      // })

    }
    this.loadingPayment = false

  }

  loadingEmission = false

  emissionPage = 1

  async loadEmissions() {
    this.emissions = []
    this.payments = []
    this.loadEmissionsByPage()

  }

  async loadEmissionsByPage() {
    const start = helper.dateToHtml(this.interval.start)
    let end: any = helper.dateToHtml(this.interval.end)
    this.loadingEmission = true
    const res = await api.get(api.core, 'avenant/emission/list/' + start + '/' + end + '/' + this.emissionPage)
    // console.log(res.data.avenants)
    this.loadingEmission = false
    if (res && res.data && res.data.avenants) {

      end = new Date(this.interval.end)
      end.setDate(end.getDate() + 1);
      // console.log('start ' + this.interval.start)
      // console.log('end ' + end)
      // console.log('================================')

      res.data.avenants.forEach((a: any) => {
        const avenant = new Avenant(a)
        // console.log(avenant.dateEmission)
        if (avenant.dateEmission! >= this.interval.start && avenant.dateEmission! < end) {
          this.emissions.push(avenant)
        }
        avenant.account.operations.forEach((o: AccountOperation) => {
          const transaction = new PaymentTransaction(o.transaction)
          transaction.avenant = avenant
          this.payments.push(transaction)
        })
      })
      if (res.data.avenants.length > 0) {
        this.emissionPage++
        this.loadEmissionsByPage()
      }
    }

  }
}
</script>
<style scoped>
td,
th {
  white-space: nowrap;
}
</style>
