<template>
  <div :style="{ 'background': line.ranking % 2 === 0 ? 'whitesmoke' : 'white' }"
       class=" border-bottom position-relative line w-100">
    <template v-if="!$store.state.showCode">

      <b-dropdown class="position-absolute add-line-before d-none" no-caret no-flip right
                  style="top:-10px;right:100px;" tag="div" toggle-class="topbar-item text-decoration-none p-0"
                  variant="link">
        <template v-slot:button-content>
          <div class="btn btn-xs btn-primary btn-icon rounded-circle">
            <i class="fad fa-plus"></i>
          </div>
        </template>
        <b-dd-item @click="addTextLineBefore">Ajouter un texte</b-dd-item>
        <b-dd-item @click="addDefaultLineBefore">Ajouter un Calcul</b-dd-item>
        <b-dd-item @click="addConditionLineBefore">Ajouter une condition</b-dd-item>
        <b-dd-item @click="addCallableLineBefore">Ajouter une fonction</b-dd-item>
        <b-dd-item @click="addForeachLineBefore">Ajouter une iteration</b-dd-item>
      </b-dropdown>

      <div class=" d-flex align-items-center" v-if="line.type === 'default'">

        <div v-for="index in level">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div class="handle">
          <i class="fad fa-arrows mx-1"></i>
        </div>
        <div class="mx-1" title="Définir comme résultat">
          <div class="form-check form-check-custom form-check-sm">
            <input class="form-check-input" type="checkbox" v-model="line.isResult"/>
          </div>
        </div>
        <template v-if="!editReturn">
          <div class="d-flex align-items-center ps-1 align-self-stretch">
            <div :title="line.returnArgument.type.label" @dragover.prevent @dragstart="dragResult"
                 @drop="replaceReturnArgument" class="return-argument py-1 bg-primary px-3 rounded text-white"
                 draggable="true" style="white-space: nowrap" v-if="line.returnArgument">
              <template v-if="helper.empty(line.returnArgument.alias)">
                {{ line.returnArgument.name }}
              </template>
              <template v-else>{{ line.returnArgument.alias }}</template>
            </div>

          </div>
          <label class="cursor-pointer">
            <i class="fad fa-edit ms-2"></i>
            <input class="hidden" type="checkbox" v-model="editReturn">
          </label>
        </template>
        <template v-else>
          <div class="d-flex align-items-center ps-1 align-self-stretch">
            <div class="return-argument py-1 bg-primary px-3 rounded text-white" v-if="line.returnArgument">
              <input :ref="'resultInput'" class="border-none text-white bg-transparent min-w-50px"
                     style="outline: none" type="text" v-model="line.returnArgument.alias">
            </div>
          </div>
          <label class="cursor-pointer">
            <i class="fad fa-check-double ms-2"></i>
            <input class="hidden" type="checkbox" v-model="editReturn">
          </label>
        </template>
        <span class="ms-2">=</span>
        <div @dragover.prevent @drop="addArgument"
             class="flex-grow-1 flex-wrap  ms-2 me-10 py-3 align-self-stretch d-flex align-items-center">
          <template v-for="place, index in line.places">
            <div class="d-flex align-items-center">
              <div :key="'place_' + place.uuid" v-if="index > 0">
                <b-dropdown class="" no-caret no-flip right tag="div"
                            toggle-class="topbar-item text-decoration-none p-0" variant="link">
                  <template v-slot:button-content>
                    <div class="btn btn-xs m-1 btn-primary btn-icon rounded">
                      <template v-if="!helper.empty(place.symbol)">
                        {{ place.symbol }}
                      </template>
                      <template v-else>
                        &nbsp;
                      </template>
                    </div>
                  </template>
                  <b-dropdown-item @click="setOperator(place, op.symbol)"
                                   class="whitespace-no-wrap text-center fw-bolder fs-2 flex-grow-1 " tag="div"
                                   :key="'operator-' + helper.generateId()" v-for="op in operators">
                    {{ op.symbol }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div :set="callable = getCallable(place.argument.code)" class="ms-3 d-flex align-items-center"
                   v-if="place.argument.type.code === 'CALLABLE'">
                <router-link :class="{ 'text-danger': callable && !callable.insurer }"
                             :title="callable ? callable.insurer ? callable.insurer.label : callable.engine ? callable.engine.name : 'Global' : ''"
                             :to="{ name: 'engine-method-editor', params: { methodId: place.argument.id, methodSlug: place.argument.code } }"
                             class="fw-bolder text-primary text-decoration-underline me-1" target="_blank"
                             v-b-tooltip.hover>
                  {{ place.argument.name }}
                </router-link>
                <div class="fw-bolder text-primary">
                  (
                </div>
                <div class="d-flex align-items-center">
                  <template v-if="callable">
                    <template v-for="req, index in callable.requirements">
                      <span class="fw-bolder mx-1" v-if="index > 0">,</span>
                      <template v-if="getPlaceForRequirement(place, req)">
                        <template v-for="callablePlace in place.callableArgumentPlaces">
                          <template v-if="callablePlace.requirement === req.id">
                            <div class="fs-8 text-gray-400 fw-bolder">
                              {{ req.label }} :
                            </div>
                            <div class="mx-1 bg-light-success px-2 rounded fw-bolder">
                                                            <span class="text-success">
                                                                {{
                                                                callablePlace.argument.alias ?
                                                                    callablePlace.argument.alias : callablePlace.argument.name
                                                              }}
                                                            </span>
                            </div>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <div :callable-code="callable.code" :callable-id="callable.id"
                             :data-id="req.id" @dragover.prevent
                             @drop.stop="linkArgumentToRequirement(place, $event)"
                             class="mx-1 bg-light-danger px-2 rounded fw-bolder text-danger">
                          {{ req.label }}
                        </div>
                      </template>
                    </template>
                  </template>
                </div>
                <div class="fw-bolder text-primary">
                  )
                </div>
                <div>
                  <div @click="removePlace(place)" class="bg-danger px-2 mx-2 rounded cursor-pointer">
                    <i class="fad fa-times text-white "></i>
                  </div>
                </div>

              </div>
              <div class="d-flex align-items-center bg-primary rounded" v-else>
                <div class=" px-4 py-1 text-white">
                  {{ place.argument.alias ? place.argument.alias : place.argument.name }}

                </div>
                <div @click="removePlace(place)" class="bg-danger px-3 py-1 rounded-end">
                  <i class="fad fa-times text-white"></i>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="d-flex pe-5 py-3 align-items-center">
          <div @click="removeLine" class="btn btn-xs btn-danger btn-icon">
            <i class="fad fa-trash"></i>
          </div>
          <div :title="'Ligne ' + line.ranking" class="text-dark badge badge-warning ms-1" v-b-tooltip.hover.left>
                        <span>
                            {{ line.ranking }}
                        </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap px-5 py-1 bg-light-danger align-items-center" v-if="errorMessages.length > 0">
        <div class="text-gray-400 fs-8  rounded px-2">
          Erreur(s) :
        </div>
        <div class="bg-danger fs-8 text-white rounded px-2 m-1" v-for="error in errorMessages">
          {{ error }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex align-items-center ps-1 align-self-stretch">
        <div v-for="index in level">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <template v-if="!line.isResult">
          <div class="return-argument py-1 fw-bolder px-3" v-if="line.returnArgument">
            {{ line.returnArgument.variableCode }} =
          </div>
        </template>
        <template v-else>
          <div class="text-danger py-1 fw-bolder px-3" v-if="line.returnArgument">
            return
          </div>
        </template>
        <div class="d-flex align-items-center" v-for="place, index in line.places">
          <template v-if="place.argument.type.code === 'CALLABLE'">
            <div class="mx-2" v-if="index > 0">
              {{ place.symbol }}
            </div>
            <div class="fw-bolder text-success">
              {{ place.argument.variableCode }}
            </div>
            <div class="fw-bolder text-success">(</div>
            <div :set="callable = getCallable(place.argument.code)" class="d-flex align-items-center">
              <template v-if="callable">
                <template v-for="req, index in callable.requirements">
                  <span class="fw-bolder mx-1" v-if="index > 0">,</span>
                  <template v-if="getPlaceForRequirement(place, req)">
                    <template v-for="callablePlace in place.callableArgumentPlaces">
                      <template v-if="callablePlace.requirement === req.id">
                        <div class="mx-1 fw-bolder">
                                                    <span class="text-primary">
                                                        {{ callablePlace.argument.variableCode }}
                                                    </span>
                        </div>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <div :data-id="req.id" @dragover.prevent
                         class="mx-1 bg-light-danger px-2 rounded fw-bolder text-danger">
                      {{ req.label }}
                    </div>
                  </template>
                </template>
              </template>
            </div>
            <div class="fw-bolder text-success">)</div>
          </template>
          <template v-else>
            <div class="mx-2" v-if="index > 0">
              {{ place.symbol }}
            </div>
            <div class="fw-bolder text-primary">
              {{ place.argument.variableCode }}
            </div>
          </template>
        </div>
      </div>
    </template>

    <b-modal :id="'createLineBefore_' + uKey" :ref="'createLineBefore_' + uKey" :title="trans('Ligne')" centered
             class="modal-fullscreen" content-class="modal-fullscreen" scrollable>
      <div>Calcul</div>
      <div>Condition</div>
      <div>Fonction</div>
      <div>Iteration</div>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel()" size="sm" variant="secondary">
          <i class="fad fa-times"></i>
          {{ trans('Cancel') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import EngineMethodLine from "@/entity/EngineMethodLine";
import EngineArgument from "@/entity/EngineArgument";
import Popup from "@/entity/Popup";
import {helper} from "@/services/Helper";
import EnginePlace from "@/entity/EnginePlace";
import EngineMethod from "@/entity/EngineMethod";
import EngineMethodRequirement from "@/entity/EngineMethodRequirement";
import ItemType from "@/entity/ItemType";
import ItemProperty from "@/entity/ItemProperty";

@Component({})
export default class EngineArithmetiqueLineEditor extends Vue {
  @Prop() line!: EngineMethodLine
  uKey = helper.generateId()
  @Prop() level!: number

  operators = [
    {'name': 'Plus', 'symbol': '+'},
    {'name': 'Moins', 'symbol': '-'},
    {'name': 'Multiplie', 'symbol': '*'},
    {'name': 'Divise', 'symbol': '/'},
    {'name': 'Modulo', 'symbol': '%'},
  ]
  editReturn = false

  updateReturnSize() {
    // console.log(evt.target)
    // const input = this.$refs.resultInput as any
    // const size = input.value.trim().length
    // input.style.width = size + 'em'
    // input.setAttribute('size', size);
  }

  mounted() {
    // if (this.line.returnArgument) {
    //     console.log(this.line.returnArgument)
    // }
    // this.updateReturnSize()
    // console.log(this.line.returnArgument!.name)
    this.linkReturnArgument()
  }

  linkReturnArgument() {
    let arg = this.line.returnArgument!
    if (arg.resultOfLine && arg.resultOfLine !== this.line.uuid) {
      arg = this.$store.state.returnArguments.find((ra: EngineArgument) => {
        return ra.uuid === arg.uuid
      })
      if (arg instanceof EngineArgument) {
        this.line.returnArgument = arg
      } else {
        this.$store.state.returnArguments.push(arg)
      }
    }
    this.line.places.forEach((p: EnginePlace) => {
      if (p.argument.type.code === 'CALLABLE') {
        // console.log(p.argument.code)
        let callable = this.getCallable(p.argument.code)
        // console.log(callable)
        const callableArg = {
          id: callable.id,
          uuid: helper.generateId(),
          itemType: null,
          type: p.argument.type,
          choices: [],
          code: callable.code,
          name: callable.label,
          value: callable.id,
          alias: callable.label,
          symbol: null as any
        }
        if (callable.id !== p.argument.id) {
          p.argument = new EngineArgument(callableArg)
        }
      }
      if (p.argument.resultOfLine) {
        arg = this.$store.state.returnArguments.find((ra: EngineArgument) => {
          return ra.uuid === p.argument.uuid
        })
        if (arg instanceof EngineArgument) {
          p.argument = arg
        } else {
          this.$store.state.returnArguments.push(p.argument)
        }
      }
    })
  }

  replaceReturnArgument(evt: any) {
    let data = evt.dataTransfer.getData('text/plain')
    if (data) {
      data = JSON.parse(data);
      let arg = new EngineArgument(data)
      if (arg.resultOfLine) {
        arg = this.$store.state.returnArguments.find((ra: EngineArgument) => {
          return ra.uuid === arg.uuid
        })
        const line: any = this.$store.state.engineMethod.getLineByGid(arg.resultOfLine!)
        if (line.ranking >= this.line.ranking) {
          const pop = new Popup('Erreur', 'Cet emplacement entrée n\'accepte que les paramètres au dessus de la ligne ' + this.line.ranking, 'danger', 'fad fa-bug', false);
          return
        }
      }
      if (arg.type.format !== 'NUMBER') {
        let msg = 'Cet emplacement n\'accepte que les propriétés de type Numérique'
        const pop = new Popup('Erreur', msg, 'danger', 'fad fa-bug', false);
        return
      }
      this.line.returnArgument = arg
      arg.resultOfLine = this.line.uuid
      // console.log(arg)
    }
  }

  getCallable(code: string) {
    // console.log('searching ' + code)
    let callable = null
    if (this.$store.state.engineInsurer) {
      callable = this.$store.state.engineMethods.find((m: EngineMethod) => {
        // console.log('has insurer ' + !helper.empty(m.insurer))
        // console.log(m.code + ' === ' + code)
        if (m.insurer) {
          return m.code === code && m.insurer.id === this.$store.state.engineInsurer.id
        } else {
          return false
        }
      })
    }
    if (!callable) {
      // console.log('not insurer redefine')
      // console.log('search global')
      callable = this.$store.state.engineMethods.find((m: EngineMethod) => {
        // console.log('"'+m.code + '" === "' + code+'"')
        return m.code === code
      })
      // console.log('found')
      // console.log(callable)
    }
    // console.log(callable)
    return callable

  }

  getPlaceForRequirement(place: EnginePlace, req: EngineMethodRequirement) {
    return place.callableArgumentPlaces.find((a: EnginePlace) => {
      return a.requirement === req.id
    }) as any
  }

  linkArgumentToRequirement(place: EnginePlace, evt: any) {
    // console.log(evt.target)
    let reqId = parseInt(evt.target.getAttribute('data-id'))
    let cId = evt.target.getAttribute('callable-id')
    let callableCode = evt.target.getAttribute('callable-code')
    let data = evt.dataTransfer.getData('text/plain')
    let callable = this.getCallable(callableCode)
    if (!(callable instanceof EngineMethod)) {
      const pop = new Popup('Erreur', 'Impossible de retrouver le calcul ', 'danger', 'fad fa-bug', false);
      return;
    }
    let req = callable.requirements.find((r: EngineMethodRequirement) => {
      return r.id === reqId
    })
    if (data && req) {
      data = JSON.parse(data);
      let arg = new EngineArgument(data)
      console.log(arg)
      if (arg.resultOfLine) {
        arg = this.$store.state.returnArguments.find((ra: EngineArgument) => {
          return ra.uuid === arg.uuid
        })
        const line: any = this.$store.state.engineMethod.getLineByGid(arg.resultOfLine!)
        if (line.ranking >= this.line.ranking) {
          const pop = new Popup('Erreur', 'Cet emplacement entrée n\'accepte que les paramètres au dessus de la ligne ' + this.line.ranking, 'danger', 'fad fa-bug', false);
          return
        }
      }
      // console.log(arg)
      // return
      if (arg.type.format !== req.type.format) {
        let msg = 'Cet emplacement n\'accepte que les propriétés de type ' + req.type.label
        if (req.itemType) {
          msg += ' ' + req.itemType.name
        }
        const pop = new Popup('Erreur', msg, 'danger', 'fad fa-bug', false);
        return
      }
      const callableArgumentPlace = new EnginePlace()
      callableArgumentPlace.argument = arg
      callableArgumentPlace.requirement = reqId
      this.addCallableArgumentPlace(callable, place, callableArgumentPlace)
    }
  }

  addCallableArgumentPlace(callable: EngineMethod, place: EnginePlace, callableArgumentPlace: EnginePlace) {
    if (callableArgumentPlace.requirement) {
      const index = callable.requirements.findIndex((r: EngineMethodRequirement) => {
        return r.id === callableArgumentPlace.requirement
      })
      if (index !== -1) {
        place.callableArgumentPlaces.splice(index, 1, callableArgumentPlace)
      } else {
        place.callableArgumentPlaces.push(callableArgumentPlace)
      }
    }

  }


  async addArgument(evt: any) {
    // console.log('add argument')
    let data = evt.dataTransfer.getData('text/plain')
    if (data) {
      data = JSON.parse(data);
      // console.log(data)
      let arg = new EngineArgument(data)
      // console.log(arg)
      if (arg.resultOfLine) {

        const match = this.$store.state.returnArguments.find((ra: EngineArgument) => {
          return ra.uuid === arg.uuid
        })
        // console.log(match)
        if (!match) {
          this.$store.state.returnArguments.push(arg)
        } else {
          arg = match
        }

        // console.log(arg)
        if (this.$store.state.engineMethod) {
          const line: any = this.$store.state.engineMethod.getLineByGid(arg.resultOfLine!)

          // console.log(this.line.ranking)
          // console.log(line)
          // console.log(this.line)
          if (line.parent && !this.line.parent) {
            const pop = new Popup('Erreur', 'Impossible d\'utiliser un paramètre conditionnel ici ', 'danger', 'fad fa-bug', false);
            return
          }

          if (line.ranking >= this.line.ranking) {
            const pop = new Popup('Erreur', 'Cet emplacement entrée n\'accepte que les paramètres au dessus de la ligne ' + this.line.ranking, 'danger', 'fad fa-bug', false);
            return
            // throw 'Cet emplacement n\'accepte que les paramètres au dessus de la ligne ' + this.line.number
          }
        }
      }

      if (arg.type && arg.type.format === 'NUMBER') {
        const place = new EnginePlace()
        place.argument = arg
        this.line.places.push(place)
        // console.log(place)
      } else if (arg.type && arg.type.code !== 'CALLABLE') {
        // console.log(arg)
        const pop = new Popup('Erreur', 'Cette ligne ne peut contenir que des nombres', 'danger', 'fad fa-bug', false);
      }
    }
  }

  dragResult(evt: any) {
    evt.dataTransfer.setData('text/plain', JSON.stringify(this.line.returnArgument));
  }


  removePlace(place: EnginePlace) {
    const index = this.line.places.findIndex((a: EnginePlace) => {
      return a === place
    })
    // console.log(index)
    if (index !== -1) {
      this.line.places.splice(index, 1)
    }
  }

  getCode() {
    let code = '';

    // code +=
  }

  // removeArgument(arg: EngineArgument) {
  //     const index = this.line.arguments.findIndex((a: EngineArgument) => {
  //         return a === arg
  //     })
  //     console.log(index)
  //     if (index !== -1) {
  //         this.line.arguments.splice(index, 1)
  //     }
  // }

  setOperator(arg: EnginePlace, op: string) {
    arg.symbol = op
  }

  removeLine() {
    this.$emit('removeLine', {line: this.line})
  }

  addDefaultLineBefore() {
    this.$emit('addLineBefore', {type: 'default', before: this.line})
  }

  addTextLineBefore() {
    this.$emit('addLineBefore', {type: 'text', before: this.line})
  }

  addConditionLineBefore() {
    this.$emit('addLineBefore', {type: 'condition', before: this.line})
  }

  addForeachLineBefore() {
    this.$emit('addLineBefore', {type: 'foreach', before: this.line})
  }

  addCallableLineBefore() {
    this.$emit('addLineBefore', {type: 'callable', before: this.line})
  }

  errorMessages: string[] = []

  @Watch('line', {immediate: true, deep: true})
  check() {
    this.errorMessages = []
    if (this.line.places.length === 0) {
      this.errorMessages.push('Ligne vide');
    }
    this.line.places.forEach((a: EnginePlace, index) => {
      if (!a.symbol && index > 0) {
        this.errorMessages.push('Aucun opérateur défini avant le paramètre N°' + (index + 1) + ' "' + a.argument.name + '"')
      }
      if (!this.isArgumentAllowed(a.argument)) {
        this.errorMessages.push('l\'argument ' + a.argument.name + ' n\'est pas autorisé')
      }
    })

    this.line.isValid = this.errorMessages.length === 0
    this.$forceUpdate()

  }


  isArgumentAllowed(arg: EngineArgument) {

    let match = false
    if (arg.type.code === 'CALLABLE') {
      const callable = this.getCallable(arg.code)
      if (callable instanceof EngineMethod) {
        match = true
      }
    } else if (arg.itemType) {
      const item = this.$store.state.sources.find((i: ItemType) => {
        return i.uuid === arg.itemType!.uuid
      })
      if (item) {
        item.properties.forEach((p: ItemProperty) => {
          if (p.name === arg.name) {
            match = true
          }
        })
      }
    }
    if (arg.resultOfLine && !match) {
      match = true
      const found = this.$store.state.returnArguments.find((ra: EngineArgument) => {
        return ra.uuid === arg.uuid
      })
      if (!found) {
        match = false
      }
      if (this.$store.state.engineMethod) {
        const line: any = this.$store.state.engineMethod.getLineByGid(arg.resultOfLine!)
        if (line.parent && !this.line.parent) {
          match = false
        }
        if (line.ranking >= this.line.ranking) {
          match = false
        }
      }
    }
    if (!match) {
      if (arg.itemType) {
        this.$store.state.sources.find((i: ItemType) => {
          let res = this.itemHasArgument(i, arg)
          if (res) {
            match = true
          }
        })
      }
      if (arg.source && !match) {
        match = true
        let link = ''
        let currentItem: ItemType | null = null
        arg.source.forEach((s: any, index) => {
          if (index === 0) {
            currentItem = this.$store.state.sources.find((i: ItemType) => {
              return i.uuid === s.uuid
            })
          } else {
            const prop = (currentItem as ItemType).properties.find((p: ItemProperty) => {
              return p.value === s.uuid
            })

            if (prop instanceof ItemProperty && prop.type.code === 'OBJECT') {
              currentItem = this.$store.state.itemTypes.find((i: ItemType) => {
                return i.uuid === prop.value
              })
            }
          }
          if (currentItem) {
            link += currentItem.code + '.'
          } else {
            match = false
          }
        })
        link = 'this.' + link + arg.code
      }
    }
    if (!match) {
      this.$store.state.engineMethod.requirements.forEach((requirement: EngineMethodRequirement) => {
        if (requirement.code === arg.code) {
          match = true
        }
      })
    }
    arg.allowed = match
    return match
  }

  itemHasArgument(itemType: ItemType, arg: EngineArgument) {
    let match = false
    itemType.properties.forEach((p: ItemProperty) => {
      // if(p.code === arg.code){
      //     console.log(p.code + ' === ' + arg.code + ' && ' + arg.itemType!.uuid + ' === ' + itemType!.uuid)
      //     console.log(arg.itemType)
      // }

      if (p.code === arg.code && (arg.itemType!.uuid === itemType!.uuid || arg.itemType!.id === itemType!.id)) {
        match = true
      }
      if (p.type.code === 'OBJECT') {
        const item = this.$store.state.itemTypes.find((i: ItemType) => {
          return i.uuid === p.value
        })
        if (item) {
          let res = this.itemHasArgument(item, arg)
          if (res) {
            match = res
          }
        }
      }
    })
    return match
  }
}
</script>
<style scoped>
.line:hover .add-line-before {
  display: block !important;
}

.line {
  animation: open .8s linear;
}

@keyframes open {
  0% {
    max-height: 0;
    overflow: hidden;
    background: red !important;
  }

  /*90% {*/
  /*    max-height: 100px;*/
  /*    overflow: auto*/
  /*}*/
  100% {
    max-height: 1000px;
    overflow: visible;
    background: inherit;
  }
}
</style>
