<template>
    <div class="mb-3 position-relative">
        <label for="autocomplete" class="form-label">{{ label }} </label>
        <input @focusin="active = true" type="text" class="form-control" id="autocomplete" @input="searchOptions"
            v-model="selected" placeholder="" />
        <div v-if="active" class="position-absolute w-100" style="top: 110% ">
            <div class="bg-white rounded shadow" style="max-height: 300px; overflow-y: scroll; ">
                <div class="card-body">
                    <p v-if="result.length==0" style="opacity: 0.5;">Recherchez</p>
                    <div v-for="(item, index) in result" @click="selectOptions(item)"
                        class="cursor-pointer border-bottom p-3 option" :class="{ 'border-top': index == 0 }" :key="index">
                        {{ item.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AutoComplete extends Vue {
    @Prop({ default: "" }) label!: string;
    @Prop({ default: "" }) value!: string;
    @Prop({
        default: [{ name: "John Doe", value: 25 },
        { name: "Jane Doe", value: 19 },
        { name: "Eve Doe", value: 34 },]
    }) options!: { name: string, value: string }[];

    active = false;

    selected = "";

   

    result: any = [];

    selectOptions(item: any) {
        this.selected = item.name;
        this.value= item.value
        this.$emit("input", this.value);
        this.active = false;
        this.$forceUpdate();
    }


    mounted() {
        
    }
    searchOptions(e: any) {
        const value = e.target.value;

        const textTrim = value.trim();

        const recherche = textTrim
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036F]/g, "")
            .replace(/'/g, "");

        const resultats: any = [];

        for (const objet of this.options) {
            // Convertir la valeur de l'index "text" en minuscules et supprimer les accents et les apostrophes
            const text = objet.name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036F]/g, "")
                .replace(/'/g, "");

            // Vérifier si la valeur de "text" correspond à la recherche
            if (text.includes(recherche)) {
                // Ajouter l'objet au tableau des résultats
                resultats.push(objet);
            }
        }

      
        this.result = resultats;
    }
}
</script>
<style scoped>
.option:hover {
    transition: all 0.5s;
}

.option:hover {
    background: rgb(246, 246, 246);
}
</style>
