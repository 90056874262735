<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'partner-list' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Apporteurs') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>

          <div class="d-flex align-items-center py-1">
            <div class="btn btn-primary btn-sm" v-b-modal="'property-modal'" @click="addItemProperty">
              <i class="fad fa-plus"></i>
              Créer une propriété
            </div>
          </div>
        </div>
      </div>


    </template>
    <template v-slot:content>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-auto ">
              <table class="table table-striped gx-5">
                <thead>
                <tr class="fw-bolder">
                  <th>Apporteur</th>
                  <th v-for="prop in itemType.properties">
                    {{ prop.name }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items">
                  <td>
                    {{ item.name }}
                  </td>
                  <td>

                    <template v-for="prop in itemType.properties">
                      <template v-for="property in item.properties">
                        <template v-if="property.model.id === prop.id">
                          <div class="d-flex align-items-center">
                            <input type="number" v-model="property.value" class="form-control">
                            <div class="btn btn-icon btn-primary btn-xs ms-2"
                                 @click="saveProperty(item.id,property.id,property.value)">
                              <i class="fad fa-save"></i>
                            </div>
                          </div>
                        </template>
                      </template>
                    </template>
                  </td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <b-modal :title="trans('Editer une propriété')" centered id="property-modal" ref="property-modal" size="sm">
        <form v-if="itemProperty">
          <h2 class="text-gray-400">
            Code : {{ itemProperty.code }}
          </h2>
          <div class="row">
            <div class="fs-3 fw-bolder" v-if="itemProperty.item">{{ trans(itemProperty.item.name) }}</div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Nom') }}</label>
              <b-form-input :id="'name'" :state="!helper.empty(itemProperty.name)" v-model="itemProperty.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Type') }}</label>
              <b-form-select :state="!helper.empty(itemProperty.type)"
                             class="form-control form-select-solid form-select" v-model="itemProperty.type">
                <b-form-select-option :value="null">Selectionnez un type</b-form-select-option>
                <b-form-select-option :key="key" :value="type" v-for="type, key in dataTypes">
                  {{ trans(type.label) }}
                </b-form-select-option>
              </b-form-select>
            </div>

          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveItemProperty()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Partner from "@/entity/Partner";
import PaymentMethod from "@/entity/PaymentMethod";
import ItemType from "@/entity/ItemType";
import Item from "@/entity/Item";
import ItemProperty from "@/entity/ItemProperty";
import Swal from "sweetalert2";
import Popup from "@/entity/Popup";
import InputNumber from "@/components/InputNumber.vue";
import DataType from "@/entity/DataType";
import Property from "@/entity/Property";

@Component({
  components: {InputNumber, Base}
})
export default class PartnerParameters extends Vue {
  [x: string]: any;


  // partner: Partner = new Partner()
  items: Item[] = []
  itemType = new ItemType()
  dataTypes: DataType[] = []
  // methods: PaymentMethod[] = []
  // uploadRoot = api.uploadRoot
  mounted() {
    this.loadDataTypes()
    this.loadPartners()
  }

  async loadDataTypes() {
    const pop = new Popup('Chargement des types', '', 'primary', 'fad fa-sync', true)
    const res = await api.get(api.master, 'api/get/data/types')
    if (res && res.data && res.data.types) {
      this.dataTypes = []
      res.data.types.forEach((type: DataType) => {
        if (type.code === 'NUMBER') {
          this.dataTypes.push(new DataType(type))
        }
      })
    }
    pop.hide()
  }

  async loadPartners() {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'partner/item/list')
    if (res && res.data) {
      this.items = []
      res.data.items.forEach((p: Item) => {
        this.items.push(new Item(p))
      })
      this.itemType = new ItemType(res.data.type)
    }
    this.$store.commit('stopLoading')
  }


  item = new ItemType()
  property = new Property()

  async saveItemProperty() {
    // this.itemProperty.type = {id: 2, code : 'NUMBER'} as any
    const res = await api.post(api.core, 'item/property/edit', {property: this.itemProperty});
    if (res && res.data) {
      (this.$refs['property-modal'] as any).hide()
      this.property = new Property()
      this.loadPartners()
    }
  }

  async saveProperty(itemId: string, propertyId: string, value: number) {
    const res = await api.get(api.core, 'partner/update/property/value/' + itemId + '/' + propertyId + '/' + value)
  }

  editItemProperty(property: ItemProperty, item: ItemType) {
    this.itemProperty = new ItemProperty({...property})
    this.itemProperty.item = this.itemType;
    // if (item.className) {
    //   this.loadProperties(item.className)
    // }
  }

  // async loadProperties(classname: string) {
  //   const res = await api.get(api.core, 'item/type/property/list/' + classname)
  //   this.classProperties = []
  //   if (res && res.data && res.data.properties) {
  //     res.data.properties.forEach((a: any) => {
  //       this.classProperties.push(a)
  //     })
  //   }
  // }

  itemProperty = new ItemProperty()

  addItemProperty() {
    this.itemProperty = new ItemProperty()
    this.itemProperty.item = this.itemType;
    // if (item.className) {
    //   this.loadProperties(item.className)
    // }
  }


  async removeProperty(property: ItemProperty) {

    const response = await Swal.fire({
      title: 'Attention',
      html: 'La suppression de cette propriété entrainera la suppression de toutes les valeurs des objets de ce modèle',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      didOpen: () => {
        // Swal.showLoading()
        // const b = Swal.getHtmlContainer().querySelector('b')
        // timerInterval = setInterval(() => {
        //     b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        // clearInterval(timerInterval)
      }
    })
    if (response.isConfirmed) {
      this.$store.commit('loading')
      const popup = new Popup('Chargement', this.trans('Suppression de la propriété ' + property.name), 'secondary', 'fad fa-cloud-upload-alt', true)
      const res = await api.delete(api.core, 'item/property/delete/' + property.uuid);
      popup.hide()
    }
    this.loadPartners()
  }
}
</script>


<style scoped>

</style>
