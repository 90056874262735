<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Survey_list') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <b-dropdown no-caret no-flip right size="sm" tag="div" toggle-class="topbar-item text-decoration-none"
                        variant="link" v-if="hasRole('ROLE_MANAGER')">
              <template v-slot:button-content>
                <div class="btn btn-sm btn-clean btn-dropdown btn-primary">
                  <i class="fad fa-file-signature fs-2"></i>
                  {{ trans('Créer un contrat') }}
                </div>
              </template>
              <b-dropdown-text class="min-w-md-350px p-0" tag="div">
                <Loading v-if="isLoading"></Loading>
                <b-tabs active-nav-item-class="bg-primary text-white" active-tab-class="" fill
                        nav-wrapper-class="" v-else>
                  <b-tab :title="cat.label" :title-link-class="'py-4'" :key="cat.id" v-for="cat in branchCategories">
                    <div class="row g-0 overflow-auto mh-325px">
                      <div class="col-6" v-for="branch in cat.branches">
                        <router-link :style="{ '--bg-hover': branch.color }"
                                     :to="{ name: 'contract-creator', params: { branchSlug: branch.slug } }"
                                     class="d-flex flex-column flex-center h-100 p-6  border-end border-bottom branch-button">
                                                <span class="svg-icon svg-icon-3x svg-icon-success mb-2">
                                                    <i :class="branch.icon + ' fs-3x'" v-if="branch.icon"></i>
                                                    <i class="fad fa-question-circle" v-else></i>
                                                </span>
                          <span class="fs-5 fw-bold text-gray-400 mb-0">
                                                    {{ branch.label }}
                                                </span>
                        </router-link>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </b-dropdown-text>
            </b-dropdown>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-auto flex-grow-1">
              <label class="mb-2 fw-bolder" for="">{{ trans('Type') }}</label>
              <b-form-select class="form-control  form-select" v-model="searched.type">
                <b-form-select-option :value="null">{{ trans('Choisir un type') }}
                </b-form-select-option>
                <b-form-select-option :key="type.code" :value="type.code" v-for="type in types">
                  {{ type.name }}
                </b-form-select-option>
              </b-form-select>
            </div>
            <div class=" col-auto flex-grow-1
                ">
              <label class="mb-2 fw-bolder" for="">{{ trans('Branche') }}</label>
              <b-form-select class="form-control  form-select" v-model="searched.branch">
                <b-form-select-option :value="null">{{ trans('Choisir une branche') }}
                </b-form-select-option>
                <template v-for="category in branchCategories">
                  <b-form-select-option :key="branch.uuid" :value="branch.uuid"
                                        v-for="branch, key in category.branches">
                    {{ category.label }} - {{ trans(branch.label) }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Assureur
              </label>
              <div>
                <b-form-select class="form-control  form-select" v-model="searched.insurer">
                  <b-form-select-option :value="null">{{ trans('Choisir un assureur') }}
                  </b-form-select-option>
                  <b-form-select-option :key="insurer.uuid" :value="insurer.slug" v-for="insurer in insurers">
                    {{ insurer.label }}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </div>
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Souscripteur
              </label>
              <div>
                <input class="form-control" placeholder="Email,Nom,Prenom,Numero de piece" type="text"
                       v-model="searched.customer">
              </div>
            </div>
          </div>


          <div class="row my-10">
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Numero de police
              </label>
              <div>
                <input class="form-control" placeholder="Partiel ou complet" type="text"
                       v-model="searched.police">
              </div>
            </div>
            <div class="col-auto flex-grow-1" v-if="hasRole('ROLE_MANAGER')">
              <label class="fw-bolder mb-2" for="">
                Apporteur
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" placeholder="Apporteur" type="text" v-model="searched.partner">
                </div>
              </div>
            </div>
            <div class="col-auto flex-grow-1" v-if="hasRole('ROLE_MANAGER')">
              <label class="fw-bolder mb-2" for="">
                Gestionnaire
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" placeholder="Gestionnaire" type="text"
                         v-model="searched.manager">
                </div>
              </div>
            </div>
          </div>

          <div class="row my-10">
            <div class="col-auto flex-grow-1">
              <label class="fw-bolder mb-2" for="">
                Date d'effet entre :
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.effetMin">
                </div>
                <div class="mx-3 fw-bolder">
                  et
                </div>
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.effetMax">
                </div>
              </div>
            </div>

            <div class="col-auto flex-grow-1 border-start border-2 border-start-dashed">
              <label class="fw-bolder mb-2" for="">
                Expire entre :
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.expireMin">
                </div>
                <div class="mx-3 fw-bolder">
                  et
                </div>
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.expireMax">
                </div>
              </div>
            </div>

            <div class="col-auto flex-grow-1 border-start border-2 border-start-dashed">
              <label class="fw-bolder mb-2" for="">
                Créer entre :
              </label>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.createMin">
                </div>
                <div class="mx-3 fw-bolder">
                  et
                </div>
                <div class="flex-grow-1">
                  <input class="form-control" type="date" v-model="searched.createMax">
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div @click="resetSearch" class="btn btn-danger me-5 d-flex align-items-center">
              <i class="fad fa-trash"></i>
              Réinitialiser
            </div>
            <button :disabled="searching" @click="search" class="btn btn-primary d-flex align-items-center">
              <i class="fad fa-search"></i>
              Rechercher
            </button>
          </div>
        </div>
      </div>

      <div class="card mt-10">
        <div class="card-body">
          <table class="table table-striped gy-3 gs-3">
            <thead>
            <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
              <th>Type</th>
              <th>{{ trans('Police') }}</th>
              <th>Source</th>
              <th>Souscripteur</th>
              <th>Emission</th>
<!--              <th>Durée</th>-->
              <th class="text-center">{{ trans('Status') }}</th>
              <!--                            <th>{{trans('Assuré')}}</th>-->
              <!--                            <th v-if="!searched.branch">{{trans('Branche')}}</th>-->
              <!--                            <th>{{trans('Souscrit le')}}</th>-->
              <!--                            <th>{{trans('Période')}}</th>-->
              <th v-if="hasRole('ROLE_MANAGER')">{{ trans('Apporteur') }}</th>
              <th>{{ trans('Prime TTC') }}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="searching">
              <td colspan="9">
                <Loading></Loading>
              </td>
            </tr>
            <tr v-else-if="avenants.length === 0">
              <td colspan="9">
                <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center p-20">
                  <i class="fad fa-empty-set fs-5x"></i>
                  <span class="fs-2 text-gray-400">{{ trans('Aucun resultat') }}</span>
                </div>
              </td>
            </tr>
            <template v-else v-for="avenant in avenants">
              <tr v-if="!searched.branch">
                <td>
                  <div class="text-gray-400 fw-bolder d-flex align-items-center">
                    <i :class="'fad fs-2 ' + avenant.branch.icon" class="me-2 text-gray-400"></i>
                    {{ avenant.branch.label }} ▪ {{ avenant.branch.category.label }}
                  </div>
                  <div>
                    {{ avenant.type }}
                  </div>
                </td>
                <td>
                  <div>
                    <router-link :to="{name : 'contract-show',params :{uuid : avenant.contractUuid}}"
                                 class="text-gray-400 fw-bolder d-flex align-items-center">
                      {{ avenant.police }}
                    </router-link>
                    <!--                    <div class="d-flex align-items-center fw-bolder">-->
                    <!--                      <img :src="uploadRoot + contract.insurer.photo" alt=""-->
                    <!--                           class="object-contain me-2" style="width:70px;height:20px">-->
                    <!--                      {{ contract.police }}-->
                    <!--                    </div>-->

                    <div class="me-2">
                      {{ readableDate(avenant.createdAt, 'dMY H:i') }}
                    </div>
                  </div>
                </td>
                <td>
                  <user-view v-model="avenant.source"></user-view>
                </td>
                <td>
                  {{ avenant.customer.name }} {{ avenant.customer.prename }}
                  <br>
                  <span class="text-gray-400 fw-bolder">
                                        {{ avenant.customer.email }}
                                    </span>
                </td>
                <td>
                  <template v-if="avenant.dateEmission">
                    {{ readableDate(avenant.dateEmission, 'dMY H:i') }}
                    <br>
                    <span class="fw-bolder">
                                                            <user-view v-model="avenant.emitBy"></user-view>
                                                        </span>
                  </template>
                  <div v-else>
                                                    <span>
                                                        <i class="fad fa-empty-set fs-2"></i>
                                                    </span>
                  </div>
                </td>

                                <td>
                                  <div class="d-flex flex-column justify-content-center align-items-center ">
                                    <div>
                                                            <span :class="{
                                                                'badge-success': avenant.status === CONSTANTS.AVENANT_STATUS.EN_COURS,
                                                                'badge-primary': avenant.status === CONSTANTS.AVENANT_STATUS.VALIDE,
                                                                'badge-danger': avenant.status === CONSTANTS.AVENANT_STATUS.PROJET,
                                                            }" class="badge">
                                                                {{ avenant.status }}
                                                            </span>
                                    </div>
<!--                                    <div class="fw-bolder mt-1">-->
<!--                                      <user-view v-model="contract.manager"></user-view>-->
<!--                                    </div>-->

                                  </div>
                                </td>
                                <td>
                                  <template v-if="avenant.partner && hasRole('ROLE_MANAGER')">
                                    <div class="fw-bolder">
                                      {{ avenant.partner.name }}
                                    </div>
                                    <div class="text-gray-400 fw-bolder fs-8">
                                      {{ avenant.partner.code }}
                                    </div>
                                  </template>
                                </td>
                                <td>
                                                    <span class="fw-bolder">
                                                        {{ currency(avenant.primeTTC) }}
                                                    </span>
                                  <br>
                                  <span class="text-danger fw-bolder">
                                                        {{ currency(avenant.balance, true) }}
                                                    </span>
                                </td>
                                <td class="text-end">
                                  <router-link :to="{ name: 'avenant-show', params: { uuid: avenant.uuid } }"
                                               class="btn btn-xs btn-icon btn-primary">
                                    <i class="fad fa-eye"></i>
                                  </router-link>
                <!--                  <div class="btn btn-xs btn-icon btn-danger ms-3" @click="deleteContract(contract)"-->
                <!--                       v-if="hasRole('ROLE_ADMIN')">-->
                <!--                    <i class="fad fa-trash"></i>-->
                <!--                  </div>-->
                                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>

      <div class="my-20">
        <Pagination :count="total" :itemLength="contracts.length" :matchCount="matchCount" :perPage="searched.perPage"
                    :showEmpty="true" @input="search" v-model="searched.page"></Pagination>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import BranchCategory from "@/entity/BranchCategory";
import Branch from "@/entity/Branch";
import Loading from "@/components/Loading.vue";
import {api} from "@/services/Api";
import Pagination from "@/components/Pagination.vue";
import UserView from "@/components/UserView.vue";
import Contract from "@/entity/Contract";
import Swal from "sweetalert2";
import User from "@/entity/User";
import Partner from "@/entity/Partner";
import PaymentMethod from "@/entity/PaymentMethod";
import Popup from "@/entity/Popup";
import Insurer from "@/entity/Insurer";
import Avenant from "@/entity/Avenant";
import CONSTANTS from "@/Utils/constant";

@Component({
  components: {Pagination, Loading, Base, UserView}
})
export default class AvenantList extends Vue {
  total = 0
  matchCount = 0
  uploadRoot = api.uploadRoot
  types: any[] = []
  branchCategories: BranchCategory[] = []
  avenants: Avenant[] = []
  searching = false
  searched: any = {
    branch: null,
    customer: null,
    type: null,
    insurer: null,
    partner: null,
    manager: null,
    police: null,
    effetMin: null,
    effetMax: null,
    expireMin: null,
    expireMax: null,
    createMin: null,
    createMax: null,
    page: 1,
    perPage: 50
  }
  contracts: any[] = []

  resetSearch() {
    const keys = Object.keys(this.searched)
    keys.forEach((k: any) => {
      this.searched[k] = null
    })
  }

  getBranchByUuid(uuid: string) {
    for (let cat of this.branchCategories) {
      for (let branch of cat.branches) {
        if (branch.uuid === uuid) {
          return branch
        }
      }
    }
    return null;
  }

  async deleteContract(contract: Contract) {
    const response = await Swal.fire({
      title: 'Supprimer',
      html: 'Êtes-vous sûr de vouloir supprimer ce contrat ?',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      didOpen: () => {
        // Swal.showLoading()
        // const b = Swal.getHtmlContainer().querySelector('b')
        // timerInterval = setInterval(() => {
        //     b.textContent = Swal.getTimerLeft()
        // }, 100)
      },
      willClose: () => {
        // clearInterval(timerInterval)
      }
    })
    if (response.isConfirmed) {
      const res = await api.get(api.core, 'contract/delete/' + contract.uuid)
      this.search()
    }
  }

  async search() {
    if (this.searching) {
      return
    }
    this.searching = true
    const res = await api.post(api.core, 'avenant/search', {searched: this.searched})
    this.searching = false
    if (res && res.data && res.data.avenants) {
      this.avenants = [];
      res.data.avenants.forEach((a: Avenant) => {
        const occ = new Avenant(a)
        const branch = this.getBranchByUuid(a.branch)
        // console.log(branch)
        occ.branch = branch as any
        this.avenants.push(occ)
      })
      this.total = res.data.total
      this.matchCount = res.data.matchCount
    }

  }

  async mounted() {
    this.loadData()

  }

  async loadData() {
    const res = await api.get(api.core, 'avenant/list/requirements')
    if (res && res.data) {
      this.partners = []
      res.data.partners.forEach((p: Partner) => {
        this.partners.push(new Partner(p))
      })
      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        this.insurers.push(new Insurer(i))
      })
      this.branchCategories = []
      res.data.categories.forEach((i: Insurer) => {
        this.branchCategories.push(new BranchCategory(i))
      })
      this.types = []
      res.data.types.forEach((type: any) => {
        this.types.push(type)
      })

    }
  }

  isLoading = false

  async loadBranches() {
    this.isLoading = true
    this.branchCategories = await this.$store.getters.branchCategories()
    this.isLoading = false
  }

  partners: Partner[] = []

  async loadPartners() {
    const res = await api.get(api.core, 'partner/list')
    if (res && res.data) {
      this.partners = []
      res.data.partners.forEach((p: Partner) => {
        this.partners.push(new Partner(p))
      })
    }
  }

  managers: User[] = []

  async loadManagers() {
    this.managers = await api.loadManagers()
  }

  insurers: Insurer[] = []

  async loadInsurers() {
    const popup = new Popup('Chargement des assureurs', '', 'warning', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'insurer/list')
    if (res && res.data && res.data.insurers) {
      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        this.insurers.push(new Insurer(i))
      })
    }
    popup.hide()
  }
}
</script>
<style scoped>
.p-0 .b-dropdown-text {
  padding: 0;
}
</style>
