<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    Tableau de bord
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <button class="btn btn-primary btn-sm ms-3" @click="createAgency" v-b-modal="'editAgency'">
              <i class="fad fa-plus"></i>
              {{ trans('Créer une agence') }}
            </button>
          </div>

        </div>
      </div>
    </template>
    <template #content>

      <div class="card">
        <div class="card-body p-0">
          <table class="table table-striped gy-7 gs-7">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>{{ trans('Agence') }}</th>
              <th>{{ trans('Code') }}</th>
              <th>{{ trans('Contact') }}</th>
              <th>{{ trans('Créer le') }}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loadingUsers">
              <td colspan="5">
                <Loading></Loading>
              </td>
            </tr>
            <template v-for="agency in agencies">
              <tr :key="agency.id">
                <td>
                  {{ agency.name }}
                </td>
                <td>
                  {{ agency.code }}
                </td>
                <td>
                  {{ agency.contact }}
                </td>
                <td>
                  {{ readable(agency.createdAt, 'dmY H:i') }}
                </td>
                <td>
                  <div class="btn btn-icon btn-xs btn-warning" @click="editAgency(agency)" v-b-modal="'editAgency'">
                    <i class="fad fa-edit"></i>
                  </div>
                  <div class="btn btn-xs btn-icon btn-danger ms-2" v-b-modal="'userModal'">
                    <i class="fad fa-ban"></i>
                  </div>
                </td>
              </tr>
            </template>

            </tbody>
          </table>
          <div>

          </div>
        </div>
      </div>

      <b-modal :title="trans('Créer une agence')" centered id="editAgency" ref="editAgency">
        <form>
          <div class="row" v-if="agency && $store.state.partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Nom') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(agency.name)" v-model="agency.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
              <div class="fs-8 text-danger mb-2">
                Ce code ne doit contenir aucun espace ou caractère spécial, il ne sert pas à être explicite
                mais juste à identifier une agence, il doit contenir entre 2 et 4 caractères
              </div>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="bg-light-primary">
                    <strong class="text-primary fw-bolder">
                      {{ $store.state.partner.code }}-
                    </strong>
                  </b-input-group-text>
                </template>
                <b-form-input :id="'code'" :state="!helper.empty(agency.code)" v-model="agency.code" v-uppercase
                              maxlength="4">
                </b-form-input>
              </b-input-group>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'contact'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'contact'" :state="!helper.empty(agency.contact)" v-model="agency.contact">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{
                  trans('Methode de paiement autorisée')
                }}</label>
              <div class="d-flex flex-wrap  justify-content-start">
                <div v-for="method in $store.state.partner.paymentMethods"
                     class="m-2 fw-bolder d-flex align-items-center justify-content-start">
                  <label class="form-check form-switch form-check-custom form-check-solid m-2">
                    <input :checked="isMethodActiveForPartner(method, agency)"
                           @change="toggleMethodForPartner(method, agency)" class="form-check-input"
                           type="checkbox"/>
                  </label>
                  {{ method.label }}
                </div>
              </div>

            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveAgency" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal :title="trans('Utilisateurs')" centered id="editUser" ref="editUser">
        <form>
          <div class="row" v-if="user && partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Noms') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(user.name)" v-model="user.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Email') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.email)" v-model="user.email">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Agence') }}</label>
              <select v-model="user.code" class="form-control form-select">
                <option :value="partner.code">{{ partner.name }}</option>
                <option :value="agency.code" v-for="agency in partner.agencies">{{ agency.name }}</option>
              </select>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Mot de passe') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.password)" v-model="user.password">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.contact)" v-model="user.contact">
              </b-form-input>
            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveUser" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import FileUpload from "@/components/FileUpload.vue";
import Base from "@/layouts/Base.vue";
import Partner from "@/entity/Partner";
import {helper} from "@/services/Helper";
import {api} from "@/services/Api";
import PaymentMethod from "@/entity/PaymentMethod";
import Loading from "@/components/Loading.vue";

@Component({
  components: {Loading, Base, FileUpload}
})
export default class PartnerAgenciesList extends Vue {

  partner: Partner | any = null
  user: Partner = new Partner()
  agency: Partner = new Partner()

  async saveUser() {
    // this.user.code = this.partner.code
    const res = await api.post(api.auth, 'api/partner/edit', {partner: this.user});
    (this.$refs as any).editUser.hide()

  }

  mounted() {
    this.loadAgencies()
  }

  isMethodActiveForPartner(method: PaymentMethod, partner: Partner) {
    return partner.paymentMethods.find((p: PaymentMethod) => p.id === method.id) ? true : false
  }

  toggleMethodForPartner(method: PaymentMethod, partner: Partner) {
    const index = partner.paymentMethods.findIndex((p: PaymentMethod) => p.id === method.id)
    if (index >= 0) {
      partner.paymentMethods.splice(index, 1)
    } else {
      partner.paymentMethods.push(method)
    }
  }

  createAgency() {
    this.agency = new Partner()
    this.agency.parent = this.partner

  }

  editAgency(agency: Partner) {

    this.agency = new Partner(agency)
    this.agency.parent = this.$store.state.partner
    if (this.agency.code.includes(this.$store.state.partner.code)) {
      this.agency.code = this.agency.code.replace(this.$store.state.partner.code, '')
    }
    this.agency.code = helper.slugify(this.agency.code, '')

  }

  async saveAgency() {
    this.$store.commit('loading')
    let agency = {...this.agency};
    agency.parent = this.$store.state.partner.id;
    // pc.branch = {slug: (pc.branch as any).slug, uuid: (pc.branch as any).uuid} as any
    const res = await api.post(api.core, 'api/agency/edit', {partner: agency})
    // return
    this.$store.commit('stopLoading')
    if (res && res.data) {
      const refs = this.$refs as any;
      if (refs.editAgency) {
        refs.editAgency.hide()
      }
      this.loadAgencies()
      // this.loadPartner()
    }
  }

  editUser(user: Partner) {
    this.user = new Partner(user);
    (this.$refs as any).editUser.show()
  }

  createUser() {
    this.user = new Partner();
    this.user.code = this.partner.code;
    (this.$refs as any).editUser.show()
  }

  getAgencyNameByCode(code: string) {
    const agency = this.partner.agencies.find((agency: Partner) => {
      return agency.code === code
    })

    return agency ? agency.name : code
  }

  users: Partner[] = []
  loadingUsers = false

  async loadUsers() {
    this.loadingUsers = true
    const res = await api.get(api.auth, 'partner/user/list/' + this.$route.params.code)
    if (res && res.data) {
      this.users = []
      res.data.users.forEach((p: any) => {
        const occ = new Partner(p)
        this.users.push(occ)
      })
    }
    this.loadingUsers = false
  }

  agencies: Partner[] = []

  async loadAgencies() {
    this.loadingUsers = true
    const res = await api.get(api.core, 'api/partner/agencies/list')
    if (res && res.data) {
      this.agencies = []
      res.data.agencies.forEach((p: any) => {
        const occ = new Partner(p)
        this.agencies.push(occ)
      })
    }
    this.loadingUsers = false
  }

}
</script>


<style scoped>

</style>
