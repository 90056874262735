import { render, staticRenderFns } from "./PartnerHome.vue?vue&type=template&id=dfca9262&scoped=true"
import script from "./PartnerHome.vue?vue&type=script&lang=ts"
export * from "./PartnerHome.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfca9262",
  null
  
)

export default component.exports