import { RouteConfig } from "vue-router";


const prefix = '/admin'
const adminRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'admin-index',
        component: () => import("@/views/User/UserList.vue"),
        meta: { requiredRoles: ['ROLE_ADMIN'] },
    },
    {
        path: '/user/list',
        name: 'user-list',
        component: () => import("@/views/User/UserList.vue"),
        meta: { requiredRoles: ['ROLE_ADMIN'] },
    },
    {
        path: '/user/edit/:id',
        name: 'user-edit',
        // component: UserForm,
        component: () => import("@/views/User/UserForm.vue"),
        meta: { requiredRoles: ['ROLE_ADMIN'] },
    },
    {
        path: '/tenant/list',
        name: 'tenant-list',
        // component: TenantList,
        component: () => import("@/views/Tenant/TenantList.vue"),
        meta: { requiredRoles: ['ROLE_ADMIN'] },
    },
    {
        path: '/role/list',
        name: 'role-list',
        // component: RoleList,
        component: () => import("@/views/Role/RoleList.vue"),
        meta: { requiredRoles: ['ROLE_ADMIN'] },
    },


]
adminRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default adminRoutes;
