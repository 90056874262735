<template>
  <div :style="{ 'background' : line.ranking % 2 === 0 ? 'whitesmoke' : 'white' }"
       class="bg-hover-light-primary border-bottom position-relative line w-100">

    <b-dropdown class="position-absolute add-line-before d-none" no-caret no-flip right
                style="top:-10px;right:50px;"
                tag="div" toggle-class="topbar-item text-decoration-none p-0" variant="link">
      <template v-slot:button-content>
        <div class="btn btn-xs btn-primary btn-icon rounded-circle">
          <i class="fad fa-plus"></i>
        </div>
      </template>
      <b-dd-item @click="addDefaultLineBefore">Ajouter un Calcul</b-dd-item>
      <b-dd-item @click="addConditionLineBefore">Ajouter une condition</b-dd-item>
      <b-dd-item @click="addCallableLineBefore">Ajouter une fonction</b-dd-item>
      <b-dd-item @click="addForeachLineBefore">Ajouter une iteration</b-dd-item>
    </b-dropdown>
    <div class="d-flex align-items-center" v-if="line.type === 'foreach'">
      <div v-for="index in level">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <div class="handle">
        <i class="fad fa-arrows mx-1"></i>
      </div>
      <template v-if="!editReturn">
        <div class="d-flex align-items-center ps-1 align-self-stretch">
          <div :title="line.returnArgument.type.label" @dragstart="dragResult"
               class="return-argument py-1 bg-primary px-3 rounded text-white" draggable="true" v-b-tooltip
               v-if="line.returnArgument">
            <template v-if="helper.empty(line.returnArgument.alias)">
              {{ line.returnArgument.name }}
            </template>
            <template v-else>{{ line.returnArgument.alias }}</template>
          </div>

        </div>
        <label class="cursor-pointer">
          <i class="fad fa-edit ms-2"></i>
          <input class="hidden" type="checkbox" v-model="editReturn">
        </label>
      </template>
      <template v-else>
        <div class="d-flex align-items-center ps-1 align-self-stretch">
          <div class="return-argument py-1 bg-primary px-3 rounded text-white" v-if="line.returnArgument">
            <input :ref="'resultInput'" class="border-none text-white bg-transparent min-w-50px"
                   style="outline: none"
                   type="text" v-model="line.returnArgument.alias">
          </div>
        </div>
        <label class="cursor-pointer">
          <i class="fad fa-check-double ms-2"></i>
          <input class="hidden" type="checkbox" v-model="editReturn">
        </label>
      </template>


      <div class="flex-grow-1 d-flex align-items-center">
        <div class="ms-3 fw-bolder">{{ trans('Pour Chaque') }}</div>
        <div class="align-self-stretch d-flex align-items-center mx-3">
          <div @dragover.prevent @drop="addForEachArgument"
               class="rounded min-w-50px p-1 d-flex align-items-center bg-secondary">
            <div v-if="!line.foreachArgument">
              &nbsp;
            </div>
            <div class="bg-primary d-flex w-100 align-items-center rounded text-white" draggable="true" v-else>
              <div class="px-4 py-1 flex-grow-1">
                {{ line.foreachArgument.name }}
              </div>
              <div @click="removeForEachArg()" class="px-2 py-1 bg-danger rounded-end">
                <i class="fad fa-times  text-white"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="ms-3 fw-bolder">
          Calculer
        </div>
        <template>
          <div class="align-self-stretch d-flex align-items-center mx-3" v-if="!line.callable">
            <b-dropdown :disabled="!line.foreachArgument" no-caret no-flip right size="sm"
                        tag="div" toggle-class="topbar-item text-decoration-none" variant="link">
              <template v-slot:button-content>
                <button :disabled="!line.foreachArgument" class="btn btn-sm btn-clean btn-dropdown btn-primary">
                  <template v-if="helper.empty(line.callable)">
                    {{ trans('Selectionnez un Calcul') }}
                  </template>
                  <template v-else>
                    {{ line.callable.label }}
                  </template>
                </button>
              </template>
              <template #default="{ hide }">
                <b-dropdown-item class="p-0 min-w-200px" tag="div">
                  Selectionnez un calcul
                </b-dropdown-item>
                <div class="overflow-auto mh-350px">
                  <template v-for="callable in allowedCallable">
                    <b-dropdown-item @click="setCallable(callable)" class="p-0 min-w-200px" tag="div"
                                     v-if="callable.id !== $store.state.engineMethod.id">
                      <span class="text-primary" v-if="callable.insurer">{{ callable.insurer.label }} ▪</span><span
                        class="text-danger" v-else>GLOBAL ▪</span> {{ callable.label }}
                    </b-dropdown-item>
                  </template>
                </div>
              </template>
            </b-dropdown>
            <div class="ms-2" v-if="!line.callable">
              <button :disabled="!line.foreachArgument" class="btn btn-icon btn-xs btn-primary"
                      title="Créer un calcul compatible"
                      v-b-modal="'foreachMethod_'+uKey"
                      v-b-tooltip.hover>
                <i class="fad fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="ms-3 d-flex align-items-center" v-else>
            <router-link
                :to="{ name : 'engine-method-editor', params: { methodId: line.callable.id, methodSlug: line.callable.code } }"
                class="fw-bolder text-primary text-decoration-underline me-1"
                target="_blank">
              {{ line.callable.label }}
            </router-link>
            <div class="fw-bolder text-primary">
              (
            </div>
            <div class="d-flex align-items-center">
              <template v-for="req,index in line.callable.requirements">
                <span class="fw-bolder mx-1" v-if="index>0">,</span>
                <template v-if="getPlaceForRequirement(req)">
                  <template v-for="place in line.places">
                    <template v-if="place.requirement === req.id">
<!--                      <div class="fs-8 text-gray-400 fw-bolder">-->
<!--                        {{ req.label }} :-->
<!--                      </div>-->
                      <div class="mx-1 bg-light-success px-2 rounded fw-bolder">
                                            <span class="text-success" v-if="place.argument">

                                                {{ place.argument.name }}
                                            </span>
                      </div>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <div :data-id="req.id" @dragover.prevent @drop="linkArgumentToRequirement"
                       class="mx-1 bg-light-danger px-2 rounded fw-bolder text-danger">
                    {{ req.label }}
                  </div>
                </template>
              </template>
            </div>
            <div class="fw-bolder text-primary">
              )
            </div>
            <div>
              <div @click="resetCallable" class="bg-danger px-2 ms-2 rounded cursor-pointer">
                <i class="fad fa-times text-white "></i>
              </div>
            </div>
          </div>
        </template>
        <div class="ms-5 fw-bolder">
          Sinon
        </div>
        <template>
          <div class="align-self-stretch d-flex align-items-center mx-3" v-if="!line.forElse">
            <b-dropdown :disabled="!line.foreachArgument" no-caret no-flip right size="sm"
                        tag="div" toggle-class="topbar-item text-decoration-none" variant="link">
              <template v-slot:button-content>
                <button :disabled="!line.foreachArgument" class="btn btn-sm btn-clean btn-dropdown btn-primary">
                  <template v-if="helper.empty(line.forElse)">
                    {{ trans('Selectionnez un Calcul') }}
                  </template>
                  <template v-else>
                    {{ line.forElse.label }}
                  </template>
                </button>
              </template>
              <template #default="{ hide }">
                <b-dropdown-item class="p-0 min-w-200px" tag="div">
                  Selectionnez un calcul
                </b-dropdown-item>
                <div class="overflow-auto mh-350px">
                  <template v-for="callable in allowedCallable">
                    <b-dropdown-item @click="setForElse(callable)" class="p-0 min-w-200px" tag="div"
                                     v-if="callable.id !== $store.state.engineMethod.id">
                      <span class="text-primary" v-if="callable.insurer">{{ callable.insurer.label }} ▪</span><span
                        class="text-danger" v-else>GLOBAL ▪</span> {{ callable.label }}
                    </b-dropdown-item>
                  </template>
                </div>
              </template>
            </b-dropdown>
            <div class="ms-2" v-if="!line.forElse">
              <button class="btn btn-icon btn-xs btn-primary" title="Créer un calcul compatible"
                      v-b-modal="'foreachMethod_'+uKey"
                      v-b-tooltip.hover>
                <i class="fad fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="ms-3 d-flex align-items-center" v-else>
            <router-link
                :to="{ name : 'engine-method-editor', params: { methodId: line.forElse.id, methodSlug: line.forElse.code } }"
                class="fw-bolder text-primary text-decoration-underline me-1"
                target="_blank">
              {{ line.forElse.label }}
            </router-link>
            <div class="fw-bolder text-primary">
              (
            </div>
            <div class="d-flex align-items-center">
              <template v-for="(req,index) in line.callable.requirements">
                <span class="fw-bolder mx-1" v-if="index>0">,</span>
                <template v-if="getPlaceForRequirement(req)">
                  <template v-for="place in line.places">
                    <template v-if="place.requirement === req.id">
<!--                      <div class="fs-8 text-gray-400 fw-bolder">-->
<!--                        {{ req.label }} :-->
<!--                      </div>-->
                      <div class="mx-1 bg-light-success px-2 rounded fw-bolder">
                                            <span class="text-success" v-if="place.argument">

                                                {{ place.argument.name }}
                                            </span>
                      </div>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <div class="mx-1 bg-light-danger px-2 rounded fw-bolder text-danger">
                    {{ req.label }}
                  </div>
                </template>
              </template>
            </div>
            <div class="fw-bolder text-primary">
              )
            </div>
            <div>
              <div @click="resetForElse" class="bg-danger px-2 ms-2 rounded cursor-pointer">
                <i class="fad fa-times text-white "></i>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="d-flex pe-5 py-3 align-items-center">
        <div @click="removeLine" class="btn btn-xs btn-danger btn-icon">
          <i class="fad fa-trash"></i>
        </div>
        <div :title="'Ligne '+line.ranking" class="text-dark badge badge-warning ms-1" v-b-tooltip.hover.left>
                <span>
                    {{ line.ranking }}
                </span>
        </div>
      </div>
    </div>
    <b-modal :id="'foreachMethod_'+uKey" :ref="'foreachMethod_'+uKey" :title="trans('Method')" centered scrollable>
      <form>
        <div class="row">
          <div class="col-auto m-2 flex-grow-1">
            <label class="required form-label">{{ trans('Nom') }}</label>
            <div>
              <b-form-input :state="!helper.empty(method.label)" class="form-control" type="text"
                            v-model="method.label">
              </b-form-input>
            </div>
          </div>
        </div>
        <div class="row" v-if="engine">
          <div class="col-auto m-2 flex-grow-1">
            <label :for="'value'" class="form-label">{{ trans('Global') }}</label>
            <div>
              <div class="form-check form-switch form-check-custom form-check-solid">
                <input class="form-check-input " type="checkbox" v-model="global"/>
              </div>
            </div>
          </div>
          <div class="col-auto m-2 flex-grow-1" v-if="!global && insurer">
            <label class="required form-label">{{ trans('Assureur') }}</label>
            <div>
              <b-form-select class="form-control form-select">
                <b-form-select-option :value="null">Aucun</b-form-select-option>
                <b-form-select-option :value="insurer" :key="insurer.id" v-for="insurer in insurers">
                  {{ insurer.label }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-auto m-2 flex-grow-1">
            <label class="form-label">{{ trans('Description') }}</label>
            <div>
              <textarea class="form-control" v-model="method.description"></textarea>
            </div>
          </div>
        </div>
      </form>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel()" size="sm" variant="secondary">
          <i class="fad fa-times"></i>
          {{ trans('Cancel') }}
        </b-button>
        <b-button @click="editMethod()" size="sm" variant="primary">
          <i class="fad fa-save"></i>
          {{ trans('Save') }}
        </b-button>
      </template>
    </b-modal>

    <div class="d-flex px-5 py-1 bg-light-danger align-items-center" v-if="errorMessages.length > 0">
      <div class="text-gray-400 fs-8  rounded px-2">
        Erreur(s) :
      </div>
      <div class="bg-danger fs-8 text-white rounded px-2 m-1" v-for="error in errorMessages">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import EngineMethodLine from '@/entity/EngineMethodLine';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import EngineArgument from "@/entity/EngineArgument";
import Popup from "@/entity/Popup";
import ItemType from "@/entity/ItemType";
import {helper} from "@/services/Helper";
import EngineMethod from "@/entity/EngineMethod";
import {api} from "@/services/Api";
import Insurer from '@/entity/Insurer';
import Engine from '@/entity/Engine';
import EngineMethodRequirement from "@/entity/EngineMethodRequirement";
import DataType from "@/entity/DataType";
import EnginePlace from "@/entity/EnginePlace";

@Component({})
export default class EngineForEachLineEditor extends Vue {

  @Prop() line!: EngineMethodLine
  @Prop() level!: number
  itemTypeAllowed: any = null
  uKey = helper.generateId()
  method = new EngineMethod()
  global = false
  insurers: Insurer[] = []
  insurer: Insurer | null = null
  engine: Engine | null = null
  allowedCallable: EngineMethod[] = []
  requirement: EngineMethodRequirement | null = null
  types: DataType[] = []
  generatedPlace: EnginePlace | null = null
  currentMethod: EngineMethod | null = null
  editReturn = false

  async mounted() {
    this.insurers = await this.$store.getters.insurers()
    this.insurer = this.$store.state.engineInsurer
    this.engine = this.$store.state.engine
    this.currentMethod = this.$store.state.engineMethod
    this.types = await this.$store.getters.dataTypes()
    this.updateAllowedCallable()
    // console.log(JSON.parse(JSON.stringify(this.line.places)))
    this.check()

  }

  dragResult(evt: any) {
    evt.dataTransfer.setData('text/plain', JSON.stringify(this.line.returnArgument));
  }

  removeForEachArg() {
    this.requirement = null
    this.line.foreachArgument = undefined
    this.check()
    this.$forceUpdate()

  }

  setCallable(callable: EngineMethod) {
    this.line.places = []
    this.line.callable = callable
    this.matchForEachArgumentToCallableRequirement()
    this.$forceUpdate()
  }

  setForElse(callable: EngineMethod) {
    // this.line.places = []
    this.line.forElse = callable
    // this.matchForEachArgumentToCallableRequirement()
    this.$forceUpdate()
  }

  resetCallable() {
    this.line.callable = undefined
    this.line.places = []
    this.check()
    this.$forceUpdate()
  }

  resetForElse() {
    this.line.forElse = undefined
    // this.line.places = []
    this.check()
    this.$forceUpdate()
  }

  matchForEachArgumentToCallableRequirement() {
    if (this.generatedPlace) {
      this.line.callable!.requirements.forEach((r: EngineMethodRequirement) => {
        if (r.itemType && this.requirement!.itemType) {
          if (r.type.id === this.requirement!.type.id && r.itemType!.id === this.requirement!.itemType!.id) {
            this.generatedPlace!.requirement = r.id
          }
        } else {
          if (r.type.id === this.requirement!.type.id) {
            this.generatedPlace!.requirement = r.id
          }
        }
      })
      this.addPlace(this.generatedPlace)
      this.check()
    }
  }

  linkArgumentToRequirement(evt: any) {
    let reqId = parseInt(evt.target.getAttribute('data-id'))
    let data = evt.dataTransfer.getData('text/plain')
    // console.log(data)
    // console.log(this.line.callable)
    let req = this.line.callable!.requirements.find((r: EngineMethodRequirement) => {
      // console.log(typeof r.id + ' === ' + typeof reqId)
      return r.id === reqId
    })
    // console.log(req)
    if (data && req) {
      data = JSON.parse(data);
      let arg = new EngineArgument(data)
      if (arg.resultOfLine) {
        // console.log(arg)
        const match = this.$store.state.returnArguments.find((ra: EngineArgument) => {
          return ra.uuid === arg.uuid
        })
        // console.log(match)
        if (!match) {
          this.$store.state.returnArguments.push(arg)
        } else {
          arg = match
        }
        // console.log(arg)
        if (this.currentMethod) {
          const line: any = this.currentMethod.getLineByGid(arg.resultOfLine!)
          if (line.ranking >= this.line.ranking) {
            const pop = new Popup('Erreur', 'Cet emplacement entrée n\'accepte que les paramètres au dessus de la ligne ' + this.line.ranking, 'danger', 'fad fa-bug', false);
            return
            // throw 'Cet emplacement n\'accepte que les paramètres au dessus de la ligne ' + this.line.number
          }
        }
      }
      if (arg.type.format !== req.type.format) {
        const pop = new Popup('Erreur', 'Cet emplacement n\'accepte que les propriétés de type ' + req.type.label, 'danger', 'fad fa-bug', false);
        return
        // throw 'Cet emplacement n\'accepte que les propriétés de type ' + req.type.label
      }
      const place = new EnginePlace()
      place.argument = arg
      place.requirement = reqId
      this.addPlace(place)
      // console.log(place)
      this.$forceUpdate()
      this.check()
    }
  }

  addPlace(place: EnginePlace) {
    if (place.requirement && this.line.callable) {
      const index = this.line.callable.requirements.findIndex((r: EngineMethodRequirement) => {
        return r.id === place.requirement
      })
      if (index !== -1) {
        this.line.places.splice(index, 1, place)
      } else {
        this.line.places.push(place)
      }
      this.check()
    }

  }

  getPlaceForRequirement(req: EngineMethodRequirement) {
    return this.line.places.find((a: EnginePlace) => {
      return a.requirement === req.id
    }) as any
  }

  async addForEachArgument(evt: any) {

    let data = evt.dataTransfer.getData('text/plain')
    if (data) {
      data = JSON.parse(data);
      const arg = new EngineArgument(data)
      if (arg.type && arg.type.code === 'OBJECT_GROUP') {
        this.line.places = []
        this.line.foreachArgument = arg

        this.$forceUpdate()
        this.updateAllowedCallable()
      } else {
        const pop = new Popup('Erreur', 'Cet Emplacement n\'autorise que les propriétés de type "Group d\'objets"', 'danger', 'fad fa-bug', false);
      }
    }
    this.check()
  }


  loadRequirement() {
    if (this.line.foreachArgument) {
      const allowed = this.$store.state.itemTypes.find((i: ItemType) => {
        return i.uuid === this.line.foreachArgument!.value
      })
      const type: any = this.types.find((d: DataType) => {
        return d.code === 'OBJECT'
      })
      this.requirement = new EngineMethodRequirement()
      this.requirement.type = type
      this.requirement.itemType = allowed
      this.requirement.label = allowed.name

      const argument = new EngineArgument()
      argument.type = type
      argument.name = allowed.name
      argument.itemType = allowed
      argument.value = this.line.foreachArgument.value
      argument.code = allowed.code
      this.generatedPlace = new EnginePlace()
      this.generatedPlace.argument = argument
    }
  }

  updateAllowedCallable() {
    this.loadRequirement()
    this.allowedCallable = []
    this.$store.state.engineMethods.forEach((m: EngineMethod) => {
      // console.log(m.label)
      if (m.requirements.length === 0) {
        this.appendAllowed(m)
      } else {
        m.requirements.forEach((r: EngineMethodRequirement) => {
          if (this.requirement) {
            // console.log(this.requirement)
            if (r.itemType) {
              if (r.type.id === this.requirement.type.id && r.itemType!.id === this.requirement.itemType!.id) {
                this.appendAllowed(m)
              }
            } else {
              if (r.type.id === this.requirement.type.id) {
                this.appendAllowed(m)
              }
            }
          }
        })
      }
    })
  }

  appendAllowed(callable: EngineMethod) {
    // console.log('allow callable ' + callable.code + (callable.insurer ? ' de ' + callable.insurer.label : ' Global'))
    let insurerMethod = null
    if (this.$store.state.engineInsurer) {
      insurerMethod = this.$store.state.engineMethods.find((m: EngineMethod) => {
        return m.code === callable.code && m.insurer && m.insurer.id === this.$store.state.engineInsurer.id
      })
    }
    if (insurerMethod) {
      // console.log('insurer exist')
      if (!this.allowedCallable.includes(insurerMethod)) {
        this.allowedCallable.push(insurerMethod)
      }
    } else {
      if (!this.allowedCallable.includes(callable)) {
        this.allowedCallable.push(callable)
      }
    }
  }

  async editMethod() {
    this.$store.commit('loading')
    const requirement: any = this.requirement;
    const method = {
      id: this.method.id,
      label: this.method.label,
      description: this.method.description,
      requirements: [requirement]
    }
    let url = 'engine/method/edit' + (!this.global && this.engine ? '/' + this.engine.id : '') + (!this.global && this.engine && this.insurer ? '/' + this.insurer.slug : '');
    const res = await api.post(api.core, url, {method})
    if (res && res.data && res.data.method) {
      const occ = new EngineMethod(res.data.method)
      this.$store.state.engineMethods.push(occ)
    }
    const modal = 'foreachMethod_' + this.uKey
    if (this.$refs[modal]) {
      (this.$refs[modal] as any).hide()
    }
    this.global = false
    this.$store.commit('stopLoading')
    // this.loadMethods()
  }


  removeLine() {
    this.$emit('removeLine', {line: this.line})
  }

  // addLineBefore() {
  //     this.$emit('addLineBefore', {type: 'default', before: this.line})
  // }

  addDefaultLineBefore() {
    this.$emit('addLineBefore', {type: 'default', before: this.line})
  }

  addConditionLineBefore() {
    this.$emit('addLineBefore', {type: 'condition', before: this.line})
  }

  addForeachLineBefore() {
    this.$emit('addLineBefore', {type: 'foreach', before: this.line})
  }

  addCallableLineBefore() {
    this.$emit('addLineBefore', {type: 'callable', before: this.line})
  }

  errorMessages: string[] = []

  @Watch('line', {immediate: true, deep: false})
  check() {
    this.errorMessages = []
    if (!this.line.foreachArgument) {
      this.errorMessages.push('Selectionnez un paramètre de type "Groupe d\'objets"');
    }
    if (!this.line.callable) {
      this.errorMessages.push('Selectionnez un calcul')
    }
    this.line.places.forEach((a: EnginePlace, index) => {
      if (!a.argument) {
        this.errorMessages.push('L\'un des paramètres est mal configuré')
      }
    })
    if (this.line.callable) {
      this.line.callable.requirements.forEach((r: EngineMethodRequirement) => {
        if (!(this.getPlaceForRequirement(r) instanceof EnginePlace)) {
          this.errorMessages.push('Paramètre manquant pour l\'entrée ' + r.label)
        }
      })
    }

    this.line.isValid = this.errorMessages.length === 0
    this.$forceUpdate()

  }

}
</script>
<style scoped>
.line:hover .add-line-before {
  display: block !important;
}

.line {
  animation: open .8s linear;
}

@keyframes open {
  0% {
    max-height: 0;
    overflow: hidden;
    background: red !important;
  }
  /*90% {*/
  /*    max-height: 100px;*/
  /*    overflow: auto*/
  /*}*/
  100% {
    max-height: 1000px;
    overflow: visible;
    background: inherit;
  }
}
</style>
