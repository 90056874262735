/* eslint-disabled */

export default class DeviceExtend {

    public type? = '';

    constructor(object?: any) {
    }


    get icon() {
        switch (this.type) {
            case 'smartphone':
                return 'fad fa-mobile-alt'
                break;
            default:
                return 'fad fa-desktop'
                break;
        }



    }

}
