<template>
    <div class="row gy-10 gx-xl-10">
        <div class="col-xl-12">
            <CotationSearch :compact="true"></CotationSearch>
        </div>
<!--        <div class="col-xl-4">-->
<!--            <div class="card card-xxl-stretch mb-5 mb-xl-10">-->
<!--                <div class="card-header border-0 pt-5">-->
<!--                    <h3 class="card-title align-items-start flex-column">-->
<!--                        <span class="card-label fw-bolder fs-3 mb-1">Aperçu des Tâches</span>-->
<!--                        <span class="text-muted fw-bold fs-7">({{todos.length}}) Tâches en attente</span>-->
<!--                    </h3>-->
<!--                    <div class="card-toolbar">-->
<!--                        <div class="d-flex align-items-center me-3">-->
<!--                            <label :class="[ showAllTodos ? 'btn-primary' : 'btn-secondary' ]" class="btn btn-icon btn-xs"-->
<!--                                   for="showAllTodos">-->
<!--                                <i class="fad fa-eye"></i>-->
<!--                                <input class="hidden" id="showAllTodos" type="checkbox" v-model="showAllTodos" value=""/>-->
<!--                            </label>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="card-body py-3">-->
<!--                    <template v-for="todo in todos">-->
<!--                        <ManageAvenant :todo="todo" :key="'todo_'+todo.uuid" v-on:validateTask="validateTask" v-on:cancelTask="cancelTask"-->
<!--                        v-if="todo.type === 'MANAGE_AVENANT'"></ManageAvenant>-->
<!--                        <ManageCotation :todo="todo" :key="'todo_'+todo.uuid" v-on:validateTask="validateTask" v-on:cancelTask="cancelTask"-->
<!--                                        v-if="todo.type === 'MANAGE_COTATION'"></ManageCotation>-->
<!--                    </template>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import InputNumber from "@/components/InputNumber.vue";
    import {api} from "@/services/Api";
    import Todo from "@/entity/Todo";
    import CotationSearch from "@/views/Income/CotationSearch.vue";
    import ManageAvenant from "@/views/Todo/ManageAvenant.vue";
    import Popup from "@/entity/Popup";
    import ManageCotation from "@/views/Todo/ManageCotation.vue";

    @Component({
        components: {ManageCotation, ManageAvenant, CotationSearch, InputNumber}
    })
    export default class ManagerCotations extends Vue {

        todos: Todo[] = []
        showAllTodos = false
        total = 0
        categories = []
        search = {
            branch: null,
            after: null,
            before: null,
            contains: null,
            minimalState: 0,
            hasClientInfos: false,
            page: 1,
            statut: [] as any
        }

        resetSearch() {
            this.search = {
                branch: null,
                after: null,
                before: null,
                contains: null,
                minimalState: 0,
                hasClientInfos: false,
                page: 1,
                statut: []
            }
        }

        mounted() {
            this.loadTodos()
        }

        async loadTodos() {
            const res = await api.get(api.auth, 'todo/list')
            if (res && res.data && res.data.todos) {
                res.data.todos.forEach((d: Todo) => {
                    this.todos.push(new Todo(d))
                })
            }

        }



        async validateTask(todo: Todo) {
            const p = new Popup('En cours', 'Validation de la tâche', 'success', 'fad fa-cogs', true)
            todo.updating = true
            const res = await api.get(api.auth, 'todo/validate/' + todo.uuid)
            if (res && res.data && res.data.todo) {
                todo.status = res.data.todo.status
            }
            p.hide()
            todo.updating = false
            // console.log(res)
        }

        async cancelTask(todo: Todo) {
            const p = new Popup('En cours', 'Annulation de la tâche', 'danger', 'fad fa-cogs', true)
            todo.updating = true
            const res = await api.get(api.auth, 'todo/cancel/' + todo.uuid)
            if (res && res.data && res.data.todo) {
                todo.status = res.data.todo.status
            }
            todo.updating = false
            // console.log(res)
            p.hide()
        }
    }
</script>
<style scoped>
</style>
