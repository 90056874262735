<template>
    <div>
        <label :class="{ 'fw-bolder cursor-pointer text-hover-primary' : itemHasChildren, 'text-primary' : itemHasChildren && !expand }"
               class="AvenantDetail p-2 d-flex align-items-center justify-content-between bg-hover-light-primary border-bottom"
               v-if="itemIsNotCustomer()">
            <input class="hidden" type="checkbox" v-model="expand">
            <div class="d-flex align-items-center flex-">
                <span v-for="index in level">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <template v-if="itemHasChildren()">
                    <i class="fad fa-angle-right fs-2 me-2" v-if="!expand"></i>
                    <i class="fad fa-angle-down fs-2 me-2" v-else></i>
                </template>

                <template v-if="property && property.name !== item.name">
                    {{property.name}}
                </template>
                {{item.name}}

<!--                >>{{item.beneficiary}}-->
            </div>
            <div>
                <template v-if="item.beneficiary">
<!--                    {{item.beneficiary}}-->
                    {{item.beneficiary.getPropByCode('name')}} {{item.beneficiary.getPropByCode('prename')}}
                </template>
            </div>
            <div class="fw-bolder ms-5" v-if="!helper.empty(item.premium)">
                <span class="badge ms-5 badge-danger" v-if="!helper.empty(item.premium)">{{currency(item.premium)}}</span>
                <span v-else>{{ currency(item.calculatedPremium) }}</span>
            </div>
        </label>

        <template v-if="expand">
            <template v-for="prop in item.properties">
                <template v-for="child in prop.children">
                    <!--                    {{prop.children}}-->
                    <AvenantDetail :avenant="avenant" :item="child" :level="level+1" :property="prop"></AvenantDetail>
                </template>
            </template>
        </template>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Item from "@/entity/Item";
    import Property from "@/entity/Property";
    import Avenant from "@/entity/Avenant";

    @Component({})
    export default class AvenantDetail extends Vue {
        @Prop() avenant!: Avenant
        @Prop() item!: Item
        @Prop() property?: Property
        @Prop({default: 0}) level!: number
        expand = true

        itemIsNotCustomer() {
            return this.item.model.className !== 'Customer'
        }

        itemHasChildren() {
            // let result = false
            return this.item.properties.some((p: Property) => p.children.length > 0)
            // this.item.properties.forEach((p: Property) => {
            //     if (p.children.length > 0) {
            //         result = true
            //     }
            // })
            // return result

            // return this.item.model.className !== 'Asset' && this.item.properties.some((p: Property) => p.children.length > 0)
        }

        mounted(): void {
            if (this.item.model.className === 'Asset') {
                // console.log(this.item.name+' '+this.item.uuid)
                console.log(this.item.beneficiary)
                // console.log(this.item)
            }

        }


    }
</script>
<style scoped>
</style>
