<template>
  <div>
    <div class="w-100 offers" v-if="question.isAuthorized()">
      <div class="bg-success fs-2 text-center py-5 text-white fw-bolder rounded-top mb-5" v-if="results.length > 0">
        {{ results.length }} offres correspondent à vos besoins
      </div>
      <div :key="'calcul_' + calculUuid">
        <div v-if="loading" class="d-flex flex-column align-items-center justify-content-center bg-light-warning  rounded border-dashed border-warning">
          <Loading/>
        </div>
        <div v-else-if="results.length === 0"
             class="d-flex flex-column align-items-center justify-content-center bg-light-warning  rounded border-dashed border-warning">
          <div class="align-items-center justify-content-center d-flex flex-column">
            <div class="fw-bolder text-gray-400 fs-2 my-10">
              <i class="fad fa-empty-set fs-5x"></i>
            </div>
            <div class="fw-bolder text-gray-400 fs-2 my-10" v-if="!invalid">
              Désolé, aucun produit compatible à vos besoins
              <label class="btn btn-xs btn-icon btn-primary" v-if="hasRole('ROLE_MANAGER')">
                <i class="fad fa-cogs"></i>
                <input type="checkbox" v-model="showComparisonDetails" class="hidden">
              </label>
            </div>
            <div class="fw-bolder text-gray-400 fs-2 my-10" v-else>
              Veuillez renseigner les informations nécessaires à la cotation
              <label class="btn btn-xs btn-icon btn-primary" v-if="hasRole('ROLE_MANAGER')">
                <i class="fad fa-cogs"></i>
                <input type="checkbox" v-model="showComparisonDetails" class="hidden">
              </label>
            </div>
          </div>
        </div>
        <template v-else>
          <div class="d-flex w-100 flex-grow-0" v-if="branch && !isMobile">
            <div class=" p-3" style="position: sticky;left:0;top:0;background: white;z-index: 2">
              <table class="table-striped gy-2 gs-3 guarantee">
                <thead>
                <tr>
                  <th class="text-center">
                    Garanties
                  </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(guarantee, index) in branch.guarantees">
                  <template v-if="onePackHasGuarantee(guarantee)">
                    <tr>
                      <td class="p-2" style="white-space: nowrap">
                        <div class="d-flex justify-content-between align-items-center"
                             style="width:17rem;white-space: initial;">
                          {{ guarantee.label }}
                          <i class="fad fa-question-circle ms-2 cursor-pointer"></i>
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div>
            <div v-if="loading" class="d-flex justify-content-center align-items-center flex-grow-1">
              <Loading/>
            </div>
            <div v-else class="results-container overflow-auto" style="flex:2;">
              <div class="d-flex flex-column align-items-center justify-content-center  bg-light-primary py-5"
                   v-if="packSelected">
                <div class="d-flex justify-content-center">
                  <div class="justify-content-center align-items-center d-flex flex-column">
                    <div>
                      <img :src="uploadRoot + packSelected.pack.insurer.photo" :alt="packSelected.pack.insurer.label"
                           class="mh-50px" v-if="packSelected.pack.insurer.photo">
                    </div>
                    <div class="fw-bolder fs-2x ms-5">
                      {{ packSelected.pack.label }}
                      <!--                      {{ getDuree() ? ' / ' + getDuree() : '' }}-->
                    </div>
                    <div class="fs-3x fw-bolder">
                      {{ currency(packSelected.premium) }}
                    </div>
                  </div>
                  <div class="ms-5 d-flex flex-column  ps-5" style="border-left: 1px gainsboro solid">
                    <template v-for="pg in packSelected.pack.packGuarantees">
                      <div class="py-2 fw-bolder" v-if="!pg.isOptional || pg.selected">
                        {{ pg.guarantee.label }}
                      </div>
                    </template>
                  </div>
                </div>
                <div class="py-5">
                  <label class="btn  btn-icon btn-primary" v-if="hasRole('ROLE_MANAGER')">
                    <i class="fad fa-cogs"></i>
                    <input type="checkbox" v-model="showComparisonDetails" class="hidden">
                  </label>
                  <div class="btn btn-info btn-icon ms-2" v-if="hasRole('ROLE_MANAGER')"
                       @click="showDetails(packSelected)">
                    <i class="fad fa-cog"></i>
                  </div>
                  <div class="btn btn-danger ms-2" @click="packSelected = null">
                    Modifier
                  </div>
                  <div class="btn bt-sm btn-warning ms-5" @click="subscribe(packSelected)">
                    Souscrire
                  </div>
                </div>
              </div>


              <div class="w-100 overflow-auto p-3" v-if="!packSelected">
                <table class="table-striped gy-2 gs-3 ms-5 results">
                  <thead>
                  <tr>
                    <template v-for="result in results">
                      <th class="text-center">
                        <div>
                          <div class=" px-5 text-center">
                            <div class="fw-bolder fs-2">
                              <div class="pb-1 fw-bolder" style="white-space: nowrap">
                                <img :src="uploadRoot + result.pack.insurer.photo" :alt="result.pack.insurer.label"
                                     class="mh-50px" v-if="result.pack.insurer.photo">
                                <template v-else>
                                  {{ result.pack.insurer.label }}
                                </template>
                              </div>
                              <div class="fs-6">
                                {{ result.pack.label }}
                              </div>

                              <div>
                                <div v-if="hasRole('ROLE_MANAGER') && workflow === WORKFLOWS.ASACI"
                                     style="min-width: 100px;"
                                     class="d-flex justify-content-center align-items-center px-4 py-4"
                                     :set="remaining=getRemainingCertificates(result.pack.insurer)">
                                  <div class="fw-bolder text-primary" v-if="attestations.blue > 0">
                                    <i class="fad fa-file text-primary fs-2"></i>
                                    {{ remaining.blue }}
                                  </div>
                                  <div class="fw-bolder" v-if="attestations.yellow>0">
                                    <i class="fad fa-file text-warning fs-2"></i>
                                    {{ remaining.yellow }}
                                  </div>
                                  <div class="fw-bolder text-success" v-if="attestations.green>0">
                                    <i class="fad fa-file text-success fs-2"></i>
                                    {{ remaining.green }}
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div class="badge badge-light-danger text-decoration-line-through fs-2"
                                 v-if="getReductionAmount(result) > 0">
                              {{ helper.currencyFormat(result.premium) }}
                            </div>
                            <div>
                              <div class=" text-primary fw-bolder">
                                  <span class="fs-2x">
                                    {{ helper.currencyFormat(result.premium - getReductionAmount(result)) }}
                                  </span>
                                <br>
                                <span>
                                    <!--                                  {{ getDuree() ? ' / ' + getDuree() : '' }}-->
                                  </span>
                              </div>
                              <div class="d-flex align-items-center justify-content-center mb-2">
                                <div class="btn bt-sm btn-warning" @click="subscribe(result)"
                                     v-if="canSubscribe(result)">
                                  Souscrire
                                </div>
                                <button class="btn bt-sm btn-light-dark whitespace-no-wrap" disabled v-else>
                                  Rupture de Stock
                                </button>

                                <label class="btn ms-2 btn-icon btn-primary" v-if="hasRole('ROLE_MANAGER')">
                                  <i class="fad fa-cogs"></i>
                                  <input type="checkbox" v-model="showComparisonDetails" class="hidden">
                                </label>
                                <!-- <div class="btn btn-info btn-icon ms-2" v-if="hasRole('ROLE_MANAGER')"
                                  @click="showDetails(result)">
                                  <i class="fad fa-cog"></i>
                                </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                    </template>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="guarantee, index in branch.guarantees">
                    <tr v-if="onePackHasGuarantee(guarantee)">
                      <template v-for="result, index in results">
                        <td :key="helper.generateId()" class="p-2 text-center" valign="middle">
                          <template v-if="result.pack.hasGuarantee(guarantee)">
                            <template v-for="pg in result.pack.packGuarantees">
                              <div v-if="pg.guarantee.id === guarantee.id">
                                <template v-if="pg">
                                  <template v-if="!pg.isOptional">
                                    <i class="fad fa-shield-check text-success fs-2"></i>
                                  </template>
                                  <template v-else-if="guarantee.method">
                                    <template v-if="getOptionalPremium(result, guarantee)">
                                      <template v-for="optional in result.optionals">
                                        <div class="d-flex justify-content-center align-items-center w-100"
                                             v-if="optional.code === guarantee.method.code">
                                          <!--                                                                                    <label for="">-->
                                          <!--                                                                                        {{optional.append}}-->
                                          <label class="fw-bolder cursor-pointer text-primary text-decoration-underline"
                                                 v-if="!pg.selected">
                                            <input :key="helper.generateId()" @change="toggleOptional(result, pg)"
                                                   class="hidden" type="checkbox" v-model="pg.selected">
                                            <i class="fad fa-plus-circle text-primary"></i>
                                            {{
                                              helper.currencyFormat(optional.premium)
                                            }}
                                          </label>
                                          <div class="d-flex w-100 justify-content-center position-relative" v-else>
                                            <i class="fad fa-shield-check text-success fs-2"></i>
                                            <label
                                                class="fw-bolder cursor-pointer text-primary text-decoration-underline position-absolute
                                                                                                                                                                d-flex align-items-center"
                                                style="right:0;height:100%;">
                                              <input :key="helper.generateId()" @change="toggleOptional(result, pg)"
                                                     class="hidden" type="checkbox" v-model="pg.selected">
                                              <i class="fad fa-times-circle cursor-pointer text-danger float-end"></i>
                                            </label>
                                          </div>
                                          <!--                                                                                    </label>-->
                                        </div>
                                      </template>
                                    </template>
                                    <template v-else>
                                      <div class="d-flex w-100 justify-content-center position-relative">
                                        <i class="fad cursor-pointer fa-exclamation-circle text-warning fs-2"
                                           title="Les conditions requises ne sont pas remplie"></i>

                                        <label
                                            class="fw-bolder cursor-pointer text-primary text-decoration-underline position-absolute
                                                                                                                                                                d-flex align-items-center"
                                            style="right:0;height:100%;" v-if="pg.selected">
                                          <input :key="helper.generateId()" @change="toggleOptional(result, pg)"
                                                 class="hidden" type="checkbox" v-model="pg.selected">
                                          <i class="fad fa-times-circle cursor-pointer text-danger float-end"></i>
                                        </label>
                                      </div>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <i class="fad cursor-pointer fa-exclamation-circle text-warning fs-2"
                                       title="Methode de calcul manquante"></i>
                                  </template>
                                </template>
                                <template v-else>
                                  <i class="fad fa-times-circle fs-2 text-gray-400"></i>
                                </template>
                              </div>
                            </template>
                          </template>
                          <template v-else>

                            <i class="fad fa-times-circle fs-2 text-gray-400" title="Garantie non couverte"></i>
                          </template>
                          <div :set="pg = result.pack.hasGuarantee(guarantee)"
                               class="text-center d-flex justify-content-center">
                          </div>
                        </td>
                      </template>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="branch && isMobile">
            <div v-for="result in results" style="border-bottom:5px whitesmoke solid" class="py-5 px-2">
              <div class="d-flex justify-content-between align-items-center">
                <div class="pb-1 fw-bolder" style="white-space: nowrap">
                  <img :src="uploadRoot + result.pack.insurer.photo" :alt="result.pack.insurer.label"
                       class="mh-50px mw-150px" v-if="result.pack.insurer.photo">
                  <template v-else>
                    {{ result.pack.insurer.label }}
                  </template>
                </div>

                <div>
                  <div class="btn btn-warning" @click="subscribe(result)">
                    Souscrire
                  </div>
                </div>
              </div>
              <div class="badge badge-light-danger text-decoration-line-through fs-2"
                   v-if="getReductionAmount(result) > 0">
                {{ helper.currencyFormat(result.premium) }}
              </div>
              <div>
                <div class="d-flex text-primary fw-bolder align-items-center">
                  <div class="fs-2x">

                    {{ helper.currencyFormat(result.premium - getReductionAmount(result)) }}
                  </div>
                  <div class="ms-2">
                    <!--                    {{ getDuree() ? ' / ' + getDuree() : '' }}-->
                  </div>
                </div>
              </div>
              <div class="text-primary pb-2">
                Les garanties communes +
              </div>
              <div class="px-2">
                <template v-for="pg, index in result.pack.packGuarantees">
                  <div :key="'pg_' + index" v-if="!allPackHasGuarantee(pg.guarantee, result.pack)"
                       class="d-flex align-items-center justify-content-between py-1">
                    <div>
                      {{ pg.guarantee.label }}
                    </div>
                    <div>
                      <div v-if="!pg.isOptional" class="fw-bolder">
                        Inclus
                      </div>
                      <div v-else :set="optional = getOptionalPremium(result, pg.guarantee)" class="fw-bolder">
                        <template v-if="optional">
                          <label class="fw-bolder cursor-pointer text-primary" v-if="!pg.selected">
                            <input :key="helper.generateId()" @change="toggleOptional(result, pg)" class="hidden"
                                   type="checkbox" v-model="pg.selected">
                            <i class="fad fa-plus-circle text-primary"></i>
                            {{
                              currency(optional.premium)
                            }}
                          </label>
                          <div class="d-flex w-100 justify-content-center align-items-center position-relative" v-else>
                            <i class="fad fa-shield-check text-success fs-2"></i>
                            <label
                                class="fw-bolder cursor-pointer text-primary text-decoration-underline d-flex align-items-center"
                                style="right:0;height:100%;">
                              <input :key="helper.generateId()" @change="toggleOptional(result, pg)" class="hidden"
                                     type="checkbox" v-model="pg.selected">
                              <i class="fad fa-times-circle cursor-pointer text-danger ms-5 fs-2 float-end"></i>
                            </label>
                          </div>
                          <!-- <i class="fad fa-plus-circle text-black"></i> -->
                          <!-- {{ currency(optional.premium) }} -->
                        </template>
                        <template v-else>
                          <div class="d-flex w-100 justify-content-center position-relative">
                            <i class="fad cursor-pointer fa-exclamation-circle text-warning fs-2"
                               title="Les conditions requises ne sont pas remplie"></i>

                            <label
                                class="fw-bolder cursor-pointer text-primary text-decoration-underline position-absolute
                                                                                                                                                                d-flex align-items-center"
                                style="right:0;height:100%;" v-if="pg.selected">
                              <input :key="helper.generateId()" @change="toggleOptional(result, pg)" class="hidden"
                                     type="checkbox" v-model="pg.selected">
                              <i class="fad fa-times-circle cursor-pointer text-danger float-end"></i>
                            </label>
                          </div>
                        </template>

                      </div>
                    </div>
                  </div>
                </template>

              </div>
              <!-- <div class="fs-6">
              {{ result.pack.label }}
          </div> -->
            </div>
          </div>
        </template>

      </div>

      <transition name="slide-left-back">
        <div class="position-fixed bg-white p-5 shadow-lg top-0 h-100" style="right:0" v-if="packDetails
          && hasRole('ROLE_MANAGER')">
          <div class="d-flex justify-content-between align-items-center">
            <h1 class="mb-0">{{ trans('Détails de la cotation') }}</h1>
            <div class="btn btn-danger btn-xs ms-5 btn-icon" @click="hideDetails">
              <i class="fad fa-times"></i>
            </div>
          </div>
          <div class="mt-5">
            <div>
              <img :src="uploadRoot + packDetails.pack.insurer.photo" :alt="packDetails.pack.insurer.label"
                   class="mh-50px">
            </div>
            <div class="my-5 fw-bolder">
              {{ packDetails.pack.insurer.label }} ▪️ {{ packDetails.pack.label }}
            </div>
            <div>
              <table class="table table-striped">
                <tbody>
                <tr v-for="detail in packDetails.details">
                  <td class="p-2">
                    {{ detail.name }}
                  </td>
                  <td class="text-end p-2">
                    {{ helper.currencyFormat(detail.value) }}
                  </td>
                </tr>
                </tbody>
              </table>

              <div>

                <h2>Backend</h2>
                <table class="table table-striped">
                  <tbody>
                  <tr v-for="detail in backendDetails">
                    <td class="p-2">
                      {{ detail.name }}
                    </td>
                    <td class="text-end p-2">
                      {{ helper.currencyFormat(detail.value) }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="bg-dark px-5">
                      Premium Details
                    </td>
                  </tr>
                  <tr v-for="detail in backendPremiumdetails">
                    <td class="p-2">
                      {{ detail.label }}
                    </td>
                    <td class="text-end p-2">
                      {{ helper.currencyFormat(detail.value) }}
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="slide-left-back">
        <div class="position-fixed bg-white shadow-lg top-0 h-100" style="right:0;z-index:2"
             v-if="showComparisonDetails && hasRole('ROLE_MANAGER')">
          <div class="d-flex justify-content-between align-items-center  p-5">
            <h1 class="mb-0">{{ trans('Détails de la comparaison') }}</h1>
            <div class="btn btn-danger btn-xs ms-5 btn-icon" @click="showComparisonDetails = false">
              <i class="fad fa-times"></i>
            </div>
          </div>
          <div class="">
            <div v-for="insurer in comparisonResume"
                 style="border-top:1px whitesmoke solid;border-bottom:1px whitesmoke solid">
              <label :for="'insurer-accordion-' + insurer.uuid"
                     class="fw-bolder py-2 px-5 w-100 cursor-pointer  bg-hover-light-primary">
                <i class="fad fa-caret-right me-1"></i>
                {{ insurer.label }}
              </label>
              <input type="checkbox" :id="'insurer-accordion-' + insurer.uuid"
                     :name="'insurer-accordion-' + insurer.uuid"
                     class="checkbox-accordion"/>
              <div class="accordion">
                <div v-for="pack in insurer.packs">
                  <label :for="'pack-accordion-' + pack.uuid"
                         class="fw-bolder py-1 w-100 px-5 ps-10 cursor-pointer bg-hover-light-primary">
                    <i class="fad fa-caret-right me-1"></i>
                    {{ pack.label }}
                  </label>
                  <input type="checkbox" :id="'pack-accordion-' + pack.uuid" :name="'pack-accordion-' + pack.uuid"
                         class="checkbox-accordion"/>
                  <div class="accordion ps-15">
                    <table class="table m-0 table-stripped">
                      <template v-if="pack.details">
                        <template v-for="detail in pack.details.details">
                          <tr class="fw-bolder py-1 cursor-pointer bg-hover-light-primary">
                            <td class="py-1 px-5">
                              {{ detail.name }}
                            </td>
                            <td class="text-end px-5" style="text-align: right;padding-left:100px">
                              {{ detail.value }}
                            </td>
                          </tr>
                        </template>
                      </template>
                    </table>

                  </div>

                </div>
              </div>
            </div>
            <div>
              <!-- <img :src="uploadRoot + packDetails.pack.insurer.photo" :alt="packDetails.pack.insurer.label" class="mh-50px"> -->
            </div>
            <div class="my-5 fw-bolder">
              <!-- {{ packDetails.pack.insurer.label }} ▪️ {{ packDetails.pack.label }} -->
            </div>
            <div>
              <table class="table table-striped">
                <tbody>
                <!-- <tr v-for="detail in packDetails.details">
                <td class="p-2">
                    {{ detail.name }}
                </td>
                <td class="text-end p-2">
                    {{ helper.currencyFormat(detail.value) }}
                </td>
            </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </transition>

    </div>
  </div>
</template>


<script lang="ts">
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import Question from "@/entity/Question";
import Branch from "@/entity/Branch";
import ResponseGroup from "@/entity/ResponseGroup";
import Survey from "@/entity/Survey";
import Step from "@/entity/Step";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import {helper} from "@/services/Helper";
import Guarantee from "@/entity/Guarantee";
import Pack from "@/entity/Pack";
import PackGuarantee from "@/entity/PackGuarantee";
import PackCategory from "@/entity/PackCategory";
import Insurer from "@/entity/Insurer";
import ItemType from "@/entity/ItemType";
import {mapper} from "@/Utils/Mapper";

@Component({
  components: {Loading}
})
export default class ComparisonOnline extends Vue {
  @Prop() question!: Question
  @Prop() branch!: Branch
  @Prop() rg!: ResponseGroup
  @Prop() survey!: Survey
  @Prop() currentStep!: Step
  calculUuid = helper.generateId()
  isMobile = false
  uploadRoot = api.uploadRoot
  showComparisonDetails = false
  packSelected: any = null
  resultDetail: any = null
  insurers = this.$store.state.insurers


  checkDesign() {
    const width = document.body.clientWidth
    this.isMobile = width < 1000
  }

  invalid = false
  results: any[] = []
  loading = false

  mounted() {
    this.loadCountCertificate()
    this.connectRealTimeAttestationCount()
    this.compare()
    this.checkDesign()
    window.addEventListener('resize', this.checkDesign)
    this.timeout = setTimeout(this.updateRowHeight, 200)
  }

  async loadCountCertificate() {
    const res = await api.get(api.core, 'insurer/certificate/list')
    this.certificateRemaining = res.data.remaining
  }

  beforeUnmount() {
    window.removeEventListener('resize', this.checkDesign)
  }

  timeout: any = null

  updated(): void {
    this.timeout = setTimeout(this.updateRowHeight, 200)
  }

  updateRowHeight() {
    clearTimeout(this.timeout)
    this.timeout = null
    // console.log('update row')
    const offers = document.querySelectorAll('.offers')
    offers.forEach((offer: any) => {
      const heads = offer.querySelectorAll('.offers th')

      const rows = offer.querySelectorAll('.offers .results tbody tr')
      const equiRows = offer.querySelectorAll('.offers .guarantee tbody tr')
      // const columns = offer.querySelectorAll('.offers td')
      let max = 0
      heads.forEach((g: HTMLElement) => {
        g.style.height = 'auto'
        if (g.getBoundingClientRect().height > max) {
          max = g.getBoundingClientRect().height
        }
      })
      heads.forEach((head: HTMLElement) => {
        head.style.height = max + 'px'
      })

      rows.forEach((row: HTMLElement, rowIndex: number) => {
        max = 0
        const columns = row.querySelectorAll('td')
        columns.forEach((column: HTMLElement) => {
          column.style.height = 'auto'
          if (column.getBoundingClientRect().height > max) {
            max = column.getBoundingClientRect().height
          }
        })
        const equiRow = equiRows[rowIndex]
        const equiCol = equiRow.querySelectorAll('td')

        equiCol.forEach((ec: HTMLElement) => {
          if (ec.getBoundingClientRect().height > max) {
            max = ec.getBoundingClientRect().height
          }
        })

        columns.forEach((column: HTMLElement) => {
          column.style.height = max + 'px'
        })


        equiCol.forEach((ec: HTMLElement) => {
          ec.style.height = max + 'px'
        })

      })

    })
  }

  get packDetails() {
    if (this.resultDetail) {
      const match = this.results.find((r: any) => {
        return r.pack.uuid === this.resultDetail.pack.uuid
      })
      return match
    }
    return null
  }

  async subscribe(result: any) {


    // return
    this.$store.commit('loading')
    // console.log(this.survey.item)
    // console.log(this.branch.items)
    const currentItem = this.branch.items.find((i: ItemType) => {
      return i.uuid === this.survey.item
    })
    const data = {
      pack: {
        id: result.pack.id,
        code: result.pack.code
      },
      insurer: {
        id: result.pack.insurer.id,
        slug: result.pack.insurer.slug
      },
      optionals: [] as any[]
    }
    const optionals: any[] = []
    result.pack.packGuarantees.forEach((pg: PackGuarantee) => {
      if (pg.isOptional && pg.selected) {
        data.optionals.push(pg.guarantee.id)
      }
    })
    // console.log(this.question.response)
    // console.log(data)
    this.question.response.value = JSON.stringify(data)
    await this.question.updateAnswer()

    // if (this.isLastStep()) {
    // return
    const res = await api.get(api.form, 'api/make/order/' + this.rg.uuid)
    if (res && res.data && res.data.order) {
      this.$store.commit('appendOrder', res.data.order)
      if (currentItem && currentItem.isRoot) {
        this.$router.push({name: 'order-view', params: {uuid: res.data.order.uuid}});
      }
    }
    // }
    this.setPackSelected()
    this.$store.commit('stopLoading')

  }

  setPackSelected() {
    if (this.question.response.value) {
      const data = JSON.parse(this.question.response.value)
      let result = this.results.find((r: any) => {
        return r.pack.insurer.slug === data.insurer.slug
      })
      if (result) {
        for (let optional of data.optionals) {
          const exist = result.pack.packGuarantees.find((pg: PackGuarantee) => {
            return pg.guarantee.id === optional
          })
          if (exist && !exist.selected) {
            exist.selected = true;
            this.toggleOptional(result, exist)
            // console.log('toggle ', exist.guarantee.label)

          }
        }
        result = this.results.find((r: any) => {
          return r.pack.insurer.slug === data.insurer.slug
        })
        if (result) {
          this.packSelected = result
          return result;
        }
      }
    }
    this.packSelected = null
    return null;
  }

  getReductionAmount(result: any) {
    let premium = 0
    result.premiumDetails.forEach((pd: any) => {
      if (pd.model.isReduction) {
        premium += pd.premium
      }
    })
    return premium
  }

  comparisonResume: any[] = []

  certificateRemaining: any[] = []
  eventSource: any = null
  workflow: any = null;
  attestations = {
    green: 0,
    yellow: 0,
    blue: 0
  }

  async connectRealTimeAttestationCount() {
    // if (this.eventSource instanceof EventSource) {
    //   this.eventSource.close()
    // }
    const baseUrl = api.mercure;
    const hubUrl = new URL(baseUrl);
    hubUrl.searchParams.append('topic', '/comparisons');
    // console.log(hubUrl.toString())
    this.eventSource = new EventSource(hubUrl.toString());
    this.eventSource.onmessage = (event: any) => {
      // console.log(event)
      var data = JSON.parse(event.data);
      if (data.hasOwnProperty('updates')) {
        let updates = data.updates
        // console.log(updates);
        if (updates.hasOwnProperty('used') && updates.hasOwnProperty('insurer')) {
          for (let data of this.certificateRemaining) {
            if (data.uuid === updates.insurer) {
              // console.log(data)
              if (updates.used.yellow > 0) {
                data.eAttestationCountYellow--
              }
            }
          }
        }
      }


      // if (data.hasOwnProperty('voted') && data.hasOwnProperty('percents')) {
      //   addLike(data.voted, data.percents);
      // }
    }
    // this.eventSource.onerror = (event: any) => {
    //   console.log(event)
    // }
  }

  getRemainingCertificates(insurer: Insurer) {
    let remaining = {
      green: 0,
      blue: 0,
      yellow: 0
    }
    // console.log('get remaining ' + insurer.label)

    for (let data of this.certificateRemaining) {
      if (insurer.uuid === data.uuid) {
        remaining.blue = data.eAttestationCountBlue
        remaining.green = data.eAttestationCountGreen
        remaining.yellow = data.eAttestationCountYellow
        // console.log(remaining.yellow)
        return remaining
      }
    }
    return remaining
  }

  canSubscribe(result: any) {

    for (let data of this.certificateRemaining) {
      if (result.pack.insurer.uuid === data.uuid) {
        if (this.attestations.blue > 0) {
          if (this.attestations.blue > data.eAttestationCountBlue) {
            return false
          }
        }
        if (this.attestations.yellow > 0) {
          if (this.attestations.yellow > data.eAttestationCountYellow) {
            return false
          }
        }

        if (this.attestations.green > 0) {
          if (this.attestations.green > data.eAttestationCountGreen) {
            return false
          }
        }

        return true
      }
    }
    return false
  }

  @Watch('$store.state.onlineRgStatus')
  async compare() {
    this.loading = true
    const mapped = mapper.map(this.rg)
    this.workflow = mapper.workflow
    this.attestations = mapper.attestations
    const res = await api.get(api.form, 'api/get/offers/' + this.rg.uuid)
    this.loading = false
    if (res && res.data) {
      this.results = []
      res.data.offers.forEach((offer: any) => {
        let offerMatch = false
        for (let insurer of this.$store.state.insurers) {
          for (let p of insurer.packs) {
            if (offer.pack.id === p.id) {
              offer.pack = new Pack(p)
              offer.pack.insurer = new Insurer(insurer)
              this.results.push(offer)
            }
          }
        }
        offer.expand = true
        offer.premium = offer.basePremium
      })
      if (res.data.debugger) {
        this.comparisonResume = res.data.debugger
      }
    }
  }

  onePackHasGuarantee(guarantee: Guarantee) {
    let match = false
    console.log('looking for guarantee ' + guarantee.label)
    this.results.forEach((r: any) => {
      const insurer: Insurer = this.$store.state.insurers.find((i: Insurer) => {
        return i.id === r.insurer.id
      })
      console.log(insurer)
      console.log(insurer.packs)
      if (insurer && insurer.packs) {
        const pack: Pack = insurer.packs.find((p: Pack) => {
          return p.id === r.pack.id
        })!
        if (pack.hasGuarantee(guarantee)) {
          match = true
        }
      } else {
        console.log('no insurer or packs in insurer')
      }
    })
    return match
  }

  allPackHasGuarantee(guarantee: Guarantee, pack: Pack) {
    let match = true
    this.results.forEach((r: any) => {
      const insurer: Insurer = this.$store.state.insurers.find((i: Insurer) => {
        return i.id === r.insurer.id
      })
      if (insurer && insurer.packs) {
        const pack: Pack = insurer.packs.find((p: Pack) => {
          return p.id === r.pack.id
        })!
        if (!pack.hasMandatoryGuarantee(guarantee)) {
          match = false
        }
      }
    })
    return match
  }

  getOptionalPremium(result: any, guarantee: Guarantee) {
    const match = result.optionals.find((o: any) => {
      if (guarantee.method) {
        return o.code === guarantee.method.code
      }
    })
    return match
  }

  oldResults: any[] = []

  async toggleOptional(result: any, packGuarantee: PackGuarantee) {
    // packGuarantee.selected = !packGuarantee.selected
    result.premium = result.basePremium
    result.pack.packGuarantees.forEach((pg: PackGuarantee) => {
      if (pg.guarantee.id === packGuarantee.guarantee.id) {
        pg.selected = packGuarantee.selected
      }
      if (pg.selected) {
        const optional = result.optionals.find((optional: any) => {
          return optional.guarantee === pg.guarantee.id
        })
        result.premium += optional.premium
      }
    })

    // result.
    this.$forceUpdate()
  }
}
</script>


<style scoped>
.accordion {
  max-height: 0;
  overflow: hidden;
}

.checkbox-accordion {
  display: none;
}

.checkbox-accordion:checked + .accordion {
  max-height: none;
}

.offers tbody tr:nth-child(2n+1) {
  background: rgba(61, 218, 215, 0.21);
}

.results td,
.results th {
  min-width: 200px;
}

.results-container {
  position: relative;
  /*border: 1px red solid;*/
}

.results-container:before,
.results-container:after {
  content: '';
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  /*background: rgb(2,0,36);*/
  background: linear-gradient(-90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 75%);

}

.results-container:after {
  left: auto;
  right: 0;
  background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 75%);
}

.results td {
  border-right: 10px white solid;
  /*background: rgba(255, 255, 255, 0.21);*/
}

.td {
  transition: all .3s ease;
}
</style>
