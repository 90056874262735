<template>
        <iframe id="iframe" class="flex-1 w-100" style="min-height:2000px;min-width:900px">
        </iframe>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import User from "@/entity/User";
import FileUpload from "@/components/FileUpload.vue";
import {api} from "@/services/Api";
import {auth} from "@/services/Auth";
import Swal from 'sweetalert2';
import JsonViewer from 'vue-json-viewer'

@Component({
  components: {FileUpload, Base, JsonViewer}
})
export default class FullPrintableView extends Vue {

  coreEndpoint = api.core

  beforeUnmount() {
    // window.removeEventListener('DOMContentLoaded', this.resize)
    window.removeEventListener("message", this.xhrListener, false);
  }

  mounted() {
    this.getData()
    window.addEventListener("message", this.xhrListener, false);
    // window.addEventListener('DOMContentLoaded', this.resize);
  }

  async getData() {
    this.$store.commit('loading')
    var xhr = new XMLHttpRequest();
    const url = this.coreEndpoint + 'item/printable/preview/' + this.$route.params.itemUuid + '/' + this.$route.params.printableUuid + '?zone=' + this.$store.state.currentTenant.code

    const res = await api.refreshToken()
    // var data_url = URL.createObjectURL(res.data);
    // document.querySelector('iframe').src = data_url;
    // console.log(res)
    xhr.open('GET', url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          // console.log(xhr.response)
          // this.response is a Blob, because we set responseType above
          // var data_url = URL.createObjectURL(xhr.response);
          var html = document.querySelector('html') as any
          html.innerHTML = xhr.response;
          // const iframe = document.querySelector('iframe')
          // iframe!.src = data_url;
          // iframe!.onload = () => {
          //   this.$store.commit('stopLoading')
          // }
        } else {
          console.error('no pdf :(');
        }
      }
    };
    // xhr.responseType = 'blob';
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.$store.state.userToken);
    xhr.send();


  }

  create() {

  }

  edit() {

  }

  save() {

  }

  print() {
    window.print();
  }

  json: any = null

  async xhrListener(event: any) {
    if (event.data.method === 'GET') {
      console.log(event)
      this.$store.commit('loading')
      if (event.data.action === 'download') {
        const res = await api.get('', event.data.url, {responseType: 'blob'})
        const blob = new Blob([res.data], {type: res.headers['content-type']});
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement('a');
        a.setAttribute("download", res.headers['name']);
        a.setAttribute("href", link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else if (event.data.action === 'show-json') {
        // console.log('SHOW JSON')
        // console.log(event.data.data)
        this.json = JSON.parse(event.data.data);
        (this.$refs as any).jsonViewer.show()
        this.$store.commit('stopLoading')
      } else if (event.data.action === 'new-demande-attestation') {
        this.$store.commit('loading')
        const res = await api.get(api.core,'demande/attestation/new/'+this.$route.params.itemUuid)
        if(res && res.data && res.data.demande){
          window.location.reload()
        }
        this.$store.commit('stopLoading')
      }else if(event.data.url){
        const res = await api.get('', event.data.url)
      }
      // console.log(res)
      this.$store.commit('stopLoading')
    }
  }


}
</script>
<style scoped></style>
