<template>
    <div :style="{ 'background' : background}" id="filter">
               <!-- <div :style="{'width': this.width, 'height' : this.width}" class="water"
                    id="container">
                   <Logo></Logo>
               </div> -->

               <Loading></Loading>

        <!-- <div class='loader loader4'>
            <div>
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

</template>
<script lang="ts">
    /* eslint-disable */
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Logo from "@/components/Logo.vue";
    import Loading from '@/components/Loading.vue';

    @Component({
        components: {Logo,Loading}
    })
    export default class LoadingLogo extends Vue {
        @Prop() width!: string;
        @Prop() background!: string;

    }
</script>
<style scoped>
    #filter {
        position: relative;
        /*top: 0;*/
        /*left: 0;*/
        width: 100%;
        height: 100%;
        /* background: rgba(255, 255, 255, .75); */
        /*z-index: 9999;*/
        display: flex;
        justify-content: center;
        align-items: center;

        background-size: 4px 4px;
    background-image: radial-gradient(transparent 1px, rgba(255, 255, 255, .5) 1px);
    backdrop-filter: saturate(50%) blur(4px);
    display: flex;
        /*backdrop-filter: blur(2px);*/
    }

    #wa-3d-logo {
        z-index: 2;
    }

    #container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        /* background-color: #17e1e6; */
        width: 500px;
        height: 500px;
        /*margin: 20px;*/
        border-radius: 100%;
        overflow: hidden;
    }


    .typing {
        position: absolute;
        z-index: 2;
        top: auto;
        right: 0;
        left: 0;
        width: 100%;
        bottom: 30px;
        display: flex;
        justify-content: center;

    }

    /* .typing .point {
        width: 20Px;
        height: 20px;
        background: #f8069c;
        background: radial-gradient(circle at 5px 5px, #f8069c, #396066);
        margin: 0 20px;
        border-radius: 100%;
        animation: bounce 1s ease infinite;
    }

    .typing .two {
        animation: bounce 1s ease .1s infinite;
    }

    .typing .three {
        animation: bounce 1s ease .2s infinite;
    }

    @keyframes bounce {
        0% {
            transform: scale(0) translateY(0)
        }
        20% {
            transform: scale(1) translateY(-10px)
        }
        40% {
            transform: scale(1) translateY(0)
        }
        80% {
            transform: scale(1) translateY(10px)
        }
        100% {
            transform: scale(0) translateY(0)
        }
    }


    .water:before,
    .water:after {
        content: "";
        position: absolute;
        width: 400px;
        height: 400px;
        top: -200px;
        background-color: #fff;
    }

    .water:before {
        border-radius: 45%;
        background: rgba(255, 255, 255, 0.7);
        animation: wave 5s linear infinite;
    }

    .water:after {
        border-radius: 35%;
        background: rgba(255, 255, 255, 0.3);
        animation: wave 5s linear infinite;
    }

    @keyframes wave {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    } */
/* 

    .loader {
        position: relative;
        margin: 75px auto;
        width: 150px;
        height: 150px;
        display: block;
        overflow: hidden;
    }
    .loader div {
        height: 100%;
    }
    .loader4, .loader4 div {
        border-radius: 50%;
        padding: 8px;
        border: 2px solid transparent;
        -webkit-animation: rotate linear 3.5s infinite;
        animation: rotate linear 3.5s infinite;
        border-radius: 50%;
        padding: 4px;
        -webkit-animation: rotate2 4s infinite linear;
        animation: rotate2 4s infinite linear;
    }
    div:hover {
        animation-play-state: paused;
    }

    .loader, .loader * {
        will-change: transform;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes rotate2 {
        0% {
            transform: rotate(0deg);
            border-top-color: #F8069C;
        }
        50% {
            transform: rotate(180deg);
            border-top-color: #3DDAD7;
        }
        100% {
            transform: rotate(360deg);
            border-top-color: #F8069C;
        }
    } */
    * {
        box-sizing: border-box;
    }


</style>
