<template>
  <div :key="'question_'+question.uuid" class="flex-grow-1 m-2 question" v-if="question.isAuthorized()">
    <label class="fw-bolder my-2 d-flex justify-content-between" for="">
      <div>
            <span :title="question.visibility" class="cursor-pointer me-3" v-b-tooltip.hover
                  v-if="question.visibility !== 'PUBLIC'">
                <i class="fad fa-lock text-danger"></i>
            </span>
        {{ question.label }}
      </div>
      <!--            <br>-->
      <!--            default : {{question.defaultValue}}-->
      <!--            <br>-->
      <!--            Response :-->
      <!--            <template v-if="question.response">-->
      <!--                {{question.response.value}}-->
      <!--            </template>-->
      <!--            <span @click="$store.commit('resetResponse',question)" class="btn btn-xs btn-icon" v-if="hasRole('ROLE_MANAGER')"><i class="fad fa-trash text-danger"></i></span>-->
    </label>


    <!--        <div class="d-flex flex-wrap" v-if="question.choices.length < 5">-->
    <!--            <div :key="choice.uuid" class=" px-5 m-5 d-flex flex-column justify-content-center align-items-center choice flex-grow-1"-->
    <!--                 v-for="choice,key in question.choices">-->
    <!--                <div v-if="choice.icon">-->
    <!--                    <img :src="uploadRoot+choice.icon" alt="" style="height: 64px;width:64px;object-fit: contain">-->
    <!--                </div>-->
    <!--                <div class="fw-bolder my-2 whitespace-no-wrap">-->
    <!--                    {{choice.label}}-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->

    <b-input-group
        :class="[ question.isSending ? 'border-secondary' : question.isValid ? 'border-success' : 'border-danger' ]"
        class="rounded border-1 d-flex flex-grow-1" style="border-style:solid">
      <b-input-group-prepend class="d-flex flex-column" v-if="question.prefix">
        <div class="input-group-text flex-grow-1">
          <template v-if=" isIcon(question.prefix)">
            <i :class="question.prefix"></i>
          </template>
          <template v-else>
            {{ question.prefix }}
          </template>
        </div>
      </b-input-group-prepend>
      <div class="input-container flex-grow-1 rounded  d-flex">
        <select :state="!helper.empty(question.response.value)"
                @change="question.updateAnswer()"
                class="form-control form-select"
                v-model.trim="question.response.value">
          <b-form-select-option :selected="helper.empty(question.response.value)" value="">
            {{ trans('Selectionnez une option') }}
          </b-form-select-option>
          <b-form-select-option :key="choice.uuid" :value="choice.code" v-for="choice,key in question.choices">
            {{ trans(choice.label) }}
          </b-form-select-option>
        </select>
        <span :title="trans('Mise à jour en cours')" class="input-status" v-b-tooltip.hover v-if="question.isSending">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
        <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="!question.isValid">
                    <i class="fad fa-exclamation-circle text-danger"></i>
                </span>
        <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="question.isValid">
                    <i class="fad fa-check-circle text-success"></i>
                </span>
      </div>
      <b-input-group-append v-if="question.suffix">
        {{ question.suffix }}
      </b-input-group-append>
    </b-input-group>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Question from "@/entity/Question";
import {helper} from "@/services/Helper";
import {api} from "@/services/Api";

@Component({})
export default class Radio extends Vue {
  @Prop() question!: Question

  uploadRoot = api.uploadRoot

  mounted() {
    // console.log(this.question.label)
    // console.log(this.question)
  }

  isIcon(text: string) {
    return text.match(/fa |fab |fad |fal |fas /) !== null
  }


}
</script>
<style scoped>
.input-container {
  position: relative;
}

.input-status {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
}

.choice {
  min-width: 13rem;
  background: rgba(171, 171, 171, .15);
  border: 2px solid #ddd;
  cursor: pointer;
}

.choice:hover {
  border: 2px solid #3ddad7;
  color: #3ddad7;
  background-color: rgba(61, 218, 215, .15);
}

.choice:hover img {
  filter: invert(69%) sepia(80%) saturate(406%) hue-rotate(124deg) brightness(100%) contrast(78%);
}

@media only screen and (max-width: 992px) {

}

@media only screen and (max-width: 500px) {
  .choice {
    width: 100%;
  }

}
</style>
