<template>
    <Base>
    <template v-slot:toolbar>
        <div class="toolbar" id="kt_toolbar">
            <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
                <div class="d-flex align-items-center me-3">
                    <slot name="leftToolbar">
                        <span class="h-20px border-gray-200 border-start mx-4"></span>
                        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                            <li class="breadcrumb-item text-muted">
                                <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                                    <i class="fad fa-chart-pie"></i>
                                    {{ trans('Statistiques') }}
                                </router-link>
                            </li>
                        </ul>
                    </slot>
                </div>


                <div class="d-flex align-items-center py-1">

                    <div class="">
                        <span :title="'Réinitialiser la recherche'" @click="resetSearch"
                            class="btn btn-icon btn-light-danger btn-xs me-3 justify-content-center" v-b-tooltip.hover>
                            <i class="fad fa-trash fw-bolder cursor-pointer"></i>
                        </span>
                        <!--        <span class="me-3" v-if="$store.state.filter.promotion && promo">-->
                        <!--            {{$store.state.filter.promotion.title}} ◾-->
                        <!--        </span>-->
                        <b-dropdown no-caret no-flip ref="filter" right size="sm" style="padding:0" tag="div"
                            toggle-class="topbar-item text-decoration-none" variant="link">
                            <template v-slot:button-content>
                                <div class="cursor-pointer btn btn-secondary btn-sm me-3">
                                    <!--                    <span class="badge badge-danger me-2" v-if="count > 0">{{count}}</span>-->
                                    <i class="fad fa-search me-2"></i>
                                    <!--                    <template v-if="dateInterval">-->
                                    <!--                        {{readableDate($store.state.filter.dateMin,false,true,false)}} - -->
                                    <!--                        {{readableDate($store.state.filter.dateMax,false,true,false)}}-->
                                    <!--                    </template>-->
                                    <template>Filtrer</template>
                                </div>
                            </template>
                            <b-dropdown-text class="min-w-500px" tag="div">
                                <div class="row">
                                    <div class="col-auto flex-grow-1">
                                        <label class="my-2" for="">{{ trans('Branche') }}</label>
                                        <b-form-select class="form-control  form-select" v-model="search.branch">
                                            <b-form-select-option :value="null">{{ trans('Choisir une branche') }}
                                            </b-form-select-option>
                                            <!--                                                <template v-for="category in categories">-->
                                            <!--                                                    <b-form-select-option :key="branch.uuid" :value="branch.slug"-->
                                            <!--                                                                          v-for="branch,key in category.branches">-->
                                            <!--                                                        {{category.label}} - {{trans(branch.label)}}-->
                                            <!--                                                    </b-form-select-option>-->
                                            <!--                                                </template>-->
                                        </b-form-select>
                                    </div>
                                    <div class="col-auto flex-grow-1">
                                        <label class="my-2" for="">{{ trans('Contenant') }}</label>
                                        <input class="form-control" type="text" v-model="search.contains">
                                    </div>
                                    <div class="col-auto flex-grow-1">
                                        <label class="my-2" for="">{{ trans('Dernière modification après') }}</label>
                                        <input class="form-control" type="date" v-model="search.after">
                                    </div>
                                    <div class="col-auto flex-grow-1">
                                        <label class="my-2" for="">{{ trans('Dernière modification avant') }}</label>
                                        <input class="form-control" type="date" v-model="search.before">
                                    </div>
                                    <div class="col-auto flex-grow-1">
                                        <label class="my-2" for="">{{ trans('Niveau de complétion') }} (%)</label>
                                        <div class="d-flex">

                                            <input-number :max="100" :min="0" :value="0" v-model="search.minimalState">
                                            </input-number>
                                        </div>
                                    </div>
                                    <div class="col-auto flex-grow-1 d-flex flex-column">
                                        <label class="my-2" for="">{{ trans('Contient les infos client') }}</label>
                                        <div class="d-flex align-items-center flex-grow-1">
                                            <div class="form-check form-switch form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="search.hasClientInfos" value="" />
                                                <!--                <label class="form-check-label" for="flexSwitchDefault">-->
                                                <!--                </label>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end mt-5">
                                    <button @click="resetSearch"
                                        class="btn btn-sm btn-white btn-active-light-primary me-2" type="reset">
                                        Réinitialiser
                                    </button>
                                    <button @click="searchRgs" class="btn btn-sm btn-primary" type="submit">
                                        Rechercher
                                    </button>
                                </div>
                            </b-dropdown-text>
                        </b-dropdown>
                    </div>

                </div>

            </div>
        </div>
    </template>
    <template v-slot:content>

        <b-row>
            <b-col> <b-card bg-variant="primary text-white" class="shadow">

                    <h1 class="fw-bolder">
                        {{ trans("Contrats") }}

                    </h1>

                    <p style="font-size: 5rem; margin: 0;" class="text-end fw-bold">
                        60
                    </p>

                </b-card></b-col>
            <b-col> <b-card bg-variant="warning text-white" class="shadow">

                    <h1 class="fw-bolder">
                        {{ trans("Prise de contact") }}

                    </h1>

                    <p style="font-size: 5rem; margin: 0;" class="text-end fw-bold">
                        60
                    </p>

                </b-card></b-col>
            <b-col> <b-card bg-variant="success text-white" class="shadow">

                    <h1 class="fw-bolder">
                        {{ trans("Ventes") }}

                    </h1>

                    <p style="font-size: 5rem; margin: 0;" class="text-end fw-bold">
                        60
                    </p>

                </b-card></b-col>
            <b-col> <b-card bg-variant="info text-white" class="shadow">

                    <h1 class="fw-bolder">
                        {{ trans("Cotations") }}

                    </h1>

                    <p style="font-size: 5rem; margin: 0;" class="text-end fw-bold">
                        60
                    </p>

                </b-card></b-col>


        </b-row>

        <b-card class="mt-5">

            <h1 class="text-uppercase">{{ trans("Prospection") }}</h1>


            <div class="mt-5">
                <h3>{{ trans("Prise de contact") }}</h3>

                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                        <tr class="fw-bolder text-muted">

                            <th class="min-w-150px">{{ trans("Gestionnaire") }}</th>
                            <th class="min-w-140px">{{ trans(" B2C par appel") }}</th>
                            <th class="min-w-140px">{{ trans(" B2C par rendez-vous") }}</th>
                            <th class="min-w-140px">{{ trans(" B2B par appel") }}</th>
                            <th class="min-w-140px">{{ trans(" B2B par rendez-vous") }}</th>
                            <!--              <th v-if="!compact">-->
                            <!--                Prochaine activité-->
                            <!--              </th>-->
                            <th class="min-w-100px text-end">{{ trans("Total") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr v-if="rgs.length === 0">
                <td colspan="9">
                  <div class="d-flex flex-column justify-content-center pb-20 pt-20 flex-grow-1 align-items-center
                                                                border-bottom-dashed border-gray-300">
                    <i class="fad fa-empty-set fs-4x"></i>
                    <span class="mt-5 fs-2x text-gray-400">
                      {{ trans('Aucun resultat') }}
                    </span>
                  </div>
                </td>
              </tr>

              -->
                        <tr v-for="(handshake, index) in handShakes" :key="'cotation_' + handshake.id">

                        </tr>
                    </tbody>
                </table>



            </div>



            <div class="mt-5">
                <h3>{{ trans("Rdv realisées") }}</h3>

                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                        <tr class="fw-bolder text-muted">

                            <th class="min-w-150px">{{ trans("Prospect") }}</th>
                            <th class="min-w-140px">{{ trans("Gestionnaire") }}</th>
                            <th class="min-w-140px">{{ trans("Liste de tout les branches") }}</th>

                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr v-if="rgs.length === 0">
                <td colspan="9">
                  <div class="d-flex flex-column justify-content-center pb-20 pt-20 flex-grow-1 align-items-center
                                                                border-bottom-dashed border-gray-300">
                    <i class="fad fa-empty-set fs-4x"></i>
                    <span class="mt-5 fs-2x text-gray-400">
                      {{ trans('Aucun resultat') }}
                    </span>
                  </div>
                </td>
              </tr>

              -->
                        <tr v-for="(handshake, index) in handShakes" :key="'cotation_' + handshake.id">

                        </tr>
                    </tbody>
                </table>



            </div>

            <div class="mt-5">
                <h3>{{ trans("Rdv prévus") }}</h3>

                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                        <tr class="fw-bolder text-muted">

                            <th class="min-w-150px">{{ trans("Prospect") }}</th>
                            <th class="min-w-140px">{{ trans("Gestionnaire") }}</th>
                            <th class="min-w-140px">{{ trans("List de tout les branches") }}</th>

                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr v-if="rgs.length === 0">
                <td colspan="9">
                  <div class="d-flex flex-column justify-content-center pb-20 pt-20 flex-grow-1 align-items-center
                                                                border-bottom-dashed border-gray-300">
                    <i class="fad fa-empty-set fs-4x"></i>
                    <span class="mt-5 fs-2x text-gray-400">
                      {{ trans('Aucun resultat') }}
                    </span>
                  </div>
                </td>
              </tr>

              -->
                        <tr v-for="(handshake, index) in handShakes" :key="'cotation_' + handshake.id">

                        </tr>
                    </tbody>
                </table>



            </div>




        </b-card>

        <b-card class="mt-5">

            <h1 class="text-uppercase">{{ trans("Ventes") }}</h1>


            <div class="mt-5">
                <h3>{{ trans("Comptabilisation") }}</h3>

                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                        <tr class="fw-bolder text-muted">

                            <th class="min-w-150px">{{ trans("Gestionnaire") }}</th>
                            <th class="min-w-140px">{{ trans(" Clients B2C") }}</th>
                            <th class="min-w-140px">{{ trans(" Primes par branches") }}</th>
                            <th class="min-w-140px">{{ trans(" Primes par branches par partenaire") }}</th>
                            <th class="min-w-140px">{{ trans(" B2B par rendez-vous") }}</th>

                            <th class="min-w-100px text-end">{{ trans("Total Emissions") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr v-if="rgs.length === 0">
    <td colspan="9">
      <div class="d-flex flex-column justify-content-center pb-20 pt-20 flex-grow-1 align-items-center
                                                    border-bottom-dashed border-gray-300">
        <i class="fad fa-empty-set fs-4x"></i>
        <span class="mt-5 fs-2x text-gray-400">
          {{ trans('Aucun resultat') }}
        </span>
      </div>
    </td>
  </tr>

  -->
                        <tr v-for="(handshake, index) in handShakes" :key="'cotation_' + handshake.id">

                        </tr>
                    </tbody>
                </table>



            </div>



            <div class="mt-5">
                <h3>{{ trans("Renouvellement") }}</h3>

                <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                        <tr class="fw-bolder text-muted">

                            <th class="min-w-150px">{{ trans("Type") }}</th>
                            <th class="min-w-140px">{{ trans("Nbre affaires nouvelles") }}</th>
                            <th class="min-w-140px">{{ trans("Primes affaires nouvelles") }}</th>
                            <th class="min-w-140px">{{ trans("Nbre renouvellements") }}</th>
                            <th class="min-w-140px">{{ trans("Primes renouvellements") }}</th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="(handshake, index) in handShakes" :key="'cotation_' + handshake.id">

                        </tr>
                    </tbody>
                </table>



            </div>





        </b-card>





    </template>
    </Base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import InputNumber from "@/components/InputNumber.vue";
import Popup from "@/entity/Popup";

@Component({
    components: { InputNumber, Base }
})
export default class Statistic extends Vue {
    cotationCount = 0;
    cotationCompleted = 0;
    cotationProcessed = 0;
    contactCount = 0;
    perPage = 5000;
    maxPage = 1;
    search = {
        branch: null,
        after: null,
        before: null,
        contains: null,
        minimalState: 0,
        hasClientInfos: false,
        page: 1,
    }
    page = 1;
    loading = false
    gettingCount = false
    gettingCustomer = false;
    gettingComplete = false;

    resetSearch() {
        this.search = {
            branch: null,
            after: null,
            before: null,
            contains: null,
            minimalState: 0,
            hasClientInfos: false,
            page: 1,
        }
    }

    async searchRgs() {
        const res = await api.post(api.form, 'response/group/search', { search: this.search })
        console.log(res)
    }

    mounted(): void {

    }



}
</script>
<style scoped></style>
