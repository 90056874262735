<template>
    <div class="d-flex flex-column align-items-center justify-content-start flex-1" v-if="user">
        <div class="border-dashed w-150px h-150px rounded d-flex justify-content-center align-items-center mt-20">
            <img :src="uploadRoot + user.photo" alt="" class="w-100 rounded h-100" v-if="!helper.empty(user.photo)">
            <i class="fad fa-user fs-5x" v-else></i>
        </div>
        <div class="mt-5 text-gray-400 fs-2 rounded">
            {{ user.email }}
        </div>
        <div class="fs-5 mt-2  fw-bolder">
            {{ user.name }} {{ user.prename }}
        </div>
        <div class="my-4">
            Entrez votre nouveau mot de passe
        </div>
        <div class="d-flex align-items-center border rounded  ">
            <i class="fad fa-lock px-5"></i>
            <input :type="show ? 'text' : 'password'" class="border-none rounded py-3 px-15" style="outline:none"
                v-model="user.password">
            <i class="fad fa-eye px-5 cursor-pointer" @click="show = !show" v-if="!show"></i>
            <i class="fad fa-eye-slash px-5 cursor-pointer" @click="show = !show" v-else></i>
        </div>
        <div class="my-4">
            Répétez votre nouveau mot de passe
        </div>
        <div class="d-flex align-items-center border rounded">
            <i class="fad fa-lock px-5"></i>
            <input :type="show ? 'text' : 'password'" class="border-none rounded py-3 px-15" style="outline:none"
                v-model="user.repeatedPassword">
            <i class="fad fa-eye px-5 cursor-pointer" @click="show = !show" v-if="!show"></i>
            <i class="fad fa-eye-slash px-5 cursor-pointer" @click="show = !show" v-else></i>
        </div>
        <div class="my-10">
            <div class="btn btn-primary" @click="update">
                Modifier mon mot de passe
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import { api } from '@/services/Api';
import User from '@/entity/User';
import { auth } from '@/services/Auth';
import Popup from '@/entity/Popup';

@Component({
    components: { Base }
})
export default class PasswordUpdate extends Vue {

    uploadRoot = api.uploadRoot
    user: User | null = null
    show = false
    beforeMount() {

    }

    async mounted() {
        this.$store.commit('loading')
        const url = api.auth + 'api/check/password/update/code/' + this.$route.params.email + '/' + this.$route.params.code
        const res = await api.get(api.auth, 'api/check/password/update/code/' + this.$route.params.email + '/' + this.$route.params.code)
        if (res.data.user) {
            this.user = new User(res.data.user)
            this.user.passwordChangeCode = this.$route.params.code
        }
        this.$store.commit('stopLoading')
    }

    async update() {
        // console.log(this.user)
        const res = await api.post(api.auth, 'api/update/password', { user: this.user, code: this.$route.params.code })
        // console.log(res)
        const pop = new Popup('Mise à jour', 'Votre mot de passe a été mis à jour', 'success', 'fad fa-thumbs-uo')
        if (res && res.data && res.data.status) {
            auth.logout()
        }
    }
}
</script>

<style></style>