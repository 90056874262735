<template>
    <div class="form-check form-check-custom  form-check-sm">
        <input class="form-check-input" type="checkbox" :checked="value.includes(data)" @change="toggle">
    </div>
</template>
<script lang="ts">
import { helper } from '@/services/Helper';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class RowSelector extends Vue {

    @Prop({ default: () => [] }) readonly value!: string[]
    @Prop({ default: '' }) data!: string



    mounted() {

    }
    handleInput() {

    }

    toggle(e: any) {
        // console.log(this.selected)
        const selected = e.target.checked
        const selection = this.value
        if (selected) {
            if (!selection.includes(this.data)) {
                selection.push(this.data)
            }
        }
        if (!selected) {
            const index = selection.indexOf(this.data)
            if (index !== -1) {
                selection.splice(index, 1)
            }
        }
        this.$emit('input', selection)
    }
}
</script>
<style scoped></style>
