import { render, staticRenderFns } from "./PartnerTree.vue?vue&type=template&id=44039f92&scoped=true"
import script from "./PartnerTree.vue?vue&type=script&lang=ts"
export * from "./PartnerTree.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44039f92",
  null
  
)

export default component.exports