<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">

            <div @click="editCategory" class="btn-sm btn btn-warning me-5"
                 v-b-modal.categoryModal v-if="currentCategory">
              <i class="fad fa-edit"></i>
              {{ trans('Modifier la catégorie') }}
            </div>

            <div @click="resetCategory" class="btn-sm btn btn-danger"
                 v-b-modal.categoryModal>
              <i class="fad fa-plus"></i>
              {{ trans('Create_Category') }}
            </div>

            <div @click="resetBranch" class="btn-sm btn btn-primary ms-5"
                 v-b-modal.branchModal>
              <i class="fad fa-plus"></i>
              {{ trans('Create_Branch') }}
            </div>

<!--            <div @click="generate" class="btn-sm btn btn-primary ms-5">-->
<!--              <i class="fad fa-cogs"></i>-->
<!--              {{ trans('Générer') }}-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content>


      <!--            <div class="title mx-10">-->
      <!--                <i class="fad fa-code-branch fs-2 me-3"></i>-->
      <!--                <span>{{trans('Branches')}}</span>-->
      <!--            </div>-->

      <div class="d-flex w-100">

        <!--                <div class=" d-flex d-flex justify-content-center  px-10">-->
        <label :key="cat.uuid" class="" v-for="cat in categories">
          <input :checked="currentCategory.uuid === cat.uuid" :value="cat.uuid" @change="setCurrent(cat)"
                 class="branch-category hidden"
                 name="branch-category"
                 type="radio"
          >
          <div class="flex-grow-1 px-10 text-center min-w-100px nbutton fs-2 p-5 m-2">
            {{ cat.label }}
            <span class="badge badge-danger ms-3">{{ cat.branches.length }}</span>
          </div>
        </label>

      </div>

      <template v-if="currentCategory">
        <draggable :animation="200" :list="branches" @end="end" class="d-flex flex-wrap w-100"
                   filter=".action-button" ghost-class="bg-light-primary" group="branches" tag="ul">
          <template class="cursor-pointer bg-hover-primary text-hover-white shadow-sm card m-5" v-for="(branch,index) in branches">
            <TransitionGroup appear name="slide-left" tag="div">
              <div  :style="{'--i' : (index+1)}" :key="'branch_'+branch.uuid" class="cursor-pointer shadow-sm card m-5">
                <div class="card-body d-flex flex-column justify-content-center align-items-center min-w-200px p-0">
                  <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100 px-5">
                    <div class="form-check form-switch form-check-custom form-check-solid ">
                      <input @change="toggleActivation($event,branch.uuid)" class="form-check-input h-20px w-30px"
                             type="checkbox"
                             v-model="branch.active" value=""/>
                    </div>
                    <div>
                      <div @click="setBranch(branch)"
                           class="btn btn-icon btn-xs btn-light-primary me-2"
                           v-b-modal.branchModal>
                        <i class="fad fa-edit"></i>
                      </div>

                      <b-dropdown no-caret no-flip ref="filter" right
                                  size="sm"
                                  style="padding:0;position: relative;"
                                  tag="div" toggle-class="topbar-item text-decoration-none"
                                  variant="link">
                        <template v-slot:button-content>
                          <div class="btn btn-icon btn-bg-light btn-active-color-primary btn-xs"
                               style="position: relative;">
                            <i class="fad fa-ellipsis-v"></i>
                          </div>
                        </template>
                        <b-dropdown-item :to="{ name : 'form-list', params : { slug: branch.slug }}">
                          {{ trans('Formulaires') }}
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name : 'item-list', params : { slug: branch.slug }}">
                          {{ trans('Objets') }}
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name : 'guarantee-list', params : { branchSlug: branch.slug }}">
                          {{ trans('Garanties') }}
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name : 'engine-index', params : { branchSlug: branch.slug }}">
                          {{ trans('Moteur de calcul') }}
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name : 'premium-detail-list', params : { branchSlug : branch.slug } }">
                          {{ trans('Détails de Facture') }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="p-5 d-flex flex-column align-items-center">
                    <i :class="branch.icon" :style="{ 'color' : branch.color }" class="fs-3x"></i>
                    <span :style="{ 'color' : branch.color }" class="mt-5">
                                            {{ branch.label }}
                                        </span>
                  </div>
                </div>
              </div>
            </TransitionGroup>
          </template>
        </draggable>
      </template>

      <b-modal :title="trans('Edit_Branch_Category')" centered id="categoryModal" ref="categoryModal">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Name') }}</label>
              <b-form-input :id="'name'" :state="category.label.trim().length > 0"
                            v-model="category.label"></b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Color') }}</label>
              <div>
                <input type="color" v-model="category.color">
              </div>
            </div>

          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveCategory()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>


      <b-modal :title="trans('Edit_Branch')" centered id="branchModal" ref="branchModal">
        <form v-if="branch">
          <div class="row row-cols-2 ">
            <FileUpload :allowMultiple="false" :allowRemove="false"
                        :allowedType="'image/jpeg,image/png,image/gif,image/jfif,image/svg+xml'"
                        :icon="'fad fa-edit'"
                        :previewClass="'d-flex border-dashed w-150px h-150px cursor-pointer  align-items-center justify-content-center m-auto'"
                        :text="'Icon'" fitMode="contain" :type="'full'"
                        accept=".jpg,.jpeg,.png,.gif,.jfif,.svg" v-model="branch.photo"/>


                        <FileUpload :allowMultiple="false" :allowRemove="false"
                        :allowedType="'image/jpeg,image/png,image/gif,image/jfif,image/svg+xml'"
                        :icon="'fad fa-edit'"
                        :previewClass="'d-flex border-dashed w-150px h-150px cursor-pointer  align-items-center justify-content-center m-auto'"
                        :text="'Illustration'" fitMode="contain" :type="'full'"
                        accept=".jpg,.jpeg,.png,.gif,.jfif,.svg" v-model="branch.illustration"/>
          </div>
          <div class="d-flex">
            <div class="m-2 flex-grow-1">
              <label :for="'category'" class="required form-label">{{ trans('Category') }}</label>
              <b-form-select :state="branch.category !== null && branch.category !== undefined"
                             class="form-control form-select-solid form-select"
                             v-model="branch.category">
                <b-form-select-option :value="null">{{ trans('Select_a_category') }}
                </b-form-select-option>
                <b-form-select-option :key="category.uuid" :value="category"

                                      v-for="category,key in categories">
                  {{ trans(category.label) }}
                </b-form-select-option>
              </b-form-select>
            </div>
            <div class="m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'name'" :state="branch.label.length > 0"
                            v-model="branch.label"></b-form-input>
            </div>
          </div>
          <div class="d-flex">
            <div class="m-2 flex-grow-1">
              <label :for="'icon'" class="required form-label">{{ trans('Icon') }}</label>
              <div>
                <IconSelector :selected="branch.icon" v-on:select="setIcon"></IconSelector>
              </div>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'color'" class="required form-label">{{ trans('Couleur') }}</label>
              <div>
                <input type="color" v-model="branch.color">
              </div>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'active'" class="form-label">{{ trans('Active') }}</label>
              <label class="form-check form-switch form-check-custom form-check-solid">
                <input class="form-check-input" id="activated" name="active" type="checkbox"
                       v-model="branch.active"
                       value=""/>
                <span class="form-check-label">
                                </span>
              </label>
            </div>


          </div>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'category'"
                     class="required form-label">{{ trans('Stratégie d\'actualisation de la date d\'effet') }}</label>

              <b-form-select :state="helper.empty(branch.startDate)"
                             class="form-control form-select-solid form-select"
                             v-model="branch.startDate">
                <b-form-select-option :value="null">{{ trans('Selectionnez une stratégie') }}
                </b-form-select-option>
                <b-form-select-option :key="date.code" :value="date.code"

                                      v-for="date in dateConstants">
                  {{ trans(date.name) }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </div>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'active'" class="form-label">{{ trans('Description') }}</label>
              <label class="form-check form-switch form-check-custom form-check-solid">
                <textarea  class="form-control" v-model="branch.description"></textarea>
                <span class="form-check-label">
                                </span>
              </label>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editBranch()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import IconSelector from "@/components/IconSelector.vue";
import BranchCategory from "@/entity/BranchCategory";
import Branch from "@/entity/Branch";
import Popup from "@/entity/Popup";
import FileUpload from "@/components/FileUpload.vue";

@Component({
  components: {FileUpload, IconSelector, Loading, Base}
})
export default class BranchList extends Vue {
  [x: string]: any;

  branches: any[] = []
  categories: BranchCategory[] = []
  branch: Branch = new Branch()
  category = new BranchCategory()
  loading = false
  currentCategory: any = null
  dateConstants = [
    {name: "Aujourd'hui", code: "today"},
    {name: "Demain", code: "tomorrow"},
    {name: "Premier jour du mois en cours", code: "first_day_current_month"},
    {name: "Dernier jour du mois en cours", code: "last_day_current_month"},
    {name: "Premier jour du mois prochain", code: "first_day_next_month"},
    {name: "Dernier jour du mois prochain", code: "last_day_next_month"},
  ]

  async mounted() {
    await this.load()
    if (this.categories.length > 0) {
      this.currentCategory = this.categories[0] as any
    }
  }

  async toggleActivation(evt: any, uuid: string) {
    // console.log(evt.target.checked)
    const res = await api.get(api.core, 'branch/toggle/activation/' + uuid)
    this.load()
    this.$forceUpdate()
  }

  editCategory() {
    if (this.currentCategory) {
      this.category = new BranchCategory({...this.currentCategory})
    }
  }

  async saveCategory() {
    if (this.category.label.length > 0) {
      const res = await api.post(api.core, 'branch/category/edit', {category: this.category})
      if (res && res.data) {
        (this.$refs.categoryModal as any).hide()
        this.category = new BranchCategory()
        this.load()
      }
    }
  }

  async load() {
    const pop = new Popup('Chargement', 'Liste des branches', 'primary', 'fad fa-sync', true)
    const res = await api.get(api.core, 'branch/category/list')
    if (res && res.data && res.data.categories) {
      this.categories = []
      res.data.categories.forEach((cat: BranchCategory) => {
        this.categories.push(new BranchCategory(cat))
      })
    }
    if (this.categories.length > 0 && this.currentCategory === null) {
      this.setCurrent(this.categories[0])
    }
    pop.hide()
    this.$forceUpdate()
  }

  setIcon(evt: any) {
    this.branch.icon = evt
  }

  setBranch(branch: Branch) {
    this.branch = branch;
  }

  resetBranch() {
    this.branch = new Branch()
  }

  resetCategory() {
    this.category = new BranchCategory()
  }

  async generate() {
    // const res = await api.get(api.form, 'survey/generate/'+)
  }

  async end(evt: any, originalEvent: any) {
    const popup = new Popup('', this.trans('Reorganisation en cours ...'), 'warning', 'fad fa-cogs', true)
    const ranks: any = []
    let i = 1;

    this.branches.forEach((b: any) => {
      ranks.push({uuid: b.uuid, ranking: i})
      i++;
    })
    const res = await api.post(api.core, 'branch/update/ranks', {ranks})
    popup.hide()
  }

  async setCurrent(cat: BranchCategory) {
    this.currentCategory = cat
    while (this.branches.length > 0) {
      this.branches.shift()
    }
    await this.$store.state.sleep(100)
    cat.branches.forEach((b: Branch) => {
      this.branches.push(b)
    })
  }


  async editBranch() {
    this.$store.commit('loading')
    const branch = this.branch as any
    branch.category = {uuid: this.branch.category!.uuid} as any
    delete branch.items
    // console.log(branch)
    // return
    const res = await api.post(api.core, 'branch/edit', {branch})
    if (res && res.data) {
      (this.$refs.branchModal as any).hide()
      this.load()
      this.branch = new Branch()
      // this.$router.push({name: 'branch-index'})
    }
    this.$store.commit('stopLoading')
  }

}
</script>
<style scoped>
.dropmenu a {
  padding: 2px 5px;
  white-space: nowrap;
}

.dropmenu a:hover {
  background: var(--bs-secondary);
}

.category {
  filter: grayscale(1);
  transition: all .5s ease;
}

.category:hover {
  filter: grayscale(.6);
}

.category.selected {
  filter: grayscale(0);
}

.white-box {
  border-radius: 17px;
  background: linear-gradient(225deg, #ffffff, #e9e7e7);
  /*background: linear-gradient(145deg, #f0f0f0, #cacaca);*/
  /*background: white;*/
  box-shadow: -3px 3px 7px #adabab,
  1px -1px 1px #f7f7f7;
}

.n {
  border-radius: 17px;
  background: white;
  box-shadow: -3px 3px 3px #757373,
  1px -1px 1px #f7f7f7;

}

.backstick {

  position: absolute;
  width: 150px;
  height: 30px;
  bottom: -15px;
  z-index: 0;
  /*opacity: .3;*/

}

.cover {
  position: absolute;
  width: 150px;
  height: 30px;
  bottom: -15px;
  background: cornflowerblue;
  border-radius: 200px 200px 0 0;
  box-shadow: 0 0 5px gray;
  z-index: 2;
}

.backstick .before {
  content: '';
  filter: brightness(0.7);
  position: absolute;
  /*background: cornflowerblue;*/
  width: 20px;
  height: 30px;
  transform: skew(30deg) translateX(-10px);
  z-index: -10;
}

.backstick .after {
  content: '';
  filter: brightness(0.7);
  position: absolute;
  /*background: cornflowerblue;*/
  width: 20px;
  height: 30px;
  top: 0;
  right: 0;
  transform: skew(-30deg) translateX(10px);
  z-index: -10;
}


.nbutton {
  padding: 1.5em 5em;
  background: #efefef;
  border: none;
  border-radius: .5rem;
  color: #444;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .2rem;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
  -6px -6px 10px rgba(255, 255, 255, .5),
  6px 6px 8px rgba(255, 255, 255, .075),
  6px 6px 10px rgba(0, 0, 0, .15);
}

.nbutton:hover {
  box-shadow: -2px -2px 6px rgba(255, 255, 255, .6),
  -2px -2px 4px rgba(255, 255, 255, .4),
  2px 2px 2px rgba(255, 255, 255, .05),
  2px 2px 4px rgba(0, 0, 0, .1);
}

input.branch-category:checked + .nbutton {
  background: cornflowerblue;
  color: #efefef;
  box-shadow: inset -2px -2px 6px rgb(56, 94, 137),
  inset -2px -2px 4px rgba(255, 255, 255, .5),
  inset 2px 2px 2px rgba(255, 255, 255, .075),
  inset 2px 2px 4px rgba(0, 0, 0, .15);
}


.slide-left-enter-active, .slide-left-leave-active {
  transition: all calc(var(--i) * .2s);
}

.slide-left-leave-from, .slide-left-leave-to {
  transition: all 0.2s ease;
  opacity: 0;
  position: absolute;
}

/*.slide-left-leave-from{*/
/*    transition: all calc(var(--i) * .2s);*/
/*}*/

.slide-left-enter {
  opacity: 0;
  transform: translateY(50%);
}

</style>
