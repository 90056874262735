<template>
  <div class="Mapper" style="cursor: grab;overflow: auto;">
    <div :id="'mapping_' + rand"
         class="d-flex justify-content-between mt-10 position-relative overflow-hidden align-items-start">
      <div class="card min-w-400px" id="questions">
        <div class="card-header align-items-center">
          <div class="card-title fw-bolder">
            <template v-if="survey">
              {{ survey.label }}
            </template>
          </div>
          <div class="car-toolbar">
            <router-link
                :to="{ name: 'survey-form', params: { branchSlug: $route.params.branchSlug, surveySlug: $route.params.surveySlug } }"
                class="btn btn-primary btn-icon">
              <i class="fad fa-external-link"></i>
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <!--                        <div class="d-flex justify-content-between mb-5">-->
          <!--                            <div class="fs-2"></div>-->
          <!--                        </div>-->
          <template v-if="survey">
            <template v-for="step in survey.steps">
              <div class="text-gray-400">{{ step.label }}</div>
              <template v-for="section in step.sections">
                <div class="text-gray-400 ps-2">{{ section.label }}</div>
                <template v-for="question in section.questions">
                  <div class="d-flex align-items-center justify-content-between my-1 ps-4">
                    <QuestionLine :question="question"></QuestionLine>
                    <div :id="'question_marker_' + question.uuid" class="w-20px ms-5 h-20px rounded bg-secondary d-flex justify-content-center align-items-center
                                             cursor-pointer pointers position-relative">
                      <!--                                                <i class="fad fa-arrows"></i>-->
                      <div :id="'input_' + question.uuid" @mouseleave="ignorePath(question.uuid)"
                           @mouseover="highlightPath(question.uuid)"
                           class="input point cursor-pointer  rounded-circle"></div>
                      <div @drag="movePoint($event, question)"
                           @dragend="endDragPoint($event, question)"
                           @dragstart="dragPoint($event, question)"
                           @mouseleave="ignorePath(question.uuid)"
                           @mouseover="highlightPath(question.uuid)"
                           class="output point cursor-pointer rounded-circle" draggable="true">
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </template>
          <template v-else>
            <Loading></Loading>
          </template>
        </div>
      </div>
      <div class="card min-w-400px" id="objects">
        <div class="card-header align-items-center">
          <div class="card-title fw-bolder">
            Propriétés
          </div>
          <div class="car-toolbar">
            <router-link :to="{ name: 'item-list', params: { slug: $route.params.branchSlug } }"
                         class="btn btn-primary btn-icon">
              <i class="fad fa-external-link"></i>
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <template v-if="survey">
            <div v-for="item in itemTypes">
              <template v-if="(survey.isRoot && (item.required || item.isRoot)) || !survey.isRoot">
                <ItemLine :allItemTypes="allItemTypes" :item="item" :itemTypes="itemTypes"
                          :survey="survey" v-on:linkQuestionToProperty="linkQuestionToProperty"></ItemLine>
              </template>
            </div>
          </template>
          <template v-else>
            <Loading></Loading>
          </template>
        </div>
      </div>
      <svg :key="mapKey" class="w-100 h-100 position-absolute pointers-svg" v-if="survey">
        <template>
          <template v-for="step in survey.steps">
            <template v-for="section in step.sections">
              <template v-for="question in section.questions">
                <path :d="null" :id="'path_' + question.uuid" :key="'path_' + question.uuid"
                      :ref="'path_' + question.uuid" class="path"></path>
              </template>
            </template>
          </template>
        </template>
        <path :id="'path_' + link.questionUuid + '_' + link.propertyUuid"
              :key="'link_' + link.questionUuid + '_' + link.propertyUuid" :ref="'link' + index" class="path link"
              v-for="(link, index) in survey.mappings"></path>
      </svg>
    </div>
    <!--        <div class="w-100" v-if="survey">-->
    <!--            <div v-for="mapping in survey.mappings">-->
    <!--                {{getQuestionByUuid(mapping.questionUuid).label}} => {{getPropertyByUuid(mapping.propertyUuid).name}}-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Survey from "@/entity/Survey";
import ItemType from "@/entity/ItemType";
import {helper} from "@/services/Helper";
import ItemProperty from "@/entity/ItemProperty";
import {api} from "@/services/Api";
import Question from "@/entity/Question";
import PropertyLine from "@/views/Configuration/Branch/Mapper/PropertyLine.vue";
import QuestionLine from "@/views/Configuration/Branch/Mapper/QuestionLine.vue";
import Loading from "@/components/Loading.vue";
import Item from "@/entity/Item";
import ItemLine from "@/views/Configuration/Branch/Mapper/ItemLine.vue";
import Mapping from "@/entity/Mapping";

@Component({
  components: {ItemLine, Loading, QuestionLine, PropertyLine}
})
export default class Mapper extends Vue {


  @Prop() allItemTypes!: ItemType[]
  itemTypes: ItemType[] = []
  root: any = null
  survey: Survey | any = null
  @Prop() map!: { survey: Survey, items: ItemType[] }
  mapKey = helper.generateId()
  rand = helper.generateId()

  async mounted() {
    // console.log('mount')
    if (this.map.survey && this.map.items) {
      this.survey = this.map.survey
      this.itemTypes = this.map.items
    }
    window.addEventListener('resize', this.resize)
    await this.$store.state.sleep(100)
    this.resize()
  }

  updated(): void {
    // console.log('updated')
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.resize)
  }

  resize() {

    this.survey.mappings.forEach((m: Mapping) => {
      this.traceMapping(m)
    })
    // console.log(this.survey.mappings)
    if (this.updateMap) {
      this.$emit('updateMap')
    }
    // this.mapKey = helper.generateId()
  }

  getItemByUuid(uuid: string) {
    const item = this.itemTypes.find((i: ItemType) => {
      return i.uuid === uuid
    })

    return item
  }

  async linkQuestionToProperty(evt: any) {
    if (evt) {
      const question = this.getQuestionByUuid(evt.questionUuid)
      const property = this.getPropertyByUuid(evt.propertyUuid)
      const mapping = new Mapping({
        questionUuid: evt.questionUuid, propertyUuid: evt.propertyUuid, propertyCode: property.code,
        propertyLink: property.link, itemCode: property.item.code, ranking: evt.ranking
      })
      const exist = this.survey.mappings.findIndex((m: Mapping) => {
        return m.questionUuid === evt.questionUuid && m.propertyUuid === evt.propertyUuid
      })
      if (exist === -1) {
        this.survey.mappings.push(mapping)
        await this.$store.state.sleep(100)
      }
      this.traceMapping(mapping)
    }

    // this.mapKey = helper.generateId()
    await this.$store.state.sleep(100)
    this.$emit('updateMap')
  }

  removeMap(mapping: Mapping) {
    const question = this.getQuestionByUuid(mapping.questionUuid)
    const property = this.getPropertyByUuid(mapping.propertyUuid)
    if ((question.type.code === 'OBJECT_GROUP' && property.type.code === 'OBJECT_GROUP') ||
        (question.type.code === 'OBJECT' && property.type.code === 'OBJECT')) {
      // console.log('remove Map ' + question.label)
      this.$emit('updateMap')
    }
  }

  updateMap = false

  traceMapping(mapping: Mapping) {
    const r: any = document.querySelector("#mapping_" + this.rand)
    const input: HTMLElement = document.querySelector("#mapping_" + this.rand + " #input_" + mapping.questionUuid) as any
    const output: HTMLElement = document.querySelector("#mapping_" + this.rand + " #property_marker_" + mapping.propertyUuid) as any
    // console.log(mapping)
    const question = this.getQuestionByUuid(mapping.questionUuid)
    const property = this.getPropertyByUuid(mapping.propertyUuid)
    // console.log(property)
    // mapping = new Mapping({
    //     questionUuid: question.uuid, propertyUuid: property.uuid, propertyCode: property.code,
    //     propertyLink: property.link, itemCode: property.item.code
    // })
    if (!property) {
      console.log('property ' + mapping.propertyUuid + ' not found')
      return
    }
    // console.log(property)
    mapping.propertyCode = property.code
    mapping.propertyLink = property.link
    mapping.itemCode = property.item.code
    if ((question.type.code === 'OBJECT_GROUP' && property.type.code === 'OBJECT_GROUP') ||
        (question.type.code === 'OBJECT' && property.type.code === 'OBJECT')) {
      this.updateMap = true
    }
    if (!input || !output) {
      // console.log('survey '+ this.survey.label +' input not found ' + question.label)
      return
    }
    let y = input.getBoundingClientRect().top + (input.getBoundingClientRect().height / 2) - r.getBoundingClientRect().top
    let x = input.getBoundingClientRect().left + (input.getBoundingClientRect().width / 2) - r.getBoundingClientRect().left
    const startPoint = {x: x, y: y}
    x = (output.getBoundingClientRect().x + 8) - r.getBoundingClientRect().left
    y = (output.getBoundingClientRect().y + 8) - r.getBoundingClientRect().top
    const endPoint = {x: x, y: y}

    // console.log(startPoint)
    // console.log(endPoint)

    const delta = this.calculateDeta()
    const fixedLineInflectionConstant = 40
    const p1 = {
      x: startPoint.x,
      y: startPoint.y,
    };
    const p2 = {
      x: startPoint.x + fixedLineInflectionConstant,
      y: startPoint.y,
    };
    const p3 = {
      x: endPoint.x - fixedLineInflectionConstant,
      y: endPoint.y,
    };
    const p4 = {
      x: endPoint.x,
      y: endPoint.y,
    };
    // return {p1, p2, p3, p4};
    // console.log(question.label + ' => ' + property.name)
    let path = document.querySelector("#mapping_" + this.rand + " #path_" + mapping.questionUuid + '_' + mapping.propertyUuid)!
    const d = 'M ' + p1.x + ', ' + p1.y + ' C ' + p2.x + ', ' + p2.y + ' ' + p3.x + ', ' + p3.y + ' ' + p4.x + ', ' + p4.y
    path.setAttribute('d', d)
    // console.log(path)
  }

  // calculateDelta(startPoint: any = null, endPoint: any = null) {
  //     if (!startPoint) {
  //         startPoint = this.startPoint
  //     }
  //     if (!endPoint) {
  //         endPoint = this.endPoint
  //     }
  //     const dx = endPoint.x - startPoint.x;
  //     const dy = endPoint.y - startPoint.y;
  //     const absDx = Math.abs(dx);
  //     const absDy = Math.abs(dy);
  //
  //     return {dx, dy, absDx, absDy};
  // }

  async getQuestions() {
    // this.survey = null
    // const res = await api.get(api.form, 'survey/get/' + this.$route.params.surveySlug)
    // if (res && res.data && res.data.survey) {
    //     this.survey = new Survey(res.data.survey)
    // }
  }

  getPropertyByUuid(uuid: string) {
    let property: ItemProperty = null as any
    this.itemTypes.forEach((i: ItemType) => {
      i.properties.forEach((p: ItemProperty) => {
        if (p.uuid === uuid) {
          property = p
          property.item = i
        }
      })
    })
    return property;
  }

  getQuestionByUuid(uuid: string) {
    return this.survey.getQuestions().find((q: Question) => {
      return q.uuid === uuid
    })
  }

  async getItems() {
    const res = await api.get(api.core, 'item/type/list/' + this.$route.params.branchSlug)
    if (res && res.data && res.data.itemTypes) {
      this.itemTypes = []
      // this.itemTypes = res.data.itemTypes
      res.data.itemTypes.forEach((itemType: any) => {
        const item = new ItemType(itemType)
        this.itemTypes.push(item)
        if (item.isRoot) {
          this.root = item
        }
      })
      if (this.root) {
        this.tree = this.getItemTree(this.root)
        // console.log(this.tree)
      }
    }
  }


  dragged: any = null;

  dragPoint(e: any, question: Question) {
    const r: any = document.querySelector("#mapping_" + this.rand)
    const input: any = document.querySelector("#mapping_" + this.rand + " #question_marker_" + question.uuid)
    const y = input.getBoundingClientRect().top + (input.getBoundingClientRect().height / 2) - r.getBoundingClientRect().top
    const x = input.getBoundingClientRect().left + (input.getBoundingClientRect().width / 2) - r.getBoundingClientRect().left
    this.startPoint = {x: x, y: y}
    r.classList.add('dragging')

    input.classList.add('input-source')
    this.$store.state.questionDragged = question
    e.dataTransfer.setData('text/plain', question.uuid);
    e.target.style.zIndex = 5
    var crt = e.target.cloneNode(true);
    e.target.style.opacity = 0
    crt.classList.add('drag-ghost');
    crt.style.background = 'cornflowerblue'
    crt.setAttribute('uuid', question.uuid)
    crt.style.position = "absolute";
    crt.style.top = (e.pageY - r.getBoundingClientRect().top - 8) + 'px';
    crt.style.left = (e.pageX - r.getBoundingClientRect().left - 8) + 'px';
    crt.style.pointerEvents = 'none';
    this.dragged = e.target
    // console.log(e.pageX)
    r.appendChild(crt);
    // console.log(crt)
    var no = document.createElement('div');
    e.dataTransfer.setDragImage(no, 0, 0);
  }

  endDragPoint(el: any, question: Question) {
    const r: any = document.querySelector("#mapping_" + this.rand)
    r.classList.remove('dragging')
    const input: any = document.querySelector("#mapping_" + this.rand + " #question_marker_" + question.uuid)
    el.target.style.opacity = 1
    el.target.style.zIndex = 4
    this.dragged = null
    this.$store.state.questionDragged = null
    input.classList.remove('input-source')
    let ghost = document.querySelector('.drag-ghost') as any;

    el.target.style.top = '2px'
    el.target.style.left = '2px'
    this.resetPath(question.uuid!)
    this.catched = false

    // if (!this.catched) {
    //     el.target.style.top = '2px'
    //     el.target.style.left = '2px'
    //     this.resetPath(question.uuid)
    // } else {
    //     el.target.style.top = (ghost.getBoundingClientRect().top - input.getBoundingClientRect().top) + 'px';
    //     el.target.style.left = (ghost.getBoundingClientRect().left - input.getBoundingClientRect().left) + 'px';
    // this.catched = false
    // }


    ghost.parentNode.removeChild(ghost)

  }

  dragging = false
  questionDragged: any = null
  startPoint = {x: 0, y: 0}
  endPoint = {x: 0, y: 0}

  movePoint(e: any, question: Question) {
    // console.log(this.catched)
    if (!this.catched) {
      let ghost: any = document.querySelector('.drag-ghost') as any;
      const r: any = document.querySelector("#mapping_" + this.rand)
      const y = e.pageY - r.getBoundingClientRect().top - window.pageYOffset
      const x = e.pageX - r.getBoundingClientRect().left
      if (e.pageX !== 0 && e.pageY !== 0) {
        ghost.style.top = (y - 8) + 'px';
        ghost.style.left = (x - 8) + 'px';

        this.endPoint = {x: x, y: y}
      }
      // console.clear()
      // console.log(ghost.style.left)
      this.calculatePath(question.uuid!)
    }
  }

  catched = false

  dragPointOver(ev: any) {
    // console.log('dragOver')
    if (!this.catched && this.dragged) {
      this.catched = true
      const mapping = document.querySelector("#mapping_" + this.rand)!
      const x = (ev.target.getBoundingClientRect().x + 8) - mapping.getBoundingClientRect().left
      const y = (ev.target.getBoundingClientRect().y + 8) - mapping.getBoundingClientRect().top
      this.dragged.style.top = y + 'px'
      this.dragged.style.left = x + 'px'
      let ghost: any = document.querySelector('.drag-ghost') as any;
      ghost.style.top = (y - 6) + 'px'
      ghost.style.left = (x - 6) + 'px'
      this.endPoint = {x: x, y: y}
      this.calculatePath(ghost.getAttribute('uuid'))
      // console.log(ev.target)
    } else {
      // console.log('already catched')
    }
  }

  dragPointLeave(ev: any) {
    this.catched = false
    // console.log('dragLeave')
    // this.$forceUpdate()
    // console.log(ev)
  }


  links: any[] = []

  dropPoint(ev: any, property: ItemProperty) {
    // if (this.dragged) {
    //     const question = new Question(JSON.parse(ev.dataTransfer.getData('text/plain')))
    //     // console.log(question)
    //     // console.log(property)
    //     if (question.type.format != property.type.format) {
    //         this.catched = false
    //         return
    //     }
    //     if ((question.type.code === 'OBJECT_GROUP' && property.type.code === 'OBJECT_GROUP') ||
    //         (question.type.code === 'OBJECT' && property.type.code === 'OBJECT')) {
    //     }
    //     property.mapped = true
    //     const link = {questionUuid: question.uuid, propertyUuid: property.uuid}
    //     this.links.forEach((l: any) => {
    //         if (l.questionUuid === link.questionUuid && l.propertyUuid === link.propertyUuid) {
    //             console.log('CE LIEN A DEJA UN PATH')
    //         }
    //     })
    //     this.links.push(link)
    //     this.$nextTick(() => {
    //         this.updateLinkPath(link)
    //     })
    // }

  }

  updateLinkPath(link: any) {
    const input = document.querySelector('#mapping_' + this.rand + ' #question_marker_' + link.questionUuid)!
    const output = document.querySelector('#mapping_' + this.rand + ' #property_marker_' + link.propertyUuid)!
    const path = document.querySelector("#mapping_" + this.rand + ' #path_' + link.questionUuid + '_' + link.propertyUuid)!
    const r: any = document.querySelector("#mapping_" + this.rand)

    const y = input.getBoundingClientRect().top + (input.getBoundingClientRect().height / 2) - r.getBoundingClientRect().top
    const x = input.getBoundingClientRect().left + (input.getBoundingClientRect().width / 2) - r.getBoundingClientRect().left

    const ey = output.getBoundingClientRect().top + (input.getBoundingClientRect().height / 2) - r.getBoundingClientRect().top
    const ex = output.getBoundingClientRect().left + (input.getBoundingClientRect().width / 2) - r.getBoundingClientRect().left
    const startPoint = {x: x, y: y}
    const endPoint = {x: ex, y: ey}
    this.calculatePath(link.questionUuid + '_' + link.propertyUuid, startPoint, endPoint)

  }


  calculateDeta(startPoint: any = null, endPoint: any = null) {
    if (!startPoint) {
      startPoint = this.startPoint
    }
    if (!endPoint) {
      endPoint = this.endPoint
    }
    const dx = endPoint.x - startPoint.x;
    const dy = endPoint.y - startPoint.y;
    const absDx = Math.abs(dx);
    const absDy = Math.abs(dy);

    return {dx, dy, absDx, absDy};
  }

  point = {p1: {x: 0, y: 0}, p2: {x: 0, y: 0}, p3: {x: 0, y: 0}, p4: {x: 0, y: 0}}
  path: any = null

  updatePoints(startPoint: any = null, endPoint: any = null) {
    if (!startPoint) {
      startPoint = this.startPoint
    }
    if (!endPoint) {
      endPoint = this.endPoint
    }
    // console.log(startPoint)
    const delta = this.calculateDeta()
    const fixedLineInflectionConstant = 40
    const p1 = {
      x: startPoint.x,
      y: startPoint.y,
    };
    const p2 = {
      x: startPoint.x + fixedLineInflectionConstant,
      y: startPoint.y,
    };
    const p3 = {
      x: endPoint.x - fixedLineInflectionConstant,
      y: endPoint.y,
    };
    const p4 = {
      x: endPoint.x,
      y: endPoint.y,
    };
    return {p1, p2, p3, p4};
  }

  calculatePath(uuid: string, startPoint: any = null, endPoint: any = null) {
    if (!startPoint) {

    }
    const input = document.querySelector("#input_" + uuid)
    // console.log(input.getBoundingClientRect())
    // console.log(startPoint)
    // console.log(endPoint)
    const point = this.updatePoints(startPoint, endPoint)
    let path = document.querySelector("#mapping_" + this.rand + " #path_" + uuid)!
    const d = 'M ' + point.p1.x + ', ' + point.p1.y + ' C ' + point.p2.x + ', ' + point.p2.y + ' ' + point.p3.x + ', ' + point.p3.y + ' ' + point.p4.x + ', ' + point.p4.y
    // console.log(d)
    path.setAttribute('d', d)
  }

  resetPath(uuid: string) {
    let path = document.querySelector("#mapping_" + this.rand + " #path_" + uuid)!
    path.setAttribute('d', 'M0 0')
    // path.removeAttribute('d')
    let output = document.querySelector("#question_marker_" + uuid + ' .output') as any
    output.style.top = '2px'
    output.style.left = '2px'

  }

  highlightPath(uuid: string) {
    let path = document.querySelector("#mapping_" + this.rand + " #path_" + uuid) as any
    let input = document.querySelector("#mapping_" + this.rand + " #input_" + uuid) as any
    path.style.stroke = 'indianred'
    input.style.background = 'indianred'
  }

  ignorePath(uuid: string) {
    let path = document.querySelector("#mapping_" + this.rand + " #path_" + uuid) as any
    let input = document.querySelector("#mapping_" + this.rand + " #input_" + uuid) as any
    path.style.stroke = 'cornflowerblue'
    input.style.background = 'cornflowerblue'
  }


  tree: any = {}
  treeConfig: any = {nodeWidth: 200, nodeHeight: 80, levelHeight: 100,}

  getItemTree(item: ItemType) {
    let tree = {
      name: item.name,
      code: item.code,
      icon: item.icon,
      className: item.className,
      props: [] as any,
      children: [] as any
    };
    item.properties.forEach((prop: ItemProperty) => {
      tree.props.push(prop)
      if (prop.type.code === 'OBJECT') {
        const link = this.itemTypes.find((i: ItemType) => {
          return i.uuid === prop.value
        })
        if (link) {
          const t = this.getItemTree(link)
          t.name = prop.name
          tree.children.push(t)
        }
      }
      if (prop.type.code === 'OBJECT_GROUP') {
        const link = this.itemTypes.find((i: ItemType) => {
          return i.uuid === prop.value
        })
        if (link) {
          // for (let i = 1; i < 3; i++) {
          const t = this.getItemTree(link)
          t.name = prop.name + ' 1'
          tree.children.push(t)
          // }
        }
      }
    })
    return tree
  }
}
</script>
<style scoped>
.point {
  fill: white;
  box-shadow: 0 0 2px rgba(150, 150, 150, .8);
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.input {
  box-shadow: inset 3px 3px 6px #00000040, inset -3px -3px 6px #9c9ea066;
  background: cornflowerblue;
  pointer-events: none;
  width: 12px;
  height: 12px;
}

.output {
  position: absolute;
  background: whitesmoke;
  box-shadow: 0 0 2px rgba(150, 150, 150, .8);
  top: 2px;
  left: 2px;
  z-index: 4;
  /*transition: opacity 0.2s ease;*/
  /*transform: translateX(-50%) translateY(-50%);*/
}

.path {
  stroke-width: 5px;
  fill: none;
  stroke: cornflowerblue;
  pointer-events: all;
  cursor: pointer;
}

.path:hover,
g:hover {
  stroke: indianred;
  box-shadow: 0 0 5px black;
}

.path.link {
  /*stroke: #f8069c;*/
}

.dragging .property .output {
  display: none;
}

.property .input {
  width: 12px;
  height: 12Px;
}

.pointers-svg {
  /*z-index: 3;*/
  pointer-events: none;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0
}
</style>
