<template>
  <div class="ItemTypeInspector">
    <label :class="{ 'text-primary': !expand }" class="fw-bolder cursor-pointer text-hover-primary w-100 py-2">
      <input class="hidden" type="checkbox" v-model="expand">
      <i class="fad fa-angle-right" v-if="!expand"></i>
      <i class="fad fa-angle-down" v-else></i>
      {{ type.name }}
    </label>
    <div v-if="parent && (expand || show)" class="m-1 cursor-pointer badge text-info">
      <i class="fad fa-angle-right"></i> Charger l'objet parent
    </div>
    <div class="d-flex flex-wrap ps-2" v-if="expand || show">
      <template v-for="prop in type.properties">

        <div v-if="prop.type.code !== 'OBJECT'">
          <div @click="setProperty(type, prop, $event)" class="m-1 cursor-pointer badge badge-primary ">
            {{ prop.name }}
          </div>
          <div v-if="prop.type.code === 'PACK'"
               @click="setPropertyCategory(type, prop, $event)" class="m-1 cursor-pointer badge badge-primary ">
            {{ prop.name }} > Catégorie
          </div>
        </div>
        <template v-else-if="allowSub">
          <!-- <label class="fw-bolder cursor-pointer text-hover-primary w-100 py-2" draggable="true">
              <input class="hidden" type="checkbox" v-model="showSub">
              <i class="fad fa-angle-right" v-if="!showSub"></i>
              <i class="fad fa-angle-down" v-else></i>
              {{ prop.name }}
          </label> -->
          <div :set="subType = getItemType(prop.value)" class="w-100 flex-grow-1">
            <!-- {{ subType }} -->
            <ItemTypeClickInspector :parent="link" :prop="prop" :show="showSub" :type="subType"
                                    v-if="subType" @setProperty="emitProperty"></ItemTypeClickInspector>
            <div v-else class="text-danger">
              l'objet de référence est introuvable
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import ItemType from "@/entity/ItemType";
import ItemProperty from "@/entity/ItemProperty";

@Component({})
export default class ItemTypeClickInspector extends Vue {
  @Prop() type!: ItemType
  @Prop() prop?: ItemProperty
  @Prop({default: true}) allowSub!: boolean
  expand = false
  @Prop() parent: any
  showSub = false
  @Prop() show?: boolean
  link: any[] = []
  parentLoaded = false
  properties: ItemProperty[] = []

  loadParent() {
  }

  loadProperties() {
    // console.log('load Properties ' + this.type.name)
    if (this.properties.length === 0) {
      console.log(this.type.properties)
      this.properties = this.type.properties
    }
  }

  mounted(): void {
    // console.log(this.type.name + ' appear')
    // console.log(this.type)
    this.link = this.parent ? [...this.parent] : []
    let prop = {}
    if (this.prop) {
      prop = {code: this.prop.code, name: this.prop.name}
    }
    this.link.push({name: this.type.name, uuid: this.type.uuid, code: this.type.code, prop: prop})
    // console.log(this.type)
    this.type.properties.forEach((p: ItemProperty) => {
      if (p.type.code === 'OBJECT') {
        // console.log(p)
        this.getItemType(p.value)
      }
    })
  }

  emitProperty(evt: any) {
    // console.log('emit setProp')
    this.$emit('setProperty', evt)
  }

  setPropertyCategory(type: ItemType, prop: ItemProperty, evt: any) {
    prop.link = 'category'
    this.$emit('setProperty', {type, prop, evt, source: [...this.link]})
  }

  setProperty(type: ItemType, prop: ItemProperty, evt: any) {
    this.$emit('setProperty', {type, prop, evt, source: [...this.link]})
  }

  getItemType(uuid: any) {
    const item = this.$store.state.itemTypes.find((i: ItemType) => {
      return i.uuid === uuid;
    })
    if (item) {
      // console.log('found subtype ' + item.name)
    } else {
      // console.log(this.$store.state.itemTypes)
      console.log('sub property ' + uuid + ' not found')
    }
    return item
  }
}
</script>
<style scoped>

</style>
