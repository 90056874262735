<template>
  <!--  <transition appear class="flex-grow-1 question mw-100" name="slide-up" tag="div">-->
  <div class="w-100 offers" v-if="question.isAuthorized()">
    <div class="bg-success fs-2 text-center py-5 text-white fw-bolder rounded-top mb-5" v-if="results.length > 0">
      {{ results.length }} offres correspondent à vos besoins

      <label class="btn btn-xs btn-icon btn-primary" v-if="hasRole('ROLE_MANAGER')">
        <i class="fad fa-cogs"></i>
        <input type="checkbox" v-model="showComparisonDetails" class="hidden">
      </label>
    </div>
    <div :key="'calcul_' + calculUuid">
      <div v-if="results.length === 0"
           class="d-flex flex-column align-items-center justify-content-center bg-light-warning  rounded border-dashed border-warning">
        <div class="align-items-center justify-content-center d-flex flex-column">
          <div class="fw-bolder text-gray-400 fs-2 my-10">
            <i class="fad fa-empty-set fs-5x"></i>
          </div>

          <div v-if="missingPack.length > 0" class="pb-10">
            <div class="fw-bolder fs-2 mb-3">
              Encore {{ missingPack.length }} {{ missingType }} à configurer
            </div>
            <div v-if="missingPack.length < 10">
              <router-link target="_blank"
                           :to="{ name :'response-group-manager' ,params:{ surveySlug: item.responseGroup?.surveySlug, rgUuid : item.responseGroup?.uuid }}"
                           v-for="item in missingPack"
                           :key="'missing_'+item.uuid" class="cursor-pointer">
                <div class="fw-bolder text-primary fs-4 d-flex align-items-center">
                  <i class="fad fa-angle-right fs-2 me-3 text-primary"></i> {{ item.alias }}
                </div>
              </router-link>
            </div>
          </div>
          <div class="fw-bolder text-gray-400 fs-2 my-10" v-else-if="!invalid">
            Désolé, aucun produit compatible à vos besoins
          </div>
          <div class="fw-bolder text-gray-400 fs-2 my-10" v-else>
            Veuillez renseigner les informations nécessaires à la cotation
            <label class="btn btn-xs btn-icon btn-primary" v-if="hasRole('ROLE_MANAGER')">
              <i class="fad fa-cogs"></i>
              <input type="checkbox" v-model="showComparisonDetails" class="hidden">
            </label>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="d-flex w-100 flex-grow-0" v-if="branch && !isMobile">
          <!--          <div class=" p-3" style="position: sticky;left:0;top:0;background: white;z-index: 2">-->
          <!--            <table class="table-striped gy-2 gs-3 guarantee">-->
          <!--              <thead>-->
          <!--              <tr>-->
          <!--                <th class="text-center">-->
          <!--                  Garanties-->
          <!--                </th>-->
          <!--              </tr>-->
          <!--              </thead>-->
          <!--              <tbody>-->
          <!--              <template v-for="guarantee, index in branch.guarantees">-->
          <!--                <template v-if="onePackHasGuarantee(guarantee)">-->
          <!--                  <tr>-->
          <!--                    <td class="p-2" style="white-space: nowrap">-->
          <!--                      <div class="d-flex justify-content-between align-items-center"-->
          <!--                           style="width:17rem;white-space: initial;">-->
          <!--                        {{ guarantee.label }}-->
          <!--                        <i class="fad fa-question-circle ms-2 cursor-pointer"></i>-->
          <!--                      </div>-->
          <!--                    </td>-->
          <!--                  </tr>-->
          <!--                </template>-->
          <!--              </template>-->
          <!--              </tbody>-->
          <!--            </table>-->
          <!--          </div>-->
          <div class="results-container overflow-auto" style="flex:2;">
            <!--            <div class="d-flex flex-column align-items-center justify-content-center  bg-light-primary py-5" v-if="packSelected">-->
            <!--              <div class="d-flex justify-content-center">-->
            <!--                <div class="justify-content-center align-items-center d-flex flex-column">-->
            <!--                  <div>-->
            <!--                    <img :src="uploadRoot + packSelected.pack.insurer.photo" :alt="packSelected.pack.insurer.label"-->
            <!--                         class="mh-50px" v-if="packSelected.pack.insurer.photo">-->
            <!--                  </div>-->
            <!--                  <div class="fw-bolder fs-2x ms-5">-->
            <!--                    {{ packSelected.pack.label }} {{ getDuree() ? ' / ' + getDuree() : '' }}-->
            <!--                  </div>-->
            <!--                  <div class="fs-2x fw-bolder badge badge-light-danger text-decoration-line-through"-->
            <!--                       v-if="getReductionAmount(packSelected) > 0">-->
            <!--                    {{ helper.currencyFormat(packSelected.premium) }}-->
            <!--                  </div>-->
            <!--                  <div class="fs-3x fw-bolder">-->
            <!--                    {{ helper.currencyFormat(packSelected.premium - getReductionAmount(packSelected)) }}-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="ms-5 d-flex flex-column  ps-5" style="border-left: 1px gainsboro solid">-->
            <!--                  <template v-for="pg in packSelected.pack.packGuarantees">-->
            <!--                    <div class="py-2 fw-bolder" v-if="!pg.isOptional || pg.selected">-->
            <!--                      {{ pg.guarantee.label }}-->
            <!--                    </div>-->
            <!--                  </template>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="py-5" :set="remaining=getRemainingCertificates(packSelected.pack.insurer)">-->
            <!--                <label class="btn  btn-icon btn-primary" v-if="hasRole('ROLE_MANAGER')">-->
            <!--                  <i class="fad fa-cogs"></i>-->
            <!--                  <input type="checkbox" v-model="showComparisonDetails" class="hidden">-->
            <!--                </label>-->
            <!--                <div class="btn btn-info btn-icon ms-2" v-if="hasRole('ROLE_MANAGER')"-->
            <!--                     @click="showDetails(packSelected)">-->
            <!--                  <i class="fad fa-cog"></i>-->
            <!--                </div>-->
            <!--                <div class="btn btn-danger ms-2" @click="packSelected = null">-->
            <!--                  Modifier-->
            <!--                </div>-->

            <!--                <div class="btn bt-sm btn-warning ms-5" @click="subscribe(packSelected)"-->
            <!--                     v-if="canSubscribe(packSelected)">-->
            <!--                  Souscrire-->
            <!--                </div>-->
            <!--                <button v-else class="btn btn-light-dark">-->
            <!--                  Rupture de Stock-->
            <!--                </button>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="w-100 overflow-auto p-3" v-if="!packSelected">
              <table class="table-striped gy-2 gs-3 ms-5 results">
                <thead>
                <tr>
                  <template v-for="result in results">
                    <th class="text-center">
                      <div>
                        <div class=" px-5 text-center">
                          <div class="fw-bolder fs-2">
                            <div class="pb-1 fw-bolder" style="white-space: nowrap">
                              <div>
                                {{ result.insurer.label }}
                              </div>
                              <img :src="uploadRoot + result.insurer.photo"
                                   class="mh-50px" v-if="result.insurer.photo">

                            </div>
                            <!--                            <div class="fs-6">-->
                            <!--                              {{ result.pack.label }}-->
                            <!--                            </div>-->

                            <div>
                              <!--                              <div v-if="hasRole('ROLE_MANAGER') && workflow === WORKFLOWS.ASACI"-->
                              <!--                                   style="min-width: 100px;"-->
                              <!--                                   class="d-flex justify-content-center align-items-center px-4 py-4"-->
                              <!--                                   :set="remaining=getRemainingCertificates(result.pack.insurer)">-->
                              <!--                                <div class="fw-bolder text-primary" v-if="attestations.blue > 0">-->
                              <!--                                  <i class="fad fa-file text-primary fs-2"></i>-->
                              <!--                                  {{ remaining.blue }}-->
                              <!--                                </div>-->
                              <!--                                <div class="fw-bolder" v-if="attestations.yellow>0">-->
                              <!--                                  <i class="fad fa-file text-warning fs-2"></i>-->
                              <!--                                  {{ remaining.yellow }}-->
                              <!--                                </div>-->
                              <!--                                <div class="fw-bolder text-success" v-if="attestations.green>0">-->
                              <!--                                  <i class="fad fa-file text-success fs-2"></i>-->
                              <!--                                  {{ remaining.green }}-->
                              <!--                                </div>-->
                              <!--                              </div>-->
                            </div>

                          </div>
                          <div class="badge badge-light-danger text-decoration-line-through fs-2"
                               v-if="getReductionAmount(result) > 0">
                            {{ helper.currencyFormat(result.premium) }}
                          </div>
                          <div>
                            <div class=" text-primary fw-bolder">
                                <span class="fs-2x">
                                  {{ helper.currencyFormat(result.premium - getReductionAmount(result)) }}
                                </span>
                              <br>
                              <span>
                                  {{ getDuree() ? ' / ' + getDuree() : '' }}
                                </span>
                            </div>
                            <div class="d-flex align-items-center justify-content-center mb-2">
                              <div class="btn bt-sm btn-warning" @click="subscribe(result)" v-if="canSubscribe(result)">
                                Souscrire
                              </div>
                              <button class="btn bt-sm btn-light-dark whitespace-no-wrap" disabled v-else>
                                Rupture de Stock
                              </button>

                              <div class="btn btn-info btn-icon ms-2" v-if="hasRole('ROLE_MANAGER')"
                                   @click="showDetails(result)">
                                <i class="fad fa-cog"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <!--                <template v-for="guarantee, index in branch.guarantees">-->
                <!--                  <tr v-if="onePackHasGuarantee(guarantee)">-->
                <!--                    <template v-for="result, index in results">-->
                <!--                      <td :key="helper.generateId()" class="p-2 text-center" style="vertical-align: middle">-->
                <!--                        <template v-if="result.pack.hasGuarantee(guarantee)">-->
                <!--                          <template v-for="pg in result.pack.packGuarantees">-->
                <!--                            <div v-if="pg.guarantee.id === guarantee.id">-->
                <!--                              <template v-if="pg">-->
                <!--                                <template v-if="!pg.isOptional">-->
                <!--                                  <i class="fad fa-shield-check text-success fs-2"></i>-->
                <!--                                </template>-->
                <!--                                <template v-else-if="guarantee.method">-->
                <!--                                  <template v-if="getOptionalPremium(result, guarantee)">-->
                <!--                                    <template v-for="optional in result.optionals">-->
                <!--                                      <div class="d-flex justify-content-center align-items-center w-100"-->
                <!--                                           v-if="optional.code === guarantee.method.code">-->
                <!--                                        &lt;!&ndash;                                                                                    <label for="">&ndash;&gt;-->
                <!--                                        &lt;!&ndash;                                                                                        {{optional.append}}&ndash;&gt;-->
                <!--                                        <label class="fw-bolder cursor-pointer text-primary text-decoration-underline"-->
                <!--                                               v-if="!pg.selected">-->
                <!--                                          <input :key="helper.generateId()" @change="toggleOptional(result, pg)"-->
                <!--                                                 class="hidden" type="checkbox" v-model="pg.selected">-->
                <!--                                          <i class="fad fa-plus-circle text-primary"></i>-->
                <!--                                          {{-->
                <!--                                            helper.currencyFormat(optional.premium)-->
                <!--                                          }}-->
                <!--                                        </label>-->
                <!--                                        <div class="d-flex w-100 justify-content-center position-relative" v-else>-->
                <!--                                          <i class="fad fa-shield-check text-success fs-2"></i>-->
                <!--                                          <label-->
                <!--                                              class="fw-bolder cursor-pointer text-primary text-decoration-underline position-absolute d-flex align-items-center"-->
                <!--                                              style="right:0;height:100%;">-->
                <!--                                            <input :key="helper.generateId()" @change="toggleOptional(result, pg)"-->
                <!--                                                   class="hidden" type="checkbox" v-model="pg.selected">-->
                <!--                                            <i class="fad fa-times-circle cursor-pointer text-danger float-end"></i>-->
                <!--                                          </label>-->
                <!--                                        </div>-->
                <!--                                        &lt;!&ndash;                                                                                    </label>&ndash;&gt;-->
                <!--                                      </div>-->
                <!--                                    </template>-->
                <!--                                  </template>-->
                <!--                                  <template v-else>-->
                <!--                                    <div class="d-flex w-100 justify-content-center position-relative">-->
                <!--                                      <i class="fad cursor-pointer fa-exclamation-circle text-warning fs-2"-->
                <!--                                         title="Les conditions requises ne sont pas remplie"></i>-->

                <!--                                      <label-->
                <!--                                          class="fw-bolder cursor-pointer text-primary text-decoration-underline position-absolute d-flex align-items-center"-->
                <!--                                          style="right:0;height:100%;" v-if="pg.selected">-->
                <!--                                        <input :key="helper.generateId()" @change="toggleOptional(result, pg)"-->
                <!--                                               class="hidden" type="checkbox" v-model="pg.selected">-->
                <!--                                        <i class="fad fa-times-circle cursor-pointer text-danger float-end"></i>-->
                <!--                                      </label>-->
                <!--                                    </div>-->
                <!--                                  </template>-->
                <!--                                </template>-->
                <!--                                <template v-else>-->
                <!--                                  <i class="fad cursor-pointer fa-exclamation-circle text-warning fs-2"-->
                <!--                                     title="Methode de calcul manquante"></i>-->
                <!--                                </template>-->
                <!--                              </template>-->
                <!--                              <template v-else>-->
                <!--                                <i class="fad fa-times-circle fs-2 text-gray-400"></i>-->
                <!--                              </template>-->
                <!--                            </div>-->
                <!--                          </template>-->
                <!--                        </template>-->
                <!--                        <template v-else>-->
                <!--                          <i class="fad fa-times-circle fs-2 text-gray-400" title="Garantie non couverte"></i>-->
                <!--                        </template>-->
                <!--                        <div :set="pg = result.pack.hasGuarantee(guarantee)"-->
                <!--                             class="text-center d-flex justify-content-center">-->
                <!--                        </div>-->
                <!--                      </td>-->
                <!--                    </template>-->
                <!--                  </tr>-->
                <!--                </template>-->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>

    </div>


    <transition name="slide-left-back">
      <div class="position-fixed bg-white p-5 shadow-lg top-0 h-100 d-flex" style="right:0"
           v-if="packDetails && hasRole('ROLE_MANAGER')">
        <div>
          <div class="d-flex justify-content-between align-items-center">
            <h1 class="mb-0">{{ trans('Détails de la cotation') }}</h1>
            <div class="btn btn-danger btn-xs ms-5 btn-icon" @click="hideDetails">
              <i class="fad fa-times"></i>
            </div>
          </div>
          <div class="mt-5">
            <div>
              <img :src="uploadRoot + packDetails.insurer.photo" :alt="packDetails.insurer.label" class="mh-50px">
            </div>
            <div class="my-5 fw-bolder">
              {{ packDetails.insurer.label }}
            </div>
            <div>
              <template v-for="detail,index in packDetails.details">
                <ComparisonOfferDetailLine :detail="detail" :key="'detail_'+index" v-on:addSub="addSubDetails"/>
              </template>
              <div>

                <h2>Backend</h2>
                <table class="table table-striped">
                  <tbody>
                  <tr v-for="detail in backendDetails">
                    <td class="p-2">
                      {{ detail.name }}
                    </td>
                    <td class="text-end p-2">
                      {{ helper.currencyFormat(detail.value) }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="bg-dark px-5">
                      Premium Details
                    </td>
                  </tr>
                  <tr v-for="detail in backendPremiumdetails">
                    <td class="p-2">
                      {{ detail.label }}
                    </td>
                    <td class="text-end p-2">
                      {{ helper.currencyFormat(detail.value) }}
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
        <div class="p-10 ms-10 position-relative" style="border-left:1px whitesmoke solid"
             v-for="(sub,index) in subDetails"
             :key="sub.method+'-'+sub.uuid">
          <div class="btn btn-danger btn-xs ms-5 btn-icon position-absolute" style="right:0;top:0"
               @click="closeDetails(index)">
            <i class="fad fa-times"></i>
          </div>
          <div>
            <div class="fw-bolder fs-2x text-uppercase">
              {{ sub.item }}
            </div>
            <div class="fw-bolder fs-2x text-uppercase">
              {{ sub.alias }}
            </div>
            <div>
              <template v-for="detail,index in sub.details">
                <ComparisonOfferDetailLine :detail="detail" :key="'detail_'+index" v-on:addSub="addSubDetails"/>
              </template>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-left-back">
      <div class="position-fixed bg-white shadow-lg top-0 h-100" style="right:0;z-index:2"
           v-if="showComparisonDetails && hasRole('ROLE_MANAGER')">
        <div class="d-flex justify-content-between align-items-center  p-5">
          <h1 class="mb-0">{{ trans('Détails de la comparaison') }}</h1>
          <div class="btn btn-danger btn-xs ms-5 btn-icon" @click="showComparisonDetails = false">
            <i class="fad fa-times"></i>
          </div>
        </div>
        <div class="">
          <div v-for="insurer in comparisonResume"
               style="border-top:1px whitesmoke solid;border-bottom:1px whitesmoke solid">
            <label :for="'insurer-accordion-' + insurer.uuid"
                   class="fw-bolder py-2 px-5 w-100 cursor-pointer  bg-hover-light-primary">
              <i class="fad fa-caret-right me-1"></i>
              {{ insurer.label }}
            </label>
            <input type="checkbox" :id="'insurer-accordion-' + insurer.uuid" :name="'insurer-accordion-' + insurer.uuid"
                   class="checkbox-accordion"/>
            <div class="accordion">
              <div v-for="pack in insurer.packs">
                <label :for="'pack-accordion-' + pack.uuid"
                       class="fw-bolder py-1 w-100 px-5 ps-10 cursor-pointer bg-hover-light-primary">
                  <i class="fad fa-caret-right me-1"></i>
                  {{ pack.label }}
                </label>
                <input type="checkbox" :id="'pack-accordion-' + pack.uuid" :name="'pack-accordion-' + pack.uuid"
                       class="checkbox-accordion"/>
                <div class="accordion ps-15">
                  <table class="table m-0 table-stripped">
                    <template v-for="detail in pack.details.details">
                      <tr class="fw-bolder py-1 cursor-pointer bg-hover-light-primary">
                        <td class="py-1 px-5">
                          {{ detail.name }}
                        </td>
                        <td class="text-end px-5" style="text-align: right;padding-left:100px">
                          {{ detail.value }}
                        </td>
                      </tr>
                    </template>
                  </table>

                </div>

              </div>
            </div>
          </div>
          <div>
            <!-- <img :src="uploadRoot + packDetails.pack.insurer.photo" :alt="packDetails.pack.insurer.label" class="mh-50px"> -->
          </div>
          <div class="my-5 fw-bolder">
            <!-- {{ packDetails.pack.insurer.label }} ▪️ {{ packDetails.pack.label }} -->
          </div>
          <div>
            <table class="table table-striped">
              <tbody>
              <!-- <tr v-for="detail in packDetails.details">
            <td class="p-2">
                {{ detail.name }}
            </td>
            <td class="text-end p-2">
                {{ helper.currencyFormat(detail.value) }}
            </td>
        </tr> -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </transition>

  </div>
  <!--  </transition>-->
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Branch from "@/entity/Branch";
import {helper} from '@/services/Helper';
import Guarantee from "@/entity/Guarantee";
import ResponseGroup from "@/entity/ResponseGroup";
import {mapper} from "@/Utils/Mapper";
import {api} from "@/services/Api";
import Popup from "@/entity/Popup";
import Item from "@/entity/Item";
import Insurer from "@/entity/Insurer";
import Pack from "@/entity/Pack";
import ItemType from "@/entity/ItemType";
import PackGuarantee from "@/entity/PackGuarantee";
import ItemGuarantee from "@/entity/ItemGuarantee";
import Property from "@/entity/Property";
import Survey from "@/entity/Survey";
import PropertyChoice from "@/entity/PropertyChoice";
import Question from '@/entity/Question';
import Step from '@/entity/Step';
import PremiumDetail from "@/entity/PremiumDetail";
import {WORKFLOWS} from "@/Utils/constant";
import {auth} from "@/services/Auth";
import ComparisonOfferDetailLine from "@/views/Comparator/Components/ComparisonOfferDetailLine.vue";

@Component({
  components: {ComparisonOfferDetailLine},
  computed: {},
  methods: {}
})
export default class InsurerOffer extends Vue {
  [x: string]: any;

  getRemainingCertificates(insurer: Insurer) {
    let remaining = {
      green: 0,
      blue: 0,
      yellow: 0
    }
    // console.log('get remaining ' + insurer.label)

    for (let data of this.certificateRemaining) {
      if (insurer.uuid === data.uuid) {
        remaining.blue = data.eAttestationCountBlue
        remaining.green = data.eAttestationCountGreen
        remaining.yellow = data.eAttestationCountYellow
        // console.log(remaining.yellow)
        return remaining
      }
    }
    return remaining
  }


  canSubscribe(result: any) {
    if (result && result.insurer && result.insurer.slug) {
      return true
    }
    for (let data of this.certificateRemaining) {
      if (result.pack.insurer.uuid === data.uuid) {
        if (this.attestations.blue > 0) {
          if (this.attestations.blue > data.eAttestationCountBlue) {
            return false
          }
        }
        if (this.attestations.yellow > 0) {
          if (this.attestations.yellow > data.eAttestationCountYellow) {
            return false
          }
        }

        if (this.attestations.green > 0) {
          if (this.attestations.green > data.eAttestationCountGreen) {
            return false
          }
        }

        return true
      }
    }
    return false
  }

  calculUuid = helper.generateId()
  @Prop() question!: Question
  @Prop() branch!: Branch
  @Prop() rg!: ResponseGroup
  @Prop() survey!: Survey
  @Prop() currentStep!: Step
  uploadRoot = api.uploadRoot
  results: any[] = []
  oldResults: any[] = []
  items: Item[] = []
  invalid = false
  packs: Pack[] = []
  allItems: Item[] = []
  timeout: any = null

  showComparisonDetails = false
  resultDetail: any = null
  isMobile = false
  compared = false
  certificateRemaining: any[] = []

  backendDetails: any[] = []
  backendPremiumdetails: any[] = []


  checkDesign() {
    const width = document.body.clientWidth
    this.isMobile = width < 1000
  }

  getReductionAmount(result: any) {
    let premium = 0
    result.premiumDetails.forEach((pd: any) => {
      if (pd.model.isReduction) {
        premium += pd.premium
      }
    })
    return premium
  }

  showDetails(result: any) {

    this.packDetails = this.results.find((r: any) => {
      return r.insurer.id === result.insurer.id
    })
    // console.log(result)
    // return
    // this.resultDetail = result
    // this.loadDebug()
  }

  subDetails: any[] = []

  addSubDetails(detail: any) {
    const match = this.subDetails.find((s: any) => {
      return s.uuid === detail.uuid && s.method === detail.method
    })
    if (match) {
      return
    }
    this.subDetails.push(detail)
  }

  closeDetails(index: any) {
    this.subDetails.splice(index, 1)

  }

  packDetails = null
  // get packDetails() {
  //   if (this.resultDetail) {
  //     const match = this.results.find((r: any) => {
  //       return r.insurer.id === this.resultDetail.insurer.id
  //     })
  //     return match
  //   }
  //   return null
  // }

  hideDetails() {
    this.packDetails = null
    this.resultDetail = null
    this.subDetails = []
  }

  async mounted() {
    // console.log(this.rg)
    // console.log('comparison mounted')
    this.loadCountCertificate()
    this.checkDesign()
    window.addEventListener('resize', this.checkDesign)
    this.timeout = setTimeout(this.updateRowHeight, 200)
    this.map()
    // this.setPackSelected()
    // this.connectRealTimeAttestationCount()
  }

  beforeUnmount() {
    // console.log('unmount')
    // if (this.eventSource instanceof EventSource) {
    //   this.eventSource.close()
    // }
    window.removeEventListener('resize', this.checkDesign)
  }

  async loadDebug() {
    const res = await api.get(api.form, 'order/debug/' + this.rg.uuid)
    if (res && res.data && res.data.data && res.data.data.data) {
      this.backendDetails = res.data.data.data.details
      this.backendPremiumdetails = res.data.data.data.premiumDetails
    }
  }

  async loadCountCertificate() {
    const res = await api.get(api.core, 'insurer/certificate/list')
    this.certificateRemaining = res.data.remaining
  }

  packSelected: any = null

  setPackSelected() {
    if (this.question.response.value) {
      const data = JSON.parse(this.question.response.value)
      let result = this.results.find((r: any) => {
        return r.insurer.slug === data.insurer.slug
      })
      if (result) {
        result = this.results.find((r: any) => {
          return r.insurer.slug === data.insurer.slug
        })
        if (result) {
          this.packSelected = result
          return result;
        }
      }
    }
    this.packSelected = null
    return null;
  }

  eventSource: any = null


  async connectRealTimeAttestationCount() {
    const baseUrl = api.mercure;
    const hubUrl = new URL(baseUrl);
    hubUrl.searchParams.append('topic', '/comparisons');
    // console.log(hubUrl.toString())
    this.eventSource = new EventSource(hubUrl.toString());

    this.eventSource.onerror = (e: any) => {
      console.log("An error occurred while attempting to connect.");
      this.eventSource.close()
    };
    this.eventSource.onmessage = (event: any) => {
      var data = JSON.parse(event.data);
      if (data.hasOwnProperty('updates')) {
        let updates = data.updates
        // console.log(updates);
        if (updates.hasOwnProperty('used') && updates.hasOwnProperty('insurer')) {
          for (let data of this.certificateRemaining) {
            if (data.uuid === updates.insurer) {
              // console.log(data)
              if (updates.used.yellow > 0) {
                data.eAttestationCountYellow--
              }
            }
          }
        }
      }
    }
  }

  async subscribe(result: any) {
    this.$store.commit('loading')
    const data = {
      pack: {
        id: null,
        code: null
      },
      insurer: {
        id: result.insurer.id,
        slug: result.insurer.slug
      },
      optionals: [] as any[]
    }
    const optionals: any[] = []
    // result.pack.packGuarantees.forEach((pg: PackGuarantee) => {
    //   if (pg.isOptional && pg.selected) {
    //     data.optionals.push(pg.guarantee.id)
    //   }
    // })
    // console.log(this.question.response)
    // console.log(data)
    this.question.response.value = JSON.stringify(data)
    await this.question.updateAnswer()

    // if (this.isLastStep()) {
    // return;

    const res = await api.get(api.form, 'api/make/order/' + this.rg.uuid)
    if (res && res.data && res.data.order) {
      this.$store.commit('appendOrder', res.data.order)
      if (this.currentItemType && this.currentItemType!.isRoot) {
        this.$router.push({name: 'order-view', params: {uuid: res.data.order.uuid}});
      }
    }
    // }
    // this.setPackSelected()
    this.$store.commit('stopLoading')
  }

  isLastStep() {
    let currentRanking = this.currentStep.ranking + 1
    for (let step of this.survey.steps) {
      if (step.ranking > currentRanking) {
        if (step.isVisible()) {
          return true
        }
      }
    }
    return false;
  }

  @Watch('$store.state.rgStatus')
  map() {
    // console.log('recalculate')
    this.compare()

  }

  calculating = false

  getDuree() {
    const avenant: any = this.items.find((i: Item) => {
      return i.model.code === 'avenant'
    })
    if (avenant instanceof Item) {
      const prop = avenant.getPropertyByCode('duree')
      const choice = prop.model.choices.find((c: PropertyChoice) => {
        return c.code === prop.value
      })
      return choice ? choice.label : ''
    }
  }


  workflow: any = null;
  attestations = {
    green: 0,
    yellow: 0,
    blue: 0
  }

  comparisonResume: any[] = []
  value = {
    min: 0,
    max: 0
  }

  ttl: any = null
  currentItemType: ItemType | null = null

  compare(set = true, setChildrenPack = true) {
    // if (this.$store.state.rgStatus === null) {
    //   console.log('init')
    //   return
    // }
    let valid = true
    this.survey.steps.forEach((step: Step) => {
      if (step.ranking < this.currentStep.ranking) {
        step.validate()
        if (!step.isValid) {
          valid = false
        }
      }
    })
    if (!valid) {
      this.invalid = true
      this.results = []
      return
    }
    this.invalid = true
    this.calculating = true
    // console.log('data is valid, comparison start')
    const mapped = mapper.map(this.rg)
    this.workflow = mapper.workflow
    this.attestations = mapper.attestations
    this.items = mapped.items
    let classes = this.$store.state.engineClasses
    if (!classes) {
      classes = window['classes' as any]
      if (classes) {
        this.$store.state.engineClasses = classes
      }
    }
    if (!classes) {
      console.error('Engine Missing')
      return
    }
    const insurers = this.$store.state.insurers
    const items = mapped.items
    let mainItem = mapped.item
    if (!mainItem) {
      new Popup('Erreur', 'Le formulaire ' + this.survey.label + ' n\'est relié à aucun objet à comparer', 'danger', 'fad fa-bug')
      return
    }
    if (!mainItem.model.method) {
      new Popup('Erreur', 'Aucune methode de calcul définie pour ' + mainItem.name)
      return
    }
    this.currentItemType = mainItem.model
    this.results = []
    if (classes) {
      // console.log('setter')
      this.comparisonResume = []
      insurers.forEach((i: Insurer) => {
        if (!i.active) {
          return
        }
        const engine: any = classes.find((c: any, index: any) => {
          return c.code === i.slug
        })
        if (!engine) {
          new Popup('Calcul Manquant', 'Avenant Affaire Nouvelle assureur ' + i.label)
          return
        }
        const insurerEngine: any = new engine.prototype()
        const keys = Object.keys(insurerEngine)
        items.forEach((i: Item) => {
          keys.forEach((k: string) => {
            if (i.model.code === k) {
              const data = classes.find((c: any) => {
                return c.code === k
              })
              if (!data.prototype) {
                console.error(i.model.code + ' manquant')
              }
              const prototype = new data.prototype(i)
              prototype.engine = insurerEngine
              insurerEngine[k] = prototype
            }
          })
        })
        if (this.rg.godfather) {
          insurerEngine.godfather = this.rg.godfather
        }
        // console.log(insurerEngine)
        // return
        // console.log(insurerEngine.racine.assure.length+' assurés')
        // console.log(' Method : ' + mainItem.model.method!.code)
        const insurerResume = {
          'label': i.label,
          uuid: i.uuid,
          packs: [] as any[],
          errors: [] as any[],
        };
        // console.log(mainItem)
        // console.log('-----------------------')
        // console.log(i.label)
        this.showMePacks(mainItem)

        if (this.missingPack.length > 0) {
          return
        }
        // return
        if (typeof insurerEngine[mainItem.model.method!.code] === 'function') {
          const item = new Item(mainItem.simplify())
          const basePremium = insurerEngine[mainItem.model.method!.code](item)
          if (basePremium && !isNaN(basePremium)) {
            let result = {
              basePremium: basePremium,
              premium: basePremium,
              details: [...insurerEngine.details],
              expand: false,
              insurer: i,
              premiumDetails: []
            }
            this.results.push(result)
          }
          // console.log(insurerEngine.details)
          // console.log(basePremium)
          return
          // clearTimeout(this.ttl)
          // this.ttl = setTimeout(this.updateStatus, 500)
        } else {
          insurerResume.errors.push({
            message: 'Impossible de calculer la prime de ' + mainItem.name + ' car la fonction "' + mainItem.model.method!.code + '" est introuvable',
          });
        }
        this.comparisonResume.push(insurerResume)
      })

      this.results.sort((a: any, b: any) => {
        return a.basePremium - b.basePremium
      })
      this.updateRowHeight()
    }
    // console.log(this.results)
    this.oldResults = [...this.results];
    // console.log(this.results)
    this.calculating = false
  }

  missingPack: Item[] = []
  missingType = ''

  showMePacks(item: Item, res = null) {
    // console.log(item)
    if (!res) {
      this.missingPack = []
      this.missingType = ''
      res = helper.generateId()
    }
    let pack = null;
    if (item.model.className === 'Asset') {
      const value = item.getPropertyByCode('pack').value
      if (value) {
        const data = JSON.parse(item.getPropertyByCode('pack').value)
        if (data && data.category && data.category.slug) {
          pack = data
        }
      }
      if (!pack) {
        // console.log('Pack Manquant pour ' + item.alias)
        const exist = this.missingPack.findIndex((i) => {
          return i.uuid === item.uuid
        })
        if (exist === -1) {
          this.missingPack.push(item)
          this.missingType = item.model.name
        }
      }
    }
    item.properties.forEach((p: Property) => {
      if (p.model.type.code === 'OBJECT' || p.model.type.code === 'OBJECT_GROUP') {
        p.children.forEach((c: Item) => {
          this.showMePacks(c, res)
        })
      }
    })
  }

  findItemRg(item: Item, rg: ResponseGroup) {
    rg.responses.forEach((r, index) => {
    })
  }

  updateStatus() {
    clearTimeout(this.ttl)
    api.post(api.form, 'api/compare/' + this.rg.uuid, {value: this.value})
  }

  replaceChild(item: Item, child: Item) {
    // console.log('on fouille ' + item.name)
    item.properties.forEach((p: Property) => {
      p.children.forEach((c: Item, index) => {
        if (c.uuid === child.uuid) {
          // console.log('on a trouve ' + child.name)
          p.children[index] = child
        }
        this.replaceChild(c, child)
      })
    })
  }

  getOptionalPremium(result: any, guarantee: Guarantee) {
    const match = result.optionals.find((o: any) => {
      if (guarantee.method) {
        return o.code === guarantee.method.code
      }
    })
    return match
  }

  async toggleOptional(result: any, packGuarantee: PackGuarantee) {
    // const optionalSelected: PackGuarantee[] = []
    this.oldResults.forEach((old) => {
      if (old.pack.id === result.pack.id) {
        old.pack.packGuarantees.forEach((p: PackGuarantee) => {
          if (p.guarantee.id === packGuarantee.guarantee.id) {
            p.selected = packGuarantee.selected
            if (p.selected) {
              // console.log('on active optionnelle ' + p.guarantee.label)
            } else {
              // console.log('on desactive optionnelle ' + p.guarantee.label)
            }
          }
          // if (p.selected) {
          //     optionalSelected.push(p)
          // }
        })

      }
    })
    // this.optionalSelected = optionalSelected
    // })
    // this.$store.state.recalculated = []
    // console.log('toggle option')
    this.compare(false)
    // this.item.updateChildrenOptionals()
    this.$forceUpdate()
  }

  replaceEngineItemConverted(engine: any, itemConverted: any) {
    let item: Item = itemConverted.item
    // console.log('on veu remplacer ' + item.name + ' ' + item.uuid)
    const entities = Object.keys(engine)
    entities.forEach((k: string) => {
      // console.log('on check la proprieté ' + k)
      const entity = engine[k]
      if (entity.item) {
        // console.log(entity.item.uuid + ' === ' + item.uuid)
        // console.log(entity)
        if (item.uuid === entity.item.uuid) {
          // console.log('replace main object ' + entity.item.name)
          engine[k].item = item
        }

        this.replaceChild(entity.item, item)
      }
      // console.log(entity)
      // const itemProps = Object.keys(entity)
    })
  }

  loadEngine() {

  }

  updated(): void {
    this.timeout = setTimeout(this.updateRowHeight, 200)
  }

  updateRowHeight() {
    clearTimeout(this.timeout)
    this.timeout = null
    // console.log('update row')
    const offers = document.querySelectorAll('.offers')
    offers.forEach((offer: any) => {
      const heads = offer.querySelectorAll('.offers th')

      const rows = offer.querySelectorAll('.offers .results tbody tr')
      const equiRows = offer.querySelectorAll('.offers .guarantee tbody tr')
      // const columns = offer.querySelectorAll('.offers td')
      let max = 0
      heads.forEach((g: HTMLElement) => {
        g.style.height = 'auto'
        if (g.getBoundingClientRect().height > max) {
          max = g.getBoundingClientRect().height
        }
      })
      heads.forEach((head: HTMLElement) => {
        head.style.height = max + 'px'
      })

      rows.forEach((row: HTMLElement, rowIndex: number) => {
        max = 0
        const columns = row.querySelectorAll('td')
        columns.forEach((column: HTMLElement) => {
          column.style.height = 'auto'
          if (column.getBoundingClientRect().height > max) {
            max = column.getBoundingClientRect().height
          }
        })
        const equiRow = equiRows[rowIndex]
        const equiCol = equiRow.querySelectorAll('td')

        equiCol.forEach((ec: HTMLElement) => {
          if (ec.getBoundingClientRect().height > max) {
            max = ec.getBoundingClientRect().height
          }
        })

        columns.forEach((column: HTMLElement) => {
          column.style.height = max + 'px'
        })


        equiCol.forEach((ec: HTMLElement) => {
          ec.style.height = max + 'px'
        })

      })

    })
  }

  onePackHasGuarantee(guarantee: Guarantee) {
    let match = false
    this.results.forEach((r: any) => {
      if (r.pack.hasGuarantee(guarantee)) {
        match = true
      }
    })
    return match
  }

  allPackHasGuarantee(guarantee: Guarantee, pack: Pack) {
    let match = true
    this.results.forEach((r: any) => {
      if (!r.pack.hasMandatoryGuarantee(guarantee)) {
        match = false
      }
    })
    return match
  }
}
</script>
<style scoped>
.accordion {
  max-height: 0;
  overflow: hidden;
}

.checkbox-accordion {
  display: none;
}

.checkbox-accordion:checked + .accordion {
  max-height: none;
}

.offers tbody tr:nth-child(2n+1) {
  background: rgba(61, 218, 215, 0.21);
}

.results td,
.results th {
  min-width: 200px;
}

.results-container {
  position: relative;
  /*border: 1px red solid;*/
}

.results-container:before,
.results-container:after {
  content: '';
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  /*background: rgb(2,0,36);*/
  background: linear-gradient(-90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 75%);

}

.results-container:after {
  left: auto;
  right: 0;
  background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 75%);
}

.results td {
  border-right: 10px white solid;
  /*background: rgba(255, 255, 255, 0.21);*/
}
</style>
