<template>
  <div class=" MultipleSurvey w-100 m-auto" :key="'question_'+question.uuid">
    <div class="d-flex align-items-center justify-content-between p-0 mb-5">
      <label class="px-5 cursor-pointer d-flex flex-grow-1 align-items-center">
        <input type="checkbox" v-model="expand" class="hidden">
        <i class="fad fa-angle-right fs-2x" v-if="!expand"></i>
        <i class="fad fa-angle-down fs-2x" v-else></i>

        <div class="fs-2x fw-bolder flex-grow-1 text-start ms-5">
          {{ question.label }} ({{ question.response.responseGroups.length }})
        </div>
      </label>


      <div>
        <button :data-kt-indicator="creatingResponseGroup ? 'on' : 'off'" :disabled="creatingResponseGroup"
                @click="createSubResponseGroup"
                v-if="empty(question.max) || question.response.responseGroups.length < question.max"
                class="btn btn-sm btn-primary">
                <span class="indicator-label">
                    <i class="fad fa-plus"></i>
                    {{ trans('Ajouter') }}
                </span>
          <span class="indicator-progress">
                    {{ trans('Ajout en cours ...') }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>

        </button>

        <button class="btn btn-sm btn-danger ms-5" @click="deleleAllSubRgs">
          <i class="fad fa-trash"></i>
          {{ trans('Supprimer') }}
        </button>
        <template v-if="question.allowBroadcast">

          <b-button :disabled="creatingResponseGroup" :id="'broadcast_list_'+question.uuid"
                    class="btn btn-sm btn-danger ms-3" v-b-modal="'broadcast_'+question.uuid">
            {{ trans('Charger une liste de mail') }}
          </b-button>
        </template>
      </div>
    </div>
    <div class="ms-5" v-if="expand">
      <div class="card" v-if="!question.isInline">
        <div class="card-body">
          <div class="table-responsive">
            <!--begin::Table-->
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <!--begin::Table head-->
              <thead>
              <tr class="fw-bolder text-muted">
                <th class="w-25px">
                  <div class="form-check form-check-sm form-check-custom form-check-solid">
                    <input class="form-check-input" data-kt-check="true" data-kt-check-target=".widget-9-check"
                           type="checkbox" value="1">
                  </div>
                </th>
                <th class="min-w-150px">{{ trans('Alias') }}</th>
                <!--                            <th v-if="question.allowMultiplicator">{{trans('Nombre')}}</th>-->
                <th class="min-w-140px">{{ trans('Contributeurs') }}</th>
                <th class="min-w-140px">{{ trans('Dernière consultation') }}</th>
                <th class="min-w-120px">{{ trans('Etat') }}</th>
                <th v-if="isAsset">{{ trans('Offre') }}</th>
                <th class="min-w-100px text-end">Actions</th>
              </tr>
              </thead>
              <tbody>
              <template v-if="question.response">
                <tr v-for="rg,index in question.response.responseGroups" :key="rg.uuid" :id="'rg_'+rg.uuid">
                  <td>
                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                      <input class="form-check-input" type="checkbox" value="1">
                    </div>
                  </td>
                  <td>
                    <div class="input-container flex-grow-1 rounded  d-flex">
                      <input @change="updateAlias(rg)" @key.enter="question.updateAnswer()"
                             class="form-control form-control-solid"
                             type="text"
                             v-model="rg.alias">
                      <span :title="trans('Mise à jour en cours')" class="input-status" v-b-tooltip.hover
                            v-if="updatingAlias">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
                      <span class="input-status" v-else-if="!aliasValid">
                                                            <i class="fad fa-exclamation-circle text-danger"></i>
                                                        </span>
                      <span class="input-status" v-else-if="aliasValid">
                                                            <i class="fad fa-check-circle text-success"></i>
                                                        </span>
                    </div>
                    <div>

                    </div>
                  </td>
                  <!--                                <td v-if="question.allowMultiplicator">-->
                  <!--                                    <input-number :min="1"></input-number>-->
                  <!--                                </td>-->
                  <td>
                    <input class="form-control form-control-solid" type="text">
                  </td>
                  <td class="text-center">
                    <template v-if="rg.lastVisit">
                      {{ helper.readable(rg.lastVisit) }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </td>

                  <td>
                                <span :class="[{
                                'badge-warning' : rg.state < 100,
                                'badge-success' : rg.state >= 100
                                }]"
                                      class="badge badge-lg">
                                {{ rg.state }}%
                                    </span>
                  </td>
                  <td v-if="isAsset">
                    <div class="fw-bolder text-primary text-decoration-underline">
                      {{ getoffer(rg) }}
                    </div>
                  </td>
                  <td class="text-end">
                    <!--                                    <div class="d-flex flex-column">-->
                    <router-link target="_blank" :title="trans('Formulaire')"
                                 :to="{ name : 'response-group-manager',
                                                 params: {surveySlug: rg.survey.slug, rgUuid: rg.uuid}}"
                                 @click.native="$emit('reset')"
                                 class="btn btn-sm btn-icon btn-primary m-1" v-b-tooltip.hover>
                      <i class="fad fa-external-link"></i>
                      <!--                                            {{trans('Formulaire')}}-->
                    </router-link>
                    <button :disabled="creatingResponseGroup" :title="trans('Supprimer')"
                            @click="deleteSubResponseGroup(rg)" class="btn btn-sm btn-icon btn-danger m-1"
                            v-b-tooltip.hover>
                      <i class="fad fa-trash"></i>
                      <!--                                            {{trans('Supprimer')}}-->
                    </button>
                    <!--                                    </div>-->
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>


        </div>
      </div>
      <template v-else v-for="(rg,index) in question.response.responseGroups">
        <div class="d-flex align-items-center border-top py-5">
          <label class="px-5 cursor-pointer d-flex align-items-center flex-grow-1">
            <input type="checkbox" v-model="rg.expand" class="hidden">
            <i class="fad fa-angle-right fs-2x" v-if="!rg.expand"></i>
            <i class="fad fa-angle-down fs-2x" v-else></i>

            <div class="ms-5 fs-2 fw-bolder">
              <template v-if="rg.alias">
                {{ rg.alias }}
              </template>
              <template v-else>
                {{ question.label }} N°{{ index + 1 }}
              </template>
            </div>
          </label>
          <button @click="deleteSubResponseGroup(rg)" class="btn btn-sm btn-danger m-0">
            <i class="fad fa-trash"></i>
            {{ trans('Supprimer') }}
          </button>
        </div>
        <template v-if="rg.expand">
          <!--        <div class="ms-10">-->
          <ResponseGroupManager :key="'rgKey_'+rg.uuid" :melted="melted" :name="'rg_'+rg.uuid" :uuid="rg.uuid"/>
          <!--        </div>-->
        </template>
      </template>
    </div>
    <b-modal :id="'broadcast_'+question.uuid" :ref="'broadcast_'+question.uuid" :title="trans('Diffusion par mail')"
             centered size="lg">
      <form>
        <div class="fw-bolder d-flex justify-content-center my-5 fs-2">
          Envoyer un lien à une liste d'addresse e-mail
        </div>
        <div class="d-flex justify-content-center my-5 fw-bolder">
          <ul>
            <li>
              <i class="fad fa-info-circle me-2"></i> Chaque addresse email pourra créer une seule occurence de
              <b>"{{ question.label }}"</b>
            </li>
            <li>
              <i class="fad fa-info-circle me-2"></i> Vous serez notifié à la création de chaque nouvelle occurence
            </li>
            <li>
              <i class="fad fa-info-circle me-2"></i> Vous serez autorisé à consulter/modifier les formulaires remplis
            </li>
          </ul>
        </div>
        <div class="text-center" v-if="isExcel">
          <div class="text-center">
            <h4>
              {{ trans('Format Excel') }}
            </h4>
            <div class="w-250px h-250px m-auto">
              <img alt="" class="object-contain w-100 h-100" src="/images/email_list_template.jpg">
            </div>
          </div>
          <div class="d-flex flex-column align-items-center justify-content-center mt-5">
            <b-button class="mb-5" size="sm" variant="primary">
              <i class="fad fa-save"></i>
              {{ trans('Selectionnez un fichier') }}
            </b-button>
            <label class="btn btn-sm btn-secondary">
              <input class="hidden" type="checkbox" v-model="isExcel">
              <i class="fad fa-file-excel"></i>
              {{ trans('Importer depuis un texte') }}
            </label>

          </div>
        </div>
        <div class="d-flex flex-grow-1 justify-content-center flex-wrap" v-else>
          <div class="flex-grow-1">
            <div class="d-flex align-items-center justify-content-between mt-2 p-0">
              <div>
                Entrez la liste d'addresse Email
              </div>
              <div>
                <div @click="addEmail" class="btn btn-sm btn-icon btn-primary">
                  <i class="fad fa-plus"></i>
                </div>
              </div>
            </div>
            <div class="d-flex flex-wrap border-dashed px-5 mh-350px overflow-auto  mt-2">
              <table class="table table-striped ">
                <thead>
                <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Email</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr :key="'tag-'+index" class="col-auto m-2" v-for="(mail,index) in mailList">
                  <td>
                    <b-input-group class="mw-350px">
                      <input class="form-control" type="text" v-model="mail.label">
                    </b-input-group>
                  </td>
                  <td></td>
                  <td>
                    <div class="d-flex justify-content-end">
                      <div @click="removeEmail(email)" class="btn btn-xs btn-icon btn-danger">
                        <i class="fad fa-trash"></i>
                      </div>
                      <div>
                        <i class="fad fa-paper-plane btn btn-icon btn-xs btn-primary mx-2"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!--                        <p>-->
            <!--                            {{trans('Séparer les addresses par des virgules(,)')}}-->
            <!--                        </p>-->
            <!--                        <div>-->
            <!--                            <b-form-textarea class="form-control" id="" name="" rows="3"></b-form-textarea>-->
            <!--                        </div>-->

            <div class="d-flex align-items-center justify-content-center mt-5">

              <b-button class="m-5" size="sm" variant="primary">
                <i class="fad fa-paper-plane"></i>
                {{ trans('Diffuser') }}
              </b-button>
              <label class="btn btn-sm btn-secondary">
                <input class="hidden" type="checkbox" v-model="isExcel">
                <i class="fad fa-file-excel"></i>
                {{ trans('Importer un fichier Excel') }}
              </label>
            </div>
          </div>
        </div>

      </form>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel()" size="sm" variant="danger">
          <i class="fad fa-times"></i>
          {{ trans('Cancel') }}
        </b-button>
        <!--                <b-button  size="sm" variant="primary">-->
        <!--                    <i class="fad fa-save"></i>-->
        <!--                    {{trans('Selectionnez un fichier')}}-->
        <!--                </b-button>-->
      </template>
    </b-modal>
    <!--        <FormProcessor></FormProcessor>-->
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Question from "@/entity/Question";
import ResponseGroup from "@/entity/ResponseGroup";
import {api} from "@/services/Api";
import Response from "@/entity/Response";
import Popup from "@/entity/Popup";
import InputNumber from "@/components/InputNumber.vue";
import {helper} from "@/services/Helper";
import Survey from "@/entity/Survey";
import Branch from "@/entity/Branch";
import ItemType from "@/entity/ItemType";
import PackCategory from "@/entity/PackCategory";
import PackCategoryGuarantee from "@/entity/PackCategoryGuarantee";
import Swal from "sweetalert2";
// import ResponseGroupManager from "@/views/Comparator/Components/ResponseGroupManager.vue";
// import FormProcessor from "@/views/Income/Comparator/FormProcessor.vue";

@Component({
  name: 'MultipleSurvey',
  components: {InputNumber}
})
export default class MultipleSurvey extends Vue {
  [x: string]: any;

  @Prop() question!: Question
  @Prop() responseGroup!: ResponseGroup
  @Prop() survey!: Survey
  @Prop() branch!: Branch
  creatingResponseGroup = false
  @Prop() melted?: boolean
  mailList: any[] = []
  slug = ''
  isExcel = false
  expand = false
  isAsset = false


  async mounted() {
    // console.log('************* multiple survey '+this.question.label + ' ' + this.question.response.responseGroups.length+' **********')
    // console.log(this.question.label)
    // // console.log(this.survey)
    // console.log(this.question.response.responseGroups)
    // if (this.survey.item) {
    //   const item = this.branch.items.find((i: ItemType) => {
    //     return i.uuid === this.survey.item
    //   })
    //   if(item){
    //     console.log(item)
    //   }
    // }
    // console.log(this.question.response)
    // for (let rg of this.question.response.responseGroups) {
    //   console.log(rg.survey)
    // }
    // console.log(this.question.response.responseGroups)
    // console.log(this.responseGroup)
    // console.log(this.question.response.uuid)
    this.checkItem()
    if (this.question.min && this.question.response.responseGroups.length < this.question.min) {
      const missing = this.question.min - this.question.response.responseGroups.length
      // console.log('min is ' + this.question.min + ' missing ' + missing)
      for (let i = 0; i < missing; i++) {
        // console.log('create sub rg for ' + this.question.label)
        // this.createSubResponseGroup()
      }
    }
  }


  async deleleAllSubRgs() {
    const response = await Swal.fire({
      title: 'Attention',
      html: 'La suppression de cette reponse entrainera la suppression de tou(te)s les "' + this.question.label + '"',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Supprimer'
    })
    if (response.isConfirmed) {
      const data: any = {
        rgs: [] as ResponseGroup[],
        question: this.question
      }
      this.question.response.responseGroups.forEach((rg: any) => {
        data.rgs.push(rg)
      })
      this.$emit('deleteSubResponseGroup', data)
    }
  }

  to: any = null

  @Watch('question.response.responseGroups')
  async responseUpdate() {
    this.to = setTimeout(this.checkItem, 200)
  }

  getoffer(rg: ResponseGroup) {
    // console.log(rg)
    const survey = this.$store.state.surveys.find((s: Survey) => {
      return s.uuid === rg.survey.uuid
    })
    const q = survey.getQuestions().find((q: Question) => {
      return q.type.code === 'GUARANTEE_SELECTOR'
    })
    // console.log('question = ' + q.uuid)
    if (q) {
      const res = rg.responses.find((r: Response) => {
        // console.log(r.questionUuid)
        return r.questionUuid === q.uuid;
      })
      if (res && res.value) {
        const data = JSON.parse(res.value)
        if (data.category && data.category.label && data.optionals) {
          let line = data.category.label
          if (data.optionals.length > 0) {
            line += ' (+' + data.optionals.length + ')';
          }
          return line;
        }

      }
    }
    return null;
  }

  getPackCategory(rg: ResponseGroup) {
    console.log(rg)
    const survey = this.$store.state.surveys.find((s: Survey) => {
      return s.uuid === rg.survey.uuid
    })
    const q = survey.getQuestions().find((q: Question) => {
      return q.type.code === 'GUARANTEE_SELECTOR'
    })
    // console.log('question = ' + q.uuid)
    if (q) {
      const res = rg.responses.find((r: Response) => {
        console.log(r.questionUuid)
        return r.questionUuid === q.uuid;
      })
      if (res && res.value) {
        const data = JSON.parse(res.value)
        if (data.category && data.category.label && data.optionals) {
          let packCategory = this.branch.packCategories.find((pc: PackCategory) => {
            return pc.id === data.category.id
          })
          console.log(packCategory)
          if (packCategory) {
            packCategory = new PackCategory({...packCategory})
            packCategory.packCategoryGuarantees.forEach((pcg: PackCategoryGuarantee) => {
              if (data.optionals.includes(pcg.guarantee.id)) {
                pcg.selected = true
              }
            })
          }
          console.log(packCategory)
          return packCategory
        }

      }
    }
    return null;
  }

  checkItem() {
    clearTimeout(this.to)
    this.to = null
    // console.log('multiple survey response update ' + this.question.label)
    if (this.question.response.responseGroups.length > 0) {
      const rg = this.question.response.responseGroups[0]
      // console.log(rg)
      const survey = this.$store.state.surveys.find((s: Survey) => {
        return (typeof rg.survey === 'string' && s.uuid === rg.survey) || s.uuid === rg.survey.uuid
      })
      // console.log(survey.item)
      if (survey.item) {
        const item = this.branch.items.find((i: ItemType) => {
          return i.uuid === survey.item
        })
        if (item && item.className === 'Asset') {
          this.isAsset = true
        }
      }
    }
  }

  removeEmail(email: string) {

  }

  addEmail() {
    let empty = false
    this.mailList.forEach((m: any) => {
      if (helper.empty(m.label)) {
        empty = true
      }
    })
    // if (!empty) {
    this.mailList.push({label: ''})
    // }
  }

  // async load() {
  //     this.spin = 'on'
  //     const res = await api.get(api.form, 'question/get/survey/linked/' + this.question.uuid)
  //     if (res && res.data && res.data.survey) {
  //         this.branch = res.data.survey.branch
  //         this.slug = res.data.survey.slug
  //     }
  //     this.spin = 'off'
  // }


  updatingAlias = false
  aliasValid = true

  async updateAlias(rg: ResponseGroup) {
    this.updatingAlias = true
    const res = await api.post(api.form, 'api/update/alias', {
      alias: rg.alias,
      uuid: rg.uuid
    })
    this.updatingAlias = false
  }

  async createSubResponseGroup() {
    // console.log(this.question.response.responseGroups)
    if (this.question.max) {
      // console.log(this.question.response.responseGroups.length + ' >= ' + this.question.max + ' = ' + (this.question.response.responseGroups.length >= this.question.max))
      if (this.question.response.responseGroups.length >= this.question.max) {
        new Popup('Erreur', 'Vous avez atteint le nombre maximum autorisé', 'danger')
        return;
      }
      // console.log('re')
    }
    // console.log('create', this.question.max)
    // return
    this.creatingResponseGroup = true
    const data = {rgUuid: this.responseGroup.uuid, questionUuid: this.question.uuid}
    api.createSubGroup(data, (res: any) => {
      this.creatingResponseGroup = false
      if (res && res.data && res.data.responses) {
        this.expand = true
        res.data.responses.forEach((d: any) => {
          const r = d.response;
          if (d.questionUuid === this.question.uuid && d.responseGroupUuid === this.responseGroup.uuid) {
            const index = this.question.response.responseGroups.findIndex((rg: ResponseGroup) => {
              return rg.uuid === r.uuid
            })
            if (index === -1) {
              const occ = new ResponseGroup(r);
              this.question.response.responseGroups.push(occ)
              this.$store.commit('appendRg', occ)
              // console.log('update multiplesurvey')
              this.question.updateAnswer();
              this.$store.commit('responseSaved')
            }
          }
        })
      }
    })
  }

  async deleteSubResponseGroup(rg: ResponseGroup) {
    // console.log('emit delete')
    const response = await Swal.fire({
      title: 'Attention',
      html: 'Voulez vous vraiment supporimer ce contenu ?',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Supprimer'
    })
    if (response.isConfirmed) {
      this.$emit('deleteSubResponseGroup', {rg: rg, question: this.question})
    }
    // this.$store.commit('deleteSubResponseGroup', rg.uuid)
  }
}
</script>
<style scoped>
.input-container {
  position: relative;
}

.input-status {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
