<template>
   <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div @click="resetDataType" class="btn-sm btn btn-primary ms-5"
                 v-b-modal.dataTypeModal>
              <i class="fad fa-plus"></i>
              {{ trans('Ajouter un motif') }}
            </div>

           

          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>

      <div class="card">
        <div class="card-body p-0">
          <table class="table table-striped gy-7 gs-7">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>{{ trans('Motif') }}</th>
              <th>{{ trans('Description') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="reason in reasons">
              <td>{{ reason.label }}</td>
              <td>{{ reason.description }}</td>
              
            </tr>
            </tbody>
          </table>
          <div>

          </div>
        </div>
      </div>
  
        <b-modal :title="trans('Ajouter un motif ')" centered id="dataTypeModal" ref="dataTypeModal">
          <form>
            <div class="row" v-if="reason">
              <div class="col-auto  flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
                <b-form-input :id="'label'" :state="!helper.empty(reason.label)"
                              v-model="reason.label"></b-form-input>
              </div>



              <div class="col-12">
                        <label class="my-4 fw-bolder" for="">{{ trans('Description') }}</label>

                        <textarea class="form-control" v-model="reason.description" id="exampleFormControlTextarea1" rows="3"></textarea>




                    </div>
             
             
  
             
            </div>
          </form>
          <template #modal-footer="{ ok, cancel, hide }">
            <b-button @click="cancel()" size="sm" variant="secondary">
              <i class="fad fa-times"></i>
              {{ trans('Cancel') }}
            </b-button>
            <b-button @click="editDataType()" size="sm" variant="primary">
              <i class="fad fa-save"></i>
              {{ trans('Save') }}
            </b-button>
          </template>
        </b-modal>
      </template>
    </Base>
  </template>
  <script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {api} from "@/services/Api";
  import Base from "@/layouts/Base.vue";
  import DataType from "@/entity/DataType";
  import Popup from "@/entity/Popup";
import Reason from '@/entity/Reason';
  
  @Component({
    components: {Base}
  })
  export default class DataTypeManager extends Vue {
  
  
    formats = [
      {name: 'Texte', code: 'TEXT'},
      {name: 'Numérique', code: 'NUMBER'},
      {name: 'Date', code: 'DATE'},
      {name: 'Oui/Non', code: 'BOOLEAN'},
      {name: 'Liste', code: 'ARRAY'},
      {name: 'Classe', code: 'CLASS'},
      {name: 'Objet', code: 'OBJECT'},
      {name: 'Groupe d\'objets', code: 'OBJECT_GROUP'},
  
    ]
    reason = new Reason()
  
    reasons: Reason[] = []
  
    resetDataType() {
      this.reason = new Reason()
    }
  
    mounted(): void {
      this.loadReason()
    }
  
    async loadReason() {
      const pop = new Popup('Recuperation des motifs', 'CHARGEMENT', 'primary', 'fad fa-sync', true)
      const res = await api.get(api.form, 'reason/list')
      if (res && res.data && res.data.reasons) {
        (this.$refs.dataTypeModal as any).hide()
        this.reasons = []
        res.data.reasons.forEach((type: Reason) => {
          this.reasons.push(new Reason(type))
        })
      }
      pop.hide()
    }
  
    async editDataType() {
      const res = await api.post(api.form, 'reason/edit', this.reason)
      if (res && res.data && res.data.type) {
        (this.$refs as any).dataTypeModal.hide()
        this.loadReason()
      }
    }
  
   
  }
  </script>
  <style scoped>
  </style>
  