<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'prospection-index' }" class="text-muted text-hover-primary">
                  <i class="fad fa-user"></i>
                  {{ trans("Details du client") }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>
      </div>
    </div>
  </template>





  <template v-slot:content>

    <template v-if="isLoading">

      <Loading />

    </template>


    <template v-else>
      <b-card>
        <div class="row justify-content-start align-items-center mt-3 g-2">
          <div class="col">
            <h1 style="font-size: 6rem; position: absolute; top: -35px">{{ customer?.status=='HOT' ? '🥵' : customer?.status=='COLD' ? '🥶' : customer?.status=='UNVALID' ? '😒' : '🫥' }}</h1>
            <h1 class="mt-5">{{ customer?.name }} {{ customer?.prename }}</h1>
            <h3 v-if="customer?.company"> {{ customer?.company?.name && `/ ${customer?.company?.name}` }}</h3>
          </div>

          <div class="col">
            <div>
              <span class="fw-bolder text-gray-400"> {{ customer?.email }} </span>

              <div class="d-flex flex-wrap">
                <div class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2">
                  {{ helper.phoneFormat(customer?.mobile) }}
                  <div class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0">
                    <i class="fad fa-phone"></i>
                  </div>
                </div>
              </div>
            </div>

            <span class="fw-bolder"> {{ customer?.location }} </span>
          </div>
          <div v-if="customer?.source" class="col">
            <span class="fw-bolder">Source:</span>

            <p>{{ customer?.source }} <br> {{
              customer?.partner == "null" ||
              customer?.partner == "" ||
              customer?.partner == null
              ? ""
              : `| ${customer?.partner}`
            }}</p>
          </div>
          <div v-if="customer?.manager" class="col">
            <span class="fw-bolder">Gestionnaire:</span>

            <p>{{ customer?.manager }} </p>
          </div>

          <div class="col">
            <div class="row g-2">
              <div class="col-12">
                <button type="button" class="btn btn-sm text-white btn-warning w-100">
                  <i class="fad fa-calendar"></i> Programmer une prise de
                  contact
                </button>
              </div>
              <div class="col-12">
                <router-link :to="{ name: 'handshake-form', params: { clientId: customer?.id, uuid: 'null' } }">
                  <button type="button" class="btn btn-sm btn-success w-100">
                    <i class="fad fa-phone"></i> Effectuer une prise de contact
                  </button>
                </router-link>
              </div>

              <!--
              <div class="col-12">
                <button type="button" class="btn btn-sm btn-secondary w-100">
                  <i class="fad fa-edit"></i> Modifier
                </button>
              </div>
              -->
            </div>
          </div>
        </div>
      </b-card>

      <div class="row mt-5 g-2">
        <div class="col">
          <div class="card text-start">

            <div class="card-body">
              <h4 class="card-title">Prises de contact effectués ({{ customer?.handshakes.length }})</h4>

              <div>
                <template v-for="(item, index) in customer?.handshakes" >
                 
             
                <div :key="index"  class="row justify-content-center align-items-end g-2 border-bottom mt-5 py-5">
                  <div class="col">

                    <span class="text-muted fw-bold text-muted d-block fs-7">
                      Effectué le {{ helper.readable(item.createdAt) }}  par {{ item.createBy }} 
                    </span>

                    <h5>
                      {{item.title}}
                    </h5>

                    <span class="fw-bold">
                      Cotations effectués : null
                    </span>




                  </div>

                  <div class="col-2">
                    <button type="button" class="btn btn-primary btn-sm"> <i class="fad fa-eye"></i> Voir plus</button>
                  </div>
                </div>
              </template>
               

              </div>
            </div>
          </div>

        </div>

        <div class="col-3">

          <div class="card">

            <div class="card-body">
              <h4 class="card-title">Cotations effectués</h4>

              <div class="border-bottom-dashed py-3 border-secondary">
                <div class="row justify-content-center align-items-center g-2">
                  <div class="col-10">Auto Particulier ▪️ Comparateur</div>
                  <div class="col">100%</div>

                </div>
                <div class="row justify-content-between align-items-center g-2 mt-1">
                  <div class="col-10"> <span class="text-muted fw-bold text-muted d-block fs-7">
                      22 Fév 24 à 08:25
                    </span></div>
                  <div class="col">
                    <button class="btn-icon btn btn-primary btn-xs"><i class="fad fa-eye"></i></button>
                  </div>

                </div>

              </div>
            </div>
          </div>


          <div class="card mt-3">

            <div class="card-body">
              <h4 class="card-title">Informations supplementaires</h4>

              <div v-for="(item, index) in customer?.informations" :key="index" class="border-bottom-dashed  border-secondary d-flex flex-column py-3">

                <span class="mb-3 fw-bolder" style="text-transform: uppercase;">{{ item.label }}</span>

                <span v-if="item.type == 'text'" class="fw-bolder">{{item.value}}</span>

                <div style="height: 150px; width: 100%;  object-fit: cover; overflow: hidden; " v-else>
                  <img v-b-modal.imagePreview  :src="source+item.value" style="height: height:100%; width:100%" alt="">
                  <b-modal id="imagePreview" :title="item.label">
                    <img :src="source+item.value" class="h-100 w-100" alt="">
                  </b-modal>
                </div>
               


              </div>

             


            </div>
          </div>
        </div>



      </div>
    </template>



  </template>
  </Base>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import SellCustomer from "@/entity/SellCustomer";
import Loading from "@/components/Loading.vue";

@Component({
  components: { Base, Loading },
})
export default class DetailsClientView extends Vue {

  customer: SellCustomer | null = null
  isLoading: boolean = false

  source = api.uploadRoot;

  async load() {



    this.isLoading = true

    const res = await api.get(api.sell, 'customer/details/' + this.$route.params.id);

    if (res && res.data) {
      this.customer = new SellCustomer(res.data.customer)
      this.isLoading = false
    } else {
      this.isLoading = false
    }

    this.$forceUpdate();
  }

  mounted() {
    this.load()
  }
}
</script>

<style scoped></style>
