<template>
    <div :key="'question_'+question.uuid" class="flex-grow-1 m-2 question" v-if="question.isAuthorized()">
        <label class="fw-bolder my-2 d-flex justify-content-between" for="">
            <span :title="question.visibility" class="cursor-pointer" v-b-tooltip.hover v-if="question.visibility !== 'PUBLIC'">
                <i class="fad fa-lock text-danger"></i>
            </span>
            {{question.label}}
            <span @click="reset" class="btn btn-xs btn-icon" v-if="hasRole('ROLE_MANAGER')">
                <i class="fad fa-trash text-danger"></i>
            </span>
        </label>
        <b-input-group
                :class="[ question.isSending ? 'border-secondary' : question.isValid ? 'border-success' : 'border-danger' ]"
                class="rounded border-1 d-flex flex-grow-1" style="border-style:solid">
            <b-input-group-prepend v-if="question.prefix">
                <div class="input-group-text flex-grow-1">
                    <template v-if=" isIcon(question.prefix)">
                        <i :class="question.prefix"></i>
                    </template>
                    <template v-else>
                        {{ question.prefix }}
                    </template>
                </div>
            </b-input-group-prepend>
            <div class="input-container flex-grow-1 rounded  d-flex">
                <input :class="{ 'rounded-left-0' : question.prefix, 'rounded-right-0' : question.suffix }" :key="'input_'+uKey"
                       :regex="question.regex" @blur="question.updateAnswer()"
                       @input="updateAmount" @key.enter="question.updateAnswer()"
                       class="fw-bolder form-control text-end" type="text" v-model="formatted">
                <span :title="trans('Mise à jour en cours')" class="input-status" v-b-tooltip.hover v-if="question.isSending">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
                <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="!question.isValid">
                    <i class="fad fa-exclamation-circle text-danger"></i>
                </span>
                <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="question.isValid">
                    <i class="fad fa-check-circle text-success"></i>
                </span>
            </div>
            <b-input-group-append v-if="question.suffix">
                <div class="input-group-text flex-grow-1">
                    <template v-if=" isIcon(question.suffix)">
                        <i :class="question.suffix"></i>
                    </template>
                    <template v-else>
                        {{ question.suffix }}
                    </template>
                </div>
            </b-input-group-append>
        </b-input-group>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import Question from "@/entity/Question";
    import {helper} from "@/services/Helper";

    @Component({})
    export default class Money extends Vue {
        [x: string]: any;

        @Prop() question!: Question
        pattern = '### ### ### ### ###'
        formatted: any = ''
        uKey = helper.generateId()


        autovalidate() {
        clearTimeout(this.to);
        this.to = setTimeout(() => {
            clearTimeout(this.to)
            this.question.updateAnswer()
        }, 1000)
    }
        // get formatted() {
        //     return this._formatted
        // }
        //
        // set formatted(value: any) {
        //     // this._formatted = value
        //     console.log(value)
        //     this._formatted = this.helper.currencyFormat(value, false, false);
        //     console.log(this._formatted)
        //     this.formatted
        //     // this.uKey = helper.generateId()
        //     // this.$forceUpdate()
        // }

        timeout: any = null

        reset(){
            this.$store.commit('resetResponse',this.question)
            this.formatted = ''
        }

        @Watch('formatted')
        throttle() {
            // console.log(this.formatted)
            let current = (this.formatted+'').replace(/\s/g, '')
            // console.log(isNaN(parseFloat(current)))
            if(isNaN(parseFloat(current))){
                current = '0'
            }
            let formatted = this.helper.currencyFormat(current, false, false);
            if (formatted !== this.formatted) {
                this.formatted = formatted
                if (this.question.response.value !== current && !this.helper.empty(current)) {
                    clearTimeout(this.timeout)
                    this.timeout = null
                    setTimeout(this.setValue, 1000)
                }
            }
        }

        setValue() {
            this.question.response!.value = this.formatted.replace(/\s/g, '')
        }

        mounted() {
            // this.formatted = this.question.response.value
            if (this.question.response && !this.helper.empty(this.question.response.value)) {
                this.formatted = this.helper.currencyFormat(this.question.response.value, false, false);
            }

            // console.log(this.formatted)
        }

        updateAmount() {
            this.autovalidate()
            // console.log('////////////')
            // console.log(this._formatted)
            // if (this.question.response && this.question.response.value) {
            //     let i = this.question.response.value.length - 1;
            //
            //     const formatted = this.helper.currencyFormat(this._formatted, false, false)
            //     // console.log(formatted)
            //     this.question.response!.value = '' + parseFloat(this._formatted)
            //     // console.log(this.question.response!.value)
            // }
        }

        isIcon(text: string) {
            return text.match(/fa |fab |fad |fal |fas /) !== null
        }

        // updateAmount() {
        //     this.question.response!.value = '' + parseFloat(this.formatted)
        // }


    }
</script>
<style scoped>
    .input-container {
        position: relative;
    }

    input {
        padding-right: 30px;
    }

    .input-status {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
    }
</style>
