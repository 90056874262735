<template>
    <div class="comparator-header d-flex">
        <div class=" px-2 py-5 mx-auto w-100 d-flex">
            <router-link :to="{ name: 'comparateur-index' }">
                <img alt="Logo" class="h-40px logo" src="../../../assets/logo.png"/>
            </router-link>
        </div>
    </div>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';

    @Component({})
    export default class ComparatorHeader extends Vue {
    }
</script>
<style scoped>

</style>
