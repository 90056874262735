<template>
    <div id="kt_header" style="" class="header align-items-stretch">
        <div class="container-fluid d-flex align-items-stretch justify-content-between">
            <div class="d-flex align-items-center d-lg-none ms-n3 me-1" data-bs-toggle="tooltip"
                 title="Show aside menu">
                <div class="btn btn-icon btn-active-light-primary" id="kt_aside_mobile_toggle">
                <span class="svg-icon svg-icon-2x mt-1">
                    <i class="fad fa-list"></i>
									</span>
                </div>
            </div>
            <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                <a href="index.html" class="d-lg-none">
                    <img alt="Logo" src="./../assets/logo.png" class="h-30px"/>
                </a>
            </div>
            <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1 topbar">
                <LeftMenu/>
                <RightMenu />

            </div>
        </div>
    </div>

</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import LeftMenu from "@/layouts/Header/LeftMenu.vue";
    import RightMenu from "@/layouts/Header/RightMenu.vue";
    import {auth} from "@/services/Auth";

    @Component({
        components: {LeftMenu, RightMenu}
    })
    export default class HeaderTop extends Vue {
        public backendUrl = process.env.VUE_APP_BACKEND_URL;

        get isConnected():boolean{
            return auth.isConnected();
        }

    }
</script>
<style scoped>
</style>
