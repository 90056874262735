<template>
  <div class="">
    <label class="fs-2 fw-bolder cursor-pointer d-flex align-items-center py-5 border-top cursor-pointer">
      <input type="checkbox" v-model="expand" class="hidden">
      <span>
        <i class="fad fa-caret-right me-2 fs-2"></i>
        {{ model.name }}
        <!--        {{ property ? property.name : '' }}-->
      </span>
      <span class="mx-10 fw-bolder text-muted flex-grow-1">
        <!--      <template v-if="itemProperty.type.code === 'OBJECT' && subProperty && subProperty.children.length > 0">-->
        <!--        {{ subProperty.children[0].generatedAlias }}-->
        <!--      </template>-->
      </span>
      <span class="badge badge-danger ps-5">
        {{ property ? property.children.length : 0 }}
      </span>
      <span class="btn btn-sm  btn-primary btn-icon mx-10" v-if="model.type.code === 'OBJECT_GROUP'">
        <i class="fad fa-plus"></i>
      </span>
    </label>
    <!--    => {{property}}-->
    <div class="child-container ms-10" v-if="itemType && expand">
      <template v-for="itemProperty of itemType?.properties">
        <!--        <div>-->
        <!--        </div>-->
        <div v-if="itemProperty.type.code.includes('OBJECT')" :set="subProperty = getSubProperty(itemProperty)"
             class="">
          <div class="d-flex align-items-center border-top py-5">
            <div class="fs-2 fw-bolder cursor-pointer">
              <i class="fad fa-caret-right me-2 fs-2"></i>
              <div class="badge badge-danger badge-lg mx-2">
                {{ subProperty ? subProperty.children.length : 0 }}
              </div>
              {{ itemProperty.name }}
            </div>
            <div class="mx-10 fw-bolder text-muted flex-grow-1">
              <template v-if="itemProperty.type.code === 'OBJECT' && subProperty && subProperty.children.length > 0">
                {{ subProperty.children[0].generatedAlias }}
              </template>
            </div>
            <div>
              <template v-if="canAddProperty(subProperty,itemProperty)">
                <div class="btn btn-sm  btn-primary mx-10" v-if="typeof itemProperty.max === 'number'">
                  <i class="fad fa-plus"></i>
                  Ajouter
                </div>
              </template>
              <div class="btn btn-sm btn-danger mx-10" v-if="canRemoveProperty(subProperty,itemProperty)">
                <i class="fad fa-times"></i>
                Retirer
              </div>
            </div>
          </div>

          <!--          <AddRemoveItemLine :item="child" :level="0" :itemTypes="itemTypes"-->
          <!--                             :itemType="itemProperty"></AddRemoveItemLine>-->
        </div>
        <!--                <div v-else-if="itemProperty.type.code === 'OBJECT_GROUP'">-->
        <!--                  <AddRemoveItemLine :items="[]" :level="0" :itemTypes="itemTypes" :itemType="itemType"></AddRemoveItemLine>-->
        <!--                </div>-->
      </template>
    </div>
  </div>
</template>


<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import Property from "@/entity/Property";
import Item from "@/entity/Item";
import ItemProperty from "@/entity/ItemProperty";
import ItemType from "@/entity/ItemType";

@Component({
  name: 'AddRemovePropertyLine',
  components: {}
})
export default class AddRemovePropertyLine extends Vue {
  @Prop() occurences!: []
  @Prop() model!: ItemProperty
  @Prop() property!: Property
  @Prop() level!: number
  @Prop() itemTypes!: ItemType[]
  itemType: ItemType | null = null
  items: Item[] = []
  expand = true

  canAddProperty(property: Property, model: ItemProperty) {
    if (typeof model.max === 'number') {
      if (property && property.children.length >= model.max) {
        return false
      }
    }

    return true;
  }

  canRemoveProperty(property: Property, model: ItemProperty) {
    if (model.type.code === 'OBJECT') {
      return false
    }
    if (typeof model.min === 'number') {
      if (property && property.children.length > model.min) {
        return true
      }
    }
    return false;
  }

  mounted() {

    for (let type of this.itemTypes) {
      if (type.uuid === this.model.value) {
        this.itemType = type
        // return
      }
    }


    // for (let p of this.itemType?.properties!) {
    //   console.log(p.name)
    // }
  }

  getObjectChildByType(model: ItemProperty) {
    const item: Item = this.property.children[0]
    if (this.property) {
      for (let child of item.properties) {
        if (child.model.uuid === model.uuid) {
          return child.children[0]
        }
      }
    }
  }

  getSubProperty(model: ItemProperty) {
    const item: Item = this.property.children[0]
    if (this.property) {
      for (let child of item.properties) {
        if (child.model.uuid === model.uuid) {
          return child
        }
      }
    }
  }

  childHasObjectProperty(child: ItemType) {

    for (let itemProperty of child.properties) {
      if (itemProperty.type.code.includes('OJBECT')) {
        return true
      }
    }
    return false
  }
}
</script>

<style scoped>

</style>
