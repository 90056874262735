<template>
    <div class="Statistics">
        <div class="row g-5 g-xl-8">
            <div class="col-xl-3">
                <a class="card bg-danger text-white hoverable card-xl-stretch mb-xl-8" href="#">
                    <div class="card-body">
                        <span>
                            <i class="text-white fad fa-list fs-3x"></i>
                        </span>
                        <div class="fw-bolder fs-2 mb-2 mt-5">+3</div>
                        <div class="fw-bold fs-7">{{trans('Cotations en attente')}}</div>
                    </div>
                </a>
            </div>
            <div class="col-xl-3">
                <a class="card bg-dark hoverable card-xl-stretch mb-xl-8" href="#">
                    <div class="card-body">
                        <span class="svg-icon svg-icon-white svg-icon-3x ms-n1">
                            <i class="text-white fad fa-users fs-3x"></i>
                        </span>
                        <div class="text-inverse-dark fw-bolder fs-2 mb-2 mt-5">+3</div>
                        <div class="fw-bold text-inverse-dark fs-7">Nouveaux Clients</div>
                    </div>
                </a>
            </div>
            <div class="col-xl-3">
                <a class="card bg-success hoverable card-xl-stretch mb-xl-8" href="#">
                    <div class="card-body">
                        <span class="svg-icon svg-icon-white svg-icon-3x ms-n1">
                            <i class="text-white fad fa-crosshairs fs-3x"></i>
                        </span>
                        <div class="py-1 mt-3">
                            <span class="text-white fs-1 fw-bolder me-2">15%</span>
                            <span class="fw-bold text-white fs-5">Objectif: 500k</span>
                        </div>
                        <div class="progress h-7px bg-white bg-opacity-50 mt-3">
                            <div class="progress-bar bg-white" role="progressbar" style="width: 15%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-xl-3">
                <a class="card bg-info hoverable card-xl-stretch mb-5 mb-xl-8" href="#">
                    <div class="card-body">
                        <span class="svg-icon svg-icon-white svg-icon-3x ms-n1">
													<svg height="24px" version="1.1"
                                                         viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"
                                                         xmlns:xlink="http://www.w3.org/1999/xlink">
														<g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
															<rect height="24" width="24" x="0" y="0"></rect>
															<path d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z"
                                                                  fill="#000000" opacity="0.3"></path>
															<path d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z"
                                                                  fill="#000000"></path>
														</g>
													</svg>
												</span>
                        <div class="text-inverse-info fw-bolder fs-2 mb-2 mt-5">$50,000</div>
                        <div class="fw-bold text-inverse-info fs-7">Milestone Reached</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';

    @Component({})
    export default class ManagerStatistics extends Vue {

    }
</script>
<style scoped>
</style>
