<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div @click="save" class="btn-sm btn btn-primary ms-5">
              <i class="fad fa-save"></i>
              {{ trans('Enregistrer') }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <!--            <h1>-->
      <!--                {{$route.params.branchSlug}} ▪ {{$route.params.surveySlug}}-->
      <!--            </h1>-->
      <!--            <p class="fs-2 mb-10">-->
      <!--                Faites Correspondre les questions aux propriétés de la base de données-->
      <!--            </p>-->
      <Loading v-if="loading"></Loading>
      <!--            <b-tabs active-nav-item-class="bg-primary text-white" active-tab-class="" nav-wrapper-class="bg-white rounded">-->
      <!--                <b-tab :title-link-class="''">-->
      <!--                    <template #title>-->
      <!--                        GLOBAL-->
      <!--                    </template>-->
      <b-tabs @input="retrace">
        <b-tab active-nav-item-class="bg-primary text-white" active-tab-class="" nav-wrapper-class="bg-white rounded"
               v-for="(map, index) in maps" :key="'map-' + helper.generateId()">
          <template #title>
            <template v-if="map.property">
              {{ map.property.name }}
            </template>
            <template v-else>
              {{ map.survey.label }}
            </template>
          </template>
          <div :key="trace">
            <Mapper :allItemTypes="itemTypes" :key="'map_' + helper.generateId()" :map="map"
                    v-on:updateMap="updateMap"></Mapper>
          </div>
          <div>
            <div v-for="survey in surveys">
              <div v-if="survey.id === map.survey.id">
                <div v-for="mapping in survey.mappings">
                  <div>
                    {{ mapping.itemCode }} : {{ mapping.propertyUuid }} - {{ mapping.propertyCode }} -
                    {{ mapping.propertyLink }} - {{ mapping.questionUuid }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import ItemType from "@/entity/ItemType";
import ItemProperty from "@/entity/ItemProperty";
import Survey from "@/entity/Survey";
import QuestionLine from "@/views/Configuration/Branch/Mapper/QuestionLine.vue";
import Loading from "@/components/Loading.vue";
import PropertyLine from "@/views/Configuration/Branch/Mapper/PropertyLine.vue";
import Question from "@/entity/Question";
import Mapper from "@/views/Configuration/Branch/Mapper/MapBox.vue";
import Mapping from "@/entity/Mapping";
import {helper} from "@/services/Helper";

@Component({
  components: {Mapper, PropertyLine, Loading, QuestionLine, Base}
})
export default class MapperIndex extends Vue {

  itemTypes: ItemType[] = []
  root: any = null
  surveys: Survey[] = []
  trace = helper.generateId()
  loading = false
  maps: { survey: Survey, items: ItemType[], property: any }[] = []

  async mounted() {
    this.load()
  }

  async load() {
    this.loading = true
    await this.getItems()
    await this.getQuestions()
    await this.addDefault()
    this.loading = false
  }

  retrace() {
    // console.log('retrace')
    this.trace = helper.generateId()
  }

  async addDefault() {
    const survey = this.surveys.find((s: Survey) => {
      return s.slug === this.$route.params.surveySlug
    })
    if (survey) {
      const map = {survey, items: this.itemTypes, property: null}
      this.maps.push(map)
    }
  }

  async save() {
    this.$store.commit('loading')
    let mappings: any[] = []
    this.surveys.forEach((s: Survey) => {
      s.mappings.forEach((mapping: Mapping) => {
        mappings.push({
          id: mapping.id,
          questionUuid: mapping.questionUuid,
          propertyUuid: mapping.propertyUuid, surveyUuid: s.uuid,
          propertyCode: mapping.propertyCode,
          propertyLink: mapping.propertyLink,
          itemCode: mapping.itemCode,
          ranking: mapping.ranking
        })
      })
    })
    const res = await api.post(api.form, 'survey/mapping/' + this.$route.params.surveySlug, {mappings})
    this.$store.commit('stopLoading')
    // console.log(res)
  }

  getPropertyByUuid(uuid: string) {
    let property: ItemProperty = null as any
    this.itemTypes.forEach((i: ItemType) => {
      i.properties.forEach((p: ItemProperty) => {
        if (p.uuid === uuid) {
          property = p
        }
      })
    })
    return property;
  }

  getQuestionByUuid(uuid: string) {
    let question: Question = null as any
    this.surveys.forEach((survey: Survey) => {
      const res = survey.getQuestions().find((q: Question) => {
        // if (uuid === q.uuid) {
        //     console.log(q.label + ' ' + q.uuid + ' => ' + (uuid === q.uuid))
        // }
        return q.uuid === uuid
      }) as any
      if (res) {
        question = res
      }
    })
    return question
  }

  updateMap() {
    // this.maps = []
    // this.addDefault()
    // console.log('update map')
    this.surveys.forEach((survey: Survey) => {
      survey.mappings.forEach((mapping: Mapping) => {
        const question = this.getQuestionByUuid(mapping.questionUuid)
        const property = this.getPropertyByUuid(mapping.propertyUuid)
        if ((question.type.code === 'OBJECT_GROUP' && property.type.code === 'OBJECT_GROUP') ||
            (question.type.code === 'OBJECT' && property.type.code === 'OBJECT')) {
          this.addLink(question.defaultValue as any, [property.value] as any, property)
        }
      })
    })
  }

  addLink(surveyUuid: string, itemUuids: string[], property: any) {

    let found = false
    let mapFound = false
    const map = this.maps.find((map: any) => {
      const uuids = map.items.map((item: ItemType) => item.uuid)
      // console.log(uuids)
      return map.survey.uuid === surveyUuid && JSON.stringify(itemUuids) === JSON.stringify(uuids)
    })
    // console.log(map)
    if (!map) {
      const survey = this.surveys.find((s: Survey) => {
        return s.uuid === surveyUuid
      }) as Survey
      const items = this.itemTypes.filter((itemType: ItemType) => {
        return itemUuids.includes(itemType.uuid)
      })
      // console.log(survey)
      const map = {survey, items, property}
      if (items.length > 0) {
        if (!this.maps.includes(map)) {
          this.maps.push(map)
        }
      }
    }
  }

  async getItems() {
    const res = await api.get(api.core, 'item/type/list/' + this.$route.params.branchSlug)
    if (res && res.data && res.data.itemTypes) {
      this.itemTypes = []
      // this.itemTypes = res.data.itemTypes
      res.data.itemTypes.forEach((itemType: any) => {
        const item = new ItemType(itemType)
        this.itemTypes.push(item)
        if (item.isRoot) {
          this.root = item
        }
      })
      if (this.root) {
        this.tree = this.getItemTree(this.root)
        // console.log(this.tree)
      }
    }
  }

  async getQuestions() {
    this.surveys = []
    const res = await api.get(api.form, 'survey/list/' + this.$route.params.branchSlug)
    if (res && res.data && res.data.surveys) {
      let counters: any = {};
      res.data.surveys.forEach((s: Survey) => {
        s.mappings.forEach((m: Mapping) => {
          if (!counters.hasOwnProperty('p_' + m.propertyUuid)) {
            counters[m.propertyUuid] = 0
          }
          if (helper.empty(m.ranking)) {
            m.ranking = counters[m.propertyUuid]
            counters[m.propertyUuid]++
          } else if (counters[m.propertyUuid] < m.ranking) {
            counters[m.propertyUuid] = m.ranking + 1
          }
          console.log(m.itemCode + ' ' + m.propertyCode + ' => ' + m.ranking)
        })
        this.surveys.push(new Survey(s))
      })
      // console.log(this.surveys)
    }
  }

  tree: any = {}
  treeConfig: any = {nodeWidth: 200, nodeHeight: 80, levelHeight: 100,}

  getItemTree(item: ItemType) {
    let tree = {
      name: item.name,
      code: item.code,
      icon: item.icon,
      className: item.className,
      props: [] as any,
      children: [] as any
    };
    item.properties.forEach((prop: ItemProperty) => {
      tree.props.push(prop)
      if (prop.type.code === 'OBJECT') {
        const link = this.itemTypes.find((i: ItemType) => {
          return i.uuid === prop.value
        })
        if (link) {
          const t = this.getItemTree(link)
          t.name = prop.name
          tree.children.push(t)
        }
      }
      if (prop.type.code === 'OBJECT_GROUP') {
        const link = this.itemTypes.find((i: ItemType) => {
          return i.uuid === prop.value
        })
        if (link) {
          // for (let i = 1; i < 3; i++) {
          const t = this.getItemTree(link)
          t.name = prop.name + ' 1'
          tree.children.push(t)
          // }
        }
      }
    })
    return tree
  }
}
</script>
<style scoped>
.point {
  fill: white;
  box-shadow: 0 0 2px rgba(150, 150, 150, .8);
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.input {
  box-shadow: inset 0 0 2px rgba(150, 150, 150, .8);
  background: cornflowerblue;
}

.output {
  position: absolute;
  background: whitesmoke;
  box-shadow: 0 0 2px rgba(150, 150, 150, .8);
  top: 2px;
  left: 2px;
  z-index: 4;
  /*transition: opacity 0.2s ease;*/
  /*transform: translateX(-50%) translateY(-50%);*/
}

.path {
  stroke-width: 5px;
  fill: none;
  stroke: cornflowerblue;
  pointer-events: all;
}

.path:hover,
g:hover {
  background: red;
  box-shadow: 0 0 5px black;
}

.pointers-svg {
  z-index: 3;
  pointer-events: none;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0
}
</style>
