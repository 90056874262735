// <?php
//
//     namespace App\Utils;
//
// const consonnes = ['w', 'r', 'd', 'j', 'p', 'k', 'l', 'm', 'n', 's'];
// const voyels = ['i', 'e', 'a', 'o', 'u', 'y', 'q', 'x', 'b', 'h'];
//
// class Transformer
// {
//
//     static function convertIdToChar($code)
// {
//     $chars = str_split($code);
//     $map = ['S', 'I', 'A', 'W', 'U', 'R', 'E', 'K', 'Y', 'H'];
//     $convert = '';
//     foreach ($chars as $index => $char) {
//     $type = $index % 2 !== 0 ? voyels : consonnes;
//     $convert .= strtoupper($type[(int)$char]);
// }
//     return $convert;
// }
//
//     static function convertCharToId($code)
// {
//     $chars = str_split($code);
//     $map = ['S', 'I', 'A', 'W', 'U', 'R', 'E', 'K', 'Y', 'H'];
//     $convert = '';
//     foreach ($chars as $index => $char) {
//     $type = $index % 2 !== 0 ? voyels : consonnes;
//     $num = array_search(strtolower($char),$type);
//     $convert .= $num;
// }
//     return (int)$convert;
// }
//
// }


class Shortener {
    consonnes = ['w', 'r', 'd', 'j', 'p', 'k', 'l', 'm', 'n', 's'];
    voyels = ['i', 'e', 'a', 'o', 'u', 'y', 'q', 'x', 'b', 'h'];

    converIdToCHar(id: number) {
        let temp = '' + id;
        while (temp.length < 4) {
            temp = '0' + temp
        }
        const chars = temp.split('')
        const map = ['S', 'I', 'A', 'W', 'U', 'R', 'E', 'K', 'Y', 'H'];
        let convert = ''

        for (let i = 0; i < chars.length; i++) {
            let type = i % 2 !== 0 ? this.voyels : this.consonnes
            const num = type[parseInt(chars[i])]
            convert += num
        }

        return convert
    }

}


export const shortener = new Shortener();
