<template>
    <Base>
        <template v-slot:toolbar>
            <div class="toolbar" id="kt_toolbar">
                <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
                    <div class="d-flex align-items-center me-3">
                        <slot name="leftToolbar">
                            <span class="h-20px border-gray-200 border-start mx-4"></span>
                            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                <li class="breadcrumb-item text-muted">
                                    <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                                        <i class="fad fa-home"></i>
                                        {{trans('Dashboard')}}
                                    </router-link>
                                </li>
                            </ul>
                        </slot>
                    </div>


                    <div class="d-flex align-items-center py-1">
                        <div @click="resetPremiumDetail" class="btn-sm btn btn-primary ms-5"
                             v-b-modal.premiumDetailModal>
                            <i class="fad fa-plus"></i>
                            {{trans('Créer un detail')}}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:content>


            <div class="card">
                <div class="card-body ">
                    <table class="table table-striped gy-7 gs-7">
                        <thead>
                        <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                            <th>{{trans('Label')}}</th>
                            <th>{{trans('Opérateur')}}</th>
                            <th>{{trans('Calcul')}}</th>
                            <th>{{trans('Actions')}}</th>
                        </tr>
                        </thead>
                        <tr v-if="isLoading">
                            <td colspan="4">
                                <Loading></Loading>
                            </td>
                        </tr>
                        <draggable :animation="200" :list="premiumDetailModels" @end="updateRanks"
                                   filter=".action-button" ghost-class="bg-light-primary" group="pdm" tag="tbody" v-else>
                            <tr v-for="pdm in premiumDetailModels">
                                <td>{{pdm.label}}</td>
                                <td>{{pdm.operator}}</td>
                                <td>
                                    <router-link :to="{ name : 'engine-method-editor', params : { methodId: pdm.method.id, methodSlug: pdm.method.code } }"
                                            class="text-primary text-decoration-underline fw-bolder" v-if="pdm.method">
                                        {{pdm.method.label}}
                                    </router-link>
                                </td>
                                <td>
                                    <div @click="setPremiumDetail(pdm)" class="btn btn-xs btn-icon btn-primary" v-b-modal.premiumDetailModal>
                                        <i class="fad fa-edit"></i>
                                    </div>
                                </td>
                            </tr>
                        </draggable>
                    </table>
                    <div>

                    </div>
                </div>
            </div>


            <b-modal :title="trans('Détail de prime')" centered id="premiumDetailModal" ref="premiumDetailModal">
                <form>
                    <div class="row">
                        <div class="col-auto m-2 flex-grow-1">
                            <label :for="'name'" class="required form-label">{{trans('Label')}}</label>
                            <b-form-input :id="'name'" :state="helper.empty(pd.label)"
                                          v-model="pd.label"></b-form-input>
                        </div>
                        <div class="col-auto m-2 flex-grow-1">
                            <label :for="'code'" class="required form-label">{{trans('Opérateur')}}</label>
                            <div>
                                <input class="form-control" type="text" v-model="pd.operator">
                            </div>
                        </div>

                      <div class="col-auto m-2 flex-grow-1">
                        <label :for="'code'" class="form-label">{{trans('Correspond à une reduction')}}</label>
                        <div class="form-check form-switch form-check-custom form-check-solid me-2">
                          <input class="form-check-input w-30px h-20px" type="checkbox"
                                 v-model="pd.isReduction"/>
                        </div>
                      </div>
                        <div class="col-auto my-2 flex-grow-1">
                            <label :for="'type'" class="required form-label">{{trans('Calcul')}}</label>
                            <div class="d-flex">
                                <b-input-group>
                                    <b-form-select :state="!helper.empty(pd.method)"
                                                   class="form-control  form-select"
                                                   v-model="pd.method">
                                        <b-form-select-option :value="null">{{trans('Select_a_type')}}
                                        </b-form-select-option>
                                        <b-form-select-option :key="'method_'+key" :value="method"
                                                              v-for="method,key in methods">
                                            {{trans(method.label)}}
                                        </b-form-select-option>
                                    </b-form-select>
                                    <b-input-group-append>
                                        <div class="btn btn-sm btn-icon btn-primary h-100"
                                             style="border-top-left-radius: 0;border-bottom-left-radius: 0"
                                             v-b-modal="'methodModal'">
                                            <i class="fad fa-plus"></i>
                                        </div>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>

                    </div>
                </form>
                <template #modal-footer="{ ok, cancel, hide }">
                    <b-button @click="cancel()" size="sm" variant="secondary">
                        <i class="fad fa-times"></i>
                        {{trans('Cancel')}}
                    </b-button>
                    <b-button @click="editPremiumDetail" size="sm" variant="primary">
                        <i class="fad fa-save"></i>
                        {{trans('Save')}}
                    </b-button>
                </template>
            </b-modal>

            <b-modal :title="trans('Calcul')" centered id="methodModal" ref="methodModal">
                <form>
                    <div class="row">
                        <div class="col-auto m-2 flex-grow-1">
                            <label class="required form-label">{{trans('Nom')}}</label>
                            <div>
                                <b-form-input :state="!helper.empty(method.label)" class="form-control" type="text" v-model="method.label">
                                </b-form-input>
                            </div>
                        </div>
                        <div class="col-auto m-2 flex-grow-1">
                            <label class="form-label">{{trans('Description')}}</label>
                            <div>
                                <textarea class="form-control" v-model="method.description"></textarea>
                            </div>
                        </div>
                    </div>
                </form>
                <template #modal-footer="{ ok, cancel, hide }">
                    <b-button @click="cancel()" size="sm" variant="secondary">
                        <i class="fad fa-times"></i>
                        {{trans('Cancel')}}
                    </b-button>
                    <b-button @click="editMethod()" size="sm" variant="primary">
                        <i class="fad fa-save"></i>
                        {{trans('Save')}}
                    </b-button>
                </template>
            </b-modal>

        </template>
    </Base>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import Base from "@/layouts/Base.vue";
    import PremiumDetailModel from "@/entity/PremiumDetailModel";
    import {api} from "@/services/Api";
    import Popup from "@/entity/Popup";
    import Loading from "@/components/Loading.vue";
    import EngineMethod from "@/entity/EngineMethod";

    @Component({
        components: {Loading, Base}
    })
    export default class PremiumDetailList extends Vue {
        pd = new PremiumDetailModel()
        premiumDetailModels: PremiumDetailModel[] = []
        methods: EngineMethod[] = []
        isLoading = false
        method = new EngineMethod()

        async mounted() {
            await this.loadPremiumDetailModels()
            this.loadMethods()
        }


        async loadMethods() {
            // this.$store.commit('loading')
            const method = {id: this.method.id, label: this.method.label, description: this.method.description}
            let url = 'engine/method/list/branch/and/global/methods/' + this.$route.params.branchSlug;

            const res = await api.post(api.core, url, {method})

            if (res && res.data && res.data.methods) {
                this.methods = []
                res.data.methods.forEach((m: any) => {
                    const occ = new EngineMethod(m)
                    this.methods.push(occ)
                })
                res.data.globals.forEach((m: any) => {
                    const occ = new EngineMethod(m)
                    occ.label = 'GLOBAL '+occ.label
                    this.methods.push(occ)
                    const pdm = this.premiumDetailModels.find((pdm: PremiumDetailModel) => {
                        return pdm.method && pdm.method.id === m.id
                    })
                    if (pdm){
                        pdm.method = occ
                    }
                })
            }
            // this.$store.commit('stopLoading')
            // this.loadPremiumDetailModels()
        }

        async editMethod() {
            this.$store.commit('loading')
            const method = {id: this.method.id, label: this.method.label, description: this.method.description}
            let url = 'engine/method/edit';

            const res = await api.post(api.core, url, {method})
            if (this.$refs.methodModal) {
                (this.$refs.methodModal as any).hide()
            }
            this.$store.commit('stopLoading')
            this.loadPremiumDetailModels()
        }

        async updateRanks() {
            const popup = new Popup('', 'Reorganisation en cours ...', 'warning', 'fad fa-cogs', true)
            const ranks: any = []
            let i = 1
            this.premiumDetailModels.forEach((p: PremiumDetailModel) => {
                ranks.push({id: p.id, ranking: i})
                i++
            })
            const res = await api.post(api.core, 'premium/detail/model/update/ranks', {ranks})
            this.loadPremiumDetailModels()
            popup.hide()
        }

        resetPremiumDetail() {
            this.pd = new PremiumDetailModel()
        }

        setPremiumDetail(pd: PremiumDetailModel) {
            this.pd = pd
        }

        async editPremiumDetail() {
            const popup = new Popup('Chargement ...', '', 'secondary', 'fad fa-cloud-download-alt', true)
            const pdm: any = this.pd
            delete pdm.premiumDetails
            const res = await api.post(api.core, 'premium/detail/model/edit/' + this.$route.params.branchSlug, {premiumDetailModel: pdm});
            (this.$refs.premiumDetailModal as any).hide()
            popup.hide()
            this.loadPremiumDetailModels()
        }

        async loadPremiumDetailModels() {
            this.isLoading = true
            const popup = new Popup('Chargement ...', '', 'secondary', 'fad fa-cloud-download-alt', true)
            const res = await api.get(api.core, 'premium/detail/model/list/' + this.$route.params.branchSlug)
            if (res && res.data && res.data.premiumDetailModels) {
                this.premiumDetailModels = []
                res.data.premiumDetailModels.forEach((pdm: PremiumDetailModel) => {
                    const occ = new PremiumDetailModel(pdm)
                    this.premiumDetailModels.push(occ)
                })
            }
            popup.hide()
            this.isLoading = false
        }
    }
</script>
<style scoped>
</style>
