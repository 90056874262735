/* eslint-disabled */
import Prospect from "./Prospect";
import ResponseGroup from "./ResponseGroup";
import NoteExtend from "@/entity/extends/NoteExtend";

export default class Note extends NoteExtend {

public id!: number;
public comment? = '';
public prospect!: Prospect;
public responseGroup!: ResponseGroup;
public uuid? = '';
public createdAt?: Date;
public updatedAt?: Date;
public createBy? = '';
public updateBy? = '';
public removeBy? = '';
public createdFromIp? = '';
public updatedFromIp? = '';

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.comment= object.comment;
this.prospect = (object.prospect instanceof Prospect) ? object.prospect : object.prospect ? new Prospect(object.prospect) : object.prospect;
this.responseGroup = (object.responseGroup instanceof ResponseGroup) ? object.responseGroup : object.responseGroup ? new ResponseGroup(object.responseGroup) : object.responseGroup;
       this.uuid= object.uuid;
       if(object.createdAt){
           this.createdAt= new Date(object.createdAt);
       }
       if(object.updatedAt){
           this.updatedAt= new Date(object.updatedAt);
       }
       this.createBy= object.createBy;
       this.updateBy= object.updateBy;
       this.removeBy= object.removeBy;
       this.createdFromIp= object.createdFromIp;
       this.updatedFromIp= object.updatedFromIp;
      }
      this.postConstruct()
  }

}
