import {RouteConfig} from "vue-router";

//communication
import CampaignList from "@/views/Communication/Campaign/CampaignList.vue"

import CampaignCreate from "@/views/Communication/Campaign/CampaignCreate.vue"


import CommunicationIndex from "@/views/Communication/CommunicationIndex.vue";
import UrlShortener from "@/views/Communication/UrlShortener.vue";
import Extractor from "@/views/Extractor.vue";
import EmailTemplateList from "@/views/Communication/EmailTemplate/EmailTemplateList.vue";
import EmailTemplateForm from "@/views/Communication/EmailTemplate/EmailTemplateForm.vue";


import SmsTemplateList from "@/views/Communication/SmsTemplate/SmsTemplateList.vue";
import SmsTemplateForm from "@/views/Communication/SmsTemplate/SmsTemplateForm.vue";


//prosepection
import ClientsView from "@/views/Sell/ClientsView.vue";
import HandShakeList from "@/views/Sell/HandShakeList.vue"
import SellDashboard from "@/views/Sell/SellDashboard.vue";
import HandShakeForm from "@/views/Sell/HandShakeForm.vue";
import DetailsClientView from "@/views/Sell/DetailsClientView.vue";






const prefix = '/crm'
const crmRoutes: Array<RouteConfig> = [

    {
        path: '/',
        name: 'crm-index',
        component: SellDashboard,
    },

    //communication
    {
        path: '/sms',
        name: 'sms',
        component: CommunicationIndex,
        meta: {requiredRoles: ['ROLE_COMMUNICATION']},
    },
    {
        path: '/extractor',
        name: 'extractor',
        component: Extractor,
        meta: {requiredRoles: ['ROLE_ADMIN']},
    },
    {
        path: '/email/template/list',
        name: 'email-template-list',
        component: EmailTemplateList,
        meta: {requiredRoles: ['ROLE_ADMIN']},
    },
    {
        path: '/email/template/form/:uuid?',
        name: 'email-template-form',
        component: EmailTemplateForm,
        meta: {requiredRoles: ['ROLE_ADMIN']},
    },
    {
        path: '/template/sms/list',
        name: 'sms-template-list',
        component: SmsTemplateList,
        meta: {requiredRoles: ['ROLE_ADMIN']},
    },
    
    {
        path: '/template/sms/form/:uuid?',
        name: 'sms-template-form',
        component: SmsTemplateForm,
        meta: {requiredRoles: ['ROLE_ADMIN']},
    },

    {
        path: '/url/shortener',
        name: 'url-shortener',
        component: UrlShortener,
        meta: {requiredRoles: ['ROLE_COMMUNICATION']},
    },

    {
        path: '/campaign/list',
        name: 'campaign-list',
        component: CampaignList,
        meta: {requiredRoles: ['ROLE_COMMUNICATION']},
    },

    {
        path: '/campaign/create/:id?',
        name: 'campaign-create',
        component: CampaignCreate,
        meta: {requiredRoles: ['ROLE_COMMUNICATION']},
    },

    //chat
    {
        path: '/chat-whatsapp',
        name: 'chat-whatsapp',
        // component: UserList,
        component: () => import("@/views/Chat/ChatIndex.vue"),
        meta: { requiredRoles: ['ROLE_CHAT'] },
    },


    //prospection
    {
        path: '/handshake/list',
        name: 'handshake-list',
        component: HandShakeList,

    },
    {
        path: '/handshake/form/:uuid?/:clientId?',
        name: 'handshake-form',
        component: HandShakeForm,

    },

    {
        path: '/prospects',
        name: 'prospection-clients',
        component: ClientsView,
    },
    {
        path: '/prospects/:id',
        name: 'details-clients',
        component: DetailsClientView,
    }

    



]
crmRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default crmRoutes;
