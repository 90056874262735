<template>
   <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div @click="resetDataType" class="btn-sm btn btn-primary ms-5"
                 v-b-modal.dataTypeModal>
              <i class="fad fa-plus"></i>
              {{ trans('Creér un tag') }}
            </div>

           

          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>

      <div class="card">
        <div class="card-body p-0">
          <table class="table table-striped gy-7 gs-7">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>{{ trans('Label') }}</th>
              <th>{{ trans('Type') }}</th>
              <th>{{ trans('Code') }}</th>
              <th>{{ trans('Classe') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="tag in tags">
              <td>{{ tag.label }}</td>
              <td>{{ tag.type }}</td>
              <td>{{ tag.code }}</td>
              <td>
                <template v-if="tag.class">
                  {{ tag.class }}
                </template>
                <template v-else>
                  <i class="fad fa-empty-set"></i>
                </template>
              </td>
            </tr>
            </tbody>
          </table>
          <div>

          </div>
        </div>
      </div>
  
        <b-modal :title="trans('Creer un tag')" centered id="dataTypeModal" ref="dataTypeModal">
          <form>
            <div class="row" v-if="tag">
              <div class="col-auto m-2 flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
                <b-form-input :id="'label'" :state="!helper.empty(tag.label)"
                              v-model="tag.label"></b-form-input>
              </div>
              <div class="col-auto m-2 flex-grow-1">
                <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
                <b-form-input :id="'code'" :state="!helper.empty(tag.code)"
                              v-model="tag.code"></b-form-input>
              </div>
              <div class="col-auto m-2 flex-grow-1">
                <label :for="'code'" class="required form-label">{{ trans('Type') }}</label>
                <b-input-group>
                  <b-form-select :state="!helper.empty(tag.type)"
                                 class="form-control form-select"
                                 v-model="tag.type">
                    <b-form-select-option :value="null">{{ trans('Selectionnez un format') }}
                    </b-form-select-option>
                    <b-form-select-option :key="'format_'+key" :value="format.code" v-for="format,key in formats">
                      {{ trans(format.name) }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-input-group-append>
                    <div class="btn btn-sm btn-icon btn-danger h-100"
                         style="border-top-left-radius: 0;border-bottom-left-radius: 0">
                      <i class="fad fa-plus"></i>
                    </div>
                  </b-input-group-append>
                </b-input-group>
              </div>
  
              <div class="col-auto m-2 flex-grow-1" v-if="tag.format === 'CLASS'">
                <label :for="'class'" class=" form-label">{{ trans('Classe') }}</label>
  
                <b-form-input :id="'class'" v-model="tag.class"></b-form-input>
              </div>
            </div>
          </form>
          <template #modal-footer="{ ok, cancel, hide }">
            <b-button @click="cancel()" size="sm" variant="secondary">
              <i class="fad fa-times"></i>
              {{ trans('Cancel') }}
            </b-button>
            <b-button @click="editDataType()" size="sm" variant="primary">
              <i class="fad fa-save"></i>
              {{ trans('Save') }}
            </b-button>
          </template>
        </b-modal>
      </template>
    </Base>
  </template>
  <script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {api} from "@/services/Api";
  import Base from "@/layouts/Base.vue";
  import DataType from "@/entity/DataType";
  import Popup from "@/entity/Popup";
import Tag from '@/entity/Tag';
  
  @Component({
    components: {Base}
  })
  export default class DataTypeManager extends Vue {
  
  
    formats = [
      {name: 'Texte', code: 'TEXT'},
      {name: 'Numérique', code: 'NUMBER'},
      {name: 'Date', code: 'DATE'},
      {name: 'Oui/Non', code: 'BOOLEAN'},
      {name: 'Liste', code: 'ARRAY'},
      {name: 'Classe', code: 'CLASS'},
      {name: 'Objet', code: 'OBJECT'},
      {name: 'Groupe d\'objets', code: 'OBJECT_GROUP'},
  
    ]
    tag = new Tag()
  
    tags: Tag[] = []
  
    resetDataType() {
      this.tag = new Tag()
    }
  
    mounted(): void {
      this.loadTags()
    }
  
    async loadTags() {
      const pop = new Popup('Chargement des tags', '', 'primary', 'fad fa-sync', true)
      const res = await api.get(api.form, 'tag/list')
      if (res && res.data && res.data.tags) {
        (this.$refs.dataTypeModal as any).hide()
        this.tags = []
        res.data.tags.forEach((type: Tag) => {
          this.tags.push(new Tag(type))
        })
      }
      pop.hide()
    }
  
    async editDataType() {
      const res = await api.post(api.form, 'tag/edit', this.tag)
      if (res && res.data && res.data.type) {
        (this.$refs as any).dataTypeModal.hide()
        this.loadTags()
      }
    }
  
   
  }
  </script>
  <style scoped>
  </style>
  