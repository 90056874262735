/* eslint-disabled */
import AccountOperation from "./AccountOperation";
import AccountExtend from "@/entity/extends/AccountExtend";

export default class Account extends AccountExtend {

public id!: number;
public balance!: number;
public allowNegative!: boolean;
public allowPositive!: boolean;
public isMaster!: boolean;
public operations: Array<AccountOperation> = [];
public uuid = '';
public createdAt?: Date;
public updatedAt?: Date;
public createBy? = '';
public updateBy? = '';
public removeBy? = '';
public createdFromIp? = '';
public updatedFromIp? = '';

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.balance= object.balance;
       this.allowNegative= object.allowNegative;
       this.allowPositive= object.allowPositive;
       this.isMaster= object.isMaster;
       if(object.operations){
           object.operations.forEach((occ: any)=>{
               const accountoperation= occ instanceof AccountOperation? occ :  new AccountOperation(occ);
               this.operations.push(accountoperation);
           });
       }
       this.uuid= object.uuid;
       if(object.createdAt){
           this.createdAt= new Date(object.createdAt);
       }
       if(object.updatedAt){
           this.updatedAt= new Date(object.updatedAt);
       }
       this.createBy= object.createBy;
       this.updateBy= object.updateBy;
       this.removeBy= object.removeBy;
       this.createdFromIp= object.createdFromIp;
       this.updatedFromIp= object.updatedFromIp;
      }
      this.postConstruct()
  }

}
