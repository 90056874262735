<template>
  <manager-home v-if="hasRole('ROLE_MANAGER')"></manager-home>
  <partner-home v-else-if="hasRole('ROLE_PARTNER')"></partner-home>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import ManagerStatistics from "@/views/Dashboard/ManagerComponent/ManagerStatistics.vue";
import InputNumber from "@/components/InputNumber.vue";
import ManagerCotations from "@/views/Dashboard/ManagerComponent/ManagerCotations.vue";
import PartnerHome from './Partner/PartnerHome.vue';
import ManagerHome from './ManagerHome.vue';
import {auth} from "@/services/Auth";

@Component({
  components: {ManagerCotations, InputNumber, ManagerStatistics, Base, PartnerHome, ManagerHome}
})
export default class Home extends Vue {
  categories = []
  search = {
    branch: null,
    after: null,
    before: null,
    contains: null,
    minimalState: 0,
    hasClientInfos: false,
    page: 1,
    statut: [] as any
  }

  mounted(){

  }

  resetSearch() {
    this.search = {
      branch: null,
      after: null,
      before: null,
      contains: null,
      minimalState: 0,
      hasClientInfos: false,
      page: 1,
      statut: []
    }
  }
}
</script>
<style scoped>
</style>
