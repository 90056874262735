<template>
  <div class="my-1">
    <div class="d-flex align-items-center cursor-pointer">
      <div :id="'property_marker_'+property.uuid" @dragenter.prevent @dragleave.prevent @dragover.prevent
           @drop="dropPoint" class="w-20px bg-secondary h-20px rounded me-5 d-flex property
             justify-content-center align-items-center position-relative z-index-1" v-if="property">
        <div
            :class="{ 'bg-danger' : $store.state.questionDragged && $store.state.questionDragged.type.format !== property.type.format }"
            class="input point cursor-pointer rounded-circle position-relative">
        </div>

        <b-dropdown class="p-0 position-absolute w-100 z-index-2" dropup no-caret no-flip ref="filter" right size="sm"
                    tag="div" toggle-class="topbar-item text-decoration-none" v-if="mapped"
                    variant="link">
          <template v-slot:button-content>
            <div class="output point cursor-pointer rounded-circle">
            </div>
          </template>
          <template v-for="mapping in survey.mappings">
            <template v-if="property.uuid === mapping.propertyUuid">
              <b-dd-text>
                <div :set="question = getQuestionByUuid(mapping.questionUuid)"
                     class="py-2 px-3 d-flex justify-content-between align-items-center bg-hover-light-primary whitespace-no-wrap">
                  {{ question.label }}
                  <i :title="'Supprimer'" @click="removeMap(mapping)"
                     class="fad fa-times text-danger ms-5"></i>
                </div>
              </b-dd-text>
            </template>
          </template>
        </b-dropdown>
      </div>
      <label class="d-flex align-items-center cursor-pointer" v-if="item">
        <i class="fad fs-2 fa-caret-right me-3" v-if="!item.expand"></i>
        <i class="fad fs-2 fa-caret-down me-3" v-else></i>
        <input @change="recalculatePath" class="hidden" type="checkbox" v-model="item.expand">
        <div class="fw-bolder text-primary">
          <span v-if="property">
            {{ property.name }} ▪️
          </span>
          {{ item.name }}
        </div>
      </label>
    </div>
    <div class="ms-5" v-if="item && item.expand">
      <div class="d-flex my-1 align-items-center justify-content-between" v-for="prop in item.properties">
        <div class="d-flex flex-grow-1 cursor-pointer">
          <div :set="subItem = getItemByUuid(prop.value)"
               class="d-flex flex-grow-1 justify-content-between align-items-center"
               v-if="prop.type.code === 'OBJECT'">
            <!--                        {{prop.value}}-->
            <ItemLine :allItemTypes="allItemTypes" :item="subItem" :itemTypes="itemTypes" :property="prop"
                      :survey="survey" v-on:linkQuestionToProperty="linkQuestionToProperty"></ItemLine>
          </div>
          <div class="flex-grow-1 d-flex align-items-center" v-else>
            <PropertyLine :allItemTypes="allItemTypes" :property="prop" :survey="survey"
                          v-on:linkQuestionToProperty="linkQuestionToProperty"></PropertyLine>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import ItemType from "@/entity/ItemType";
import ItemProperty from "@/entity/ItemProperty";
import PropertyLine from "@/views/Configuration/Branch/Mapper/PropertyLine.vue";
import Mapping from "@/entity/Mapping";
import Survey from "@/entity/Survey";
import {helper} from "@/services/Helper";
import Question from "@/entity/Question";

@Component({
  components: {PropertyLine}
})
export default class ItemLine extends Vue {
  @Prop() item!: ItemType
  @Prop() itemTypes!: ItemType[]
  @Prop() allItemTypes!: ItemType[]
  @Prop({default: null}) property?: ItemProperty
  @Prop() survey!: Survey
  questionDragged = null
  expand = true

  beforeMount(): void {
    // console.log('Loading item '+this.item.name)
  }

  async removeMap(mapping: Mapping) {
    if (this.property) {
      const index = this.survey.mappings.findIndex((m: Mapping) => {
        return m.questionUuid === mapping.questionUuid && m.propertyUuid === this.property!.uuid
      })
      if (index !== -1) {
        this.survey.mappings.splice(index, 1)
        await this.$store.state.sleep(100)
        this.$emit('linkQuestionToProperty')
      }
    }
  }

  dropPoint(evt: any) {
    if (!helper.empty(evt.dataTransfer.getData('text/plain')) && this.property) {
      this.$emit('linkQuestionToProperty', {
        questionUuid: evt.dataTransfer.getData('text/plain'),
        propertyUuid: this.property.uuid
      })
    }
    // console.log(this.property.name)
    this.$store.state.questionDragged = null
    // console.log(this.mapped)
  }

  recalculatePath() {
    this.$emit('linkQuestionToProperty')
  }

  get mapped() {
    if (this.property) {
      const maps = this.survey.mappings.filter((m: Mapping) => {
        return m.propertyUuid === this.property!.uuid
      })
      // console.log('map length '+maps.length)
      // console.log(this.$store.state.questionDragged)
      return maps.length > 0 && this.$store.state.questionDragged === null
    }
    return null
  }

  getQuestionByUuid(uuid: string) {
    return this.survey.getQuestions().find((q: Question) => {
      return q.uuid === uuid
    })
  }

  getItemByUuid(uuid: string) {
    const item = this.allItemTypes.find((i: ItemType) => {
      return i.uuid === uuid
    })
    return item
  }

  linkQuestionToProperty(evt: any) {
    this.$emit('linkQuestionToProperty', evt)
  }

}
</script>
<style scoped>


.input {
  box-shadow: inset 3px 3px 6px #00000040, inset -3px -3px 6px #9c9ea066;
  background: cornflowerblue;
  pointer-events: none;
  width: 12px;
  height: 12px;
}

.output {
  position: absolute;
  background: whitesmoke;
  box-shadow: 0 0 2px rgba(150, 150, 150, .8);
  top: 0;
  left: 2px;
  z-index: 4;
  width: 16px;
  height: 16px;
  /*transition: opacity 0.2s ease;*/
  /*transform: translateX(-50%) translateY(-50%);*/
}
</style>
