<template>
    <div class="w-50px h-50px d-flex align-items-center justify-content-center cursor-pointer bg-primary badge SavedResponseGroup rounded-circle"
         style="position: absolute;left:20px;bottom:20px;z-index: 10000">
        <i class="fad fa-save text-white fs-2x"></i>
        <!-- {{trans('Vous avez ')}} <span>0</span> {{trans('cotations enregistrés')}}-->
    </div>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import ResponseGroup from "@/entity/ResponseGroup";
    import {api} from "@/services/Api";

    @Component({})
    export default class SavedResponseGroup extends Vue {
        responseGroups: ResponseGroup[] = []

        mounted() {
            this.getRgs()
        }

        async getRgs() {
            const res = await api.get(api.form, 'api/response/group/list')

            console.log(res)
        }
    }
</script>
<style scoped>
</style>
