/* eslint-disabled */
import AccountOperation from "./AccountOperation";
import PaymentMethod from "./PaymentMethod";
import Account from "./Account";
import Avenant from "./Avenant";
import Receipt from "./Receipt";
import PaymentTransactionExtend from "@/entity/extends/PaymentTransactionExtend";

export default class PaymentTransaction extends PaymentTransactionExtend {

    public id!: number;
    public token? = '';
    public initiator? = '';
    public status = '';
    public operations: Array<AccountOperation> = [];
    public amount!: number;
    public data? = '';
    public payedAt? = '';
    public method!: PaymentMethod;
    public type? = '';
    public target? = '';
    public errors? = '';
    public response? = '';
    public contact? = '';
    public source!: Account;
    public destination!: Account;
    public label? = '';
    public avenant!: Avenant;
    public receipt!: Receipt;
    public balance? = '';
    public uuid? = '';
    public createdAt?: Date;
    public updatedAt?: Date;
    public createBy? = '';
    public updateBy? = '';
    public removeBy? = '';
    public createdFromIp? = '';
    public updatedFromIp? = '';



    constructor(object?: any) {
        super(object)
        if (object) {
            this.id = object.id;
            this.token = object.token;
            this.initiator = object.initiator;
            this.status = object.status;
            if (object.operations) {
                object.operations.forEach((occ: any) => {
                    const accountoperation = occ instanceof AccountOperation ? occ : new AccountOperation(occ);
                    this.operations.push(accountoperation);
                });
            }
            this.amount = object.amount;
            this.data = object.data;
            this.payedAt = object.payedAt;
            this.method = (object.method instanceof PaymentMethod) ? object.method : object.method ? new PaymentMethod(object.method) : object.method;
            this.type = object.type;
            this.target = object.target;
            this.errors = object.errors;
            this.response = object.response;
            this.contact = object.contact;
            this.source = (object.source instanceof Account) ? object.source : object.source ? new Account(object.source) : object.source;
            this.destination = (object.destination instanceof Account) ? object.destination : object.destination ? new Account(object.destination) : object.destination;
            this.label = object.label;
            this.avenant = (object.avenant instanceof Avenant) ? object.avenant : object.avenant ? new Avenant(object.avenant) : object.avenant;
            this.receipt = (object.receipt instanceof Receipt) ? object.receipt : object.receipt ? new Receipt(object.receipt) : object.receipt;
            this.balance = object.balance;
            this.uuid = object.uuid;
            if (object.createdAt) {
                this.createdAt = new Date(object.createdAt);
            }
            if (object.updatedAt) {
                this.updatedAt = new Date(object.updatedAt);
            }
            this.createBy = object.createBy;
            this.updateBy = object.updateBy;
            this.removeBy = object.removeBy;
            this.createdFromIp = object.createdFromIp;
            this.updatedFromIp = object.updatedFromIp;
        }
        this.postConstruct()
    }

}
