<template>
  <div>

    <div class="card mb-10">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <label class="my-4 fw-bolder" for="">{{ trans('Branche') }}</label>
            <b-form-select class="form-control  form-select" v-model="search.branch">
              <b-form-select-option :value="null">{{ trans('Choisir une branche') }}
              </b-form-select-option>
              <template v-for="category in categories">
                <b-form-select-option :key="branch.uuid" :value="branch.slug"
                                      v-for="branch, key in category.branches">
                  {{ category.label }} - {{ trans(branch.label) }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col-3">
            <label class="my-4 fw-bolder" for="">{{ trans('Formulaires') }}</label>

            <b-dropdown class="flex-grow-1 w-100" no-caret no-flip right tag="div"
                        toggle-class="w-100 p-0 flex-grow-1" variant="link"
                        @click="(event)=> {event.stopPropagation()}">
              <template v-slot:button-content>
                <div
                    class="form-control flex-grow-1 text-start justify-content-between d-flex align-items-center w-100">
                  <template v-if="empty(search.forms)">
                    Selectionnez des formulaires
                  </template>
                  <template v-else>
                    <div class="w-100 overflow-auto d-flex">
                      <div v-for="code in search.forms" class="badge badge-primary mx-1 d-flex align-items-center">
                        {{ getSurveyNameByCode(code) }}
                        <i class="fad fa-times ps-2 cursor-pointer text-white" @click="toggleSurvey(code)"></i>
                      </div>
                    </div>
                  </template>
                  <i class="fad fa-angle-down ms-5 fs-2"></i>
                </div>
              </template>
              <b-dropdown-text class="min-w-md-350px p-0" tag="div">
                <template>
                  <div class="overflow-auto border-rounded" style="max-height:250px">
                    <div class="p-1">
                      <input type="text" placeholder="Rechercher" v-model="formSearched" class="form-control">
                    </div>
                    <template v-for="survey of surveys">
                      <template v-if="isFormVisible(survey)">
                        <div class="bg-hover-light-primary px-3 py-2 border-top"
                             @click="toggleSurvey(survey.slug)"
                             :class="{ 'bg-light-primary' : search.forms.indexOf(survey.slug) !== -1 }">
                          <div class="fw-bolder">
                            {{ survey.label }}
                          </div>
                          <div class="fw-bolder text-muted fs-8">
                            {{ survey.branch }}
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </template>
              </b-dropdown-text>
            </b-dropdown>

          </div>
          <template v-if="userType !== 'partner'">

            <div class="col-3">
              <label class="my-4 fw-bolder" for="">{{ trans('Gestionnaire') }}</label>
              <b-form-select class="form-control  form-select" v-model="search.manager">
                <b-form-select-option :value="null">{{ trans('Utilisateur') }}
                </b-form-select-option>
                <b-form-select-option :key="manager.uuid" :value="{ uuid: manager.uuid, email: manager.email }"
                                      v-for="manager in managers">
                  {{ manager.name + ' ' + manager.prename }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </template>
          <div class="col-3">
            <label class="my-4 fw-bolder" for="">{{ trans('Agences') }}</label>

            <div class="d-flex">
              <b-dropdown class="flex-grow-1 w-100" no-caret no-flip right tag="div"
                          toggle-class="w-100 p-0 flex-grow-1" variant="link"
                          @click="(event)=> {event.stopPropagation()}">
                <template v-slot:button-content>
                  <div
                      class="form-control flex-grow-1 text-start justify-content-between d-flex align-items-center w-100">
                    <template v-if="empty(search.partners)">
                      Selectionnez des Partenaires
                    </template>
                    <template v-else>
                      <div class="w-100 overflow-auto d-flex">
                        <div v-for="code in search.partners"
                             class="badge badge-primary mx-1 d-flex align-items-center">
                          {{ getPartnerNameByCode(code) }}
                          <i class="fad fa-times ps-2 cursor-pointer text-white" @click="togglePartner(code)"></i>
                        </div>
                      </div>
                    </template>
                    <i class="fad fa-angle-down ms-5 fs-2"></i>
                  </div>
                </template>
                <b-dropdown-text class="min-w-md-350px p-0" tag="div">
                  <template>
                    <div class="overflow-auto border-rounded" style="max-height:250px">
                      <div class="p-1">
                        <input type="text" placeholder="Rechercher" v-model="partnerSearched" class="form-control">
                      </div>
                      <div class="bg-hover-light-primary px-3 py-2 border-top" v-if="empty(partnerSearched)"
                           @click="togglePartner('all')"
                           :class="{ 'bg-light-primary' : search.partners.indexOf('all') !== -1 }">
                        <div class="fw-bolder">
                          Tous les partenaires
                        </div>
                        <div class="fw-bolder text-muted fs-8">
                          ALL
                        </div>
                      </div>
                      <template v-for="partner of partners">
                        <template
                            v-if="empty(partnerSearched) || (!empty(partnerSearched) && (!empty(partner.name) && partner.name.trim().toLowerCase().includes(partnerSearched.toLowerCase())) || (partner.code.trim().toLowerCase().includes(partnerSearched.toLowerCase())))">
                          <div class="bg-hover-light-primary px-3 py-2 border-top"
                               @click="togglePartner(partner.code)"
                               :class="{ 'bg-light-primary' : search.partners.indexOf(partner.code) !== -1 }">
                            <div class="fw-bolder">
                              {{ partner.name }}
                            </div>
                            <div class="fw-bolder text-muted fs-8">
                              {{ partner.code }}
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </b-dropdown-text>
              </b-dropdown>
            </div>
          </div>


          <div class="col-3">
            <label class="my-4 fw-bolder" for="">{{ trans('Agents') }}</label>

            <div class="d-flex">
              <b-dropdown class="flex-grow-1 w-100" no-caret no-flip right tag="div"
                          toggle-class="w-100 p-0 flex-grow-1" variant="link"
                          @click="(event)=> {event.stopPropagation()}">
                <template v-slot:button-content>
                  <div
                      class="form-control flex-grow-1 text-start justify-content-between d-flex align-items-center w-100">
                    <template v-if="empty(search.agents)">
                      Selectionnez des agents
                    </template>
                    <template v-else>
                      <div class="w-100 overflow-auto d-flex">
                        <div v-for="code in search.agents"
                             class="badge badge-primary mx-1 d-flex align-items-center">
                          {{ getPartnerUserNameByCode(code) }}
                          <i class="fad fa-times ps-2 cursor-pointer text-white" @click="togglePartnerUsers(code)"></i>
                        </div>
                      </div>
                    </template>
                    <i class="fad fa-angle-down ms-5 fs-2"></i>
                  </div>
                </template>
                <b-dropdown-text class="min-w-md-350px p-0" tag="div">
                  <template>
                    <div class="overflow-auto border-rounded" style="max-height:250px">
                      <div class="p-1">
                        <input type="text" placeholder="Rechercher" v-model="partnerSearched" class="form-control">
                      </div>
                      <div class="bg-hover-light-primary px-3 py-2 border-top" v-if="empty(partnerSearched)"
                           @click="togglePartnerUsers('all')"
                           :class="{ 'bg-light-primary' : search.partners.indexOf('all') !== -1 }">
                        <div class="fw-bolder">
                          Tous les agents
                        </div>
                        <div class="fw-bolder text-muted fs-8">
                          ALL
                        </div>
                      </div>
                      <template v-for="partner of partnerUsers">
                        <template
                            v-if="empty(partnerSearched) || (!empty(partnerSearched) && (!empty(partner.name) && partner.name.trim().toLowerCase().includes(partnerSearched.toLowerCase())) || (partner.code.trim().toLowerCase().includes(partnerSearched.toLowerCase())))">
                          <div class="bg-hover-light-primary px-3 py-2 border-top"
                               @click="togglePartnerUsers(partner.code)"
                               :class="{ 'bg-light-primary' : search.partners.indexOf(partner.code) !== -1 }">
                            <div class="fw-bolder">
                              {{ partner.name }}
                            </div>
                            <div class="fw-bolder text-muted fs-8">
                              {{ partner.code }}
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </b-dropdown-text>
              </b-dropdown>
            </div>
          </div>


          <!--    <template v-if="userType === 'partner'">
                   <div class="col-3">
                     <label class="my-4 fw-bolder" for="">{{ trans('Agents') }}</label>
                     <b-form-select class="form-control  form-select" v-model="search.agent">
                       <b-form-select-option :value="null">{{ trans('Agents') }}
                       </b-form-select-option>
                       <b-form-select-option :key="user.id"
                                             :value="codePartnerUser+'-'+shortener.converIdToCHar(user.id).toUpperCase()"
                                             v-for="user in usersByPartner">
                         {{ user.name }} | {{ shortener.converIdToCHar(user.id).toUpperCase() }}
                       </b-form-select-option>
                     </b-form-select>
                   </div>
                 </template>
       -->
          <div class="col-3">
            <label class="my-4 fw-bolder" for="">{{ trans('Contenant la réponse') }}</label>
            <input class="form-control" type="text" v-model="search.contains">
          </div>
          <div class="col-2 flex-grow-1">
            <label class="my-4 fw-bolder">{{ trans('Periode de création') }}</label>
            <div class="d-flex align-items-center">
              <date-range-picker
                  ref="picker"
                  :opens="'left'"
                  :locale-data="localData"
                  :singleDatePicker="false"
                  :autoApply="false"
                  :ranges="ranges"
                  @update="updateValues"
                  v-model="dateRange"
                  :timePicker="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :date-range="{ startDate : search.after, endDate: search.before }"
              >
                <template v-slot:input="picker" class="flex-grow-1">
                  <template v-if="picker.startDate && picker.endDate">
                    {{ readableDate(picker.startDate, 'dmY') }} ❖ {{ readableDate(picker.endDate, 'dmY') }}
                  </template>
                  <template v-else>
                    Selectionnez des dates
                  </template>
                </template>
              </date-range-picker>

            </div>
          </div>
          <!--          <div class="col-auto flex-grow-1">-->
          <!--            <label class="my-4 fw-bolder" for="">{{ trans('Dernière modification après') }}</label>-->
          <!--            <input class="form-control" type="date" v-model="search.after">-->
          <!--          </div>-->
          <!--          <div class="col-auto flex-grow-1">-->
          <!--            <label class="my-4 fw-bolder" for="">{{trans('Dernière modification avant') }}</label>-->
          <!--            <input class="form-control" type="date" v-model="search.before">-->
          <!--          </div>-->
          <div class="col-auto flex-grow-1">
            <label class="my-4 fw-bolder" for="">{{ trans('Niveau de complétion') }} (%)</label>
            <div class="d-flex">
              <input-number :max="100" :min="0" :value="0" v-model="search.minimalState">
              </input-number>
            </div>
          </div>
          <div class="col-auto flex-grow-1">
            <label class="my-4 fw-bolder" for="">{{ trans('Uuid / ID') }}</label>
            <div class="d-flex">
            <input type="text" v-model="search.uuid" class="form-control">
            </div>
          </div>
          <div class="col-auto flex-grow-1">
            <label class="my-4 fw-bolder" for="">{{ trans('Par page') }}</label>
            <div class="d-flex">
              <input-number :max="100" :min="0" :value="0" v-model="search.max">
              </input-number>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-auto flex-grow-1 m-w-300px">
            <label class="my-4 fw-bolder" for="">{{ trans('Status') }}</label>
            <div class="d-flex flex-wrap flex-grow-1">
              <label class="d-flex align-items-center cursor-pointer text-primary m-2"
                     v-for="option in rgStatutOptions">
                <div class="form-check form-check-custom  form-check-sm">
                  <!-- <input type="checkbox" class="form-check-input"> -->
                  <input type="checkbox" class="form-check-input" v-model="search.statut" :true-value="[]"
                         :value="option.code">
                </div>
                <div class="ms-2">{{ option.name }}</div>
              </label>
            </div>
          </div>
          <div class="col-auto flex-grow-1 m-w-300px">
            <label class="my-4 fw-bolder" for="">{{ trans('Qualification') }}</label>
            <div class="d-flex flex-wrap flex-grow-1">
              <label class="d-flex align-items-center cursor-pointer text-primary m-2">
                <div class="form-check form-check-custom  form-check-sm">
                  <input type="checkbox" class="form-check-input" v-model="search.qualification" :true-value="[]"
                         :value="null">
                </div>
                <div class="ms-2">En attente</div>
              </label>

              <label class="d-flex align-items-center cursor-pointer text-primary m-2">
                <div class="form-check form-check-custom  form-check-sm">
                  <input type="checkbox" class="form-check-input" v-model="search.qualification" :true-value="[]"
                         :value="true">
                </div>
                <div class="ms-2">Qualifiée</div>
              </label>

              <label class="d-flex align-items-center cursor-pointer text-primary m-2">
                <div class="form-check form-check-custom  form-check-sm">
                  <input type="checkbox" class="form-check-input" v-model="search.qualification" :true-value="[]"
                         :value="false">
                </div>
                <div class="ms-2">Disqualifiée</div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end m-5">
        <button @click="resetSearch" class="btn btn-sm btn-white btn-active-light-primary me-2" type="reset">
          Réinitialiser
        </button>
        <button @click="searchRgs" class="btn btn-sm btn-primary" type="submit">
          Rechercher
        </button>
      </div>
    </div>


    <div class="d-flex justify-content-end mb-5">
      <div class="btn btn-primary" @click="exportSearchResult(1)">
        <i class="fad fa-file-export"></i>
        Exporter
      </div>
    </div>
    <div class="card card-xxl-stretch mb-5 mb-xl-8">
      <!--      <div class="card-header border-0 pt-5">-->
      <!--        <h3 class="card-title align-items-start flex-column">-->
      <!--          <span class="card-label fw-bolder fs-3 mb-1">{{ trans('Cotations') }}</span>-->
      <!--          <span class="text-muted mt-1 fw-bold fs-7">Total()</span>-->
      <!--        </h3>-->
      <!--        <div class="card-toolbar" data-bs-original-title="Click to add a user" data-bs-placement="top"-->
      <!--             data-bs-toggle="tooltip" data-bs-trigger="hover" title="">-->
      <!--          <div class="">-->
      <!--            <span :title="'Réinitialiser la recherche'" @click="resetSearch"-->
      <!--                  class="btn btn-icon btn-light-danger btn-xs me-3 justify-content-center" v-b-tooltip.hover>-->
      <!--              <i class="fad fa-trash fw-bolder cursor-pointer"></i>-->
      <!--            </span>-->
      <!--            <b-dropdown no-caret no-flip ref="cotationFilter" right size="sm" style="padding:0" tag="div"-->
      <!--                        toggle-class="topbar-item text-decoration-none" variant="link">-->
      <!--              <template v-slot:button-content>-->
      <!--                <div class="cursor-pointer btn btn-secondary btn-sm me-3">-->
      <!--                  <i class="fad fa-search me-2"></i>-->
      <!--                  <template>Rechercher</template>-->
      <!--                </div>-->
      <!--              </template>-->
      <!--              <b-dropdown-text class="min-w-650px" tag="div">-->
      <!--                <div class="row">-->
      <!--                  <div class="col-6" v-if="userType !== 'partner'">-->
      <!--                    <label class="my-4 fw-bolder" for="">{{ trans('Gestionnaire') }}</label>-->
      <!--                    <b-form-select class="form-control  form-select" v-model="search.manager">-->
      <!--                      <b-form-select-option :value="null">{{ trans('Utilisateur') }}-->
      <!--                      </b-form-select-option>-->
      <!--                      <b-form-select-option :key="manager.uuid" :value="{ uuid: manager.uuid, email: manager.email }"-->
      <!--                                            v-for="manager in managers">-->
      <!--                        {{ manager.name + ' ' + manager.prename }}-->
      <!--                      </b-form-select-option>-->
      <!--                    </b-form-select>-->
      <!--                  </div>-->
      <!--                  <div class="col-6">-->
      <!--                    <label class="my-4 fw-bolder" for="">{{ trans('Branche') }}</label>-->
      <!--                    <b-form-select class="form-control  form-select" v-model="search.branch">-->
      <!--                      <b-form-select-option :value="null">{{ trans('Choisir une branche') }}-->
      <!--                      </b-form-select-option>-->
      <!--                      <template v-for="category in categories">-->
      <!--                        <b-form-select-option :key="branch.uuid" :value="branch.slug"-->
      <!--                                              v-for="branch, key in category.branches">-->
      <!--                          {{ category.label }} - {{ trans(branch.label) }}-->
      <!--                        </b-form-select-option>-->
      <!--                      </template>-->
      <!--                    </b-form-select>-->
      <!--                  </div>-->


      <!--                  <div class="col-6" v-if="userType !== 'partner'">-->
      <!--                    <label class="my-4 fw-bolder" for="">{{ trans('Partenaires') }}</label>-->

      <!--                    <div class="d-flex">-->
      <!--                      <b-dropdown class="flex-grow-1" no-caret no-flip right tag="div"-->
      <!--                                  toggle-class="topbar-item text-decoration-none p-0 flex-grow-1" variant="link"-->
      <!--                                  @click="(event)=> {event.stopPropagation()}">-->
      <!--                        <template v-slot:button-content>-->
      <!--                          <div-->
      <!--                              class="form-control flex-grow-1 text-start justify-content-between d-flex align-items-center">-->
      <!--                            Selectionnez des Partenaires-->
      <!--                            <i class="fad fa-angle-down ms-5 fs-2"></i>-->
      <!--                          </div>-->
      <!--                        </template>-->
      <!--                        <template>-->
      <!--                          <div>-->
      <!--                            OK-->
      <!--                          </div>-->
      <!--                        </template>-->
      <!--                        &lt;!&ndash;                      <template v-for="row in rows" class="btn btn-danger flex-grow-1 m-1 btn-sm cursor-pointer">&ndash;&gt;-->
      <!--                        &lt;!&ndash;                        <b-dd-item @click="sendResponse(row)" :key="row.id+'_'+genId()">{{ row.title }}</b-dd-item>&ndash;&gt;-->
      <!--                        &lt;!&ndash;                      </template>&ndash;&gt;-->
      <!--                      </b-dropdown>-->
      <!--                    </div>-->
      <!--                    &lt;!&ndash;                    <VueMultiselect :options="partners" :selectedLabel="'Ok'"&ndash;&gt;-->
      <!--                    &lt;!&ndash;                                    :tagPlaceholder="trans('Selectionner un partenaire')"&ndash;&gt;-->
      <!--                    &lt;!&ndash;                                    placeholder="Selectionner un partenaire"&ndash;&gt;-->
      <!--                    &lt;!&ndash;                                    select-label="+" :value="search.partner" @input="setPartner"&ndash;&gt;-->
      <!--                    &lt;!&ndash;                                    :customLabel="customPartnerLabel"&ndash;&gt;-->
      <!--                    &lt;!&ndash;                                    tag-placeholder="+" track-by="uuid">&ndash;&gt;-->
      <!--                    &lt;!&ndash;                    </VueMultiselect>&ndash;&gt;-->

      <!--                    &lt;!&ndash; <b-form-select class="form-control  form-select" v-model="search.partner">-->
      <!--                      <b-form-select-option :value="null">{{ trans('Partenaire') }}-->
      <!--                      </b-form-select-option>-->
      <!--                      <b-form-select-option :key="partner.uuid" :value="{ uuid: partner.uuid, email: partner.email }"-->
      <!--                        v-for="partner in $store.state.partners">-->
      <!--                        {{ partner.code + ' - ' + partner.name }}-->
      <!--                      </b-form-select-option>-->
      <!--                    </b-form-select> &ndash;&gt;-->
      <!--                  </div>-->


      <!--                  <div class="col-6">-->
      <!--                    <label class="my-4 fw-bolder" for="">{{ trans('Contenant') }}</label>-->
      <!--                    <input class="form-control" type="text" v-model="search.contains">-->
      <!--                  </div>-->
      <!--                  <div class="col-auto flex-grow-1">-->
      <!--                    <label class="my-4 fw-bolder" for="">{{ trans('Dernière modification après') }}</label>-->
      <!--                    <input class="form-control" type="date" v-model="search.after">-->
      <!--                  </div>-->
      <!--                  <div class="col-auto flex-grow-1">-->
      <!--                    <label class="my-4 fw-bolder" for="">{{-->
      <!--                        trans('Dernière modification avant')-->
      <!--                      }}</label>-->
      <!--                    <input class="form-control" type="date" v-model="search.before">-->
      <!--                  </div>-->
      <!--                  <div class="col-auto flex-grow-1">-->
      <!--                    <label class="my-4 fw-bolder" for="">{{ trans('Niveau de complétion') }}-->
      <!--                      (%)</label>-->
      <!--                    <div class="d-flex">-->
      <!--                      <input-number :max="100" :min="0" :value="0" v-model="search.minimalState">-->
      <!--                      </input-number>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                  <div class="col-auto flex-grow-1">-->
      <!--                    <label class="my-4 fw-bolder" for="">{{ trans('Par page') }}</label>-->
      <!--                    <div class="d-flex">-->
      <!--                      <input-number :max="100" :min="0" :value="0" v-model="search.max">-->
      <!--                      </input-number>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--                <div>-->
      <!--                  <div class="col-auto flex-grow-1">-->
      <!--                    <label class="my-4 fw-bolder" for="">{{ trans('Status') }}</label>-->
      <!--                    <div class="d-flex flex-wrap flex-grow-1">-->
      <!--                      <label class="d-flex align-items-center cursor-pointer text-primary m-2"-->
      <!--                             v-for="option in rgStatutOptions">-->
      <!--                        <div class="form-check form-check-custom  form-check-sm">-->
      <!--                          &lt;!&ndash; <input type="checkbox" class="form-check-input"> &ndash;&gt;-->
      <!--                          <input type="checkbox" class="form-check-input" v-model="search.statut" :true-value="[]"-->
      <!--                                 :value="option.code">-->

      <!--                        </div>-->
      <!--                        <div class="ms-2">{{ option.name }}</div>-->
      <!--                      </label>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--                <div class="d-flex justify-content-end my-5">-->
      <!--                  <button @click="resetSearch" class="btn btn-sm btn-white btn-active-light-primary me-2" type="reset">-->
      <!--                    Réinitialiser-->
      <!--                  </button>-->
      <!--                  <button @click="searchRgs" class="btn btn-sm btn-primary" type="submit">-->
      <!--                    Rechercher-->
      <!--                  </button>-->
      <!--                </div>-->
      <!--              </b-dropdown-text>-->
      <!--            </b-dropdown>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <Pagination :count="total" :itemLength="rgs.length" :matchCount="matchCount" v-model="search.page"
                  showEmpty="true"
                  class="p-10" :perPage="search.max" :key="'pagination_' + search.page"
                  @input="searchRgs($event)"></Pagination>
      <div class="card-body py-3">
        <div class="table-responsive">
          <table class="table table-row-dashed table-row-gray-300 align-middle  g-4">
            <thead>
            <tr class="fw-bolder text-muted">
              <th class="w-25px">
                <div class="form-check form-check-sm form-check-custom form-check-solid">
                  <input class="form-check-input" data-kt-check="true" data-kt-check-target=".widget-9-check"
                         type="checkbox" value="1">
                </div>
              </th>
              <th class="min-w-150px">{{ trans('Cotations') }}</th>
              <th class="min-w-140px">{{ trans('Prospect') }}</th>
              <th v-if="!compact">
                {{ trans('Valeur') }}
              </th>
              <!--              <th v-if="!compact">-->
              <!--                Prochaine activité-->
              <!--              </th>-->
              <!--              <th v-if="!compact && userType !== 'partner'">-->
              <!--                Gestionnaire-->
              <!--              </th>-->
              <th v-if="!compact && userType !== 'partner'">
                Partenaire
              </th>
              <th v-if="!compact && userType == 'partner'">
                Agents
              </th>
              <th class="min-w-120px">{{ trans('Status') }}</th>
              <th class="min-w-100px text-end">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="rgs.length === 0">
              <td colspan="9">
                <div class="d-flex flex-column justify-content-center pb-20 pt-20 flex-grow-1 align-items-center
                                                                border-bottom-dashed border-gray-300">
                  <i class="fad fa-empty-set fs-4x"></i>
                  <span class="mt-5 fs-2x text-gray-400">
                      {{ trans('Aucun resultat') }}
                    </span>
                </div>
              </td>
            </tr>
            <tr v-for="rg, index in rgs" :key="'cotation_' + rg.uuid" class="bg-hover-light-primary">
              <td>
                <div class="d-flex justify-content-center fw-bolder text-gray-400 mb-2">
                  <div v-if="rg.qualified === null" title="En Attente de qualification">
                    <i class="fad fa-question-circle fs-2"></i>
                  </div>
                  <div v-else-if="rg.qualified" title="Qualifée">
                    <i class="fad fa-thumbs-up text-success fs-2"></i>
                  </div>
                  <div v-else title="Disqualifiée">
                    <i class="fad fa-thumbs-down text-danger fs-2"></i>
                  </div>
                  <!--                  {{ rg.id }}-->
                </div>
                <div class="form-check form-check-sm form-check-custom form-check-solid">
                  <input class="form-check-input widget-9-check" type="checkbox" value="1">
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="d-flex justify-content-start flex-column">
                    <router-link :to="{
                        name: 'response-group-manager',
                        params: { surveySlug: rg.survey.slug, rgUuid: rg.uuid }
                      }" class="text-dark text-uppercase fw-bolder text-hover-primary fs-6" target="_blank">
                      <!--                      {{ rg.survey.branch }}-->
                      {{ rg.alias }}

                    </router-link>
                    <span class="fw-bolder d-block fs-7">
                        {{ rg.survey.label }} ▪️ {{ rg.source }}
                      </span>
                    <span class="text-muted fw-bold text-muted d-block fs-7">
                        {{ readableDate(rg.createdAt, 'dMy Hi') }}
                      </span>
                    <!--a :class="[rg.location.vpn ? 'text-danger' : 'text-primary']"
                      v-if="rg.location">
                      <i class="fad fa-map-marker-alt text-primary me-1"></i>
                      {{ rg.location.city }}, {{ rg.location.country }}
                      <template v-if="!compact">▪️ <span>{{ rg.location.ip }}</span></template>
                  </a-->
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex flex-column" v-if="rg.prospect">
                    <span class="fw-bolder">
                      {{ rg.prospect.name }} {{ rg.prospect.prename }}
                    </span>
                  <span class="fw-bolder text-gray-400">
                      <template v-if="!helper.empty(rg.prospect.email)">
                        {{ rg.prospect.email }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </span>

                  <div class="d-flex flex-wrap">
                    <div class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2"
                         v-if="!helper.empty(rg.prospect.mobile)">
                      {{ helper.phoneFormat(rg.prospect.mobile) }}
                      <div class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0">
                        <i class="fad fa-phone"></i>
                      </div>
                    </div>
                    <div class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2"
                         v-if="!helper.empty(rg.prospect.fixe)">
                      {{ helper.phoneFormat(rg.prospect.fixe) }}
                      <div class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0">
                        <i class="fad fa-phone"></i>
                      </div>
                    </div>
                  </div>
                  <!--a :title="rg.device.os + ' ' + rg.device.browser" href="#"
                    v-if="!compact && rg.device">
                    {{ rg.device.brand }}
                    <template v-if="!helper.empty(rg.device.model)">
                        ,{{ rg.device.model }}
                    </template>
                    <template v-if="!helper.empty(rg.device.browser)">
                        ,{{ rg.device.browser }}
                    </template>
                </a-->
                </div>
              </td>

              <td v-if="!compact" class="fw-bolder text-center">
                <template v-if="rg.value">
                  <span>{{ currency(rg.value.min) }}</span>
                  <span class="mx-1">-</span>
                  <span>{{ currency(rg.value.max) }}</span>
                </template>
                <template v-else>
                  -
                </template>
              </td>
              <!--              <td v-if="!compact">-->
              <!--              </td>-->
              <!--              <td class="text-gray-400 fw-bolder" v-if="!compact && userType !== 'partner'">-->
              <!--                <user-view v-model="rg.manager"></user-view>-->
              <!--              </td>-->
              <td v-if="!compact && userType !== 'partner'">
                <div v-if="!empty(rg.partner)">
                  <partner-view v-model="rg.partner"></partner-view>
                </div>
                <div v-if="!empty(rg.godfather)">
                  {{ rg.godfather }}
                </div>
              </td>

              <td v-if="!compact && userType === 'partner'">
                <div v-if="!empty(rg.partner)">
                  <partner-view v-model="rg.partner"></partner-view>
                </div>
                <div v-if="!empty(rg.godfather)">
                  {{ rg.godfather }}
                </div>
              </td>


              <td class="text-end">
                <div class="d-flex flex-column w-100 me-2">
                  <div class="d-flex flex-stack mb-2">
                    <span class="text-muted me-2 fs-7 fw-bold">{{ rg.state }} %</span>
                    <span>
                      <i class="fad fa-sync" @click="updateState(rg)"></i>
                    </span>
                  </div>
                  <div class="progress h-6px w-100">
                    <div :aria-valuenow="rg.state" :style="{ width: rg.state + '%' }" aria-valuemax="100"
                         aria-valuemin="0" class="progress-bar bg-primary" role="progressbar"></div>
                  </div>
                  <span :class="{

                      'badge-secondary': rg.status === 'INCOMPLETE',
                      'badge-dark': rg.status.match(/REACHABLE/),
                      'badge-light-success': rg.status === 'QUALIFIED',
                      'badge-light-danger': rg.status.match(/DISQUALIFIED/),
                      'badge-danger': rg.status.match(/COMPARED/),
                      'badge-info': rg.status.match(/OFFER_SELECTED/),
                      'badge-success': rg.status === 'VALIDATED',
                    }" class="badge text-uppercase mt-3 badge-secondary">
                      {{ CONSTANTS.responseGroupStatus[rg.status] }}
                    </span>
                </div>
              </td>
              <td class="text-end">

                <router-link target="_blank" :class="[compact ? 'btn-xs' : 'btn-sm']" :title="trans('Tous les details')"
                             :to="{ name: 'show-cotation', params: { uuid: rg.uuid } }"
                             class="btn btn-primary btn-icon m-1"
                             v-b-tooltip.hover>
                  <i class="fad fa-eye"></i>
                </router-link>
                <router-link :class="[compact ? 'btn-xs' : 'btn-sm']" :title="trans('Questionnaire')" :to="{
                    name: 'response-group-manager',
                    params: { surveySlug: rg.survey.slug, rgUuid: rg.uuid }
                  }" class="btn btn-icon btn-warning m-1" target="_blank" v-b-tooltip.hover>
                  <!--                                            <span class="svg-icon svg-icon-3">-->
                  <i class="fad fa-external-link"></i>
                  <!--                                            </span>-->
                </router-link>
                <div :class="[compact ? 'btn-xs' : 'btn-sm']" :title="trans('Supprimer')" @click="deleteRg(rg.uuid)"
                     class="btn btn-icon btn-danger m-1" v-b-tooltip.hover v-if="hasRole('ROLE_ADMIN')">
                  <!--                                            <span class="svg-icon svg-icon-3">-->
                  <i class="fad fa-trash"></i>
                  <!--                                            </span>-->
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div>
      <Pagination :count="total" :itemLength="rgs.length" :matchCount="matchCount" v-model="search.page"
                  showEmpty="true"
                  class="p-10" :perPage="search.max" :key="'pagination_' + search.page"
                  @input="searchRgs($event)"></Pagination>
    </div>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import ResponseGroup from "@/entity/ResponseGroup";
import Branch from "@/entity/Branch";
import Survey from "@/entity/Survey";
import Popup from "@/entity/Popup";
import BranchCategory from "@/entity/BranchCategory";
import InputNumber from "@/components/InputNumber.vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import Pagination from "@/components/Pagination.vue";
import UserView from "@/components/UserView.vue";
import PartnerView from "@/components/PartnerView.vue";
import CONSTANTS from '@/Utils/constant';
import User from '@/entity/User';
import {helper} from '@/services/Helper';
import {auth} from '@/services/Auth';
import Partner from '@/entity/Partner';

import {shortener} from "@/Utils/Shortener"
import DateRangePicker from "vue2-daterange-picker";

@Component({
  components: {Pagination, VueMultiselect, InputNumber, UserView, PartnerView, DateRangePicker}
})
export default class CotationSearch extends Vue {

  [x: string]: any;

  shortener = shortener
  @Prop({default: false}) readonly compact!: boolean
  userType: any = 'manager'
  surveys: Survey[] = [];
  branches: Branch[] = [];
  total = 0
  matchCount = 0
  partners: Partner[] = []
  categories: BranchCategory[] = [];
  rgs: ResponseGroup[] = []
  currentCategory: any = null;
  partnerSearched = ''
  formSearched = ''
  searchTemplate = {
    uuid:null,
    branch: null,
    after: null,
    before: null,
    createBefore: null,
    createAfter: null,
    contains: null,
    minimalState: 0,
    hasClientInfos: false,
    manager: null,
    partners: [],
    agents: [],
    forms: [],
    page: 1,
    max: 100,
    qualification: [null, true, false],
    statut: ['REACHABLE', 'QUALIFIED', 'COMPARED', 'OFFER_SELECTED', 'PAYMENT_INITIALIZED'] as any

  }
  search: any
  rgStatutOptions: any[] = []
  managers: User[] = []
  usersByPartner: Partner[] = []
  codePartnerUser: any = null

  dateRange:any = {
    startDate: null,
    endDate: null,
  };
  lastEditRange = {
    startDate: null,
    endDate: null
  };

  localData = {
    direction: 'ltr',
    format: 'mm/dd/yyyy',
    separator: ' - ',
    applyLabel: 'Valider',
    cancelLabel: 'Annuler',
    weekLabel: ' ',
    customRangeLabel: 'Custom Range',
    daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    firstDay: 0
  }

  loadRanges() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    this.ranges = {
      Aujourdhui: [today, today],
      Hier: [yesterday, yesterday],
      "Ce mois": [
        CONSTANTS.dateConstants.first_day_current_month(),
        CONSTANTS.dateConstants.last_day_current_month(),
      ],
      "Le mois dernier": [
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth(), 0),
      ],
      "Cette annee": [
        new Date(today.getFullYear(), 0, 1),
        new Date(today.getFullYear(), 11, 31),
      ],
    };
    this.$forceUpdate();
  }


  updateValues() {
    const startDate: Date = new Date(this.dateRange.startDate);
    const endDate: Date = new Date(this.dateRange.endDate);
    this.search = {
      ...this.search,
      createAfter: helper.reversedDateToHtml(startDate),
      createBefore: helper.reversedDateToHtml(endDate),
    };

    this.loadData();
  }
  ranges: any = false


  async updateState(rg: ResponseGroup) {
    this.$store.commit('loading')
    const res = await api.get(api.form, 'api/update/response/group/state/' + rg.uuid)
    this.$store.commit('stopLoading')
    if (res && res.data && res.data.responseGroup) {
      rg.state = res.data.responseGroup.state
    }
    console.log(res)
  }

  togglePartner(code: string) {

    const index = this.search.partners.indexOf(code)
    if (index !== -1) {
      this.search.partners.splice(index, 1)
    } else {
      if (code === 'all' || this.search.partners.includes('all')) {
        this.search.partners = ['all']
      } else {
        this.search.partners.push(code)
      }
    }
    this.$forceUpdate()
  }

  togglePartnerUsers(code: string) {

    const index = this.search.agents.indexOf(code)
    if (index !== -1) {
      this.search.agents.splice(index, 1)
    } else {
      if (code === 'all' || this.search.agents.includes('all')) {
        this.search.agents = ['all']
      } else {
        this.search.agents.push(code)
      }
    }
    this.$forceUpdate()
  }

  parseJwt(token: string): any {
    if (!token) {
      // this.logout()
      // console.log(token + ' token is empty');
      return {roles: []};
    }
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    // console.log(jsonPayload);
    return JSON.parse(jsonPayload);
  }

  toggleSurvey(code: string) {
    const index = this.search.forms.indexOf(code)
    if (index !== -1) {
      this.search.forms.splice(index, 1)
    } else {
      this.search.forms.push(code)
    }
    this.$forceUpdate()
  }

  getSurveyNameByCode(code: string) {
    const survey = this.surveys.find((survey: Survey) => {
      return survey.slug === code
    })
    if (survey) {
      if (!helper.empty(survey.label)) {
        return survey.label
      }
      return survey.slug
    }
    return code;
  }

  isFormVisible(survey: Survey) {

    if (helper.empty(this.formSearched)) {
      return true
    }
    if (survey.slug.toLowerCase().includes(this.formSearched.toLowerCase()) || survey.label.toLowerCase().includes(this.formSearched.toLowerCase())) {
      return true
    }
    // if (this.search.branch) {
    //   console.log(survey)
    //   console.log(this.search.branch)
    // }

    return false
  }


  partnerMatchSearch() {
    return helper.empty(this.partnerSearched)
        || (!helper.empty(this.partnerSearched) && (!helper.empty(this.partner.name) && this.partner.name.trim().toLowerCase().includes(this.partnerSearched.toLowerCase()))
            || (this.partner.code.trim().toLowerCase().includes(this.partnerSearched.toLowerCase())))
  }

  async exportSearchResult(page = 1, marker: any = null, download = false) {


    const maxPage = this.matchCount > 0 ? Math.ceil(this.matchCount / this.search.max) : 1;
    let message = '<div class="fw-bolder my-5 fs-2">Exportation de la page ' + page + '/ ' + maxPage + '</div>';
    if (page > maxPage) {
      message = '<div class="fw-bolder my-5 fs-2">Téléchargement du fichier en cours ...</div>';
      download = true;
    }

    this.$store.commit('loading', message)
    // return
    const now = new Date()
    if (!marker) {
      marker = helper.slugify(helper.readable(now))
    }
    let config = {}
    if (download) {
      config = {responseType: 'blob'};
    }
    const res = await api.post(api.form, 'response/group/print/search/' + page + '/' + marker, {search: this.search}, config)
    this.$store.commit('stopLoading')
    if (download) {
      api.downloadBlob(res)
      return
    }
    page++
    if (res && res.data) {
      if (download) {

      } else if (res.data.count) {
        if (res.data.count > 0) {
          this.exportSearchResult(page, marker, false)
        } else {
          console.log('must download')
          this.exportSearchResult(page, marker, true)
        }
      }
    }
  }

  getPartnerNameByCode(code: string) {
    if (code === 'all') {
      return 'Tous les partenaires';
    }
    const partner = this.partners.find((partner: Partner) => {
      return partner.code === code
    })
    if (partner) {
      if (!helper.empty(partner.name)) {
        return partner.name
      }
      return partner.code
    }
    return code;
  }


  getPartnerUserNameByCode(code: string) {
    if (code === 'all') {
      return 'Tous les partenaires';
    }
    const partner = this.partnerUsers.find((partner: Partner) => {
      return partner.code === code
    })
    if (partner) {
      if (!helper.empty(partner.name)) {
        return partner.name
      }
      return partner.code
    }
    return code;
  }

  customPartnerLabel(option: any) {
    // console.log(option)
    if (typeof option === 'string') {
      return option
    }
    return option.code
  }

  setPartner(e: any) {
    this.search.partner = e.code
    this.$forceUpdate()
  }

  beforeMount(): void {
    this.search = {...this.searchTemplate}
    this.loadRanges();
  }

  async loadPartnerUsers() {
    this.loadingUsers = true
    const res = await api.get(api.auth, 'partner/user/list')
    if (res && res.data) {
      // this.usersByPartner = []
      // res.data.users.forEach((p: any) => {
      //   const occ = new Partner(p)
      //   this.usersByPartner.push(occ)
      // })


      const partners = res.data.users


      if (partners) {
        this.partnerUsers = []
        partners.forEach((p: Partner) => {
          if (p.code.includes('-')) {
            this.partnerUsers.push(p)
          }
        })
      }


    }

    this.loadingUsers = false

  }

  partnerUsers: any[] = []

  async mounted() {


    // this.resetSearch()
    this.userType = localStorage.getItem('userType')
    this.setRgStatus()
    if (this.userType === 'manager') {
      const partners = await this.$store.getters.partners()
      if (partners) {
        this.partners = []
        partners.forEach((p: Partner) => {
          if (!p.code.includes('-')) {
            this.partners.push(p)
          }
        })
      }
      // this.partnerUsers = await this.$store.getters.partnerUsers()
    }

    this.codePartnerUser = this.parseJwt(this.$store.state.userToken).code
    this.loadPartnerUsers()
    this.loadSurveys()
    // this.getManagers()
    // let def = localStorage.getItem('cotationSearchFilter')
    // if (def) {
    //   def = JSON.parse(def)
    //   if (def) {
    //     this.search = def
    //   }
    // }
    this.load()
    this.searchRgs()
    this.managers = await api.loadManagers()

  }

  async getManagers() {
    const res = await api.get(api.auth, 'api/get/managers');
    res.data.managers.forEach((m: any) => {
      const occ = new User(m)
      // if (auth.userHasRole(occ, 'ROLE_MANAGER')) {
      this.managers.push(occ)
      // }
    })
  }

  setRgStatus() {
    const opts = CONSTANTS.responseGroupStatus;
    const keys = Object.keys(opts)
    this.rgStatutOptions = []
    keys.forEach((k: string) => {
      this.rgStatutOptions.push({name: opts[k], code: k})
    })
  }

  resetSearch() {
    this.search = {...this.searchTemplate}
    this.setRgStatus()
    this.dateRange = {
    startDate: null,
    endDate: null,
  };
  }

  async deleteRg(uuid: string) {
    const res = await api.delete(api.form, 'response/group/delete/' + uuid)
    if (res && res.data && res.data.status === 'success') {
      this.searchRgs()
    }
  }

  loading = false


  // surveys: Survey[] = []

  async loadSurveys() {
    if (helper.empty(this.surveys)) {
      const res = await api.get(api.form, 'survey/min/list')
      if (res && res.data && res.data.surveys) {
        res.data.surveys.forEach((s: any) => {
          const occ = new Survey(s)
          this.surveys.push(occ)
        })
      }
    }
  }

  async searchRgs(page: number | null = null) {
    if (page) {
      if (!isNaN(page!)) {
        // console.log('setting page ', page)
        this.search.page = parseInt(page + '')
      }
    }
    // console.log('search')
    const ref = this.$refs as any
    if (ref.cotationFilter) {
      ref.cotationFilter.hide()
    }
    const popup = new Popup('Chargement', 'Recherche des cotations', 'success', 'fad fa-sync', true)
    localStorage.setItem('cotationSearchFilter', JSON.stringify(this.search))
    const res = await api.post(api.form, 'response/group/search', {search: this.search})
    popup.hide()
    if (res && res.data && res.data.responseGroups) {
      this.rgs = []
      // console.log(res.data)
      this.matchCount = res.data.count
      this.total = res.data.total
      res.data.responseGroups.forEach((r: ResponseGroup) => {
        this.rgs.push(new ResponseGroup(r))
      })
    }
    // console.log(res)
  }

  async load() {
    this.$store.commit('loading');
    this.categories = await this.$store.getters.branchCategories()
    this.$store.commit('stopLoading')
  }
}
</script>
<style scoped></style>
