<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <Base>
        <template v-slot:content>
            <div class="d-flex flex-wrap" v-for="survey in surveys">
                <router-link :to="{ name: 'comparator-form-processor', params: { surveySlug: survey.slug, uuid : 'new' } }"
                             class="cursor-pointer bg-hover-primary text-hover-white shadow-sm card m-5">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center min-w-150px">
                            <span class="mt-5">
                            {{survey.label}}
                                </span>
                    </div>
                </router-link>

            </div>
        </template>
        <SavedResponseGroup></SavedResponseGroup>
    </Base>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import Base from "@/layouts/Base.vue";
    import {api} from "@/services/Api";
    import Survey from "@/entity/Survey";
    import Branch from "@/entity/Branch";
    import BranchCategory from "@/entity/BranchCategory";
    import SavedResponseGroup from "@/views/Income/Comparator/SavedResponseGroup.vue";

    @Component({
        components: {SavedResponseGroup, Base}
    })
    export default class FormSelector extends Vue {

        surveys: Survey[] = [];
        branches: Branch[] = [];
        categories: BranchCategory[] = [];
        currentCategory: any = null
        PLATFORM = process.env.VUE_APP_PLATFORM

        mounted() {
            this.load()
        }

        async load() {
            this.$store.commit('loading');
            const res = await api.get(api.form, 'survey/list/' + this.PLATFORM + '/' + this.$route.params.slug);
            if (res && res.data && res.data.surveys) {
                if (res.data.surveys.length === 1) {
                    this.$router.push({
                        name: 'comparator-form-processor',
                        params: {surveySlug: res.data.surveys[0].slug, uuid: 'new'}
                    })
                } else {
                    this.surveys = [];
                    res.data.surveys.forEach((s: Survey) => {
                        this.surveys.push(new Survey(s))
                    })
                }
            }
            this.$store.commit('stopLoading')
        }
    }
</script>
<style scoped>
</style>
