/* eslint-disabled */

export default class PremiumDetailExtend {

  edit = false
  

  constructor(object?: any) {
  }

  postConstruct() {
  }
}
