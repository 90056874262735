<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";

@Component({
  components: {Base}
})
export default class SellDashboard extends Vue {

}
</script>

<template>
  <Base></Base>
</template>

<style scoped>

</style>