<template>
    <Base>

    </Base>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import Base from "@/layouts/Base.vue";

    @Component({
        components: {Base}
    })
    export default class ContractParameters extends Vue {
    }
</script>
<style scoped>
</style>
