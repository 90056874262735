<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    Tableau de bord
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <button class="btn btn-primary btn-sm ms-3" @click="createUser" v-b-modal="'editUser'">
              <i class="fad fa-plus"></i>
              {{ trans('Créer un utilisateur') }}
            </button>
          </div>

        </div>
      </div>
    </template>
    <template #content>
      <div class="card">
        <div class="card-body p-0">
          <table class="table table-striped gy-7 gs-7">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>{{ trans('Utilisateur') }}</th>
              <th>{{ trans('Email') }}</th>
              <th>{{ trans('Contact') }}</th>
              <th>{{ trans('Code') }}</th>
              <th>{{ trans('Agence') }}</th>
              <th>{{ trans('Créer le') }}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="user in users">
              <tr :key="user.id">
                <td>
                  {{ user.name }}
                </td>
                <td>
                  {{ user.email }}
                </td>
                <td>
                  {{ user.contact }}
                </td>
                <td>
                  {{ user.code }}
                </td>
                <td>
                  {{ getAgencyNameByCode(user.code) }}
                </td>
                <td>
                  {{ readable(user.createdAt, 'dmY H:i') }}
                </td>
                <td>
                  <div class="d-flex">
                    <div class="btn btn-icon btn-xs btn-warning" @click="editUser(user)" v-b-modal="'editAgency'">
                      <i class="fad fa-edit"></i>
                    </div>
                    <div class="btn btn-xs btn-icon btn-danger ms-2"
                         v-b-modal="'userModal'">
                      <i class="fad fa-ban"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </template>

            </tbody>
          </table>
          <div>

          </div>
        </div>
      </div>

      <b-modal :title="trans('Utilisateurs')" centered id="editUser" ref="editUser">
        <form>
          <div class="row" v-if="user && $store.state.partner">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Noms') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(user.name)" v-model="user.name">
              </b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Email') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.email)" v-model="user.email">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Agence') }}</label>
              <select v-model="user.code" class="form-control form-select">
                <option :value="$store.state.partner.code">{{ $store.state.partner.name }}</option>
                <option :value="agency.code" v-for="agency in agencies">{{ agency.name }}</option>
              </select>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Mot de passe') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.password)" v-model="user.password">
              </b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Contact') }}</label>
              <b-form-input :id="'code'" :state="!helper.empty(user.contact)" v-model="user.contact">
              </b-form-input>
            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveUser" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Partner from "@/entity/Partner";
import {api} from "@/services/Api";
import Base from "@/layouts/Base.vue";
import partner from "@/router/partner";

@Component({
  components: {Base}
})
export default class PartnerUserList extends Vue {
  users: Partner[] = []
  user = new Partner()

  mounted() {
    this.loadUsers()
    this.loadAgencies()
  }

  agencies: Partner[] = []

  async loadAgencies() {
    const res = await api.get(api.core, 'api/partner/agencies/list')
    if (res && res.data) {
      this.agencies = []
      res.data.agencies.forEach((p: any) => {
        const occ = new Partner(p)
        this.agencies.push(occ)
      })
    }
  }

  async loadUsers() {
    const res = await api.get(api.auth, 'partner/api/user/list')
    if (res && res.data) {
      this.users = []
      res.data.users.forEach((p: any) => {
        const occ = new Partner(p)
        this.users.push(occ)
      })
    }
  }

  getAgencyNameByCode(code: string) {

    let partnerCode: any = null
    if (code.includes('-')) {
      partnerCode = code.split('-')
      if (partnerCode.length > 1) {
        partnerCode.pop()
        partnerCode = partnerCode.join('-')
      }
      if(partnerCode){
        code = partnerCode
      }
    }

    const agency = this.agencies.find((agency: Partner) => {
      return agency.code === code
    })



    return agency ? agency.name : code
  }

  editUser(user: Partner) {
    this.user = new Partner(user);
    (this.$refs as any).editUser.show()
  }

  createUser() {
    this.user = new Partner();
    this.user.code = this.$store.state.partner.code;
    (this.$refs as any).editUser.show()
  }

  async saveUser() {
    // this.user.code = this.partner.code
    const res = await api.post(api.auth, 'partner/api/edit', {partner: this.user});
    (this.$refs as any).editUser.hide()
    this.loadUsers()

  }
}
</script>


<style scoped>

</style>
