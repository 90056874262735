<template>
  <div :key="'question_' + question.uuid" class="flex-grow-1 m-2 question" v-if="question.isAuthorized()">
    <label class="fw-bolder my-2 d-flex justify-content-between" for="">
      <div>
            <span :title="question.visibility" class="cursor-pointer" v-b-tooltip.hover
                  v-if="question.visibility !== 'PUBLIC'">
                <i class="fad fa-lock text-danger"></i>
            </span>
        {{ question.label }}

        <span :title="trans('Mise à jour en cours')" class="input-status d-flex align-items-center" v-b-tooltip.hover
              v-if="question.isSending">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
        <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover
              v-else-if="!question.isValid">
                    <i class="fad fa-exclamation-circle text-danger"></i>
                </span>
      </div>
      <!--            <span @click="$store.commit('resetResponse',question)" class="btn btn-xs btn-icon" v-if="hasRole('ROLE_MANAGER')"><i class="fad fa-trash text-danger"></i></span>-->
    </label>

    <b-input-group
        :class="[question.isSending ? 'border-secondary' : question.isValid ? 'border-success' : 'border-danger']"
        class="rounded border-1 d-flex flex-grow-1">

      <b-input-group-prepend class="d-flex flex-column" v-if="question.prefix">
        <div class="input-group-text flex-grow-1">
          <template v-if="isIcon(question.prefix)">
            <i :class="question.prefix"></i>
          </template>
          <template v-else>
            {{ question.prefix }}
          </template>
        </div>
      </b-input-group-prepend>
      <div class="input-container flex-grow-1 rounded d-flex">
        <div>
          <div v-for="choice in question.choices" :key="question.uuid+'_'+choice.id"
               class="d-flex my-2 align-items-center">
            <div class="form-check form-switch form-check-custom form-check-solid me-2">
              <input class="form-check-input h-20px w-30px" type="checkbox" @change="toggle(choice.code)" :checked="isSelected(choice.code)"/>
            </div>
            <div class="fw-bolder">{{ choice.label }}</div>
          </div>
        </div>

        <!--        <span :title="trans(question.message)" class="input-status d-flex align-items-center" v-b-tooltip.hover-->
        <!--              v-else-if="question.isValid">-->
        <!--                    <i class="fad fa-check-circle text-success"></i>-->
        <!--                </span>-->
      </div>
      <b-input-group-append v-if="question.suffix">
        {{ question.suffix }}
      </b-input-group-append>
    </b-input-group>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Question from "@/entity/Question";
import {helper} from "@/services/Helper";

@Component({})
export default class MultipleChoice extends Vue {
  @Prop() question!: Question

  mounted(): void {
  }

  isSelected(choice: string) {
    let data: any[] = []
    if (!helper.empty(this.question.response.value)) {
      data = JSON.parse(this.question.response.value as any)
    }
    return data.includes(choice)
  }

  toggle(choice: string) {
    // console.log(this.question.response.value)
    let data: any[] = []
    if (!helper.empty(this.question.response.value)) {
      data = JSON.parse(this.question.response.value as any)
    }
    if (data.includes(choice)) {
      const index = data.indexOf(choice)
      data.splice(index, 1)
    } else {
      data.push(choice)
    }
    // console.log(data)
    this.question.response.value = JSON.stringify(data)
    this.question.updateAnswer()
  }

  selectTrue() {
    // console.log('true')
    if (this.question.response.value === 'true') {
      this.$store.commit('responseSaved')
    } else {
      this.question.response.value = "true"
      this.question.updateAnswer()
    }
  }

  selectFalse() {
    if (this.question.response.value === 'false') {
      this.$store.commit('responseSaved')
    } else {
      this.question.response.value = "false"
      this.question.updateAnswer()
    }
  }

  // type = 'text'
}
</script>
<style scoped>
.choice i {
  color: inherit !important;
}

.bg-green {
  background: #3DDAD7 !important;
}

.bg-light-green {
  background: rgba(61, 218, 215, 0.45);
}

.bg-hover-light-green:hover {
  background: rgba(61, 218, 215, 0.40);
}
</style>
