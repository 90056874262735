import {RouteConfig} from "vue-router";
import CookieDisabled from "@/views/Errors/CookieDisabled.vue";


const prefix = '/error'
const errorRoutes: Array<RouteConfig> = [
    {
        path: '/cookie/disabled',
        name: 'error-cookie-disabled',
        component: CookieDisabled,
        meta: {requiredRoles: ['ROLE_COMMUNICATION']},
    },


]
errorRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default errorRoutes;
