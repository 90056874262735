<template>
  <Base>

    <template #content>

      <b-tabs active-nav-item-class="" active-tab-class="" nav-wrapper-class="bg-white px-10  rounded-bottom nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5
                                                          fw-bolder flex-nowrap flex-grow-1">
        <b-tab :title-link-class="'nav-link text-active-primary py-5'" v-for="category in categories"
               @click="updateCategory(category)"
               :key="category.id">
          <template #title>
            <div class="d-flex align-items-center ">
              <!-- <i class="fad fa-users fs-2 me-2"></i> -->
              {{ category.label }}
            </div>
          </template>
          <template #default>


            <b-tabs active-nav-item-class="" active-tab-class="" nav-wrapper-class="bg-white px-10 rounded-bottom nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5
                                                          fw-bolder flex-nowrap flex-grow-1">
              <b-tab :title-link-class="'nav-link text-active-primary py-5'"
                     v-for="branch in category.branches" @click="updateBranch(branch)"
                     :key="'branch_'+branch.id">
                <template #title>
                  <div class="d-flex align-items-center ">
                    {{ branch.label }}
                  </div>
                </template>
                <template #default>
                  <b-tabs active-nav-item-class="" active-tab-class=""
                          nav-wrapper-class="bg-white px-10 rounded-bottom nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap flex-grow-1">
                    <b-tab :title-link-class="'nav-link text-active-primary py-5'" @click="updateInsurer(insurer)"
                           v-for="insurer in availableInsurers"
                           :key="'insurer-'+insurer.id">
                      <template #title>
                        <div class="d-flex align-items-center ">
                          <!-- <i class="fad fa-users fs-2 me-2"></i> -->
                          {{ insurer.label }}
                        </div>
                      </template>
                      <template #default>

                      </template>
                    </b-tab>
                  </b-tabs>
                  <div class="card">
                    <div class="card-body">

                      <div class="d-flex justify-content-between mb-5">
                        <div>
                          <input type="text" class="form-control" v-model="search" placeholder="Rechercher">
                        </div>
                        <div class="form-check form-switch form-check-custom form-check-solid ">
                          <label class="me-5 fs-5" v-if="!all">Tout cocher</label>
                          <label class="me-5 fs-5" v-else>Tout décocher</label>
                          <input @change="toggleAll" class="form-check-input h-30px w-50px"
                                 type="checkbox" v-model="all" value=""/>
                        </div>
                      </div>
                      <table class="table table-striped table-head-custom table-vertical-center gx-5">
                        <thead>
                        <tr>
                          <th></th>
                          <th v-for="pack in packs" class="text-uppercase fw-bolder">
                            {{ pack.label }}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="partner in partners">
                        <tr :key="partner.id"  class="border-top-1" v-if="empty(search) || (partner.name && partner.name.toLowerCase().includes(search.toLowerCase()) || partner.code.toLowerCase().includes(search.toLowerCase()))">
                          <td class="mw-150px">
                            <div>
                              {{ partner.name }}
                            </div>
                            <div class="text-muted fs-8">
                              {{ partner.code }}
                            </div>
                          </td>
                          <td v-for="pack in packs">
                            <div class="form-check form-switch form-check-custom form-check-solid ">
                              <input @change="toggle(pack,partner)" class="form-check-input h-30px w-50px"
                                     :checked="isChecked(pack,partner)"
                                     type="checkbox" value=""/>
                            </div>
                          </td>
                        </tr>
                        </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </b-tab>
            </b-tabs>


          </template>

        </b-tab>
      </b-tabs>

    </template>
  </Base>
</template>


<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Partner from "@/entity/Partner";
import Popup from "@/entity/Popup";
import Insurer from "@/entity/Insurer";
import BranchCategory from "@/entity/BranchCategory";
import Pack from "@/entity/Pack";
import Branch from "@/entity/Branch";

@Component({
  components: {Base}
})
export default class PartnerPackRestriction extends Vue {

  all = false
  search = ''

  async toggleAll(event: any) {
    const res = await api.get(api.core, 'partner/toggle/branch/packs/' + this.branch.slug + '/' + this.insurer.id + '/' + event.target.checked)
    console.log(res)
    this.loadPartners()
  }

  async toggle(pack: any = null, partner: any = null) {
    const res = await api.get(api.core, 'partner/toggle/pack/restriction/' + partner.id + '/' + pack.id)
    if (res && res.data && res.data.allowed && partner instanceof Partner) {
      partner.allowedPacks = res.data.allowed
    }
  }

  isChecked(pack: Pack, partner: Partner) {
    return partner.allowedPacks.includes(pack.id)
  }

  partners: Partner[] = []
  insurers: Insurer[] = []
  availableInsurers: Insurer[] = []
  categories: BranchCategory[] = []
  packs: Pack[] = []
  category: any = null
  branch: any = null
  insurer: any = null

  mounted() {
    this.loadPartners()
    this.loadInsurers()
    this.loadBranches()
  }

  updateInsurer(insurer: Insurer) {
    this.insurer = insurer
    this.loadPacks()
  }

  updateBranch(branch: Branch) {
    this.branch = branch
    this.loadPacks()
  }

  updateCategory(category: BranchCategory) {
    this.category = category
    if (category.branches.length > 0) {
      this.branch = category.branches[0]
    }
    this.loadPacks()
  }

  loadPacks() {
    if (this.category && this.branch && this.insurer) {
      this.packs = []
      this.availableInsurers = []
      this.insurers.forEach((i: Insurer) => {
        i.packs.forEach((p: Pack) => {
          if (!this.availableInsurers.includes(i) && p.branchSlug === this.branch.slug) {
            this.availableInsurers.push(i)
          }
        })
        if (i.id === this.insurer.id) {
          i.packs.forEach((p: Pack) => {
            if (p.branchSlug === this.branch.slug) {
              this.packs.push(p)
            }
          })
        }
        let unchecked = false

        for (let partner of this.partners) {
          for (let pack of this.packs) {
            if (!partner.allowedPacks.includes(pack.id)) {
              unchecked = true;
              this.all = false
              return
            }
          }
        }
        if (!unchecked) {
          this.all = true
        }
      })
    }
  }

  appendPartner(partner: Partner) {
    const match = this.partners.find((p: Partner) => {
      return partner.id === p.id
    })
    if (!match) {
      this.partners.push(partner)
    }
    partner.agencies.forEach((a: Partner) => {
      this.appendPartner(a)
    })
  }

  async loadPartners() {
    if (this.$route.params.code) {
      const res = await api.get(api.core, 'partner/get/' + this.$route.params.code)
      if (res && res.data) {
        const p = new Partner(res.data.partner)
        this.appendPartner(p)

      }
    } else {
      const res = await api.get(api.core, 'partner/top/list')
      if (res && res.data) {
        this.partners = []
        res.data.partners.forEach((p: Partner) => {
          this.partners.push(new Partner(p))
        })
      }
    }
    this.loadPacks()
  }

  async loadInsurers() {
    const popup = new Popup('Chargement des assureurs', '', 'warning', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'insurer/list')
    if (res && res.data && res.data.insurers) {
      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        this.insurers.push(new Insurer(i))
      })
      if (this.insurers.length > 0) {
        this.insurer = this.insurers[0]
      }
    }
    popup.hide()
    this.loadPacks()
  }

  async loadBranches() {
    const pop = new Popup('Chargement', 'Liste des branches', 'primary', 'fad fa-sync', true)
    const res = await api.get(api.core, 'branch/category/list')
    if (res && res.data && res.data.categories) {
      this.categories = []
      res.data.categories.forEach((cat: BranchCategory) => {
        this.categories.push(new BranchCategory(cat))
      })
    }
    if (this.categories.length > 0 && this.category === null) {
      this.category = this.categories[0]
      if (this.category.branches.length > 0) {
        this.branch = this.category.branches[0]
      }
    }
    pop.hide()
    this.loadPacks()
  }

  orderByInsurer() {

  }

  orderByCategory() {

  }


}
</script>


<style scoped>

</style>
