import { render, staticRenderFns } from "./Delivery.vue?vue&type=template&id=b4455ad2&scoped=true"
import script from "./Delivery.vue?vue&type=script&lang=ts"
export * from "./Delivery.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4455ad2",
  null
  
)

export default component.exports