<template>
    <div :style="{ 'background' : line.ranking % 2 === 0 ? 'whitesmoke' : 'white' }"
         class="bg-hover-light-primary border-bottom position-relative line w-100">

        <b-dropdown no-caret no-flip right class="position-absolute add-line-before d-none"
                    style="top:-10px;right:50px;"
                    tag="div" toggle-class="topbar-item text-decoration-none p-0" variant="link">
            <template v-slot:button-content>
                <div class="btn btn-xs btn-primary btn-icon rounded-circle">
                    <i class="fad fa-plus"></i>
                </div>
            </template>
            <b-dd-item @click="addDefaultLineBefore">Ajouter un Calcul</b-dd-item>
            <b-dd-item @click="addConditionLineBefore">Ajouter une condition</b-dd-item>
            <b-dd-item @click="addCallableLineBefore">Ajouter une fonction</b-dd-item>
            <b-dd-item @click="addForeachLineBefore">Ajouter une iteration</b-dd-item>
        </b-dropdown>


<!--        <div @click="addLineBefore(line)" class="btn-primary rounded-pill btn btn-xs btn-icon position-absolute add-line-before d-none"-->
<!--             style="top:-10px;right:50px;" title="Ajouter une ligne ici" v-b-tooltip.hover>-->
<!--            <i class="fad fa-plus"></i>-->
<!--        </div>-->
        <div :title="'Ligne '+line.ranking" class="position-absolute text-dark badge badge-warning"
             style="right:-35px;top:50%;transform: translateY(-50%)" v-b-tooltip.hover>
        <span>
            {{line.ranking}}
        </span>
        </div>
        <div class="d-flex align-items-center" v-if="line.type === 'callable'">
            <div class="mx-1" title="Définir comme résultat" v-b-tooltip>
                <div class="form-check form-check-custom form-check-sm">
                    <input class="form-check-input" type="checkbox" v-model="line.isResult"/>
                </div>
            </div>
            <!--            <div class="d-flex align-items-center ps-1 align-self-stretch">-->
            <!--                <div :title="line.returnArgument.type.label" @dragstart="dragResult"-->
            <!--                     class="return-argument py-1 bg-primary px-3 rounded text-white"-->
            <!--                     draggable="true"-->
            <!--                     v-b-tooltip v-if="line.returnArgument">-->
            <!--                    <template v-if="helper.empty(line.returnArgument.alias)">-->
            <!--                        {{line.returnArgument.name}}-->
            <!--                    </template>-->
            <!--                    <template v-else>{{line.returnArgument.alias}}</template>-->
            <!--                </div>-->
            <!--                <span class="ms-2">=</span>-->
            <!--            </div>-->

            <template v-if="!editReturn">
                <div class="d-flex align-items-center ps-1 align-self-stretch">
                    <div :title="line.returnArgument.type.label" @dragstart="dragResult"
                         class="return-argument py-1 bg-primary px-3 rounded text-white"
                         draggable="true"
                         v-b-tooltip v-if="line.returnArgument">
                        <template v-if="helper.empty(line.returnArgument.alias)">
                            {{line.returnArgument.name}}
                        </template>
                        <template v-else>{{line.returnArgument.alias}}</template>
                    </div>

                </div>
                <label class="cursor-pointer">
                    <i class="fad fa-edit ms-2"></i>
                    <input class="hidden" type="checkbox" v-model="editReturn">
                </label>
            </template>
            <template v-else>
                <div class="d-flex align-items-center ps-1 align-self-stretch">
                    <div class="return-argument py-1 bg-primary px-3 rounded text-white" v-if="line.returnArgument">
                        <input :ref="'resultInput'" class="border-none text-white bg-transparent min-w-50px"
                               style="outline: none"
                               type="text" v-model="line.returnArgument.alias">
                    </div>
                </div>
                <label class="cursor-pointer">
                    <i class="fad fa-check-double ms-2"></i>
                    <input class="hidden" type="checkbox" v-model="editReturn">
                </label>
            </template>


            <div class="flex-grow-1 d-flex align-items-center">
                <div class="align-self-stretch d-flex align-items-center mx-3" v-if="!line.callable">
                    <b-dropdown no-caret no-flip right size="sm"
                                tag="div" toggle-class="topbar-item text-decoration-none" variant="link">
                        <template v-slot:button-content>
                            <button class="btn btn-sm btn-clean btn-dropdown btn-primary">
                                <template v-if="helper.empty(line.callable)">
                                    {{trans('Selectionnez un Calcul')}}
                                </template>
                                <template v-else>
                                    {{line.callable.label}}
                                </template>
                            </button>
                        </template>
                        <template #default="{ hide }">
                            <b-dropdown-item class="p-0 min-w-200px" tag="div">
                                Selectionnez un calcul
                            </b-dropdown-item>
                            <template v-for="callable in methods">
                                <b-dropdown-item @click="setCallable(callable)" class="p-0 min-w-200px" tag="div"
                                                 v-if="callable.id !== $store.state.engineMethod.id">
                                    {{callable.label}}
                                </b-dropdown-item>
                            </template>
                        </template>
                    </b-dropdown>
                </div>
                <div class="ms-3 d-flex align-items-center" v-else>
                    <router-link :to="{ name : 'engine-method-editor', params: { methodId: line.callable.id, methodSlug: line.callable.code } }"
                                 class="fw-bolder text-primary text-decoration-underline me-1"
                                 target="_blank">
                        {{line.callable.label}}
                    </router-link>
                    <div class="fw-bolder text-primary">
                        (
                    </div>
                    <div class="d-flex align-items-center">
                        <template v-for="req,index in line.callable.requirements">
                            <span class="fw-bolder mx-1" v-if="index>0">,</span>
                            <template v-if="getPlaceForRequirement(req)">
                                <template v-for="place in line.places">
                                    <template v-if="place.requirement === req.id">
                                        <div class="fs-8 text-gray-400 fw-bolder ms-1">
                                            {{req.label}} :
                                        </div>
                                        <div class="mx-1 bg-light-success px-2 rounded fw-bolder">
                                            <span class="text-success">
                                                {{place.argument.alias ? place.argument.alias : place.argument.name}}
                                            </span>
                                        </div>
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <div :data-id="req.id" @dragover.prevent @drop="linkArgumentToRequirement"
                                     class="mx-1 bg-light-danger px-2 rounded fw-bolder text-danger">
                                    {{req.label}}
                                </div>
                            </template>
                        </template>
                    </div>
                    <div class="fw-bolder text-primary">
                        )
                    </div>
                    <div>
                        <div @click="resetCallable" class="bg-danger px-2 ms-2 rounded cursor-pointer">
                            <i class="fad fa-times text-white "></i>
                        </div>
                    </div>

                </div>
            </div>
            <div class="d-flex pe-5 py-3">
                <div @click="removeLine" class="btn btn-xs btn-danger btn-icon">
                    <i class="fad fa-trash"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import EngineMethodLine from "@/entity/EngineMethodLine";
    import {helper} from "@/services/Helper";
    import Insurer from '@/entity/Insurer';
    import Engine from '@/entity/Engine';
    import EngineMethod from '@/entity/EngineMethod';
    import EngineMethodRequirement from "@/entity/EngineMethodRequirement";
    import DataType from "@/entity/DataType";
    import EngineArgument from "@/entity/EngineArgument";
    import Popup from "@/entity/Popup";
    import EnginePlace from "@/entity/EnginePlace";

    @Component({})
    export default class EngineCallableLineEditor extends Vue {
        @Prop() line!: EngineMethodLine

        uKey = helper.generateId()
        insurers: Insurer[] = []
        insurer: Insurer | null = null
        engine: Engine | null = null
        requirement: EngineMethodRequirement | null = null
        types: DataType[] = []
        currentMethod: EngineMethod | null = null
        methods: EngineMethod[] = []

        editReturn = false

        async mounted() {
            this.insurers = await this.$store.getters.insurers()
            this.insurer = this.$store.state.engineInsurer
            this.engine = this.$store.state.engine
            this.methods = this.$store.state.engineMethods
            this.currentMethod = this.$store.state.engineMethod
            this.types = await this.$store.getters.dataTypes()
        }


        setCallable(callable: EngineMethod) {
            this.line.places = []
            this.line.callable = callable
            this.$forceUpdate()
        }

        resetCallable() {
            this.line.callable = undefined
            this.line.places = []
            this.$forceUpdate()
        }

        dragResult(evt: any) {
            evt.dataTransfer.setData('text/plain', JSON.stringify(this.line.returnArgument));
        }

        getPlaceForRequirement(req: EngineMethodRequirement) {
            return this.line.places.find((a: EnginePlace) => {
                return a.requirement === req.id
            }) as any
        }


        linkArgumentToRequirement(evt: any) {
            let reqId = parseInt(evt.target.getAttribute('data-id'))
            let data = evt.dataTransfer.getData('text/plain')
            let req = this.line.callable!.requirements.find((r: EngineMethodRequirement) => {
                return r.id === reqId
            })
            if (data && req) {
                data = JSON.parse(data);
                let arg = new EngineArgument(data)
                if (arg.resultOfLine) {
                    arg = this.$store.state.returnArguments.find((ra: EngineArgument) => {
                        return ra.uuid === arg.uuid
                    })
                    if (this.currentMethod) {
                        const line: any = this.currentMethod.getLineByGid(arg.resultOfLine!)
                        if (line.ranking >= this.line.ranking) {
                            const pop = new Popup('Erreur', 'Cet emplacement entrée n\'accepte que les paramètres au dessus de la ligne ' + this.line.ranking, 'danger', 'fad fa-bug', false);
                            return
                            // throw 'Cet emplacement n\'accepte que les paramètres au dessus de la ligne ' + this.line.number
                        }
                    }
                }
                if (arg.type.format !== req.type.format) {
                    let msg = 'Cet emplacement n\'accepte que les propriétés de type ' + req.type.label
                    if (req.itemType) {
                        msg += ' ' + req.itemType.name
                    }
                    const pop = new Popup('Erreur', msg, 'danger', 'fad fa-bug', false);
                    return
                    // throw 'Cet emplacement n\'accepte que les propriétés de type ' + req.type.label
                }
                const place = new EnginePlace()
                place.argument = arg
                place.requirement = reqId
                this.addPlace(place)
            }
        }

        addPlace(place: EnginePlace) {
            if (place.requirement && this.line.callable) {
                const index = this.line.callable.requirements.findIndex((r: EngineMethodRequirement) => {
                    return r.id === place.requirement
                })
                if (index !== -1) {
                    this.line.places.splice(index, 1, place)
                } else {
                    this.line.places.push(place)
                }
            }

        }


        removeLine() {
            this.$emit('removeLine', {line: this.line})
        }

        // addLineBefore() {
        //     this.$emit('addLineBefore', {type: 'default', before: this.line})
        // }

        addDefaultLineBefore() {
            this.$emit('addLineBefore', {type: 'default', before: this.line})
        }

        addConditionLineBefore() {
            this.$emit('addLineBefore', {type: 'condition', before: this.line})
        }

        addForeachLineBefore() {
            this.$emit('addLineBefore', {type: 'foreach', before: this.line})
        }
        addCallableLineBefore() {
            this.$emit('addLineBefore', {type: 'callable', before: this.line})
        }

        errorMessages: string[] = []

        @Watch('line', {immediate: true, deep: false})
        check() {
            this.errorMessages = []
            if (!this.line.callable) {
                this.errorMessages.push('Selectionnez un calcul')
            }
            this.line.places.forEach((a: EnginePlace, index) => {
                if (!a.argument) {
                    this.errorMessages.push('L\'un des paramètres est mal configuré')
                }
            })
            if (this.line.callable) {
                this.line.callable.requirements.forEach((r: EngineMethodRequirement) => {
                    if (!(this.getPlaceForRequirement(r) instanceof EnginePlace)) {
                        this.errorMessages.push('Paramètre manquant pour l\'entrée ' + r.label)
                    }
                })
            }
            this.line.isValid = this.errorMessages.length === 0
            this.$forceUpdate()

        }
    }
</script>
<style scoped>
    .line:hover .add-line-before {
        display: block !important;
    }

    .line {
        animation: open .8s linear;
    }

</style>
