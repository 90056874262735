<template>
    <Base>
        <template v-slot:toolbar>
            <div class="toolbar" id="kt_toolbar">
                <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
                    <div class="d-flex align-items-center me-3">
                        <slot name="leftToolbar">
                            <span class="h-20px border-gray-200 border-start mx-4"></span>
                            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                <li class="breadcrumb-item text-muted">
                                    <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                                        <i class="fad fa-home"></i>
                                        Tableau de bord
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <span class="bullet bg-gray-200 w-5px h-2px"></span>
                                </li>
                                <li class="breadcrumb-item text-muted">
                                    <!--                                    <router-link :to="{name: 'form-list', params : { slug : survey.branch }}" v-if="survey">-->
                                    <!--                                        Formulaires-->
                                    <!--                                    </router-link>-->
                                </li>
                                <li class="breadcrumb-item">
                                    <span class="bullet bg-gray-200 w-5px h-2px"></span>
                                </li>
                                <li class="breadcrumb-item text-muted">
                                    {{trans('Edit_survey')}}
                                </li>
                            </ul>
                        </slot>
                    </div>


                    <div class="d-flex align-items-center py-1" v-b-modal.platform variant="primary">
                        <div class="btn btn-primary btn-sm">
                            <i class="fad fa-plus"></i>
                            {{trans('Créer une plateforme')}}
                        </div>
                    </div>

                </div>
            </div>
        </template>
        <template v-slot:content>

            <div class="text-primary fw-bolder fs-2">
                Configuration des formulaires par plateforme
            </div>

            <p class="pt-4 mw-500px fs-6">
                Une branche pouvant posséder plusieurs formulaires, cette section vous permet de définir quels 
                formulaires seront affichés sur chacune des plateformes créée.
            </p>

            <div class="d-flex flex-wrap align-items-center justify-content-start">
                <div class="card min-w-300px m-5" v-for="platform in platforms">
                    <div class="card-header">
                        <div class="card-title">
                            {{platform.label}}
                        </div>
                    </div>
                    <div class="card-body p-0">

                        <div class="d-flex align-items-center justify-content-between p-2" v-for="survey in surveys">
                            <div class="fw-bolder">
                                {{survey.label}}
                            </div>
                            <div>

                                <label class="form-check form-switch form-check-custom form-check-solid m-2">
                                    <input :checked="isShown(platform,survey)" @change="toggleSurvey(platform.id,survey.id)"
                                           class="form-check-input"
                                           type="checkbox"/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal :title="trans('Editer une plateforme')" centered id="platform" ref="platform" size="lg">
                <form>
                    <div class="row" v-if="platform">
                        <div class="col-auto m-2 flex-grow-1">
                            <label :for="'name'" class="required form-label">{{trans('Label')}}</label>
                            <b-form-input :id="'name'" :state="!helper.empty(platform.label)"
                                          v-model="platform.label"></b-form-input>
                        </div>
                        <div class="col-auto m-2 flex-grow-1">
                            <label :for="'name'" class="required form-label">{{trans('Origine (URL)')}}</label>
                            <b-form-input :id="'name'" :state="!helper.empty(platform.baseUrl)"
                                          v-model="platform.baseUrl"></b-form-input>
                        </div>
                    </div>
                    <div class="row" v-if="platform">
                        <div v-for="survey in surveys"></div>
                    </div>
                </form>
                <template #modal-footer="{ ok, cancel, hide }">
                    <b-button @click="cancel()" size="sm" variant="secondary">
                        <i class="fad fa-times"></i>
                        {{trans('Cancel')}}
                    </b-button>
                    <b-button @click="edit" size="sm" variant="primary">
                        <i class="fad fa-save"></i>
                        {{trans('Save')}}
                    </b-button>
                </template>
            </b-modal>
        </template>
    </Base>
</template>
<script lang="ts">
    import {Component, Vue} from 'vue-property-decorator';
    import Base from "@/layouts/Base.vue";
    import {api} from "@/services/Api";
    import Platform from "@/entity/Platform";
    import Survey from "@/entity/Survey";

    @Component({
        components: {Base}
    })
    export default class PlatformList extends Vue {

        platform = new Platform();
        platforms: Platform[] = []
        surveys: Survey[] = []

        mounted(): void {
            this.load()
            this.loadSurveys()
        }

        async load() {
            const res = await api.get(api.form, 'platform/list');
            if (res && res.data && res.data.platforms) {
                this.platforms = [];
                res.data.platforms.forEach((p: Platform) => {
                    this.platforms.push(new Platform(p))
                })
            }
        }

        async edit() {
            const platform = {...this.platform}
            const res = await api.post(api.form, 'platform/edit', {platform})
            const refs = this.$refs as any;
            refs['platform'].hide()
            this.load();
        }

        isShown(platform: Platform, survey: Survey) {
            let shown = false;
            platform.surveys.forEach((s: Survey) => {
                if (s.uuid === survey.uuid) {
                    shown = true
                }
            })
            return shown;
        }

        async toggleSurvey(platformId: number, surveyId: number) {
            const res = await api.get(api.form, 'platform/' + platformId + '/toogle/survey/' + surveyId)
            if (res && res.data && res.data.platforms) {
                this.platforms = [];
                res.data.platforms.forEach((p: Platform) => {
                    this.platforms.push(new Platform(p))
                })
            }
        }

        async loadSurveys() {
            this.$store.commit('loading')
            const res = await api.get(api.form, 'survey/list')
            if (res && res.data && res.data.surveys) {
                this.surveys = []
                res.data.surveys.forEach((s: any) => {
                    this.surveys.push(new Survey(s))
                })
            }
            this.$store.commit('stopLoading')
        }


    }
</script>
<style scoped>
</style>
