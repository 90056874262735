<template>
  <div class="flex-grow-1 m-2" :key="'question_'+question.uuid" v-if="question.isAuthorized()">
    <div v-if="question.response">
    </div>
    <label class="fw-bolder my-2 d-flex justify-content-between" for="">
            <span :title="question.visibility" class="cursor-pointer" v-b-tooltip.hover
                  v-if="question.visibility !== 'PUBLIC'">
                <i class="fad fa-lock text-danger"></i>
            </span>
      {{ question.label }}
      <!--          {{question.response.uuid}}-->
      <!--            <span @click="$store.commit('resetResponse',question)" class="btn btn-xs btn-icon" v-if="hasRole('ROLE_MANAGER')"><i class="fad fa-trash text-danger"></i></span>-->
    </label>

    <b-input-group
        :class="[ question.isSending ? 'border-secondary' : question.isValid ? 'border-success' : 'border-danger' ]"
        class="rounded border-1" style="border-style:solid">
      <b-input-group-prepend v-if="question.prefix">
        {{ question.prefix }}
      </b-input-group-prepend>
      <div class="input-container flex-grow-1 rounded">
        <input :regex="question.regex" :type="type" @blur="question.updateAnswer()"
               @change="question.updateAnswer()" @key.enter="question.updateAnswer()"
               class="form-control form-control-solid"
               v-model.trim="question.response.value">

        <span :title="trans('Mise à jour en cours')" class="input-status" v-b-tooltip.hover v-if="question.isSending">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
        <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="!question.isValid">
                    <i class="fad fa-exclamation-circle text-danger"></i>
                </span>
        <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="question.isValid">
                    <i class="fad fa-check-circle text-success"></i>
                </span>
      </div>
      <b-input-group-append v-if="question.suffix">
        {{ question.suffix }}
      </b-input-group-append>
    </b-input-group>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Question from "@/entity/Question";
import CONSTANTS from "@/Utils/constant";
import {helper} from "@/services/Helper";

@Component({})
export default class DateComponent extends Vue {
  @Prop() question!: Question
  type = 'date'

  mounted(): void {

    if (this.question.response && this.question.response.value) {
      try {
        const value: any = this.question.response.value
        const when = new Date(value)
        this.question.response.value = helper.reversedDateToHtml(when)
      } catch (e) {
        console.log(e)
      }
    }
    // console.log(this.question.response.value)
    this.setType()
    this.setCustom()
    this.checkMin()
    this.checkMax()
  }

  checkMin() {

    let current = null
    if (this.question.response && this.question.response.value) {
      current = new Date(this.question.response.value)
    }
    if (this.question.min !== null && this.question.min !== undefined) {
      const min = helper.dateFromCode(this.question.min)
      if (!current) {
        this.question.response.value = helper.reversedDateToHtml(min)
      } else if (current.getTime() < min.getTime()) {
        this.question.response.value = helper.reversedDateToHtml(min)
      }
    }
  }

  checkMax() {
    let current = null
    if (this.question.response && this.question.response.value) {
      current = new Date(this.question.response.value)
    }
    if (this.question.max !== null && this.question.max !== undefined) {
      const max = helper.dateFromCode(this.question.max)
      if (current && current.getTime() > max.getTime()) {
        this.question.response.value = helper.reversedDateToHtml(max)
      }
    }
  }

  setCustom() {
    if (this.question.defaultValue && CONSTANTS.dateConstants.hasOwnProperty(this.question.defaultValue)) {
      if (this.question.response) {
        const date = (CONSTANTS.dateConstants as any)[this.question.defaultValue as any]()
        this.question.response.value = date.toISOString().substring(0, 10)
        this.question.updateAnswer()
      }
    }
  }

  setType() {
    if (this.question.typeHasTag('DATETIME')) {
      this.type = 'datetime-local'
    }
  }

}
</script>
<style scoped>
.input-container {
  position: relative;
}

.input-status {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
