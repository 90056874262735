<template>
    <b-dropdown no-flip ref="iconSelector" right size="sm" split split-variant="primary" tag="div"
                toggle-class="topbar-item text-decoration-none" variant="primary">
        <template v-slot:button-content>
            <i :class="current"></i>
        </template>
        <b-dropdown-text class="min-w-md-250px p-0" tag="div">
            <input :placeholder="trans('Search')" @input="searching" class="form-control rounded-0 border-transparent"
                   ref="searchInput" type="text" v-model="search">
            <div class="separator"></div>
            <div class="choices bg-white  mh-150px overflow-auto">
                <div class="icon-picks d-flex flex-wrap">
                    <template v-for="icon,i in icons">
                        <div :key="i" :title="icon.class" @click="select(icon.class)"
                             class="icon-pick p-3 bg-hover-light-primary flex-grow-1"
                             v-if="icon.show">
                            <i :class="icon.class" class="fs-2"></i>
                        </div>
                    </template>
                </div>
            </div>
        </b-dropdown-text>
    </b-dropdown>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {api} from "@/services/Api";
    import Popup from "@/entity/Popup";
    import icons from "../assets/icons.json";

    @Component({})
    export default class IconSelector extends Vue {
        @Prop({default: 0}) readonly value!: number
        @Prop() selected?: string;
        private icons: any[] = [];
        private open = true;
        private search = ''
        private current: any = null
        to: any = null

        searching() {
            clearTimeout(this.to)
            this.to = null
            this.to = setTimeout(() => {
                clearTimeout(this.to)
                this.icons.forEach((icon) => {
                    if (this.search.toLowerCase().trim() === '') {
                        icon.show = true
                        return
                    }
                    if (icon.class.toLowerCase().includes(this.search)) {
                        icon.show = true
                    } else {
                        icon.show = false
                    }
                })
            }, 500)
        }


        handleInput() {
            this.$emit('input', this.selected)
        }

        constructor() {
            super()
            icons.forEach((icon) => {
                this.icons.push({class: icon, show: true})
            })

            if (this.selected) {
                this.current = this.selected
            }
            // console.log(this.icons)
        }


        select(icon: string): void {
            this.search = ''
            this.searching()
            // console.log(icon)
            this.$emit('input', icon)
            this.$emit('select', icon)
            this.current = icon;
            this.open = !open;
            if (this.$refs.hasOwnProperty('iconSelector')) {
                (this.$refs.iconSelector as any).hide(true);
            }
        }

        async mounted(): Promise<void> {
            if (this.selected) {
                this.current = this.selected
            }
            if(this.value){
                this.current = this.value
            }
            // try {
            //     const resp = await api.get('icons.json', true);
            //     this.icons = resp.data;
            // } catch (e) {
            //     new Popup('Error', this.trans('Unable_to_load_icons'), 'danger', 'fad fa-wifi-slash');
            // }

        }
    }
</script>
<style scoped>
    .b-dropdown-text {
        padding: 0 !important;
    }
</style>
