<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">


            <b-input-group>
              <b-form-select class="form-control form-select">
                <b-form-select-option value="null">{{ trans('Langues') }}
                </b-form-select-option>
                <b-form-select-option :key="locale.id" v-for="locale in languages" :value="locale">
                  {{ trans(locale.label) }}
                </b-form-select-option>
              </b-form-select>
              <b-input-group-append>
                <!--                            <div class="btn btn-sm btn-icon btn-secondary h-100" style="border-radius: 0">-->
                <!--                                <i class="fad fa-edit"></i>-->
                <!--                            </div>-->
                <div class="btn btn-sm btn-icon btn-primary h-100"
                     style="border-top-left-radius: 0;border-bottom-left-radius: 0">
                  <i class="fad fa-plus"></i>
                </div>
              </b-input-group-append>
            </b-input-group>

            <!--                        <div class="btn-sm btn btn-primary ms-5" v-b-modal.locale>-->
            <!--                            <i class="fad fa-plus"></i>-->
            <!--                            {{trans('Ajouter une langue')}}-->
            <!--                        </div>-->
          </div>
        </div>
      </div>
    </template>
    <template #content>


      <div class="d-flex align-items-center justify-content-between py-5">
        <h1>
          Platformes
        </h1>
        <div>
          <div class="btn btn-icon btn-primary" v-b-modal.platformModal>
            <i class="fad fa-plus"></i>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <router-link :to="{name : 'platform-translation', params:{id:platform.id}}" class="card m-5 cursor-pointer"
                     v-for="platform of platforms" :key="platform.id">
          <div class="card-body">
            <div class="fw-bolder fs-2">
              {{ platform.label }}
            </div>
            <div class="text-muted fs-8">
              {{ platform.code }}
            </div>
            <div>

            </div>
          </div>
        </router-link>
      </div>

      <div class="d-flex align-items-center justify-content-between py-5">
        <h1>
          Langues
        </h1>
        <div>
          <div class="btn btn-icon btn-primary" v-b-modal.languageModal>
            <i class="fad fa-plus"></i>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="card m-5" v-for="language of languages" :key="language.id">
          <div class="card-body">
            <div class="fw-bolder fs-2">
              {{ language.label }}
            </div>
            <div class="text-muted fs-8">
              {{ language.code }}
            </div>
            <div>

            </div>
          </div>
        </div>
      </div>

      <b-modal :title="trans('Platforme')" centered id="platformModal" ref="platformModal">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'name'" :state="!helper.empty(platform.label)"
                            v-model="platform.label"></b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
              <div>
                <b-form-input :id="'name'" :state="!helper.empty(platform.code)"
                              v-model="platform.code"></b-form-input>
              </div>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="savePlatform()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal :title="trans('Langue')" centered id="languageModal" ref="languageModal">
        <form>
          <div class="row">
          </div>
          <div class="col-auto m-2 flex-grow-1">
            <label :for="'name'" class="required form-label">{{ trans('Label') }}</label>
            <b-form-input :id="'name'" :state="!helper.empty(language.label)"
                          v-model="language.label"></b-form-input>
          </div>
          <div class="col-auto m-2 flex-grow-1">
            <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
            <div>
              <b-form-input :id="'name'" :state="!helper.empty(language.code)"
                            v-model="language.code"></b-form-input>
            </div>
          </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="saveLanguage()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Locale from "@/entity/Locale";
import FileUpload from "@/components/FileUpload.vue";
import Platform from "@/entity/Platform";
import Language from "@/entity/Language";
import {helper} from "@/services/Helper";

@Component({
  components: {FileUpload, Base}
})
export default class LanguageList extends Vue {

  platform = new Platform()
  language = new Language()
  platforms: Platform[] = []
  languages: Language[] = []

  mounted(): void {
    this.loadPlatforms()
    this.loadLanguages()
  }

  async loadPlatforms() {
    const res = await api.get(api.translator, 'platform/list')
    if (res) {
      this.platforms = []
      res.data.platforms.forEach((platform: any) => {
        this.platforms.push(new Platform(platform))
      })
    }
  }

  async savePlatform() {
    if (helper.empty(this.platform.label) || helper.empty(this.platform.code)) {
      return
    }
    this.$store.commit('loading')
    const res = await api.post(api.translator, 'platform/edit', {
      platform: this.platform
    });
    (this.$refs as any).platformModal.hide()
    this.platform = new Platform()
    this.$store.commit('stopLoading')
    this.loadPlatforms()
  }

  async saveLanguage() {
    if (helper.empty(this.language.label) || helper.empty(this.language.code)) {
      return
    }
    this.$store.commit('loading')
    const res = await api.post(api.translator, 'language/edit', {
      language: this.language
    });
    (this.$refs as any).languageModal.hide()
    this.language = new Language()
    this.$store.commit('stopLoading')
    this.loadLanguages()
  }

  async loadLanguages() {
    const res = await api.get(api.translator, 'language/list')
    if (res) {
      this.languages = []
      res.data.languages.forEach((language: any) => {
        this.languages.push(new Language(language))
      })
    }
  }

  save() {

  }
}
</script>
<style scoped>
</style>
