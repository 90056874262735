<template>
    <div class="QuestionLine">
        <span class="fw-bolder">
            {{question.label}}
        </span>
        <span>
            ▪ {{question.type.label}}
        </span>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Question from "@/entity/Question";

    @Component({})
    export default class QuestionLine extends Vue {
        @Prop() question!: Question
    }
</script>
<style scoped>
</style>
