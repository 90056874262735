<template>
   <Base>
    <template v-slot:content>
        
    </template>
   </Base>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ResponseGroup from "@/entity/ResponseGroup";
import { api } from "@/services/Api";
import Base from "@/layouts/Base.vue";


@Component({})
export default class CotationDebugger extends Vue {
    responseGroup: ResponseGroup | null = null


    mounted() {

    }

    async loadData() {
        const res = await api.get(api.form, 'api/response/group/list')
        console.log(res)
    }
}
</script>
<style scoped></style>
