/* eslint-disabled */
import Branch from "./Branch";
import EngineMethod from "./EngineMethod";
import VoucherExtend from "@/entity/extends/VoucherExtend";

export default class Voucher extends VoucherExtend {

public id = '';
public label? = '';
public code = '';
public maxUse = '';
public uses? = '';
public branch!: Branch;
public callable!: EngineMethod;

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.label= object.label;
       this.code= object.code;
       this.maxUse= object.maxUse;
       this.uses= object.uses;
this.branch = (object.branch instanceof Branch) ? object.branch : object.branch ? new Branch(object.branch) : object.branch;
this.callable = (object.callable instanceof EngineMethod) ? object.callable : object.callable ? new EngineMethod(object.callable) : object.callable;
      }
      this.postConstruct()
  }

}
