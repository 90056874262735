/* eslint-disabled */
import Account from "./Account";
import PaymentTransaction from "./PaymentTransaction";
import AccountOperationExtend from "@/entity/extends/AccountOperationExtend";

export default class AccountOperation extends AccountOperationExtend {

public id!: number;
public description? = '';
public type = '';
public amount!: number;
public account!: Account;
public transaction!: PaymentTransaction;
public uuid = '';
public createdAt?: Date;
public updatedAt?: Date;
public createBy? = '';
public updateBy? = '';
public removeBy? = '';
public createdFromIp? = '';
public updatedFromIp? = '';

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.description= object.description;
       this.type= object.type;
       this.amount= object.amount;
this.account = (object.account instanceof Account) ? object.account : new Account(object.account);
this.transaction = (object.transaction instanceof PaymentTransaction) ? object.transaction : new PaymentTransaction(object.transaction);
       this.uuid= object.uuid;
       if(object.createdAt){
           this.createdAt= new Date(object.createdAt);
       }
       if(object.updatedAt){
           this.updatedAt= new Date(object.updatedAt);
       }
       this.createBy= object.createBy;
       this.updateBy= object.updateBy;
       this.removeBy= object.removeBy;
       this.createdFromIp= object.createdFromIp;
       this.updatedFromIp= object.updatedFromIp;
      }
      this.postConstruct()
  }

}
