<template>
  <div class="PropertyLine d-flex align-items-center position-relative">
    <div :id="'property_marker_' + property.uuid" @dragenter.prevent @dragleave.prevent @dragover.prevent
         @drop="dropPoint" class="w-20px bg-secondary h-20px rounded me-5 d-flex property
             justify-content-center align-items-center position-relative z-index-1">

      <div
          :class="{ 'bg-danger': $store.state.questionDragged && $store.state.questionDragged.type.format !== property.type.format }"
          class="input point cursor-pointer rounded-circle">
      </div>
    </div>
    <b-dropdown class="p-0 position-absolute w-100 z-index-2" dropup no-caret no-flip ref="filter" right
                size="sm" tag="div" toggle-class="topbar-item text-decoration-none" v-if="mapped" variant="link">
      <template v-slot:button-content>
        <div class="output point cursor-pointer rounded-circle">
        </div>
      </template>
      <div class="py-2">
        <div class="px-5">
          <div class="fw-bolder">
            Priorité d'affectation
          </div>
          <div class="text-muted fw-bolder">
            Glissez pour réorganiser
          </div>
        </div>
        <draggable :animation="200" :id="'property_' + property.uuid" :list="mappings" @end="updateMappingRanks"
                   class="" filter=".action-button" ghost-class="bg-light-primary"
                   :group="'group_property_' + property.uuid" tag="div">
          <div :id="+property.uuid+'-'+mapping.id" class="py-2 px-3 d-flex justify-content-between align-items-center
            bg-hover-light-primary whitespace-no-wrap" v-for="(mapping,index) in mappings">
              <span v-if="getQuestionByUuid(mapping.questionUuid)">
                <span class="fw-bolder">
                  {{ mapping.ranking }}
                </span>
                <span class="px-1">
                 ❖
                </span>
                  {{ getQuestionByUuid(mapping.questionUuid).label }}
              </span>
            <i :title="'Supprimer'" @click="removeMap(mapping)" class="fad fa-times text-danger ms-5"></i>
          </div>
        </draggable>
      </div>
    </b-dropdown>
    <span class="fw-bolder">{{ property.name }}</span>
    <span>&nbsp;▪ {{ property.type.label }}</span>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ItemProperty from "@/entity/ItemProperty";
import {helper} from "@/services/Helper";
import Mapping from "@/entity/Mapping";
import Survey from "@/entity/Survey";
import Question from "@/entity/Question";
import ItemType from "@/entity/ItemType";
import PropertyChoice from "@/entity/PropertyChoice";
import QuestionChoice from "@/entity/QuestionChoice";
import Popup from "@/entity/Popup";
import {api} from "@/services/Api";

@Component({
  components: {}
})
export default class PropertyLine extends Vue {
  @Prop() property!: ItemProperty
  @Prop() survey!: Survey
  @Prop() allItemTypes!: ItemType[]
  mappings: Mapping[] = []


  @Watch('survey', {immediate: true})
  loadMappings() {
    this.mappings = []
    this.survey.mappings.forEach((mapping: Mapping, index: number) => {
      if (mapping.propertyUuid === this.property.uuid) {
        if (helper.empty(mapping.ranking)) {
          mapping.ranking = index
        }
        this.mappings.push(mapping)
      }
    })
  }

  async updateMappingRanks(evt: any) {
    const data = evt.clone.id.split('-')
    const propertyUuid = data[0]
    const mappingId = data[1]
    if (!propertyUuid) {
      new Popup('Erreur', "Il vous faut enregistrer la question avant de pouvoir réorganiser l'ordre des choix")
      return
    }
    if (!mappingId) {
      new Popup('Erreur', "Il vous faut enregistrer ce choix avant de pouvoir réorganiser l'ordre des choix")
      return
    }
    const popup = new Popup('', 'Reorganisation en cours ...', 'warning', 'fad fa-cogs', true)
    const ranks: any = []
    let i = 1;
    this.mappings.forEach((s: Mapping) => {
      const q: any = this.getQuestionByUuid(s.questionUuid)
      if (s) {
        ranks.push({id: s.id, ranking: i, label: q.label, qUuid: s.questionUuid})
        i++;
      }
    })
    const res = await api.post(api.form, 'mapping/property/update/ranks', {ranks})
    if (res && res.data && res.data.mappings) {
      this.mappings = res.data.mappings
    }
    popup.hide()
  }

  async removeMap(mapping: Mapping) {
    const index = this.survey.mappings.findIndex((m: Mapping) => {
      return m.questionUuid === mapping.questionUuid && m.propertyUuid === this.property.uuid
    })
    if (mapping.id) {
      const res = await api.get(api.form, 'question/remove/mapping/' + mapping.id)
    }

    if (index !== -1) {
      this.survey.mappings.splice(index, 1)
      await this.$store.state.sleep(100)
      this.$emit('linkQuestionToProperty')
    }
  }

  get mapped() {
    const maps = this.survey.mappings.filter((m: Mapping) => {
      return m.propertyUuid === this.property.uuid
    })
    return maps.length > 0 && this.$store.state.questionDragged === null
  }

  getQuestionByUuid(uuid: string) {
    return this.survey.getQuestions().find((q: Question) => {
      return q.uuid === uuid
    })
  }


  dragPointLeave(evt: any) {
    // console.log('leave')
    evt.preventDefault()
    // const i: any = document.querySelector("#property_marker_"+this.property.uuid)
    // i.style.background = 'gainsboro'
    this.$emit('dragPointLeave')
  }

  dragOver(evt: any) {
    // const i: any = document.querySelector("#property_marker_"+this.property.uuid)
    // i.style.background = 'red'
    // console.log('over')
    evt.preventDefault()
    // evt.dataTransfer.dropEffect = 'copy';
  }

  dropPoint(evt: any) {
    if (!helper.empty(evt.dataTransfer.getData('text/plain'))) {
      this.linkQuestionToProperty(evt.dataTransfer.getData('text/plain'))
    }
    this.$store.state.questionDragged = null
  }


  linkQuestionToProperty(questionUuid: string) {
    const question: Question = this.getQuestionByUuid(questionUuid) as any
    // console.log('question choices')
    // question!.choices.forEach((qc: QuestionChoice) => {
    //     console.log(qc.code)
    // })
    // console.log('property choices')
    // this.property.choices.forEach((qc: QuestionChoice) => {
    //     console.log(qc.code)
    // })
    // console.log(this.property.choices)
    if (this.property.type.code === 'RADIO') {
      let error = 0

      question.choices.forEach((qc: QuestionChoice) => {
        const index = this.property.choices.findIndex((c: PropertyChoice) => {
          return qc.code.toUpperCase() === c.code.toUpperCase()
        })
        if (index === -1) {
          new Popup(qc.code, 'La question fait reférence à un choix inexistant dans la propriété ', undefined, undefined, true);
          error++
        } else {
          // console.log(c.code + ' is OK')
        }
      })
      if (error === 0) {
        this.$emit('linkQuestionToProperty', {
          questionUuid,
          propertyUuid: this.property.uuid,
          ranking: this.mappings.length
        })
      }
    } else {
      this.$emit('linkQuestionToProperty', {
        questionUuid,
        propertyUuid: this.property.uuid,
        ranking: this.mappings.length
      })
    }

  }
}
</script>
<style scoped>
.input {
  box-shadow: inset 3px 3px 6px #00000040, inset -3px -3px 6px #9c9ea066;
  background: cornflowerblue;
  pointer-events: none;
  width: 12px;
  height: 12px;
}

.output {
  position: absolute;
  background: whitesmoke;
  pointer-events: none;
  box-shadow: 0 0 2px rgba(150, 150, 150, .8);
  top: 0px;
  left: 2px;
  z-index: 2;
  width: 16px;
  height: 16px;
  /*transition: opacity 0.2s ease;*/
  /*transform: translateX(-50%) translateY(-50%);*/
}
</style>
