<template>
    <div>
      <div>FORM PROCESSOR</div>
        <Loading v-if="loading"></Loading>
        <div class="d-flex justify-content-between align-items-center" v-if="!melted">
            <div class="d-flex align-items-center" v-if="rg">
                <router-link :key="$route.fullPath" :to="{ name : 'comparator-form-processor',
                params: {surveySlug: rg.rgParent.slug, uuid: rg.rgParent.uuid}}"
                             @click.native="reset" v-if="rg.rgParent">
                    <i class="fad fa-arrow-left fs-3x cursor-pointer me-4g"></i>
                </router-link>
                <h1 v-if="rg.alias">
                    {{rg.alias}}
                </h1>
            </div>

            <div>
                <router-link :to="{ name: 'survey-form',params: { surveySlug : form.slug,branchSlug : form.branch } }"
                             class="btn btn-primary"
                             target="_blank"
                             v-if="form">
                    <i class="fad fa-ballot-check"></i>
                    {{trans('Modifier les questions')}}
                </router-link>
            </div>
        </div>
        <!--        <template v-if="rg">-->
        <!--            {{rg.uuid}}-->
        <!--        </template>-->
        <div class="m-auto">
            <ProspectIdentifier :potentialOwners="potentialOwners" :rg="rg" v-if="rg"></ProspectIdentifier>
            <div class="d-flex justify-content-center" v-if="notFound">
                <div class="card">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center flex-1">
                        <i class="fad fa-file-search fs-5x"></i>
                        <div class="mt-5 fw-bolder fs-2">
                            {{trans('Cette cotation est introuvable')}}
                        </div>
                        <div class="mt-5">
                            <div @click="create" class="btn btn-primary btn-sm">
                                <i class="fad fa-plus"></i>
                                {{trans('Créer une nouvelle cotation')}}
                            </div>
                        </div>

                        <!--                        <div class="d-flex flex-column">-->
                        <div class="fs-2  my-5">
                            {{trans('Vous avez pensé à sécuriser vos cotations ?')}}
                        </div>
                        <div class="btn btn-success btn-sm">
                            <i class="fad fa-search"></i>
                            {{trans('Retrouver les en quelques clics')}}
                        </div>
                        <!--                        </div>-->
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center" v-if="requireAuth">
                <div class="card">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center flex-1">
                        <i class="fad fa-shield-alt text-success fs-5x"></i>
                        <div class="mt-5 fw-bolder fs-2">
                            {{trans('Cette cotation été sécurisée via Smart Lock')}}
                        </div>
                        <p class="my-3">
                            {{trans('Retrouvez le code de déverouillage dans votre boite email')}} :
                        </p>
                        <div class="fw-bolder fs-2">
                            {{ownerEmail}}
                        </div>
                        <div class="mt-5">
                            <b-input-group>
                                <b-input-group-prepend class="d-flex flex-column">
                                    <div class="input-group-text flex-grow-1">
                                        <i class="fad fa-lock"></i>
                                    </div>
                                </b-input-group-prepend>
                                <div class="input-container flex-grow-1 rounded  d-flex">
                                    <input :placeholder="trans('Entrez votre code')" class="form-control rounded-0" type="text">

                                </div>
                                <b-input-group-append class="d-flex flex-column">
                                    <div class="input-group-text flex-grow-1 cursor-pointer bg-success">
                                        <!--                                        <button class="btn btn-icon btn-sm btn-success">-->
                                        <i class="fad fa-paper-plane text-white"></i>
                                        <!--                                        </button>-->
                                    </div>
                                </b-input-group-append>
                            </b-input-group>
                        </div>

                        <!--                        <div class="d-flex flex-column">-->
                        <div class="fs-2 mt-5">
                            {{trans('Vous ne retrouvez pas le mail ?')}}
                        </div>
                        <p>
                            <i class="fad fa-lightbulb me-2"></i>
                            {{trans('Pensez à vérifier vos spams')}}
                        </p>
                        <div class="btn btn-primary">
                            <i class="fad fa-at"></i>
                            {{trans('Renvoyer mon code par mail')}}
                        </div>
                        <!--                        </div>-->
                    </div>
                </div>
            </div>

            <div class="wizard wizard-1" v-if="form && form.steps.length > 1">
                <div class="wizard-nav border-bottom position-relative d-flex align-items-center">
                    <div class="wizard-steps  d-flex justify-content-evenly align-items-center flex-grow-1">
                        <div :key="'step-'+step.uuid" class="d-flex align-items-center" v-for="step,index in form.steps">
                            <div :class="{ 'active' :  currentStep >= getStepIndex(step) }"
                                 @click="goToStep(step)"
                                 class="p-8 p-lg-10 wizard-step d-flex align-items-center text-center position-relative"
                                 v-if="step">
                                <!--                                {{step.ranking}}-->
                                <div
                                        class="wizard-label mx-5 position-relative cursor-pointer">
                                    <i :class="step.icon" class="fs-3x"></i>
                                    <p class="wizard-title pt-2 fw-bolder">{{step.label}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="border-warning border-dashed bg-light-warning p-5 mt-10 rounded d-flex align-items-center"
                 v-if="rg && !rg.isSecure && !melted">
                <div>
                    <i class="fad fa-exclamation-triangle fs-4x text-warning"></i>
                </div>
                <div class="ms-10">
                    <div class="fw-bolder fs-2">
                        {{trans('Attention')}}
                    </div>
                    <div>
                        {{trans('Cette cotation n\'a pas encore été sécurisée.')}}
                    </div>
                    <div>
                        {{trans('Renseignez votre addresse email et votre contact afin de sécuriser vos données')}}
                    </div>
                </div>
            </div>

            <template v-if="form && currentStep < form.steps.length">
                <div class="row my-5" v-for="section in form.steps[currentStep].sections">
                    <template v-if="section.visible">
                        <h2 class="my-5" v-if="!helper.empty(section.label)">
                            {{section.label}}
                        </h2>
                        <div :class="{ 'bg-transparent p-0 m-0' : isSectionContainSurvey(section) }" class="card">
                            <div :class="{ 'p-0' : isSectionContainSurvey(section) }" class="card-body">

                                <div class="d-flex flex-wrap justify-content-start">
                                    <template v-for="question in section.questions">
                                        <template v-if="question.isVisible()">
                                            <NumberQuestion :question="question"
                                                            v-if="question.type.code.match(/NUMBER|INTEGER/)"></NumberQuestion>
                                            <Money :question="question" v-else-if="question.type.code === 'AMOUNT'"></Money>
                                            <CarBrand :question="question" v-else-if="question.type.code === 'CAR_BRAND'"></CarBrand>
                                            <CarModel :question="question" v-else-if="question.type.code === 'CAR_MODEL'"></CarModel>
                                            <TextType :question="question" v-else-if="question.type.code.match(/TEXT/)"></TextType>
                                            <Checkbox :question="question" v-else-if="question.type.code === 'BOOLEAN'"></Checkbox>
                                            <QuestionList :question="question" v-else-if="question.type.code === 'QUESTION_LIST'"></QuestionList>
                                            <Contact :question="question" v-else-if="question.type.code === 'CONTACT'"></Contact>
                                            <Radio :question="question" v-else-if="question.type.code === 'RADIO'"></Radio>
                                            <Mail :question="question" v-else-if="question.type.code === 'EMAIL'"></Mail>
                                            <Date :question="question" v-else-if="question.type.code === 'DATE'"></Date>
                                            <FileQuestion :question="question" v-else-if="question.type.code === 'PHOTO'"></FileQuestion>
                                            <SurveyType :melted="true" :question="question" v-else-if="question.type.code === 'OBJECT'"
                                                        v-on:reset="reset"></SurveyType>
                                            <MultipleSurvey :creatingResponseGroup="creatingResponseGroup" :melted="true" :question="question"
                                                            v-else-if="question.type.code === 'OBJECT_GROUP'" :responseGroup="rg"
                                                            v-on:deleteSubResponseGroup="deleteSubResponseGroup"
                                                            v-on:reset="reset"></MultipleSurvey>
                                            <PackCategory :question="question" v-else-if="question.type.code === 'PACK_CATEGORY'"></PackCategory>
                                            <div class="d-flex align-items-center m-2" v-else>
                                        <span class="badge badge-danger">
                                            {{question.label}}
                                            <br>
                                            {{question.type.code}} NOT HANDLED
                                        </span>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>


                <div class="d-flex justify-content-between">
                    <div>
                        <button class="btn btn-secondary" v-if="showPreviousStep">
                            <i class="fad fa-arrow-left"></i>
                            {{trans('Etape précédente')}}
                        </button>
                    </div>

                    <button @click="nextStep" class="btn btn-warning" v-if="showNextStep">
                        {{trans('Etape suivante')}}
                        <i class="fad fa-arrow-right"></i>
                    </button>
                </div>
            </template>
        </div>
    </div>
    <!--        </template>-->
    <!--    </Base>-->
</template>
<script lang="ts">
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import {api} from "@/services/Api";
    import Base from "@/layouts/Base.vue";
    import ResponseGroup from "@/entity/ResponseGroup";
    import ProspectIdentifier from "@/views/Income/Comparator/ProspectIdentifier.vue";
    import Survey from "@/entity/Survey";
    import TextType from "@/views/Income/Comparator/FormComponents/TextType.vue";
    import Contact from "@/views/Income/Comparator/FormComponents/Contact.vue";
    import Mail from "@/views/Income/Comparator/FormComponents/Mail.vue";
    import Date from "@/views/Income/Comparator/FormComponents/Date.vue";
    import Response from "@/entity/Response";
    import Section from "@/entity/Section";
    import Question from "@/entity/Question";
    import MultipleSurvey from "@/views/Income/Comparator/FormComponents/MultipleSurvey.vue";
    import Popup from "@/entity/Popup";
    import Step from "@/entity/Step";
    import FileQuestion from "@/views/Income/Comparator/FormComponents/FileQuestion.vue";
    import PackCategory from "@/views/Income/Comparator/FormComponents/PackCategory.vue";
    import SurveyType from "@/views/Income/Comparator/FormComponents/SurveyType.vue";
    import {Route} from "vue-router";
    import Loading from "@/components/Loading.vue";
    import NumberQuestion from "@/views/Income/Comparator/FormComponents/NumberQuestion.vue";
    import {auth} from "@/services/Auth";
    import Radio from "@/views/Income/Comparator/FormComponents/Radio.vue";
    import QuestionList from "@/views/Income/Comparator/FormComponents/QuestionList.vue";
    import QuestionTypeSelector from "@/views/Income/Comparator/QuestionTypeSelector.vue";
    import Checkbox from "@/views/Income/Comparator/FormComponents/Checkbox.vue";
    import CarBrand from "@/views/Income/Comparator/FormComponents/CarBrand.vue";
    import CarModel from "@/views/Income/Comparator/FormComponents/CarModel.vue";
    import Money from "@/views/Income/Comparator/FormComponents/Money.vue";

    @Component({
        name: 'FormProcessor',
        components: {
            Money,
            CarModel,
            CarBrand,
            Checkbox,
            QuestionTypeSelector,
            QuestionList,
            Radio,
            NumberQuestion,
            Loading, SurveyType, PackCategory, FileQuestion, MultipleSurvey, Date, Mail, Contact, TextType, ProspectIdentifier, Base
        }
    })
    export default class FormProcessor extends Vue {
        [x: string]: any;

        isNew = false
        notFound = false
        requireAuth = false
        ownerEmail = ''
        loading = false
        @Prop() melted?: boolean
        rg: any = null
        form: any = null
        potentialOwners = []
        currentStep = 0
        responses: Response [] = []
        creatingResponseGroup = false
        @Prop() rgUuid?: string


        getStepIndex(step: Step) {
            return this.form.steps.findIndex((s: Step) => s.uuid === step.uuid)
        }

        goToStep(step: Step) {
            if (auth.hasRole('ROLE_MANAGER')) {
                this.currentStep = this.form.steps.findIndex((s: Step) => s.uuid === step.uuid)
            }
        }

        get title() {
            console.log(this.form)
            return 'Comparateur'
        }


        get showPreviousStep() {

            if (this.form && this.form.steps.length > 0) {
                return false
            }
            return true
        }

        get showNextStep() {
            if (!this.form) {
                return false
            }
            if (this.form.steps.length === 1 && this.melted) {
                return false
            }
            // if(this.form.steps.slice(-1).pop().uuid === this.currentStep.uuid){
            //     return false
            // }
            // if (this.form && this.form.steps.length > 0 && this.form.steps[this.form.steps.length - 2].uuid !== this.currentStep.uuid) {
            //     return false
            // }
            return true
        }

        previousStep() {

        }

        nextStep() {
            // const index = this.form.steps.findIndex((s: Step) => s.uuid === this.currentStep.uuid);
            // // console.log('currentIndex ' + index)
            // // console.log('Length : ' + this.form.steps.length)
            // if (index !== -1) {
            //     if (this.form.steps.length > (index + 1)) {
            //
            //     }
            // }
        }

        async create() {
            this.isNew = true
            this.$store.commit('loading')
            const res = await api.get(api.form, 'api/response/group/new/' + this.$route.params.surveySlug)
            if (res && res.data && res.data.responseGroup) {
                var theCookies = document.cookie.split(';');
                var aString = '';
                for (var i = 1; i <= theCookies.length; i++) {
                    aString += i + ' ' + theCookies[i - 1] + "\n";
                }
                this.isNew = false
                this.$router.push({
                    name: 'comparator-form-processor',
                    params: {surveySlug: this.$route.params.surveySlug, uuid: res.data.responseGroup.uuid}
                })
                window.location.reload()
                // this.getData()
            }
            this.$store.commit('stopLoading')
        }

        isSectionContainSurvey(section: Section) {
            let found = false;
            section.questions.forEach((q: Question) => {
                if (q.type.code.match(/OBJECT|OBJECT_GROUP/)) {
                    found = true;
                    return found;
                }
            })
            return found;
        }


        // saveAnswer(question,response,responseGroup) {

        // }

        beforeMount(): void {
            // console.log('-------------------NEW RG '+ this.rgUuid +'----------------------')
        }

        async mounted() {
            // console.log('mount')
            if (this.rgUuid === 'new') {
                await this.create()
            } else {
                this.getData()
            }
        }

        reset() {
            this.isNew = false
            this.notFound = false
            this.requireAuth = false
            this.ownerEmail = ''
            this.rg = null
            this.form = null
            this.potentialOwners = []
            this.currentStep = 0
            this.responses = []
            this.creatingResponseGroup = false
            this.getData()
            this.$emit('reset')
        }

        async resetResponse(uuid: string) {
            const res = await api.get(api.form, 'response/reset/' + uuid)
        }


        async getData() {
            this.loading = true
            const index = this.$store.state.rgs.findIndex((saved: ResponseGroup) => {
                return saved.uuid === this.rgUuid
            })
            if (index !== -1) {
                this.rg = this.$store.state.rgs[index]
            }
            if (this.rg) {
                const surveyIndex = this.$store.state.surveys.findIndex((saved: Survey) => {
                    return saved.uuid === this.rg.survey.uuid
                })
                if (surveyIndex !== -1) {
                    this.form = new Survey(this.$store.state.surveys[surveyIndex])
                }
            }
            if (this.rgUuid === 'new') {
                return;
            }
            if (!this.rg || !this.form) {
                this.requireAuth = false
                this.notFound = false
                const res = await api.get(api.form, 'api/get/data/' + this.rgUuid)
                if (res && res.data && res.data.responseGroup) {
                    this.isNew = false
                    this.rg = new ResponseGroup(res.data.responseGroup)
                    const formModel = res.data.form
                    this.$store.commit('appendSurvey', formModel)
                    this.form = new Survey(formModel)
                    if (res.data.potentialOwners) {
                        this.potentialOwners = res.data.potentialOwners
                    }
                }
                if (res && res.data && res.data.status === 'auth_required') {
                    this.requireAuth = true
                    this.ownerEmail = res.data.email
                }
                if (res && res.data && res.data.status === 'not_found') {
                    this.notFound = true
                }
            }
            this.setRgResponses()
            this.extractRgs(this.rg)
            this.$store.commit('appendRg', this.rg)
            this.loading = false
        }


        async deleteSubResponseGroup(payload: any) {
            this.creatingResponseGroup = true
            const pop = new Popup('Chargement', 'Suppression en cours', 'warning', 'fad fa-cogs', true)
            const res = await api.get(api.form, 'api/delete/sub/response/group/' + payload.rg.uuid + '/' + payload.question.response.uuid)
            if (res && res.data && res.data.response) {
                payload.question.response = new Response(res.data.response)
            }
            pop.hide()
            this.creatingResponseGroup = false
        }

        setRgResponses() {
            const rg = this.rg as ResponseGroup
            if (this.helper.empty(this.form) || this.helper.empty(this.rg)) {
                return;
            }

            if (this.form.steps.length > 0) {
                this.form.steps.forEach((s: Step) => {
                    s.sections.forEach((sec: Section) => {
                        sec.questions.forEach((q: Question) => {
                            this.rg.responses.forEach((res: Response) => {
                                this.$store.commit('appendResponse', res);
                                res.responseGroup!.uuid = this.rg.uuid
                                res.responseGroupUuid = this.rg.uuid
                                if ((q.uuid === res.questionUuid || (res.question && q.uuid === res.question!.uuid))
                                    && res.responseGroupUuid === this.rg.uuid) {
                                    q.response = res
                                    if (q.type.code.includes('OBJECT')) {
                                        // q.response.responseGroups.forEach((r: ResponseGroup) => {
                                        // const rgOcc = new ResponseGroup(r)
                                        // this.$store.commit('appendRg', rgOcc)
                                        // })
                                        // const formModel = new Survey(q.survey)
                                        this.$store.commit('appendSurvey', q.survey);
                                    }
                                    q.validate()
                                }
                            })
                            q.response.responseGroup = this.rg
                            q.response.responseGroupUuid = this.rg.uuid
                            this.rg.responses.push(q.response)
                            q.setDefaultValue()
                            // console.log(q.label+ ' = '+ q.response.value +' rg => '+q.response.responseGroup!.uuid)
                        })
                    })
                })
            }
            // this.$forceUpdate()
        }

        extractRgs(rg: ResponseGroup) {
            rg.responses.forEach((res: Response) => {
                res.responseGroups.forEach((r: ResponseGroup) => {
                    const rgOcc = new ResponseGroup(r)
                    this.$store.commit('appendRg', rgOcc)
                    this.extractRgs(rgOcc)
                })
            })
        }
    }
</script>
<style scoped>
    .wizard-step.active, .wizard-step.active i {
        color: var(--bs-primary);
    }
</style>
