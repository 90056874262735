/* eslint-disabled */

export default class PremiumDetailModelExtend {

  edit = false
  value = 0

  constructor(object?: any) {
  }

  postConstruct() {
  }
}
