<template>
    <div :key="'question_'+question.uuid" class="flex-grow-1 m-2 question" v-if="question.isAuthorized()">
        <label class="fw-bolder my-2 d-flex justify-content-between" for="">
            <span :title="question.visibility" class="cursor-pointer" v-b-tooltip.hover v-if="question.visibility !== 'PUBLIC'">
                <i class="fad fa-lock text-danger"></i>
            </span>
            {{question.label}}
            <span @click="reset" class="btn btn-xs btn-icon" v-if="hasRole('ROLE_MANAGER')"><i
                    class="fad fa-trash text-danger"></i></span>
        </label>
        <b-input-group
                :class="[ question.isSending ? 'border-secondary' : question.isValid ? 'border-success' : 'border-danger' ]"
                class="rounded border-1 d-flex flex-grow-1" style="border-style:solid">

            <div class="input-container flex-grow-1 rounded  d-flex">
                <input-number :key="'number_'+uKey" :prefix="question.prefix" :suffix="question.suffix"
                              @input="update" v-model="question.response.value"></input-number>
                <span :title="trans('Mise à jour en cours')" class="input-status" v-b-tooltip.hover v-if="question.isSending">
                    <i class="fad fa-spinner-third spin text-secondary" style="text-shadow: 0 0 1px black"></i>
                </span>
                <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="!question.isValid">
                    <i class="fad fa-exclamation-circle text-danger"></i>
                </span>
                <span :title="trans(question.message)" class="input-status" v-b-tooltip.hover v-else-if="question.isValid">
                    <i class="fad fa-check-circle text-success"></i>
                </span>
            </div>
            <!--            <b-input-group-append v-if="question.suffix">-->
            <!--                {{question.suffix}}-->
            <!--            </b-input-group-append>-->
        </b-input-group>
    </div>
</template>
<script lang="ts">
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import Question from "@/entity/Question";
    import InputNumber from "@/components/InputNumber.vue";
    import {helper} from "@/services/Helper";

    @Component({
        components: {InputNumber}
    })
    export default class NumberQuestion extends Vue {
        @Prop() question!: Question
        type = 'number'
        timeout: any = null
        uKey = helper.generateId()

        isIcon(text: string) {
            return text.match(/fa |fab |fad |fal |fas /) !== null
        }

        reset() {
            this.$store.commit('resetResponse', this.question)
            // this.uKey = helper.generateId()
            // console.log(this.question.response.value)
        }

        @Watch('$store.state.rgStatus')
        updateReset(){
            // console.log(this.question.response.value)
            // this.$forceUpdate()
            this.uKey = helper.generateId()
        }

        update() {
            clearTimeout(this.timeout)
            this.timeout = null
            this.timeout = setTimeout(this.process, 1000)
        }

        process() {
            this.question.updateAnswer()
        }

        beforeMount(): void {
        }

        mounted(): void {
            this.setType()
        }

        setType() {
            if (this.question.typeHasTag('MONTANT')) {
                this.type = 'amount'
            }

        }
    }
</script>
<style scoped>
    input {
        border-radius: 0;
        background: transparent;
        border: none;
        outline: none;
        text-align: right;
        width: 100px;
    }

    .input-container {
        position: relative;
    }

    .input-status {
        position: absolute;
        top: 50%;
        left: 40px;
        transform: translateY(-50%);
        cursor: pointer;
    }
</style>
