import { render, staticRenderFns } from "./Parameters.vue?vue&type=template&id=2ca37f2a&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform"
import script from "./Parameters.vue?vue&type=script&lang=ts"
export * from "./Parameters.vue?vue&type=script&lang=ts"
import style0 from "./Parameters.vue?vue&type=style&index=0&id=2ca37f2a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ca37f2a",
  null
  
)

export default component.exports