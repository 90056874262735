<template>
    <div class="flex-grow-1 m-2" :key="'question_' + question.uuid" v-if="question.isAuthorized()">
        <label
            :class="[question.isSending ? 'border-secondary' : question.isValid ? 'border-success' : 'border-danger']"
            class="d-flex border-dashed w-300px cursor-pointer h-150px align-items-center justify-content-center m-auto rounded position-relative">

            <span style="z-index: 2;" :title="trans(question.message)" class="input-status" v-b-tooltip.hover
                v-if="!question.isValid">
                <i class="fad fa-exclamation-circle text-danger"></i>
            </span>
            <input :accept="question.extensionAccepted" :id="inputId" @change="uploadFile" type="file">

            <template v-if="background">
                <img :alt="question.label" :src="background" class="w-100 h-100 object-cover rounded">
                <div class="position-absolute  w-50px h-50px d-flex justify-content-center align-items-center fw-bolder fs-7x text-white"
                    style="text-shadow: 0 0 1px black" v-if="isUploading">
                    {{ percent }}%
                    <i class="fad fa-spinner-third spin text-white fs-4x position-absolute"
                        style="text-shadow: 0 0 1px black"></i>
                </div>
            </template>
            <template v-else>
                <span>{{ question.label }}</span>
            </template>
        </label>

        <div v-if="background" class="d-flex align-items-center justify-content-center w-100 ms-2 mt-2">
            <i class="fad fa-eye  fs-2x"></i>
            <span v-b-modal.modal-image class="fw-bolder ms-2"> Voir plus</span>
        </div>

        <b-modal size="lg" :title="question.label" id="modal-image">

            <div class="w-100 h-100">
                <img :src="background" style="object-fit: contain;" class="w-100 h-100"alt="">
            </div>


        </b-modal>
    </div>


</template>
<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { uploadService } from "@/services/UploadService";
import File from "@/entity/File";
import { api } from "@/services/Api";
import Question from "@/entity/Question";
import Tag from "@/entity/Tag";
import FileUpload from "@/components/FileUpload.vue";

@Component({
    components: { FileUpload }
})
export default class FileQuestion extends Vue {
    @Prop() question!: Question
    @Prop() allowMultiple?: boolean;
    @Prop() allowDownload?: boolean;
    @Prop() allowRemove?: boolean;
    @Prop() showFullName?: boolean;
    @Prop() currentFile?: File;
    // publicUrl = process.env.VUE_APP_BACKEND_URL;
    @Prop() fitMode?: string;
    @Prop() accept?: string;
    @Prop() data?: any;
    @Prop() allowedType?: string;
    @Prop() text?: string;
    @Prop() type?: string;
    @Prop() icon?: string;
    @Prop() previewStyle?: string;
    @Prop() buttonStyle?: string;
    @Prop() currentImageLink?: string;
    public types: string[] = [];
    message = 'Selectionnez un fichier';
    error = false;
    background: any = '';
    public file: any = null;
    folder = null;
    percent = 0;
    success = false;
    @Prop() uuid?: string;
    isUploading = false;
    fileId = '';
    public endPoint = api.master + 'uploads/';
    inputId = '';


    hasTag(label: string) {
      return false;
        // return this.question.type!.tags.findIndex((t: Tag) => t.label === label) !== -1
    }

    get excerpt(): string {
        if (this.file) {
            if (this.showFullName) {
                return this.file.name;
            }
            if (this.file instanceof File) {
                return this.file.excerpt;
            }
            let ln = '';
            if (this.file.name.length > 30) {
                ln = this.file.name.substring(0, 20);
                ln += '...' + this.file.name.substring((this.file.name.length - 5), this.file.name.length);
                return ln;
            }
            return this.file.name;
        }
        return '';
    }

    mounted(): void {
        if (this.currentFile) {
            this.file = this.currentFile;
        }

        this.inputId = uploadService.generateId();
        if (this.allowedType) {
            this.types = this.allowedType.split(',');
        }

        if (this.question.response.value) {
            this.background = this.endPoint + this.question.response.value;
        }

        uploadService.on('uploadStart', (data: any) => {

            if (data.id === this.fileId) {
                this.percent = data.percent;
            }
        });
        uploadService.on('chunkSent', (data: any) => {

            if (data.id === this.fileId) {
                // console.log(data.id);
                this.percent = data.percent;
            }
        });
        uploadService.on('uploadSuccess', async (data: any) => {
            if (data.id === this.fileId) {
                this.error = false;
                this.success = true;
                this.percent = 100;
                this.question.response.value = data.filename
                this.background = this.endPoint + this.question.response.value;
                await this.question.updateAnswer()
                this.$emit('input', data.serverFileId);
                this.isUploading = false;
            }
        });
        uploadService.on('error', (data: any) => {

            if (data.id === this.fileId) {
                // const error = data.xhr.error;
                this.isUploading = false;
                this.error = true;
                this.message = 'Une erreur a été rencontré lors du téléversement, veuillez réessayer plus tard';
            }
        });
    }

    async downloadFile(): Promise<void> {
        if (this.file instanceof File) {
            await api.downloadFile(this.file);
        }

    }

    drop() {
        console.log('drop');
    }

    allowDrop() {
        console.log('allow drop');
    }


    setUuid(uuid: string) {
        this.uuid = uuid;
    }

    cancelUpload() {
        uploadService.cancel(this.fileId);
        if (this.file instanceof File) {
            this.$emit('remove', this.file.uuid);
        }
        this.file = null;
        this.fileId = '';
        this.isUploading = false;
        this.percent = 0;
        this.error = false;
        this.success = false;
        this.background = '';
    }

    uploadFile(event: Event): void {
        this.error = true;
        let files = (event.target as any).files;
        if (files.length <= 0) {
            this.message = 'Selectionnez un fichier';
            return;
        }
        const file = files[0] as any;

        if (this.types.length > 0) {
            if (this.types.indexOf(file.type) === -1) {
                this.error = true;
                this.message = 'Format ' + file.type + ' non autorisé';
                // console.log(this.message);
                return;
            }
        }
        this.file = file;

        if (this.question.hasTag('PHOTO')) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.background = reader.result;
            });
            reader.readAsDataURL(file);
        }
        this.isUploading = true;
        this.error = false;
        this.success = false;

        this.fileId = uploadService.generateId();
        uploadService.upload(file, this.fileId, undefined, this.data);
    }

}
</script>
<style scoped>
input[type='file'] {
    display: none;
}

.percent-container {
    position: absolute;
    bottom: 20px;
    width: 90%;
    height: 20px;
    z-index: 3;
    background: rgba(255, 255, 255, .4);
    box-shadow: 0 0 1px grey;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
}

.input-status {
    position: absolute;
    top: 10px;
    right: 10px;
    /*transform: translateY(-50%);*/
    cursor: pointer;
}

.spin {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotateZ(0deg)
    }

    100% {
        transform: rotateZ(360deg)
    }
}
</style>
