<template>
    <div class="flex-grow-1 m-2" :key="'question_' + question.uuid" v-if="question.isAuthorized()">



        <label class="fw-bolder my-2 d-flex justify-content-between" for="">
            <div>
                <span :title="question.visibility" class="cursor-pointer" v-b-tooltip.hover
                    v-if="question.visibility !== 'PUBLIC'">
                    <i class="fad fa-lock text-danger"></i>
                </span>
                {{ question.label }}
            </div>

        </label>


        <FileUpload accept=".jpg,.jpeg,.png,.docx,.pdf,.svg" v-model="background" :showFullName="true"
            :data="question.response.value" :currentFile="question.response.value" :allowRemove="true"
            :fitMode="'contain'" text="Uploader votre fichier" />
    </div>


</template>
<script lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { uploadService } from "@/services/UploadService";
import File from "@/entity/File";
import { api } from "@/services/Api";
import Question from "@/entity/Question";
import Tag from "@/entity/Tag";
import FileUpload from "@/components/FileUpload.vue";

@Component({
    components: { FileUpload }
})
export default class FileInput extends Vue {
    @Prop() question!: Question
    @Prop() allowMultiple?: boolean;
    @Prop() allowDownload?: boolean;
    @Prop() allowRemove?: boolean;
    @Prop() showFullName?: boolean;
    @Prop() currentFile?: File;
    // publicUrl = process.env.VUE_APP_BACKEND_URL;
    @Prop() fitMode?: string;
    @Prop() accept?: string;
    @Prop() data?: any;
    @Prop() allowedType?: string;
    @Prop() text?: string;
    @Prop() type?: string;
    @Prop() icon?: string;
    @Prop() previewStyle?: string;
    @Prop() buttonStyle?: string;
    @Prop() currentImageLink?: string;
    @Prop() uuid?: string;

    public types: string[] = [];
    message = 'Selectionnez un fichier';
    error = false;
    background: any = '';
    public file: any = null;
    folder = null;
    percent = 0;
    success = false;

    isUploading = false;
    fileId = '';
    public endPoint = api.master + 'uploads/';
    inputId = '';





    mounted(): void {
        if (this.currentFile) {
            this.file = this.currentFile;
        }

        this.inputId = uploadService.generateId();
        if (this.allowedType) {
            this.types = this.allowedType.split(',');
        }

        if (this.question.response.value) {
            this.background = this.endPoint + this.question.response.value;
        }

        uploadService.on('uploadStart', (data: any) => {

            if (data.id === this.fileId) {
                this.percent = data.percent;
            }
        });
        uploadService.on('chunkSent', (data: any) => {

            if (data.id === this.fileId) {
                // console.log(data.id);
                this.percent = data.percent;
            }
        });
        uploadService.on('uploadSuccess', async (data: any) => {
            if (data.id === this.fileId) {
                this.error = false;
                this.success = true;
                this.percent = 100;
                this.question.response.value = data.filename
                this.background = this.endPoint + this.question.response.value;
                await this.question.updateAnswer()
                this.$emit('input', data.serverFileId);
                this.isUploading = false;
            }
        });
        uploadService.on('error', (data: any) => {

            if (data.id === this.fileId) {
                // const error = data.xhr.error;
                this.isUploading = false;
                this.error = true;
                this.message = 'Une erreur a été rencontré lors du téléversement, veuillez réessayer plus tard';
            }
        });
    }


    @Watch('background')
    saveResponseValue() {

        this.question.response.value = this.endPoint + this.background

        this.question.updateAnswer()

        console.log('upload effect');

    }



}
</script>
<style scoped>
input[type='file'] {
    display: none;
}

.percent-container {
    position: absolute;
    bottom: 20px;
    width: 90%;
    height: 20px;
    z-index: 3;
    background: rgba(255, 255, 255, .4);
    box-shadow: 0 0 1px grey;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
}

.input-status {
    position: absolute;
    top: 10px;
    right: 10px;
    /*transform: translateY(-50%);*/
    cursor: pointer;
}

.spin {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotateZ(0deg)
    }

    100% {
        transform: rotateZ(360deg)
    }
}
</style>
