/* eslint-disabled */
import SmsTemplate from "./SmsTemplate";
import EmailTemplate from "./EmailTemplate";
import ActionExtend from "@/entity/extends/ActionExtend";

export default class Action extends ActionExtend {

public id = 0;
public type = '';
public waitingTime? = 0;
public smsTemplate!: SmsTemplate;
public emailTemplate!: EmailTemplate;
public ranking = 0;

  constructor (object?: any) {
      super(object)
      if(object){
       this.id= object.id;
       this.type= object.type;
       this.waitingTime= object.waitingTime;
this.smsTemplate = (object.smsTemplate instanceof SmsTemplate) ? object.smsTemplate : object.smsTemplate ? new SmsTemplate(object.smsTemplate) : object.smsTemplate;
this.emailTemplate = (object.emailTemplate instanceof EmailTemplate) ? object.emailTemplate : object.emailTemplate ? new EmailTemplate(object.emailTemplate) : object.emailTemplate;
       this.ranking= object.ranking;
      }
      this.postConstruct()
  }

}
