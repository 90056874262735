<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
          </div>

        </div>
      </div>
    </template>

    <template #content>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="my-5">
              <label class="my-2" for="">{{ trans('Nom') }}</label>
              <div>
                <input type="text" class="form-control" v-model="template.name">
              </div>
            </div>
            <div class="my-5">
              <label class="my-2" for="">{{ trans('Description') }}</label>
              <div>
                <textarea class="form-control" v-model="template.description">
                </textarea>
              </div>
            </div>

            <div class="my-5 d-flex justify-content-between">
              <div>
                {{ trans('Destinataires') }}
              </div>
              <div>
                <div class="d-flex align-items-center">
                  <div>
                    <input type="text" class="form-control" placeholder="email@wiassur.com" v-model="recipient"
                           @keyup.enter="addRecipient">
                  </div>
                  <div class=" btn btn-icon btn-primary btn-sm ms-1" @click="addRecipient">
                    <i class="fad fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex p-5 bg-light-dark mb-5">
                <div v-for="(recipient,index) in template.defaultRecipients" :key="'recip_'+index"
                     class="bg-primary py-2  rounded text-inverse-primary fw-bolder d-flex align-items-center m-1">
                  <div class="ms-2">
                    {{ recipient }}
                  </div>
                  <div class="px-1 rounded  mx-2 d-flex align-items-center cursor-pointer"
                       @click="removeRecipient(index)">
                    <i class="fad fa-times text-danger"></i>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                Objet
              </div>
              <div>
                <input type="text" class="form-control" v-model="template.object">
              </div>
            </div>


            <div class="py-10">
              <b-tabs>
                <b-tab>
                  <template #title>
                    HTML
                  </template>
                  <div class="d-flex min-h-300px">
                    <div class="flex-1" style="border-right: 1px solid gray">
                      <div class=" py-5 text-center bg-light-dark">Code</div>
                      <div class="d-flex align-items-stretch">
                        <code-editor :hide_header="true" width="100%" font_size="10px" :min-height="500"
                                     :border-radius="0"
                                     style="border-radius:0!important;min-height:500px"
                                     v-model="template.html"></code-editor>
                        <!--                        <textarea class="flex-1 form-control min-h-500px border-none" v-model="template.html"></textarea>-->
                      </div>
                    </div>
                    <div class="flex-1">
                      <div class=" py-5 text-center  bg-light-dark">Aperçu</div>
                      <iframe :srcdoc="template.html" width="100%" height="100%"></iframe>
                    </div>
                  </div>

                  <div class="py-10 d-flex justify-content-end" @click="save">
                    <div class="btn btn-primary">
                      Enregistrer
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    Texte
                  </template>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import EmailTemplate from "@/entity/EmailTemplate";
import Popup from "@/entity/Popup";
import CodeEditor from 'simple-code-editor';
import {api} from "@/services/Api";

@Component({
  components: {CodeEditor, Base}
})
export default class EmailTemplateForm extends Vue {

  template = new EmailTemplate()

  recipient = ''

  isValidEmail(email: string) {
    // Regular expression for a basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }

  addRecipient() {
    if (!this.isValidEmail(this.recipient)) {
      new Popup('Erreur', 'Addresse email invalide', 'danger');
      return
    }
    if (!this.template.defaultRecipients.includes(this.recipient)) {
      this.template.defaultRecipients.push(this.recipient)
      this.recipient = ''
    }
  }

  removeRecipient(index: number) {
    if (index >= 0 && index < this.template.defaultRecipients.length) {
      this.template.defaultRecipients.splice(index, 1)
    }
  }

  async save() {
    this.$store.commit('loading')
    const res = await api.post(api.com, 'email/template/edit', {
      template: this.template
    })
    this.$store.commit('stopLoading')
  }

  mounted() {
    this.load()
  }

  async load() {
    if (this.$route.params.uuid) {
      const res = await api.get(api.com, 'email/template/get/' + this.$route.params.uuid)
      if (res && res.data) {
        this.template = new EmailTemplate(res.data.template)
      }
    }
  }

}

</script>

<style scoped>
textarea {
}
</style>