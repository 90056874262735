import { render, staticRenderFns } from "./ManageCotation.vue?vue&type=template&id=76840134&scoped=true"
import script from "./ManageCotation.vue?vue&type=script&lang=ts"
export * from "./ManageCotation.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76840134",
  null
  
)

export default component.exports