<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div @click="edit" class="btn-sm btn btn-danger ms-5" v-b-modal="'paymentMethodModal'">
              <i class="fad fa-plus"></i>
              {{ trans('Créer') }}
            </div>

          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>

      <div class="card">
        <div class="card-body p-0">
          <table class="table table-striped gy-7 gs-7">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>{{ trans('Label') }}</th>
              <th>{{ trans('Active') }}</th>
              <th>{{ trans('Manager Required') }}</th>
              <th>{{ trans('Phone Number Required') }}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="method in methods">
              <td>
                <div class="d-flex">
                  <div class="me-5">
                    <img :src="uploadRoot + method.photo" alt="" class="w-50px h-50px rounded">
                  </div>
                  <div>
                    <div class="fw-bolder fs-2">
                      {{ method.label }}
                    </div>
                    <div class="text-gray-400">
                      {{ method.slug }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <label class="form-check form-switch form-check-custom form-check-solid m-2">
                  <input :checked="method.active" @change="toggle(method)"
                         class="form-check-input" type="checkbox"/>
                </label>
              </td>
              <td>
                <label class="form-check form-switch form-check-custom form-check-solid m-2">
                  <input :checked="method.requireManagerValidation" @change="toggleManager(method)"
                         class="form-check-input" type="checkbox"/>
                </label>
              </td>
              <td>
                <label class="form-check form-switch form-check-custom form-check-solid m-2">
                  <input :checked="method.requirePhoneNumber" @change="togglePhone(method)"
                         class="form-check-input" type="checkbox"/>
                </label>
              </td>
              <td>
                <div class="btn btn-xs btn-icon btn-primary" @click="edit(method)" v-b-modal="'paymentMethodModal'">
                  <i class="fad fa-edit"></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div>

          </div>
        </div>
      </div>

      <b-modal :title="trans('Editer un type de donnée')" centered id="paymentMethodModal" ref="paymentMethodModal">
        <form>
          <div class="row" v-if="method">
            <div class="d-flex justify-content-center">
              <FileUpload :allowMultiple="false" :allowRemove="false"
                          :allowedType="'image/jpeg,image/png,image/gif,image/jfif'" :fitMode="'contain'"
                          :icon="'fad fa-edit'"
                          :previewClass="'d-flex border-dashed w-150px object-contain cursor-pointer h-150px align-items-center justify-content-center m-auto'"
                          :text="'Selectionnez un logo'" :subFolder="'payment-methods'" :type="'full'"
                          accept=".jpg,.jpeg,.png,.gif,.jfif" v-on:filename="method.photo = $event"></FileUpload>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(method.label)"
                            v-model="method.label"></b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="form-label">{{ trans("Requiert l'intervention d'un agent ? ") }}</label>
              <label class="form-check form-switch form-check-custom form-check-solid m-2">
                <input v-model="method.requireManagerValidation"
                       class="form-check-input" type="checkbox"/>
              </label>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="form-label">{{
                  trans("Requiert la saisie  d'un numéro de téléphone ? ")
                }}</label>
              <label class="form-check form-switch form-check-custom form-check-solid m-2">
                <input v-model="method.requirePhoneNumber"
                       class="form-check-input" type="checkbox"/>
              </label>
            </div>

          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="save()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import PaymentMethod from '@/entity/PaymentMethod';
import FileUpload from "@/components/FileUpload.vue";
import {api} from '@/services/Api';
import Popup from '@/entity/Popup';

@Component({
  components: {Base, FileUpload}
})
export default class PaymentMethodList extends Vue {

  method: PaymentMethod = new PaymentMethod();
  methods: PaymentMethod[] = []
  uploadRoot = api.uploadRoot

  mounted() {
    this.load()
  }

  async toggle(method: PaymentMethod) {
    const popup = new Popup('Chargement ...', '', 'warning', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'payment/method/toggle/' + method.slug)
    popup.hide()
    this.load()
  }


  async toggleManager(method: PaymentMethod) {
    const popup = new Popup('Chargement ...', '', 'warning', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'payment/method/toggle/maanager/' + method.slug)
    popup.hide()
    this.load()
  }

  async togglePhone(method: PaymentMethod) {
    const popup = new Popup('Chargement ...', '', 'warning', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'payment/method/toggle/phone/' + method.slug)
    popup.hide()
    this.load()
  }

  async load() {
    const popup = new Popup('Chargement ...', '', 'warning', 'fad fa-cogs', true)
    const res = await api.get(api.core, 'payment/method/list')
    popup.hide()
    if (res && res.data) {
      this.methods = []
      res.data.methods.forEach((method: PaymentMethod) => {
        this.methods.push(new PaymentMethod(method))
      })
    }
  }

  edit(method?: PaymentMethod) {
    this.method = method ? new PaymentMethod({...method}) : new PaymentMethod()
  }

  async save() {
    const popup = new Popup('Enregistrement en cours ...', '', 'warning', 'fad fa-cogs', true)
    const res = await api.post(api.core, 'payment/method/edit', {method: this.method})
    if (res && res.data) {
      (this.$refs.paymentMethodModal as any).hide()
      this.method = new PaymentMethod()
      this.load()
    }
    popup.hide()
  }
}
</script>
<style scoped>

</style>
