/* eslint-disabled */

import Avenant from "@/entity/Avenant";

export default class BranchExtend {


    public engineVersion = null
    public avenants: Avenant[] = []
    emmissionTTCGlobale = 0
    emmissionNetteGlobale = 0
    avenantCount = 0

    constructor(object?: any) {
        if (object) {
            this.engineVersion = object.engineVersion
        }
    }

    postConstruct() {

    }

}
