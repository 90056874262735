/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import store from "@/store";
import {api} from "@/services/Api";
import router from "@/router";
import User from "@/entity/User";
import Tenant from '../entity/Tenant';
import Role from "@/entity/Role";
import Partner from "@/entity/Partner";
import {helper} from "@/services/Helper";

class Auth {

    ROLE_HIERARCHY: any = {
        ROLE_ADMIN: ['ROLE_USER', 'ROLE_COMMUNICATION', 'ROLE_SELL', 'ROLE_PRODUCTION', 'ROLE_INCOME'],
        ROLE_MANAGER: ['ROLE_USER', 'ROLE_COMMUNICATION', 'ROLE_SELL', 'ROLE_PRODUCTION', 'ROLE_INCOME']
    }

    constructor() {
    }

    async login(username: string, password: string, captchaResponse = '') {
        const result = await api.post(api.auth, 'login_check', {
            username: username,
            password: password,
            captcha: captchaResponse
        });
        if (!result) {
            return false;
        }
        const data = result.data;
        // console.log(data)
        // return
        if (data.token) {
            store.state.userToken = data.token
            store.state.userRefreshToken = data.refreshToken
            store.state.tenants = []
            store.state.user = new User(data.user)
            store.state.hierarchy = new Role(data.hierarchy)
            localStorage.setItem('mercureToken', data.mercure)
            localStorage.setItem('refreshToken', data.refreshToken)
            localStorage.setItem('userToken', data.token)
            localStorage.setItem('hierarchy', JSON.stringify(data.hierarchy))
            localStorage.setItem('user', JSON.stringify(data.user))
            let currentTenant: any = localStorage.getItem('currentTenant') ? new Tenant(JSON.parse(localStorage.getItem('currentTenant')!)) : null
            // console.log(currentTenant)
            // return
            let match = false

            const decodedJwt = this.parseJwt(data.token)

            let firstTenant: any = null;
            decodedJwt.tenants.forEach((t: any) => {
                const occ = new Tenant(t)
                if (!firstTenant) {
                    firstTenant = occ
                }
                if (currentTenant && occ.code === currentTenant) {
                    match = true
                }
                store.state.tenants.push(occ)
                if (!currentTenant) {
                    currentTenant = occ
                    store.state.currentTenant = occ
                    // console.log('setting current tenant to',occ);
                    localStorage.setItem('currentTenant', JSON.stringify(occ))
                } else {
                    store.state.currentTenant = currentTenant
                }
            })
            if (!match) {
                store.state.currentTenant = firstTenant
                localStorage.setItem('currentTenant', JSON.stringify(firstTenant))

            }
            if (helper.empty(store.state.currentTenant) && decodedJwt.tenants.length > 0) {
                store.state.currentTenant = new Tenant(data.user.tenants[0])
                localStorage.setItem('currentTenant', JSON.stringify(store.state.currentTenant))
            }
            localStorage.setItem('tenants', JSON.stringify(store.state.tenants))

            // console.log(store.state)
            // return
            router.push({name: 'home'})
            return true;
        }
        return false;
    }

    async loadPartnerData() {
        const res = await api.get(api.core, 'api/partner/load/data')
        // console.log(res)
        if (res && res.data) {
            const partner = new Partner(res.data.partner)
            store.state.partner = partner
            localStorage.setItem('partner', JSON.stringify(partner))
        }
    }

    async partnerLogin(username: string, password: string, captchaResponse = '') {
        const result = await api.post(api.auth, 'partner/login_check', {
            username: username, password: password,
            captcha: captchaResponse
        });
        if (!result) {
            return false;
        }
        const data = result.data;
        // return
        if (data.token) {
            store.state.userToken = data.token
            store.state.userRefreshToken = data.refreshToken
            store.state.tenants = []
            store.state.user = new Partner(data.user)
            // store.state.hierarchy = new Role(data.hierarchy)
            localStorage.setItem('mercureToken', data.mercure)
            localStorage.setItem('refreshToken', data.refreshToken)
            localStorage.setItem('userToken', data.token)
            // localStorage.setItem('hierarchy', JSON.stringify(data.hierarchy))
            localStorage.setItem('user', JSON.stringify(data.user))
            // console.log('is manager ?',this.hasRole('ROLE_PARTNER'))
            // return
            let currentTenant: any = localStorage.getItem('currentTenant') ? new Tenant(JSON.parse(localStorage.getItem('currentTenant')!)) : null
            // console.log(currentTenant)
            // return
            let match = false
            const decodedJwt = this.parseJwt(data.token)
            let firstTenant: any = null;
            decodedJwt.tenants.forEach((t: any) => {
                const occ = new Tenant(t)
                if (currentTenant && occ.code === currentTenant) {
                    match = true
                }
                store.state.tenants.push(occ)
                if (!currentTenant) {
                    currentTenant = occ
                    store.state.currentTenant = occ
                    // console.log('setting current tenant to',occ);
                    localStorage.setItem('currentTenant', JSON.stringify(occ))
                } else {
                    store.state.currentTenant = currentTenant
                }
            })
            if (!match) {
                store.state.currentTenant = null
                localStorage.removeItem('currentTenant')
            }
            if (decodedJwt.tenants.length > 0) {
                store.state.currentTenant = new Tenant(decodedJwt.tenants[0])
                localStorage.setItem('currentTenant', JSON.stringify(store.state.currentTenant))
            }
            localStorage.setItem('tenants', JSON.stringify(store.state.tenants))

            this.loadPartnerData()

            // console.log(store.state)
            // return
            router.push({name: 'home'})
            return true;
        }
        return false;
    }


    loadData() {
        const token = localStorage.getItem('userToken') || '';
        const refreshToken = localStorage.getItem('refreshToken') || '';
        store.state.userToken = token
        store.state.userRefreshToken = refreshToken
        return {token, refreshToken};
    }

    isConnected(): any {
        const data = this.loadData();
        if (!data) {
            return false;
        }
        return data.token;
    }

    isAdmin(): boolean {
        const data = this.loadData();
        if (!data) {
            return false;
        }
        const payload = this.parseJwt(data.token);
        if (payload.hasOwnProperty('roles')) {
            return payload.roles.includes('ROLE_ADMIN');
        }
        // new Popup('Erreur', 'L\'authentification a échoué', 'danger', 'fad fa-user');
        this.logout();
        return false;
    }

    logout(): void {
        let route = 'login'
        if (this.hasRole('ROLE_MANAGER')) {
            route = 'login'
        } else if (this.hasRole('ROLE_PARTNER')) {
            route = 'partner-login'
        }
        // console.log(route)
        // return
        store.commit('logout');
        localStorage.removeItem('user');
        localStorage.removeItem('userRefreshToken');
        localStorage.removeItem('token');
        localStorage.clear();
        if (router.currentRoute.name !== 'login') {
            router.push({name: route});
        }
    }

    hasRole(role: string) {

        // console.log('on check ' + role)
        const {token} = this.loadData()
        const data = this.parseJwt(token)
        let userRoles: any[] = []
        const currentTenant: Tenant = store.state.currentTenant
        for (let r of data.roles) {
            if (role === r || (currentTenant && currentTenant.code && r === role + '_' + currentTenant.code.toUpperCase())) {
                return true
            }
        }
        return data.roles.includes('ROLE_ADMIN')
        // || userRoles.includes('ROLE_ADMIN')
    }

    userHasRole(user: User, role: string) {

        let userRoles: any[] = []
        for (let r of user.roles) {
            if (role === r) {
                return true
            }
        }
        return false
        //user.roles.includes('ROLE_ADMIN')
    }

    partnerHasRole(partner: Partner, role: string) {
        let userRoles: any[] = []
        for (let r of partner.roles) {
            if (role === r) {
                return true
            }
        }
        return false
        //user.roles.includes('ROLE_ADMIN')
    }


    getCurrentUser() {
        if (store.state.user instanceof User) {
            // console.log('user from store')
            return store.state.user
        }
        const data = this.loadData()
        const userData = this.parseJwt(data.token)
        if (userData) {
            return new User(userData)
        }
        return null
    }

    getRoles() {
        const {token} = this.loadData()
        const data = this.parseJwt(token)
        return data.roles
    }

    hasRoles(roles: []) {
        let full = true;
        for (let role of roles) {
            if (!this.hasRole(role)) {
                full = false
            }
        }
        return full || this.hasRole('ROLE_ADMIN')
    }

    hasOneRole(roles: []) {
        for (let role of roles) {
            if (this.hasRole(role)) {
                return true;
            }
        }
        return this.hasRole('ROLE_ADMIN')
    }

    parseJwt(token: string): any {
        if (!token) {
            // this.logout()
            // console.log(token + ' token is empty');
            return {roles: []};
        }
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}

export const auth = new Auth();
