<template>
  <div :id="'step-container_' + step.uuid" class="Step">

    <draggable :animation="200" :id="'step_' + step.uuid" :list="step.sections" @end="updateSectionRanks" class=""
               filter=".action-button" ghost-class="bg-light-primary" group="sections" tag="div">
      <section-view :key="section.uuid" :questionTypes="questionTypes" :section="section" :survey="survey"
                    :selected="questionSelected" :durations="durations" :packCategories="packCategories"
                    v-for="section in step.sections" v-on:loadTypes="loadTypes" v-on:toggleQuestion="toggleQuestion"
                    v-on:reloadSurvey="reloadSurvey" v-on:delete="deleteSection"
                    v-on:showSectionForm="showSectionForm"></section-view>
    </draggable>
    <b-modal :id="'sectionForm-' + step.uuid" :title="trans('Edit_Section') + ' - ' + section.uuid" centered
             ref="sectionForm" scrollable size="xl">
      <div class="question-form d-flex flex-wrap row">
        <div class="col-auto m-2 flex-grow-1">
          <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
          <b-form-input :id="'label'" :state="section.label.trim().length > 0"
                        v-model="section.label"></b-form-input>
        </div>

        <div class="col-auto m-2 flex-grow-1">
          <label :for="'template'" class="required form-label">{{ trans('Template') }}</label>
          <b-form-input :id="'template'" :state="section.template.trim().length > 0"
                        v-model="section.template"></b-form-input>
        </div>

        <div class="col-auto m-2 flex-grow-1">
          <label :for="'expandable'" class="required form-label">{{ trans('expandable') }}</label>
          <div class="form-check form-switch form-check-custom form-check-solid">
            <input class="form-check-input" type="checkbox" v-model="section.expandable"/>
          </div>
        </div>

        <!--                <div class="row">-->
        <div class="col-auto m-2 flex-grow-1">
          <label :for="'description'" class="required form-label">{{ trans('Description') }}</label>
          <b-form-textarea :id="'description'" :state="!empty(section.description)"
                           v-model.trim="section.description"></b-form-textarea>
        </div>
        <!--                </div>-->
      </div>

      <template #modal-footer="{ ok, cancel, hide }">
        <b-button @click="cancel()" size="sm" variant="secondary">
          <i class="fad fa-times"></i>
          {{ trans('Cancel') }}
        </b-button>
        <b-button @click="editSection" size="sm" variant="primary">
          <i class="fad fa-save"></i>
          {{ trans('Save') }}
        </b-button>
      </template>
    </b-modal>

    <!--        <b-modal :title="trans('Edit_Question')" centered id="questionForm" ref="questionForm" scrollable size="xl">-->
    <!--        </b-modal>-->
    <div class="d-flex justify-content-center align-items-center">
      <div class="btn btn-warning btn-sm" v-b-modal="'sectionForm-' + step.uuid">
        <i class="fad fa-plus"></i>
        {{ trans('Create_a_section') }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Step from "@/entity/Step";

import Question from "@/entity/Question";
import Survey from "@/entity/Survey";
import Section from "@/entity/Section";
import {api} from "@/services/Api";
import SectionView from "@/views/Configuration/Branch/SurveyComponents/SectionView.vue";
import Popup from "@/entity/Popup";
import QuestionType from "@/entity/QuestionType";
import Duration from '@/entity/Duration';
import PackCategory from "@/entity/PackCategory";


@Component({
  components: {SectionView,}
})
export default class StepView extends Vue {
  [x: string]: any;

  @Prop() step!: Step
  @Prop() survey!: Survey
  @Prop() questionSelected!: string[]
  @Prop() questionTypes!: QuestionType[]
  @Prop() durations!: Duration[]
  @Prop() packCategories!: PackCategory[]
  question: Question = new Question()
  section: Section = new Section()

  mounted() {

    this.resetSection()
  }

  loadTypes() {
    this.$emit('loadTypes')
  }

  resetSection() {
    this.section = new Section()
    this.section.template = 'default'
  }

  deleteSection(uuid: string) {
    console.log(uuid)
    this.step.sections = this.step.sections.filter((section: Section) => {
      return section.uuid !== uuid
    })
  }

  editQuestion() {

  }

  toggleQuestion(evt: any) {
    this.$emit('toggleQuestion', evt)
  }

  reloadSurvey() {
    this.$emit('reload')
  }

  async updateSectionRanks(evt: any) {
    const section = evt.clone.id.replace('section-container_', '')
    const fromStep = evt.from.id.replace('step_', '')
    const oldIndex = evt.oldIndex
    const toStep = evt.to.id.replace('step_', '')
    const newIndex = evt.newIndex
    if (fromStep !== toStep || oldIndex !== newIndex) {
      const res = await api.post(api.form, 'section/update/position', {step: toStep, index: newIndex, section: section})
      this.reloadSurvey()
    }


    const popup = new Popup('', this.trans('Reorganisation en cours ...'), 'warning', 'fad fa-cogs', true)
    const ranks: any = []
    let i = 1;
    this.step.sections.forEach((s: any) => {
      if (s) {
        ranks.push({uuid: s.uuid, ranking: i})
        i++;
      }
    })
    // const res = await api.post(api.form, 'step/update/ranks', {ranks})
    // if (res && res.data && res.data.categories) {
    // }
    popup.hide()
  }

  showSectionForm(section: Section) {
    this.section = section
    const refs = this.$refs as any
    if (refs.hasOwnProperty('sectionForm')) {
      refs.sectionForm.show()
    }
  }

  async editSection() {
    this.$store.commit('loading')
    this.section.step = {uuid: this.step.uuid} as Step;
    const res = await api.post(api.form, 'section/edit', {section: this.section})
    if (res && res.data && res.data.status === 'success') {
      if (this.$refs) {
        (this.$refs.sectionForm as any).hide();
      }
      this.resetSection()
    }
    this.$store.commit('stopLoading')
    this.$emit('reload')
  }
}
</script>
<style scoped></style>
