<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Survey_list') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">

            <label
                class="form-check form-switch form-check-custom form-check-solid d-flex justify-content-end align-items-center">
              <span class=" form-label m-0 me-2">{{ trans('Afficher tout') }}</span>
              <input class="form-check-input h-20px w-30px me-2"
                     type="checkbox" v-model="showAll"
                     value=""/>
            </label>

            <b-button @click="resetSurvey" class="btn-sm" v-b-modal.surveyForm variant="primary">
              <i class="fad fa-plus"></i>
              {{ trans('Créer un formulaire') }}
            </b-button>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>


      <div class="d-flex flex-wrap" v-if="surveys.length > 0">
        <template v-for="survey in surveys">
          <div :key="survey.uuid" class="card m-3 flex-grow-1 " v-if="showAll || survey.isRoot">
            <div class="card-body d-flex flex-column justify-content-start align-self-stretch">
              <div class="d-flex align-items-center justify-content-between ">
                <div class="d-flex align-items-start">
                  <div>
                    <!--                                        <i :class="survey.icon+' fs-3x me-5'"></i>-->
                  </div>
                  <div class="d-flex align-items-center">
                    <label class="form-check form-switch form-check-custom form-check-solid me-2">
                      <input @change="toggleSurveyActive(survey.uuid)" class="form-check-input h-20px w-30px"
                             type="checkbox" v-model="survey.active"
                             value=""/>
                    </label>
                    <span class="fw-bolder">{{ survey.label }}</span>
                    <span>
                                            <i class="fad fa-star ms-2 text-danger" v-if="survey.isRoot"></i>
                                        </span>
                  </div>

                </div>
                <div>

                  <router-link
                      :title="trans('Organiser les données')"
                      :to="{ name: 'mapper-index', params : { branchSlug : survey.branch, surveySlug : survey.slug  } }"
                      class="btn btn-icon btn-sm btn-secondary me-2"
                      v-b-tooltip.hover
                      v-if="survey.isRoot && survey.branch">
                    <i class="fad fa-link"></i>
                  </router-link>
                  <!--                                    <div :title="trans('Afficher le formulaire')" class="btn btn-icon btn-sm btn-danger me-2" v-b-tooltip.hover>-->
                  <!--                                        <i class="fad fa-external-link"></i>-->
                  <!--                                    </div>-->
                  <div :title="trans('Modifier le formulaire')" @click="set(survey)"
                       class="btn btn-icon btn-sm btn-warning me-2"
                       v-b-modal.surveyForm v-b-tooltip.hover>
                    <i class="fad fa-edit"></i>
                  </div>
                  <router-link :title="trans('Gérer les questions')"
                               :to="{ name: 'survey-form',params: { surveySlug : survey.slug,branchSlug : survey.branch } }"
                               class="btn btn-icon btn-sm btn-primary me-2"
                               v-b-tooltip.hover>
                    <i class="fad fa-ballot-check"></i>
                  </router-link>
                  <div :title="trans('Générer le formulaire')" @click="generate(survey.slug)"
                       class="btn btn-icon btn-sm btn-danger me-2" v-b-tooltip.hover  v-if="!isSurveysOrphan">
                    <i class="fad fa-engine-warning"></i>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-wrap mt-5">
                            <span class="badge badge-dark m-1" v-for="platform in platforms">
                                {{ platform.label }}
                            </span>
              </div>
            </div>
          </div>
        </template>
      </div>

      <!--            <Loading v-else></Loading>-->


      <b-modal :title="trans('Edit_Survey')" centered id="surveyForm" ref="surveyForm" size="lg">
        <form>
          <div class="row" v-if="survey">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'name'" :state="survey.label.trim().length > 0"
                            v-model="survey.label"></b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Template') }}</label>
              <b-form-input :id="'name'" :state="survey.template.trim().length > 0"
                            v-model="survey.template"></b-form-input>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label for="">{{ trans('Formulaire principal') }}</label>
              <div>
                <label
                    class="form-check form-switch form-check-custom form-check-solid d-flex justify-content-end align-items-center">
                  <input class="form-check-input me-2"
                         type="checkbox" v-model="survey.isRoot"
                         value=""/>
                </label>
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1" v-if="!isSurveysOrphan()">
              <label for="">{{ trans("Importation contrat externe") }}</label>
              <div>
                <label
                    class="form-check form-switch form-check-custom form-check-solid d-flex justify-content-end align-items-center">
                  <input class="form-check-input me-2"
                         type="checkbox" v-model="survey.isImport"
                         value=""/>
                </label>
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1"  v-if="!isSurveysOrphan()">
              <label :for="'category'" class="required form-label">{{ trans('Lié à l\'objet') }}</label>

              <b-form-select class="form-control form-select-solid form-select" v-model="survey.item">
                <b-form-select-option :value="null">{{ trans('Select_an_item') }}</b-form-select-option>
                <b-form-select-option :key="item.uuid" :value="item.uuid" v-for="item,key in itemTypes">
                  {{ trans(item.name) }}
                </b-form-select-option>
              </b-form-select>
              <!--                                <b-form-input :id="'category'" :state="branch.label.length > 0"-->
              <!--                                              v-model="branch.category"></b-form-input>-->
            </div>
          </div>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Description') }}</label>
              <textarea class="form-control" cols="30" id="" name="" rows="5" v-model="survey.description"></textarea>
            </div>
          </div>

        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editSurvey" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Survey from "@/entity/Survey";
import Branch from "@/entity/Branch";
import Popup from "@/entity/Popup";
import {api} from "@/services/Api";
import ItemType from "@/entity/ItemType";
import Loading from "@/components/Loading.vue";
import InputNumber from "@/components/InputNumber.vue";
import Tag from "@/entity/Tag";
import Platform from "@/entity/Platform";

@Component({
  components: {InputNumber, Loading, Base}
})
export default class SurveyList extends Vue {
  [x: string]: any;

  itemTypes: ItemType[] = []
  surveys: Survey[] = []
  survey = new Survey()
  branch!: Branch
  tags: Tag[] = []
  showAll = false
  platforms: Platform[] = []

  isSurveysOrphan() {
    const slugs = [null, 'null', 'undefined', undefined, 'orphan'];
    return slugs.includes(this.$route.params.slug)
  }

  async editSurvey() {
    let survey = {...this.survey} as any
    // survey.branch = {id: this.branch.id, uuid: this.branch!.uuid} as Branch
    // survey.item = {id: survey.item!.id, uuid: survey.item!.uuid} as ItemType
    survey.steps = [];
    const res = await api.post(api.form, 'survey/edit', {survey})
    if (res.data.status === 'success') {
      this.resetSurvey();
      (this.$refs['surveyForm'] as any).hide()
    }
  }

  async mounted(): Promise<void> {
    await this.loadItems()
    this.resetSurvey()

  }

  set(survey: Survey) {
    this.survey = {...survey} as any
  }

  addTag() {
    // let empty = false
    // this.survey.tags.forEach((t: Tag) => {
    //   if (this.helper.empty(t.label)) {
    //     empty = true
    //   }
    // })
    // if (!empty) {
    //   this.survey.tags.push(new Tag())
    // } else {
    //   new Popup('Erreur', 'Veuillez renseigner les tags vides');
    // }
  }

  async resetSurvey() {

    this.$store.commit('loading')
    this.survey = new Survey()
    this.survey.template = 'default'
    this.survey.branch = this.$route.params.slug
    // this.setBranch()
    await this.load()
    this.$store.commit('stopLoading')

  }

  async toggleSurveyActive(uuid: string) {

    if (uuid) {
      this.$store.commit('loading')
      const res = await api.get(api.form, 'survey/toggle/active/' + uuid);
      this.load()
      this.$store.commit('stopLoading')
    }
  }

  async generate(slug: string) {
    this.$store.commit('loading')
    const res = await api.get(api.form, 'survey/generate/' + slug)
    console.log(res)
    this.$store.commit('stopLoading')
  }

  async setBranch() {
    // if (this.$route.params.slug) {
    //     const res = await api.get(api.core, 'branch/get/data/' + this.$route.params.slug)
    //     if (res && res.data && res.data.branch) {
    //         this.branch = new Branch(res.data.branch)
    //     }
    //     if (!this.branch) {
    //         new Popup(this.trans('An_error_occurred'), this.trans('This_slug_dont_match_with_any_branch'), 'danger')
    //         return;
    //     }
    // }
  }

  async load() {
    this.$store.commit('loading')
    const res = await api.get(api.form, 'survey/list/' + this.$route.params.slug)
    if (res && res.data && res.data.surveys) {
      this.surveys = []
      res.data.surveys.forEach((s: any) => {
        this.surveys.push(new Survey(s))
      })
    }
    this.$store.commit('stopLoading')
  }

  async loadPlatforms() {
    const res = await api.get(api.form, 'platform/list');
    if (res && res.data && res.data.platforms) {
      this.platforms = [];
      res.data.platforms.forEach((p: Platform) => {
        this.platforms.push(new Platform(p))
      })
    }
  }

  async loadItems() {
    const pop = new Popup('Chargement ...', 'Chargement des objets en cours', 'warning', 'fad fa-exclamation', true)
    // this.$store.commit('loading')
    const res = await api.get(api.core, 'item/type/list/' + this.$route.params.slug)
    if (res && res.data && res.data.itemTypes) {
      this.itemTypes = []
      res.data.itemTypes.forEach((itemType: any) => {
        this.itemTypes.push(new ItemType(itemType))
      })
    }
    pop.hide()
    // this.$store.commit('stopLoading')
  }

}
</script>
<style scoped>
</style>
