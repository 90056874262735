<template>
    <Base>
    <template #toolbar>
        <div class="toolbar" id="kt_toolbar">
            <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
                <div class="d-flex align-items-center me-3">
                    <slot name="leftToolbar">
                        <span class="h-20px border-gray-200 border-start mx-4"></span>
                        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                            <li class="breadcrumb-item text-muted">
                                <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                                    <i class="fad fa-home"></i>
                                    {{ trans('Dashboard') }}
                                </router-link>
                            </li>
                        </ul>
                    </slot>
                </div>


                <div class="d-flex align-items-center py-1">
                    <div class="btn-sm btn btn-primary ms-5" v-b-modal="'durationModal'">
                        <i class="fad fa-plus"></i>
                        {{ trans('Créer') }}
                    </div>

                </div>

            </div>
        </div>
    </template>
    <template #content>

        <div class="card">
            <div class="card-body p-0">
                <table class="table table-striped gy-7 gs-7">
                    <thead>
                        <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                            <th>{{ trans('Label') }}</th>
                            <th>{{trans('Période')}}</th>
                            <th>{{ trans('Code') }}</th>
                            <th>{{ trans('Définition') }}</th>
                            <th>{{ trans('Action') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="duration in durations">
                            <td>{{ duration.label }}</td>
                            <td>{{duration.period}}</td>
                            <td>{{ duration.code }}</td>
                            <td>
                                <template v-if="duration.year > 0">
                                    {{ duration.year +' '+ trans('An') }}
                                </template>       
                            
                            <template  v-if="duration.month > 0">
                                    {{duration.month +' '+ trans('Mois') }}
                            </template>
                            <template  v-if="duration.day > 0">
                                    {{duration.day +' '+ trans('Jour')}}
                            </template>
                            </td>
                            <td>
                                <div class="btn btn-xs btn-icon btn-warning" @click="setduration(duration)">
                                    <i class="fad fa-edit"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>

                </div>
            </div>
        </div>

        <b-modal :title="trans('Editer un type de donnée')" centered id="durationModal" ref="durationModal">
            <form>
                <div class="row" v-if="duration">
                    <div class="col-auto m-2 flex-grow-1">
                        <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
                        <b-form-input :id="'label'" :state="!helper.empty(duration.label)" v-model="duration.label">
                        </b-form-input>
                    </div>
                     <div class="col-auto m-2 flex-grow-1">
                        <label :for="'label'" class="required form-label">{{ trans('Période') }}</label>
                        <b-form-input :id="'label'" :state="!helper.empty(duration.period)" v-model="duration.period">
                        </b-form-input>
                    </div>
                    <div class="col-auto flex-grow-1 d-flex">
                        <div class="m-1">
                            <label class="required form-label">{{ trans('Année') }}</label>
                            <b-form-input type="number" :id="'year'" :state="!helper.empty(duration.year)"
                                v-model="duration.year"></b-form-input>
                        </div>
                        <div class="m-1">
                            <label class="required form-label">{{ trans('Mois') }}</label>
                            <b-form-input type="number" :id="'month'" :state="!helper.empty(duration.month)"
                                v-model="duration.month"></b-form-input>
                        </div>
                        <div class="m-1">
                            <label class="required form-label">{{ trans('Jour') }}</label>
                            <b-form-input type="number" :id="'day'" :state="!helper.empty(duration.day)"
                                v-model="duration.day"></b-form-input>
                        </div>
                    </div>
                </div>
            </form>
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button @click="cancel()" size="sm" variant="secondary">
                    <i class="fad fa-times"></i>
                    {{ trans('Cancel') }}
                </b-button>
                <b-button @click="editDuration()" size="sm" variant="primary">
                    <i class="fad fa-save"></i>
                    {{ trans('Save') }}
                </b-button>
            </template>
        </b-modal>
    </template>
    </Base>
</template>
<script lang="ts">
import Duration from '@/entity/Duration';
import Popup from '@/entity/Popup';
import { api } from '@/services/Api';
import { Component, Vue } from 'vue-property-decorator';
import Base from '../../layouts/Base.vue';

@Component({
    components: { Base }
})
export default class DurationManager extends Vue {

    durations: Duration[] = []
    duration = new Duration()
    mounted(): void {
        this.loadData()
    }

    async loadData() {
        const pop = new Popup('Chargement des durées', '', 'primary', 'fad fa-sync', true)
        const res = await api.get(api.master, 'api/get/durations')
        if (res && res.data && res.data.durations) {
            (this.$refs.durationModal as any).hide()
            this.durations = []
            res.data.durations.forEach((dur: Duration) => {
                this.durations.push(new Duration(dur))
            })
        }
        pop.hide()
    }

    setduration(duration:Duration){
        this.duration = duration;
        (this.$refs.durationModal as any).show()
    }

    async editDuration() {
        const res = await api.post(api.master, 'duration/edit', { duration: this.duration })
        if (res && res.data && res.data.duration) {
            this.loadData()
        }
    }
}
</script>
<style scoped>
</style>