<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Survey_list') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="card m-5 shadow-sm">
        <div class="card-body d-flex flex-column">

          <form>
            <div class="row" v-if="insurer">
              <div class="d-flex justify-content-center">
                <FileUpload :allowMultiple="false" :allowRemove="false" :key="insurer.id"
                            :allowedType="'image/jpeg,image/png,image/gif,image/jfif'" :fitMode="'contain'"
                            :icon="'fad fa-edit'"
                            v-model="insurer.photo"
                            :previewClass="'d-flex border-dashed w-150px object-contain cursor-pointer h-150px align-items-center justify-content-center m-auto'"
                            :text="'Selectionnez un logo'" :type="'full'" accept=".jpg,.jpeg,.png,.gif,.jfif"
                ></FileUpload>
              </div>
              <div class="col-auto m-2 flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
                <b-form-input :id="'label'" :state="!helper.empty(insurer.label)"
                              v-model="insurer.label"></b-form-input>
              </div>


              <div class="col-auto m-2 flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Code ASACI') }}</label>
                <b-form-input :id="'label'" :state="!helper.empty(insurer.codeCompagnie)"
                              v-model="insurer.codeCompagnie"></b-form-input>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Numero de téléphone') }}</label>
                <b-form-input :id="'label'" :state="!helper.empty(insurer.phoneNumber)"
                              v-model="insurer.phoneNumber"></b-form-input>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Addresse') }}</label>
                <b-form-input :id="'label'" :state="!helper.empty(insurer.address)"
                              v-model="insurer.address"></b-form-input>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Numero de téléphone') }}</label>
                <b-form-input :id="'label'" :state="!helper.empty(insurer.email)"
                              v-model="insurer.email"></b-form-input>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Code Autorisation ASACI') }}</label>
                <b-form-input :id="'label'" :state="!helper.empty(insurer.codeAutorisation)"
                              v-model="insurer.codeAutorisation"></b-form-input>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Attestations restantes ASACI (Jaune)') }}</label>
                <b-form-input :id="'label'" type="number" :state="!helper.empty(insurer.eAttestationCountYellow)"
                              v-model="insurer.eAttestationCountYellow"></b-form-input>
              </div>

              <div class="col-auto m-2 flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Attestations restantes ASACI (Vert)') }}</label>
                <b-form-input :id="'label'" type="number" :state="!helper.empty(insurer.eAttestationCountGreen)"
                              v-model="insurer.eAttestationCountGreen"></b-form-input>
              </div>
              <div class="col-auto m-2 flex-grow-1">
                <label :for="'label'" class="required form-label">{{ trans('Attestations restantes ASACI (Bleu)') }}</label>
                <b-form-input :id="'label'" type="number" :state="!helper.empty(insurer.eAttestationCountBlue)"
                              v-model="insurer.eAttestationCountBlue"></b-form-input>
              </div>

              <div class="d-flex justify-content-center m-5" @click="saveInsurer">
                <div class="btn btn-primary">
                  Enregistrer
                </div>
              </div>


            </div>

          </form>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <b-tabs>
            <b-tab active>
              <template #title>
                <!--                <b-spinner type="grow" small></b-spinner>-->
                Cartes Brunes
              </template>

              <div>
                <div class="d-flex justify-content-end m-5">

                  <b-dropdown ref="attribution" right size="sm" style="padding:0" tag="div"
                              toggle-class="" variant="primary" v-if="numbers.length > 0">
                    <template v-slot:button-content>
                      Attribuer à
                    </template>
                    <b-dropdown-item :key="'p_'+partner.uuid" @click="setPartner(partner)" v-for="partner in partners">
                      <div class="py-3">
                        {{ partner.name }} . {{ partner.code }}
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>

                  <div class="btn btn-primary ms-3" v-b-modal="'importBrownCard'">
                    <i class="fad fa-download"></i>
                    Importer
                  </div>


                </div>

                <div>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>
                        <div class="form-check form-check-custom form-check-sm">
                          <input class="form-check-input" type="checkbox"/>
                        </div>
                      </th>
                      <th>Numero</th>
                      <th>Partenaire</th>

                      <th>Créé le</th>
                      <th>Attribué à</th>
                      <th>Attribué le</th>
                      <th>Attribué Par</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="browncard in brownCards">
                      <td>
                        <div class="form-check form-check-custom form-check-sm" v-if="!isAssigned(browncard.number)">
                          <input class="form-check-input" type="checkbox" :checked="isSelected(browncard.number)"
                                 @change="select($event,browncard.number)"/>
                        </div>
                        <div class="form-check form-check-custom form-check-sm" v-else>
                          <input class="form-check-input" type="checkbox" checked disabled/>
                        </div>
                      </td>
                      <td>
                        {{ browncard.number }}
                      </td>
                      <td>
                        <div v-if="browncard.partner">
                          {{ browncard.partner.name }}
                        </div>
                        <div v-else>
                          -
                        </div>
                      </td>
                      <td>
                        {{ datify(browncard.createdAt) }}
                      </td>
                      <td>
                        <router-link :to="{name : 'contract-show', params : {contractUuid : browncard.item.contract.uuid}}" v-if="browncard.item" class="fw-bolder">
                          <template v-if="!helper.empty(browncard.item.alias)">
                            {{ browncard.item.alias }}
                          </template>
                          <template v-else>
                            {{ browncard.item.contract.number }}
                          </template>
                        </router-link>
                      </td>
                      <td>
                        {{ datify(browncard.at) }}
                      </td>
                      <td>
                        {{browncard.assignedBy}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-tab>

            <!--            <b-tab>-->
            <!--              <template #title>-->
            <!--                <b-spinner type="border" small></b-spinner> Tab 2-->
            <!--              </template>-->
            <!--              <p class="p-3">Tab contents 2</p>-->
            <!--            </b-tab>-->
          </b-tabs>
        </div>
      </div>

      <b-modal :title="trans('Importer des carte brunes')" centered id="importBrownCard" ref="importBrownCard"
               scrollable size="xl">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'min'" class="required form-label">{{ trans('De') }}</label>
              <b-form-input type="number" :id="'min'" v-model="interval.min" @keyup="updateMax"
                            @change="updateMax"></b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'max'" class="required form-label">{{ trans('À') }}</label>
              <b-form-input type="number" :id="'max'" @keyup="updateCount" @change="updateCount"
                            v-model="interval.max"></b-form-input>
            </div>
          </div>

          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'count'" class=" form-label">{{ trans('Nombre de carte brune généré') }}</label>
              <b-form-input type="number" :id="'count'" v-model="count" @keyup="updateMax"
                            @change="updateMax"></b-form-input>
            </div>

          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button size="sm" variant="primary" @click="importBrownCards">
            <i class="fad fa-save"></i>
            {{ trans('Importer') }}
          </b-button>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import FileUpload from "@/components/FileUpload.vue";
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Insurer from '@/entity/Insurer';
import BrownCard from "@/entity/BrownCard";
import InputNumber from "@/components/InputNumber.vue";
import Popup from "@/entity/Popup";
import Partner from "@/entity/Partner";

@Component({
  components: {InputNumber, Base, FileUpload}
})
export default class InsurerForm extends Vue {

  insurer = new Insurer()
  count: number = 1
  max = 0
  min = 0
  shift = false
  numbers: number[] = []
  selection = {
    min: 0,
    max: 0
  }
  interval = {
    max: 0,
    min: 0,
  }

  async setPartner(partner: Partner) {
    this.$store.commit('loading')
    const res = await api.post(api.core, 'insurer/set/browncards/partner', {
      slug: this.$route.params.slug,
      browncards: this.numbers,
      partner: partner.code
    })
    if (res && res.data) {
      this.numbers = []
      this.selection = {min: 0, max: 0}
      await this.loadBrownCards()
      this.$store.commit('stopLoading')

    }
  }

  async saveInsurer() {
    const popup = new Popup('Enregistrement en cours ...', '', 'warning', 'fad fa-cogs', true)
    const insurer = {...this.insurer}
    const res = await api.post(api.core, 'insurer/edit', {insurer});
    popup.hide()
  }

  isSelected(number: number) {
    number = parseInt(number as any)
    return this.numbers.includes(number)
  }

  select(e: any, number: number) {
    number = parseInt(number as any)
    if (!this.shift) {
      if (!this.numbers.includes(number)) {
        this.selection.min = number
      } else {
        this.selection.min = 0
      }
      this.toggle(number)
    } else {
      this.selection.max = number
      if (this.selection.min > 0) {
        const start = Math.min(this.selection.min, this.selection.max)
        const end = Math.max(this.selection.min, this.selection.max)
        // console.log(start, end)
        for (let i = start; i <= end; i++) {
          this.append(i)
        }
      } else {
        this.selection.min = number
        this.toggle(number)
      }
    }
    // console.log(this.numbers)
    this.$forceUpdate()
  }

  clear() {
    this.numbers = []
  }

  append(number: number) {
    number = parseInt(number as any)
    if (!this.numbers.includes(number)) {
      if (!this.isAssigned(number)) {
        this.numbers.push(number)
      }
    }
  }

  isAssigned(number: number) {
    const bc = this.brownCards.find((bc: BrownCard) => {
      return '' + bc.number === '' + number
    })
    return (bc && bc.partner && bc.partner.code) || (bc && bc.at)
  }

  toggle(number: number) {
    number = parseInt(number as any)

    if (!this.numbers.includes(number)) {
      if (!this.isAssigned(number)) {
        this.numbers.push(number)
      }
    } else {
      const i = this.numbers.indexOf(number)
      this.numbers.splice(i, 1)
    }
  }

  mounted() {
    this.load()
    this.loadBrownCards()
    this.loadPartners()
    window.addEventListener('keyup', this.shiftListener)
    window.addEventListener('keydown', this.shiftListener)
  }

  beforeUnmount() {
    window.removeEventListener('keyup', this.shiftListener)
    window.removeEventListener('keydown', this.shiftListener)
  }

  shiftListener(e: any) {
    this.shift = e.shiftKey
  }


  updateCount() {
    const max: number = parseInt(this.interval.max as any)
    const min: number = parseInt(this.interval.min as any)
    this.count = max - min + 1
  }

  updateMax() {
    const min: number = parseInt(this.interval.min as any)
    const count: number = parseInt(this.count as any)
    // console.log(typeof min)
    // console.log(typeof count)
    this.interval.max = min + count - 1
  }

  async importBrownCards() {
    this.$store.commit('loading')
    const res = await api.get(api.core, 'insurer/import/browncards/' + this.$route.params.slug + '/' + this.interval.min + '/' + this.interval.max)
    if (res && res.data && res.data.browncards) {
      (this.$refs as any).importBrownCard.hide()
    }
    this.$store.commit('stopLoading')

  }

  async load() {
    const res = await api.get(api.core, 'insurer/get/data/' + this.$route.params.slug)
    if (res && res.data) {
      this.insurer = new Insurer(res.data.insurer)
    }
  }

  brownCards: BrownCard[] = []

  async loadBrownCards() {
    this.brownCards = []
    const res = await api.get(api.core, 'insurer/get/browncards/' + this.$route.params.slug)
    if (res && res.data && res.data.browncards) {
      res.data.browncards.forEach((bc: any) => {
        this.brownCards.push(new BrownCard(bc))
      })
    }
  }

  partners: Partner[] = []

  async loadPartners() {
    const res = await api.get(api.core, 'partner/list')
    if (res && res.data && res.data.partners) {
      this.partners = []
      res.data.partners.forEach((bc: any) => {
        this.partners.push(new Partner(bc))
      })
    }
  }
}
</script>


<style scoped>

</style>
