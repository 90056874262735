<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'communication-index'}" class="text-muted text-hover-primary">
                    <i class="fad fa-comments"></i>
                    {{ trans('Communication') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <label class="btn btn-primary btn-sm">
              <input @change="setContacts" accept=".xlsx,.xls,.csv" class="hidden" type="file">
              <i class="fad fa-file-import"></i>
              {{ trans('Importer') }}
            </label>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex">
        <div class="flex-grow-1 p-10">
          <div class="d-flex justify-content-between">
            <div>
              Orange({{ orangeCount }})
            </div>
            <div>
              MTN ({{ mtnCount }})
            </div>
            <div>
              Moov ({{ moovCount }})
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center mt-5">
            <h2>{{ trans('Message') }}</h2>
            <button :data-kt-indicator="gettingBalance ? 'on' : 'off'" @click.prevent="getBalance"
                    class="btn btn-warning text-dark fw-bolder btn-sm" type="submit">
              <span class="indicator-label">{{ balance }} {{ trans('disponible') }}</span>
              <span class="indicator-progress">{{ balance }} {{ trans('disponible') }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
          </div>
          <div>
            <textarea class="form-control my-5 h-200px" v-model.trim="message"></textarea>
          </div>
          <div class="d-flex justify-content-end">
            <button @click="process" class="btn btn-sm btn-primary" :disabled="inProcess" >
              <i class="fad fa-paper-plane"></i>
              {{ trans('Envoyer') }}
            </button>
     <!--       <div @click="stopDiffusion" class="btn btn-sm btn-danger" v-else>
              <i class="fad fa-paper-plane"></i>
              {{ trans('Annuler') }}
            </div>
            -->
          </div>
        </div>
        <div class="flex-grow-1 p-5 bg-white">
          <div class="py-5 d-flex justify-content-around">
            <div @click="loadCustomers" class="btn btn-primary btn-sm">
              Charger tous les clients
            </div>
            <div @click="loadProspects" class="btn btn-primary btn-sm">
              Charger tous les prospects
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-5">
            <div class="d-flex align-items-center">
              <div class="ms-2 me-3 form-check form-check-sm form-check-custom form-check-solid">
                <input @change="toggleAll" class="form-check-input " type="checkbox">
              </div>
              <h2 class="d-flex mb-0">
                {{ trans('Contacts') }} ({{ contacts.length }})
              </h2>
            </div>
            <div>
                            <span @click="emptyContacts" class="badge badge-danger">
                            <i class="fad fa-ban text-white"></i>
                            </span>
              <span class="badge badge-primary ms-2" v-b-modal.add-contact>
                            <i class="fad fa-plus text-white"></i>
                            </span>
            </div>
          </div>
          <div class="mh-400px overflow-auto">
            <div class="d-flex align-items-center p-2 justify-content-between border-bottom"
                 v-for="contact in contacts">
              <div class="d-flex align-items-center">
                <div class="me-3 form-check form-check-sm form-check-custom form-check-solid">
                  <input class="form-check-input " type="checkbox" v-model="contact.selected">
                </div>
                {{ contact.number }}
              </div>
              <div>
                <i class="fad fa-pause-circle" v-if="!contact.sent"></i>
                <i class="fad fa-thumbs-up text-success" v-else></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card my-5">
        <div class="card-body">
          <h2>{{ trans('Résumé') }}</h2>
          <div class="border-bottom py-2" v-for="log,index in logs">
            {{ index + 1 }} - {{ log }}
          </div>
        </div>
      </div>

      <b-modal centered id="add-contact" ref="addContact" title="Ajouter un contact">
        <template #default>
          <label for="">{{ trans('Contact') }}</label>
          <input class="form-control" type="number" v-model="newContact">
        </template>
        <template #modal-footer="{ ok, cancel, hide }">
          <div class="w-100 d-flex justify-content-end">
            <div @click="cancel" class="btn ">
              {{ trans('Annuler') }}
            </div>
            <div @click="addContact" class="btn btn-primary">
              {{ trans('Enregister') }}
            </div>
          </div>
        </template>
      </b-modal>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import Popup from "@/entity/Popup";
import readXlsxFile from "read-excel-file";

@Component({
  components: {Base}
})
export default class CommunicationIndex extends Vue {
  [x: string]: any;

  balance = 0
  gettingBalance = false
  contacts: any[] = []
  orangeCount = 0
  mtnCount = 0
  moovCount = 0
  orangeSms = {
    token: null,
    expireAt: null
  }
  customerCount = 0
  prospectCount = 0
  inProcess = false
  stoped = false
  message = 'Protégez-vous en souscrivant à une assurance santé. RDV sur wiassur.com pour comparer tous les assureurs gratuitement et économiser de l\'argent.'
  newContact = ''
  logs: string[] = []

  isLoading =false

  addContact() {
    if (this.isValidNumber(this.newContact)) {
      const validated = this.validateNumber(this.newContact)
      this.contacts.push({number: validated, selected: true, sent: false});
      (this.$refs as any).addContact.hide()
      this.newContact = ''
    }
    this.getCounts()
  }

  async getMTNCredit() {
    const res = await api.get(api.auth, 'sms/get/credit');
    if (res && res.data && res.data.credit) {
      this.balance = res.data.credit
    }
  }

  async loadCustomers() {
    const res = await api.get(api.core, 'customer/phone/number/list')
    if (res && res.data && res.data.phoneNumbers) {
      this.customerCount = res.data.phoneNumbers.length
      res.data.phoneNumbers.forEach((pn: string) => {
        const match = this.contacts.findIndex((c: any) => {
          return c.number === pn
        })
        if (match === -1) {
          this.contacts.push({number: pn, selected: true, sent: false})
        }
      })
    }
  }

  async loadProspects() {
    const res = await api.get(api.form, 'prospect/phone/number/list')
    if (res && res.data && res.data.phoneNumbers) {
      this.customerCount = res.data.phoneNumbers.length
      res.data.phoneNumbers.forEach((pn: string) => {
        const match = this.contacts.findIndex((c: any) => {
          return c.number === pn
        })
        if (match === -1) {
          this.contacts.push({number: pn, selected: true, sent: false})
        }
      })
    }
  }

  setContacts(e: any) {
    const file = e.target.files[0]
    const parts = file.name.split('.')
    const ext: string = parts[parts.length - 1]
    if (ext.startsWith('xls')) {
      readXlsxFile(file).then((rows) => {
        rows.forEach((row: any) => {
          row.forEach((column: any) => {
            if (this.isValidNumber(column)) {
              const validated: any = this.validateNumber(column)
              this.contacts.push({number: validated, selected: true, sent: false})
            }
          })
        })
        this.getCounts()
      })
    }
  }

  emptyContacts() {
    this.contacts = []
  }

  validateNumber(number: string) {
    number = number.replace(/\s/g, '').replace(/\D/g, '');
    if (number.startsWith('+225') && (number.length === 12 || number.length === 14)) {
      number = number.substring(4, number.length);
    }
    if (number.startsWith('225') && (number.length === 11 || number.length === 13)) {
      number = number.substring(3, number.length);
    }
    if (number.startsWith('00225') && (number.length === 13 || number.length === 15)) {
      number = number.substring(5, number.length);
    }
    return number;
  }

  isValidNumber(number: string) {
    const validated = this.validateNumber(number)
    const reg = /^\d+$/
    return validated.length === 10 && reg.test(number)
  }

  get expireAt() {
    if (this.orangeSms.expireAt) {
      const expireAt = new Date(Number(this.orangeSms.expireAt))
      return this.helper.readable(expireAt, true, true)
    }
    return ''
  }

  async mounted() {
    this.setup()
    this.getBalance()
  }

  setup() {
    // this.newContact = '0789986979'
    this.newContact = '2250544984490'
    this.addContact()
    this.newContact = '0789986979'
    this.addContact()
    this.newContact = ''

  }

  toggleAll(e: any) {
    const checked = e.target.checked
    if (checked) {
      this.contacts.forEach((contact: any) => {
        contact.selected = true
      })
    } else {
      this.contacts.forEach((contact: any) => {
        contact.selected = false
      })
    }
  }

  async getBalance() {
    this.getMTNCredit()
  }

  startAt: any = null
  endAt: any = null

  async process() {
    if (this.helper.empty(this.message)) {
      new Popup('Attention', 'Votre message est vide', 'danger')
      return;
    }
    const numbers: string[] = []
    this.contacts.forEach((c: any) => {
      if (c.selected && !numbers.includes(c.number)) {
        numbers.push(c.number)
      }
    })
    if (numbers.length > 0) {
      this.inProcess =true
      const pop = new Popup(
      "Chargement",
      "Envoie des messages",
      "primary",
      "fad fa-sync",
      true

    );
      this.$store.commit('loading')
      const res = await api.post(api.auth, 'sms/send', {
        'message': this.message,
        'recipients': numbers
      })
      this.$store.commit('stopLoading')
      this.inProcess =false
      pop.hide();
    }
    return
  }

  async send(i: number) {

    await this.getToken()
    const res = await api.post(api.com, 'communication/orange/sms/send', {
      number: this.contacts[i].number,
      token: this.orangeSms.token,
      message: this.message
    })
    if (res && res.data && res.data.status === 'success') {
      this.contacts[i].sent = true
      this.balance--
      const now = new Date();
      this.logs.push('Message envoyé au ' + this.contacts[i].number + ' le ' + this.helper.readable(now, true, false, true, true));
    } else {
      const now = new Date();
      this.logs.push('Echec au ' + this.contacts[i].number + ' le ' + this.helper.readable(now, true, false, true, true));
    }
    return;
  }

  isDuplicated(number: string) {
    for (let i = 0; i < this.contacts.length; i++) {
      if (this.contacts[i].number === number) {
        return true
      }
    }
    return false
  }

  async sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async stopDiffusion() {
    this.stoped = true
  }

  async getToken() {
    const exist = localStorage.getItem('orangeSMS')
    // console.log(exist)
    // return;
    if (exist) {
      this.orangeSms = JSON.parse(exist)
    }
    if (this.isTokenExpired() || !exist) {
      const pop = new Popup('EN COURS', 'Génération des accès', 'warning', 'fas fa-cogs', true)
      // console.log('token is expired')
      const res = await api.get(api.com, 'communication/orange/sms/get/token')
      if (res && res.data && res.data.status === 'success') {
        this.orangeSms.token = res.data.token
        const now = Date.now()
        this.orangeSms.expireAt = (now + 3600000) as any
        localStorage.setItem('orangeSMS', JSON.stringify(this.orangeSms))
      }
      pop.hide()
      return this.orangeSms.token
    } else {
      // const expireAt = new Date(Number(this.orangeSms.expireAt))
      // console.log('token valide jusqua ' + this.helper.readable(expireAt, true, true))
      return this.orangeSms.token
    }
  }


  isTokenExpired(): boolean {
    const now = Date.now() + 5
    const expireAt = Number(this.orangeSms.expireAt)
    return now >= expireAt
  }

  getCounts() {
    this.orangeCount = 0
    this.mtnCount = 0
    this.moovCount = 0
    this.contacts.forEach((contact: any) => {
      const number = this.validateNumber(contact.number)
      if (number.length > 0) {
        if (number.startsWith('07')) {
          this.orangeCount++
        }
        if (number.startsWith('05')) {
          this.mtnCount++
        }
        if (number.startsWith('01')) {
          this.moovCount++
        }
      }
    })
  }

}
</script>
<style scoped>
</style>
